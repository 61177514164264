import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col, Icon, PageTile, Row } from '../Atoms';
import { getTemplateBodyUrl, getTemplateFooterUrl, getTemplateHeaderUrl } from '../../utils/appRoutes';
import TemplatePageWrapper from './TemplatePageWrapper';
import TemplateForm from './TemplateForm';

const TemplatePage: FC = () => {
  return (
    <TemplatePageWrapper>
      {(template, partnerId, templateId) => (
        <>
          <Row className="mb-4">
            <Col>
              <PageTile className="d-flex align-items-center">
                <div className="text-primary ms-2">
                  <Icon type="mdiFilePdfBox" scale={2} />
                </div>
                <div className="ps-4">
                  <h2 className="fw-bold mb-0">{template.name}</h2>
                  <span className="text-secondary">Change template’s settings or edit Header, Body and Footer</span>
                </div>
              </PageTile>
            </Col>
          </Row>
          <TemplateForm template={template}>
            <Col md={6} sm={12}>
              <PageTile>
                <h5>Edit</h5>
                <small className="text-secondary">Header and Footer will be automatically added to each page</small>
                <div className="mt-2 d-flex">
                  <Link
                    to={getTemplateHeaderUrl(partnerId, templateId)}
                    className="btn btn-outline-primary flex-grow-1 flex-basis-0"
                  >
                    Header
                  </Link>
                  <Link
                    to={getTemplateBodyUrl(partnerId, templateId)}
                    className="btn btn-outline-primary flex-grow-1 flex-basis-0"
                  >
                    Body
                  </Link>
                  <Link
                    to={getTemplateFooterUrl(partnerId, templateId)}
                    className="btn btn-outline-primary flex-grow-1 flex-basis-0"
                  >
                    Footer
                  </Link>
                </div>
              </PageTile>
            </Col>
          </TemplateForm>
        </>
      )}
    </TemplatePageWrapper>
  );
};

export default TemplatePage;
