import { useState, useEffect } from 'react';
import { get, set, cloneDeep } from 'lodash';
import { ProjectType } from 'types';
import { Container, Row, Col } from 'reactstrap';
import { useWatch } from 'react-hook-form';
import { FormField, Alert } from '../../Atoms';
import { State, ContactFormFields } from '../types';

interface SelectOptions {
  value: string;
  label: string;
}

interface ContactFormProps {
  project?: ProjectType;
}

export const FIELD_TYPES = {
  INPUT: {
    TEXT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
    PHONE: 'tel'
  },
  TEXTAREA: 'textarea',
  SELECT: 'select',
  COUNTRY_LIST: 'country-list',
  CHECKBOX: 'checkbox',
  CONSENT: 'consent',
  DIVIDER: '--- divider ---'
};

export const FIELDS = [
  { label: 'Text', value: FIELD_TYPES.INPUT.TEXT },
  { label: 'Number', value: FIELD_TYPES.INPUT.NUMBER },
  { label: 'Email', value: FIELD_TYPES.INPUT.EMAIL },
  { label: 'Phone', value: FIELD_TYPES.INPUT.PHONE },
  { label: 'Textarea', value: FIELD_TYPES.TEXTAREA },
  { label: 'Country List', value: FIELD_TYPES.COUNTRY_LIST },
  { label: 'Select', value: FIELD_TYPES.SELECT },
  { label: 'Checkbox', value: FIELD_TYPES.CHECKBOX },
  { label: 'Consent', value: FIELD_TYPES.CONSENT },
  { label: 'Divider', value: FIELD_TYPES.DIVIDER }
];

export const getFields = (type: string, index: number) => {
  const fields = [];

  if (![FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(type)) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].name`}
        name={`settings.contactForm.fields[${index}].name`}
        label="Name"
        required
        useShadowValue
      />
    );
  }

  if (FIELD_TYPES.CONSENT === type) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].label`}
        name={`settings.contactForm.fields[${index}].label`}
        label="Label"
        useShadowValue
      />
    );
  }

  if (FIELD_TYPES.SELECT === type) {
    fields.push(
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'label', 'untitled')}
        name={`settings.contactForm.fields[${index}].options`}
        key={`settings.contactForm.fields[${index}].options`}
        label="Options"
        description="List of available options"
        useShadowValue
      >
        <FormField.Text<SelectOptions> key="value" name="value" label="Value" />
        <FormField.Text<SelectOptions> key="label" name="label" label="Label" />
      </FormField.ArrayWrapper>
    );
  }

  if (![FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(type)) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].autocomplete`}
        name={`settings.contactForm.fields[${index}].autocomplete`}
        label="Autocomplete"
        useShadowValue
      />
    );
  }

  if (![FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(type)) {
    fields.push(
      <FormField.Toggle<ProjectType>
        key={`settings.contactForm.fields[${index}].required`}
        name={`settings.contactForm.fields[${index}].required`}
        label="Required"
        description=""
        useShadowValue
      />
    );
  }

  if (FIELD_TYPES.CHECKBOX === type) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].title`}
        name={`settings.contactForm.fields[${index}].title`}
        label="Title"
        description=""
        useShadowValue
      />
    );
    fields.push(
      <FormField.Toggle<ProjectType>
        key={`settings.contactForm.fields[${index}].default`}
        name={`settings.contactForm.fields[${index}].default`}
        label="Prechecked"
        description=""
        useShadowValue
      />
    );
  }

  return fields;
};

export const ContactFormFieldsWrapper = (props: { index?: number; state?: State }) => {
  if (!props.state || props.index === undefined) {
    return null;
  }

  if (props.state && props.state.contactFormType) {
    return <>{getFields(props.state.contactFormType[props.index], props.index as number)}</>;
  }

  return null;
};

export const ContactForm = ({ project }: ContactFormProps) => {
  const fields = useWatch({ name: 'settings.contactForm.fields' });

  const contactFormFieldSettings = get(project, 'settings.contactForm.fields', []).map(
    (setting: ContactFormFields) => setting.type
  );

  const [state, setState] = useState<State>({ contactFormType: contactFormFieldSettings, metricState: [] });

  const [showUniqueAlert, setShowUniqueAlert] = useState<boolean>();
  const [showNoEmailAlert, setShowNoEmailAlert] = useState<boolean>();

  const contactFormTypeChangeHandler = (type: SelectOptions | null, index?: number) => {
    if (!type || index === undefined) {
      return;
    }
    set(state, `contactFormType[${index}]`, type?.value);
    setState(cloneDeep(state));
  };

  const checkContactFormFields = (values: ContactFormFields[]) => {
    setShowUniqueAlert(false);
    setShowNoEmailAlert(false);

    const map: Record<string, boolean> = {};

    values.forEach((value: ContactFormFields) => {
      const { type } = value;
      let { type: typeValue } = value;

      if (type === FIELD_TYPES.INPUT.EMAIL || type === FIELD_TYPES.CONSENT || type === FIELD_TYPES.DIVIDER) {
        typeValue = type;
      }

      if (map[typeValue] && typeValue !== FIELD_TYPES.DIVIDER) {
        setShowUniqueAlert(true);
      }

      map[typeValue] = true;
    });

    if (!map[FIELD_TYPES.INPUT.EMAIL] && values.length) {
      setShowNoEmailAlert(true);
    }
  };

  useEffect(() => {
    fields && checkContactFormFields(fields);
  }, [fields]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <FormField.Text<ProjectType>
              name="settings.contactForm.title"
              label="Contact form title"
              description=""
              useShadowValue
            />
            <FormField.Text<ProjectType>
              name="settings.contactForm.titleContacted"
              label="Contact form title (contacted)"
              description="Title to show if client has already contacted"
              useShadowValue
            />
            <FormField.Text<ProjectType> name="settings.contactForm.subtitle" label="Subtitle" useShadowValue />
            <FormField.Text<ProjectType>
              name="settings.contactForm.consentMessage"
              label="Contact form consent message"
              description=""
              useShadowValue
            />
            <FormField.Text<ProjectType>
              name="settings.contactForm.requestReceiverNotice"
              label="Notice about request receiver"
              useShadowValue
            />
            <FormField.Toggle<ProjectType>
              name="settings.contactForm.sendPdf"
              label="Send an email with a PDF file to clients instead of getting in contact"
              useShadowValue
            />
          </Col>

          <Col>
            <FormField.ArrayWrapper<ProjectType>
              name="settings.contactForm.fields"
              label="Fields"
              headerFormat={value => get(value, 'name', 'untitled')}
              useShadowValue
            >
              <FormField.Select<ContactFormFields>
                name="type"
                label="Type"
                description="Choose field type from the list"
                options={FIELDS}
                required
                onChange={contactFormTypeChangeHandler}
                useShadowValue
              />

              <ContactFormFieldsWrapper state={state} />
            </FormField.ArrayWrapper>
          </Col>
        </Row>
      </Container>
      {showUniqueAlert && (
        <Alert color="warning" className="my-2">
          This list requires field <strong>name</strong> to be unique. Please change the values
        </Alert>
      )}
      {showNoEmailAlert && (
        <Alert color="warning" className="my-2">
          Please add <strong>Email</strong> field to the list
        </Alert>
      )}
    </>
  );
};
