import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Button, RSForm, FormFeedback, FormGroup, Input, Label } from '../../Atoms';

const resetPasswordForm = b.with('reset-password-form');

class ResetPasswordForm extends React.PureComponent {
  state = {
    password: '',
    confirmPassword: '',
    passwordsDoNotMatch: false
  };

  get isDisabled() {
    const { request } = this.props;
    const { password, confirmPassword } = this.state;

    return password === '' || confirmPassword === '' || request;
  }

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { onSubmit } = this.props;
    const { password, confirmPassword } = this.state;

    const passwordsDoNotMatch = password !== confirmPassword;

    this.setState(prevState => ({
      passwordsDoNotMatch,
      confirmPassword: passwordsDoNotMatch ? '' : prevState.confirmPassword
    }));

    if (!passwordsDoNotMatch) {
      onSubmit(password);
    }
  };

  render() {
    const { password, confirmPassword, passwordsDoNotMatch } = this.state;

    return (
      <RSForm className={resetPasswordForm()} onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="password">New password</Label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            onChange={this.handleInputChange}
            value={password}
            invalid={passwordsDoNotMatch}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Confirm new password</Label>
          <Input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm password"
            onChange={this.handleInputChange}
            value={confirmPassword}
            invalid={passwordsDoNotMatch}
            required
          />
          <FormFeedback>Passwords do not match. Please try again</FormFeedback>
        </FormGroup>
        <Button color="primary" type="submit" disabled={this.isDisabled} block>
          Submit
        </Button>
      </RSForm>
    );
  }
}

ResetPasswordForm.defaultProps = {
  request: false
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  request: PropTypes.bool
};

export default ResetPasswordForm;
