import { ButtonGroup, Button, PageTile } from '../Atoms';
import Project from '../ProjectsPage/Project';
import { useSwitch } from '../../utils/hooks';
import ProjectPageWrapper from './ProjectPageWrapper';
import ProjectSettingsDashboard from './ProjectSettingsDashboard';

const ProjectSettingsPage = () => {
  const { isOpen: showNew, toggle } = useSwitch(false);

  return (
    <ProjectPageWrapper>
      {project => (
        <PageTile>
          <ButtonGroup>
            <Button onClick={toggle} outline={!showNew}>
              New Ui
            </Button>
            <Button onClick={toggle} outline={showNew}>
              Old Ui
            </Button>
          </ButtonGroup>
          {showNew && <ProjectSettingsDashboard project={project} />}
          {!showNew && <Project project={project} />}
        </PageTile>
      )}
    </ProjectPageWrapper>
  );
};

export default ProjectSettingsPage;
