// eslint-disable-next-line max-classes-per-file
import React from 'react';
import PropTypes from 'prop-types';
import { useUserRights } from '../../utils/hooks';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const UserRights = ({ children }) => children({ hasRights: useUserRights() });

UserRights.propTypes = {
  children: PropTypes.func.isRequired
};

// HOC
// eslint-disable-next-line react/no-multi-comp
export const withUserRights = TargetComponent => {
  class Component extends React.PureComponent {
    render() {
      return <UserRights>{({ hasRights }) => <TargetComponent {...this.props} hasRights={hasRights} />}</UserRights>;
    }
  }

  Component.displayName = `WithUserRights(${getDisplayName(TargetComponent)})`;

  return Component;
};

export default UserRights;
