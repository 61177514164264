import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import { Alert } from '../Atoms';
import { settingsActions, settingsSelectors } from '../../modules/settings';
import SettingsEditor from '../SettingsEditor';
import ProtectedButton from '../ProtectedButton';
import { RIGHTS } from '../../utils/rights';

const globalThemePage = b.with('global-theme-page');

export class GlobalThemePage extends React.PureComponent {
  state = { invalid: false, value: '' };

  componentDidMount() {
    this.props.fetchGlobalTheme();
  }

  get isDisabledButton() {
    return !this.state.value || this.state.invalid;
  }

  handleChange = value => {
    this.setState(prevState => ({ ...prevState, value }));
  };

  handleValid = invalid => {
    this.setState(prevState => ({ ...prevState, invalid }));
  };

  handleSaveTheme = () => {
    this.props.updateGlobalTheme(window.btoa(this.state.value));
    this.handleChange('');
  };

  render() {
    const { theme } = this.props;

    return (
      <div className={`${globalThemePage()} w-100 p-3 flex-grow-1`}>
        <Alert color="warning">
          <strong>Be careful!</strong> Values below are applied to all configurators as a default css, regardless of
          partner, project or seed
        </Alert>
        <div className="mb-3">
          <SettingsEditor value={theme} onChange={this.handleChange} onValid={this.handleValid} type="theme" />
        </div>
        <ProtectedButton
          color="primary"
          onClick={this.handleSaveTheme}
          rights={[RIGHTS.GLOBAL_THEME__UPDATE]}
          disabled={this.isDisabledButton}
        >
          Save CSS theme
        </ProtectedButton>
      </div>
    );
  }
}

GlobalThemePage.defaultProps = {
  theme: ''
};

GlobalThemePage.propTypes = {
  fetchGlobalTheme: PropTypes.func.isRequired,
  updateGlobalTheme: PropTypes.func.isRequired,
  theme: PropTypes.string
};

const mapStateToProps = state => ({
  theme: settingsSelectors.selectParsedGlobalTheme(state)
});

const mapDispatchToProps = {
  fetchGlobalTheme: settingsActions.fetchGlobalTheme,
  updateGlobalTheme: settingsActions.updateGlobalTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalThemePage);
