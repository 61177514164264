import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import saveAs from 'file-saver';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, PdfViewer as PDFWrapper } from '../Atoms';
import api from '../../utils/api';
import Loader from '../Loader';

import './PDFViewer.scss';

const pdfViewer = b.with('pdf-viewer');

class PDFViewer extends React.PureComponent {
  state = {
    request: true,
    src: '',
    error: 'Not found',
    blob: null
  };

  componentDidMount() {
    this.getPDF();
  }

  async getPDF() {
    const { id, signed } = this.props;

    const { data, error } = await api.instances.getPDF(id, signed);

    if (data) {
      const src = URL.createObjectURL(data);

      this.setState({ request: false, src, blob: data });
    } else if (error) {
      this.setState({ request: false, error: error.message });
    }
  }

  handleSaveDocument = () => {
    const { id, signed } = this.props;

    saveAs(this.state.blob, `${signed ? 'signed ' : ''}order ${id}.pdf`);
  };

  render() {
    const { request, src, error, blob } = this.state;
    const { onClose } = this.props;

    return (
      <Modal isOpen toggle={onClose} size="lg" centered className={pdfViewer()}>
        <ModalHeader toggle={onClose}>Pricing offer</ModalHeader>
        <ModalBody className={pdfViewer('body')}>
          <Loader loading={request}>
            {src ? <PDFWrapper src={src} /> : <span className="text-danger">{error}</span>}
          </Loader>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSaveDocument} disabled={!blob}>
            Save file
          </Button>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

PDFViewer.defaultProps = {
  signed: false
};

PDFViewer.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  signed: PropTypes.bool
};

export default PDFViewer;
