import { FC, useCallback, useState } from 'react';
import { TemplateType } from 'types';
import { FormType } from '../Atoms';
import { useUpdateTemplate } from '../../store/template';
import TemplatePageWrapper from './TemplatePageWrapper';
import TemplateSourceForm from './TemplateSourceForm';
import TemplatePreview from './TemplatePreview';

const TemplateSourcePage: FC = () => {
  const [localTemplate, setLocalTemplate] = useState<Partial<TemplateType>>();

  const { updateTemplate } = useUpdateTemplate();

  const handleSubmit = useCallback(
    (formValues: TemplateType) => {
      updateTemplate(formValues);
    },
    [updateTemplate]
  );

  const handleChangeFormValues = useCallback<FormType.HandlerChangeFormValues<TemplateType>>(formValues => {
    setLocalTemplate(formValues as TemplateType);
  }, []);

  return (
    <TemplatePageWrapper>
      {(template, partnerId, templateId, source) => (
        <TemplateSourceForm
          template={template}
          templateSource={source}
          handleSubmit={handleSubmit}
          handleChangeFormValues={handleChangeFormValues}
        >
          <TemplatePreview partnerId={partnerId} template={localTemplate} type={source} />
        </TemplateSourceForm>
      )}
    </TemplatePageWrapper>
  );
};

export default TemplateSourcePage;
