import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import * as objectPath from 'object-path';
import Select from '../Select';
import MultiSelect from '../MultiSelect';

/*
  SpySelect acts like a regular Select/MultiSelect, only difference is that options are generated by some other array in the same form
  If it's not in the same form then it's not needed.
*/
const SpySelect = props => {
  const { source, sourceField, isMulti, ...rest } = props;

  return (
    <Field name={source}>
      {({ input }) => {
        const value = Array.isArray(input.value) ? input.value : [];
        let options;

        if (sourceField) {
          options = value.map(item => {
            const itemValue = objectPath.get(item, sourceField.value);
            const itemLabel = objectPath.get(item, sourceField.label || sourceField.value);

            return { label: itemLabel, value: itemValue };
          });
        } else {
          options = value.map(item => ({ label: item, value: item }));
        }
        const Component = isMulti ? MultiSelect : Select;

        return <Component {...rest} options={options} />;
      }}
    </Field>
  );
};

SpySelect.propTypes = {
  source: PropTypes.string.isRequired, // array name whose values to search
  sourceField: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired
  }), // if fieldname is defined, it expects array to consist of objects and fieldName is the key
  isMulti: PropTypes.bool // if isMulti, will render MultiSelect instead of regular select
};

SpySelect.defaultProps = {
  sourceField: undefined,
  isMulti: false
};

export default SpySelect;
