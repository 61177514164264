import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import b from 'b_';
import { InstanceType } from 'types';
import { RIGHTS } from '../../../utils/rights';
import DeleteRequestButton from '../../DeleteRequestButton';
import ProtectedButton from '../../ProtectedButton';
import { authSelectors } from '../../../modules/auth';
import { instancesActions } from '../../../modules/instances';
import FileUpload from '../../FileUpload';
import api from '../../../utils/api';
import PDFViewer from '../../PDFViewer';
import OpenConfiguratorLink from '../../OpenConfiguratorLink';
import LegalesignSigner from './LegalesignSigner';
import SendToProduction from './SendToProduction';
import InstanceEdit from './InstanceEdit';
import MappedInstance from './MappedInstance';

import './InstanceDetails.scss';

const instanceDetails = b.with('instance-details');

interface InstanceDetailsProps {
  instance: InstanceType;
}

interface ActionsProps {
  instance: InstanceType;
  hasLead: boolean;
  makeLeadPrimary: () => void;
  setUploadPdf: (b: boolean) => void;
  setEditing: (b: boolean) => void;
  setSignedPdfModal: (b: boolean) => void;
  setPdfModal: (b: boolean) => void;
  hasPdfFile: boolean;
  isSendToProductionAvailable: boolean;
}

const Actions = ({
  hasLead,
  makeLeadPrimary,
  setUploadPdf,
  setSignedPdfModal,
  setPdfModal,
  setEditing,
  isSendToProductionAvailable,
  instance,
  hasPdfFile
}: ActionsProps) => (
  <div className={instanceDetails('actions')}>
    {hasLead ? (
      <>
        <ProtectedButton
          rights={[RIGHTS.VISITORS__MAKE_LEAD_PRIMARY]}
          onClick={makeLeadPrimary}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-fw fa-bookmark" />
          Make Primary
        </ProtectedButton>
        <ProtectedButton
          rights={[RIGHTS.VISITORS__READ_PDF]}
          onClick={() => setPdfModal(true)}
          size="sm"
          color="info"
          outline
        >
          <i className="fa fa-fw fa-file-pdf-o" />
          Open PDF
        </ProtectedButton>
        <ProtectedButton
          rights={[RIGHTS.VISITORS__UPLOAD_SIGNED_PDF]}
          onClick={() => setUploadPdf(true)}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-fw fa-upload" />
          Upload signed PDF
        </ProtectedButton>
        {hasPdfFile ? (
          <ProtectedButton
            rights={[RIGHTS.VISITORS__READ_SIGNED_PDF]}
            onClick={() => setSignedPdfModal(true)}
            size="sm"
            color="info"
            outline
          >
            <i className="fa fa-fw fa-download" />
            Open signed PDF
          </ProtectedButton>
        ) : null}
        <DeleteRequestButton instance={instance} />
        <ProtectedButton
          rights={[RIGHTS.VISITORS__UPDATE]}
          onClick={() => setEditing(true)}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-edit fa-fw" />
          Edit
        </ProtectedButton>
        {isSendToProductionAvailable ? <MappedInstance instance={instance} /> : null}
      </>
    ) : null}
  </div>
);

const InstanceDetails = ({ instance }: InstanceDetailsProps) => {
  const dispatch = useDispatch();

  const { lead } = instance;
  const hasLead = !!lead?.firstName;
  const hasPdfFile = !!lead?.pdfFileId;

  const isLegaleSignAvailable = useSelector(authSelectors.selectUserIsLegaleSignAvailable) && hasLead;
  const isSendToProductionAvailable = useSelector(authSelectors.selectUserRequestsShowSendToProduction) && hasPdfFile;

  const makeLeadPrimary = useCallback(() => {
    dispatch(instancesActions.makeLeadPrimary(instance._id));
  }, [dispatch, instance._id]);

  const signedPDFUploaded = useCallback(
    (result: InstanceType[]) => {
      dispatch(instancesActions.signedPDFUploaded(result[0]));
    },
    [dispatch]
  );

  const [pdfModal, setPdfModal] = useState(false);
  const [signedPdfModal, setSignedPdfModal] = useState(false);
  const [uploadPdf, setUploadPdf] = useState(false);
  const [editing, setEditing] = useState(false);

  return (
    <>
      <div className={instanceDetails()}>
        <Actions
          makeLeadPrimary={makeLeadPrimary}
          hasLead={hasLead}
          hasPdfFile={hasPdfFile}
          setPdfModal={setPdfModal}
          setSignedPdfModal={setSignedPdfModal}
          setEditing={setEditing}
          instance={instance}
          setUploadPdf={setUploadPdf}
          isSendToProductionAvailable={isSendToProductionAvailable}
        />
        <div className={instanceDetails('details')}>
          {/** Instance details */}
          <h5 className="text-primary">Lead details</h5>
          <dl className="d-flex flex-wrap">
            <dt className={instanceDetails('label')}>Lead id</dt>
            <dd className={instanceDetails('info')}>
              <OpenConfiguratorLink type="id" link={instance._id} caption={instance._id} icon="" />
            </dd>
            <dt className={instanceDetails('label')}>Lead name</dt>
            <dd className={instanceDetails('info')}>{instance.instanceName}</dd>
            <dt className={instanceDetails('label')}>Created</dt>
            <dd className={instanceDetails('info')}>{new Date(instance.createdAt).toLocaleString()}</dd>
            <dt className={instanceDetails('label')}>Modified</dt>
            <dd className={instanceDetails('info')}>{new Date(instance.updatedAt).toLocaleString()}</dd>
            <dt className={instanceDetails('label')}>PDF notes</dt>
            <dd className={instanceDetails('info')}>{instance.pdfNotes}</dd>
          </dl>
          {/** Lead details */}
          {lead ? (
            <dl className="d-flex flex-wrap">
              <dt className={instanceDetails('label')}>Request name</dt>
              <dd className={instanceDetails('info')}>{`${lead.firstName} ${lead.lastName}`}</dd>
              <dt className={instanceDetails('label')}>Request email</dt>
              <dd className={instanceDetails('info')}>{lead.email}</dd>
              <dt className={instanceDetails('label')}>Request message</dt>
              <dd className={instanceDetails('info')}>{lead.message}</dd>
              <dt className={instanceDetails('label')}>Downloaded PDF</dt>
              <dd className={instanceDetails('info')}>
                <span className={`fa fa-fw ${lead.downloadedPDF ? 'fa-check text-success' : 'fa-ban text-info'}`} />
              </dd>
            </dl>
          ) : null}

          {/** Features */}
          <div className={instanceDetails('features')}>
            {isLegaleSignAvailable ? (
              <div className={instanceDetails('signature')}>
                <LegalesignSigner instance={instance} />
              </div>
            ) : null}
            {isSendToProductionAvailable ? (
              <div className={instanceDetails('production')}>
                <SendToProduction instance={instance} />
              </div>
            ) : null}
          </div>
        </div>
        {instance._id && signedPdfModal ? (
          <PDFViewer id={instance._id} signed onClose={() => setSignedPdfModal(false)} />
        ) : null}
        {instance._id && pdfModal ? (
          <PDFViewer id={instance._id} signed={false} onClose={() => setPdfModal(false)} />
        ) : null}
        {uploadPdf ? (
          <FileUpload
            onSuccess={signedPDFUploaded}
            onClose={() => setUploadPdf(false)}
            api={api.instances.uploadSignedPDF(instance._id)}
            caption="Upload signed PDF"
            mimeTypes={['application/pdf']}
          />
        ) : null}
        {editing ? <InstanceEdit instance={instance} onClose={() => setEditing(false)} /> : null}
      </div>
    </>
  );
};

export default InstanceDetails;
