import { useCallback, useMemo, useState, MouseEventHandler } from 'react';
import filesize from 'filesize';
import { AssetType, AssetKindType } from 'types';
import { Column } from 'react-table';
import { Table } from '../../Atoms';
import Loader from '../../Loader';
import { useModal } from '../../../utils/hooks';
import AssetModal from '../AssetModal';
import { ASSET_TYPES } from '../../../modules/assets';
import { GetAssetUrlType } from '../../../utils/api/makeAssetUrl';

interface AssetGroupProps {
  list: AssetType[];
  onDeleteAsset: (id: string, unsafe?: string) => void;
  getAssetUrl: GetAssetUrlType;
  assetType: AssetKindType;
}

function AssetGroup({ list, onDeleteAsset, getAssetUrl, assetType }: AssetGroupProps) {
  const { isOpen, open, close } = useModal();
  const [selectedAsset, selectAsset] = useState('');

  const latestMap = useMemo(() => {
    const latest: Record<string, number> = {};

    list.forEach(asset => {
      const uploadDate = Date.parse(asset.uploadDate); // timestamp

      if (!latest[asset.filename]) latest[asset.filename] = uploadDate;

      if (uploadDate > latest[asset.filename]) latest[asset.filename] = uploadDate;
    });

    return latest;
  }, [list]);

  const currentAsset = list.find(asset => asset._id === selectedAsset);

  const handleAssetClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      const { assetId } = e.currentTarget.dataset;

      if (assetId) {
        selectAsset(assetId);
        open();
      }
    },
    [open]
  );

  const columns: Column<AssetType>[] = useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        Cell: ({ row, value }) => {
          const timestamp = Date.parse(row.original.uploadDate);

          const isPrevious = timestamp !== latestMap[value];
          const className = `btn btn-link ${isPrevious ? 'btn-sm text-muted text-decoration-none' : ''}`;

          return assetType === ASSET_TYPES.MODELS ? (
            <a
              className={className}
              title="Download"
              href={getAssetUrl({ fileName: value, projectId: row.original?.metadata?.projectId })}
              download
            >
              {value}
            </a>
          ) : (
            <button
              type="button"
              className={className}
              data-asset-id={row.original?._id}
              onClick={handleAssetClick}
              title="Preview"
            >
              {value}
            </button>
          );
        }
      },
      {
        Header: 'Size',
        accessor: 'length',
        disableFilters: true,
        Cell: ({ value }) => <span title={String(value)}>{filesize(value)}</span>
      },
      {
        Header: 'Uploaded',
        accessor: 'uploadDate',
        disableFilters: true,
        Cell: ({ value }) => value && new Date(value).toLocaleString()
      }
    ],
    [assetType, getAssetUrl, handleAssetClick, latestMap]
  );

  return (
    <Loader loading={list.length === 0}>
      <Table columns={columns} data={list} />
      {isOpen && currentAsset ? (
        <AssetModal
          assetUrl={getAssetUrl({ fileName: currentAsset.filename, projectId: currentAsset?.metadata?.projectId })}
          assetIdUrl={getAssetUrl({ fileName: currentAsset._id, projectId: currentAsset?.metadata?.projectId })}
          asset={currentAsset}
          onDeleteAsset={onDeleteAsset}
          onClose={close}
        />
      ) : null}
    </Loader>
  );
}

export default AssetGroup;
