import { NOTIFICATION_TYPES } from '../../utils/propTypes';

export const NOTIFICATION__SHOW = 'NOTIFICATION__SHOW';
export const NOTIFICATION__HIDE = 'NOTIFICATION__HIDE';
export const VISITOR_LIST__EXPAND = 'VISITOR_LIST_EXPAND';
export const VISITOR_LIST__SELECT_INSTANCE = 'VISITOR_LIST_SELECT_INSTANCE';

export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';

export function showErrorNotification(message, details) {
  return showNotification(message, NOTIFICATION_TYPES.ERROR, 10000, details);
}

export function showSuccessNotification(message) {
  return showNotification(message, NOTIFICATION_TYPES.SUCCESS, 3000);
}

export function showNotification(message, type, timeout, details) {
  return {
    type: NOTIFICATION__SHOW,
    payload: {
      message,
      details,
      type,
      timeout
    }
  };
}

export function visitorListExpand(expanded) {
  return {
    type: VISITOR_LIST__EXPAND,
    payload: {
      expanded
    }
  };
}

export function visitorListSelectInstance(visitor, instance) {
  return {
    type: VISITOR_LIST__SELECT_INSTANCE,
    payload: {
      visitor,
      instance
    }
  };
}

export function hideNotification(id) {
  return {
    type: NOTIFICATION__HIDE,
    payload: {
      id
    }
  };
}

export const setWindowSize = (width, height) => ({
  type: SET_WINDOW_SIZE,
  payload: { width, height }
});
