import { cloneElement, ReactElement } from 'react';

function getTransformedChildren(
  name: string,
  index: number,
  childrenArray: ReactElement[],
  customProperties: Record<string, any> = {}
) {
  return childrenArray.map(child => {
    const childName = `${name}.${child.props.name}`;

    /* If child is a Form.Group, we need to do the reassignment later inside Group's children */

    return cloneElement(child, {
      name: childName,
      id: childName,
      parentName: name,
      isWithinArray: true,
      index,
      ...customProperties
    });
  });
}

export default getTransformedChildren;
