import React from 'react';
import PropTypes from 'prop-types';
import { Field as FField } from 'react-final-form';
import { Alert } from '../../Atoms';
import Form from '../../Form';
import Field from '../../Form/Field';
import { makeSuggestions } from '../../../utils';
import { useAssetsActions } from '../../../utils/hooks';
import ShareView from './ShareView';
import CtaSettings from './CTASettings';
import ContactFormSettings from './ContactFormSettings';
import PriceSchemes from './PriceSchemes';

/*
  Gets value of current form's given field with possible key for array-object values
*/

const SUPPORTED_CURRENCY_LIST = [
  { label: 'Euro (€)', value: '€' },
  { label: 'Dollar ($)', value: '$' },
  { label: 'Swiss Frank (CHf)', value: 'CHf' },
  { label: 'Pound (£)', value: '£' }
];

// icons from Configurator icon module
const ICONS = [
  'alert',
  'format-list-numbered',
  'cash-multiple',
  'chevron-up',
  'chevron-double-up',
  'chevron-down',
  'chevron-double-down',
  'chevron-right',
  'chevron-double-right',
  'chevron-left',
  'chevron-double-left',
  'chevron-down-up',
  'chevron-up-down',
  'close',
  'cogs',
  'cube',
  'floor-plan',
  'fullscreen',
  'fullscreen-exit',
  'loading',
  'home',
  'home-outline',
  'menu',
  'minus-circle',
  'open-in-new',
  'plus-circle',
  'target',
  'menu-dots',
  'menu-down',
  'menu-right',
  'check',
  'check-bold',
  'save',
  'trash',
  'warning',
  'open-with',
  'content-copy',
  'cancel',
  'cursor-move',
  'rotate-left',
  'undo',
  'bedroom',

  // Share dialog icons
  'facebook',
  'messenger',
  'twitter',
  'whatsapp',
  'mail',
  'web-share',
  'unfold-less-horizontal',
  'unfold-more-horizontal',
  'co2'
];
const iconOptions = [
  ...ICONS.map(icon => ({ value: icon, label: icon })),
  { value: '_custom', label: 'Custom - choose image from assets' }
];

const METRICS_UNIT_OPTIONS = [
  { value: 'm', label: 'm' },
  { value: 'kg', label: 'kg' },
  { value: 'mm', label: 'mm' },
  { value: 'cm', label: 'cm' },
  { value: 'm²', label: 'm²' },
  { value: 'in', label: 'in' },
  { value: 'ft', label: 'ft' },
  { value: 'ft²', label: 'ft²' },
  { value: 's', label: 's' },
  { value: '€', label: '€' },
  { value: '£', label: '£' },
  { value: '$', label: '$' }
];

const Settings = ({ header, projectId, seedId, useShadowValue }) => {
  const {
    projectImages,
    projectTextures,
    uploadImage,
    uploadTexture,
    updateImagesAfterUpload,
    updateTexturesAfterUpload
  } = useAssetsActions(projectId);

  return (
    <>
      <h5 className="text-primary">{header}</h5>
      <Form.Group header="General configuration" icon="fa-cog">
        <Field.ArrayObject
          name="settings.optionStatuses"
          label="Option statuses"
          description="Option statuses defined here can be used in option definitions and remote controls to change how options are shown in the Configurator"
          uniqueField="name"
          defaultNew={{
            name: 'untitled-option-status',
            label: 'Untitled status'
          }}
          useShadowValue={useShadowValue}
        >
          <Field.Text
            name="name"
            label="Name"
            description="Name which the status is referred by elsewhere. Must be unique"
          />
          <Field.Text
            name="label"
            label="Label"
            description="Label which is displayed in Option and Remote Controls panel"
          />
          <Field.Text name="description" label="Description" description="Additional hints" />
          <Field.Toggle
            name="forceReselectState"
            label="Force reselect"
            description="Does this option force reselection of control if it's disabled"
          />
          <Field.Toggle name="disabledState" label="Disable" description="Disable option and remove it from ui" />
          <Field.Toggle
            name="lockedState"
            label="Lock"
            description="Locked options are visible, muted and not selectable"
          />
          <Field.Toggle
            name="inactiveState"
            label="Inactivate"
            description="Inactive options are visible, muted but selectable"
          />
          <Field.Toggle
            name="priceHiddenState"
            label="Hide price"
            description="Hide price of this option in Configurator"
          />
          <Field.Toggle
            name="areaHiddenState"
            label="Hide area"
            description="Hide area of this option in Configurator"
          />
          <Field.Toggle
            name="optionHintHiddenState"
            label="Hide option hint"
            description="Hide hint of this option in Configurator"
          />
        </Field.ArrayObject>

        <Field.ArrayObject
          name="settings.extraInfoCollections"
          label="Extra info collections"
          uniqueField="name"
          defaultNew={{ name: 'Untitled collection' }}
          description="These collections can be used for options' and 3D sprites' more info panels"
          useShadowValue={useShadowValue}
        >
          <Field.Text name="name" label="Name" description="Unique name for the collection" required />
          <Field.ArrayObject
            name="infoPanels"
            label="Image and description panels"
            description="These will be shown in a gallery with descriptive texts"
          >
            <Field.Text name="name" label="Name" description="" />
            <Field.Markdown name="text" label="Content" description="" classes={{ preview: 'markdown-content' }} />
            <Field.Text name="caption" label="Caption" description="" />
            <Field.Image
              name="image.fileName"
              label="Image"
              description=""
              list={projectImages}
              uploadImage={uploadImage}
              onUploadSuccess={updateImagesAfterUpload}
            />
            <Field.Toggle
              name="image.isPanorama"
              label="Panorama"
              description="Loads panorama viewer for equirectangular images."
            />
          </Field.ArrayObject>
          <Field.Text
            name="externalLink.label"
            label="External link label"
            description={
              <span>
                This is used in <strong>imagesFullscreenControls</strong>.
              </span>
            }
          />
          <Field.Text
            name="externalLink.url"
            label="External link url"
            description={
              <span>
                This is used in <strong>imagesFullscreenControls</strong>.
              </span>
            }
          />
        </Field.ArrayObject>
      </Form.Group>

      <Form.Group header="Interface settings" icon="fa-columns">
        <Field.ArrayObject
          uniqueField="name"
          name="settings.translations"
          label="Translations"
          description="Here add various translations for different languages. Base language is English."
          defaultNew={{ name: 'New language', disabled: false, definitions: [] }}
          useShadowValue={useShadowValue}
        >
          <Field.Text name="name" label="Language" description="Must be unique" required />
          <Field.Toggle name="disabled" label="Disable language" />
          <Field.ArrayObject name="definitions" label="Definitions">
            <Field.Text name="key" label="Original" />
            <Field.Text name="value" label="Translation" />
          </Field.ArrayObject>
        </Field.ArrayObject>

        <Field.Toggle
          name="settings.interface.aboutTabHidden"
          label="Hide About page"
          useShadowValue={useShadowValue}
        />
        <Field.Toggle
          name="settings.interface.reviewTabHidden"
          label="Hide Review step"
          useShadowValue={useShadowValue}
        />
        <Field.Toggle
          name="settings.interface.summaryTabHidden"
          label="Hide Summary page"
          useShadowValue={useShadowValue}
        />

        <Field.Toggle
          name="settings.comments.enabled"
          label="Enable commenting"
          description="Experimental feature"
          useShadowValue={useShadowValue}
        />
        <Form.Group header="Pricing (deprecated)" icon="fa-eur">
          <Field.Toggle
            name="settings.price.usePriceCodes"
            label="Use pricecodes"
            description="Enable using pricecodes for this seed. If defined here not in root part settings, option prices will not be remotely controllable as a side effect. Overrides root part settings. This will also enable pricing schemes"
            useShadowValue={useShadowValue}
          />

          <Alert color="info">
            Following definitions define the <b>&quot;default&quot;</b> pricing scheme
          </Alert>

          <Field.Array
            component={Field.Text}
            name="settings.interface.priceCaveat"
            label="Price Caveat"
            description="Paragraphs to show additional information about price"
            useShadowValue={useShadowValue}
          />
          <Field.Select
            name="settings.interface.currency"
            label="Currency"
            options={SUPPORTED_CURRENCY_LIST}
            description="Pick one from the list or type a custom symbol"
            creatable
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.interface.currencyFormat"
            label="Currency format"
            description="Use hashes to format currency like ####.##"
            useShadowValue={useShadowValue}
          />
          <Field.Number
            name="settings.interface.priceRounding"
            label="Price rounding"
            description="Price will be a multiple of this value"
            useShadowValue={useShadowValue}
          />
          <Field.Toggle
            name="settings.interface.summaryPriceHidden"
            label="Hide Total Price from Summary"
            useShadowValue={useShadowValue}
          />
          <Field.ArrayObject
            name="settings.price.schemes"
            label="Price schemes"
            description="Price schemes which enable overriding values defined above"
            useShadowValue={useShadowValue}
            defaultNew={{ name: 'untitled' }}
          >
            <Field.Text name="name" label="Unique name" description={'Must be unique and not "default"'} required />
            <Field.Text name="displayName" label="Display name" />
            <Field.Text name="description" label="Description" />
            <Field.Textarea rows={4} name="caveat" label="Caveat" description="Additional information about price" />
            <Field.Select
              name="currency"
              label="Currency"
              options={SUPPORTED_CURRENCY_LIST}
              description="Pick one from the list or type a custom symbol"
              creatable
            />
            <Field.Text
              name="currencyFormat"
              label="Currency format"
              description="Use hashes to format currency like ####.##"
            />
            <Field.Number
              name="priceRounding"
              label="Price rounding"
              description="Price will be a multiple of this value"
            />
            <Field.Toggle name="disabled" label="Disable" />
          </Field.ArrayObject>
        </Form.Group>
        <Form.Group header="Index page" icon="fa-television">
          <Field.ArrayObject
            name="settings.intro"
            label="Intro settings"
            description="Add different settings to each used language. Must have at least one"
            useShadowValue={useShadowValue}
          >
            <Field.SpySelect
              source="settings.translations"
              sourceField={{ value: 'name', label: 'name' }}
              name="language"
              label="language"
              description="Select language from previously defined interface languages list"
              creatable
              clearable
            />
            <Field.Text name="title" label="Intro title" description="" />
            <Field.Markdown
              name="content"
              label="Intro content"
              description=""
              classes={{ preview: 'markdown-content' }}
            />
            <Field.ArrayObject name="images" label="Images" description="Shown in gallery">
              <Field.Image
                name="fileName"
                label="Filename"
                list={projectImages}
                uploadImage={uploadImage}
                onUploadSuccess={updateImagesAfterUpload}
              />
              <Field.Toggle name="isPanorama" label="Panorama" />
            </Field.ArrayObject>
            <Field.Text
              name="introVideo"
              label="Video"
              description="Url of intro video to show instead of the gallery"
            />
          </Field.ArrayObject>
          <Field.Toggle name="settings.interface.hideGallery" label="Hide gallery" useShadowValue={useShadowValue} />
        </Form.Group>
        <Form.Group header="Summary page" icon="fa-info">
          <Field.Text
            name="settings.summary.title"
            label="Summary title"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Textarea
            name="settings.summary.text"
            label="Summary paragraph"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Array
            component={Field.Image}
            componentProps={{ list: projectImages, uploadImage, onUploadSuccess: updateImagesAfterUpload }}
            internalFieldName="fileName"
            name="settings.summary.gallery"
            label="Images"
            description="Shown in gallery"
            useShadowValue={useShadowValue}
          />
        </Form.Group>
        <CtaSettings useShadowValue={useShadowValue} seedId={seedId} />
        <Form.Group header="Header" icon="fa-window-maximize">
          <Field.Text name="settings.interface.header" label="Header label" useShadowValue={useShadowValue} />
          <Field.Image
            name="settings.interface.headerIcon"
            label="Header icon"
            description="Preferably svg image to show as logo next to header"
            list={projectImages}
            header="Select header icon"
            uploadImage={uploadImage}
            onUploadSuccess={updateImagesAfterUpload}
            useShadowValue={useShadowValue}
          />
          <Field.ArrayObject
            name="settings.sisterConfigurators"
            label="Sister configurators"
            description="List of related Configurators which are linked to this one."
            useShadowValue={useShadowValue}
          >
            <Field.Text name="displayName" label="Label" required />
            <Field.Text name="link" label="Link" required />
          </Field.ArrayObject>
        </Form.Group>

        <Form.Group header="Footer" icon="fa-window-minimize">
          <Field.ArrayObject name="settings.footer.partnerLinks" label="Links" useShadowValue={useShadowValue}>
            <Field.Text name="label" label="Label" required />
            <Field.Text name="url" label="Url" required />
          </Field.ArrayObject>
          <Field.Toggle
            name="settings.footer.poweredByDisabled"
            label="Disable 'Powered by Creatomus'"
            description="This setting removes references to Creatomus. Don't use on embedded models."
            useShadowValue={useShadowValue}
          />
        </Form.Group>
        <Form.Group header="Share settings" icon="fa-share-alt">
          <Field.Image
            name="settings.interface.favicon"
            label="Favicon"
            description="The optimal size is 32x32 pixels"
            list={projectImages}
            header="Select favicon"
            uploadImage={uploadImage}
            onUploadSuccess={updateImagesAfterUpload}
            useShadowValue={useShadowValue}
          />
          <Field.Image
            name="settings.sharing.image"
            label="Share image"
            description="Default image to show when sharing"
            list={projectImages}
            uploadImage={uploadImage}
            onUploadSuccess={updateImagesAfterUpload}
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.sharing.title"
            label="Share title"
            description="Default title to show when sharing"
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.sharing.description"
            label="Share description"
            description="Default text to show when sharing"
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.sharing.twitter"
            label="Twitter share description"
            description="Default text to show when sharing to Twitter"
            useShadowValue={useShadowValue}
          />
          <ShareView seedId={seedId} useShadowValue={useShadowValue} />
        </Form.Group>
      </Form.Group>

      <Form.Group header="Prices" icon="fa-eur">
        <PriceSchemes useShadowValue={useShadowValue} supportedCurrencyList={SUPPORTED_CURRENCY_LIST} />
      </Form.Group>

      <Form.Group header="Model settings" icon="fa-eye" label="" description="">
        <Field.Toggle
          name="settings.model.forceFetchingAssets"
          label="Force fetching assets"
          description={
            <span>
              Will use <strong>updatedAt</strong> field when fetching assets to ignore old cache
            </span>
          }
          useShadowValue={useShadowValue}
        />
        <Field.Number
          name="settings.model.maxInstanceCount"
          label="Max instance count"
          useShadowValue={useShadowValue}
        />
        <Field.Select
          name="settings.model.modelUnits"
          options={makeSuggestions(['metric', 'imperial'])}
          label="Model units"
          description=""
          useShadowValue={useShadowValue}
        />
        <Field.ArrayObject
          name="settings.model.ppsqmLayers"
          label="Price per sq unit layers"
          description="List of layer names to calculate price per square unit from"
          defaultNew={{ layer: 'New layer', ppsqm: 0 }}
          useShadowValue={useShadowValue}
        >
          <Field.Text name="layer" label="Layer name" required />
          <Field.Number name="ppsqm" label="Price" description="Price per square unit" />
          <Field.Text name="priceCode_psq" label="Pricecode" description="Price code per square unit" />
        </Field.ArrayObject>

        <Field.Toggle
          name="settings.partAnimation.enabled"
          label="Enable part animation"
          description="Will animate changing parts"
          useShadowValue={useShadowValue}
        />
        <Form.Group header="Area settings" icon="fa-calculator">
          <Alert color="info">
            In order to show <b>Net Area</b> in <b>Summary</b> both layers and unit have to be set
          </Alert>
          <Field.Array
            component={Field.Text}
            internalFieldName="layer"
            name="settings.model.netAreaLayers"
            label="Net area layers"
            description="List of layer names to get geometry for area calculations from. Both Layers and Unit has to be set in order to show Net area"
            useShadowValue={useShadowValue}
          />
          <Field.Select
            name="settings.model.areaUnit"
            label="Area unit"
            creatable
            description="If this is not set, area is not shown"
            options={[
              {
                value: 'm2',
                label: 'Square meters (m2)'
              },
              {
                value: 'm²',
                label: 'Square meters (m²)'
              },
              {
                value: 'sq.m',
                label: 'Square meters (sq.m)'
              },
              {
                value: 'sq.ft',
                label: 'Square feet (sq.ft)'
              },
              {
                value: 'ft²',
                label: 'Square feet (ft²)'
              }
            ]}
            useShadowValue={useShadowValue}
          />
          <Field.Array
            component={Field.Text}
            name="settings.model.netAreaCaveat"
            label="Net area Caveat"
            description="Paragraphs to show additional information about net area"
            useShadowValue={useShadowValue}
          />
        </Form.Group>
        <Form.Group header="Environment settings" icon="fa-globe">
          <Field.Toggle
            name="settings.sectionCap.enabled"
            label="Enable section cap"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Rgb
            name="settings.sectionCap.color"
            label="Section cap color"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Toggle
            name="settings.skybox.enabled"
            label="Enable skybox"
            description="Will show background as defined by skybox texture"
            useShadowValue={useShadowValue}
          />
          <Field.Toggle
            name="settings.skybox.useAsEnvMap"
            label="Use skybox textures as environment map"
            description="Will enable reflections based on skybox texture"
            useShadowValue={useShadowValue}
          />
          <Field.Image
            name="settings.skybox.equirectangular.Texture.fileName"
            label="Skybox"
            description="Equirectangular texture for skybox"
            list={projectTextures}
            header="Select texture"
            uploadImage={uploadTexture}
            onUploadSuccess={updateTexturesAfterUpload}
            useShadowValue={useShadowValue}
          />
        </Form.Group>
        <Form.Group header="Metrics">
          <h3>Metrics</h3>
          <p>Area, price and bedrooms are counted by default, add items here to enable counting.</p>
          <Field.ArrayObject name="settings.metrics" label="Metrics">
            <Field.Text name="name" label="Name" required description="Unique name" useShadowValue={useShadowValue} />
            <Field.Text
              name="displayName"
              label="Label"
              description="Caption shown under value"
              useShadowValue={useShadowValue}
            />
            <Field.Select
              name="unit"
              label="Unit"
              description="Metrics unit like m², cm, kg. You can also define a custom one"
              useShadowValue={useShadowValue}
              creatable
              clearable
              options={METRICS_UNIT_OPTIONS}
            />
            <Field.Textarea name="caveat" label="Caveat" description="Additional information about the metric" />
            <FField name="caveat">
              {({ input, parentName }) =>
                input.value ? (
                  <Field.Text
                    name={`${parentName}.caveatTitle`}
                    label="Caveat Title"
                    description="Caption to show in summary panel"
                    required
                  />
                ) : null
              }
            </FField>

            <FField name="unit">
              {({ input, parentName }) =>
                input.value ? (
                  <Field.Toggle
                    name={`${parentName}.unitBeforeValue`}
                    label="Unit before value"
                    description="By default unit is after the value"
                  />
                ) : null
              }
            </FField>

            <Field.Text name="format" label="Format" description="Use hashes to format numbers like ####.##" />

            <Field.Select name="icon" label="Icon" clearable options={iconOptions} />
            <FField name="icon">
              {({ input, parentName }) => {
                return input.value === '_custom' ? (
                  <Field.Image
                    name={`${parentName}.image.fileName`}
                    label="Custom image for icon"
                    description=""
                    useShadowValue={useShadowValue}
                    list={projectImages}
                    uploadImage={uploadImage}
                    onUploadSuccess={updateImagesAfterUpload}
                  />
                ) : null;
              }}
            </FField>
          </Field.ArrayObject>
        </Form.Group>
      </Form.Group>
      <ContactFormSettings useShadowValue={useShadowValue} />
      <Form.Group header="Partner settings" icon="fa-handshake-o">
        <Field.Toggle
          name="settings.partner.requireEmailToSaveOrphanInstances"
          label="Require email to save orphan instances"
          description=""
          useShadowValue={useShadowValue}
        />
        <Form.Group header="Pdf settings" icon="fa-file-pdf-o">
          <Field.Text name="settings.pdf.caption" label="Pdf caption" description="" useShadowValue={useShadowValue} />
          <Field.Text
            name="settings.pdf.partnerName"
            label="Pdf partner name"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.pdf.development"
            label="Pdf development name"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Array
            name="settings.pdf.address"
            component={Field.Text}
            label="Pdf address"
            description="Add as many lines as needed"
            useShadowValue={useShadowValue}
          />
          <Field.Toggle
            name="settings.pdf.showNotes"
            label="Display notes in pdf"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Toggle
            name="settings.pdf.showFooter"
            label="Display footer in pdf"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Array
            name="settings.pdf.instruction"
            component={Field.Text}
            label="Instructions"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.pdf.customTotal"
            label="Custom total"
            description="Custom string for 'total' in pdf"
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.pdf.customNotes"
            label="Custom notes"
            description="Custom notes to add to pdf"
            useShadowValue={useShadowValue}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group header="Cookie settings" icon="fa-archive">
        <Field.Text
          name="settings.cookies.header"
          label="Cookie consent dialog header"
          description=""
          useShadowValue={useShadowValue}
        />
        <Field.Text
          name="settings.cookies.message"
          label="Cookie consent dialog message"
          description=""
          useShadowValue={useShadowValue}
        />
        <Field.Text
          name="settings.cookies.policyLink.text"
          label="Cookie consent policy link text"
          description=""
          useShadowValue={useShadowValue}
        />
        <Field.Text
          name="settings.cookies.policyLink.link"
          label="Cookie consent policy link label"
          description=""
          useShadowValue={useShadowValue}
        />
        <Field.Text
          name="settings.cookies.policyLink.url"
          label="Cookie consent policy link url"
          description=""
          useShadowValue={useShadowValue}
        />
      </Form.Group>
    </>
  );
};

Settings.propTypes = {
  header: PropTypes.string,
  projectId: PropTypes.string,
  seedId: PropTypes.string,
  useShadowValue: PropTypes.bool
};

Settings.defaultProps = {
  header: 'Settings',
  projectId: '',
  seedId: '',
  useShadowValue: false
};

export default Settings;
