import { FC } from 'react';
import { authSelectors } from '../../modules/auth';
import { useAppSelector } from '../../store/hooks';
import { Icon } from '../Atoms';

interface ConfiguratorLinkProps {
  type: 'id' | 'seed' | 'project';
  link: string;
  label?: string;
  className?: string;
}

const ConfiguratorLink: FC<ConfiguratorLinkProps> = ({ type, link, label, className, children }) => {
  const configuratorUrl = useAppSelector(authSelectors.selectUserConfiguratorUrl);

  const href = `${configuratorUrl}/${type}/${link}`;

  return (
    <a
      className={className || 'btn btn-link btn-sm p-0 text-truncate'}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title="Open in Configurator"
    >
      {children || (
        <>
          <u>{label || href}</u> <Icon type="mdiOpenInNew" scale={0.7} />
        </>
      )}
    </a>
  );
};

export default ConfiguratorLink;
