import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import b from 'b_';
import { VisitorType } from 'types';
import { CellProps, Column } from 'react-table';
import { instancesSelectors } from '../../../modules/instances';
import Loader from '../../Loader';
import SelectFilter from '../../SelectFilter';
import { getVisitorUrl } from '../../../utils/appRoutes';
import './VisitorList.scss';
import { Icon, Table } from '../../Atoms';
import { useInstancesList } from '../../InstancesPage/InstancesPage';

const visitorList = b.with('visitor-list');

const VISITOR_STATUSES = instancesSelectors.VISITOR_STATUS.map(status => ({ label: status, value: status }));

const columns: Column<VisitorType>[] = [
  {
    id: 'username',
    Header: 'Visitor',
    accessor: 'username',
    Cell: ({ row, value }) => <Link to={getVisitorUrl(row.original._id)}>{value}</Link>
  },
  {
    Header: 'Name in request',
    accessor: 'name'
  },
  {
    Header: 'Saved designs',
    accessor: row => row.instances.length,
    Cell: ({ value }: CellProps<VisitorType, number>) => String(value),
    disableFilters: true
  },
  {
    id: 'hasRequest',
    Header: 'Requests',
    accessor: row => row.instances.filter(instance => instance.lead).length,
    sortDescFirst: false,
    width: 30,
    maxWidth: 30,
    disableFilters: true,
    Cell: ({ row, value }: CellProps<VisitorType, number>) =>
      row.original.instances.some(instance => !!instance.lead) ? (
        <span title="Has a request">
          <Icon type="mdiFolderOutline" /> {value}
        </span>
      ) : null
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: ({ column }) => (
      <SelectFilter options={VISITOR_STATUSES} filter={column.filter} onChange={column.setFilter} />
    )
  }
];

const VisitorList = () => {
  const visitors = useSelector(instancesSelectors.selectVisitorList) as VisitorType[];

  const { loading } = useInstancesList();

  return (
    <Loader loading={loading}>
      <Table className={visitorList()} columns={columns} data={visitors} />
    </Loader>
  );
};

export default VisitorList;
