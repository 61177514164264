import React, { useCallback, useMemo, useState } from 'react';
import b from 'b_';
import ReactTable from 'react-table-6';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../Atoms';
import Loader from '../../Loader';
import { defaultFilterMethod, multiSelectFilterMethod } from '../../../utils/helpers/table';
import { invitationsActions, invitationsHooks, invitationsSelectors } from '../../../modules/invitations';
import { RIGHTS } from '../../../utils/rights';
import MultiSelectFilter from '../../MultiSelectFilter';
import SelectFilter from '../../SelectFilter';
import { useUserRights } from '../../../utils/hooks';

const invitationListStyle = b.with('invitation-list');

const INVITATION_STATUS = {
  DRAFT: 'draft',
  SENT: 'sent',
  REJECTED: 'rejected',
  REGISTERED: 'registered'
};

const badgeCellClassName = 'app-entity-list__cell_badge';

const renderStatusClassName = status => {
  if (status === INVITATION_STATUS.REJECTED) {
    return 'text-danger';
  }

  if (status === INVITATION_STATUS.REGISTERED) {
    return 'text-success';
  }

  return '';
};

const InvitationList = () => {
  const dispatch = useDispatch();
  const [filtered, setFiltered] = useState([
    { id: 'roles', value: [] },
    { id: 'projects', value: [] },
    { id: 'partner', value: 'All' }
  ]);
  const { request, list } = invitationsHooks.useInvitationList();
  const filterValues = useSelector(invitationsSelectors.selectInvitationFilterValues);
  const handleDeleteInvitation = useCallback(
    e => {
      const { id, username } = e.currentTarget.dataset;
      const response = window.confirm(`Are you sure that you want to delete invitation for "${username}"?`); // eslint-disable-line no-alert

      if (response) {
        dispatch(invitationsActions.deleteInvitation(id));
      }
    },
    [dispatch]
  );
  const handleRejectInvitation = useCallback(
    e => {
      const { id, username } = e.currentTarget.dataset;
      const response = window.confirm(`Are you sure that you want to reject invitation for "${username}"?`); // eslint-disable-line no-alert
      const invitation = list.find(item => item._id === id);

      if (response && invitation) {
        dispatch(invitationsActions.updateInvitation({ ...invitation, status: INVITATION_STATUS.REJECTED }));
      }
    },
    [dispatch, list]
  );
  const handleBadgeClick = useCallback(
    e => {
      const { value, key } = e.currentTarget.dataset;

      setFiltered(
        filtered.map(filter =>
          filter.id === key
            ? { ...filter, value: Array.isArray(filter.value) ? [...new Set([...filter.value, value])] : value }
            : filter
        )
      );
    },
    [filtered]
  );

  const renderBadgeCell = useCallback(
    row => {
      return row.value.sort().map(item => (
        <button
          type="button"
          key={item._id}
          className="app-badge app-badge_purple"
          onClick={handleBadgeClick}
          data-value={item._id}
          data-key={row.column.id}
        >
          {item.name}
        </button>
      ));
    },
    [handleBadgeClick]
  );
  const hasRights = useUserRights();

  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'username',
        maxWidth: 200,
        className: 'p-2 fw-bold'
      },
      {
        accessor: 'status',
        Header: 'Status',
        width: 90,
        className: 'p-2 text-capitalize',
        resizable: false,
        Cell: row => <span className={renderStatusClassName(row.original.status)}>{row.value.toLowerCase()}</span>
      },
      {
        id: 'createdAt',
        Header: 'Created',
        accessor: 'createdAt',
        width: 150,
        resizable: false,
        filterable: false,
        Cell: row => new Date(row.value).toLocaleString(),
        className: 'p-2 text-center'
      },
      {
        id: 'expiresAt',
        Header: 'Expires',
        accessor: 'expiresAt',
        width: 150,
        resizable: false,
        filterable: false,
        Cell: row => {
          const date = new Date(row.value);

          return (
            <span
              className={new Date() > date && row.original.status !== INVITATION_STATUS.REGISTERED ? 'text-danger' : ''}
            >
              {date.toLocaleString()}
            </span>
          );
        },
        className: 'p-2 text-center'
      },
      {
        id: 'partner',
        Header: 'Partner',
        accessor: 'populated.partner',
        className: badgeCellClassName,
        maxWidth: 170,
        Cell: renderBadgeCell,
        // eslint-disable-next-line react/prop-types
        Filter: ({ filter, onChange }) => (
          <SelectFilter options={filterValues.partner} filter={filter} onChange={onChange} />
        ),
        filterMethod: multiSelectFilterMethod
      },
      {
        id: 'roles',
        Header: 'Roles',
        accessor: 'populated.roles',
        className: badgeCellClassName,
        maxWidth: 170,
        Cell: renderBadgeCell,
        // eslint-disable-next-line react/prop-types
        Filter: ({ filter, onChange }) => (
          <MultiSelectFilter options={filterValues.roles} filter={filter} onChange={onChange} name="role" />
        ),
        filterMethod: multiSelectFilterMethod
      },
      {
        id: 'projects',
        Header: 'Projects',
        accessor: 'populated.projects',
        className: badgeCellClassName,
        maxWidth: 170,
        Cell: renderBadgeCell,
        // eslint-disable-next-line react/prop-types
        Filter: ({ filter, onChange }) => (
          <MultiSelectFilter options={filterValues.projects} filter={filter} onChange={onChange} name="project" />
        ),
        filterMethod: multiSelectFilterMethod
      },
      {
        Header: 'Message',
        accessor: 'message',
        resizable: false,
        filterable: false,
        sortable: false,
        className: 'text-pre-wrap'
      },
      {
        accessor: '_id',
        resizable: false,
        filterable: false,
        sortable: false,
        width: 35,
        className: 'p-2',
        show: hasRights([RIGHTS.INVITATIONS__UPDATE]),
        Cell: row =>
          row.original.status === INVITATION_STATUS.DRAFT || row.original.status === INVITATION_STATUS.SENT ? (
            <Button
              onClick={handleRejectInvitation}
              data-id={row.value}
              data-username={row.original.username}
              color="link"
              size="sm"
              className="p-0"
              title="Reject invitation"
            >
              <i className="fa fa-ban text-danger" />
            </Button>
          ) : null
      },
      {
        accessor: '_id',
        resizable: false,
        filterable: false,
        sortable: false,
        width: 35,
        className: 'p-2',
        show: hasRights([RIGHTS.INVITATIONS__DELETE]),
        Cell: row => (
          <Button
            onClick={handleDeleteInvitation}
            data-id={row.value}
            data-username={row.original.username}
            color="link"
            size="sm"
            className="p-0"
            title="Delete invitation"
          >
            <i className="fa fa-trash-o text-secondary" />
          </Button>
        )
      }
    ],
    [
      filterValues.partner,
      filterValues.projects,
      filterValues.roles,
      handleDeleteInvitation,
      handleRejectInvitation,
      hasRights,
      renderBadgeCell
    ]
  );

  return (
    <Loader loading={request}>
      <ReactTable
        data={list}
        pageSize={list.length}
        showPagination={false}
        columns={columns}
        filtered={filtered}
        defaultFilterMethod={defaultFilterMethod}
        onFilteredChange={setFiltered}
        defaultSorted={[{ id: 'createdAt', desc: true }]}
        filterable
        resizable={false}
        getTheadFilterThProps={() => ({ style: { position: 'inherit', overflow: 'inherit' } })}
        className={`${invitationListStyle()} app-entity-list -highlight`}
      />
    </Loader>
  );
};

export default InvitationList;
