import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { Button, RSForm, FormFeedback, FormGroup, Input, Label } from '../../Atoms';
import { authActions, authSelectors } from '../../../modules/auth';

const forgotPasswordForm = b.with('forgot-password-form');

export class ForgotPasswordFormComponent extends React.PureComponent {
  state = {
    email: ''
  };

  get hasError() {
    return Boolean(this.props.error);
  }

  get isDisabled() {
    return this.state.email === '';
  }

  handleEmailChange = e => {
    const { value } = e.target;

    this.setState({
      email: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { onSubmit } = this.props;
    const { email } = this.state;

    onSubmit(email, window.location.origin);
  };

  render() {
    const { error } = this.props;
    const { email } = this.state;

    return (
      <RSForm className={forgotPasswordForm()} onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="email" hidden>
            E-mail
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
            onChange={this.handleEmailChange}
            invalid={this.hasError}
            value={email}
            required
          />
          <FormFeedback>{error}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Button color="primary" type="submit" disabled={this.isDisabled} block>
            Submit
          </Button>
        </FormGroup>
        <span className="text-center fw-light d-block small">
          To reset your password, please enter your email address. There you will receive link to perform the reset
        </span>
      </RSForm>
    );
  }
}

ForgotPasswordFormComponent.defaultProps = {
  error: ''
};

ForgotPasswordFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  error: authSelectors.selectAuthError(state)
});

const mapDispatchToProps = {
  onSubmit: authActions.forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormComponent);
