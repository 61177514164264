import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { AssetKindType } from 'types';
import ASSET_TYPES, { assetTypes } from './assetTypes';

// eslint-disable-next-line import/prefer-default-export
export const useActiveAssetsType = () => {
  const location = useLocation();

  return useMemo(() => {
    let { type } = queryString.parse(location.search);

    if (Array.isArray(type)) [type] = type;

    return (type && assetTypes.includes(type as AssetKindType) ? type : ASSET_TYPES.IMAGES) as AssetKindType;
  }, [location.search]);
};
