import { CSSProperties, FC, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

interface FadeProps {
  in?: boolean;
  appear?: boolean;
  timeout?: number;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Fade: FC<FadeProps> = ({
  children,
  in: active,
  appear,
  timeout = 150,
  mountOnEnter,
  unmountOnExit,
  className,
  style
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition<HTMLDivElement>
      nodeRef={nodeRef}
      in={active}
      appear={appear}
      timeout={timeout}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      classNames={{
        appear: 'fade',
        appearDone: 'fade show',
        enterDone: 'fade show',
        exit: 'fade'
      }}
    >
      <div ref={nodeRef} className={`fade${className ? ` ${className}` : ''}`} style={style}>
        {children}
      </div>
    </CSSTransition>
  );
};

export default Fade;
