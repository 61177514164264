import { Suspense as ReactSuspense, FC, Component } from 'react';
import { Spinner } from '../Bootstrap';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error); // eslint-disable-line no-console
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert alert-danger" role="alert">
          An error occurred during loading the component
        </div>
      );
    }

    return this.props.children;
  }
}

const Suspense: FC = ({ children }) => {
  return (
    <ErrorBoundary>
      <ReactSuspense
        fallback={
          <div className="py-4">
            <Spinner color="primary" />
          </div>
        }
      >
        {children}
      </ReactSuspense>
    </ErrorBoundary>
  );
};

export default Suspense;
