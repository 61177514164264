import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import { uiActions } from '../ui';
import * as partsSelectors from './partsSelectors';
import partsActions from './partsActions';

// fetch parts by seedId
export function* doFetchPartsSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, partsActions.fetchParts, api.parts.getList, payload.seedId);
}

export function* watchFetchPartsSaga() {
  yield takeLatest(partsActions.FETCH_PARTS, doFetchPartsSaga);
}

export function* doCreatePartSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, partsActions.createPart, api.parts.create, payload.entity);
}

export function* watchCreatePartSaga() {
  yield takeLatest(partsActions.CREATE_PART, doCreatePartSaga);
}

export function* doUpdatePartSaga({ payload }) {
  const part = { ...payload.part };
  const unsavedPositions = yield select(partsSelectors.selectPartsUnsavedPositions);
  const currentPosition = unsavedPositions[part._id];

  if (currentPosition) {
    part.Config = { ...part.Config, graphX: currentPosition.graphX, graphY: currentPosition.graphY };
  }

  yield call(sagas.doBaseAsyncSaga, partsActions.updatePart, api.parts.update, part);
}

export function* watchUpdatePartSaga() {
  yield takeLatest(partsActions.UPDATE_PART, doUpdatePartSaga);
}

export function* doDeletePartSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, partsActions.deletePart, api.parts.delete, payload.part);
}

export function* watchDeletePartSaga() {
  yield takeLatest(partsActions.DELETE_PART, doDeletePartSaga);
}

export function* doUpdatePartsSaga({ payload }) {
  yield put(partsActions.updateParts.request());

  const result = yield all(
    payload.parts.map(part => call(sagas.doBaseAsyncSaga, partsActions.updatePart, api.parts.update, part))
  );

  const parts = result.map(({ data }) => data).filter(Boolean);

  if (parts.length === payload.parts.length) {
    yield put(uiActions.showSuccessNotification('Parts updated successfully'));
    yield put(partsActions.updateParts.success());
  } else {
    yield put(partsActions.updateParts.failure());
  }
}

export function* doSaveUnsavedPartsSaga({ payload }) {
  const allParts = yield select(partsSelectors.selectParts);
  const unsavedPositions = yield select(partsSelectors.selectPartsUnsavedPositions);

  const parts = [];

  Object.values(unsavedPositions).forEach(item => {
    const part = allParts[item._id];

    if (part.seed === payload.seedId) {
      parts.push({
        ...part,
        Config: { ...part.Config, graphX: item.graphX, graphY: item.graphY }
      });
    }
  });

  if (parts.length) {
    const { data } = yield call(
      sagas.doBaseAsyncSaga,
      partsActions.saveUnsavedParts,
      api.parts.multiUpdate,
      payload.seedId,
      parts
    );

    if (data) {
      yield put(uiActions.showSuccessNotification('Parts updated successfully'));

      if (Array.isArray(data.errors)) {
        yield all(
          data.errors.map(item =>
            put(
              uiActions.showErrorNotification(
                `Part ${item._id}. ${item.error?.output?.payload?.message || 'Unknown error'}`
              )
            )
          )
        );
      }
    }
  }
}

export function* watchUpdatePartsSaga() {
  yield takeLatest(partsActions.UPDATE_PARTS, doUpdatePartsSaga);
}

export function* watchSaveUnsavedPartsPartsSaga() {
  yield takeLatest(partsActions.SAVE_UNSAVED_PARTS, doSaveUnsavedPartsSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchPartsSaga(),
    watchCreatePartSaga(),
    watchUpdatePartSaga(),
    watchDeletePartSaga(),
    watchUpdatePartsSaga(),
    watchSaveUnsavedPartsPartsSaga()
  ]);
}
