import { useState } from 'react';
import { get, set, cloneDeep } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { State, Metric } from '../types';

// icons from Configurator icon module
const ICONS = [
  'alert',
  'format-list-numbered',
  'cash-multiple',
  'chevron-up',
  'chevron-double-up',
  'chevron-down',
  'chevron-double-down',
  'chevron-right',
  'chevron-double-right',
  'chevron-left',
  'chevron-double-left',
  'chevron-down-up',
  'chevron-up-down',
  'close',
  'cogs',
  'cube',
  'floor-plan',
  'fullscreen',
  'fullscreen-exit',
  'loading',
  'home',
  'home-outline',
  'menu',
  'minus-circle',
  'open-in-new',
  'plus-circle',
  'target',
  'menu-dots',
  'menu-down',
  'menu-right',
  'check',
  'check-bold',
  'save',
  'trash',
  'warning',
  'open-with',
  'content-copy',
  'cancel',
  'cursor-move',
  'rotate-left',
  'undo',
  'bedroom',

  // Share dialog icons
  'facebook',
  'messenger',
  'twitter',
  'whatsapp',
  'mail',
  'web-share',
  'unfold-less-horizontal',
  'unfold-more-horizontal',
  'co2'
];
const iconOptions = [
  ...ICONS.map(icon => ({ value: icon, label: icon })),
  { value: '_custom', label: 'Custom - choose image from assets' }
];

interface MetricIconWrapperProps {
  index?: number;
  state?: State;
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
  column: 'left' | 'right';
}

interface UnitAndCaveatProps {
  index?: number;
  state?: State;
  column: 'left' | 'right';
}

interface MetricsProps {
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
}

export const MetricIconWrapper = (props: MetricIconWrapperProps) => {
  if (props.index === undefined) {
    return null;
  }

  return get(props.state, `metricState[${props.index}].icon`, false) ? (
    <FormField.Image<ProjectType>
      name={`settings.metrics[${props.index}].image.fileName`}
      label="Custom image for icon"
      description=""
      list={props.projectImages}
      uploadImage={props.uploadImage}
      onUploadSuccess={props.updateImagesAfterUpload}
      column={props.column}
    />
  ) : null;
};

export const MetricUnitWrapper = (props: UnitAndCaveatProps) => {
  if (props.index === undefined) {
    return null;
  }

  return get(props.state, `metricState[${props.index}].unit`, false) ? (
    <FormField.Toggle<ProjectType>
      name={`settings.metrics[${props.index}].unitBeforeValue`}
      label="Unit before value"
      description="By default unit is after the value"
      column={props.column}
    />
  ) : null;
};

export const MetricCaveatWrapper = (props: UnitAndCaveatProps) => {
  if (props.index === undefined) {
    return null;
  }

  return get(props.state, `metricState[${props.index}].caveat`, false) ? (
    <FormField.Text<ProjectType>
      name={`settings.metrics[${props.index}].caveatTitle`}
      label="Caveat Title"
      description="Caption to show in summary panel"
      required
      column={props.column}
    />
  ) : null;
};

export const Metrics = ({ projectImages, uploadImage, updateImagesAfterUpload }: MetricsProps) => {
  const [state, setState] = useState<State>({ contactFormType: [], metricState: [] });

  const metricCaveatStateChangeHandler = (show: boolean, index?: number) => {
    set(state, `metricState[${index}].caveat`, show);

    setState(cloneDeep(state));
  };
  const metricUnitStateChangeHandler = (show: boolean, index?: number) => {
    set(state, `metricState[${index}].unit`, show);

    setState(cloneDeep(state));
  };
  const metricIconStateChangeHandler = (value: { value: string; label: string } | null, index?: number) => {
    const show = value?.value === '_custom';

    set(state, `metricState[${index}].icon`, show);
    setState(cloneDeep(state));
  };

  return (
    <>
      <h3>Metrics</h3>
      <p>Area, price and bedrooms are counted by default, add items here to enable counting.</p>
      <FormField.ArrayWrapper<ProjectType>
        name="settings.metrics"
        label="Metrics"
        headerFormat={value => get(value, 'name', 'untitled')}
        twoColumns
        useShadowValue
      >
        <FormField.Text<Metric> name="name" label="Name" required description="Unique name" column="left" />
        <FormField.Text<Metric>
          name="displayName"
          label="Label"
          description="Caption shown under value"
          column="left"
        />
        <FormField.Select<Metric>
          name="unit"
          label="Unit"
          description="Metrics unit like m², cm, kg. You can also define a custom one"
          options={[
            { value: 'm', label: 'm' },
            { value: 'kg', label: 'kg' },
            { value: 'mm', label: 'mm' },
            { value: 'cm', label: 'cm' },
            { value: 'm²', label: 'm²' },
            { value: 'in', label: 'in' },
            { value: 'ft', label: 'ft' },
            { value: 'ft²', label: 'ft²' },
            { value: 's', label: 's' },
            { value: '€', label: '€' },
            { value: '£', label: '£' },
            { value: '$', label: '$' }
          ]}
          onChange={(value, index) => metricUnitStateChangeHandler(!!value, index)}
          column="left"
        />
        <MetricUnitWrapper column="left" state={state} />
        <FormField.Textarea<Metric>
          name="caveat"
          label="Caveat"
          description="Additional information about the metric"
          onChange={(e, index) => {
            metricCaveatStateChangeHandler(!!e.target.value, index);
          }}
          column="right"
        />
        <MetricCaveatWrapper column="right" state={state} />
        <FormField.Text<Metric>
          name="format"
          label="Format"
          description="Use hashes to format numbers like ####.##"
          column="right"
        />
        <FormField.Select<Metric>
          name="icon"
          label="Icon"
          options={iconOptions}
          onChange={(value, index) => metricIconStateChangeHandler(value, index)}
          column="right"
        />
        <MetricIconWrapper
          uploadImage={uploadImage}
          updateImagesAfterUpload={updateImagesAfterUpload}
          state={state}
          projectImages={projectImages}
          column="right"
        />
      </FormField.ArrayWrapper>
    </>
  );
};
