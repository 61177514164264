import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Atoms';
import UserRights from '../UserRights';

const ProtectedButton = ({ rights, ...rest }) => (
  <UserRights>{({ hasRights }) => (hasRights(rights) ? <Button {...rest} /> : null)}</UserRights>
);

ProtectedButton.defaultProps = {
  rights: []
};

ProtectedButton.propTypes = {
  rights: PropTypes.arrayOf(PropTypes.string)
};

export default ProtectedButton;
