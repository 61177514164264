import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { ExtraInfoCollection, InfoPanel } from '../types';

export interface ExtraInfoProps {
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
}

export const ExtraInfo = ({ projectImages, uploadImage, updateImagesAfterUpload }: ExtraInfoProps) => {
  return (
    <>
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'name', 'untitled')}
        name="settings.extraInfoCollections"
        label="Extra info collections"
        description="These collections can be used for options' and 3D sprites' more info panels"
        useShadowValue
      >
        <FormField.Text<ExtraInfoCollection>
          name="name"
          label="Name"
          description="Unique name for the collection"
          required
          column="left"
        />
        <FormField.ArrayWrapper<ExtraInfoCollection>
          headerFormat={value => get(value, 'name', 'untitled')}
          name="infoPanels"
          label="Image and description panels"
          description="These will be shown in a gallery with descriptive texts"
          column="left"
        >
          <FormField.Text<InfoPanel> name="name" label="Name" description="" />
          <FormField.Markdown<InfoPanel> name="text" label="Content" description="" />
          <FormField.Text<InfoPanel> name="caption" label="Caption" description="" />

          <FormField.Image<InfoPanel>
            name="image.fileName"
            label="Image"
            description=""
            list={projectImages}
            uploadImage={uploadImage}
            onUploadSuccess={updateImagesAfterUpload}
          />

          <FormField.Toggle<InfoPanel>
            name="image.isPanorama"
            label="Panorama"
            description="Loads panorama viewer for equirectangular images."
          />
        </FormField.ArrayWrapper>
        <FormField.Text<ExtraInfoCollection>
          name="externalLink.label"
          label="External link label"
          description={
            <span>
              This is used in <strong>imagesFullscreenControls</strong>.
            </span>
          }
          column="right"
        />
        <FormField.Text<ExtraInfoCollection>
          name="externalLink.url"
          label="External link url"
          description={
            <span>
              This is used in <strong>imagesFullscreenControls</strong>.
            </span>
          }
          column="right"
        />
      </FormField.ArrayWrapper>
    </>
  );
};
