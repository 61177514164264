import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../Atoms';
import { useModal } from '../../utils/hooks';
import ProtectedButton from '../ProtectedButton';

const CreationModal = props => {
  const { onCreate, resetFlags, button, header, rights, children, disabled, title, created } = props;
  const { isOpen, toggle, close } = useModal(false);
  const [form, setForm] = useState({});

  const handleCreate = useCallback(() => {
    onCreate(form.values);
  }, [form, onCreate]);

  useEffect(() => {
    if (created) {
      close();
      resetFlags();
    }
  }, [close, created, resetFlags]);

  return (
    <>
      <ProtectedButton onClick={toggle} rights={rights} color="primary" disabled={disabled} title={title} outline>
        {button}
      </ProtectedButton>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        backdrop={form.pristine ? true : 'static'}
        keyboard={form.pristine}
      >
        <ModalHeader toggle={toggle}>{header}</ModalHeader>
        <ModalBody className="overflow-visible">{children({ setForm })}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreate} disabled={form.invalid}>
            Create
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

CreationModal.defaultProps = {
  rights: [],
  disabled: false,
  created: false,
  title: ''
};

CreationModal.propTypes = {
  children: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  resetFlags: PropTypes.func.isRequired,
  button: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  rights: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  created: PropTypes.bool,
  title: PropTypes.string
};

export default CreationModal;
