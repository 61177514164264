import { BSClassNameFormatter, BSColorType, BSSpinnerType } from '../helpers';

interface SpinnerProps {
  type?: BSSpinnerType;
  color?: BSColorType;
  small?: boolean;
}

const Spinner = ({ type = 'border', color = 'primary', small = false }: SpinnerProps) => {
  return (
    <div
      className={BSClassNameFormatter('spinner', { [type]: Boolean(type), [`${type}-sm`]: small }, `text-${color}`)}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
