import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormFeedback } from '../../../Atoms';
import { TYPE_SUGGESTION_LIST } from '../../../../utils/propTypes';
import FieldWrapper from '../FieldWrapper';

class Select extends React.PureComponent {
  renderField = ({ input, meta }) => {
    const { options, clearable, creatable, disabled } = this.props;

    let selectedOption;

    if (input.value !== undefined) {
      selectedOption = options.find(({ value }) => value === input.value) || {
        label: String(input.value),
        value: input.value
      };
    }

    const onChange = option => input.onChange((option && option.value) || undefined);
    // option doesn't exist if clearing select field, undefined is needed because otherwise it will become null, which might mess with Joi validation
    const SelectComponent = creatable ? CreatableSelect : ReactSelect;

    return (
      <>
        <SelectComponent
          {...input}
          onChange={onChange}
          options={options}
          value={selectedOption}
          isClearable={clearable}
          isDisabled={disabled}
          isSearchable
          classNamePrefix="field-select"
          className={`w-100 ${meta.invalid ? 'is-invalid' : ''}`}
        />

        <FormFeedback>{meta.error}</FormFeedback>
      </>
    );
  };

  validate = value => {
    if (this.props.required && !value) {
      return 'Required';
    }

    return undefined;
  };

  render() {
    const { name } = this.props;

    return (
      <FieldWrapper {...this.props}>
        <Field name={name} render={this.renderField} validate={this.validate} />
      </FieldWrapper>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: TYPE_SUGGESTION_LIST,
  creatable: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

Select.defaultProps = {
  creatable: false,
  clearable: false,
  disabled: false,
  options: [],
  required: false
};

export default Select;
