import * as authActions from './authActions';

export const initialState = {
  user: null,
  error: '',
  request: false,
  userInfoReceived: false,
  emailSent: false,
  passwordChanged: false,
  token: {
    valid: false,
    checked: false
  },
  invitation: {
    valid: false,
    checked: false,
    value: {}
  }
};

const authReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case authActions.RESET_AUTH_STATE:
      return { ...initialState, user: state.user, userInfoReceived: state.userInfoReceived };
    case authActions.LOGIN__REQUEST:
    case authActions.REGISTRATION__REQUEST:
    case authActions.FORGOT_PASSWORD__REQUEST:
    case authActions.VERIFY_TOKEN__REQUEST:
    case authActions.VERIFY_INVITATION__REQUEST:
    case authActions.RESET_PASSWORD__REQUEST:
    case authActions.ACCEPT_TERMS_OF_USE__REQUEST:
      return { ...state, request: true };
    case authActions.LOGIN__SUCCESS:
    case authActions.REGISTRATION__SUCCESS:
    case authActions.ACCEPT_TERMS_OF_USE__SUCCESS:
      return { ...state, user: payload.user, error: '', request: false };
    case authActions.FORGOT_PASSWORD__SUCCESS:
      return { ...state, emailSent: true, error: '', request: false };
    case authActions.RESET_PASSWORD__SUCCESS:
      return { ...state, passwordChanged: true, error: '', request: false };
    case authActions.GET_USER_INFO__SUCCESS:
      return { ...state, user: payload.user, userInfoReceived: true };
    case authActions.LOGOUT__SUCCESS:
      return { ...initialState, userInfoReceived: state.userInfoReceived };
    case authActions.LOGIN__FAILURE:
    case authActions.REGISTRATION__FAILURE:
    case authActions.FORGOT_PASSWORD__FAILURE:
    case authActions.RESET_PASSWORD__FAILURE:
    case authActions.LOGOUT__FAILURE:
    case authActions.ACCEPT_TERMS_OF_USE__FAILURE:
      return { ...state, error: payload.error, request: false };
    case authActions.VERIFY_TOKEN__SUCCESS:
      return { ...state, token: { valid: true, checked: true }, request: false };
    case authActions.VERIFY_TOKEN__FAILURE:
      return { ...state, token: { valid: false, checked: true }, request: false };
    case authActions.VERIFY_INVITATION__SUCCESS:
      return { ...state, invitation: { valid: true, checked: true, value: payload.invitation }, request: false };
    case authActions.VERIFY_INVITATION__FAILURE:
      return { ...state, invitation: { valid: false, checked: true, value: null }, request: false };
    case authActions.GET_USER_INFO__FAILURE:
      return { ...state, userInfoReceived: true };
    default:
      return state;
  }
};

export default authReducer;
