import { useEffect, useState } from 'react';
import { InstanceType } from 'types';
import api from '../../utils/api/routes';
import { CsvDownloader } from '../Atoms';

interface SeedAnalyticsProps {
  seedId: string;
}

const COLUMNS = [
  'instanceId',
  'isOrphanInstance',
  'hasRequestBeenSent',
  'controlName',
  'controlDisplayName',
  'selectedOption',
  'selectedOptionDisplayName',
  'isSelectedOptionDefault'
];

function parseAnalytics(instances: InstanceType[]) {
  return instances.reduce((result: string[][], instance) => {
    if (instance.summary && Array.isArray(instance.summary.sections)) {
      instance.summary.sections.forEach(section => {
        if (Array.isArray(section.choices)) {
          section.choices.forEach(({ option = {} }) => {
            const { control, name, displayName } = option;

            result.push([
              instance._id, // instanceId
              instance.orphan, // isOrphanInstance
              Boolean(instance.lead?.email), // hasRequestBeenSent
              control.name, // controlName
              control.displayName, // controlDisplayName
              name, // selectedOption
              displayName, // selectedOptionDisplayName
              instance.savedState && !instance.savedState[control.treeName] // isSelectedOptionDefault
            ]);
          });
        }
      });
    }

    return result;
  }, []);
}

const SeedAnalytics = ({ seedId }: SeedAnalyticsProps) => {
  const [parsedData, setParsedData] = useState<string[][]>([]);

  useEffect(() => {
    let ignore = false;

    async function fetchInstances() {
      const { data } = await api.instances.getListBySeed(seedId, [
        'seed.root',
        'savedState',
        'orphan',
        'lead.email',
        'summary.sections.phaseName',
        'summary.sections.choices.option.control.name',
        'summary.sections.choices.option.control.displayName',
        'summary.sections.choices.option.control.treeName',
        'summary.sections.choices.option.name',
        'summary.sections.choices.option.displayName'
      ]);

      if (!ignore && Array.isArray(data)) {
        setParsedData(parseAnalytics(data));
      }
    }

    fetchInstances();

    return () => {
      ignore = true;
    };
  }, [seedId]);

  return (
    <CsvDownloader
      filename={`seed analytics ${seedId}`}
      title="Download Analytics"
      columns={COLUMNS}
      data={parsedData}
    />
  );
};

export default SeedAnalytics;
