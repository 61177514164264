import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import b from 'b_';
import { Button } from '../../../Atoms';
import { TYPE_INSTANCE } from '../../../../utils/propTypes';
import ProtectedButton from '../../../ProtectedButton';
import { RIGHTS } from '../../../../utils/rights';
import { instancesActions, instancesSelectors } from '../../../../modules/instances';
import DeleteRequestButton from '../../../DeleteRequestButton';
import JsonViewer from '../../../JsonViewer';
import OpenConfiguratorLink from '../../../OpenConfiguratorLink';
import { getVisitorUrl } from '../../../../utils/appRoutes';

import './RequestDetails.scss';

const requestDetails = b.with('request-details');

const leadMainFields = new Set([
  'createdAt',
  'updatedAt',
  'firstName',
  'lastName',
  'email',
  'message',
  'unread',
  'consentGiven',
  'consent',
  'downloadedPDF',
  'integration'
]);

export class RequestDetails extends React.PureComponent {
  state = {
    modal: false
  };

  componentDidMount() {
    const { instance, markRequestAsRead } = this.props;

    if (instance.lead.unread) {
      markRequestAsRead(instance._id);
    }
  }

  get isIntegrationStatusVisible() {
    const { instance, isIntegrationStatusVisible } = this.props;

    return isIntegrationStatusVisible && instance.lead.integration;
  }

  openModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  runIntegration = () => {
    const { instance, runIntegration } = this.props;

    runIntegration(instance._id);
  };

  renderConsentMessage() {
    const { instance } = this.props;
    const { consent } = instance.lead;

    if (!consent || !consent.label) {
      return 'Not given';
    }

    return (
      <span>
        <i className="fa fa-fw fa-check text-success" /> {consent.label}
      </span>
    );
  }

  renderLinkToVisitorPage() {
    const { instance } = this.props;

    return instance.orphan ? null : (
      <ProtectedButton
        rights={[RIGHTS.VISITORS__READ]}
        tag={Link}
        color="primary"
        size="sm"
        outline
        to={getVisitorUrl(instance.populated.visitor._id)}
      >
        Show visitor details
      </ProtectedButton>
    );
  }

  renderRunIntegrationButton() {
    if (this.isIntegrationStatusVisible && this.props.instance.lead.integration.error) {
      return (
        <ProtectedButton
          rights={[RIGHTS.VISITORS__RUN_INTEGRATION]}
          onClick={this.runIntegration}
          color="primary"
          size="sm"
          disabled={this.props.integrationRequest}
          outline
        >
          <i className="fa fa-fw fa-share" /> Run integration
        </ProtectedButton>
      );
    }

    return null;
  }

  render() {
    const { instance } = this.props;
    const { lead, populated } = instance;

    return (
      <div className={`${requestDetails()} row`}>
        <div className="col-md-5 col-lg-4">
          <h5 className="text-primary">Instance</h5>
          <dl className="d-flex flex-wrap">
            <dt className={requestDetails('label')}>Id</dt>
            <dd className={requestDetails('info')}>{instance._id}</dd>
            <dt className={requestDetails('label')}>Name</dt>
            <dd className={requestDetails('info')}>{instance.instanceName}</dd>
            <dt className={requestDetails('label')}>Created</dt>
            <dd className={requestDetails('info')}>{new Date(instance.createdAt).toLocaleString()}</dd>
            <dt className={requestDetails('label')}>Modified</dt>
            <dd className={requestDetails('info')}>{new Date(instance.updatedAt).toLocaleString()}</dd>
            <br />
            <dt className={requestDetails('label')}>Seed name</dt>
            <dd className={requestDetails('info')}>
              {populated.seed.name} ({populated.seed.version})
            </dd>
            <dt className={requestDetails('label')}>Seed id</dt>
            <dd className={requestDetails('info')}>{populated.seed._id}</dd>
          </dl>
        </div>
        <div className="col-md-7 col-lg-8">
          <h5 className="text-primary">Request</h5>
          <dl className="d-flex flex-wrap">
            <dt className={requestDetails('label')}>Created</dt>
            <dd className={requestDetails('info')}>{new Date(lead.createdAt).toLocaleString()}</dd>
            <dt className={requestDetails('label')}>Modified</dt>
            <dd className={requestDetails('info')}>{new Date(lead.updatedAt).toLocaleString()}</dd>
            <dt className={requestDetails('label')}>First name</dt>
            <dd className={requestDetails('info')}>{lead.firstName}</dd>
            <dt className={requestDetails('label')}>Last name</dt>
            <dd className={requestDetails('info')}>{lead.lastName}</dd>
            <dt className={requestDetails('label')}>Email</dt>
            <dd className={requestDetails('info')}>{lead.email}</dd>
            <dt className={requestDetails('label')}>Message</dt>
            <dd className={requestDetails('info')}>{lead.message}</dd>
            <dt className={requestDetails('label')}>Consent</dt>
            <dd className={requestDetails('info')}>{this.renderConsentMessage()}</dd>
            {lead.downloadedPDF ? (
              <>
                <dt className={requestDetails('label')}>Downloaded PDF</dt>
                <dd className={requestDetails('info')}>
                  <i className="fa fa-fw fa-check text-success" />
                </dd>
              </>
            ) : null}
            {this.isIntegrationStatusVisible ? (
              <>
                <dt className={requestDetails('label')}>Integration status</dt>
                <dd className={requestDetails('info')}>
                  {lead.integration.error ? (
                    <i className="fa fa-fw fa-exclamation-circle  text-danger" title="Error" />
                  ) : (
                    <i className="fa fa-fw fa-share-square text-success" title="Success" />
                  )}
                </dd>
              </>
            ) : null}
          </dl>

          <h5>Other fields</h5>
          <dl className="d-flex flex-wrap">
            {Object.keys(lead)
              .filter(key => !leadMainFields.has(key))
              .map(key => (
                <React.Fragment key={key}>
                  <dt className={requestDetails('label')}>{key}</dt>
                  <dd className={requestDetails('info')}>{lead[key]}</dd>
                </React.Fragment>
              ))}
          </dl>
          <div className={requestDetails('controls')}>
            <DeleteRequestButton instance={instance} />
            {this.renderLinkToVisitorPage()}
            <OpenConfiguratorLink
              type="id"
              link={instance._id}
              caption="Open configurator"
              color="outline-primary"
              size="sm"
            />
            {this.isIntegrationStatusVisible ? (
              <>
                <Button onClick={this.openModal} color="primary" size="sm" outline>
                  <i className="fa fa-fw fa-eye" /> Show integration info
                </Button>
                {this.renderRunIntegrationButton()}
              </>
            ) : null}
            {this.state.modal ? (
              <JsonViewer onClose={this.closeModal} header="Integration info" data={lead.integration} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

RequestDetails.defaultProps = {
  integrationRequest: false
};

RequestDetails.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  markRequestAsRead: PropTypes.func.isRequired,
  runIntegration: PropTypes.func.isRequired,
  isIntegrationStatusVisible: PropTypes.bool.isRequired,
  integrationRequest: PropTypes.bool
};

const mapStateToProps = state => ({
  integrationRequest: instancesSelectors.selectInstancesIntegrationRequest(state)
});

const mapDispatchToProps = {
  markRequestAsRead: instancesActions.markRequestAsRead,
  runIntegration: instancesActions.runIntegration
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);
