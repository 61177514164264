import { LocationDescriptor } from 'history';

const PARTNER_ID = ':partnerId';
const TEMPLATE_ID = ':templateId';

export enum ApplicationRoute {
  Main = '/',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/:token',

  Partners = '/partners',
  Partner = '/partners/:partnerId',
  PartnerCreation = '/partners/new',

  Templates = '/partners/:partnerId/templates',
  Template = '/partners/:partnerId/templates/:templateId',
  TemplateSource = '/partners/:partnerId/templates/:templateId/:source',

  Users = '/users',
  Invitations = '/invitations',
  Roles = '/roles',
  GlobalSettings = '/global-settings',
  GlobalTheme = '/global-theme',

  Plots = '/plots',

  ProjectList = '/projects',
  Project = '/projects/:projectId',
  ProjectAssets = '/projects/:projectId/assets',
  ProjectInfo = '/projects/:projectId/info',
  ProjectSettings = '/projects/:projectId/settings',
  // settings pages
  // content
  ProjectSettingsContentSettings = '/projects/:projectId/settings/content/settings',
  ProjectSettingsContentSummary = '/projects/:projectId/settings/content/summary',
  ProjectSettingsContentExtra = '/projects/:projectId/settings/content/extra',
  ProjectSettingsContentOptionStatuses = '/projects/:projectId/settings/content/option-statuses',
  // ui
  ProjectSettingsUiTranslatons = '/projects/:projectId/settings/ui/translations',
  ProjectSettingsUiBranding = '/projects/:projectId/settings/ui/branding',
  ProjectSettingsUiTheming = '/projects/:projectId/settings/ui/theming',

  // pricing
  //  ...
  // end

  ProjectSelfService = '/projects/:projectId/self-service',
  Instances = '/instances',

  Requests = '/requests',

  Visitors = '/visitors',
  Visitor = '/visitors/:visitorId',

  Seed = '/seeds/:seedId',
  SeedInfo = '/seeds/:seedId/info',
  SeedSettings = '/seeds/:seedId/settings',
  SeedGraph = '/seeds/:seedId/graph',
  SeedParts = '/seeds/:seedId/parts',
  SeedMaterials = '/seeds/:seedId/materials',
  SeedLights = '/seeds/:seedId/lights',
  SeedViews = '/seeds/:seedId/views',
  SeedTabs = '/seeds/:seedId/tabs',
  SeedPrices = '/seeds/:seedId/prices',
  SeedRemoteControls = '/seeds/:seedId/remote-controls',
  SeedRemoteSwitches = '/seeds/:seedId/remote-switches',

  Test = '/test',
  Assets = '/assets',

  FactoryMaps = '/factory-maps',
  FactoryMap = '/factory-maps/:factoryId',

  IframeScripts = '/iframe-scripts',
  IframeScript = '/iframe-scripts/:scriptId',

  Subscriptions = '/subscriptions'
}

export const getPartnerUrl = (partnerId: string) => ApplicationRoute.Partner.replace(PARTNER_ID, partnerId);
export const getVisitorUrl = (visitorId: string) => ApplicationRoute.Visitor.replace(':visitorId', visitorId);
export const getFactoryMapUrl = (factoryId: string) => ApplicationRoute.FactoryMap.replace(':factoryId', factoryId);
export const getIframeScriptUrl = (scriptId: string) => ApplicationRoute.IframeScript.replace(':scriptId', scriptId);

export const getProjectUrl = (
  projectId?: string,
  page?: 'list' | 'self-service' | 'info' | 'settings' | 'textures' | 'images' | 'models'
): LocationDescriptor => {
  if (page === 'list' || !projectId) {
    return ApplicationRoute.ProjectList;
  }

  const pathname = `${ApplicationRoute.ProjectList}/${projectId}`;

  if (!page) {
    return pathname;
  }

  if (page === 'textures' || page === 'images' || page === 'models') {
    return { pathname: `${pathname}/assets`, search: `?type=${page}` };
  }

  return `${pathname}/${page}`;
};

export const getSeedUrl = (
  seedId: string,
  page?:
    | 'info'
    | 'settings'
    | 'graph'
    | 'parts'
    | 'materials'
    | 'lights'
    | 'views'
    | 'tabs'
    | 'prices'
    | 'remote-controls'
    | 'remote-switches'
) => {
  const pathname = ApplicationRoute.Seed.replace(':seedId', seedId);

  if (!page) return pathname;

  return `${pathname}/${page}`;
};

export const getTemplatesUrl = (partnerId: string) => ApplicationRoute.Templates.replace(PARTNER_ID, partnerId);
export const getTemplateUrl = (partnerId: string, templateId: string) =>
  ApplicationRoute.Template.replace(PARTNER_ID, partnerId).replace(TEMPLATE_ID, templateId);

export const getTemplateBodyUrl = (partnerId: string, templateId: string) =>
  ApplicationRoute.TemplateSource.replace(PARTNER_ID, partnerId)
    .replace(TEMPLATE_ID, templateId)
    .replace(':source', 'body');
export const getTemplateHeaderUrl = (partnerId: string, templateId: string) =>
  ApplicationRoute.TemplateSource.replace(PARTNER_ID, partnerId)
    .replace(TEMPLATE_ID, templateId)
    .replace(':source', 'header');
export const getTemplateFooterUrl = (partnerId: string, templateId: string) =>
  ApplicationRoute.TemplateSource.replace(PARTNER_ID, partnerId)
    .replace(TEMPLATE_ID, templateId)
    .replace(':source', 'footer');
