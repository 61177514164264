import { ReactNode } from 'react';
import { ProjectType } from 'types';
import { Redirect, useParams } from 'react-router-dom';
import { useProject } from '../../store/project';
import { getProjectUrl } from '../../utils/appRoutes';
import Loader from '../Loader';
import { Container } from '../Atoms';
import { useSeeds } from '../../store/seed';
import { usePartners } from '../../modules/partners/partnersHooks';

interface ProjectPageWrapperProps {
  children: (project: ProjectType) => ReactNode;
}

const ProjectPageWrapper = ({ children }: ProjectPageWrapperProps) => {
  const { projectId } = useParams<{ projectId: string }>();

  const { project, isInProgress } = useProject(projectId);

  useSeeds();
  usePartners();

  return (
    <Loader loading={isInProgress}>
      {!project ? (
        <Redirect to={getProjectUrl()} />
      ) : (
        <div className="bg-light py-3 flex-grow-1">
          <Container type="lg">{children(project)}</Container>
        </div>
      )}
    </Loader>
  );
};

export default ProjectPageWrapper;
