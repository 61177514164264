import { createSelector } from 'reselect';

export const selectRoles = state => state.roles.list;
export const selectRolesRequest = state => state.roles.request;
export const selectRoleCreatedFlag = state => state.roles.created;

export const selectRoleList = createSelector([selectRoles], roles => Object.values(roles));

export const selectRoleListForSuggestions = createSelector([selectRoleList], roles =>
  roles.map(role => ({ label: role.name, value: role._id }))
);

export const selectUserListForFilter = createSelector([selectRoleList], roles => {
  const users = []
    .concat(...roles.map(role => role.users))
    .reduce((acc, user) => ({ ...acc, [user._id]: user.username }), {});

  return Object.keys(users).map(id => ({ label: users[id], value: id }));
});
