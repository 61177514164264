import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

interface PartnerLink {
  label: string;
  url: string;
}

interface SisterConfig {
  displayName: string;
  link: string;
}

export interface BrandingProps {
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
}

export const Branding = ({ projectImages, uploadImage, updateImagesAfterUpload }: BrandingProps) => {
  return (
    <>
      <FormField.Image<ProjectType>
        name="settings.interface.favicon"
        label="Favicon"
        description="The optimal size is 32x32 pixels"
        list={projectImages}
        header="Select favicon"
        uploadImage={uploadImage}
        onUploadSuccess={updateImagesAfterUpload}
        useShadowValue
      />
      <FormField.Text<ProjectType> useShadowValue name="settings.interface.header" label="Header label" />
      <FormField.Image<ProjectType>
        name="settings.interface.headerIcon"
        label="Header icon"
        description="Preferably svg image to show as logo next to header"
        list={projectImages}
        header="Select header icon"
        uploadImage={uploadImage}
        onUploadSuccess={updateImagesAfterUpload}
        useShadowValue
      />
      <FormField.ArrayWrapper<ProjectType>
        name="settings.sisterConfigurators"
        label="Sister configurators"
        description="List of related Configurators which are linked to this one."
        headerFormat={value => get(value, 'displayName', 'untitled')}
        useShadowValue
      >
        <FormField.Text<SisterConfig> name="displayName" label="Label" required />
        <FormField.Text<SisterConfig> name="link" label="Link" required />
      </FormField.ArrayWrapper>
      <FormField.ArrayWrapper<ProjectType>
        name="settings.footer.partnerLinks"
        label="Links"
        headerFormat={value => get(value, 'label', 'untitled')}
        useShadowValue
      >
        <FormField.Text<PartnerLink> name="label" label="Label" required />
        <FormField.Text<PartnerLink> name="url" label="Url" required />
      </FormField.ArrayWrapper>
      <FormField.Toggle<ProjectType>
        name="settings.footer.poweredByDisabled"
        label="Disable 'Powered by Creatomus'"
        description="This setting removes references to Creatomus. Don't use on embedded models."
        useShadowValue
      />
    </>
  );
};
