import { useMemo, useState } from 'react';
import { ProjectType } from 'types';
import ProjectCreation from '../ProjectsPage/ProjectCreation';
import { useProjectList } from '../../store/project';
import { ListPageWrapper } from '../Atoms';
import { useSeeds } from '../../store/seed';
import { usePartners } from '../../modules/partners/partnersHooks';
import ProjectListItem from './ProjectListItem';
import ProjectListSearch, { SortType } from './ProjectListSearch';

const getProjectUpdatedAt = ({ updatedAt, populated }: ProjectType) => {
  const projectUpdatedAt = new Date(updatedAt);

  if (!populated.publicSeedUpdatedAt) {
    return projectUpdatedAt;
  }

  const publicSeedUpdatedAt = new Date(populated.publicSeedUpdatedAt);

  return publicSeedUpdatedAt > projectUpdatedAt ? publicSeedUpdatedAt : projectUpdatedAt;
};

const sortFunction = (list: ProjectType[], sortType: SortType) =>
  sortType === SortType.ALPHABET
    ? list
    : [...list].sort((projectA: ProjectType, projectB: ProjectType) => {
        const dateA = getProjectUpdatedAt(projectA);
        const dateB = getProjectUpdatedAt(projectB);

        if (dateA > dateB) return -1;

        if (dateA < dateB) return 1;

        return 0;
      });

const ProjectList = () => {
  const { projects, isInProgress } = useProjectList();
  const [sortType, setSortType] = useState<SortType>(SortType.CALENDAR);
  const [search, setSearch] = useState('');

  useSeeds();
  usePartners();

  const sortedList = useMemo(() => sortFunction(projects, sortType), [projects, sortType]);

  const filtered = sortedList.filter(
    ({ name, populated }) => name.toLowerCase().includes(search) || populated.partnerName.toLowerCase().includes(search)
  );

  return (
    <ListPageWrapper
      title="Projects"
      loading={isInProgress}
      button={<ProjectCreation />}
      search={<ProjectListSearch onChange={setSearch} onChangeSortType={setSortType} currentSortType={sortType} />}
    >
      {filtered.length === 0 ? (
        <div className="d-flex">
          <span className="my-4 mx-auto">No projects found</span>
        </div>
      ) : (
        filtered.map(project => <ProjectListItem key={project._id} project={project} />)
      )}
    </ListPageWrapper>
  );
};

export default ProjectList;
