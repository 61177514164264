import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RIGHTS } from '../../../utils/rights';
import { authSelectors } from '../../../modules/auth';
import CreationModal from '../../CreationModal';
import Form from '../../Form';
import Field from '../../Form/Field';
import { partnersSelectors } from '../../../modules/partners';
import { useUserRights } from '../../../utils/hooks';
import { useCreateProject } from '../../../store/project';

export const ProjectCreation = () => {
  const { createProject, isSuccess } = useCreateProject();

  const hasPartner = useSelector(authSelectors.selectUserHasPartner);
  const partnerId = useSelector(authSelectors.selectUserPartnerId);
  const partners = useSelector(partnersSelectors.selectPartnerListForSuggestions);
  const hasRights = useUserRights();
  const hasRightsToUpdatePartner = useMemo(() => hasRights([RIGHTS.PROJECTS__UPDATE_PARTNER]), [hasRights]);
  const initialValues = useMemo(
    () => (hasRightsToUpdatePartner ? { partnerId } : null),
    [hasRightsToUpdatePartner, partnerId]
  );

  return (
    <CreationModal
      button="+ Add"
      header="Add a new project"
      rights={[RIGHTS.PROJECTS__CREATE]}
      onCreate={createProject}
      disabled={!hasPartner}
      title={hasPartner ? '' : 'Assign partner to yourself to create a new project'}
      created={isSuccess}
      resetFlags={() => {}}
    >
      {({ setForm }) => (
        <Form onChange={setForm} initialValues={initialValues} useBottomButtons={false}>
          <Field.Text name="name" required label="Name" description="" />
          <Field.Text name="link" required description="Must be unique among projects" />
          {hasRightsToUpdatePartner ? (
            <Field.Select required name="partnerId" isRequired label="Partner" options={partners} />
          ) : null}
        </Form>
      )}
    </CreationModal>
  );
};

export default ProjectCreation;
