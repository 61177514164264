import { actions } from '../@helpers';
import ASSET_TYPES from './assetTypes';

const moduleNames = {
  [ASSET_TYPES.IMAGES]: 'IMAGES',
  [ASSET_TYPES.TEXTURES]: 'TEXTURES',
  [ASSET_TYPES.MODELS]: 'MODELS'
};

const makeAssetActions = assetType => {
  const MODULE_NAME = moduleNames[assetType];

  const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

  const fetchList = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_LIST'),
    {
      base:
        type =>
        (projectId = '') => ({ type, payload: { projectId } })
    }
  );

  const deleteEntity = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'DELETE_ENTITY'),
    {
      base: type => (id, unsafe) => {
        return { type, payload: { id, unsafe } };
      },
      success: type => id => ({ type, payload: { id } })
    }
  );

  const updateAssetsAfterUpload = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_ASSET_LIST_AFTER_UPLOAD'),
    {
      base: type => assets => ({ type, payload: { assets } })
    }
  );

  const commonActions = {
    ...moduleActions,
    fetchList,
    delete: deleteEntity,
    updateAssetsAfterUpload,

    FETCH_LIST: fetchList.ACTION_TYPE,
    DELETE: deleteEntity.ACTION_TYPE,
    UPDATE_ASSET_LIST_AFTER_UPLOAD: updateAssetsAfterUpload.ACTION_TYPE
  };

  if (assetType === ASSET_TYPES.MODELS) return commonActions;

  return {
    ...commonActions
  };
};

const assetsActions = {
  images: makeAssetActions(ASSET_TYPES.IMAGES),
  textures: makeAssetActions(ASSET_TYPES.TEXTURES),
  models: makeAssetActions(ASSET_TYPES.MODELS)
};

export default assetsActions;
