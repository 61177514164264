import { RegisterOptions } from 'react-hook-form';
import { Theme } from 'react-select';
import { ValidationRule } from 'react-hook-form/dist/types/validator';

export const getConvertedRegisterOptions = <T>(registerOptions?: RegisterOptions<T>): Partial<RegisterOptions<T>> => {
  if (!registerOptions) return {};

  const result = { ...registerOptions };

  if (result.required) {
    result.required = 'Please fill in this field';
  }

  if (result.min && typeof result.min !== 'object') {
    result.min = {
      value: result.min,
      message: `Value must be greater than or equal to ${result.min}`
    };
  }

  if (result.max && typeof result.max !== 'object') {
    result.max = {
      value: result.max,
      message: `Value must be less than or equal to ${result.max}`
    };
  }

  if (result.minLength && typeof result.minLength !== 'object') {
    result.minLength = {
      value: result.minLength,
      message: `Please lengthen this text to ${result.minLength} characters or more`
    };
  }

  if (result.maxLength && typeof result.maxLength !== 'object') {
    result.maxLength = {
      value: result.maxLength,
      message: `Please shorten this text to ${result.maxLength} characters or less`
    };
  }

  return result;
};

export const getThemeForSelectComponent = (theme: Theme, invalid: boolean): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral20: invalid ? '#dc3545' : theme.colors.neutral20
  }
});

export const getPattern = (type: string | RegExp): ValidationRule<RegExp> | undefined => {
  if (type === 'email') {
    return {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: 'Please enter a valid email address'
    };
  }

  if (type === 'url') {
    return {
      value:
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
      message: 'Please enter a URL'
    };
  }

  if (typeof type === 'string') {
    return undefined;
  }

  return {
    value: type,
    message: 'Please enter a valid value'
  };
};
