import { ReactNode } from 'react';
import { SeedType } from 'types';
import { Redirect, useParams } from 'react-router-dom';
import { getProjectUrl } from '../../utils/appRoutes';
import Loader from '../Loader';
import { Container } from '../Atoms';
import { useSeed } from '../../store/seed';

interface SeedPageWrapperProps {
  children: (project: SeedType) => ReactNode;
}

const SeedPageWrapper = ({ children }: SeedPageWrapperProps) => {
  const { seedId } = useParams<{ seedId: string }>();

  const { seed, isInProgress } = useSeed(seedId);

  return (
    <Loader loading={isInProgress}>
      {!seed ? (
        <Redirect to={getProjectUrl()} />
      ) : (
        <div className="bg-light py-3 flex-grow-1">
          <Container type="lg">{children(seed)}</Container>
        </div>
      )}
    </Loader>
  );
};

export default SeedPageWrapper;
