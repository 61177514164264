import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal as BSModal, ModalBody, ModalFooter, ModalHeader } from '../../../Atoms';

const Modal = ({ header, onSubmit, onClose, children, size, preview }) => {
  const submit = useCallback(() => {
    onSubmit();
    onClose();
  }, [onSubmit, onClose]);

  return (
    <BSModal isOpen size={size} backdrop toggle={onClose}>
      <ModalHeader toggle={onClose}>{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {preview ? null : (
        <ModalFooter>
          <Button color="primary" onClick={submit}>
            Submit
          </Button>
          <Button color="secondary" outline onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      )}
    </BSModal>
  );
};

Modal.propTypes = {
  header: PropTypes.node,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  preview: PropTypes.bool
};
Modal.defaultProps = {
  header: '',
  size: 'sm',
  preview: false
};

export default Modal;
