import { memo, useCallback, useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '..';
import { useModal } from '../../../utils/hooks';
import { NavigationInterceptorProps } from './types';

const NavigationInterceptor = memo(({ isDirty, isValid, isSubmitSuccessful, onSubmit }: NavigationInterceptorProps) => {
  const { isOpen, close: closeModal, open: openModal } = useModal(false);

  const [lastLocation, setLastLocation] = useState('');
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const { push } = useHistory();
  const handleBlockedNavigation = useCallback(
    (location: Location) => {
      if (confirmedNavigation || isSubmitSuccessful) {
        return true;
      }

      setLastLocation(location.pathname);
      openModal();

      return false;
    },
    [confirmedNavigation, isSubmitSuccessful, openModal]
  );

  const handleDiscardChanges = () => {
    setConfirmedNavigation(true);
    closeModal();
  };

  useEffect(() => {
    if (lastLocation && (confirmedNavigation || isSubmitSuccessful)) {
      push(lastLocation);
    }
  }, [confirmedNavigation, push, isSubmitSuccessful, lastLocation, closeModal]);

  // Reset state
  useEffect(() => {
    if (!isDirty) {
      closeModal();
      setConfirmedNavigation(false);
    }
  }, [isDirty, closeModal]);

  return (
    <>
      <Prompt when={isDirty} message={handleBlockedNavigation} />
      {isOpen ? (
        <Modal isOpen backdrop toggle={closeModal}>
          <ModalHeader toggle={closeModal}>Warning</ModalHeader>
          <ModalBody>You have unsaved changes!</ModalBody>
          <ModalFooter>
            <Button color="secondary me-auto" onClick={closeModal} outline size="sm">
              Keep Editing
            </Button>
            <Button color="secondary" onClick={handleDiscardChanges} outline size="sm">
              Discard Changes
            </Button>
            <Button color="primary" onClick={onSubmit} outline size="sm" disabled={!isValid}>
              Submit Changes
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
});

export default NavigationInterceptor;
