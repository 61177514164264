import React from 'react';
import PropTypes from 'prop-types';

const PartDecorator = ({ rootPartId, partId, isOrphan }) => {
  if (partId === rootPartId) {
    return <i className="fa fa-fw fa-code-fork" title="Root part" />;
  }

  if (isOrphan) {
    return <i className="fa fa-warning fa-fw" title="Orphan" />;
  }

  return null;
};

PartDecorator.propTypes = {
  rootPartId: PropTypes.string,
  partId: PropTypes.string,
  isOrphan: PropTypes.bool
};
PartDecorator.defaultProps = {
  rootPartId: '',
  isOrphan: false,
  partId: ''
};

export default PartDecorator;
