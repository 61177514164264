import { createSelector } from 'reselect';

export const selectInvitations = state => state.invitations.list;
export const selectInvitation = (state, id) => state.invitations.list[id];
export const selectInvitationsRequest = state => state.invitations.request;
export const selectInvitationUpdatedFlag = state => state.invitations.updated;
export const selectInvitationCreatedFlag = state => state.invitations.created;

export const selectInvitationList = createSelector([selectInvitations], invitations => Object.values(invitations));

export const selectInvitationFilterValues = createSelector([selectInvitationList], invitations => {
  const fields = ['partner', 'roles', 'projects'];
  const initial = fields.reduce((result, field) => {
    result[field] = {}; // eslint-disable-line no-param-reassign

    return result;
  }, {});

  const values = invitations.reduce((result, invitation) => {
    fields.forEach(field => {
      if (Array.isArray(invitation.populated?.[field])) {
        invitation.populated[field].forEach(item => {
          result[field][item._id] = item.name; // eslint-disable-line no-param-reassign
        });
      }
    });

    return result;
  }, initial);

  return fields.reduce((result, field) => {
    // eslint-disable-next-line no-param-reassign
    result[field] = Object.keys(values[field]).map(id => ({ label: values[field][id], value: id }));

    return result;
  }, {});
});
