import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form-html5-validation';
import { Col, Row } from '../../../Atoms';
import FieldWrapper from '../FieldWrapper';
import MatrixField from './MatrixField';

const PARSER_WITH_EMPTY = input => {
  return input ? Number(input) : '';
};

const Matrix = props => {
  const { name, columns, rows } = props;
  const items = [];

  for (let i = 0; i < rows; i += 1) {
    const rowItems = [];

    for (let j = 0; j < columns; j += 1) {
      const itemName = `${name}[${i * columns + j}]`;

      rowItems[j] = (
        <Col key={j}>
          <Field name={itemName} placeholder={`[${i}][${j}]`} component={MatrixField} parse={PARSER_WITH_EMPTY} />
        </Col>
      );
    }

    items[i] = (
      <Row key={i} className="my-2">
        {rowItems}
      </Row>
    );
  }

  return <FieldWrapper {...props}>{items}</FieldWrapper>;
};

Matrix.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired
};

export default Matrix;
