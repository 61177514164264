import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import FieldWrapper from '../FieldWrapper';
import FormContext from '../../FormContext';
import ArrayPreview from './ArrayPreview';
import ArrayList from './ArrayList';

const ArrayField = props => {
  const { name, component, componentProps, internalFieldName } = props;

  return (
    <FieldWrapper {...props} complex>
      <FormContext.Consumer>
        {({ preview }) => {
          const FieldComponent = preview ? ArrayPreview : ArrayList;

          return (
            <FieldComponent
              name={name}
              component={component}
              componentProps={componentProps}
              internalFieldName={internalFieldName}
            />
          );
        }}
      </FormContext.Consumer>
    </FieldWrapper>
  );
};

ArrayField.propTypes = {
  name: PropTypes.string.isRequired,
  internalFieldName: PropTypes.string,
  // this is used if array is actually an object with one internal field.
  // normally array-object should be used but it's simpler to use arrayField in ui
  // for example images = [{fileName}], we actually have only one value to change.
  component: PropTypes.func,
  componentProps: PropTypes.shape({}),
  defaultData: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  disabled: PropTypes.bool
};

ArrayField.defaultProps = {
  component: Text,
  defaultData: '',
  internalFieldName: '',
  componentProps: undefined,
  disabled: false
};

export default ArrayField;
