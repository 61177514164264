import { useCallback, useMemo } from 'react';
import { SeedType } from 'types';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Icon, UncontrolledDropdown } from '../Atoms';
import { useModal, useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';
import { useUpdateProjectPublicSeed } from '../../store/project';
import { getSeedUrl } from '../../utils/appRoutes';
import ConfiguratorLink from '../ConfiguratorLink';
import { useDeleteSeed } from '../../store/seed';
import SeedCreationModal from '../SeedCreationModal';

interface ProjectListItemActionsProps {
  seed: SeedType;
  isPublicSeed: boolean;
}

const ProjectDashboardVersionActions = ({ seed, isPublicSeed }: ProjectListItemActionsProps) => {
  const { isOpen, close, open } = useModal();
  const { updateProjectPublicSeed } = useUpdateProjectPublicSeed(seed.projectId);
  const { deleteSeed } = useDeleteSeed(seed._id, seed.name, seed.version);
  const hasRights = useUserRights();
  const handleClickOnMakePublic = useCallback(
    e => {
      const { seedId } = e.currentTarget.dataset;

      updateProjectPublicSeed(seedId);
    },
    [updateProjectPublicSeed]
  );

  const initialCloneValue = useMemo(() => ({ ...seed, version: undefined }), [seed]);

  return (
    <>
      <SeedCreationModal type="clone" onClose={close} defaultValues={initialCloneValue} isOpen={isOpen} />
      <UncontrolledDropdown className="ms-auto p-0">
        <DropdownToggle color="icon" className="text-primary" size="sm">
          <Icon type="mdiDotsVertical" />
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem className="px-3 d-flex" tag={Link} to={getSeedUrl(seed._id)}>
            <Icon type="mdiFileOutline" />
            <span className="ps-2 fw-bolder">Edit</span>
          </DropdownItem>
          <DropdownItem className="px-3 d-flex" tag={ConfiguratorLink} link={seed._id} type="seed">
            <Icon type="mdiOpenInNew" />
            <span className="ps-2">Open in Configurator</span>
          </DropdownItem>
          {hasRights([RIGHTS.SEEDS__CLONE]) ? (
            <DropdownItem className="px-3 d-flex" onClick={open}>
              <Icon type="mdiContentCopy" />
              <span className="ps-2">Duplicate</span>
            </DropdownItem>
          ) : null}
          {!isPublicSeed && hasRights([RIGHTS.PROJECTS__UPDATE]) ? (
            <DropdownItem className="px-3 d-flex" onClick={handleClickOnMakePublic} data-seed-id={seed._id}>
              <Icon type="mdiCheck" />
              <span className="ps-2">Make public</span>
            </DropdownItem>
          ) : null}
          {hasRights([RIGHTS.SEEDS__DELETE]) ? (
            <DropdownItem className="px-3 d-flex dropdown-item-danger" onClick={deleteSeed}>
              <Icon type="mdiDeleteOutline" />
              <span className="ps-2">Delete</span>
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default ProjectDashboardVersionActions;
