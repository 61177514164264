import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AssetKindType } from 'types';
import { assetsActions, ASSET_TYPES } from '../../../modules/assets';
import { useModal } from '../../../utils/hooks';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import FileUpload from '../../FileUpload';
import api from '../../../utils/api';
import { LOST_AND_FOUND_KEY } from '../ProjectList';

interface AssetUploadProps {
  selectedProject: string;
  type?: AssetKindType;
  size?: string;
}

const AssetUpload = ({ selectedProject, type = ASSET_TYPES.IMAGES, size = 'md' }: AssetUploadProps) => {
  const dispatch = useDispatch();

  const updateTexturesAfterUpload = useCallback(
    (assets: AssetKindType[]) => {
      dispatch(assetsActions.textures.updateAssetsAfterUpload(assets));
    },
    [dispatch]
  );
  const updateImagesAfterUpload = useCallback(
    (assets: AssetKindType[]) => {
      dispatch(assetsActions.images.updateAssetsAfterUpload(assets));
    },
    [dispatch]
  );
  const updateModelsAfterUpload = useCallback(
    (assets: AssetKindType[]) => {
      dispatch(assetsActions.models.updateAssetsAfterUpload(assets));
    },
    [dispatch]
  );

  let rights = [RIGHTS.IMAGES__UPLOAD];
  let updateHandler = updateImagesAfterUpload;
  let apiUpload = api.assets.images.multiUpload(selectedProject);
  let caption = 'Upload images';

  if (type === ASSET_TYPES.TEXTURES) {
    rights = [RIGHTS.TEXTURES__UPLOAD];
    updateHandler = updateTexturesAfterUpload;
    apiUpload = api.assets.textures.multiUpload(selectedProject);
    caption = 'Upload textures';
  }

  if (type === ASSET_TYPES.MODELS) {
    rights = [RIGHTS.MODELS__UPLOAD];
    updateHandler = updateModelsAfterUpload;
    apiUpload = api.assets.models.multiUpload(selectedProject);
    caption = 'Upload models';
  }

  if (!selectedProject) {
    rights = [RIGHTS.ASSETS__MANIPULATE_GLOBALS];
  }

  const { isOpen, open, close } = useModal();

  return selectedProject === LOST_AND_FOUND_KEY ? null : (
    <>
      <ProtectedButton rights={rights} color="danger" className="pull-right flex-grow-0" onClick={open} size={size}>
        <i className="fa fa-fw fa-upload" />
        Upload
      </ProtectedButton>
      {isOpen ? (
        <FileUpload
          onClose={close}
          api={apiUpload}
          caption={caption}
          onSuccess={updateHandler}
          limit={10}
          multiUpload
        />
      ) : null}
    </>
  );
};

export default AssetUpload;
