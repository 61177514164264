import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ApplicationRoute, getIframeScriptUrl } from '../../../utils/appRoutes';
import { iframeScriptsSelectors } from '../../../modules/iframeScripts';
import BreadcrumbSection from '../BreadcrumbSection';
import BreadcrumbBackButton from '../BreadcrumbBackButton';

const IframeScriptsBreadcrumbs: FC = () => {
  const match = useRouteMatch<{ scriptId?: string }>();
  const isListPage = match.path === ApplicationRoute.IframeScripts;
  const scripts = useSelector(iframeScriptsSelectors.selectIframeScriptList);
  const list = useMemo(
    () =>
      scripts.map(script => ({
        label: `version: ${script.version}`,
        id: script._id,
        path: getIframeScriptUrl(script._id)
      })),
    [scripts]
  );

  return (
    <>
      <BreadcrumbBackButton to={isListPage ? ApplicationRoute.Main : ApplicationRoute.IframeScripts} />
      <BreadcrumbSection title="Iframe scripts" list={list} activeId={match.params.scriptId} />
    </>
  );
};

export default IframeScriptsBreadcrumbs;
