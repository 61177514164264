import { useCallback, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import { SeedType, RootStateType, SeedPriceType } from 'types';
import { usePartList } from '../../../utils/hooks';
import { partsSelectors } from '../../../modules/parts';
import Loader from '../../Loader/Loader';
import Form from '../../Form/Form';
import { useUpdateSeedPrices } from '../../../store/seed';
import { seedsSelectors } from '../../../modules/seeds';
import PriceList from './PriceList';

const subscription = { submitting: true, pristinge: true };

export interface PriceEditorProps {
  seed: SeedType;
}

interface Item {
  schemes: number[];
  code: string;
}

interface ExplodedPrice {
  code: string;
  scheme: string;
  price: number;
}

const PriceEditor = ({ seed }: PriceEditorProps) => {
  const { request } = usePartList(seed._id);
  const seedsAreLoading = useSelector(seedsSelectors.selectSeedsRequest);
  const { updateSeedPrices } = useUpdateSeedPrices();
  const [isSaving, setIsSaving] = useState(false);

  const pricesList = useSelector((state: RootStateType) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    partsSelectors.selectAllPriceCodes(state, seed._id)
  );

  const useMultiplePriceSchemes = seed.settings?.price?.useMultiplePriceSchemes;

  const explodePrices = useCallback(
    values =>
      values.reduce((result: ExplodedPrice[], item: Item) => {
        Object.keys(item.schemes).forEach((itemKey: any) => {
          const price = item.schemes[itemKey];

          if (typeof price === 'number') {
            result.push({ code: item.code, scheme: itemKey, price });
          }
        });

        return result;
      }, []),
    []
  );

  const onSubmit = useCallback(
    value => {
      setIsSaving(true);
      updateSeedPrices({ _id: seed._id, prices: explodePrices(value.prices) }).then(() => {
        setIsSaving(false);
      });
    },
    [updateSeedPrices, explodePrices, seed._id]
  );

  const handleRawSubmit = useCallback(
    async (value: { prices: SeedPriceType[] }) => {
      setIsSaving(true);
      await updateSeedPrices({ _id: seed._id, prices: value.prices });
      setIsSaving(false);
    },
    [seed._id, updateSeedPrices]
  );

  return (
    <Loader loading={request || isSaving || seedsAreLoading}>
      <Form name="Prices" onSubmit={onSubmit} initialValues={{ prices: pricesList }}>
        <p>Each cell corresponds to a price code and pricing scheme used in the model.</p>
        <FieldArray
          name="prices"
          subscription={subscription}
          seed={seed}
          isMultipleSchemes={useMultiplePriceSchemes}
          onRawSubmit={handleRawSubmit}
          component={PriceList as FunctionComponent}
        />
        <br />
      </Form>
    </Loader>
  );
};

export default PriceEditor;
