import { FC, SyntheticEvent, useCallback } from 'react';
import { BSClassNameFormatter } from '../helpers';

interface PaginationItemProps {
  onClick(index: number, e?: SyntheticEvent<HTMLButtonElement>): void;
  page?: number;
  active?: boolean;
  disabled?: boolean;
  type?: 'previous' | 'next' | 'first' | 'last';
  className?: string;
}

const defaultChildren = {
  previous: '\u2039',
  next: '\u203A',
  first: '\xAB',
  last: '\xBB'
};

const PaginationItem: FC<PaginationItemProps> = ({
  onClick,
  page = 0,
  active,
  disabled,
  type,
  children,
  className
}) => {
  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const { page: i } = e.currentTarget.dataset;

      onClick(Number(i), e);
    },
    [onClick]
  );
  const caret = type ? defaultChildren[type] : null;

  return (
    <li
      className={BSClassNameFormatter(
        `page-item ${className}`,
        undefined,
        active ? 'active' : '',
        disabled ? 'disabled' : ''
      )}
    >
      <button className="page-link" type="button" onClick={handleClick} data-page={page}>
        {children || caret || page + 1}
      </button>
    </li>
  );
};

export default PaginationItem;
