import { useContext } from 'react';
import { useController } from 'react-hook-form';
import RSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormContext } from '../../FormContext';
import { DefaultOptionType, FieldType, SelectProps } from '../../types';
import FieldWrapper from '../FieldWrapper';
import { getConvertedRegisterOptions, getThemeForSelectComponent } from '../helpers';

const Select = <T, K = DefaultOptionType>({
  name,
  description,
  label,
  required,
  options = [],
  getOptionValue = (option?: any) => `${option?.value}`,
  getOptionLabel = (option?: any) => `${option?.label}`,
  disabled,
  onChange,
  index,
  useShadowValue,
  creatable
}: SelectProps<T, K>) => {
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const { readOnly } = useContext(FormContext);

  const fieldError: any = fieldState.error;

  let value = field.value !== undefined ? options.find(option => getOptionValue(option) === field.value) : null;

  if (!value && field.value && creatable) {
    value = { label: field.value, value: field.value } as any;
  }

  if (!value) {
    value = { label: '', value: undefined } as any;
  }

  const SelectComponent = creatable ? CreatableSelect : RSelect;

  return (
    <FieldWrapper
      type={FieldType.SELECT}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
      useShadowValue={useShadowValue}
    >
      <SelectComponent<K>
        defaultValue={undefined}
        value={value}
        options={options}
        onChange={(option, triggeredAction) => {
          field.onChange(triggeredAction.action === 'clear' ? undefined : getOptionValue(option));
          onChange && onChange(option, index);
        }}
        onBlur={field.onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled || readOnly}
        isClearable={!required}
        className={fieldState.invalid ? 'is-invalid' : ''}
        theme={theme => getThemeForSelectComponent(theme, fieldState.invalid)}
      />
    </FieldWrapper>
  );
};

export default Select;
