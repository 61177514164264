import { reducer } from '../@helpers';
import assetsActions from './assetsActions';
import ASSET_TYPES from './assetTypes';
/*
  This reducer collects both images and textures - it is important that assetType is declared in success payload
*/

const makeAssetModuleReducer = assetType => {
  const moduleReducer = (state, { type, payload = {} }) => {
    if (type === assetsActions[assetType].updateAssetsAfterUpload.ACTION_TYPE) {
      const list = { ...state.list };

      payload.assets.forEach(asset => {
        list[asset._id] = asset;
      });

      return { ...state, list };
    }

    return state;
  };

  return reducer.createBaseModuleReducer(assetsActions[assetType], moduleReducer);
};

const moduleModelsReducer = (state, { type, payload = {} }) => {
  if (type === assetsActions[ASSET_TYPES.MODELS].updateAssetsAfterUpload.ACTION_TYPE) {
    const list = { ...state.list };

    payload.assets.forEach(asset => {
      list[asset._id] = asset;
    });

    return { ...state, list };
  }

  return state;
};

const assetsReducer = {
  images: makeAssetModuleReducer(ASSET_TYPES.IMAGES),
  textures: makeAssetModuleReducer(ASSET_TYPES.TEXTURES),
  models: reducer.createBaseModuleReducer(assetsActions[ASSET_TYPES.MODELS], moduleModelsReducer)
};

export default assetsReducer;
