import { PartType, ProjectType, SeedType } from 'types';
import React, { useCallback, useState } from 'react';
import { Form, FormField, Icon, PageTile } from '../Atoms';
import { useProjectList } from '../../store/project';
import api from '../../utils/api/routes';
import { useSeedListByProjectForSelect } from '../../store/seed';

interface SeedInfoFormProps {
  seed: SeedType;
  onSubmit(value: SeedType): void;
}

const rootPartIdFilter = (value: string, list: PartType[]) =>
  list.filter(part => part.ReferenceName.toLowerCase().includes(value.toLowerCase()));

const useFetchSeedParts = (seedId: string) => {
  const [parts, setParts] = useState<PartType[]>([]);

  return useCallback(async () => {
    if (parts.length !== 0) return parts;

    const { data = [] } = await api.parts.getList(seedId, ['ReferenceName', 'Option']);

    setParts(data);

    return data;
  }, [parts, seedId]);
};

const SeedInfoForm = ({ seed, onSubmit }: SeedInfoFormProps) => {
  const { projects } = useProjectList();
  const { seeds } = useSeedListByProjectForSelect(seed.projectId);
  const fetchParts = useFetchSeedParts(seed._id);

  return (
    <Form<SeedType> defaultValues={seed} onSubmit={onSubmit}>
      <PageTile className="mb-4 d-flex align-items-center">
        <div className="text-primary ms-2">
          <Icon type="mdiFileSettingsOutline" scale={2} />
        </div>
        <div className="ps-4">
          <h2 className="fw-bold mb-0">{seed.name}</h2>
          <small className="text-muted">These settings will be applied to the whole seed</small>
        </div>
      </PageTile>
      <PageTile>
        <FormField.Text<SeedType> name="name" label="Name" required />
        <FormField.Text<SeedType> name="version" label="Version" required />
        <FormField.Text<SeedType> name="nameLong" label="Longer descriptive name" />
        <FormField.Text<SeedType> name="description" label="Description" />
        <FormField.Select<SeedType, ProjectType>
          name="projectId"
          label="Project"
          options={projects}
          getOptionValue={project => `${project?._id}`}
          getOptionLabel={project => `${project?.name}`}
          required
        />
        <FormField.AsyncSelect<SeedType, PartType>
          name="rootPartId"
          label="Root part"
          filter={rootPartIdFilter}
          api={fetchParts}
          getOptionValue={part => `${part?._id}`}
          getOptionLabel={part => `${part?.ReferenceName} | ${part?.Option}`}
          required
        />
        <FormField.Switch<SeedType> name="deprecated" label="Deprecated" />
        <FormField.Condition<SeedType>
          name="deprecated"
          conditionalFields={['linkToNewProject']}
          condition={value => value === true}
        >
          <FormField.Select<SeedType, ProjectType>
            name="linkToNewProject"
            label="Link to new project"
            options={projects}
            getOptionValue={project => `${project?.link}`}
            getOptionLabel={project => `${project?.name}`}
            required
          />
        </FormField.Condition>
        <FormField.MultiSelect<SeedType>
          name="settings.childSeedIds"
          label="Child seeds"
          description="These seeds and parts will be loaded together with current seed"
          options={seeds}
        />
        <FormField.Condition<SeedType>
          name="settings.childSeedIds"
          conditionalFields={['settings.hideChildSeedUI']}
          condition={value => Array.isArray(value) && value.length > 0}
        >
          <FormField.Switch<SeedType>
            name="settings.hideChildSeedUI"
            label="Hide child settings UI"
            description="Hides controls, views, cameras of child seeds"
          />
        </FormField.Condition>
        <hr />
        <FormField.Toggle<SeedType>
          name="assets.enabled"
          label="Use external 3D assets"
          description="This feature is experimental"
        />
        <FormField.Condition<SeedType>
          name="assets.enabled"
          conditionalFields={['assets.urls']}
          condition={value => value === true}
        >
          <FormField.ArrayWrapper<SeedType>
            name="assets.urls"
            label="Urls of asset files."
            component={<FormField.Text<SeedType> name="assets.urls" label="" />}
            description="Each root node will be loaded as a part. Use *.glb or *.gltf files."
          />
        </FormField.Condition>
      </PageTile>
    </Form>
  );
};

export default SeedInfoForm;
