import React from 'react';
import { Page, PageContent, PageHeader } from '../Page';
import FactoryMapsList from './FactoryMapsList';
import FactoryMapCreation from './FactoryMapCreation';

const FactoryMapsPage = () => (
  <Page>
    <PageHeader className="d-flex flex-row flex-wrap align-items-center justify-content-end pb-3">
      <FactoryMapCreation />
    </PageHeader>
    <PageContent>
      <FactoryMapsList />
    </PageContent>
  </Page>
);

export default FactoryMapsPage;
