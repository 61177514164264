import { useCallback } from 'react';
import ProtectedButton from '../ProtectedButton';
import Form from '../Form';
import Field from '../Form/Field';
import { useModal } from '../../utils/hooks';

interface RawEditorProps {
  onSubmit: (result: { json: any }) => void;
  initial?: { json: any };
  rights?: string[];
  header?: string;
  onlyModal?: boolean;
  onClose?: () => void;
  block?: boolean;
}

const RawEditor = ({
  onSubmit,
  initial = { json: {} },
  rights = [],
  header = '',
  onlyModal = false,
  onClose,
  block = false
}: RawEditorProps) => {
  const { isOpen, open, close } = useModal(onlyModal);
  const handleClose = useCallback(() => {
    onClose && onClose();
    close();
  }, [close, onClose]);

  return (
    <>
      <Form
        initialValues={initial}
        inModal
        closeModal={handleClose}
        name={header}
        onSubmit={onSubmit}
        isModalOpen={isOpen}
      >
        <Field.Json name="json" />
      </Form>
      {onlyModal ? null : (
        <ProtectedButton rights={rights} onClick={open} color="primary" outline block={block}>
          {`Edit as JSON {...}`}
        </ProtectedButton>
      )}
    </>
  );
};

export default RawEditor;
