import { useSelector } from 'react-redux';
import * as authSelectors from './authSelectors';

// eslint-disable-next-line import/prefer-default-export
export const useAuthInvitation = () => {
  const checked = useSelector(authSelectors.selectInvitationChecked);
  const valid = useSelector(authSelectors.selectInvitationValid);
  const invitation = useSelector(authSelectors.selectInvitationValue);

  return { checked, valid, invitation };
};
