import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import numberformat from 'number-format.js';
import b from 'b_';
import { CellProps, Column } from 'react-table';
import { InstanceType, VisitorType } from 'types';
import { authSelectors } from '../../modules/auth';
import OpenConfiguratorLink from '../OpenConfiguratorLink';
import { Table } from '../Atoms';
import InstanceDetails from './InstanceDetails';

import './VisitorInstanceList.scss';

const visitorInstanceList = b.with('visitor-instance-list');

const getRowId = (row: InstanceType) => row._id;

const columns: Column<InstanceType>[] = [
  {
    id: 'primary',
    accessor: row => Number(!!row.lead?.primary),
    width: 30,
    Cell: ({ value }: CellProps<InstanceType, boolean>) => (
      <i className={`${visitorInstanceList('icon', { active: !!value })} fa fa-fw fa-bookmark`} />
    ),
    disableFilters: true
  },
  {
    id: 'hasFirstName',
    accessor: row => Number(!!row.lead?.firstName),
    width: 30,
    Cell: ({ value }: CellProps<InstanceType, boolean>) => (
      <i className={`${visitorInstanceList('icon', { active: !!value })} fa fa-fw fa-envelope`} />
    ),
    disableFilters: true
  },
  {
    accessor: 'factoryMap',
    width: 30,
    Cell: ({ value }) => <i className={`${visitorInstanceList('icon', { active: !!value })} fa fa-fw fa-industry`} />,
    disableFilters: true
  },
  {
    Header: 'House type',
    id: 'houseType',
    accessor: row => row.populated?.seed?.name
  },
  {
    Header: 'Saved design',
    accessor: 'instanceName'
  },
  {
    Header: 'Reservation Fee',
    id: 'reservationFee',
    accessor: row => row.lead?.reservationFee,
    Cell: ({ value }: CellProps<InstanceType, number>) => value || 0,
    width: 120
  },
  {
    Header: 'Total Price',
    accessor: 'summary',
    width: 100,
    Cell: ({ value }) =>
      value?.total ? (
        <span>
          {value.currency} {numberformat('### ###.', value.total)}
        </span>
      ) : null,
    disableFilters: true
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    width: 145,
    Cell: ({ value }) => new Date(value).toLocaleString(),
    disableFilters: true
  },
  {
    Header: 'Updated',
    accessor: 'updatedAt',
    width: 145,
    Cell: ({ value }) => new Date(value).toLocaleString(),

    disableFilters: true
  },
  {
    accessor: '_id',
    width: 150,
    Cell: ({ value }) => <OpenConfiguratorLink type="id" link={value} caption="Open configurator" />,
    disableFilters: true,
    disableSortBy: true
  }
];

const VisitorInstanceList = ({ visitor }: { visitor: VisitorType }) => {
  const [selectedInstanceId, setSelectedInstanceId] = useState('');

  const isReservationFeeColumnVisible = useSelector(authSelectors.selectUserRequestsShowReservationFee);
  const isSendToProductionAvailable = useSelector(authSelectors.selectUserRequestsShowSendToProduction);

  const selectedInstance = useMemo(
    () => visitor.instances.find(instance => instance._id === selectedInstanceId),
    [selectedInstanceId, visitor.instances]
  );

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!isSendToProductionAvailable) cols.push('factoryMap');

    if (!isReservationFeeColumnVisible) cols.push('reservationFee');

    return cols;
  }, [isReservationFeeColumnVisible, isSendToProductionAvailable]);

  return (
    <div className={visitorInstanceList()}>
      <Table
        data={visitor.instances}
        columns={columns}
        hiddenColumns={hiddenColumns}
        className={`${visitorInstanceList('table')}`}
        getRowId={getRowId}
        onSelectRowId={setSelectedInstanceId}
        selectedRowId={selectedInstanceId}
      />
      {selectedInstance ? <InstanceDetails instance={selectedInstance} /> : null}
    </div>
  );
};

export default VisitorInstanceList;
