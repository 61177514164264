import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { useSeed } from '../../store/seed';
import PricesEditor from './PricesEditor';

const Placeholder = () => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <p>The model was not found</p>
  </div>
);

const PricesPage = () => {
  const { seedId } = useParams();
  const { seed, isLoading } = useSeed(seedId);

  return (
    <Loader loading={isLoading}>
      <div className="m-3">{seed ? <PricesEditor seed={seed} /> : <Placeholder />}</div>
    </Loader>
  );
};

export default PricesPage;
