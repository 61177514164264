import { actions } from '../@helpers';

const MODULE_NAME = 'USERS';
const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const usersActions = {
  FETCH_USERS: moduleActions.fetchList.ACTION_TYPE,
  UPDATE_USER: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_USER: moduleActions.deleteEntity.ACTION_TYPE,

  fetchUsers: moduleActions.fetchList,
  updateUser: moduleActions.updateEntity,
  deleteUser: moduleActions.deleteEntity,

  ...moduleActions
};

export default usersActions;
