import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AssetKindType } from 'types';
import { Badge, Nav, NavItem, NavLink } from '../../Atoms';
import { ASSET_TYPES } from '../../../modules/assets';
import { useQueryParamsForLinkFormatter } from '../../../utils/hooks';

const ASSETS_TABS = [
  {
    type: ASSET_TYPES.IMAGES,
    name: 'Images'
  },
  {
    type: ASSET_TYPES.TEXTURES,
    name: 'Textures'
  },
  {
    type: ASSET_TYPES.MODELS,
    name: 'Models'
  }
];

interface AssetsTabsProps {
  activeType: AssetKindType;
  imagesLength: number;
  texturesLength: number;
  modelsLength: number;
}
const AssetsTabs = ({ activeType, imagesLength, texturesLength, modelsLength }: AssetsTabsProps) => {
  const formatParams = useQueryParamsForLinkFormatter();
  const getCount = useCallback(
    type => {
      if (type === ASSET_TYPES.TEXTURES) {
        return texturesLength;
      }

      if (type === ASSET_TYPES.MODELS) {
        return modelsLength;
      }

      return imagesLength;
    },
    [imagesLength, modelsLength, texturesLength]
  );

  return (
    <Nav tabs className="flex-grow-1">
      {ASSETS_TABS.map(tab => (
        <NavItem key={tab.type}>
          <NavLink active={activeType === tab.type} tag={Link} to={{ search: formatParams({ type: tab.type }) }}>
            {tab.name}{' '}
            <Badge color="info" pill>
              {getCount(tab.type)}
            </Badge>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default AssetsTabs;
