import { Button, ButtonGroup } from '../Atoms';
import { useUpdateSeed } from '../../store/seed';
import ProjectSettingsDashboard from '../ProjectPage/ProjectSettingsDashboard';
import { useSwitch } from '../../utils/hooks';
import SeedSettings from './Seed/SeedSettings';
import SeedPageWrapper from './SeedPageWrapper';

const SeedSettingsPage = () => {
  const { updateSeedMainScope } = useUpdateSeed();

  const { isOpen: showNew, toggle } = useSwitch(false);

  return (
    <SeedPageWrapper>
      {seed => {
        return (
          <>
            <ButtonGroup>
              <Button onClick={toggle} outline={!showNew}>
                New Ui
              </Button>
              <Button onClick={toggle} outline={showNew}>
                Old Ui
              </Button>
            </ButtonGroup>
            {!showNew && <SeedSettings seed={seed} onSubmit={updateSeedMainScope} />}
            {showNew && <ProjectSettingsDashboard seedId={seed._id} projectId={seed.projectId} />}
          </>
        );
      }}
    </SeedPageWrapper>
  );
};

export default SeedSettingsPage;
