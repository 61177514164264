import { FC } from 'react';
import { BSClassNameFormatter, BSContainerType } from '../helpers';

interface ContainerProps {
  type?: BSContainerType;
  className?: string;
}

const Container: FC<ContainerProps> = ({ children, type, className }) => {
  return (
    <div className={BSClassNameFormatter(type ? `container-${type}` : 'container', undefined, className)}>
      {children}
    </div>
  );
};

export default Container;
