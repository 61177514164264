import { ReactNode, SyntheticEvent } from 'react';
import { Button, ButtonGroup } from '../../../Atoms';

export interface GraphActionType {
  name: string;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  label: string;
}

interface CanvasControlsProps {
  className: string;
  onZoomExtents: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onReorder: () => void;
  toolbar: ReactNode;
}

const CanvasControls = ({ className, onZoomExtents, onReorder, toolbar }: CanvasControlsProps) => {
  return (
    <ButtonGroup className={className}>
      <Button onClick={onReorder} title="Reorder items algorithmically">
        <span className="fa fa-fw fa-random" /> Reorder
      </Button>
      <Button title="Zoom extents" color="secondary" outline onClick={onZoomExtents}>
        <i className="fa fa-fw fa-search" />
      </Button>
      {toolbar}
    </ButtonGroup>
  );
};

export default CanvasControls;
