/* eslint-disable react/require-default-props */
import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Page.scss';

const page = b.with('page');

const getClassName = (block, custom) => (custom ? `${block} ${custom}` : block);

export const Page = ({ children, className = '' }) => (
  <div className={page()}>
    <div className={getClassName(page('container'), className)}>{children}</div>
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export const PageTitle = ({ title }) => <h3 className="h3 text-primary">{title}</h3>;

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export const PageHeader = ({ children, className = '' }) => (
  <div className={getClassName(page('header'))}>
    <div className={getClassName(page('header-content'), className)}>{children}</div>
  </div>
);

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export const PageContent = ({ children, className = '', fixed = true }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const handleResize = useCallback(() => {
    const { parentElement } = ref.current;
    const parentHeight = parentElement.clientHeight;
    const headerHeight = parentElement.firstElementChild.clientHeight;

    setHeight(parentHeight - headerHeight);
  }, []);

  useEffect(() => {
    if (fixed) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fixed, handleResize]);

  useEffect(() => {
    if (fixed) {
      handleResize();
    }
  }, [fixed, handleResize]);

  const style = fixed ? { height: `${height}px` } : {};

  return (
    <div className={getClassName(page('content', { fixed }), className)} style={style} ref={ref}>
      {children}
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fixed: PropTypes.bool
};
