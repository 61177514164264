import { FC } from 'react';
import { ListPageWrapper } from '../Atoms';
import { useSubscriptionList } from '../../store/subscription';
import SubscriptionTable from './SubscriptionTable';

const SubscriptionList: FC = () => {
  const { subscriptions, isInProgress } = useSubscriptionList();

  return (
    <ListPageWrapper title="Subscriptions" loading={isInProgress}>
      <SubscriptionTable subscriptions={subscriptions} />
    </ListPageWrapper>
  );
};

export default SubscriptionList;
