import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import { Alert } from '../Atoms';
import { settingsActions, settingsSelectors } from '../../modules/settings';
import SettingsEditor from '../SettingsEditor';
import ProtectedButton from '../ProtectedButton';
import { RIGHTS } from '../../utils/rights';

const globalSettingsPage = b.with('global-settings-page');

export class GlobalSettingsPage extends React.PureComponent {
  state = {
    invalid: false,
    value: ''
  };

  componentDidMount() {
    this.props.fetchGlobalSettings();
  }

  get isDisabledButton() {
    return !this.state.value || this.state.invalid;
  }

  handleChange = value => {
    this.setState(prevState => ({ ...prevState, value }));
  };

  handleValid = invalid => {
    this.setState(prevState => ({ ...prevState, invalid }));
  };

  handleSaveSettings = () => {
    this.props.updateGlobalSettings(JSON.parse(this.state.value));
    this.handleChange('');
  };

  render() {
    const { settings } = this.props;

    return (
      <div className={`${globalSettingsPage()} w-100 p-3 flex-grow-1`}>
        <Alert color="warning">
          <strong>Be careful!</strong> Values below are applied to all objects as a default settings, regardless of
          partner, project or seed
        </Alert>
        <div className="mb-3">
          <SettingsEditor value={settings} onChange={this.handleChange} onValid={this.handleValid} />
        </div>
        <ProtectedButton
          color="primary"
          onClick={this.handleSaveSettings}
          rights={[RIGHTS.GLOBAL_SETTINGS__UPDATE]}
          disabled={this.isDisabledButton}
        >
          Save settings
        </ProtectedButton>
      </div>
    );
  }
}

GlobalSettingsPage.defaultProps = {
  settings: ''
};

GlobalSettingsPage.propTypes = {
  fetchGlobalSettings: PropTypes.func.isRequired,
  updateGlobalSettings: PropTypes.func.isRequired,
  settings: PropTypes.string
};

const mapStateToProps = state => ({
  settings: settingsSelectors.selectParsedGlobalSettings(state)
});

const mapDispatchToProps = {
  fetchGlobalSettings: settingsActions.fetchGlobalSettings,
  updateGlobalSettings: settingsActions.updateGlobalSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSettingsPage);
