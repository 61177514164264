import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import { uiActions, uiSelectors } from '../../modules/ui';
import { TYPE_NOTIFICATIONS } from '../../utils/propTypes';
import Notification from './Notification';

import './Notifications.scss';

const className = b.with('notifications');

export const Notifications = ({ notifications, hide }) => {
  const handleHideNotification = useCallback(
    id => {
      hide(id);
    },
    [hide]
  );

  return (
    <div className={className()}>
      {notifications.map(notification => (
        <Notification key={notification.id} notification={notification} onHide={handleHideNotification} />
      ))}
    </div>
  );
};

Notifications.defaultProps = {
  notifications: [],
  hide: () => null
};

Notifications.propTypes = {
  notifications: TYPE_NOTIFICATIONS,
  hide: PropTypes.func
};

const mapStateToProps = state => ({
  notifications: uiSelectors.selectVisibleNotifications(state)
});

const mapDispatchToProps = {
  hide: uiActions.hideNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
