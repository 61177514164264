import { ReactNode, useCallback, useMemo, useState, useRef } from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';

export interface GraphNodeRenderProps {
  isSelected: boolean;
  onSelect: (id: string) => void;
  onDeselect: () => void;
}

export interface GraphNodeShape {
  id: string;
  label: string;
  x: number;
  y: number;
  pristine: boolean;
  height: number;
  hasParent?: boolean;
  render: (p: GraphNodeRenderProps) => ReactNode;
  className?: string;
}

interface GraphNodeProps {
  node: GraphNodeShape;
  onSelect: (id: string) => void;
  isSelected: boolean;
  color?: string; // one of bootstrap colors,
  onDragStart: (nodeId: string) => void;
  onDragStop: (nodeId: string, dX: number, dY: number, sX: number, sY: number) => void;
  onDrag: (e: DraggableEvent) => void;
  scale?: number;
  onDeselect: () => void;
  primaryActionIndex?: number;
}

const GraphNode = ({
  node,
  onSelect,
  isSelected,
  onDragStart,
  onDragStop,
  onDrag,
  scale = 1,
  onDeselect
}: GraphNodeProps) => {
  const { x, y } = node;
  const nodeRef = useRef<HTMLDivElement>(null);

  const [dragStartState, setDragStartState] = useState({ startX: 0, startY: 0 });
  const dragStartHandler = useCallback(
    ({ clientX: startX, clientY: startY }) => {
      onDragStart(node.id);
      setDragStartState({ startX, startY });
    },
    [node.id, onDragStart]
  );

  const dragStopHandler = useCallback(
    ({ clientX: endX, clientY: endY }) => {
      const { startX, startY } = dragStartState;

      onDragStop(node.id, (endX - startX) / scale, (endY - startY) / scale, x, y);
    },
    [dragStartState, node.id, onDragStop, scale, x, y]
  );

  // memoized so it wouldn't create a new object each render
  const position = useMemo(() => ({ x, y }), [x, y]);

  return useMemo(() => {
    return (
      <Draggable
        nodeRef={nodeRef}
        onStart={dragStartHandler}
        scale={scale}
        position={position}
        onStop={dragStopHandler}
        onDrag={onDrag}
        handle=".graph-node-handle"
      >
        <div ref={nodeRef} className="d-inline-block position-absolute">
          {node.render({ isSelected, onSelect, onDeselect })}
        </div>
      </Draggable>
    );
  }, [dragStartHandler, dragStopHandler, isSelected, node, onDeselect, onDrag, onSelect, position, scale]);
};

export default GraphNode;
