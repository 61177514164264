import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import b from 'b_';
import { TYPE_INSTANCE_LIST } from '../../../utils/propTypes';
import { defaultFilterMethod } from '../../../utils/helpers/table';
import { instancesActions, instancesSelectors } from '../../../modules/instances';
import { authSelectors } from '../../../modules/auth';
import Loader from '../../Loader';
import RequestDetails from './RequestDetails';

import './RequestList.scss';

const requestList = b.with('request-list');

export class RequestList extends React.PureComponent {
  state = {
    expanded: {}
  };

  componentDidMount() {
    const { instances, fetchInstances } = this.props;

    if (!instances.length) {
      fetchInstances();
    }
  }

  markAsUnread = e => {
    const { id } = e.target.dataset;

    if (id) {
      this.props.markRequestAsUnread(id);
    }
  };

  handleExpandedChange = expanded => {
    this.setState({ expanded });
  };

  renderUnreadIndicator = row =>
    row.value ? (
      <i className="fa fa-circle fa-fw text-info" title="Unread request" />
    ) : (
      <button
        className={requestList('indicator')}
        onClick={this.markAsUnread}
        data-id={row.original._id}
        type="button"
        title="Mark as unread"
      >
        <i className="fa fa-circle-thin fa-fw" />
      </button>
    );

  renderIntegrationStatus = row => {
    if (!row.value) {
      return null;
    }

    if (row.value.error) {
      return (
        <i
          className="fa fa-fw fa-exclamation-circle text-danger"
          title={`${row.value.type} - ${row.value.errorMessage}`}
        />
      );
    }

    return (
      <i
        className="fa fa-fw fa-share-square text-success"
        title={`${row.value.type} - ${new Date(row.value.finishedAt).toLocaleString()}`}
      />
    );
  };

  render() {
    const { request, instances, isIntegrationStatusVisible } = this.props;
    const { expanded } = this.state;
    const columns = [
      {
        resizable: false,
        expander: true
      },
      {
        id: 'unread',
        accessor: 'lead.unread',
        width: 35,
        className: 'p-2',
        filterable: false,
        resizable: false,
        Cell: this.renderUnreadIndicator
      },
      {
        accessor: 'orphan',
        width: 35,
        className: 'p-2',
        filterable: false,
        resizable: false,
        Cell: row =>
          row.value ? (
            <i className="fa fa-fw fa-user-secret text-secondary" title="Unregistered user" />
          ) : (
            <i className="fa fa-fw fa-user-o text-info" title="Registered user" />
          )
      },
      {
        Header: 'Email',
        accessor: 'lead.email',
        className: 'p-2',
        width: 230
      },
      {
        Header: 'First Name',
        accessor: 'lead.firstName',
        className: 'p-2',
        maxWidth: 120
      },
      {
        Header: 'Last Name',
        accessor: 'lead.lastName',
        className: 'p-2',
        maxWidth: 120
      },
      {
        accessor: 'populated.seed.name',
        Header: 'Seed',
        filterable: true,
        Cell: row => row.value,
        className: 'p-2',
        minWidth: 120
      },
      {
        Header: 'Message',
        accessor: 'lead.message',
        className: 'p-2'
      },
      {
        id: 'createdAt',
        Header: 'Created At',
        accessor: 'lead.createdAt',
        width: 140,
        Cell: c => (c.value ? new Date(c.value).toLocaleString() : ''),
        filterable: false,
        resizable: false
      },
      {
        accessor: 'lead.integration',
        className: 'p-2',
        width: 35,
        filterable: false,
        resizable: false,
        show: isIntegrationStatusVisible,
        Cell: this.renderIntegrationStatus
      }
    ];

    return (
      <Loader loading={request}>
        <ReactTable
          data={instances}
          defaultSorted={[{ id: 'createdAt', desc: true }]}
          pageSize={instances.length}
          showPagination={false}
          resizable={false}
          columns={columns}
          expanded={expanded}
          filterable
          onExpandedChange={this.handleExpandedChange}
          className={`${requestList()} app-entity-list -striped -highlight`}
          defaultFilterMethod={defaultFilterMethod}
          getTrProps={(state, rowInfo) =>
            rowInfo ? { className: requestList('row', { unread: rowInfo.row.unread }) } : {}
          }
          SubComponent={row => (
            <div className={`${requestList('details')} bg-light`}>
              <RequestDetails instance={row.original} isIntegrationStatusVisible={isIntegrationStatusVisible} />
            </div>
          )}
        />
      </Loader>
    );
  }
}

RequestList.defaultProps = {
  instances: [],
  request: false,
  isIntegrationStatusVisible: false
};

RequestList.propTypes = {
  fetchInstances: PropTypes.func.isRequired,
  markRequestAsUnread: PropTypes.func.isRequired,
  instances: TYPE_INSTANCE_LIST,
  isIntegrationStatusVisible: PropTypes.bool,
  request: PropTypes.bool
};

const mapStateToProps = state => ({
  instances: instancesSelectors.selectInstancesWithRequest(state),
  request: instancesSelectors.selectInstancesRequest(state),
  isIntegrationStatusVisible: authSelectors.selectIsIntegrationStatusVisible(state)
});

const mapDispatchToProps = {
  fetchInstances: instancesActions.fetchInstances,
  markRequestAsUnread: instancesActions.markRequestAsUnread
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestList);
