import { createSelector } from 'reselect';

export const selectStatistics = state => state.statistics.list;
export const selectStatisticsRequest = state => state.statistics.request;

export const selectStatisticList = createSelector([selectStatistics], statistics => Object.values(statistics));

export const selectStatisticsByUser = id =>
  createSelector([selectStatisticList], statistics => {
    const userStats = statistics.find(statistic => statistic.userId === id);

    if (!userStats) {
      return null;
    }

    const { seeds = {} } = userStats;

    return {
      ...userStats,
      seeds: Object.keys(seeds).map(seedId => ({
        ...seeds[seedId],
        _id: seedId,
        requests: seeds[seedId].requests || []
      }))
    };
  });
