import React, { useCallback, useMemo } from 'react';
import b from 'b_';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { Button, Table } from '../../Atoms';
import { usePartList, useQueryParamsForLinkFormatter, useUserRights } from '../../../utils/hooks';
import { defaultFilterMethod } from '../../../utils/helpers/table';
import Loader from '../../Loader';
import { RIGHTS } from '../../../utils/rights';
import ProtectedButton from '../../ProtectedButton';
import usePartEditor from '../usePartEditor';
import { useSeed } from '../../../store/seed';
import PartModals, { MODAL_MODES, MODAL_TYPES, NEW_INDEX } from './PartModals/PartModals';
import PartForm from './PartModals/PartForm';
import PartDecorator from './PartDecorator';

const partList = b.with('part-list');

const usePartsColumns = (seedId, handleDelete) => {
  const { seed } = useSeed(seedId);

  const rootPartId = seed?.rootPartId;
  const hasRights = useUserRights();
  const formatParams = useQueryParamsForLinkFormatter();

  const onDelete = useCallback(e => handleDelete(e.currentTarget.dataset.id), [handleDelete]);

  return useMemo(
    () => [
      {
        accessor: 'populated.isOrphan',
        Cell: table => {
          return <PartDecorator rootPartId={rootPartId} partId={table.row.original?._id} isOrphan={table.value} />;
        },
        disableFilters: true,
        width: 20
      },
      {
        Header: 'ReferenceName',
        accessor: 'ReferenceName',
        width: 250,
        className: 'fw-bold p-2',
        Cell: table => (
          <Link to={{ search: formatParams({ type: MODAL_TYPES.PART, partId: table.row.original._id }) }}>
            {table.value || <span className="font-italic">No Part Name</span>}
          </Link>
        )
      },
      {
        Header: 'Option',
        accessor: 'Option',
        width: 250,
        className: 'p-2',
        Cell: table => table.value || <span className="font-italic">No Option Name</span>
      },
      {
        Header: 'Controls',
        accessor: 'Controls',
        width: 250,
        className: 'p-2',
        disableFilters: true,
        disableSortBy: true,
        Cell: table => (
          <div className="d-flex flex-column">
            {table.value && Array.isArray(table.value.Object)
              ? table.value.Object.map((item, index) => (
                  <Link
                    key={item.name}
                    to={{
                      search: formatParams({
                        type: MODAL_TYPES.CONTROL,
                        partId: table.row.original._id,
                        controlIndex: index
                      })
                    }}
                  >
                    {item.name}
                  </Link>
                ))
              : null}
            {hasRights([RIGHTS.PARTS__UPDATE]) ? (
              <Link
                to={{
                  search: formatParams({
                    type: MODAL_TYPES.CONTROL,
                    partId: table.row.original._id,
                    controlIndex: NEW_INDEX
                  })
                }}
              >
                + Add new control
              </Link>
            ) : null}
          </div>
        )
      },
      {
        Header: 'Materials',
        accessor: 'Materials',
        className: 'p-2',
        disableFilters: true,
        disableSortBy: true,
        Cell: table =>
          table.value && Array.isArray(table.value) && table.value.length
            ? pluralize('material', table.value.length, true)
            : 'No materials'
      },
      {
        width: 40,
        accessor: '_id',
        id: 'edit-raw',
        className: 'text-center p-2',
        disableFilters: true,
        disableSortBy: true,
        show: hasRights([RIGHTS.PARTS__EDIT_RAW]),
        Cell: table => (
          <Link to={{ search: formatParams({ type: MODAL_TYPES.PART, partId: table.value, mode: MODAL_MODES.RAW }) }}>
            <i className="fa fa-fw fa-code" title="Edit raw" />
          </Link>
        )
      },
      {
        width: 40,
        className: 'text-center p-2',
        accessor: '_id',
        id: 'delete',
        disableFilters: true,
        disableSortBy: true,
        show: hasRights([RIGHTS.PARTS__DELETE]),
        Cell: table => (
          <Button color="text" className="m-0 p-0" size="sm" onClick={onDelete} data-id={table.value}>
            <i className="fa fa-fw fa-trash" title="Delete" />
          </Button>
        )
      }
    ],
    [formatParams, hasRights, onDelete, rootPartId]
  );
};

const PartList = () => {
  const {
    seedId,
    projectId,
    onOpenPartModal: onAddPart,
    isPartModalOpen,
    onClosePartModal,
    onCreatePart,
    handleDelete
  } = usePartEditor();

  const { parts, request } = usePartList(seedId);

  const columns = usePartsColumns(seedId, handleDelete);

  /* Sections and divs are needed for browsers correctly calculating heights and alignments */
  return (
    <Loader loading={request}>
      <section className="w-100 pt-0 p-3">
        <div>
          <ProtectedButton
            rights={[RIGHTS.PARTS__CREATE]}
            onClick={onAddPart}
            color="primary"
            block={false}
            type="button"
          >
            <i className="fa fa-fw fa-plus" />
            Add part
          </ProtectedButton>
        </div>
        <Table
          data={parts}
          defaultPageSize={50}
          columns={columns}
          filterable
          defaultFilterMethod={defaultFilterMethod}
          resizable={false}
          className={`${partList()} mt-3`}
          hover
        />
        <PartModals projectId={projectId} />
        {isPartModalOpen ? (
          <PartForm
            onSubmit={onCreatePart}
            close={onClosePartModal}
            part={{}}
            creation
            projectId={projectId}
            seedId={seedId}
          />
        ) : null}
      </section>
    </Loader>
  );
};

export default PartList;
