import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

class Alias extends React.PureComponent {
  render() {
    const { name, alias } = this.props;

    return (
      <Field
        name={name}
        render={({ input }) => (
          <Field
            name={alias}
            render={aliasField => {
              if (input.value !== aliasField.input.value) input.onChange(aliasField.input.value);

              return null;
            }}
          />
        )}
      />
    );
  }
}

Alias.propTypes = {
  name: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired
};

export default Alias;
