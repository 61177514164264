export const API_URL = process.env.REACT_APP_BACKEND_HOST;
export const CONFIGURATOR_URL = process.env.REACT_APP_CONFIGURATOR_HOST;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_HOST;

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const ACKEE_SERVER = process.env.REACT_APP_ACKEE_SERVER;
export const ACKEE_DOMAIN_ID = process.env.REACT_APP_ACKEE_DOMAIN_ID;

export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const TERMS_OF_USE_LINK = 'https://www.creatomus.com/terms-of-use-content-managers';
export const PRIVACY_POLICY_LINK = 'https://www.creatomus.com/privacy-policy';

export const HELP_LINK = 'https://creatomus.atlassian.net/wiki/spaces/DOCS/overview';
