import { forwardRef, ReactNode } from 'react';

interface ListPageItemProps {
  children?: ReactNode;
}

const ListPageItem = forwardRef<HTMLDivElement, ListPageItemProps>(({ children }, ref) => {
  return (
    <div className="py-2 border-bottom ps-2" ref={ref}>
      {children}
    </div>
  );
});

export default ListPageItem;
