import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { assetsActions } from '../../modules/assets';
import Loader from '../Loader';
import { useProjects } from '../../store/project';
import AssetsList from './AssetsList';

const AssetsPage = () => {
  const dispatch = useDispatch();
  const { isInProgress } = useProjects();

  useEffect(() => {
    dispatch(assetsActions.images.fetchList());
    dispatch(assetsActions.textures.fetchList());
    dispatch(assetsActions.models.fetchList());
  }, [dispatch]);

  // it's important that AssetsList is mounted only after projects have arrived so that the default selected project is not global ('')
  return (
    <Loader loading={isInProgress}>
      <AssetsList />
    </Loader>
  );
};

export default AssetsPage;
