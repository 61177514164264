import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { Form as RFForm, Field } from 'react-final-form';
import Select from 'react-select';
import { Col, Form, FormGroup, Label } from '../../../Atoms';
import { TYPE_USER, TYPE_SUGGESTION_LIST } from '../../../../utils/propTypes';
import { RIGHTS } from '../../../../utils/rights';
import ProtectedButton from '../../../ProtectedButton';
import { rolesSelectors } from '../../../../modules/roles';
import { partnersSelectors } from '../../../../modules/partners';
import { projectsSelectors } from '../../../../modules/projects';
import { usersActions } from '../../../../modules/users';

const userForm = b.with('user-form');

export class UserForm extends React.PureComponent {
  onDelete = () => {
    const { user, deleteUser } = this.props;
    const response = window.confirm(`Are you sure that you want to delete user "${user.username}"?`); // eslint-disable-line no-alert

    if (response) {
      deleteUser(user._id);
    }
  };

  renderForm = ({ handleSubmit, form, submitting, pristine }) => {
    const { roles, partners, projects } = this.props;

    return (
      <Form className={userForm()} onSubmit={handleSubmit} autoComplete="off">
        <FormGroup row>
          <Label for="roles" sm={2}>
            Roles
          </Label>
          <Col sm={10}>
            <Field
              name="roles"
              render={({ input }) => (
                <Select
                  isMulti
                  name="roles"
                  className="w-100"
                  value={roles.filter(({ value }) => input.value.includes(value))}
                  onChange={newOptions => input.onChange(newOptions ? newOptions.map(option => option.value) : [])}
                  options={roles}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="projectIds" sm={2}>
            Projects
          </Label>
          <Col sm={10}>
            <Field
              name="projectIds"
              render={({ input }) => (
                <Select
                  isMulti
                  name="projectIds"
                  className="w-100"
                  value={projects.filter(({ value }) => input.value.includes(value))}
                  onChange={newOptions => input.onChange(newOptions ? newOptions.map(option => option.value) : [])}
                  options={projects}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="partner" sm={2}>
            Partner
          </Label>
          <Col sm={10}>
            <Field
              name="partner"
              render={({ input }) => (
                <Select
                  name="partner"
                  className="w-100"
                  value={partners.filter(({ value }) => input.value === value)}
                  onChange={newOption => input.onChange(newOption.value)}
                  options={partners}
                />
              )}
            />
          </Col>
        </FormGroup>
        <div className={userForm('buttons')}>
          <ProtectedButton
            rights={[RIGHTS.USERS__UPDATE]}
            type="submit"
            color="primary"
            size="sm"
            disabled={submitting || pristine}
            outline
          >
            Save
          </ProtectedButton>
          <ProtectedButton
            rights={[RIGHTS.USERS__UPDATE]}
            size="sm"
            onClick={form.reset}
            disabled={submitting || pristine}
            outline
          >
            Reset
          </ProtectedButton>
          <ProtectedButton rights={[RIGHTS.USERS__DELETE]} color="danger" size="sm" onClick={this.onDelete} outline>
            Delete
          </ProtectedButton>
        </div>
      </Form>
    );
  };

  render() {
    const { user, updateUser } = this.props;

    return <RFForm onSubmit={updateUser} initialValues={user} render={this.renderForm} />;
  }
}

UserForm.defaultProps = {
  roles: [],
  partners: [],
  projects: []
};

UserForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  user: TYPE_USER, // eslint-disable-line react/require-default-props
  roles: TYPE_SUGGESTION_LIST,
  partners: TYPE_SUGGESTION_LIST,
  projects: TYPE_SUGGESTION_LIST
};

const mapStateToProps = state => ({
  roles: rolesSelectors.selectRoleListForSuggestions(state),
  partners: partnersSelectors.selectPartnerListForSuggestions(state),
  projects: projectsSelectors.selectProjectListForSuggestions(state)
});

const mapDispatchToProps = {
  updateUser: usersActions.updateUser,
  deleteUser: usersActions.deleteUser
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
