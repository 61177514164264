import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Form as RFForm, FormSpy, Field } from 'react-final-form';
import Select from 'react-select';
import { Form, FormGroup, Label } from '../../Atoms';
import { TYPE_ROLE } from '../../../utils/propTypes';
import rights, { RIGHTS } from '../../../utils/rights';
import ProtectedButton from '../../ProtectedButton';
import FormField from '../../FormField';

import './RoleForm.scss';

const roleForm = b.with('role-form');

const FIELDS = [
  { key: 'name', label: 'Name', required: true, disabled: false, placeholder: 'Role name' },
  { key: 'description', label: 'Description', required: false, disabled: false, placeholder: 'Role description' }
];

const RIGHTS_OPTIONS = rights.map(value => ({ label: value, value }));

export class RoleForm extends React.PureComponent {
  validateForm = values => {
    const errors = {};

    FIELDS.forEach(({ required, key }) => {
      if (required && !values[key]) {
        errors[key] = 'Required';
      }
    });

    return errors;
  };

  onDelete = () => {
    const { role, onDelete } = this.props;
    const response = window.confirm(`Are you sure that you want to delete role "${role.name}"?`); // eslint-disable-line no-alert

    if (response) {
      onDelete(role._id);
    }
  };

  renderForm = ({ handleSubmit, form, submitting, pristine, values }) => {
    const { isNewRole, onChange } = this.props;
    const deleteButtonTitle = values.users && values.users.length ? 'This role is used by users' : '';

    return (
      <Form className={roleForm()} onSubmit={handleSubmit} autoComplete="off">
        {FIELDS.map(field => (
          <FormField field={field} key={field.key} size="sm" />
        ))}
        <FormGroup>
          <Label for="rights">Rights</Label>
          <Field
            name="rights"
            render={({ input }) => (
              <Select
                isMulti
                name="rights"
                className="w-100"
                value={(input.value || []).map(value => ({ value, label: value }))}
                onChange={value => input.onChange(value.map(item => item.value) || [])}
                options={RIGHTS_OPTIONS}
              />
            )}
          />
        </FormGroup>
        <div className={roleForm('buttons', { hidden: isNewRole })}>
          <ProtectedButton
            rights={[RIGHTS.ROLES__UPDATE]}
            type="submit"
            color="primary"
            size="sm"
            disabled={submitting || pristine || form.getState().invalid}
            outline
          >
            Save
          </ProtectedButton>
          <ProtectedButton
            rights={[RIGHTS.ROLES__UPDATE]}
            size="sm"
            onClick={form.reset}
            disabled={submitting || pristine}
            outline
          >
            Reset
          </ProtectedButton>
          <ProtectedButton
            rights={[RIGHTS.ROLES__DELETE]}
            color="danger"
            size="sm"
            onClick={this.onDelete}
            outline
            disabled={Boolean(values.users && values.users.length)}
            title={deleteButtonTitle}
          >
            Delete
          </ProtectedButton>
        </div>
        <FormSpy subscription={{ values: true, invalid: true, pristine: true }} onChange={onChange} />
      </Form>
    );
  };

  render() {
    const { role, onSubmit } = this.props;

    return <RFForm onSubmit={onSubmit} initialValues={role} render={this.renderForm} validate={this.validateForm} />;
  }
}

RoleForm.defaultProps = {
  onSubmit: () => null,
  onChange: () => null,
  onDelete: () => null,
  isNewRole: false
};

RoleForm.propTypes = {
  role: TYPE_ROLE, // eslint-disable-line react/require-default-props
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isNewRole: PropTypes.bool
};

export default RoleForm;
