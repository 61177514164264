import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from '../Atoms';
import { PRIVACY_POLICY_LINK } from '../../config';

const PrivacyPolicyLink = ({ dropdown }) => {
  const Tag = dropdown ? DropdownItem : 'a';

  return (
    <Tag href={PRIVACY_POLICY_LINK} rel="noopener noreferrer" target="_blank">
      Privacy Policy
    </Tag>
  );
};

PrivacyPolicyLink.propTypes = {
  dropdown: PropTypes.bool
};

PrivacyPolicyLink.defaultProps = {
  dropdown: false
};

export default PrivacyPolicyLink;
