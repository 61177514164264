import React, { FC, lazy } from 'react';
import Suspense from '../Suspense/Suspense';

import './JsonViewer.scss';

interface JsonTreeProps {
  data?: any;
  hideRoot?: boolean;
}

interface JsonViewerProps extends JsonTreeProps {
  maxHeight?: number;
}

const JSONTree = lazy<FC<JsonTreeProps>>(() => import('react-json-tree') as any);

const JsonViewer = ({ data, hideRoot = true, maxHeight = 400 }: JsonViewerProps) => {
  return (
    <Suspense>
      <pre className="border rounded bg-light p-1 px-3 json-viewer" style={{ maxHeight: `${maxHeight}px` }}>
        <JSONTree data={data} hideRoot={hideRoot} />
      </pre>
    </Suspense>
  );
};

export default JsonViewer;
