import {
  mdiAccount,
  mdiAccountBoxOutline,
  mdiAccountDetailsOutline,
  mdiAccountMultipleOutline,
  mdiAccountPlusOutline,
  mdiAccountTie,
  mdiApplicationBracketsOutline,
  mdiArrowLeft,
  mdiCash,
  mdiCheck,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiClose,
  mdiCogOutline,
  mdiContentCopy,
  mdiCubeOutline,
  mdiDeleteOutline,
  mdiDotsGrid,
  mdiDotsVertical,
  mdiDrag,
  mdiFactory,
  mdiFileCogOutline,
  mdiFileOutline,
  mdiFilePdfBox,
  mdiFileSettingsOutline,
  mdiFolderCogOutline,
  mdiFolderOutline,
  mdiFolderSettingsOutline,
  mdiFormatListBulleted,
  mdiGraphOutline,
  mdiImageMultipleOutline,
  mdiImageOutline,
  mdiLightbulbOnOutline,
  mdiMagnify,
  mdiMenu,
  mdiMessageTextOutline,
  mdiMinus,
  mdiOfficeBuildingOutline,
  mdiOpenInNew,
  mdiPalette,
  mdiPaletteSwatchOutline,
  mdiPlus,
  mdiSortAlphabeticalAscending,
  mdiSortCalendarAscending,
  mdiTable,
  mdiTableLarge,
  mdiTexture,
  mdiToggleSwitchOutline,
  mdiTrashCanOutline,
  mdiVideoOutline,
  mdiWebhook,
  mdiImageText,
  mdiTranslate,
  mdiCardBulletedSettingsOutline,
  mdiListStatus,
  mdiTagTextOutline,
  mdiPaletteOutline,
  mdiCashMultiple,
  mdiLayersTripleOutline,
  mdiFormTextbox,
  mdiGestureTap,
  mdiContentSave,
  mdiTransitConnectionVariant,
  mdiCodeTags,
  mdiCookieOutline,
  mdiShareVariant,
  mdiFloorPlan,
  mdiCounter,
  mdiPanorama,
  mdiPlay,
  mdiFileCheckOutline,
  mdiFileReplaceOutline,
  mdiFileUndoOutline,
  mdiUnfoldMoreHorizontal,
  mdiUnfoldLessHorizontal,
  mdiFileSign,
  mdiFileImportOutline
} from '@mdi/js';

import './Icon.scss';

export const ICONS = {
  mdiAccount,
  mdiAccountBoxOutline,
  mdiAccountDetailsOutline,
  mdiAccountMultipleOutline,
  mdiAccountPlusOutline,
  mdiAccountTie,
  mdiApplicationBracketsOutline,
  mdiArrowLeft,
  mdiCash,
  mdiCheck,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiClose,
  mdiCogOutline,
  mdiContentCopy,
  mdiCubeOutline,
  mdiDeleteOutline,
  mdiDotsGrid,
  mdiDotsVertical,
  mdiDrag,
  mdiFactory,
  mdiFileCogOutline,
  mdiFileOutline,
  mdiFilePdfBox,
  mdiFileSettingsOutline,
  mdiFolderCogOutline,
  mdiFolderOutline,
  mdiFolderSettingsOutline,
  mdiFormatListBulleted,
  mdiGraphOutline,
  mdiImageMultipleOutline,
  mdiImageOutline,
  mdiLightbulbOnOutline,
  mdiMagnify,
  mdiMenu,
  mdiMessageTextOutline,
  mdiMinus,
  mdiOfficeBuildingOutline,
  mdiOpenInNew,
  mdiPalette,
  mdiPaletteSwatchOutline,
  mdiPlus,
  mdiSortAlphabeticalAscending,
  mdiSortCalendarAscending,
  mdiTable,
  mdiTableLarge,
  mdiTexture,
  mdiToggleSwitchOutline,
  mdiTrashCanOutline,
  mdiVideoOutline,
  mdiWebhook,
  mdiImageText,
  mdiTranslate,
  mdiCardBulletedSettingsOutline,
  mdiListStatus,
  mdiTagTextOutline,
  mdiPaletteOutline,
  mdiCashMultiple,
  mdiLayersTripleOutline,
  mdiFormTextbox,
  mdiGestureTap,
  mdiContentSave,
  mdiTransitConnectionVariant,
  mdiCodeTags,
  mdiCookieOutline,
  mdiShareVariant,
  mdiFloorPlan,
  mdiCounter,
  mdiPanorama,
  mdiPlay,
  mdiFileCheckOutline,
  mdiFileReplaceOutline,
  mdiFileUndoOutline,
  mdiUnfoldMoreHorizontal,
  mdiUnfoldLessHorizontal,
  mdiFileSign,
  mdiFileImportOutline
};

export type IconType = keyof typeof ICONS;

const getComputedStyle = (scale: number) => (scale !== 1 ? { transform: `scale(${scale})` } : undefined);

interface IconProps {
  type: IconType;
  scale?: number;
  mix?: string;
}

const Icon = ({ type, scale = 1, mix = '' }: IconProps) => {
  return (
    <i className={`icon ${mix}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={getComputedStyle(scale)}>
        <path fill="currentColor" d={ICONS[type]} />
      </svg>
    </i>
  );
};

export default Icon;
