import { useGetPartnersQuery } from './services/api';

export const usePartnersList = () => {
  const { data, isLoading, isUninitialized } = useGetPartnersQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    partners: data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};
