import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field as RFField, useField } from 'react-final-form';
import { Alert, Col, Row } from '../../Atoms';
import Form from '../../Form';
import Field from '../../Form/Field';

const FIELD_TYPES = {
  INPUT: {
    TEXT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
    PHONE: 'tel'
  },
  TEXTAREA: 'textarea',
  SELECT: 'select',
  COUNTRY_LIST: 'country-list',
  CHECKBOX: 'checkbox',
  CONSENT: 'consent',
  DIVIDER: '--- divider ---'
};

const FIELDS = [
  { label: 'Text', value: FIELD_TYPES.INPUT.TEXT },
  { label: 'Number', value: FIELD_TYPES.INPUT.NUMBER },
  { label: 'Email', value: FIELD_TYPES.INPUT.EMAIL },
  { label: 'Phone', value: FIELD_TYPES.INPUT.PHONE },
  { label: 'Textarea', value: FIELD_TYPES.TEXTAREA },
  { label: 'Country List', value: FIELD_TYPES.COUNTRY_LIST },
  { label: 'Select', value: FIELD_TYPES.SELECT },
  { label: 'Checkbox', value: FIELD_TYPES.CHECKBOX },
  { label: 'Consent', value: FIELD_TYPES.CONSENT },
  { label: 'Divider', value: FIELD_TYPES.DIVIDER }
];

const IGNORED_NAMES = new Set([
  FIELD_TYPES.INPUT.EMAIL,
  FIELD_TYPES.INPUT.PHONE,
  FIELD_TYPES.COUNTRY_LIST,
  FIELD_TYPES.CONSENT,
  FIELD_TYPES.DIVIDER
]);

const headingFormat = value => {
  if (!value.type) return null;

  if (IGNORED_NAMES.has(value.type)) {
    return value.type;
  }

  return value.name ? `${value.type}: ${value.name}` : value.type;
};

const validateFields = values => {
  const map = {};

  for (let i = 0; i < values.length; i += 1) {
    const { type } = values[i];
    let { name: value } = values[i];

    if (type === FIELD_TYPES.INPUT.EMAIL || type === FIELD_TYPES.CONSENT || type === FIELD_TYPES.DIVIDER) {
      value = type;
    }

    if (map[value] && value !== FIELD_TYPES.DIVIDER) {
      return (
        <Alert color="warning" className="my-2">
          This list requires field <strong>name</strong> to be unique. Please change the values
        </Alert>
      );
    }

    map[value] = true;
  }

  if (!map[FIELD_TYPES.INPUT.EMAIL] && values.length) {
    return (
      <Alert color="warning" className="my-2">
        Please add <strong>Email</strong> field to the list
      </Alert>
    );
  }

  return undefined;
};

const ContactFormSettings = ({ useShadowValue }) => {
  const { input: fieldInput } = useField('settings.contactForm.fields', { subscription: { value: true } });

  const availableTypes = useMemo(() => {
    const value = Array.isArray(fieldInput.value) ? fieldInput.value : [];

    const usedTypes = value.reduce((result, current) => {
      result[current.type] = true; // eslint-disable-line no-param-reassign

      return result;
    }, {});

    return FIELDS.map(field => ({
      ...field,
      isDisabled:
        (field.value === FIELD_TYPES.INPUT.EMAIL || field.value === FIELD_TYPES.CONSENT) && usedTypes[field.value]
    }));
  }, [fieldInput.value]);

  return (
    <Form.Group header="Contact form" icon="fa-envelope-o">
      <Row>
        <Col xs="12" md="6">
          <Field.Text
            name="settings.contactForm.title"
            label="Contact form title"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.contactForm.titleContacted"
            label="Contact form title (contacted)"
            description="Title to show if client has already contacted"
            useShadowValue={useShadowValue}
          />
          <Field.Text name="settings.contactForm.subtitle" label="Subtitle" useShadowValue={useShadowValue} />
          <Field.Text
            name="settings.contactForm.consentMessage"
            label="Contact form consent message"
            description=""
            useShadowValue={useShadowValue}
          />
          <Field.Text
            name="settings.contactForm.requestReceiverNotice"
            label="Notice about request receiver"
            useShadowValue={useShadowValue}
          />
          <Field.Toggle
            name="settings.contactForm.sendPdf"
            label="Send an email with a PDF file to clients instead of getting in contact"
            useShadowValue={useShadowValue}
          />
        </Col>
        <Col xs="12" md="6">
          <Field.ArrayObject
            name="settings.contactForm.fields"
            label="Fields"
            uniqueField="name"
            validate={validateFields}
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.Select
              name="type"
              label="Type"
              description="Choose field type from the list"
              options={availableTypes}
              required
            />
            <RFField
              name="type"
              render={({ input, parentName, disabled }) => {
                return (
                  <>
                    {[FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(
                      input.value
                    ) ? null : (
                      <Field.Text name={`${parentName}.name`} label="Name" required disabled={disabled} />
                    )}
                    {FIELD_TYPES.CONSENT === input.value ? null : (
                      <Field.Text name={`${parentName}.label`} label="Label" disabled={disabled} />
                    )}

                    {input.value === FIELD_TYPES.SELECT ? (
                      <Field.ArrayObject
                        name={`${parentName}.options`}
                        label="Options"
                        description="List of available options"
                        uniqueField="value"
                      >
                        <Field.Text name="value" label="Value" />
                        <Field.Text name="label" label="Label" />
                      </Field.ArrayObject>
                    ) : null}

                    {[FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(input.value) ? null : (
                      <Field.Text name={`${parentName}.autocomplete`} label="Autocomplete" disabled={disabled} />
                    )}
                    {[FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(
                      input.value
                    ) ? null : (
                      <Field.Toggle
                        name={`${parentName}.required`}
                        label="Required"
                        description=""
                        disabled={disabled}
                      />
                    )}
                    {FIELD_TYPES.CHECKBOX === input.value ? (
                      <>
                        <Field.Text name={`${parentName}.title`} label="Title" description="" disabled={disabled} />
                        <Field.Toggle
                          name={`${parentName}.default`}
                          label="Prechecked"
                          description=""
                          disabled={disabled}
                        />
                      </>
                    ) : null}
                  </>
                );
              }}
            />
          </Field.ArrayObject>
        </Col>
      </Row>
    </Form.Group>
  );
};

ContactFormSettings.propTypes = {
  useShadowValue: PropTypes.bool
};

ContactFormSettings.defaultProps = {
  useShadowValue: false
};

export default ContactFormSettings;
