import { SeedType } from 'types';
import { Link } from 'react-router-dom';
import { Badge, Icon } from '../Atoms';
import { getSeedUrl } from '../../utils/appRoutes';
import ProjectDashboardVersionActions from './ProjectDashboardVersionActions';

interface ProjectDashboardVersionItemProps {
  seed: SeedType;
  publicSeedId?: string;
}

const ProjectDashboardVersionItem = ({ seed, publicSeedId }: ProjectDashboardVersionItemProps) => {
  const isPublicSeed = seed._id === publicSeedId;

  return (
    <div className="list-group-item d-flex align-items-center">
      <div>
        <div className="d-flex align-items-center">
          <Icon type="mdiFileOutline" />
          <Link to={getSeedUrl(seed._id)} title="Open project">
            <u className="px-2 text-dark fw-bolder">{seed.name}</u>
          </Link>
          {isPublicSeed ? (
            <Badge color="warning" pill>
              Public
            </Badge>
          ) : null}
        </div>
        <small className="text-muted ps-1">{seed.version}</small>
      </div>
      <ProjectDashboardVersionActions seed={seed} isPublicSeed={isPublicSeed} />
    </div>
  );
};

export default ProjectDashboardVersionItem;
