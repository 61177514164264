import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '../../../../Atoms';

const ImageModalSelectedItem = ({ url, name }) => (
  <Col>{url && name ? <img className="img-fluid" src={url} alt={name} /> : null}</Col>
);

ImageModalSelectedItem.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string
};

ImageModalSelectedItem.defaultProps = {
  url: '',
  name: ''
};

export default ImageModalSelectedItem;
