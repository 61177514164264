import { FactoryMapType } from 'types';
import { RIGHTS } from '../../../utils/rights';
import RawEditor from '../../RawEditor';

interface FactoryMapRawEditorProps {
  onSubmit: (value: FactoryMapType) => void;
  factoryMap: any;
}

const FactoryMapRawEditor = ({ factoryMap, onSubmit }: FactoryMapRawEditorProps) => {
  const handleSubmit = ({ json }: { json: FactoryMapType }) => {
    onSubmit({ ...json, _id: factoryMap._id });
  };

  return <RawEditor rights={[RIGHTS.SUPERUSER]} initial={{ json: factoryMap }} onSubmit={handleSubmit} />;
};

export default FactoryMapRawEditor;
