import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { Image } from '../types';

export interface SummaryProps {
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
}

export const Summary = ({ projectImages, uploadImage, updateImagesAfterUpload }: SummaryProps) => {
  return (
    <>
      <FormField.Text<ProjectType> name="settings.summary.title" label="Summary title" description="" useShadowValue />
      <FormField.Textarea<ProjectType>
        name="settings.summary.text"
        label="Summary paragraph"
        description=""
        useShadowValue
      />
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'fileName', 'untitled')}
        name="settings.summary.gallery"
        label="Shown in gallery"
        useShadowValue
      >
        <FormField.Image<Image>
          name="fileName"
          label="images"
          list={projectImages}
          uploadImage={uploadImage}
          onUploadSuccess={updateImagesAfterUpload}
          useShadowValue
        />
      </FormField.ArrayWrapper>
    </>
  );
};
