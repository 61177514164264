import { all, call, cancel, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import * as authActions from './authActions';

export function* cancelWorkerSaga(task) {
  yield cancel(task);
}

export function* doLoginSaga(action) {
  yield put(authActions.loginRequest());

  const { username, password } = action.payload;
  const { data, error } = yield call(api.auth.login, username, password);

  if (error) {
    yield put(authActions.loginFailure(error.message));
  } else {
    yield put(authActions.loginSuccess(data));
  }
}

export function* watchLoginSaga() {
  yield takeLatest(authActions.LOGIN, doLoginSaga);
}

export function* doRegistrationSaga(action) {
  yield put(authActions.registrationRequest());

  const { username, password, invitationId } = action.payload;
  const { data, error } = yield call(api.auth.registration, username, password, invitationId);

  if (error) {
    yield put(authActions.registrationFailure(error.message));
  } else {
    yield put(authActions.registrationSuccess(data));
  }
}

export function* watchRegistrationSaga() {
  yield takeLatest(authActions.REGISTRATION, doRegistrationSaga);
}

export function* doForgotPasswordSaga(action) {
  yield put(authActions.forgotPasswordRequest());

  const { username, host } = action.payload;
  const { error } = yield call(api.auth.forgotPassword, username, host);

  if (error) {
    yield put(authActions.forgotPasswordFailure(error.message));
  } else {
    yield put(authActions.forgotPasswordSuccess());
  }
}

export function* watchForgotPasswordSaga() {
  yield takeLatest(authActions.FORGOT_PASSWORD, doForgotPasswordSaga);
}

export function* doVerifyTokenSaga(action) {
  yield put(authActions.verifyTokenRequest());

  const { token } = action.payload;
  const { error } = yield call(api.auth.verifyToken, token);

  if (error) {
    yield put(authActions.verifyTokenFailure());
  } else {
    yield put(authActions.verifyTokenSuccess());
  }
}

export function* watchVerifyTokenSaga() {
  yield takeLatest(authActions.VERIFY_TOKEN, doVerifyTokenSaga);
}

export function* doVerifyInvitationSaga(action) {
  yield put(authActions.verifyInvitationRequest());

  const { invitationId } = action.payload;
  const { error, data } = yield call(api.auth.verifyInvitation, invitationId);

  if (error) {
    yield put(authActions.verifyInvitationFailure());
  } else {
    yield put(authActions.verifyInvitationSuccess(data));
  }
}

export function* watchVerifyInvitationSaga() {
  yield takeLatest(authActions.VERIFY_INVITATION, doVerifyInvitationSaga);
}

export function* doResetPasswordSaga(action) {
  yield call(doVerifyTokenSaga, action);

  yield put(authActions.resetPasswordRequest());

  const { password, token } = action.payload;
  const { data, error } = yield call(api.auth.resetPassword, password, token);

  if (error) {
    yield put(authActions.resetPasswordFailure(error.message));
  } else {
    yield put(authActions.resetPasswordSuccess(data));
  }
}

export function* watchResetPasswordSaga() {
  const workerTask = yield takeLatest(authActions.RESET_PASSWORD, doResetPasswordSaga);

  yield takeLatest(authActions.VERIFY_TOKEN__FAILURE, cancelWorkerSaga, workerTask);
}

export function* doGetUserInfoSaga() {
  yield put(authActions.getUserInfoRequest());

  const { data, error } = yield call(api.auth.getUserInfo);

  if (error) {
    yield put(authActions.getUserInfoFailure());
  } else {
    yield put(authActions.getUserInfoSuccess(data));
  }
}

export function* watchGetUserInfoSaga() {
  yield takeLatest(authActions.GET_USER_INFO, doGetUserInfoSaga);
}

export function* doLogoutSaga() {
  const { error } = yield call(api.auth.logout);

  if (error) {
    yield put(authActions.logoutFailure(error.message));
  } else {
    yield put(authActions.logoutSuccess());
  }
}

export function* watchLogoutSaga() {
  yield takeLatest(authActions.LOGOUT, doLogoutSaga);
}

export function* doLoginFacebookSaga(action) {
  const { info } = action.payload;
  const { data, error } = yield call(api.auth.loginFacebook, info.accessToken);

  if (error) {
    yield put(authActions.loginFailure(error.message));
  } else {
    yield put(authActions.loginSuccess(data));
  }
}

export function* watchLoginFacebook() {
  yield takeLatest(authActions.LOGIN_FACEBOOK, doLoginFacebookSaga);
}

export function* doLoginGoogleSaga(action) {
  const { info } = action.payload;
  const { data, error } = yield call(api.auth.loginGoogle, info.tokenId);

  if (error) {
    yield put(authActions.loginFailure(error.message));
  } else {
    yield put(authActions.loginSuccess(data));
  }
}

export function* watchLoginGoogle() {
  yield takeLatest(authActions.LOGIN_GOOGLE, doLoginGoogleSaga);
}

export function* doAcceptTermsOfUseSaga() {
  yield put(authActions.acceptTermsOfUseRequest());

  const { data, error } = yield call(api.auth.acceptTermsOfUse);

  if (error) {
    yield put(authActions.acceptTermsOfUseFailure(error.message));
  } else {
    yield put(authActions.acceptTermsOfUseSuccess(data));
  }
}

export function* watchAcceptTermsOfUseSaga() {
  yield takeLatest(authActions.ACCEPT_TERMS_OF_USE, doAcceptTermsOfUseSaga);
}

export default function* userSaga() {
  yield all([
    watchLoginSaga(),
    watchRegistrationSaga(),
    watchForgotPasswordSaga(),
    watchVerifyTokenSaga(),
    watchVerifyInvitationSaga(),
    watchResetPasswordSaga(),
    watchGetUserInfoSaga(),
    watchLogoutSaga(),
    watchLoginFacebook(),
    watchLoginGoogle(),
    watchAcceptTermsOfUseSaga()
  ]);
}
