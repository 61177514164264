export const ACTION_TYPES = {
  OPEN_TAB: 'open-tab',
  OPEN_DIALOG: 'open-dialog',
  OPEN_LINK: 'open-link',
  OPEN_FIRST_CONTROL_TAB: 'open-first-control-tab',
  OPEN_LAST_CONTROL_TAB: 'open-last-control-tab'
};

const ACTON_NAMES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  MY_DESIGNS: 'my-designs',
  SAVE: 'save',
  SHARE: 'share',
  CONTACT_US: 'contact-us',
  CONTACT_US_AGAIN: 'contact-us-again',
  DOWNLOAD_PDF: 'download-pdf',
  DESIGN_YOUR_HOME: 'design-your-home',
  REVIEW_DESIGN: 'review-design',
  EDIT: 'edit',
  BACK: 'back',
  REVIEW: 'review',
  ABOUT_THIS_HOUSE: 'about-this-house',
  SUMMARY: 'summary',
  OPEN_INSTANCE: 'open-instance',
  FINISH: 'finish'
};

export const DIALOG_TYPES = {
  AUTH: 'auth',
  LOGOUT: 'logout',
  PROFILE: 'profile',
  SAVE: 'save',
  SHARE: 'share',
  CONTACT: 'contact',
  PDF: 'pdf',
  OPEN_INSTANCE: 'open-instance',
  EXPORT_MODEL: 'export-model',
  ADD_COMMENT: 'add-comment'
};

export const REQUIRE_LOGIN_DIALOGS = {
  [DIALOG_TYPES.SAVE]: true,
  [DIALOG_TYPES.SHARE]: true,
  [DIALOG_TYPES.CONTACT]: true,
  [DIALOG_TYPES.PDF]: true
};

export const ACTIONS = [
  { label: 'Open tab', value: ACTION_TYPES.OPEN_TAB },
  { label: 'Open dialog', value: ACTION_TYPES.OPEN_DIALOG },
  { label: 'Open link', value: ACTION_TYPES.OPEN_LINK },
  { label: 'Open first control tab', value: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB },
  { label: 'Open last control tab', value: ACTION_TYPES.OPEN_LAST_CONTROL_TAB }
];

const COLOR_NAMES = {
  MAIN: 'main',
  MAIN_OUTLINE: 'main-outline',
  ACCENT: 'accent',
  ACCENT_OUTLINE: 'accent-outline',
  SECONDARY: 'secondary',
  SECONDARY_HOVERED: 'secondary-hovered',
  TEXT: 'text',
  TEXT_BORDERLESS: 'text-borderless'
};

export const COLORS = [
  { label: 'Main Fill', value: COLOR_NAMES.MAIN },
  { label: 'Main Outline', value: COLOR_NAMES.MAIN_OUTLINE },
  { label: 'Accent Fill', value: COLOR_NAMES.ACCENT },
  { label: 'Accent Outline', value: COLOR_NAMES.ACCENT_OUTLINE },
  { label: 'Secondary Fill', value: COLOR_NAMES.SECONDARY },
  { label: 'Secondary Outline', value: COLOR_NAMES.SECONDARY_HOVERED },
  { label: 'Text Outline', value: COLOR_NAMES.TEXT },
  { label: 'Text Flat', value: COLOR_NAMES.TEXT_BORDERLESS }
];

export const ICONS = [
  { label: 'Save', value: 'save' },
  { label: 'Web share', value: 'web-share' },
  { label: 'Cube', value: 'cube' },
  { label: 'Cogs', value: 'cogs' },
  { label: 'Chevron left', value: 'chevron-left' }
];

export const DIALOGS = [
  { label: 'Auth', value: DIALOG_TYPES.AUTH },
  { label: 'Logout', value: DIALOG_TYPES.LOGOUT },
  { label: 'My Designs', value: DIALOG_TYPES.PROFILE },
  { label: 'Save', value: DIALOG_TYPES.SAVE },
  { label: 'Share', value: DIALOG_TYPES.SHARE },
  { label: 'Contact', value: DIALOG_TYPES.CONTACT },
  { label: 'PDF', value: DIALOG_TYPES.PDF },
  { label: 'Open instance', value: DIALOG_TYPES.OPEN_INSTANCE },
  { label: 'Export model', value: DIALOG_TYPES.EXPORT_MODEL },
  { label: 'Add comment', value: DIALOG_TYPES.ADD_COMMENT }
];

export const AUTHORIZATION_VISIBILITY_LIST = [
  { label: 'Hide for authorized users', value: 'authorized' },
  { label: 'Hide for unauthorized users', value: 'unauthorized' }
];

export const SAVING_VISIBILITY_LIST = [
  { label: 'Hide for unsaved instances', value: 'unsaved' },
  { label: 'Hide for saved instances', value: 'saved' }
];

export const REQUEST_VISIBILITY_LIST = [
  { label: 'Hide for instances with request', value: 'withRequest' },
  { label: 'Hide fot instances without request', value: 'withoutRequest' }
];

export const CONFIGURATOR_MODE_VISIBILITY_LIST = [
  { label: 'Hide for project or seed mode', value: 'noInstance' },
  { label: 'Hide fot instance mode', value: 'instance' }
];

export const getDefaultConfiguration = () => ({
  list: [
    {
      name: ACTON_NAMES.LOGIN,
      displayName: 'Log in',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.AUTH,
      hidingByAuth: 'authorized'
    },
    {
      name: ACTON_NAMES.LOGOUT,
      displayName: 'Log out',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.LOGOUT,
      hidingByAuth: 'unauthorized'
    },
    {
      name: ACTON_NAMES.MY_DESIGNS,
      displayName: 'My Designs',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.PROFILE,
      hidingByAuth: 'unauthorized'
    },
    {
      name: ACTON_NAMES.SAVE,
      displayName: 'Save',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.SAVE,
      hidingBySaving: 'saved'
    },
    {
      name: ACTON_NAMES.SHARE,
      displayName: 'Share',
      description: 'Unique link to your design',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.SHARE
    },
    {
      name: ACTON_NAMES.CONTACT_US,
      displayName: 'Contact us',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.CONTACT,
      hidingByRequest: 'withRequest'
    },
    {
      name: ACTON_NAMES.CONTACT_US_AGAIN,
      displayName: 'Contact us again',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.CONTACT,
      hidingByRequest: 'withoutRequest'
    },
    {
      name: ACTON_NAMES.DOWNLOAD_PDF,
      displayName: 'Download summary',
      description: 'File with complete information about your design',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.PDF,
      requireLogin: true
    },
    {
      name: ACTON_NAMES.DESIGN_YOUR_HOME,
      displayName: 'Design Your Home',
      type: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB,
      hidingByConfiguratorMode: 'instance'
    },
    {
      name: ACTON_NAMES.REVIEW_DESIGN,
      displayName: 'Review the design',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review',
      hidingByConfiguratorMode: 'noInstance'
    },
    {
      name: ACTON_NAMES.EDIT,
      displayName: 'Edit',
      type: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB,
      hidingByConfiguratorMode: 'noInstance'
    },
    {
      name: ACTON_NAMES.BACK,
      displayName: 'Back',
      type: ACTION_TYPES.OPEN_LAST_CONTROL_TAB,
      hidingByConfiguratorMode: 'instance'
    },
    {
      name: ACTON_NAMES.REVIEW,
      displayName: 'Review',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review'
    },
    {
      name: ACTON_NAMES.ABOUT_THIS_HOUSE,
      displayName: 'About this house',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'index'
    },
    {
      name: ACTON_NAMES.SUMMARY,
      displayName: 'Summary',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'summary'
    },
    {
      name: ACTON_NAMES.OPEN_INSTANCE,
      displayName: 'Open...',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.OPEN_INSTANCE
    },
    {
      name: ACTON_NAMES.FINISH,
      displayName: 'Finish',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review'
    }
  ],
  groups: {
    aboutTab: [
      { name: ACTON_NAMES.DESIGN_YOUR_HOME, color: COLOR_NAMES.ACCENT },
      { name: ACTON_NAMES.REVIEW_DESIGN, color: COLOR_NAMES.ACCENT },
      { name: ACTON_NAMES.LOGIN, color: COLOR_NAMES.TEXT_BORDERLESS },
      { name: ACTON_NAMES.MY_DESIGNS, color: COLOR_NAMES.TEXT_BORDERLESS }
    ],
    reviewTab: [
      {
        name: ACTON_NAMES.EDIT,
        color: COLOR_NAMES.MAIN_OUTLINE
      },
      {
        name: ACTON_NAMES.BACK,
        color: COLOR_NAMES.MAIN_OUTLINE
      },
      { name: ACTON_NAMES.SAVE, color: COLOR_NAMES.MAIN },
      { name: ACTON_NAMES.SUMMARY, color: COLOR_NAMES.MAIN }
    ],
    summaryTab: [
      { name: ACTON_NAMES.CONTACT_US, color: COLOR_NAMES.ACCENT },
      { name: ACTON_NAMES.CONTACT_US_AGAIN, color: COLOR_NAMES.ACCENT },
      { name: ACTON_NAMES.DOWNLOAD_PDF, color: COLOR_NAMES.ACCENT_OUTLINE }
    ],
    menu: [
      {
        section: 'actions',
        actions: [
          { name: ACTON_NAMES.MY_DESIGNS },
          { name: ACTON_NAMES.SAVE },
          { name: ACTON_NAMES.SHARE },
          { name: ACTON_NAMES.DOWNLOAD_PDF },
          { name: ACTON_NAMES.CONTACT_US },
          { name: ACTON_NAMES.CONTACT_US_AGAIN },
          { name: ACTON_NAMES.OPEN_INSTANCE }
        ]
      },
      { section: 'links', actions: [{ name: ACTON_NAMES.ABOUT_THIS_HOUSE }] },
      { section: 'auth', actions: [{ name: ACTON_NAMES.LOGIN }, { name: ACTON_NAMES.LOGOUT }] }
    ],
    overviewPanel: [
      { name: ACTON_NAMES.CONTACT_US, color: COLOR_NAMES.ACCENT },
      { name: ACTON_NAMES.CONTACT_US_AGAIN, color: COLOR_NAMES.ACCENT },
      { name: ACTON_NAMES.SUMMARY, color: COLOR_NAMES.TEXT_BORDERLESS }
    ],
    summaryPanel: [
      { name: ACTON_NAMES.BACK, color: COLOR_NAMES.MAIN_OUTLINE, icon: 'chevron-left' },
      { name: ACTON_NAMES.EDIT, color: COLOR_NAMES.MAIN_OUTLINE, icon: 'cogs' },
      { name: ACTON_NAMES.REVIEW, color: COLOR_NAMES.MAIN_OUTLINE, icon: 'cube' },
      { name: ACTON_NAMES.SAVE, color: COLOR_NAMES.MAIN, icon: 'save' },
      { name: ACTON_NAMES.SHARE, color: COLOR_NAMES.MAIN_OUTLINE, icon: 'web-share' }
    ],
    shareDialog: [{ name: 'contact-us', color: COLOR_NAMES.ACCENT }],
    summarySaveButton: 'save',
    finishButton: 'finish'
  }
});
