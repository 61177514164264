import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import b from 'b_';
import { Button, FormGroup } from '../Atoms';
import { authActions } from '../../modules/auth';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from '../../config';

import './SocialAuth.scss';

const socialAuth = b.with('social-auth');

const SocialAuth = props => {
  const { signUp, onFacebookLogin, onGoogleLogin, onGoogleLoginFailure } = props;
  const facebookText = signUp ? 'Sign up with Facebook' : 'Log in with Facebook';
  const googleText = signUp ? 'Sign up with Google' : 'Log in with Google';

  return (
    <div className={socialAuth()}>
      <hr />
      <FormGroup className="text-center">
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          cssClass={`btn btn-primary ${socialAuth('button', { type: 'facebook' })}`}
          textButton={<i className="fa fa-facebook fa-fw" />}
          callback={onFacebookLogin}
          title={facebookText}
        />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          uxMode="popup"
          autoLoad={false}
          render={renderProps => (
            <Button
              onClick={renderProps.onClick}
              className={socialAuth('button', { type: 'google' })}
              title={googleText}
            >
              <i className="fa fa-google fa-fw" />
            </Button>
          )}
          buttonText={<i className="fa fa-google fa-fw" />}
          onSuccess={onGoogleLogin}
          onFailure={onGoogleLoginFailure}
        />
      </FormGroup>
    </div>
  );
};

SocialAuth.defaultProps = {
  signUp: false,
  onGoogleLoginFailure: () => null
};

SocialAuth.propTypes = {
  signUp: PropTypes.bool,
  onGoogleLoginFailure: PropTypes.func,
  onFacebookLogin: PropTypes.func.isRequired,
  onGoogleLogin: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onFacebookLogin: authActions.loginFacebook,
  onGoogleLogin: authActions.loginGoogle
};

export default connect(null, mapDispatchToProps)(SocialAuth);
