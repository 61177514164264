import React from 'react';
import PropTypes from 'prop-types';
import ProtectedButton from '../../ProtectedButton';

const Submit = ({ disabled, label, color, icon, rights, onClick, outline }) => {
  return (
    <ProtectedButton
      rights={rights}
      disabled={disabled}
      color={color}
      onClick={onClick}
      outline={outline}
      type="submit"
    >
      {icon ? <i className={`fa fa-fw ${icon}`} /> : null}
      {label}
    </ProtectedButton>
  );
};

Submit.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  rights: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  outline: PropTypes.bool
};
Submit.defaultProps = {
  disabled: false,
  label: 'Submit',
  color: 'primary',
  icon: 'fa-check',
  rights: [],
  onClick: () => null,
  outline: false
};

export default Submit;
