import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../Atoms';

const MatrixField = ({ input, placeholder, meta }) => (
  <Input {...input} placeholder={placeholder} type="number" step="any" invalid={!meta.valid} required />
);

MatrixField.propTypes = {
  input: PropTypes.shape({}).isRequired, // from react final form
  meta: PropTypes.shape({ valid: PropTypes.bool }).isRequired, // from react final form
  placeholder: PropTypes.string
};

MatrixField.defaultProps = {
  placeholder: ''
};

export default MatrixField;
