import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { uiActions } from '../modules/ui';
import { authActions } from '../modules/auth';
import ServerError from '../utils/api/serverError';

const rtkQueryErrorHandling: Middleware = (api: MiddlewareAPI) => next => action => {
  if (isRejectedWithValue(action)) {
    const error = ServerError.parse({ response: action.payload, aborted: action.meta.aborted });

    if (error.statusCode === 401) {
      api.dispatch(authActions.logoutSuccess());
    } else {
      api.dispatch(uiActions.showErrorNotification(error.message, error.details));
    }
  }

  return next(action);
};

export default rtkQueryErrorHandling;
