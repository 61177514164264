import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import b from 'b_';
import { useFetchFactoryMap, useFetchFactoryMapFlags } from '../../modules/factoryMaps/factoryMapsHooks';
import Loader from '../Loader';
import { ApplicationRoute } from '../../utils/appRoutes';
import FactoryMapForm from './FactoryMapForm/FactoryMapForm';

const factoryMapPage = b.with('factory-map-page');

const FactoryMapPage = () => {
  const { factoryId } = useParams();
  const history = useHistory();
  const { factoryMap, request } = useFetchFactoryMap(factoryId);
  const { deleted, reset } = useFetchFactoryMapFlags();

  useEffect(() => {
    if (deleted) {
      reset();

      history.replace(ApplicationRoute.FactoryMaps);
    }
  }, [deleted, history, reset]);

  return (
    <div className={`${factoryMapPage()} flex-grow-1 w-100 p-3`}>
      <Loader loading={request}>{factoryMap ? <FactoryMapForm factoryMap={factoryMap} /> : null}</Loader>
    </div>
  );
};

export default FactoryMapPage;
