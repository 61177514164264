import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from '../../../Form';
import Field from '../../../Form/Field';
import Settings from '../../../ProjectsPage/Settings';
import { RIGHTS } from '../../../../utils/rights';
import { useFetchGlobalSettings } from '../../../../modules/settings/settingsHooks';
import { useProject } from '../../../../store/project';
import { useAssets } from '../../../../utils/hooks';

/* eslint-disable react/prop-types */
const SeedSettings = ({ seed, onSubmit }) => {
  const globalSettings = useFetchGlobalSettings();
  const { project } = useProject(seed.projectId);

  useAssets(seed?.projectId);

  const shadowValues = useMemo(() => {
    return {
      list: [
        {
          name: 'master',
          value: {
            settings: globalSettings.raw
          }
        },
        {
          name: 'project',
          value: {
            settings: project?.settings || {}
          }
        },
        {
          name: 'current'
        }
      ]
    };
  }, [globalSettings.raw, project]);

  return (
    <Form initialValues={seed} onSubmit={onSubmit} rights={[RIGHTS.SEEDS__UPDATE]} shadowValues={shadowValues}>
      <h4>{seed.name}</h4>

      <Settings header="Seed settings" projectId={seed.projectId} seedId={seed._id} useShadowValue />
      <Form.Group
        header={
          <span className="text-primary">
            <i className="fa fa-css3 fa-fw" />
            Theme
          </span>
        }
      >
        <Field.Css description="Seed theme" name="theme" label="Theme" />
      </Form.Group>
    </Form>
  );
};

SeedSettings.propTypes = {
  seed: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SeedSettings;
