export const NODE_WIDTH = 300;
const HALF_NODE_HEADER_HEIGHT = 30;

interface GraphEdgeProps {
  source: { x: number; y: number };
  target: { x: number; y: number };
  className: string;
}

export interface GraphEdgeShape {
  id: string;
  source: string;
  target: string;
  sourcePosition: { x: number; y: number };
  targetPosition: { x: number; y: number };
}

const GraphEdge = ({ source, target, className }: GraphEdgeProps) => {
  const first = { x: source.x + NODE_WIDTH, y: source.y + HALF_NODE_HEADER_HEIGHT };
  const last = { x: target.x, y: target.y + HALF_NODE_HEADER_HEIGHT };
  const t = (last.x - first.x) / 2;
  const path = ['M', first.x, first.y, 'C', first.x + t, first.y, last.x - t, last.y, last.x, last.y].join(' ');

  return <path d={path} className={className} />;
};

export default GraphEdge;
