import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { partsSelectors } from '../../../modules/parts';
import Field from '../../Form/Field';
import { usePartList } from '../../../utils/hooks';

const ShareView = ({ seedId, useShadowValue }) => {
  const cameraViewSuggestions = useSelector(state => partsSelectors.selectCameraViewsForSuggestions(state, seedId));

  usePartList(seedId);

  return (
    <Field.Select
      name="settings.sharing.view"
      options={cameraViewSuggestions}
      label="Share view"
      description="View that is rendered to the snapshot image"
      useShadowValue={useShadowValue}
    />
  );
};

ShareView.propTypes = {
  seedId: PropTypes.string.isRequired,
  useShadowValue: PropTypes.bool
};

ShareView.defaultProps = {
  useShadowValue: false
};

export default ShareView;
