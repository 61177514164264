import { FC } from 'react';
import { ProjectStatisticsType } from 'types';
import { Icon, IconType, Spinner } from '../Atoms';
import { useProjectStatistics } from '../../store/project';

interface ProjectListItemStatisticsProps {
  projectId: string;
  inView: boolean;
}

const ITEMS: { icon: IconType; field: keyof ProjectStatisticsType; title: string }[] = [
  { icon: 'mdiCubeOutline', field: 'instances', title: 'Designs' },
  { icon: 'mdiAccount', field: 'visitors', title: 'Visitors' },
  { icon: 'mdiMessageTextOutline', field: 'leads', title: 'Requests' }
];

const ProjectListItemStatistics: FC<ProjectListItemStatisticsProps> = ({ projectId, inView }) => {
  const { data, isInProgress } = useProjectStatistics(projectId, !inView);

  return (
    <div className="text-muted text-smaller pt-1">
      {isInProgress ? (
        <div className="me-2 mt-1">
          <Spinner color="secondary" small />
        </div>
      ) : (
        ITEMS.map(item => (
          <span className="pe-2 d-inline-flex align-items-center" key={item.field} title={item.title}>
            <Icon type={item.icon} scale={0.7} /> <span>{data?.[item.field] || 0}</span>
          </span>
        ))
      )}
    </div>
  );
};

export default ProjectListItemStatistics;
