import React, { useCallback, useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { FormFeedback, Input as BootstrapInput } from '../../../Atoms';
import FieldWrapper from '../FieldWrapper';
import { FieldProps } from '../FieldWrapper/FieldWrapper';

interface InputProps extends FieldProps {
  type?: string;
  readOnly?: boolean;
  plaintext?: boolean;
  placeholder?: string;
  min?: number;
  max?: number;
  step?: number;
  rows?: number;
  guide?: string;
}

type GuideProps = {
  value: any;
  parentOnChange: (arg0: any) => any;
  parentValue: any;
};

const InputGuide = (input: GuideProps) => {
  const { parentOnChange, parentValue, value } = input;

  useEffect(() => {
    if (parentValue === undefined && value !== undefined) parentOnChange(value);
  }, [parentOnChange, parentValue, value]);

  return null;
};

const defaultFormat = (i: any) => i;
const defaultParse = (i: any) => i;

const Input = ({
  name,
  type = 'text',
  parse = defaultParse,
  format = defaultFormat,
  readOnly = false,
  plaintext = false,
  required = false,
  disabled = false,
  placeholder = '',
  min,
  max,
  step,
  rows,
  guide,
  ...rest
}: InputProps) => {
  const renderField = useCallback(
    ({ input, meta }) => {
      const { valid, error } = meta;

      const { value = '', ...restInputProps } = input;

      let guideElement = null;

      if (guide) {
        const currentStem = input.name.split('.');
        const guideStem = guide.split('.');

        // remove as many items from currentStem as guide has currently and insert guideStem instead;

        currentStem.splice(-guideStem.length, guideStem.length, ...guideStem);

        const guideName = currentStem.join('.');

        guideElement = guide ? (
          <Field name={guideName}>
            {({ input: childInput }: FieldRenderProps<any>) => (
              <InputGuide parentOnChange={input.onChange} parentValue={input.value} value={childInput.value} />
            )}
          </Field>
        ) : null;
      }

      return (
        <>
          {guideElement}

          <BootstrapInput
            {...restInputProps}
            className="form-icon-trailing"
            value={value}
            id={name}
            type={type}
            readOnly={readOnly}
            plaintext={plaintext}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            invalid={!valid}
            min={min}
            max={max}
            step={step || 'any'}
            rows={rows}
            onKeyDown={event => {
              if (type !== 'number') {
                return;
              }
              const isNumberKey = /[0-9\-.,]+/.test(event.key);
              let allowedEvent =
                isNumberKey ||
                ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key) ||
                event.ctrlKey;

              if ((event.target as HTMLInputElement).value.includes('-') && event.key === '-') {
                allowedEvent = false;
              }

              if (!allowedEvent) {
                event.preventDefault();
              }
            }}
          />
          <FormFeedback>{error}</FormFeedback>
        </>
      );
    },
    [disabled, guide, max, min, name, placeholder, plaintext, readOnly, required, rows, step, type]
  );

  return (
    <FieldWrapper name={name} {...rest}>
      <Field name={name} type={type} parse={parse} format={format} render={renderField} />
    </FieldWrapper>
  );
};

export default Input;
