import { Col, PageTile, Row } from '../Atoms';
import ConfiguratorLink from '../ConfiguratorLink';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';
import ProjectDashboardStatistics from './ProjectDashboardStatistics';
import ProjectDashboardVersions from './ProjectDashboardVersions';
import ProjectDashboardLinks from './ProjectDashboardLinks';
import ProjectPageWrapper from './ProjectPageWrapper';
import ProjectDashboardActions from './ProjectDashboardActions';

const ProjectDashboard = () => {
  const hasRights = useUserRights();

  return (
    <ProjectPageWrapper>
      {project => (
        <Row>
          <Col lg={7} md={6} sm={12} className="mb-4 mb-md-0">
            <PageTile className="mb-4">
              <span className="text-muted">{project.populated.partnerName}</span>
              <h2 className="fw-bold mb-0">{project.name}</h2>
              <div className="overflow-hidden">
                <ConfiguratorLink type="project" link={project.link} />
              </div>
              {hasRights([RIGHTS.PROJECTS__READ_STATISTICS]) ? (
                <ProjectDashboardStatistics projectId={project._id} />
              ) : null}
            </PageTile>
            <ProjectDashboardLinks projectId={project._id} />
          </Col>
          <Col lg={5} md={6} sm={12}>
            <ProjectDashboardVersions
              projectId={project._id}
              projectName={project.name}
              publicSeedId={project.publicSeedId}
            />
            <ProjectDashboardActions project={project} />
          </Col>
        </Row>
      )}
    </ProjectPageWrapper>
  );
};

export default ProjectDashboard;
