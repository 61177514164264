import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AssetKindType, AssetType } from 'types';
import { assetsActions, ASSET_TYPES } from '../../../modules/assets';
import { TYPE_ASSET_TYPES, TYPE_ASSET_LIST } from '../../../utils/propTypes';
import AssetGroup from '../AssetGroup';
import makeAssetUrl from '../../../utils/api/makeAssetUrl';

function useAssetsFunctions(type: AssetKindType) {
  const dispatch = useDispatch();

  return {
    getAssetUrl: makeAssetUrl(type),
    deleteAsset: useCallback(
      (id: string, unsafe?: string) => dispatch(assetsActions[type].delete(id, unsafe)),
      [dispatch, type]
    )
  };
}

interface AssetGroupPickerProps {
  assetType: AssetKindType;
  filteredImagesList: AssetType[];
  filteredTexturesList: AssetType[];
  filteredModelsList: AssetType[];
}

const AssetGroupPicker = ({
  assetType,
  filteredImagesList,
  filteredTexturesList,
  filteredModelsList
}: AssetGroupPickerProps) => {
  const { deleteAsset, getAssetUrl } = useAssetsFunctions(assetType);

  let list = filteredImagesList;

  if (assetType === ASSET_TYPES.TEXTURES) {
    list = filteredTexturesList;
  }

  if (assetType === ASSET_TYPES.MODELS) {
    list = filteredModelsList;
  }

  return <AssetGroup list={list} onDeleteAsset={deleteAsset} getAssetUrl={getAssetUrl} assetType={assetType} />;
};

AssetGroupPicker.propTypes = {
  assetType: TYPE_ASSET_TYPES.isRequired,
  filteredImagesList: TYPE_ASSET_LIST,
  filteredTexturesList: TYPE_ASSET_LIST,
  filteredModelsList: TYPE_ASSET_LIST
};

AssetGroupPicker.defaultProps = {
  filteredImagesList: [],
  filteredTexturesList: [],
  filteredModelsList: []
};

export default AssetGroupPicker;
