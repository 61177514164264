import { ReactNode, useCallback } from 'react';
import Select from 'react-select';
import { useProjectListForSelect } from '../../../store/project';

export const LOST_AND_FOUND_KEY = '_lostAndFound';

interface ProjectListProps {
  onSelect: (value: string) => void;
  selectedProject?: string;
  includeGlobal?: boolean;
  includeLostAndFound?: boolean;
}
const ProjectList = ({
  onSelect,
  selectedProject = '',
  includeGlobal = true,
  includeLostAndFound = false
}: ProjectListProps) => {
  const list = useProjectListForSelect();

  const selectHandler = useCallback(
    selectedValue => {
      const value = selectedValue?.value || '';

      onSelect(value);
    },
    [onSelect]
  );

  const projectSuggestions = [...list] as { label: ReactNode; value: string }[];

  if (includeGlobal) projectSuggestions.push({ label: <b>Global</b>, value: '' });

  if (includeLostAndFound) projectSuggestions.push({ label: <b>Lost & found</b>, value: LOST_AND_FOUND_KEY });

  const selectedValue = projectSuggestions.find(({ value }) => value === selectedProject);

  return (
    <Select
      onChange={selectHandler}
      placeholder="Select project..."
      value={selectedValue}
      options={projectSuggestions}
      isClearable
    />
  );
};

export default ProjectList;
