import { useFormContext, useWatch } from 'react-hook-form';
import { PropsWithChildren, useEffect } from 'react';
import { FieldName, FieldValue } from '../types';

interface ConditionProps<T> {
  name: FieldName<T>;
  conditionalFields: FieldName<T>[];
  condition(value: FieldValue<T>): boolean;
}

const Condition = <T,>({ name, conditionalFields, children, condition }: PropsWithChildren<ConditionProps<T>>) => {
  const { unregister } = useFormContext<T>();
  const value = useWatch<T>({ name });
  const isVisible = condition(value);

  useEffect(() => {
    if (!isVisible) {
      unregister(conditionalFields, { keepDefaultValue: true });
    }
  }, [conditionalFields, isVisible, unregister]);

  return isVisible ? <>{children}</> : null;
};

export default Condition;
