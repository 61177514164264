import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Button } from '../../Atoms';
import { partsActions } from '../../../modules/parts';
import { useRootPartBySeedId } from '../../../utils/hooks';
import getPartFieldsToUpdate from '../../../utils/getPartFieldsToUpdate';

const AddTabButton = ({ seedId, tabsLength }) => {
  const rootPart = useRootPartBySeedId(seedId);
  const dispatch = useDispatch();

  const handleAddTab = useCallback(() => {
    const newPart = { ...rootPart };

    if (!newPart.Phases) newPart.Phases = {};

    if (!newPart.Phases.Object) newPart.Phases.Object = [];

    const newIndex = newPart.Phases.Object.length;
    const dummyTab = { name: `newTab_${newIndex}`, tabIndex: tabsLength, displayName: `Dummy Tab ${newIndex}` };

    newPart.Phases.Object.push(dummyTab);

    dispatch(partsActions.updatePart(getPartFieldsToUpdate(newPart, ['Phases'])));
  }, [dispatch, rootPart, tabsLength]);

  if (!rootPart) {
    return <Alert color="warning">No root part found. Cannot add tabs.</Alert>;
  }

  return (
    <Button color="outline-primary" onClick={handleAddTab}>
      + Add (by default to root part)
    </Button>
  );
};

AddTabButton.propTypes = {
  seedId: PropTypes.string.isRequired,
  tabsLength: PropTypes.number.isRequired
};

export default AddTabButton;
