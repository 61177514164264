/* eslint-disable import/prefer-default-export */
export function mapValues(list, key = '_id') {
  return list.reduce((result, current) => {
    const value = current[key];

    return { ...result, ...{ [value]: current } };
  }, {});
}

export const makeSuggestions = values => values.map(label => ({ value: label, label }));

export function msToTime(duration) {
  let seconds = parseInt((duration / 1000) % 60, 10);
  let minutes = parseInt((duration / (1000 * 60)) % 60, 10);
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

export function debounce(callback, time) {
  let timeout;

  return function cb(...args) {
    const fn = () => callback.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(fn, time);
  };
}
