import { FC } from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { Icon } from '../Atoms';

interface BreadcrumbBackButtonProps {
  to: LocationDescriptor;
}

const BreadcrumbBackButton: FC<BreadcrumbBackButtonProps> = ({ to }) => {
  return (
    <Link to={to} className="btn btn-link p-1">
      <Icon type="mdiArrowLeft" />
    </Link>
  );
};

export default BreadcrumbBackButton;
