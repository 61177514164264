import React, { useCallback } from 'react';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Col, Input, Row } from '../../Atoms';
import { TYPE_PARTNER } from '../../../utils/propTypes';
import { partnersActions, partnersSelectors } from '../../../modules/partners';

const partnerPublicAPIKey = b.with('partner-public-api-key');

const PartnerPublicAPIKey = ({ partner }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(partnersSelectors.selectPartnersApiKeyRequest);
  const isVisible = partner.hasPublicAPIKey && partner.publicAPIKey;
  const inputValue = partner.hasPublicAPIKey ? partner.publicAPIKey || 'Plug' : '';

  const createPublicAPIKey = useCallback(() => {
    let response = true;

    if (partner.hasPublicAPIKey) {
      response = window.confirm(`Are you sure that you want to create new public API key for this partner?`); // eslint-disable-line no-alert
    }

    if (response) {
      dispatch(partnersActions.createAPIKey(partner._id));
    }
  }, [dispatch, partner._id, partner.hasPublicAPIKey]);

  const removePublicAPIKey = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to delete public API key for this partner?`); // eslint-disable-line no-alert

    if (response) {
      dispatch(partnersActions.createAPIKey(partner._id, true));
    }
  }, [dispatch, partner._id]);

  return (
    <div className={partnerPublicAPIKey()}>
      <h3 className="text-primary">Public API key</h3>
      <Row>
        <Col lg="8" className="mb-4">
          <Input disabled={!isVisible} type={isVisible ? 'text' : 'password'} value={inputValue} />
        </Col>
        <Col className="mb-4">
          <ButtonGroup>
            <Button onClick={createPublicAPIKey} disabled={isLoading} color="primary" outline>
              {partner.hasPublicAPIKey ? 'Generate new key' : 'Generate key'}
            </Button>
            <Button onClick={removePublicAPIKey} disabled={!partner.hasPublicAPIKey} color="danger" outline>
              Remove key
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

PartnerPublicAPIKey.propTypes = {
  partner: TYPE_PARTNER.isRequired
};

export default PartnerPublicAPIKey;
