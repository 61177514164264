export const RESET_AUTH_STATE = 'RESET_AUTH_STATE';

export const LOGIN = 'LOGIN';
export const LOGIN__REQUEST = 'LOGIN__REQUEST';
export const LOGIN__SUCCESS = 'LOGIN__SUCCESS';
export const LOGIN__FAILURE = 'LOGIN__FAILURE';

export const REGISTRATION = 'REGISTRATION';
export const REGISTRATION__REQUEST = 'REGISTRATION__REQUEST';
export const REGISTRATION__SUCCESS = 'REGISTRATION__SUCCESS';
export const REGISTRATION__FAILURE = 'REGISTRATION__FAILURE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD__REQUEST = 'FORGOT_PASSWORD__REQUEST';
export const FORGOT_PASSWORD__SUCCESS = 'FORGOT_PASSWORD__SUCCESS';
export const FORGOT_PASSWORD__FAILURE = 'FORGOT_PASSWORD__FAILURE';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN__REQUEST = 'VERIFY_TOKEN__REQUEST';
export const VERIFY_TOKEN__SUCCESS = 'VERIFY_TOKEN__SUCCESS';
export const VERIFY_TOKEN__FAILURE = 'VERIFY_TOKEN__FAILURE';

export const VERIFY_INVITATION = 'VERIFY_INVITATION';
export const VERIFY_INVITATION__REQUEST = 'VERIFY_INVITATION__REQUEST';
export const VERIFY_INVITATION__SUCCESS = 'VERIFY_INVITATION__SUCCESS';
export const VERIFY_INVITATION__FAILURE = 'VERIFY_INVITATION__FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD__REQUEST = 'RESET_PASSWORD__REQUEST';
export const RESET_PASSWORD__SUCCESS = 'RESET_PASSWORD__SUCCESS';
export const RESET_PASSWORD__FAILURE = 'RESET_PASSWORD__FAILURE';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO__REQUEST = 'GET_USER_INFO__REQUEST';
export const GET_USER_INFO__SUCCESS = 'GET_USER_INFO__SUCCESS';
export const GET_USER_INFO__FAILURE = 'GET_USER_INFO__FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT__SUCCESS = 'LOGOUT__SUCCESS';
export const LOGOUT__FAILURE = 'LOGOUT__FAILURE';

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';

export const ACCEPT_TERMS_OF_USE = 'ACCEPT_TERMS_OF_USE';
export const ACCEPT_TERMS_OF_USE__REQUEST = 'ACCEPT_TERMS_OF_USE__REQUEST';
export const ACCEPT_TERMS_OF_USE__SUCCESS = 'ACCEPT_TERMS_OF_USE__SUCCESS';
export const ACCEPT_TERMS_OF_USE__FAILURE = 'ACCEPT_TERMS_OF_USE__FAILURE';

export function resetAuthState() {
  return {
    type: RESET_AUTH_STATE
  };
}

export function login(username, password) {
  return {
    type: LOGIN,
    payload: {
      username,
      password
    }
  };
}

export function loginRequest() {
  return {
    type: LOGIN__REQUEST
  };
}

export function loginSuccess(user) {
  return {
    type: LOGIN__SUCCESS,
    payload: {
      user
    }
  };
}

export function loginFailure(error) {
  return {
    type: LOGIN__FAILURE,
    payload: {
      error
    }
  };
}

export function registration(username, password, invitationId) {
  return {
    type: REGISTRATION,
    payload: {
      username,
      password,
      invitationId
    }
  };
}

export function registrationRequest() {
  return {
    type: REGISTRATION__REQUEST
  };
}

export function registrationSuccess(user) {
  return {
    type: REGISTRATION__SUCCESS,
    payload: {
      user
    }
  };
}

export function registrationFailure(error) {
  return {
    type: REGISTRATION__FAILURE,
    payload: {
      error
    }
  };
}

export function forgotPassword(username, host) {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      username,
      host
    }
  };
}

export function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD__REQUEST
  };
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD__SUCCESS
  };
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD__FAILURE,
    payload: {
      error
    }
  };
}

export function verifyToken(token) {
  return {
    type: VERIFY_TOKEN,
    payload: {
      token
    }
  };
}

export function verifyTokenRequest() {
  return {
    type: VERIFY_TOKEN__REQUEST
  };
}

export function verifyTokenSuccess() {
  return {
    type: VERIFY_TOKEN__SUCCESS
  };
}

export function verifyTokenFailure() {
  return {
    type: VERIFY_TOKEN__FAILURE
  };
}

export function verifyInvitation(invitationId) {
  return {
    type: VERIFY_INVITATION,
    payload: {
      invitationId
    }
  };
}

export function verifyInvitationRequest() {
  return {
    type: VERIFY_INVITATION__REQUEST
  };
}

export function verifyInvitationSuccess(invitation) {
  return {
    type: VERIFY_INVITATION__SUCCESS,
    payload: {
      invitation
    }
  };
}

export function verifyInvitationFailure() {
  return {
    type: VERIFY_INVITATION__FAILURE
  };
}

export function resetPassword(password, token) {
  return {
    type: RESET_PASSWORD,
    payload: {
      password,
      token
    }
  };
}

export function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD__REQUEST
  };
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD__SUCCESS
  };
}

export function resetPasswordFailure(error) {
  return {
    type: RESET_PASSWORD__FAILURE,
    payload: {
      error
    }
  };
}

export function getUserInfo() {
  return {
    type: GET_USER_INFO
  };
}

export function getUserInfoRequest() {
  return {
    type: GET_USER_INFO__REQUEST
  };
}

export function getUserInfoSuccess(user) {
  return {
    type: GET_USER_INFO__SUCCESS,
    payload: {
      user
    }
  };
}

export function getUserInfoFailure() {
  return {
    type: GET_USER_INFO__FAILURE
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT__SUCCESS
  };
}

export function logoutFailure(error) {
  return {
    type: LOGOUT__FAILURE,
    payload: {
      error
    }
  };
}

export function loginFacebook(info) {
  return {
    type: LOGIN_FACEBOOK,
    payload: {
      info
    }
  };
}

export function loginGoogle(info) {
  return {
    type: LOGIN_GOOGLE,
    payload: {
      info
    }
  };
}

export function acceptTermsOfUse() {
  return {
    type: ACCEPT_TERMS_OF_USE
  };
}

export function acceptTermsOfUseRequest() {
  return {
    type: ACCEPT_TERMS_OF_USE__REQUEST
  };
}

export function acceptTermsOfUseSuccess(user) {
  return {
    type: ACCEPT_TERMS_OF_USE__SUCCESS,
    payload: { user }
  };
}

export function acceptTermsOfUseFailure(error) {
  return {
    type: ACCEPT_TERMS_OF_USE__FAILURE,
    payload: {
      error
    }
  };
}
