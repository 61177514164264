import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export interface SharingProps {
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
  cameraViewSuggestions: any[];
}

export const Sharing = ({
  projectImages,
  uploadImage,
  updateImagesAfterUpload,
  cameraViewSuggestions
}: SharingProps) => {
  return (
    <>
      <FormField.Image<ProjectType>
        name="settings.sharing.image"
        label="Share image"
        description="Default image to show when sharing"
        list={projectImages}
        uploadImage={uploadImage}
        onUploadSuccess={updateImagesAfterUpload}
      />
      <FormField.Text<ProjectType>
        name="settings.sharing.title"
        label="Share title"
        description="Default title to show when sharing"
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.sharing.description"
        label="Share description"
        description="Default text to show when sharing"
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.sharing.twitter"
        label="Twitter share description"
        description="Default text to show when sharing to Twitter"
        useShadowValue
      />
      <FormField.Select<ProjectType>
        name="settings.sharing.view"
        options={cameraViewSuggestions}
        label="Share view"
        description="View that is rendered to the snapshot image"
        useShadowValue
      />
    </>
  );
};
