import { useMemo } from 'react';
import b from 'b_';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { IframeScriptType } from 'types';
import { Badge, Table } from '../../Atoms';
import Loader from '../../Loader';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';
import { iframeScriptsHooks } from '../../../modules/iframeScripts';
import { getIframeScriptUrl } from '../../../utils/appRoutes';

const iframeScriptsList = b.with('iframe-scripts-list');

const IframeScriptsList = () => {
  const { request, list } = iframeScriptsHooks.useIframeScriptList();
  const hasRights = useUserRights();

  const columns = useMemo<Column<IframeScriptType>[]>(() => {
    const className = 'fw-bold p-2';

    return [
      {
        id: 'version',
        Header: 'Version',
        accessor: 'version',
        width: 120,
        disableResizing: true,
        disableFilters: true,
        className,
        Cell: ({ value, row }) =>
          hasRights([RIGHTS.IFRAME_SCRIPT__UPDATE]) ? (
            <Link to={getIframeScriptUrl(row.original._id)}>{value}</Link>
          ) : (
            value
          )
      },
      {
        accessor: 'latest',
        disableFilters: true,
        disableSortBy: true,
        disableResizing: true,
        className: 'p-2 text-center',
        width: 100,
        Cell: row =>
          row.value ? (
            <Badge color="warning" pill>
              Latest
            </Badge>
          ) : null
      },
      {
        id: 'updatedAt',
        Header: 'Updated',
        accessor: 'updatedAt',
        disableFilters: true,
        disableResizing: true,
        className: 'p-2',
        Cell: row => new Date(row.value).toLocaleString(),
        width: 150
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableFilters: true,
        className: 'p-2'
      }
    ];
  }, [hasRights]);

  return (
    <Loader loading={request}>
      <Table data={list} columns={columns} className={iframeScriptsList()} />
    </Loader>
  );
};

export default IframeScriptsList;
