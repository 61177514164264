import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FACTORY_MAP_TYPE, TYPE_FACTORY_MAP } from '../../../../utils/propTypes';

const InstancePreview = ({ selectedInstance, factoryMap }) => {
  const instanceValues = useMemo(() => {
    const values = {};

    const sections = selectedInstance.summary?.sections || [];

    sections.forEach(section => {
      const choices = section.choices.filter(Boolean);

      if (choices) {
        // first loop to get selected values
        choices.forEach(choice => {
          const { control, name } = choice.option;

          values[control.parameter || control.name] = name;
        }, {});

        // then loop again to see if anything needs to be fixed
        choices.forEach(choice => {
          const { fix } = choice.option;

          if (fix) {
            Object.keys(fix).forEach(fixKey => {
              if (values[fixKey] === undefined) {
                // only if it wasn't selected previously
                values[fixKey] = fix[fixKey];
              }
            });
          }
        });
      }
    });

    return values;
  }, [selectedInstance]);
  const getMapFromDefinition = useCallback(
    (definition = []) => {
      const result = definition.find(({ conditions = [] }) =>
        conditions
          .filter(condition => condition.key && condition.values && condition.values.length) // to remove empty conditions
          .every(condition => condition.values.some(conditionValue => conditionValue === instanceValues[condition.key]))
      );

      return result ? result.value : 'UNDEFINED';
    },
    [instanceValues]
  );

  const getValueFromKeys = useCallback(
    values => {
      let result = 'UNDEFINED';

      // .some to stop loop on first success
      values.some(({ value: controlName }) => {
        if (instanceValues[controlName] !== undefined) {
          result = instanceValues[controlName];

          return true;
        }

        return false;
      });

      return result;
    },
    [instanceValues]
  );

  const renderField = useCallback(
    field => {
      const { type, values = [] } = field;

      switch (type) {
        case FACTORY_MAP_TYPE.MAP:
          return `${field.name}, ${getMapFromDefinition(field.values)}\n`;
        case FACTORY_MAP_TYPE.KEY:
          return `${field.name}, ${values[0] ? instanceValues[values[0].value] : ''}\n`;
        case FACTORY_MAP_TYPE.KEYS:
          return `${field.name}, ${getValueFromKeys(values)}\n`;
        case FACTORY_MAP_TYPE.VALUE:
        default:
          return `${field.name}, ${values[0] ? values[0].value : ''}\n`;
      }
    },
    [getMapFromDefinition, getValueFromKeys, instanceValues]
  );

  return (
    <pre className="border rounded bg-light p-3">
      <code>{factoryMap.definition.filter(field => field.name).map(renderField)}</code>
    </pre>
  );
};

InstancePreview.propTypes = {
  selectedInstance: PropTypes.shape({
    summary: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }).isRequired,
  factoryMap: TYPE_FACTORY_MAP.isRequired
};

export default InstancePreview;
