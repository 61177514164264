import React from 'react';
import { Page, PageContent, PageHeader } from '../Page';
import IframeScriptsList from './IframeScriptsList';
import IframeScriptCreation from './IframeScriptCreation';

const IframeScriptsPage = () => (
  <Page>
    <PageHeader className="d-flex flex-row flex-wrap align-items-center justify-content-end pb-3">
      <IframeScriptCreation />
    </PageHeader>
    <PageContent>
      <IframeScriptsList />
    </PageContent>
  </Page>
);

export default IframeScriptsPage;
