/* eslint-disable import/prefer-default-export */
export const defaultPartner = {
  name: 'Creatomus Solutions',
  domain: 'https://configurator.creatomus.com',
  email: 'info@creatomus.com',
  adminSettings: {
    configuratorHost: 'https://configurator.creatomus.com',
    requestsShowSendToProduction: false,
    requestsShowPlot: false,
    requestsShowReservationFee: false,
    isLegaleSignAvailable: false
  },
  emailTemplates: {
    welcomeTemplate: 231893,
    requestTemplate: 477584,
    requestNotificationTemplate: 375565,
    requestPDFSummaryTemplate: 582714,
    crmProductionTemplate: 340184
  },
  emailFrom: {
    address: 'internal@creatomus.com',
    name: 'Internal Creatomus'
  }
};
