import React, { ReactNode } from 'react';
import b from 'b_';
import { FormText } from '../../../Atoms';

const field = b.with('form-field');
const FieldFeedback = ({ description = null }: { description: ReactNode }) =>
  description ? (
    <FormText className={`${field('description')} mt-0 mb-1 d-inline-block pe-2`}>{description}</FormText>
  ) : null;

export default FieldFeedback;
