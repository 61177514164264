import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AssetType, ProjectType } from 'types';
import { assetsHooks, assetsSelectors } from '../../../modules/assets';
import ProjectList, { LOST_AND_FOUND_KEY } from '../ProjectList';
import AssetGroupPicker from '../AssetGroupPicker';
import AssetUpload from '../AssetUpload';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';
import { Page, PageContent, PageHeader } from '../../Page';
import AssetsTabs from '../AssetsTabs';
import { useProjectList, useProjects } from '../../../store/project';

const filterAssets = (
  list: AssetType[],
  projectIds: Record<string, ProjectType | undefined>,
  selectedProject: string,
  hasGlobalRights = false
) => {
  return list.filter(({ metadata }) => {
    const meta = metadata || {};
    const { projectId = '' } = meta;

    if (selectedProject === LOST_AND_FOUND_KEY) {
      // special case to filter out all assets which have projectId assigned but are not in available projects

      return projectId && !projectIds[projectId];
    }

    // projectId has to exist or you have global rights and projectId is selected project
    return (projectId || hasGlobalRights) && projectId === selectedProject;
  });
};

const AssetsList = () => {
  const selectedAssetType = assetsHooks.useActiveAssetsType();
  const {
    projects: [firstProject]
  } = useProjectList();
  const [selectedProject, selectProject] = useState((firstProject && firstProject._id) || '');
  const { projects: availableProjectIds } = useProjects();
  const texturesList = useSelector(assetsSelectors.textures.selectList);
  const imagesList = useSelector(assetsSelectors.images.selectList);
  const modelsList = useSelector(assetsSelectors.models.selectList);
  const hasRights = useUserRights();
  const hasGlobalRights = hasRights([RIGHTS.ASSETS__MANIPULATE_GLOBALS]);
  const filteredImagesList = useMemo(
    () => filterAssets(imagesList, availableProjectIds, selectedProject, hasGlobalRights),
    [availableProjectIds, hasGlobalRights, imagesList, selectedProject]
  );
  const filteredTexturesList = useMemo(
    () => filterAssets(texturesList, availableProjectIds, selectedProject, hasGlobalRights),
    [availableProjectIds, hasGlobalRights, selectedProject, texturesList]
  );
  const filteredModelsList = useMemo(
    () => filterAssets(modelsList, availableProjectIds, selectedProject, hasGlobalRights),
    [availableProjectIds, hasGlobalRights, selectedProject, modelsList]
  );

  return (
    <Page>
      <PageHeader>
        <div className="d-flex flex-row mb-3">
          <div className="flex-grow-1 pe-3">
            <ProjectList
              onSelect={selectProject}
              selectedProject={selectedProject}
              includeLostAndFound={hasGlobalRights}
              includeGlobal={hasGlobalRights}
            />
          </div>
          <AssetUpload type={selectedAssetType} selectedProject={selectedProject} />
        </div>
        <AssetsTabs
          activeType={selectedAssetType}
          imagesLength={filteredImagesList.length}
          texturesLength={filteredTexturesList.length}
          modelsLength={filteredModelsList.length}
        />
      </PageHeader>
      <PageContent>
        <AssetGroupPicker
          assetType={selectedAssetType}
          filteredImagesList={filteredImagesList}
          filteredTexturesList={filteredTexturesList}
          filteredModelsList={filteredModelsList}
        />
      </PageContent>
    </Page>
  );
};

export default AssetsList;
