import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelectLib from 'react-select/async';
import { Field } from 'react-final-form';
import { FormFeedback } from '../../../Atoms';
import FieldWrapper from '../FieldWrapper';

const AsyncSelect = props => {
  const [cached, setCached] = useState(false);
  const [list, setList] = useState([]);
  const { name, api, getOptionLabel, getOptionValue, filterField, withCache } = props;

  const filterList = useCallback(
    value => list.filter(item => item[filterField].toLowerCase().includes(value.toLowerCase())),
    [filterField, list]
  );
  const fetchList = useCallback(
    async (value, callback) => {
      const { data = [] } = cached && withCache ? { data: list } : await api(value);

      setList(Array.isArray(data) ? data : []);
      setCached(true);

      return value ? callback(filterList(value)) : data;
    },
    [cached, withCache, api, list, filterList]
  );

  const renderField = useCallback(
    ({ input, meta }) => {
      const current = input.value !== undefined ? list.find(({ _id }) => _id === input.value) : null;

      return (
        <>
          <AsyncSelectLib
            cacheOptions
            defaultOptions
            value={current}
            loadOptions={fetchList}
            onChange={option => input.onChange(option._id)}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
          />

          <FormFeedback>{meta.error}</FormFeedback>
        </>
      );
    },
    [list, fetchList, getOptionLabel, getOptionValue]
  );

  return (
    <FieldWrapper {...props}>
      <Field name={name} render={renderField} />
    </FieldWrapper>
  );
};

AsyncSelect.defaultProps = {
  getOptionLabel: option => option.name,
  getOptionValue: option => option._id,
  filterField: 'name',
  withCache: true
};

AsyncSelect.propTypes = {
  name: PropTypes.string.isRequired,
  api: PropTypes.func.isRequired,
  withCache: PropTypes.bool,
  filterField: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func
};

export default AsyncSelect;
