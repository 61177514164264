import { all, call, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import usersActions from './usersActions';

export function* doFetchUsersSaga() {
  yield call(sagas.doBaseAsyncSaga, usersActions.fetchUsers, api.users.getList);
}

export function* watchFetchUsersSaga() {
  yield takeLatest(usersActions.FETCH_USERS, doFetchUsersSaga);
}

export function* doUpdateUserSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, usersActions.updateUser, api.users.update, payload.entity);
}

export function* watchUpdateUserSaga() {
  yield takeLatest(usersActions.UPDATE_USER, doUpdateUserSaga);
}

export function* doDeleteUserSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, usersActions.deleteUser, api.users.delete, payload.id);
}

export function* watchDeleteUserSaga() {
  yield takeLatest(usersActions.DELETE_USER, doDeleteUserSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchUsersSaga(), watchUpdateUserSaga(), watchDeleteUserSaga()]);
}
