import { Alert } from 'reactstrap';
import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export const AreaCalc = () => {
  return (
    <>
      <Alert color="info">
        In order to show <b>Net Area</b> in <b>Summary</b> both layers and unit have to be set
      </Alert>
      <FormField.ArrayWrapper<ProjectType>
        name="settings.model.netAreaLayers"
        label="Net area layers"
        description="List of layer names to get geometry for area calculations from. Both Layers and Unit has to be set in order to show Net area"
        headerFormat={value => get(value, 'layer', 'untitled')}
        component={
          <FormField.Text<ProjectType>
            useShadowValue={false}
            internalName="layer"
            name="settings.model.netAreaLayers"
            label=""
          />
        }
        useShadowValue
      />
      <FormField.Select<ProjectType>
        name="settings.model.areaUnit"
        label="Area unit"
        description="If this is not set, area is not shown"
        options={[
          {
            value: 'm2',
            label: 'Square meters (m2)'
          },
          {
            value: 'm²',
            label: 'Square meters (m²)'
          },
          {
            value: 'sq.m',
            label: 'Square meters (sq.m)'
          },
          {
            value: 'sq.ft',
            label: 'Square feet (sq.ft)'
          },
          {
            value: 'ft²',
            label: 'Square feet (ft²)'
          }
        ]}
        useShadowValue
      />
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => value as unknown as string}
        component={<FormField.Text<ProjectType> useShadowValue={false} name="settings.model.netAreaCaveat" label="" />}
        name="settings.model.netAreaCaveat"
        label="Net area Caveat"
        description="Paragraphs to show additional information about net area"
        useShadowValue
      />
    </>
  );
};
