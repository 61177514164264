import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { TemplateSourceType, TemplateType } from 'types';
import { Button, ButtonGroup, CodeEditor, Col, JsonViewer, Loader, PageTile, PdfViewer } from '../Atoms';
import { useGetTemplatePreview } from '../../store/template';
import TemplateInstanceSelector from './TemplateInstanceSelector';

interface TemplatePreviewProps {
  partnerId: string;
  template?: Partial<TemplateType>;
  type: TemplateSourceType;
}

export enum PreviewModes {
  HTML = 'html',
  VARIABLES = 'variables',
  PDF = 'pdf'
}

const buttons = [
  { mode: PreviewModes.PDF, title: 'PDF' },
  { mode: PreviewModes.VARIABLES, title: 'Variables' },
  { mode: PreviewModes.HTML, title: 'HTML' }
];

const TemplatePreview: FC<TemplatePreviewProps> = ({ partnerId, template, type }) => {
  const [instanceId, setInstanceId] = useState('');
  const [previewMode, setPreviewMode] = useState(PreviewModes.PDF);
  const { getTemplateHtmlPreview, getTemplatePdfPreview, htmlData, pdfLink, isLoading } = useGetTemplatePreview();

  const handleChangeMode = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    const { mode } = e.currentTarget.dataset;

    setPreviewMode(mode as PreviewModes);
  }, []);

  const handleClick = () => {
    if (!template) return;

    if (previewMode === PreviewModes.PDF) {
      getTemplatePdfPreview({
        instanceId,
        body: template.body,
        header: template.header,
        footer: template.footer,
        options: template.options
      });

      return;
    }

    getTemplateHtmlPreview({ instanceId, body: template.body, header: template.header, footer: template.footer });
  };

  return (
    <Col md={6} sm={12}>
      <PageTile className="h-100 d-flex flex-column">
        <h5>Select design to preview</h5>
        <div className="d-flex">
          <div className="flex-grow-1">
            <TemplateInstanceSelector partnerId={partnerId} onSelect={setInstanceId} />
          </div>
          {!instanceId ? null : (
            <Button color="primary" type="button" outline disabled={isLoading} className="ms-3" onClick={handleClick}>
              Preview
            </Button>
          )}
        </div>
        {!instanceId ? (
          <div className="d-flex align-items-center justify-content-center h-100 pt-4">
            <p>No instance selected</p>
          </div>
        ) : (
          <div className="d-flex flex-column flex-grow-1">
            <ButtonGroup className="my-4 d-flex">
              {buttons.map(({ mode: btnMode, title }) => (
                <Button
                  key={title}
                  color="secondary"
                  outline={previewMode !== btnMode}
                  onClick={handleChangeMode}
                  data-mode={btnMode}
                  className="flex-grow-1 flex-basis-0"
                >
                  {title}
                </Button>
              ))}
            </ButtonGroup>
            <div className="d-flex justify-content-center flex-grow-1">
              <Loader loading={isLoading}>
                {previewMode === PreviewModes.HTML && htmlData ? (
                  <CodeEditor mode="html" value={htmlData?.[type]} readonly />
                ) : null}
                {previewMode === PreviewModes.VARIABLES && htmlData ? <JsonViewer data={htmlData?.variables} /> : null}
                {previewMode === PreviewModes.PDF && pdfLink ? <PdfViewer src={pdfLink} /> : null}
              </Loader>
            </div>
          </div>
        )}
      </PageTile>
    </Col>
  );
};

export default TemplatePreview;
