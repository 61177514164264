import { actions } from '../@helpers';

const MODULE_NAME = 'PARTS';

const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const fetchParts = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_LIST'),
  {
    base: type => seedId => ({ type, payload: { seedId } })
  }
);

const updatePart = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_PART'),
  {
    base: type => part => ({ type, payload: { part } })
  }
);

const updateParts = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_PARTS'),
  {
    base: type => (seedId, parts) => ({ type, payload: { seedId, parts } })
  }
);

const deletePart = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'DELETE_PART'),
  {
    base: type => part => ({ type, payload: { part } }),
    success: type => id => ({ type, payload: { id } })
  }
);

/** Used in legacy graph. Single part position update */
const updatePartPosition = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_PART_POSITION'),
  {
    base:
      type =>
      ({ _id, graphX, graphY }) => ({ type, payload: { _id, graphX, graphY } })
  }
);

/** Same as above but takes multiple parts with one dispatch */
const updatePartsPosition = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_PARTS_POSITION'),
  {
    base: type => partPositionsMap => ({ type, payload: partPositionsMap })
  }
);

const reorderParts = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'REORDER_PARTS'),
  { base: type => nodes => ({ type, payload: { nodes } }) }
);

const resetUnsavedPartsInfo = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'RESET_UNSAVED_PARTS_INFO'),
  { base: type => () => ({ type }) }
);

const saveUnsavedParts = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'SAVE_UNSAVED_PARTS'),
  { base: type => seedId => ({ type, payload: { seedId } }) }
);

const partsActions = {
  FETCH_PARTS: fetchParts.ACTION_TYPE,
  CREATE_PART: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_PART: updatePart.ACTION_TYPE,
  DELETE_PART: deletePart.ACTION_TYPE,
  UPDATE_PARTS: updateParts.ACTION_TYPE,
  UPDATE_PART_LOCALLY: updatePartPosition.ACTION_TYPE,
  UPDATE_PARTS_LOCALLY: updatePartsPosition.ACTION_TYPE,
  RESET_UNSAVED_PARTS_INFO: resetUnsavedPartsInfo.ACTION_TYPE,
  SAVE_UNSAVED_PARTS: saveUnsavedParts.ACTION_TYPE,
  REORDER_PARTS: reorderParts.ACTION_TYPE,

  ...moduleActions,

  fetchParts,
  createPart: moduleActions.createEntity,
  updatePart,
  deletePart,
  updatePartPosition,
  updatePartsPosition,
  resetUnsavedPartsInfo,
  updateParts,
  reorderParts,
  saveUnsavedParts
};

export default partsActions;
