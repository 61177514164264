import { useCallback, useMemo, SyntheticEvent } from 'react';
import { useQueryParams, useSwitch, useUserRights } from '../../../utils/hooks';
import { MODAL_TYPES } from '../PartList/PartModals';
import usePartEditor from '../usePartEditor';
import { MODAL_MODES } from '../PartList/PartModals/PartModals';
import { RIGHTS } from '../../../utils/rights';
import LegacyPartGraph from './LegacyPartGraph';
import GroupedPartGraph from './GroupedPartGraph';
import './PartGraph.scss';

const usePartsGraph = (
  onOpenPartModal: (e: SyntheticEvent<HTMLElement>) => void,
  handleDelete: (nodeId: string) => void
) => {
  const hasRights = useUserRights();
  const setParams = useQueryParams();

  const { isOpen: isGroupedMode, toggle: toggleGroupedMode } = useSwitch(true);

  const onOpenPart = useCallback(
    id => {
      setParams({ type: MODAL_TYPES.PART, partId: id });
    },
    [setParams]
  );

  const onOpenPartRaw = useCallback(
    id => {
      setParams({ type: MODAL_TYPES.PART, partId: id, mode: MODAL_MODES.RAW });
    },
    [setParams]
  );

  // memoized so new object wouldn't be created
  const nodeActions = useMemo(() => {
    const actions = [
      { name: 'edit', label: 'Edit', clickHandler: onOpenPart },
      { name: 'editRaw', label: 'Edit Raw', clickHandler: onOpenPartRaw }
    ];

    if (hasRights([RIGHTS.PARTS__DELETE])) {
      actions.push({ name: 'delete', label: 'Delete', clickHandler: handleDelete });
    }

    return actions;
  }, [handleDelete, hasRights, onOpenPart, onOpenPartRaw]);

  return {
    nodeActions,
    isGroupedMode,
    onOpenPart,
    onOpenPartModal,
    onOpenPartRaw,
    onToggleGroupedMode: toggleGroupedMode
  };
};

const PartGraph = () => {
  const { seedId, projectId, onOpenPartModal, isPartModalOpen, onClosePartModal, onCreatePart, handleDelete } =
    usePartEditor();
  const { onToggleGroupedMode, isGroupedMode, onOpenPart, onOpenPartRaw } = usePartsGraph(
    onOpenPartModal,
    handleDelete
  );

  return isGroupedMode ? (
    <GroupedPartGraph
      projectId={projectId}
      seedId={seedId}
      isPartModalOpen={isPartModalOpen}
      onClosePartModal={onClosePartModal}
      onCreatePart={onCreatePart}
      onOpenPartModal={onOpenPartModal}
      onDelete={handleDelete}
      onOpenPart={onOpenPart}
      onOpenPartRaw={onOpenPartRaw}
      onSwitch={onToggleGroupedMode}
    />
  ) : (
    <LegacyPartGraph
      seedId={seedId}
      projectId={projectId}
      isPartModalOpen={isPartModalOpen}
      onClosePartModal={onClosePartModal}
      onCreatePart={onCreatePart}
      onOpenPartModal={onOpenPartModal}
      onDeletePart={handleDelete}
      onOpenPart={onOpenPart}
      onOpenPartRaw={onOpenPartRaw}
      onSwitch={onToggleGroupedMode}
    />
  );
};

export default PartGraph;
