import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactTable from 'react-table-6';
import { partsSelectors } from '../../../modules/parts';
import Form from '../../Form/Form';
import Field from '../../Form/Field';
import { defaultFilterMethod } from '../../../utils/helpers/table';
import './RemoteSwitchesEditor.scss';
import { useUpdateSeedRemoteSwitches } from '../../../store/seed';

const columns = [
  { accessor: 'index', maxWidth: 50, className: 'text-center' },
  { Header: 'Name', accessor: 'name', maxWidth: 400, filterable: true },
  {
    Header: 'Value',
    accessor: 'index',
    className: 'overflow-visible',
    Cell: row => {
      return (
        <Field.Select
          key={row.value}
          creatable
          clearable
          label=" "
          name={`switches[${row.value}].value`}
          options={row.original.options}
          useShadowValue
        />
      );
    }
  }
];

const RemoteSwitchesEditor = ({ seedId }) => {
  const { switches, options, shadowValues } = useSelector(state =>
    partsSelectors.selectRemoteSwitchesListBySeed(state, seedId)
  );

  const { updateSeedRemoteSwitches } = useUpdateSeedRemoteSwitches();

  const data = useMemo(
    () =>
      switches.map(s => ({
        ...s,
        options: options[s.name]
      })),
    [options, switches]
  );

  const onSubmit = useCallback(
    value => {
      updateSeedRemoteSwitches({ _id: seedId, remoteSwitches: value.switches });
    },
    [updateSeedRemoteSwitches, seedId]
  );

  const formShadowValues = useMemo(() => {
    return {
      list: [
        {
          name: 'part',
          value: { switches: shadowValues }
        },
        { name: 'current' }
      ]
    };
  }, [shadowValues]);

  const initialValues = useMemo(() => ({ switches }), [switches]);

  return (
    <Form
      name="Remote switches"
      onSubmit={onSubmit}
      initialValues={initialValues}
      shadowValues={formShadowValues}
      ignorePristine
    >
      You can change the values for each of the switches here. Please make sure to save changes afterwards.
      <hr />
      <ReactTable
        defaultFilterable={false}
        defaultFilterMethod={defaultFilterMethod}
        columns={columns}
        data={data}
        className="remote-switch-editor -highlight"
      />
      <hr />
    </Form>
  );
};

RemoteSwitchesEditor.propTypes = {
  seedId: PropTypes.string.isRequired
};

export default RemoteSwitchesEditor;
