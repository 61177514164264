import { reducer } from '../@helpers';
import { mappedInstancesActions } from '../mappedInstances';
import instancesActions from './instancesActions';

function moduleReducer(state, { type, payload = {} }) {
  switch (type) {
    case instancesActions.runIntegration.request.ACTION_TYPE:
      return { ...state, integrationRequest: true };
    case instancesActions.markRequestAsRead.success.ACTION_TYPE:
    case instancesActions.markRequestAsUnread.success.ACTION_TYPE:
    case instancesActions.deleteRequest.success.ACTION_TYPE:
    case instancesActions.sendToLegalesign.success.ACTION_TYPE:
    case instancesActions.checkLegalesignStatus.success.ACTION_TYPE:
    case instancesActions.sendToProduction.success.ACTION_TYPE:
    case instancesActions.cancelOrder.success.ACTION_TYPE:
    case instancesActions.runIntegration.success.ACTION_TYPE:
    case instancesActions.updateInstance.success.ACTION_TYPE:
    case instancesActions.updateInstanceData.success.ACTION_TYPE:
    case instancesActions.SIGNED_PDF_UPLOADED: {
      const instance = payload;
      const { populated } = state.list[instance._id];

      return {
        ...state,
        integrationRequest: false,
        list: { ...state.list, [instance._id]: { ...instance, populated } }
      };
    }
    case mappedInstancesActions.createMappedInstance.success.ACTION_TYPE: {
      const { instance } = payload;
      const { populated } = state.list[instance._id];

      return { ...state, list: { ...state.list, [instance._id]: { ...instance, populated } } };
    }
    case instancesActions.deleteRequest.failure.ACTION_TYPE:
    case instancesActions.runIntegration.failure.ACTION_TYPE:
    case instancesActions.sendToLegalesign.failure.ACTION_TYPE: {
      return { ...state, error: payload.error, integrationRequest: false };
    }
    case instancesActions.deleteEntity.success.ACTION_TYPE: {
      const newList = { ...state.list };

      delete newList[payload.id];

      return { ...state, list: newList };
    }
    default:
      return state;
  }
}

export default reducer.createBaseModuleReducer(instancesActions, moduleReducer);
