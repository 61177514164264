import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import { partnersActions, partnersSelectors } from '../../modules/partners';
import PartnerForm from '../PartnerForm';
import { ApplicationRoute } from '../../utils/appRoutes';

const partnerCreationPage = b.with('partner-creation-page');

export class PartnerCreationPage extends React.PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isCreated, history, resetFlag } = this.props;

    if (isCreated) {
      resetFlag();
      history.push(ApplicationRoute.Partners);
    }
  }

  handleSubmitPartnerForm = partner => {
    this.props.createPartner(partner);
  };

  render() {
    return (
      <div className={`${partnerCreationPage()} h-100`}>
        <PartnerForm onSubmit={this.handleSubmitPartnerForm} created />
      </div>
    );
  }
}

PartnerCreationPage.defaultProps = {
  isCreated: false
};

PartnerCreationPage.propTypes = {
  createPartner: PropTypes.func.isRequired,
  resetFlag: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isCreated: PropTypes.bool
};

const mapStateToProps = state => ({
  isCreated: partnersSelectors.selectPartnerCreatedFlag(state)
});
const mapDispatchToProps = {
  createPartner: partnersActions.createPartner,
  resetFlag: partnersActions.resetFlags
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCreationPage);
