import mappedInstancesActions from './mappedInstancesActions';

export const initialState = {
  preview: {},
  list: {},
  error: '',
  request: false,
  previewRequest: false,
  created: false,
  creating: false
};

const mappedInstancesReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case mappedInstancesActions.fetchMappedInstance.request.ACTION_TYPE:
      return { ...state, request: true };
    case mappedInstancesActions.fetchMappedInstancePreview.request.ACTION_TYPE:
      return { ...state, previewRequest: true, created: false, creating: false };
    case mappedInstancesActions.createMappedInstance.request.ACTION_TYPE:
      return { ...state, creating: true };
    case mappedInstancesActions.fetchMappedInstance.success.ACTION_TYPE:
    case mappedInstancesActions.updateMappedInstance.success.ACTION_TYPE: {
      return { ...state, list: { ...state.list, [payload._id]: payload }, request: false, error: '' };
    }
    case mappedInstancesActions.createMappedInstance.success.ACTION_TYPE: {
      const { mappedInstance } = payload;

      return {
        ...state,
        list: { ...state.list, [mappedInstance._id]: mappedInstance },
        request: false,
        error: '',
        created: true,
        creating: false
      };
    }
    case mappedInstancesActions.fetchMappedInstancePreview.success.ACTION_TYPE: {
      return { ...state, preview: { ...payload }, previewRequest: false, error: '' };
    }
    case mappedInstancesActions.fetchMappedInstance.failure.ACTION_TYPE:
    case mappedInstancesActions.fetchMappedInstancePreview.failure.ACTION_TYPE:
    case mappedInstancesActions.createMappedInstance.failure.ACTION_TYPE:
    case mappedInstancesActions.updateMappedInstance.failure.ACTION_TYPE:
      return { ...state, error: payload.error, request: false, previewRequest: false };
    default:
      return state;
  }
};

export default mappedInstancesReducer;
