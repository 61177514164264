import { useState, useMemo } from 'react';
import { ProjectType, SeedType } from 'types';
import { get, set } from 'lodash';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormField, Form, Box } from '../Atoms';
import { useFetchGlobalSettings } from '../../modules/settings/settingsHooks';
import { useSeed, useUpdateSeed } from '../../store/seed';
import { useProject, useUpdateProject } from '../../store/project';
import { useSwitch, useAssetsActions, usePartList } from '../../utils/hooks';
import { partsSelectors } from '../../modules/parts';
import {
  ContactForm,
  Metrics,
  Actions,
  PriceSchemes,
  Content,
  Summary,
  ExtraInfo,
  OptionStatuses,
  Translations,
  Branding,
  PerLayerPricing,
  UserFlow,
  Sharing,
  Pdf,
  Cookies,
  ModelSettings,
  AreaCalc,
  SkyBox,
  Theming
} from './SettingsFormComponents';
import { GroupLink, SettingsGroup, ProjectSettingsDashboardProps } from './types';

import './Project.scss';

const ProjectSettingsDashboard = ({ project, projectId, seedId }: ProjectSettingsDashboardProps) => {
  const { updateProject, isError: isProjectError, error: projectError } = useUpdateProject();
  const { updateSeedMainScope, isError: isSeedError, error: seedError } = useUpdateSeed();
  const globalSettings = useFetchGlobalSettings();

  enum Mode {
    Seed = 'seed',
    Project = 'project'
  }

  let mode: Mode;

  let publicSeed: SeedType;
  let shadowValues: any;

  let handleUpdate: (formValue: any) => void;

  const loadedProject = useProject(projectId || '');
  const loadedSeed = useSeed(project?.publicSeedId || seedId || '');
  const [currentFormData, setCurrentFormData] = useState<ProjectType | SeedType>();

  const { isOpen, toggle } = useSwitch(false);
  const [groupIndex, setGroupIndex] = useState<number>();
  const [linkIndex, setLinkIndex] = useState<number>();

  const fixNullsInNumberFields = (formValue: SeedType | ProjectType) => {
    setCurrentFormData(formValue);

    get(formValue, 'settings.model.ppsqmLayers', []).forEach((layer: any) => {
      if (layer.ppsqm === null) {
        // eslint-disable-next-line no-param-reassign
        layer.ppsqm = undefined;
      }
    });

    const maxInstanceCount = 'settings.model.maxInstanceCount';

    if (get(formValue, maxInstanceCount) === null) set(formValue, maxInstanceCount, undefined);

    const defaultPriceRounding = 'settings.price.defaults.priceRounding';

    if (get(formValue, defaultPriceRounding) === null) set(formValue, defaultPriceRounding, undefined);

    get(formValue, 'settings.price.schemes', []).forEach((scheme: any) => {
      if (scheme.priceRounding === null) {
        // eslint-disable-next-line no-param-reassign
        scheme.priceRounding = undefined;
      }
    });

    return formValue;
  };

  if (projectId && seedId && !project) {
    // is used to edit a seed
    // eslint-disable-next-line no-param-reassign
    project = loadedProject.project;
    publicSeed = (currentFormData as SeedType) || (loadedSeed.seed as SeedType);
    mode = Mode.Seed;

    shadowValues = {
      list: [
        {
          name: 'master',
          value: {
            settings: globalSettings.raw
          }
        },
        {
          name: 'project',
          value: {
            settings: project?.settings || {}
          }
        },
        {
          name: 'current'
        }
      ]
    };

    handleUpdate = async formValue => {
      const result = await updateSeedMainScope({ ...publicSeed, ...formValue });

      if (!(result as any).error) {
        toggle();
      }
    };
  } else if (project) {
    // is used to edit a project
    // eslint-disable-next-line no-param-reassign
    project = (currentFormData as ProjectType) || project;
    publicSeed = loadedSeed.seed as SeedType;
    mode = Mode.Project;

    shadowValues = {
      list: [
        {
          name: 'master',
          value: {
            settings: globalSettings.raw
          }
        },
        {
          name: 'current'
        },
        {
          name: 'public seed',
          value: {
            settings: publicSeed?.settings
          }
        }
      ]
    };

    handleUpdate = async formValue => {
      const result = await updateProject({ ...project, ...formValue });

      if (!(result as any).error) {
        toggle();
      }
    };
  }

  const cameraViewSuggestions = useSelector(state =>
    partsSelectors.selectCameraViewsForSuggestions(state, project?.publicSeedId)
  );

  usePartList(project?.publicSeedId);

  const {
    projectImages,
    projectTextures,
    uploadImage,
    uploadTexture,
    updateImagesAfterUpload,
    updateTexturesAfterUpload
  } = useAssetsActions(project?._id);

  const settingsGroups: SettingsGroup[] = useMemo(() => {
    return [
      {
        name: 'Content',
        links: [
          {
            title: 'Content settings',
            description: 'Title, intro, gallery, video',
            icon: 'mdiImageText',
            inactive: p =>
              get(p, 'settings.interface.hideGallery') === undefined && !get(p, 'settings.intro', []).length,
            component: projectImagesList => (
              <Content
                projectImages={projectImagesList}
                uploadImage={uploadImage}
                updateImagesAfterUpload={updateImagesAfterUpload}
              />
            )
          },
          {
            title: 'Summary',
            description: 'Gallery, title, paragraph',
            icon: 'mdiTranslate',
            inactive: p => p?.settings?.summary === undefined,
            component: projectImagesList => (
              <Summary
                projectImages={projectImagesList}
                uploadImage={uploadImage}
                updateImagesAfterUpload={updateImagesAfterUpload}
              />
            )
          },
          {
            title: 'Extra Info Collections',
            description: 'Used in UI',
            icon: 'mdiCardBulletedSettingsOutline',
            inactive: p => !get(p, 'settings.extraInfoCollections', []).length,
            component: projectImagesList => (
              <ExtraInfo
                projectImages={projectImagesList}
                uploadImage={uploadImage}
                updateImagesAfterUpload={updateImagesAfterUpload}
              />
            )
          },
          {
            title: 'Option Statuses',
            description: 'Disable, lock, inactive, hide price, hide area, etc.',
            icon: 'mdiListStatus',
            inactive: p => !get(p, 'settings.optionStatuses', []).length,
            component: () => <OptionStatuses />
          }
        ]
      },
      {
        name: 'UI',
        links: [
          {
            title: 'Translations',
            description: 'For multilingual configurators',
            icon: 'mdiTranslate',
            inactive: p => !get(p, 'settings.translations', []).length,
            component: () => <Translations />
          },
          {
            title: 'Branding',
            description: 'Brand name, logo, links, favicon',
            icon: 'mdiTagTextOutline',
            inactive: p =>
              get(p, 'settings.interface.favicon') === undefined &&
              get(p, 'settings.interface.header') === undefined &&
              get(p, 'settings.interface.headerIcon') === undefined &&
              get(p, 'settings.interface.footer') === undefined &&
              get(p, 'settings.sisterConfigurators') === undefined,
            component: projectImagesList => (
              <Branding
                projectImages={projectImagesList}
                uploadImage={uploadImage}
                updateImagesAfterUpload={updateImagesAfterUpload}
              />
            )
          },
          {
            title: 'Theming',
            description: 'Custom CSS styling',
            icon: 'mdiPaletteOutline',
            inactive: p => !get(p, 'theme', null),
            component: () => <Theming />
          }
        ]
      },
      {
        name: 'Pricing',
        links: [
          {
            title: 'Pricing Schemes',
            description: 'Pricing Schemes',
            icon: 'mdiCashMultiple',
            inactive: p =>
              get(p, 'settings.price.schemes') === undefined && get(p, 'settings.price.defaults') === undefined,
            component: () => <PriceSchemes />
          },
          {
            title: 'Per Layer Pricing',
            description: 'Special pricing mode',
            icon: 'mdiLayersTripleOutline',
            inactive: p => !get(p, 'settings.model.ppsqmLayers', []).length,
            component: () => <PerLayerPricing />
          }
        ]
      },
      {
        name: 'User flow',
        links: [
          {
            title: 'User Flow Settings',
            description: 'Control how users navigate inside the configurator',
            icon: 'mdiTransitConnectionVariant',
            inactive: p =>
              get(p, 'settings.interface.aboutTabHidden') === undefined &&
              get(p, 'settings.interface.reviewTabHidden') === undefined &&
              get(p, 'settings.interface.summaryTabHidden') === undefined &&
              get(p, 'settings.comments.enabled') === undefined,
            component: () => <UserFlow />
          },

          {
            title: 'Actions',
            description: 'Control what users can do inside the configurator',
            icon: 'mdiGestureTap',
            component: () => <Actions seedId={project?.publicSeedId} />,
            inactive: p => {
              const groups = get(p, 'settings.actions.groups');

              const groupsInactive =
                get(groups, 'aboutTab', []).length === 0 &&
                get(groups, 'menu', []).length === 0 &&
                get(groups, 'overviewPanel', []).length === 0 &&
                get(groups, 'reviewTab', []).length === 0 &&
                get(groups, 'shareDialog', []).length === 0 &&
                get(groups, 'summaryPanel', []).length === 0 &&
                get(groups, 'summaryTab', []).length === 0 &&
                !get(groups, 'finishButton') &&
                !get(groups, 'homeButton') &&
                !get(groups, 'summarySaveButton');

              return groupsInactive && get(p, 'settings.actions.list', []).length === 0;
            }
          },
          {
            title: 'Save Form',
            description: 'Require email to save anonymous designs',
            icon: 'mdiContentSave',
            inactive: p => get(p, 'settings.partner.requireEmailToSaveOrphanInstances') === undefined,
            component: () => (
              <FormField.Toggle<ProjectType>
                label="Require email to save orphan instances"
                description=""
                name="settings.partner.requireEmailToSaveOrphanInstances"
                useShadowValue
              />
            )
          },
          {
            title: 'Contact Form',
            description: 'Form settings and fields configuration',
            icon: 'mdiFormTextbox',
            inactive: p => !get(p, 'settings.contactForm', null),
            component: () => <ContactForm project={project} />
          }
        ]
      },
      {
        name: 'Publishing',
        links: [
          {
            title: 'Sharing & SEO',
            description: 'Favicon, description, share image, etc.',
            icon: 'mdiShareVariant',
            inactive: p => get(p, 'settings.sharing') === undefined,
            component: projectImagesList => {
              return (
                <Sharing
                  projectImages={projectImagesList}
                  uploadImage={uploadImage}
                  updateImagesAfterUpload={updateImagesAfterUpload}
                  cameraViewSuggestions={cameraViewSuggestions}
                />
              );
            }
          },
          {
            title: 'PDF',
            description: 'Enable exportitng configuration as a file',
            icon: 'mdiFilePdfBox',
            inactive: p => get(p, 'settings.pdf') === undefined,
            component: () => <Pdf />
          },
          {
            title: 'Cookies',
            description: 'Cookie consent dialog and link to privacy policy',
            icon: 'mdiCookieOutline',
            inactive: p => get(p, 'settings.cookies') === undefined,
            component: () => <Cookies />
          },
          {
            title: 'Embedding',
            hidden: mode === Mode.Seed,
            description: 'Allowed domains',
            icon: 'mdiCodeTags',
            inactive: p => !get(p, 'allowedDomains', []).length,
            component: () => (
              <FormField.MultiSelect<ProjectType, { label: string; value: string }>
                creatable
                name="allowedDomains"
                label="Allowed domains"
                description="List of domains where embedding is allowed"
                options={[]}
                getNewOptionData={(value: string) => {
                  return { label: value, value };
                }}
              />
            )
          }
        ]
      },
      {
        name: 'Model',
        links: [
          {
            title: 'Model Settings',
            description: 'Metric or imperial units, max instance conut, etc.',
            icon: 'mdiCubeOutline',
            inactive: p =>
              get(p, 'settings.model.forceFetchingAssets') === undefined &&
              get(p, 'settings.model.maxInstanceCount') === undefined &&
              get(p, 'settings.model.modelUnits') === undefined,
            component: () => <ModelSettings />
          },
          {
            title: 'Automatic Area Calculation',
            description: 'Net area, area unit and caveat',
            icon: 'mdiFloorPlan',
            inactive: p =>
              get(p, 'settings.model.netAreaLayers') === undefined &&
              get(p, 'settings.model.areaUnit') === undefined &&
              get(p, 'settings.model.netAreaCaveat') === undefined,
            component: () => <AreaCalc />
          },
          {
            title: 'Custom Metrics',
            description: 'Display and conut any non-standard metrics',
            icon: 'mdiCounter',
            inactive: p => get(p, 'settings.metrics') === undefined,
            component: projectImagesList => {
              return (
                <Metrics
                  projectImages={projectImagesList}
                  uploadImage={uploadImage}
                  updateImagesAfterUpload={updateImagesAfterUpload}
                />
              );
            }
          },
          {
            title: 'Skybox',
            description: 'Ehable skybox and setup textures',
            icon: 'mdiPanorama',
            inactive: p =>
              get(p, 'settings.skybox.enabled') === undefined &&
              get(p, 'settings.skybox.useAsEnvMap') === undefined &&
              get(p, 'settings.skybox.equirectangular') === undefined,
            component: (_, projectTextureList) => (
              <SkyBox
                projectTextures={projectTextureList}
                uploadTexture={uploadTexture}
                updateTexturesAfterUpload={updateTexturesAfterUpload}
              />
            )
          },
          {
            title: 'Part Animation',
            description: 'Enable part animation',
            icon: 'mdiPlay',
            inactive: p => get(p, 'settings.partAnimation.enabled') === undefined,
            component: () => (
              <FormField.Toggle<ProjectType>
                name="settings.partAnimation.enabled"
                label="Enable part animation"
                description="Will animate changing parts"
                useShadowValue
              />
            )
          }
        ]
      }
    ];
    // "mode" can't be added, otherwise there will be a tsc error. but it may not change for a given settings page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraViewSuggestions, project, updateImagesAfterUpload, updateTexturesAfterUpload, uploadImage, uploadTexture]);

  const getSettingsItemByIndex = (gIndex?: number, lIndex?: number): GroupLink | null => {
    if (gIndex === undefined || lIndex === undefined) {
      return null;
    }

    const group = settingsGroups[gIndex];

    if (!group || !group.links.length) return null;

    const link = group.links[lIndex];

    if (!link) return null;

    return link;
  };

  const ModalComponent = () => {
    const isError = isProjectError || isSeedError;
    const error = projectError || seedError;

    const defaultError = {
      path: get(error, 'data.data[0].path', []).join('.'),
      message: get(error, 'data.data[0].message', '')
    };

    const activeLink = getSettingsItemByIndex(groupIndex, linkIndex);

    const seedForm = (
      <Form<SeedType>
        defaultValues={publicSeed}
        onCancel={() => {
          setCurrentFormData(undefined);
        }}
        defaultError={isError && currentFormData ? defaultError : undefined}
        shadowValues={shadowValues.list}
        onSubmit={formValues => {
          handleUpdate(fixNullsInNumberFields(formValues));
        }}
      >
        {activeLink?.component && activeLink.component(projectImages, projectTextures)}
      </Form>
    );
    const projectForm = (
      <Form<ProjectType>
        defaultValues={project}
        onCancel={() => {
          setCurrentFormData(undefined);
        }}
        shadowValues={shadowValues.list}
        defaultError={isError && currentFormData ? defaultError : undefined}
        onSubmit={formValues => {
          handleUpdate(fixNullsInNumberFields(formValues));
        }}
      >
        {activeLink?.component && activeLink.component(projectImages, projectTextures)}
      </Form>
    );

    return (
      <Modal className={isError && currentFormData ? 'errorDialog' : ''} size="xl" isOpen={isOpen}>
        <ModalHeader
          toggle={() => {
            toggle();
            setCurrentFormData(undefined);
          }}
        >
          {activeLink?.title}
        </ModalHeader>
        <ModalBody>
          {mode === Mode.Project && projectForm}
          {mode === Mode.Seed && seedForm}
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      {settingsGroups.map((group, index) => (
        <div key={group.name} className="mt-3 mb-1">
          <h3 className="ml-4">{group.name}</h3>
          <div className="d-flex flex-wrap pb-2 gap-3">
            {group.links
              .filter((link: GroupLink) => !link.hidden)
              .map((link: GroupLink) => (
                <div key={link.title}>
                  <Box
                    onClick={() => {
                      setGroupIndex(settingsGroups.indexOf(group));
                      setLinkIndex(group.links.indexOf(link));
                      toggle();
                    }}
                    color="#2D9CDB"
                    icon={link.icon}
                    title={link.title}
                    description={link.description}
                    inactive={link.inactive ? link.inactive(mode === Mode.Project ? project : publicSeed) : true}
                    innerChildren={
                      <>
                        {mode === Mode.Project && link.inactive && !link.inactive(publicSeed) && (
                          <div className="mt-2 rounded-pill border border-warning text-warning px-1">
                            <small>Overridden by Version</small>
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
      {ModalComponent()}
    </>
  );
};

export default ProjectSettingsDashboard;
