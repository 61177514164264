import { useCallback } from 'react';
import { useFormContext, Path } from 'react-hook-form';
import Icon from '../../../Icon/Icon';

interface ArrayRemoveButtonProps {
  index: number;
  name: any;
  labelOffset?: boolean;
}

const ArrayRemoveButton = <T,>({ index, name, labelOffset }: ArrayRemoveButtonProps) => {
  const { getValues, setValue } = useFormContext<T>();

  const handleRemove = useCallback(
    e => {
      const idxToRemove = e.currentTarget.dataset.index;
      const current = getValues(name) as any[];

      // "remove" from useFieldArray has an issue with deleting from nested arrays, so using "setValue" instead
      current.splice(idxToRemove, 1);
      setValue(name as Path<T>, current as any, { shouldDirty: true });
    },
    [getValues, setValue, name]
  );

  return (
    <button
      className="btn btn-sm border-0 btn-outline-danger flex-grow-0 ml-auto px-1 align-self-start"
      style={{ height: '2rem', marginTop: labelOffset ? '2rem' : '' }}
      type="button"
      title="Remove"
      data-index={index}
      onClick={handleRemove}
    >
      <Icon type="mdiTrashCanOutline" scale={0.7} />
    </button>
  );
};

export default ArrayRemoveButton;
