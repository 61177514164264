export const VIEW_TYPES = {
  PLAN: 'PlanView',
  VIEW: 'View',
  FIXED: 'Fixed',
  ORTHO: 'Ortho',
  ORTHO_FIXED: 'OrthoFixed',
  OTHER: 'other'
};

export const VIEW_TYPES_LIST = Object.values(VIEW_TYPES);

export const VIEW_TYPES_SUGGESTIONS = VIEW_TYPES_LIST.map(value => ({ value, label: value }));
