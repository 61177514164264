import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export const Cookies = () => {
  return (
    <>
      <FormField.Text<ProjectType>
        name="settings.cookies.header"
        label="Cookie consent dialog header"
        description=""
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.cookies.message"
        label="Cookie consent dialog message"
        description=""
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.cookies.policyLink.text"
        label="Cookie consent policy link text"
        description=""
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.cookies.policyLink.link"
        label="Cookie consent policy link label"
        description=""
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.cookies.policyLink.url"
        label="Cookie consent policy link url"
        description=""
        useShadowValue
      />
    </>
  );
};
