import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import { Button } from '../../../Atoms';
import SortableWrapper from '../../SortableWrapper';
import useArrayFieldMutators from '../useArrayFieldMutators';
import StaticEmptyAlert from './StaticEmptyAlert';
import FieldItem from './FieldItem';

const ArrayFields = ({
  name,
  validate,
  subscription,
  defaultNew,
  isStatic,
  lockMoving,
  staticEmptyAlert,
  children,
  headingIndex,
  isOpen,
  headingFormat
}) => {
  const fieldArray = useFieldArray(name, { validate, subscription });

  const { fields, meta } = fieldArray;

  const {
    keys,
    removeHandler: onKeyRemove,
    moveHandler: onKeyMove,
    addHandler: onKeyAdd,
    forceMount
  } = useArrayFieldMutators(fieldArray);

  const childrenArray = useMemo(() => React.Children.toArray(children), [children]);

  return (
    <>
      <SortableWrapper
        useDragHandle={!isStatic || !lockMoving}
        onSortEnd={({ oldIndex, newIndex }) => onKeyMove(oldIndex, newIndex)}
        helperClass="card_moved"
      >
        {isStatic ? <StaticEmptyAlert isEmpty={fields.length === 0} content={staticEmptyAlert} /> : null}
        {fields.map((field, index) => (
          <FieldItem
            key={keys[index]}
            onRemove={() => onKeyRemove(index)}
            headingIndex={headingIndex}
            name={field}
            index={index}
            isOpen={isOpen}
            isStatic={isStatic}
            lockMoving={lockMoving}
            headingFormat={headingFormat}
            value={fields.value[index]}
            forceMount={forceMount[index]}
          >
            {childrenArray}
          </FieldItem>
        ))}
      </SortableWrapper>
      {meta.invalid && React.isValidElement(meta.error) ? meta.error : null}
      {isStatic ? null : (
        <Button onClick={() => onKeyAdd(defaultNew)} outline color="primary" block>
          + Add
        </Button>
      )}
    </>
  );
};

ArrayFields.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  subscription: PropTypes.shape({}),
  defaultNew: PropTypes.shape({}).isRequired,
  isStatic: PropTypes.bool.isRequired,
  lockMoving: PropTypes.bool.isRequired,
  staticEmptyAlert: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  headingIndex: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headingFormat: PropTypes.func
};

ArrayFields.defaultProps = {
  headingFormat: undefined,
  validate: undefined,
  subscription: {}
};

export default ArrayFields;
