import { useState, useMemo } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { ProjectType } from 'types';
import { partsSelectors } from '../../../modules/parts';
import { FormField } from '../../Atoms';
import { TAB_NAMES } from '../../../utils/defaultTabNames';
import { ACTION_TYPES, REQUIRE_LOGIN_DIALOGS, DIALOGS } from './actionsConstants';

export interface TypeDependentFieldsProps {
  pageState: { type: { label: string; value: string }[] };
  seedId?: string;
  index?: number;
}

export const TypeDependentFields = ({ pageState, index, seedId }: TypeDependentFieldsProps) => {
  const tabs = useSelector(state => partsSelectors.selectTabNamesForSuggestions(state, seedId));
  const tabsSuggestions = useMemo(() => {
    return [
      ...tabs,
      { label: 'About', value: TAB_NAMES.ABOUT },
      { label: 'Review', value: TAB_NAMES.REVIEW },
      { label: 'Summary', value: TAB_NAMES.SUMMARY }
    ];
  }, [tabs]);

  const typeValue = get(pageState, `type[${index}]`);
  const [showToggle, setShowToggle] = useState<boolean>(false);

  const handleDialogChange = (value: any) => {
    setShowToggle(!!REQUIRE_LOGIN_DIALOGS[value?.value]);
  };

  return (
    <>
      {typeValue?.value === ACTION_TYPES.OPEN_DIALOG && (
        <>
          <FormField.Select<ProjectType>
            onChange={handleDialogChange}
            name={`settings.actions.list.${index}.dialog`}
            label="Dialog"
            options={DIALOGS}
            required
          />
          {showToggle && (
            <FormField.Toggle<ProjectType> name={`settings.actions.list.${index}.requireLogin`} label="Require login" />
          )}
        </>
      )}
      {typeValue?.value === ACTION_TYPES.OPEN_LINK && (
        <FormField.Text<ProjectType> name={`settings.actions.list.${index}.link`} label="Link" required />
      )}
      {typeValue?.value === ACTION_TYPES.OPEN_TAB && (
        <FormField.Select<ProjectType>
          name={`settings.actions.list.${index}.tab`}
          label="Tab"
          options={tabsSuggestions}
          required
        />
      )}
    </>
  );
};
