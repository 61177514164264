import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

const WhenFieldChanges = ({ field, becomes, set, to }) => {
  const [previous, setPrevious] = useState();
  const { input: sourceField } = useField(field, { subscription: { value: true } });
  const { input: targetField } = useField(set, { subscription: {} });
  const { onChange } = targetField;

  useEffect(() => {
    setPrevious(sourceField.value);
  }, [sourceField.value]);

  useEffect(() => {
    if (previous === becomes) {
      setPrevious(previous);
      onChange(to);
    }
  }, [becomes, field, onChange, previous, to]);

  return null;
};

WhenFieldChanges.propTypes = {
  field: PropTypes.string.isRequired,
  set: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  becomes: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  to: PropTypes.any.isRequired
};

export default WhenFieldChanges;
