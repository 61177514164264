import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../Atoms';

const Reset = ({ disabled, label, color, icon }) => (
  <Button name="reset" disabled={disabled} color={color} type="reset">
    <i className={`fa fa-fw ${icon}`} />
    {label}
  </Button>
);

Reset.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string
};
Reset.defaultProps = {
  disabled: false,
  label: 'Reset',
  color: 'outline-danger',
  icon: 'fa-times'
};

export default Reset;
