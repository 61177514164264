import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../../Atoms';
import { RIGHTS } from '../../../../../utils/rights';
import { TYPE_INSTANCE, TYPE_MAPPED_INSTANCE } from '../../../../../utils/propTypes';
import ProtectedButton from '../../../../ProtectedButton';
import { mappedInstancesActions } from '../../../../../modules/mappedInstances';
import MappedInstanceMetadata from '../MappedInstanceMetadata';
import MappedInstanceCode from '../MappedInstanceCode';
import MappedInstanceEditor from './MappedInstanceEditor';

const mappedInstanceViewer = b.with('mapped-instance-editor');

export class MappedInstanceViewer extends React.PureComponent {
  state = {
    editor: false
  };

  get isLeadSent() {
    const { instance } = this.props;

    return instance.lead && instance.lead.sentToProduction;
  }

  handleRegenerateMappedInstance = () => {
    const { updateMappedInstance, mappedInstance } = this.props;

    updateMappedInstance({ ...mappedInstance, lines: mappedInstance.generatedLines });
  };

  handleResetMappedInstance = () => {
    const { updateMappedInstance, mappedInstance } = this.props;

    updateMappedInstance({ ...mappedInstance, lines: mappedInstance.originalLines });
  };

  handleUpdateMappedInstance = lines => {
    const { updateMappedInstance, mappedInstance } = this.props;

    updateMappedInstance({ ...mappedInstance, lines });

    this.handleCloseMappedEditor();
  };

  handleOpenMappedEditor = () => {
    this.setState({ editor: true });
  };

  handleCloseMappedEditor = () => {
    this.setState({ editor: false });
  };

  renderAdditionalButtons() {
    const { mappedInstance } = this.props;
    const { locked } = mappedInstance;

    return (
      <div className="mb-3">
        <ProtectedButton
          rights={[RIGHTS.MAPPED_INSTANCES__UPDATE]}
          color="primary"
          title="Regenerate from current lead and factory mapping"
          size="sm"
          onClick={this.handleRegenerateMappedInstance}
          disabled={this.isLeadSent || locked}
        >
          <i className="fa fa-fw fa-repeat" /> Regenerate
        </ProtectedButton>
        <ProtectedButton
          rights={[RIGHTS.MAPPED_INSTANCES__UPDATE]}
          color="primary"
          title="Reset to last generated data"
          size="sm"
          onClick={this.handleResetMappedInstance}
          disabled={this.isLeadSent || locked}
        >
          <i className="fa fa-fw fa-undo" /> Reset
        </ProtectedButton>
      </div>
    );
  }

  renderEditButton() {
    const { mappedInstance } = this.props;
    const { locked } = mappedInstance;

    return (
      <ProtectedButton
        rights={[RIGHTS.MAPPED_INSTANCES__UPDATE]}
        color="info"
        onClick={this.handleOpenMappedEditor}
        disabled={this.isLeadSent || locked}
      >
        Edit
      </ProtectedButton>
    );
  }

  renderConfirmingInfo() {
    const { mappedInstance } = this.props;

    if (mappedInstance.locked && this.isLeadSent) {
      return (
        <Alert color="primary">
          <i className="fa fa-fw fa-check" />
          Sent to production and locked
        </Alert>
      );
    }

    if (!mappedInstance.locked && this.isLeadSent) {
      return (
        <Alert color="warning">
          <i className="fa fa-fw fa-exclamation" />
          Sent to production without mapping.
        </Alert>
      );
    }

    return null;
  }

  render() {
    const { onClose, mappedInstance, instance } = this.props;
    const { editor } = this.state;

    return (
      <Modal
        isOpen
        toggle={onClose}
        size="lg"
        centered
        className={mappedInstanceViewer()}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={onClose}>Mapped instance</ModalHeader>
        <ModalBody className={mappedInstanceViewer('body')}>
          <MappedInstanceMetadata
            factoryId={mappedInstance.factoryId}
            instanceId={instance._id}
            seedId={instance.seed.root}
          />
          <MappedInstanceCode lines={mappedInstance.lines} />
          {this.renderAdditionalButtons()}
          {editor ? (
            <MappedInstanceEditor
              onClose={this.handleCloseMappedEditor}
              onSave={this.handleUpdateMappedInstance}
              lines={mappedInstance.lines}
            />
          ) : null}
          {this.renderConfirmingInfo()}
        </ModalBody>
        <ModalFooter>
          {this.renderEditButton()}
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MappedInstanceViewer.defaultProps = {};

MappedInstanceViewer.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  mappedInstance: TYPE_MAPPED_INSTANCE.isRequired,
  onClose: PropTypes.func.isRequired,
  updateMappedInstance: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  updateMappedInstance: mappedInstancesActions.updateMappedInstance
};

export default connect(null, mapDispatchToProps)(MappedInstanceViewer);
