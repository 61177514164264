import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from '../Atoms';
import { useQueryParamsForLinkFormatter, useUserRights } from '../../utils/hooks';

import './TabNavigation.scss';

const tabNavigation = b.with('tab-navigation');

const TabNavigation = ({ routes = [], activeRoute }) => {
  const formatQueryParams = useQueryParamsForLinkFormatter();
  const hasRights = useUserRights();

  return (
    <Nav tabs className={tabNavigation()}>
      {routes
        .filter(({ rights }) => hasRights(rights))
        .map(({ name, label, icon }) => (
          <NavItem key={name}>
            <NavLink
              tag={Link}
              to={{ search: formatQueryParams({ screen: name }) }}
              active={name === activeRoute}
              data-name={name}
              className={`${tabNavigation('link')} px-2`}
            >
              {icon ? <i className={`fa ${icon} fa-fw`} /> : null} {label}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
};

TabNavigation.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      rights: PropTypes.arrayOf(PropTypes.string.isRequired)
    })
  ).isRequired,
  activeRoute: PropTypes.string.isRequired
};

export default TabNavigation;
