import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from '../../../../Atoms';
import { RIGHTS } from '../../../../../utils/rights';
import { TYPE_INSTANCE, TYPE_MAPPED_INSTANCE_PREVIEW } from '../../../../../utils/propTypes';
import ProtectedButton from '../../../../ProtectedButton';
import { mappedInstancesActions, mappedInstancesSelectors } from '../../../../../modules/mappedInstances';
import Loader from '../../../../Loader';
import MappedInstanceMetadata from '../MappedInstanceMetadata';
import MappedInstanceCode from '../MappedInstanceCode';

const mappedInstancePreview = b.with('mapped-instance-preview');

export class MappedInstancePreview extends React.PureComponent {
  componentDidMount() {
    const { fetchMappedInstancePreview, instance } = this.props;

    fetchMappedInstancePreview(instance._id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isCreated, onClose } = this.props;

    if (isCreated) {
      onClose();
    }
  }

  get isLeadSent() {
    const { instance } = this.props;

    return instance.lead && instance.lead.sentToProduction;
  }

  handleCreateMappedInstance = () => {
    const { instance, createMappedInstance } = this.props;

    createMappedInstance(instance._id, instance.factoryMap._id);
  };

  renderInstructions() {
    if (this.isLeadSent) {
      return <Alert color="warning">Cannot change preview because lead is already sent to production</Alert>;
    }

    return <Alert color="primary">Save current preview to database to edit and get CSV data</Alert>;
  }

  render() {
    const { onClose, request, isCreating, instance, preview = {} } = this.props;

    return (
      <Modal isOpen toggle={onClose} size="lg" centered className={mappedInstancePreview()}>
        <ModalHeader toggle={onClose}>Mapped instance preview</ModalHeader>
        <ModalBody className={mappedInstancePreview('body')}>
          <Loader loading={request}>
            <MappedInstanceMetadata
              factoryId={preview.factoryId}
              instanceId={instance._id}
              seedId={instance.seed.root}
            />
            <MappedInstanceCode lines={preview.lines} />
            {this.renderInstructions()}
          </Loader>
        </ModalBody>
        <ModalFooter>
          <ProtectedButton
            rights={[RIGHTS.MAPPED_INSTANCES__CREATE]}
            color="primary"
            onClick={this.handleCreateMappedInstance}
            disabled={this.isLeadSent || isCreating}
          >
            <i className="fa fa-fw fa-upload" /> Save
          </ProtectedButton>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MappedInstancePreview.defaultProps = {
  request: false,
  isCreated: false,
  isCreating: false
};

MappedInstancePreview.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchMappedInstancePreview: PropTypes.func.isRequired,
  createMappedInstance: PropTypes.func.isRequired,
  preview: TYPE_MAPPED_INSTANCE_PREVIEW, // eslint-disable-line react/require-default-props,
  request: PropTypes.bool,
  isCreated: PropTypes.bool,
  isCreating: PropTypes.bool
};

const mapStateToProps = state => ({
  request: mappedInstancesSelectors.selectMappedInstancesPreviewRequest(state),
  preview: mappedInstancesSelectors.selectMappedInstancesPreview(state),
  isCreated: mappedInstancesSelectors.selectMappedInstancesCreatedFlag(state),
  isCreating: mappedInstancesSelectors.selectMappedInstancesCreatingFlag(state)
});

const mapDispatchToProps = {
  fetchMappedInstancePreview: mappedInstancesActions.fetchMappedInstancePreview,
  createMappedInstance: mappedInstancesActions.createMappedInstance
};

export default connect(mapStateToProps, mapDispatchToProps)(MappedInstancePreview);
