import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PartControlType } from 'types';
import { ListGroup, ListGroupItem } from '../../../Atoms';
import { partsSelectors } from '../../../../modules/parts';
import { useQueryParams } from '../../../../utils/hooks';
import { MODAL_TYPES, NEW_INDEX } from '../../PartList/PartModals';

interface PartControlsProps {
  nodeId: string;
}

const PartControls = ({ nodeId }: PartControlsProps) => {
  const setParams = useQueryParams();

  const controlsList: PartControlType[] = useSelector(state => partsSelectors.selectPartControls(state, nodeId));
  const addControl = useCallback(
    e => {
      if (e.defaultPrevented) return; // default is prevented on panning the map
      setParams({ type: MODAL_TYPES.CONTROL, controlIndex: NEW_INDEX, partId: nodeId });
    },
    [nodeId, setParams]
  );
  const editControl = useCallback(
    e => {
      if (e.defaultPrevented) return; // default is prevented on panning the map
      const { controlIndex } = e.currentTarget.dataset;

      setParams({ type: MODAL_TYPES.CONTROL, controlIndex, partId: nodeId });
    },
    [nodeId, setParams]
  );

  return (
    <ListGroup flush>
      {controlsList.map((control, controlIndex) => (
        <ListGroupItem action key={control.name} data-control-index={controlIndex} onClick={editControl}>
          {control.name} <small>({control.list?.length || 0})</small>
        </ListGroupItem>
      ))}
      <ListGroupItem action onClick={addControl} color="dark">
        + Add Control
      </ListGroupItem>
    </ListGroup>
  );
};

PartControls.propTypes = {
  nodeId: PropTypes.string.isRequired
};

export default PartControls;
