import { all, call, takeLatest, put } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import mappedInstancesActions from '../mappedInstances/mappedInstancesActions';
import instancesActions from './instancesActions';

export function* doFetchInstancesSaga() {
  yield call(sagas.doBaseAsyncSaga, instancesActions.fetchInstances, api.instances.getList);
}

export function* watchFetchInstancesSaga() {
  yield takeLatest(
    [instancesActions.FETCH_INSTANCES, instancesActions.makeLeadPrimary.success.ACTION_TYPE],
    doFetchInstancesSaga
  );
}

export function* doMarkRequestAsReadSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.markRequestAsRead,
    api.instances.markRequestAsRead,
    payload.instanceId
  );
}

export function* watchMarkRequestAsReadSaga() {
  yield takeLatest(instancesActions.MARK_REQUEST_AS_READ, doMarkRequestAsReadSaga);
}

export function* doMarkRequestAsUnreadSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.markRequestAsUnread,
    api.instances.markRequestAsUnread,
    payload.instanceId
  );
}

export function* watchMarkRequestAsUnreadSaga() {
  yield takeLatest(instancesActions.MARK_REQUEST_AS_UNREAD, doMarkRequestAsUnreadSaga);
}

export function* doDeleteRequestSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, instancesActions.deleteRequest, api.instances.deleteRequest, payload.instanceId);
}

export function* watchDeleteRequestSaga() {
  yield takeLatest(instancesActions.DELETE_REQUEST, doDeleteRequestSaga);
}

export function* doMakeLeadPrimarySaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.makeLeadPrimary,
    api.instances.makeLeadPrimary,
    payload.instanceId
  );
}

export function* watchMakeLeadPrimarySaga() {
  yield takeLatest(instancesActions.MAKE_LEAD_PRIMARY, doMakeLeadPrimarySaga);
}

export function* doSendToLegalesignSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.sendToLegalesign,
    api.instances.sendToLegalesign,
    payload.instanceId
  );
}

export function* watchSendToLegalesignSaga() {
  yield takeLatest(instancesActions.SEND_TO_LEGALESIGN, doSendToLegalesignSaga);
}

export function* doCheckLegalesignStatusSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.checkLegalesignStatus,
    api.instances.checkLegalesignStatus,
    payload.instanceId
  );
}

export function* watchCheckLegalesignStatusSaga() {
  yield takeLatest(instancesActions.CHECK_LEGALESIGN_STATUS, doCheckLegalesignStatusSaga);
}

export function* doSendToProductionSaga({ payload }) {
  const { data } = yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.sendToProduction,
    api.instances.sendToProduction,
    payload.instanceId
  );

  if (data) {
    yield put(mappedInstancesActions.fetchMappedInstance(data.mappedInstanceId));
  }
}

export function* watchSendToProductionSaga() {
  yield takeLatest(instancesActions.SEND_TO_PRODUCTION, doSendToProductionSaga);
}

export function* doCancelOrderSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, instancesActions.cancelOrder, api.instances.cancelOrder, payload.instanceId);
}

export function* watchCancelOrderSaga() {
  yield takeLatest(instancesActions.CANCEL_ORDER, doCancelOrderSaga);
}

export function* doRunIntegrationSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, instancesActions.runIntegration, api.instances.runIntegration, payload.instanceId);
}

export function* watchRunIntegrationSaga() {
  yield takeLatest(instancesActions.RUN_INTEGRATION, doRunIntegrationSaga);
}

export function* doUpdateInstanceSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, instancesActions.updateInstance, api.instances.update, payload.instance);
}

export function* doUpdateInstanceDataSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    instancesActions.updateInstanceData,
    api.instances.updateData,
    payload.instanceId,
    payload.instance
  );
}

export function* watchUpdateInstanceSaga() {
  yield takeLatest(instancesActions.UPDATE_INSTANCE, doUpdateInstanceSaga);
}
export function* watchUpdateInstanceDataSaga() {
  yield takeLatest(instancesActions.UPDATE_INSTANCE_DATA, doUpdateInstanceDataSaga);
}

export function* doDeleteInstanceSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, instancesActions.deleteEntity, api.instances.delete, payload.id);
}

export function* watchDeleteInstanceSaga() {
  yield takeLatest(instancesActions.DELETE_INSTANCE, doDeleteInstanceSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchInstancesSaga(),
    watchMarkRequestAsReadSaga(),
    watchMarkRequestAsUnreadSaga(),
    watchDeleteRequestSaga(),
    watchMakeLeadPrimarySaga(),
    watchSendToLegalesignSaga(),
    watchCheckLegalesignStatusSaga(),
    watchSendToProductionSaga(),
    watchCancelOrderSaga(),
    watchRunIntegrationSaga(),
    watchUpdateInstanceSaga(),
    watchUpdateInstanceDataSaga(),
    watchDeleteInstanceSaga()
  ]);
}
