import { createSelector } from 'reselect';
import { api } from '../../store/services/api';

// TODO: deprecated
const selector = api.endpoints.getProjects.select();

export const selectProjects = state => selector(state)?.data?.entities || {};

export const selectProjectListForSuggestions = createSelector([selectProjects], projects =>
  Object.values(projects).map(project => ({ label: project.name, value: project._id }))
);
