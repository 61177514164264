import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from '../Atoms';
import { TERMS_OF_USE_LINK } from '../../config';

const TermsOfUseLink = ({ dropdown }) => {
  const Tag = dropdown ? DropdownItem : 'a';

  return (
    <Tag href={TERMS_OF_USE_LINK} rel="noopener noreferrer" target="_blank">
      Terms of Use
    </Tag>
  );
};

TermsOfUseLink.propTypes = {
  dropdown: PropTypes.bool
};

TermsOfUseLink.defaultProps = {
  dropdown: false
};

export default TermsOfUseLink;
