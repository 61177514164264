import * as Effects from 'redux-saga/effects';
import { ActionType } from 'types';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import partnersActions from './partnersActions';

const { all, call } = Effects;
const takeLatest: any = Effects.takeLatest; // eslint-disable-line prefer-destructuring

export function* doFetchPartnersSaga() {
  yield call(sagas.doBaseAsyncSaga, partnersActions.fetchPartners, api.partners.getList);
}

export function* watchFetchPartnersSaga() {
  yield takeLatest(partnersActions.FETCH_PARTNERS, doFetchPartnersSaga);
}

export function* doCreatePartnerSaga(action: ActionType) {
  yield call(sagas.doBaseAsyncSaga, partnersActions.createPartner, api.partners.create, action.payload.entity);
}

export function* watchCreatePartnerSaga() {
  yield takeLatest(partnersActions.CREATE_PARTNER, doCreatePartnerSaga);
}

export function* doUpdatePartnerSaga(action: ActionType) {
  yield call(sagas.doBaseAsyncSaga, partnersActions.updatePartner, api.partners.update, action.payload.entity);
}

export function* watchUpdatePartnerSaga() {
  yield takeLatest(partnersActions.UPDATE_PARTNER, doUpdatePartnerSaga);
}

export function* doDeletePartnerSaga(action: ActionType) {
  yield call(sagas.doBaseAsyncSaga, partnersActions.deletePartner, api.partners.delete, action.payload.id);
}

export function* watchDeletePartnerSaga() {
  yield takeLatest(partnersActions.DELETE_PARTNER, doDeletePartnerSaga);
}

export function* doCreateAPIKeySaga(action: ActionType) {
  yield call(
    sagas.doBaseAsyncSaga,
    partnersActions.createAPIKey,
    api.partners.createAPIKey,
    action.payload.partnerId,
    action.payload.remove
  );
}

export function* watchCreateAPIKeySaga() {
  yield takeLatest(partnersActions.CREATE_API_KEY, doCreateAPIKeySaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchPartnersSaga(),
    watchCreatePartnerSaga(),
    watchUpdatePartnerSaga(),
    watchDeletePartnerSaga(),
    watchCreateAPIKeySaga()
  ]);
}
