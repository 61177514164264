import { useGetLastUpdatedInstancesQuery } from './services/api';

export const useLastUpdatedInstances = () => {
  const { data, isLoading, isUninitialized } = useGetLastUpdatedInstancesQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};
