import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ApplicationRoute, getFactoryMapUrl } from '../../../utils/appRoutes';
import { factoryMapsSelectors } from '../../../modules/factoryMaps';
import BreadcrumbSection from '../BreadcrumbSection';
import BreadcrumbBackButton from '../BreadcrumbBackButton';

const FactoryMapsBreadcrumbs: FC = () => {
  const match = useRouteMatch<{ factoryId?: string }>();
  const isListPage = match.path === ApplicationRoute.FactoryMaps;
  const factoryMaps = useSelector(factoryMapsSelectors.selectFactoryMapsList);
  const list = useMemo(
    () =>
      factoryMaps.map(factoryMap => ({
        label: factoryMap.name,
        id: factoryMap._id,
        path: getFactoryMapUrl(factoryMap._id)
      })),
    [factoryMaps]
  );

  return (
    <>
      <BreadcrumbBackButton to={isListPage ? ApplicationRoute.Main : ApplicationRoute.FactoryMaps} />
      <BreadcrumbSection title="Factory maps" list={list} activeId={match.params.factoryId} />
    </>
  );
};

export default FactoryMapsBreadcrumbs;
