import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { partnersSelectors } from '../../../modules/partners';
import { ApplicationRoute, getPartnerUrl } from '../../../utils/appRoutes';
import BreadcrumbSection from '../BreadcrumbSection';
import BreadcrumbBackButton from '../BreadcrumbBackButton';

interface PartnersBreadcrumbsProps {
  hideBackButton?: boolean;
  generatePathUrl?: (id: string) => string;
}

const PartnersBreadcrumbs: FC<PartnersBreadcrumbsProps> = ({ hideBackButton, generatePathUrl = getPartnerUrl }) => {
  const {
    path,
    params: { partnerId }
  } = useRouteMatch<{ partnerId?: string }>();
  const isListPage = path === ApplicationRoute.Partners;
  const partners = useSelector(partnersSelectors.selectPartnerList);
  const list = useMemo(
    () =>
      partners.map(partner => ({
        label: partner.name,
        id: partner._id,
        path: generatePathUrl(partner._id)
      })),
    [partners, generatePathUrl]
  );

  return (
    <>
      {hideBackButton ? null : (
        <BreadcrumbBackButton to={isListPage ? ApplicationRoute.Main : ApplicationRoute.Partners} />
      )}
      <BreadcrumbSection title="Partners" list={list} activeId={partnerId} />
    </>
  );
};

export default PartnersBreadcrumbs;
