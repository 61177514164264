import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../Form';
import Field from '../Form/Field';
import { rolesHooks } from '../../modules/roles';
import { partnersHooks } from '../../modules/partners';
import { invitationsActions, invitationsSelectors } from '../../modules/invitations';
import { useProjectListForSelect } from '../../store/project';

const InvitationForm = ({ onSubmit, onClose, title, id }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(state => invitationsSelectors.selectInvitation(state, id)) || {};
  const created = useSelector(invitationsSelectors.selectInvitationCreatedFlag);
  const updated = useSelector(invitationsSelectors.selectInvitationUpdatedFlag);
  const projects = useProjectListForSelect();
  const { list: roles } = rolesHooks.useRoleListForSelect();
  const { list: partners } = partnersHooks.usePartnerListForSelect();

  const handleClose = useCallback(() => {
    dispatch(invitationsActions.resetFlags());

    onClose();
  }, [onClose, dispatch]);

  useEffect(() => {
    if (created || updated) {
      handleClose();
    }
  }, [handleClose, created, updated]);

  return (
    <Form onSubmit={onSubmit} inModal closeModal={handleClose} name={title} initialValues={initialValues} isModalOpen>
      <Field.Email required name="username" label="User email" disabled={initialValues.username} />
      <Field.Select options={partners} name="partner" label="Select partner" />
      <Field.MultiSelect options={roles} name="roles" label="Select roles" />
      <Field.MultiSelect options={projects} name="projectIds" label="Select projects" />
      <Field.Textarea name="message" label="Message" />
    </Form>
  );
};

InvitationForm.defaultProps = {
  id: null
};

InvitationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string
};

export default InvitationForm;
