import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Field from '../../Form/Field';
import Form from '../../Form';
import { TYPE_IFRAME_SCRIPT } from '../../../utils/propTypes';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import { iframeScriptsActions } from '../../../modules/iframeScripts';

const IframeScriptForm = ({ iframeScript }) => {
  const dispatch = useDispatch();

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line no-alert
    const response = window.confirm(
      `Are you sure that you want to delete this script "${iframeScript.version} (${iframeScript._id})"?`
    );

    if (response) {
      dispatch(iframeScriptsActions.deleteIframeScript(iframeScript._id));
    }
  }, [dispatch, iframeScript._id, iframeScript.version]);

  const handleSubmitForm = useCallback(
    value => {
      dispatch(iframeScriptsActions.updateIframeScript(value));
    },
    [dispatch]
  );

  const handleSetLatest = useCallback(() => {
    dispatch(iframeScriptsActions.setLatestIframeScript(iframeScript._id));
  }, [dispatch, iframeScript._id]);

  return (
    <Form
      initialValues={iframeScript}
      onSubmit={handleSubmitForm}
      customButton={
        <div className="pull-right">
          <ProtectedButton
            onClick={handleDelete}
            disabled={iframeScript.latest}
            color="danger"
            outline
            rights={[RIGHTS.IFRAME_SCRIPT__DELETE]}
          >
            <i className="fa fa-trash fa-fw" />
            Delete
          </ProtectedButton>
          <ProtectedButton
            onClick={handleSetLatest}
            disabled={iframeScript.latest}
            color="primary"
            rights={[RIGHTS.IFRAME_SCRIPT__SET_LATEST]}
          >
            Set latest
          </ProtectedButton>
        </div>
      }
    >
      <Field.Textarea name="description" label="Description" />
      <Field.Code required name="value" label="Value" mode="javascript" theme="xcode" />
    </Form>
  );
};

IframeScriptForm.propTypes = {
  iframeScript: TYPE_IFRAME_SCRIPT.isRequired
};

export default IframeScriptForm;
