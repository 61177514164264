import { actions } from '../@helpers';

const MODULE_NAME = 'SETTINGS';

const fetchGlobalSettingsType = actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_GLOBAL_SETTINGS');
const updateGlobalSettingsType = actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_GLOBAL_SETTINGS');

const fetchGlobalThemeType = actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_GLOBAL_THEME');
const updateGlobalThemeType = actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_GLOBAL_THEME');

const fetchGlobalSettings = actions.createAsyncActionCreator(fetchGlobalSettingsType);
const updateGlobalSettings = actions.createAsyncActionCreator(updateGlobalSettingsType, {
  base: type => settings => ({ type, payload: { settings } })
});

const fetchGlobalTheme = actions.createAsyncActionCreator(fetchGlobalThemeType);
const updateGlobalTheme = actions.createAsyncActionCreator(updateGlobalThemeType, {
  base: type => theme => ({ type, payload: { theme } })
});

const PARSE_GLOBAL_SETTINGS = actions.createModulePrefixForActionType(MODULE_NAME, 'PARSE_GLOBAL_SETTINGS');
const PARSE_GLOBAL_SETTINGS__SUCCESS = actions.createSuccessActionType(PARSE_GLOBAL_SETTINGS);
const PARSE_GLOBAL_SETTINGS__FAILURE = actions.createFailureActionType(PARSE_GLOBAL_SETTINGS);

const PARSE_GLOBAL_THEME = actions.createModulePrefixForActionType(MODULE_NAME, 'PARSE_GLOBAL_THEME');
const PARSE_GLOBAL_THEME__SUCCESS = actions.createSuccessActionType(PARSE_GLOBAL_THEME);
const PARSE_GLOBAL_THEME__FAILURE = actions.createFailureActionType(PARSE_GLOBAL_THEME);

const parseSuccess = type => (raw, parsed) => ({ type, payload: { raw, parsed } });
const parseFailure = type => error => ({ type, payload: { error } });

const settingsActions = {
  fetchGlobalSettings,
  updateGlobalSettings,
  fetchGlobalTheme,
  updateGlobalTheme,

  PARSE_GLOBAL_SETTINGS__SUCCESS,
  PARSE_GLOBAL_THEME__SUCCESS,
  PARSE_GLOBAL_SETTINGS__FAILURE,
  PARSE_GLOBAL_THEME__FAILURE,

  parseGlobalSettingsSuccess: parseSuccess(PARSE_GLOBAL_SETTINGS__SUCCESS),
  parseGlobalThemeSuccess: parseSuccess(PARSE_GLOBAL_THEME__SUCCESS),
  parseGlobalSettingsFailure: parseFailure(PARSE_GLOBAL_SETTINGS__FAILURE),
  parseGlobalThemeFailure: parseFailure(PARSE_GLOBAL_THEME__FAILURE)
};

export default settingsActions;
