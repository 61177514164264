import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { FormContext } from '../../FormContext';
import FormSection from '../../Section';
import { ArrayField } from '../../types';
import ArrayRemoveButton from './ArrayRemoveButton';
import ArraySortableItemWrapper from './ArraySortableItemWrapper';
import ArrayContext from './ArrayContext';

interface ArrayItemProps<T> {
  item: ArrayField<T>;
  title: string;
  index: number;
  twoColumns?: boolean;
  singleComponentMode?: boolean;
  useShadowValue?: boolean;
  noWrapper?: boolean;
  noLabel?: boolean;
  level?: number;
  onToggle?: () => void;
}

const ArrayItem = <T,>({
  item,
  title,
  index,
  children,
  twoColumns,
  singleComponentMode,
  useShadowValue,
  noWrapper,
  noLabel,
  level,
  onToggle
}: // eslint-disable-next-line sonarjs/cognitive-complexity
PropsWithChildren<ArrayItemProps<T>>) => {
  const { isSortable, isStatic, arrayName } = useContext(ArrayContext);

  const transformedChildren = useMemo(
    () =>
      React.Children.map(children, (child: any) => {
        let resultingName;

        if (child.props.isFullName) {
          resultingName = `${child.props.name}.${index}`;
        } else if (child.props.internalName && singleComponentMode) {
          resultingName = `${child.props.name}.${index}.${child.props.internalName}`;
        } else {
          resultingName = singleComponentMode
            ? `${child.props.name}.${index}`
            : `${arrayName}.${index}.${child.props.name}`;
        }

        let resultUseShadow = useShadowValue;

        if (child.props.useShadowValue !== undefined) {
          resultUseShadow = child.props.useShadowValue;
        }

        return child?.props?.name
          ? React.createElement(child.type, {
              ...child.props,
              key: child.props.name,
              name: resultingName,
              useShadowValue: resultUseShadow,
              index,
              noLabel,
              level
            })
          : React.createElement(child.type, { ...child.props, index, useShadowValue });
      }),
    [arrayName, children, index, singleComponentMode, useShadowValue, noLabel, level]
  );

  const { readOnly: contextReadOnly } = useContext(FormContext);

  const RemoveButton = useMemo(
    () =>
      isStatic || contextReadOnly ? null : (
        <ArrayRemoveButton name={arrayName} index={index} labelOffset={!noLabel && noWrapper} />
      ),
    [isStatic, index, contextReadOnly, noLabel, noWrapper, arrayName]
  );

  const getArrayItem = (listeners?: DraggableSyntheticListeners) => {
    if (singleComponentMode) {
      return (
        <div className="d-flex">
          <div className="flex-grow-1">{transformedChildren}</div>
          <div className="mt-2">{RemoveButton}</div>
        </div>
      );
    }

    if (noWrapper) {
      return (
        <div className="d-flex">
          <div className="d-flex flex-grow-1">
            {transformedChildren} {RemoveButton}
          </div>
        </div>
      );
    }

    return (
      <FormSection onToggle={onToggle} title={title} headerButton={RemoveButton} dragHandleListeners={listeners}>
        {twoColumns ? (
          <Container>
            <Row>
              <Col xs="12" md="6">
                {transformedChildren?.filter(child => child.props.column === 'left')}
              </Col>
              <Col xs="12" md="6">
                {transformedChildren?.filter(child => child.props.column === 'right')}
              </Col>
            </Row>
          </Container>
        ) : (
          <>{transformedChildren}</>
        )}
      </FormSection>
    );
  };

  return isSortable ? (
    <ArraySortableItemWrapper id={item.id}>{listeners => getArrayItem(listeners)}</ArraySortableItemWrapper>
  ) : (
    getArrayItem()
  );
};

export default ArrayItem;
