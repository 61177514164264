import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../../../Atoms';
import FieldWrapper from '../FieldWrapper';
import FormContext from '../../FormContext';
import PreviewFields from './PreviewFields';
import ArrayFields from './ArrayFields';

const subscription = { submitting: true, pristine: true, invalid: true, error: true, value: true };

const uniqueValidation = (values, uniqueField) => {
  const map = {};

  for (let i = 0; i < values.length; i += 1) {
    const value = values[i][uniqueField];

    if (map[value]) {
      return (
        <Alert color="warning" className="my-2">
          This list requires field <strong>{uniqueField}</strong> to be unique. Please change the values
        </Alert>
      );
    }

    map[value] = true;
  }

  return undefined;
};

const makeValidate = (values = [], uniqueField, validate) => {
  if (validate) {
    return validate(values, uniqueField, uniqueValidation);
  }

  return uniqueField ? uniqueValidation(values, uniqueField) : uniqueField;
};

const ArrayObject = props => {
  const {
    name,
    headingIndex,
    children,
    isStatic,
    lockMoving,
    staticEmptyAlert,
    isOpen,
    uniqueField,
    defaultNew,
    headingFormat,
    validate
  } = props;

  const validateFunc = useCallback(values => makeValidate(values, uniqueField, validate), [uniqueField, validate]);

  return (
    <FieldWrapper complex {...props}>
      <FormContext.Consumer>
        {({ preview }) => {
          const Fields = preview ? PreviewFields : ArrayFields;

          return (
            <Fields
              name={name}
              subscription={subscription}
              validate={validateFunc}
              defaultNew={defaultNew}
              isStatic={isStatic}
              lockMoving={lockMoving}
              staticEmptyAlert={staticEmptyAlert}
              headingIndex={headingIndex}
              headingFormat={headingFormat}
              isOpen={isOpen}
            >
              {children}
            </Fields>
          );
        }}
      </FormContext.Consumer>
    </FieldWrapper>
  );
};

ArrayObject.propTypes = {
  name: PropTypes.string.isRequired,
  headingIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
  isStatic: PropTypes.bool, // cannot delete, add or rearrange children,
  lockMoving: PropTypes.bool, // cannot rearrange children
  staticEmptyAlert: PropTypes.string, // alert to show if field is static and empty
  isOpen: PropTypes.bool, // is group open by default
  uniqueField: PropTypes.string, // if defined, will validate that children will keep unique values for that field,
  defaultNew: PropTypes.shape({}), // if defined, will add element with this data when clicking on +Add button
  headingFormat: PropTypes.func,
  validate: PropTypes.func
};

ArrayObject.defaultProps = {
  headingIndex: 0,
  isStatic: false,
  lockMoving: false,
  isOpen: false,
  uniqueField: '',
  defaultNew: {},
  staticEmptyAlert: '',
  headingFormat: null,
  validate: null
};

export default ArrayObject;
