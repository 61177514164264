import type { Modifier, ModifierArguments } from '@popperjs/core';

const maxHeightDropdownModifier: Modifier<string, any> = {
  name: 'setMaxHeight',
  enabled: true,
  phase: 'beforeWrite',
  fn({ state }: ModifierArguments<any>) {
    state.styles.popper.overflow = 'auto';
    state.styles.popper.maxHeight = `250px`;
  }
};

export default maxHeightDropdownModifier;
