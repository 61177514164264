import React from 'react';
import { TYPE_MAPPED_INSTANCE_LINES } from '../../../../../utils/propTypes';

const MappedInstanceCode = ({ lines }) => (
  <pre className="border rounded bg-light p-3">
    <code>{lines.map(line => `${line.key}, ${line.value}\n`)}</code>
  </pre>
);

MappedInstanceCode.defaultProps = {
  lines: []
};

MappedInstanceCode.propTypes = {
  lines: TYPE_MAPPED_INSTANCE_LINES
};

export default MappedInstanceCode;
