import React from 'react';
import Code from '../Code';

const parse = value => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return undefined;
  }
};

const format = value => JSON.stringify(value, undefined, 2);
const Json = props => <Code {...props} mode="json" format={format} parse={parse} theme="github" />;

export default Json;
