import { createSelector } from 'reselect';

export const selectPlots = state => state.plots.list;
export const selectPlot = (state, id) => state.plots.list[id];
export const selectPlotsRequest = state => state.plots.request;
export const selectPlotCreatedFlag = state => state.plots.created;
export const selectPlotDeletedFlag = state => state.plots.deleted;

export const selectPlotList = createSelector([selectPlots], plots => Object.values(plots));

export const selectPlotDevelopmentList = createSelector([selectPlotList], plots =>
  [...new Set(plots.map(plot => plot.development))]
    .filter(Boolean)
    .sort()
    .map(development => ({ label: development, value: development }))
);

export const selectPlotStatusList = createSelector([selectPlotList], plots =>
  [...new Set(plots.map(plot => plot.status))]
    .filter(Boolean)
    .sort()
    .map(status => ({ label: status, value: status }))
);
