import { all, call, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import iframeScriptsActions from './iframeScriptsActions';

export function* doSetLatestIframeScriptSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    iframeScriptsActions.setLatestIframeScript,
    api.iframeScripts.setLatest,
    payload.id
  );
}

export function* watchSetLatestIframeScriptSaga() {
  yield takeLatest(iframeScriptsActions.SET_LATEST_IFRAME_SCRIPT, doSetLatestIframeScriptSaga);
}
export function* doCreateIframeScriptSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, iframeScriptsActions.createIframeScript, api.iframeScripts.create, payload.entity);
}

export function* watchCreateIframeScriptSaga() {
  yield takeLatest(iframeScriptsActions.CREATE_IFRAME_SCRIPT, doCreateIframeScriptSaga);
}

export function* doUpdateIframeScriptSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, iframeScriptsActions.updateIframeScript, api.iframeScripts.update, payload.entity);
}

export function* watchUpdateIframeScriptSaga() {
  yield takeLatest(iframeScriptsActions.UPDATE_IFRAME_SCRIPT, doUpdateIframeScriptSaga);
}

export function* doDeleteIframeScriptSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, iframeScriptsActions.deleteIframeScript, api.iframeScripts.delete, payload.id);
}

export function* watchDeleteIframeScriptSaga() {
  yield takeLatest(iframeScriptsActions.DELETE_IFRAME_SCRIPT, doDeleteIframeScriptSaga);
}

export function* doFetchIframeScriptsSaga() {
  yield call(sagas.doBaseAsyncSaga, iframeScriptsActions.fetchIframeScripts, api.iframeScripts.getList);
}

export function* watchFetchIframeScriptsSaga() {
  yield takeLatest(iframeScriptsActions.FETCH_IFRAME_SCRIPTS, doFetchIframeScriptsSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchIframeScriptsSaga(),
    watchCreateIframeScriptSaga(),
    watchUpdateIframeScriptSaga(),
    watchDeleteIframeScriptSaga(),
    watchSetLatestIframeScriptSaga()
  ]);
}
