import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SeedType, PriceScheme, SeedPriceType } from 'types';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Field as FFField } from 'react-final-form';
import { Column } from 'react-table';
import { Button, Table } from '../../../Atoms';
import Field from '../../../Form/Field';
import { partsSelectors } from '../../../../modules/parts';
import { RIGHTS } from '../../../../utils/rights';
import { useUserRights } from '../../../../utils/hooks';
import RawEditor from '../../../RawEditor';

interface FieldSwitchProps {
  pool?: any[];
  input: {
    value: number;
  };
}

const FieldSwitch = ({ pool = [], input }: FieldSwitchProps) => {
  const { value = 0 } = input;

  return pool[Number(value)] || null;
};

export interface PriceListProps extends FieldArrayRenderProps<any, any> {
  seed: SeedType;
  isMultipleSchemes: boolean;
  onRawSubmit: (value: { prices: SeedPriceType[] }) => void;
}

const PriceList = (props: PriceListProps) => {
  const { seed, fields, isMultipleSchemes, onRawSubmit } = props;

  const getHasRights = useUserRights();

  const hasUpdateRights = getHasRights([RIGHTS.SEEDS__UPDATE]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const schemes: PriceScheme[] = useSelector(state => partsSelectors.selectAllPriceSchemes(state, seed._id));

  const data = fields.map(field => ({ name: field }));

  const handleAdd = useCallback(() => {
    const newValues = schemes.reduce((result: Record<string, number>, scheme) => {
      // eslint-disable-next-line no-param-reassign
      result[scheme.name] = 0;

      return result;
    }, {});

    fields.push({ code: `code#${fields.length}`, schemes: newValues });
  }, [fields, schemes]);

  const handleRemove = useCallback(
    e => {
      const { index } = e.currentTarget.dataset;

      fields.remove(Number(index));
    },
    [fields]
  );

  const columns: Column[] = useMemo(() => {
    const result: Column[] = [
      {
        accessor: 'name',
        Header: 'Code',
        disableFilters: true,
        disableSortBy: true,
        Cell: (table: any) =>
          hasUpdateRights ? (
            <FFField
              name={`${table.value}.used`}
              component={FieldSwitch}
              pool={[
                <Field.Text required noLabel name={`${table.value}.code`} />,
                <Field.Immutable noLabel name={`${table.value}.code`} />
              ]}
            />
          ) : (
            <Field.Immutable noLabel name={`${table.value}.code`} />
          )
      }
    ];

    const singleSchemeFilter = (scheme: PriceScheme) => {
      if (!isMultipleSchemes) {
        return scheme.name === 'default';
      }

      return scheme.name !== 'default';
    };

    const filteredSchemes = schemes.filter(singleSchemeFilter);

    result.push(
      ...(filteredSchemes.length
        ? filteredSchemes
        : [{ name: 'default', displayName: 'default', uniqueName: 'default' }]
      ).map((scheme: PriceScheme) => ({
        id: scheme.name,
        disableFilters: true,
        disableSortBy: true,
        Header: scheme.uniqueName || scheme.name,
        Cell: (table: any) => (
          <Field.Number allowEmpty name={`${table.row.original.name}.schemes.${scheme.name}`} noLabel />
        )
      }))
    );

    if (hasUpdateRights) {
      result.push({
        id: 'remove',
        width: 50,
        // resizable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: (table: any) => (
          <FFField
            name={`${table.row.original.name}.used`}
            component={FieldSwitch}
            pool={[
              <Button color="danger" outline onClick={handleRemove} size="sm" data-index={table.row.index}>
                <i className="fa fa-trash fa-fw" title="Remove" />
              </Button>,
              <Button size="sm" disabled title="Price code in use">
                <i className="fa fa-fw fa-lock" />
              </Button>
            ]}
          />
        )
      });
    }

    return result;
  }, [handleRemove, hasUpdateRights, schemes, isMultipleSchemes]);

  const handleRawSubmit = useCallback(
    ({ json: prices }: { json: SeedPriceType[] }) => {
      onRawSubmit({ prices });
    },
    [onRawSubmit]
  );

  const initialValue = useMemo(() => ({ json: seed.prices || [] }), [seed.prices]);

  return (
    <div className="mb-2">
      <RawEditor rights={[RIGHTS.SUPERUSER]} onSubmit={handleRawSubmit} initial={initialValue} header="Edit Raw" />
      <Table columns={columns} data={data} />
      {hasUpdateRights ? (
        <Button color="primary" outline onClick={handleAdd} className="my-1">
          Add
        </Button>
      ) : null}
    </div>
  );
};

export default PriceList;
