import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import { Field, Form as RFForm, FormSpy } from 'react-final-form';
import Select from 'react-select';
import { Button, Col, RSForm, FormFeedback, FormGroup, Label, Row } from '../../Atoms';
import { TYPE_PLOT, TYPE_SUGGESTION_LIST } from '../../../utils/propTypes';
import { projectsSelectors } from '../../../modules/projects';
import FormField from '../../FormField';
import { RIGHTS } from '../../../utils/rights';
import ProtectedButton from '../../ProtectedButton';

import './PlotForm.scss';

const plotForm = b.with('plot-form');

const FIELDS = [
  { key: 'name', label: 'Name', type: 'text', required: true },
  { key: 'plotType', label: 'Plot Type', type: 'text', required: true },
  { key: 'code', label: 'Code', type: 'text', required: true },
  { key: 'development', label: 'Development', type: 'text' },
  { key: 'price', label: 'Price', type: 'text' },
  { key: 'cutOffDate', label: 'CutOffDate', type: 'text' },
  { key: 'description', label: 'Description', type: 'text' }
];

const STATUSES = ['Not released', 'Released', 'Reserved', 'Sold'];
const SUGGESTED_STATUSES = STATUSES.map(status => ({ value: status, label: status }));

const ERRORS = {
  required: 'Required',
  incorrect: 'Incorrect value'
};

export class PlotForm extends React.PureComponent {
  onDelete = () => {
    const { plot, onDelete } = this.props;
    const response = window.confirm(`Are you sure that you want to delete plot "${plot.name}"?`); // eslint-disable-line no-alert

    if (response) {
      onDelete(plot._id);
    }
  };

  selectTheme = (theme, meta) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral20: meta.valid ? theme.colors.neutral20 : '#dc3545'
    }
  });

  validateForm = values => {
    const errors = {};

    FIELDS.forEach(({ required, key }) => {
      if (required && !values[key]) {
        errors[key] = ERRORS.required;
      }
    });

    if (!values.projectId) {
      errors.projectId = ERRORS.required;
    }

    if (!STATUSES.includes(values.status)) {
      errors.status = ERRORS.incorrect;
    }

    if (!values.status) {
      errors.status = ERRORS.required;
    }

    return errors;
  };

  renderForm = ({ handleSubmit, form, submitting, pristine }) => {
    const { isNew, onChange } = this.props;

    return (
      <RSForm className={plotForm()} onSubmit={handleSubmit} autoComplete="off">
        <div className={`${plotForm('fields')}`}>
          <Row>
            {FIELDS.map(field => (
              <Col lg={6} key={field.key}>
                <FormField field={field} size="sm" />
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Field
                  name="status"
                  render={({ input, meta }) => {
                    return (
                      <>
                        <Select
                          name="status"
                          className={plotForm('status', { invalid: !meta.valid })}
                          value={{ value: input.value, label: input.value }}
                          onChange={value => input.onChange(value.value)}
                          theme={theme => this.selectTheme(theme, meta)}
                          options={SUGGESTED_STATUSES}
                        />
                        <FormFeedback>{meta.error}</FormFeedback>
                      </>
                    );
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <Label for="projectId">Project</Label>
                <Field
                  name="projectId"
                  render={({ input, meta }) => (
                    <>
                      <Select
                        name="projectId"
                        className={plotForm('project', { invalid: !meta.valid })}
                        theme={theme => this.selectTheme(theme, meta)}
                        value={this.props.projects.filter(({ value }) => input.value === value)}
                        onChange={newOption => input.onChange(newOption.value)}
                        options={this.props.projects}
                      />
                      <FormFeedback>{meta.error}</FormFeedback>
                    </>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className={`${plotForm('buttons', { hidden: isNew })} mb-2`}>
          <Button
            type="submit"
            color="primary"
            disabled={submitting || pristine || !form.getState || form.getState().invalid}
            outline
            size="sm"
          >
            Save
          </Button>
          <Button onClick={form.reset} disabled={submitting || pristine} outline size="sm">
            Reset
          </Button>
          <ProtectedButton rights={[RIGHTS.PLOTS__DELETE]} color="danger" onClick={this.onDelete} outline size="sm">
            Delete
          </ProtectedButton>
        </div>
        <FormSpy subscription={{ values: true, invalid: true, pristine: true }} onChange={onChange} />
      </RSForm>
    );
  };

  render() {
    const { plot, onSubmit } = this.props;

    return <RFForm onSubmit={onSubmit} initialValues={plot} render={this.renderForm} validate={this.validateForm} />;
  }
}

PlotForm.defaultProps = {
  projects: [],
  isNew: false,
  onSubmit: () => null,
  onChange: () => null,
  onDelete: () => null
};

PlotForm.propTypes = {
  plot: TYPE_PLOT, // eslint-disable-line react/require-default-props
  projects: TYPE_SUGGESTION_LIST,
  isNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  projects: projectsSelectors.selectProjectListForSuggestions(state)
});

export default connect(mapStateToProps, null)(PlotForm);
