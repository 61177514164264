import { all, call, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import plotsActions from './plotsActions';

export function* doFetchPlotsSaga() {
  yield call(sagas.doBaseAsyncSaga, plotsActions.fetchPlots, api.plots.getList);
}

export function* watchFetchPlotsSaga() {
  yield takeLatest(plotsActions.FETCH_PLOTS, doFetchPlotsSaga);
}

export function* doCreatePlotSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, plotsActions.createPlot, api.plots.create, payload.entity);
}

export function* watchCreatePlotSaga() {
  yield takeLatest(plotsActions.CREATE_PLOT, doCreatePlotSaga);
}

export function* doUpdatePlotSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, plotsActions.updatePlot, api.plots.update, payload.entity);
}

export function* watchUpdatePlotSaga() {
  yield takeLatest(plotsActions.UPDATE_PLOT, doUpdatePlotSaga);
}

export function* doDeletePlotSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, plotsActions.deletePlot, api.plots.delete, payload.id);
}

export function* watchDeletePlotSaga() {
  yield takeLatest(plotsActions.DELETE_PLOT, doDeletePlotSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchPlotsSaga(), watchCreatePlotSaga(), watchUpdatePlotSaga(), watchDeletePlotSaga()]);
}
