import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import b from 'b_';
import { Button } from '../Atoms';
import { authSelectors } from '../../modules/auth';

import './OpenConfiguratorLink.scss';

const openConfiguratorLink = b.with('open-configurator-link');

// TODO: DEPRECATED

const OpenConfiguratorLink = ({ type, link, caption, icon, color, size }) => {
  const configuratorUrl = useSelector(authSelectors.selectUserConfiguratorUrl);

  return (
    <Button
      className={openConfiguratorLink({ type: color })}
      tag="a"
      color={color === 'inline' ? 'link' : color}
      size={size}
      href={`${configuratorUrl}/${type}/${link}`}
      target="_blank"
      rel="noopener noreferrer"
      title="Open configurator"
    >
      {caption} {icon ? <i className={`fa fa-fw ${icon}`} /> : null}
    </Button>
  );
};

OpenConfiguratorLink.defaultProps = {
  caption: 'Open',
  icon: 'fa-external-link',
  color: 'inline',
  size: 'md'
};

OpenConfiguratorLink.propTypes = {
  type: PropTypes.oneOf(['id', 'seed', 'project']).isRequired,
  link: PropTypes.string.isRequired,
  caption: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string
};

export default OpenConfiguratorLink;
