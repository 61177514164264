import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../Atoms';
import { partsActions, partsSelectors } from '../../../modules/parts';
import { useRootPartBySeedId } from '../../../utils/hooks';
import getPartFieldsToUpdate from '../../../utils/getPartFieldsToUpdate';
import LightForm, { LIGHT_PATHS, LIGHTS, LIGHTS_LIST } from './LightForm';

const NEW_LIGHTS = {
  [LIGHTS.SUNLIGHT]: {
    altitude: 35,
    azimuth: 150,
    color: 'rgb(255,255,255)',
    intensity: 0.4,
    castShadow: true,
    name: `new_sunlight`,
    disabled: false
  },
  [LIGHTS.SKYLIGHT]: { name: `new_skylight`, color: 'rgb(170,170,170)', intensity: 1 },
  [LIGHTS.CUSTOM]: { name: `new_light`, type: 'point', castShadow: false }
};

const useLights = (type, seedId) => {
  const dispatch = useDispatch();
  const rootPart = useRootPartBySeedId(seedId);
  const path = LIGHT_PATHS[type];

  const lights = useSelector(state => {
    if (type === LIGHTS.SUNLIGHT) {
      return partsSelectors.selectSunlightsList(state, seedId);
    }

    if (type === LIGHTS.SKYLIGHT) {
      return partsSelectors.selectSkylightsList(state, seedId);
    }

    return partsSelectors.selectCustomLightsList(state, seedId);
  });

  const handleAddLight = useCallback(() => {
    const light = NEW_LIGHTS[type];

    if (!rootPart) {
      return;
    }

    const newPart = { ...rootPart };

    newPart.Lights = { ...(newPart.Lights || {}) };
    newPart.Lights[path] = [...(newPart.Lights[path] || [])];
    newPart.Lights[path].push({ ...light, name: `${light.name}_${newPart.Lights[path].length}` });

    dispatch(partsActions.updateParts(seedId, [getPartFieldsToUpdate(newPart, ['Lights'])]));
  }, [dispatch, path, rootPart, seedId, type]);

  return { lights, handleAddLight, rootPart };
};

const LightList = ({ type, seedId }) => {
  const { lights, rootPart, handleAddLight } = useLights(type, seedId);

  return (
    <>
      {lights.map(light => (
        <LightForm seedId={seedId} light={light} type={type} key={light.name} />
      ))}
      {rootPart ? (
        <Button onClick={handleAddLight} color="primary" outline>
          <i className="fa fa-fw fa-plus" /> Add light (by default to root part)
        </Button>
      ) : null}
    </>
  );
};

LightList.propTypes = {
  type: PropTypes.oneOf(LIGHTS_LIST).isRequired,
  seedId: PropTypes.string.isRequired
};

export default LightList;
