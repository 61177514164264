import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as partnersSelectors from './partnersSelectors';
import partnersActions from './partnersActions';

export const usePartnerListForSelect = () => {
  const dispatch = useDispatch();
  const list = useSelector(partnersSelectors.selectPartnerListForSuggestions);

  useEffect(() => {
    if (list.length === 0) {
      dispatch(partnersActions.fetchPartners());
    }
  }, [dispatch, list.length]);

  return { list };
};

export const usePartners = (forceFetch = false) => {
  const dispatch = useDispatch();
  const list = useSelector(partnersSelectors.selectPartnerList);
  const request = useSelector(partnersSelectors.selectPartnersRequest);

  useEffect(() => {
    if (list.length === 0 || forceFetch) {
      dispatch(partnersActions.fetchPartners());
    }
  }, [dispatch, list.length, forceFetch]);

  return { list, request };
};
