import { actions } from '../@helpers';

const MODULE_NAME = 'STATISTICS';

const FETCH_USER_STATISTICS = actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_USER_STATISTICS');

const fetchUserStatistics = actions.createAsyncActionCreator(FETCH_USER_STATISTICS, {
  base: type => userId => ({ type, payload: { userId } })
});

const statisticsActions = {
  fetchUserStatistics,

  FETCH_USER_STATISTICS
};

export default statisticsActions;
