import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as factoryMapsSelectors from './factoryMapsSelectors';
import factoryMapsActions from './factoryMapsActions';

export const useFactoryMapsList = () => {
  const dispatch = useDispatch();
  const request = useSelector(factoryMapsSelectors.selectFactoryMapsRequest);
  const list = useSelector(factoryMapsSelectors.selectFactoryMapsList);

  useEffect(() => {
    dispatch(factoryMapsActions.fetchFactoryMaps());
  }, [dispatch]);

  return { request, list };
};

export const useFactoryMap = factoryId => useSelector(factoryMapsSelectors.selectFactoryMap(factoryId));

export const useFetchFactoryMap = factoryId => {
  const dispatch = useDispatch();
  const factoryMap = useFactoryMap(factoryId);
  const request = useSelector(factoryMapsSelectors.selectFactoryMapsRequest);

  useEffect(() => {
    if (!factoryMap) {
      dispatch(factoryMapsActions.fetchFactoryMaps());
    }
  }, [dispatch, factoryMap]);

  return { factoryMap, request };
};

export const useFetchFactoryMapFlags = () => {
  const dispatch = useDispatch();
  const created = useSelector(factoryMapsSelectors.selectFactoryMapCreatedFlag);
  const updated = useSelector(factoryMapsSelectors.selectFactoryMapUpdatedFlag);
  const deleted = useSelector(factoryMapsSelectors.selectFactoryMapDeletedFlag);

  const reset = useCallback(() => {
    dispatch(factoryMapsActions.resetFlags());
  }, [dispatch]);

  return { created, updated, deleted, reset };
};
