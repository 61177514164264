import { useCallback, useMemo } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { ProjectType } from 'types';
import { Container, Icon, Box } from '../Atoms';
import { useLastUpdatedProjects } from '../../store/project';
import { useLastUpdatedInstances } from '../../store/instance';
import { usePartnersList } from '../../store/partners';
import { useUsersList } from '../../store/users';
import { useRolesList } from '../../store/roles';
import { useInvitationsList } from '../../store/invitations';
import { useUserRights } from '../../utils/hooks';
import { ApplicationRoute, getProjectUrl } from '../../utils/appRoutes';
import { formatDaysAgo } from '../../utils/helpers/time';
import { PROTECTED_ROUTES, ProtectedAppRoute } from '../RouteList';
import DashHeader from './DashHeader';

const Dashboard = () => {
  const hasRights = useUserRights();

  const filteredRoutes = useMemo(
    () =>
      PROTECTED_ROUTES.map(group => ({
        ...group,
        links: group.links.filter(link => hasRights(link.rights) && link.name)
      })).filter(group => group.links.length !== 0 && !!group.name),
    [hasRights]
  );

  const { data: lastUpdatedProjects } = useLastUpdatedProjects();
  const { data: lastUpdatedInstances } = useLastUpdatedInstances();
  const { partners: partnersList } = usePartnersList();
  const { users: usersList } = useUsersList();
  const { roles: rolesList } = useRolesList();
  const { invitations: invitationsList } = useInvitationsList();

  const getProjectUpdatedAt = ({ updatedAt, populated }: ProjectType) => {
    const projectUpdatedAt = new Date(updatedAt);

    if (!populated.publicSeedUpdatedAt) {
      return projectUpdatedAt;
    }

    const publicSeedUpdatedAt = new Date(populated.publicSeedUpdatedAt);

    return publicSeedUpdatedAt > projectUpdatedAt ? publicSeedUpdatedAt : projectUpdatedAt;
  };

  const lastUpdatedProjectsList = useCallback(
    (link?: ProtectedAppRoute, color?: string) => {
      return !link ? null : (
        <div className="border-bottom">
          {lastUpdatedProjects?.map(project => (
            <div key={project._id} className="d-flex justify-content-between align-items-baseline">
              {link.icon && (
                <div style={{ color }}>
                  <Icon type={link.icon} scale={0.7} />
                </div>
              )}
              <div title={project.name} className="flex-fill overflow-hidden text-nowrap text-truncate">
                <RRNavLink style={{ color }} to={getProjectUrl(project._id)} exact>
                  {project.name}
                </RRNavLink>
              </div>
              <small className="text-right text-nowrap text-muted ms-1 flex-shrink-0">
                {formatDaysAgo(new Date(getProjectUpdatedAt(project)))}
              </small>
            </div>
          ))}
        </div>
      );
    },
    [lastUpdatedProjects]
  );

  const lastUpdatedInstancesBlock = useCallback(
    (type: 'designs' | 'requests', link?: ProtectedAppRoute, color?: string) => {
      const data = lastUpdatedInstances?.[type];

      const list = [
        [data?.today, 'today'],
        [data?.lastMonth, 'month'],
        [data?.all, 'total']
      ];

      return (
        <div className="d-flex justify-content-between border-bottom">
          {list.map(([value, label]) => (
            <div key={label} className="text-center">
              <div className="font-weight-bold" style={{ color }}>
                {value}
              </div>
              <small className="text-muted">{label}</small>
            </div>
          ))}
        </div>
      );
    },
    [lastUpdatedInstances]
  );

  const totalItemsBlock = useCallback((list, color, totalStr) => {
    return (
      <div className="ms-3 me-3 border-bottom text-center">
        <div className="font-weight-bold" style={{ color }}>
          {(list || []).length}
        </div>
        <small className="text-muted">{totalStr}</small>
      </div>
    );
  }, []);

  const additionalBlocksMap = (route: ApplicationRoute) => {
    switch (route) {
      case ApplicationRoute.ProjectList:
        return (link: ProtectedAppRoute, color?: string) => lastUpdatedProjectsList(link, color);
      case ApplicationRoute.Instances:
        return (link: ProtectedAppRoute, color?: string) => lastUpdatedInstancesBlock('designs', link, color);
      case ApplicationRoute.Requests:
        return (link: ProtectedAppRoute, color?: string) => lastUpdatedInstancesBlock('requests', link, color);
      case ApplicationRoute.Partners:
        return (link: ProtectedAppRoute, color?: string) => totalItemsBlock(partnersList, color, 'total');
      case ApplicationRoute.Users:
        return (link: ProtectedAppRoute, color?: string) => totalItemsBlock(usersList, color, 'total');
      case ApplicationRoute.Roles:
        return (link: ProtectedAppRoute, color?: string) => totalItemsBlock(rolesList, color, 'total');
      case ApplicationRoute.Invitations:
        return (link: ProtectedAppRoute, color?: string) =>
          totalItemsBlock(
            invitationsList?.filter(invitation => invitation.status !== 'registered'),
            color,
            'pending'
          );
    }

    return () => null;
  };

  return (
    <div className="bg-light py-3 flex-grow-1">
      <Container type="lg">
        <div className="bg-white">
          <DashHeader />
          <Nav tag="nav" vertical>
            {filteredRoutes.map((group, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className="mt-3 mb-1">
                <h3 className="ms-3">{group.name}</h3>

                <div className="d-flex flex-wrap px-2 pb-2 gap-3 ms-2">
                  {group.links
                    .filter(link => hasRights(link.rights))
                    .map(link => (
                      <Box
                        color={group.color}
                        icon={link.icon}
                        title={link.name}
                        description={link.description}
                        link={link.path}
                      >
                        {additionalBlocksMap(link.path)(link, group.color)}
                      </Box>
                    ))}
                </div>
              </div>
            ))}
          </Nav>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
