import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import { TYPE_ASSET_LIST, TYPE_PART_CONTROLS, TYPE_SEED, TYPE_SUGGESTION_LIST } from '../../utils/propTypes';
import { seedsSelectors } from '../../modules/seeds';
import { partsActions, partsSelectors } from '../../modules/parts';
import { assetsActions, assetsSelectors } from '../../modules/assets';
import { api } from '../../store/services/api';
import RemoteControlsEditor from './RemoteControlsEditor';
import RemoteControlsRawEditor from './RemoteControlsRawEditor';

class RemoteControlsPage extends React.PureComponent {
  componentDidMount() {
    const { seed, fetchParts, match, fetchImages } = this.props;

    fetchParts(match.params.seedId);

    if (seed) fetchImages(seed.projectId);
  }

  componentDidUpdate(prevProps) {
    const { seed, fetchImages } = this.props;

    if (seed && !prevProps.seed) {
      fetchImages(seed.projectId);
    }
  }

  updateRemoteControlHandler = remoteControl => {
    const { seed, updateRemoteControl } = this.props;

    updateRemoteControl({ _id: seed._id, remoteControl });
  };

  removeRemoteControlHandler = remoteControl => {
    const { seed, deleteRemoteControl } = this.props;

    deleteRemoteControl({ _id: seed._id, remoteControl });
  };

  renderPlaceholder = () => (
    <div className="d-flex align-items-center justify-content-center h-100">
      <p>The model was not found</p>
    </div>
  );

  render() {
    const { seed, controlsList, optionStatusList, request, isPriceCodesEnabled, imagesList, updateImagesAfterUpload } =
      this.props;

    const projectId = seed?.projectId || ''; // since seed can be boolean false

    return (
      <Loader loading={request && !seed}>
        <div className="p-3 w-100">
          {seed ? (
            <>
              <RemoteControlsRawEditor seed={seed} />
              <RemoteControlsEditor
                controlsList={controlsList}
                optionStatusList={optionStatusList}
                onSubmit={this.updateRemoteControlHandler}
                onRemoveRemoteControl={this.removeRemoteControlHandler}
                disableOptionPrice={isPriceCodesEnabled}
                projectImages={imagesList}
                projectId={projectId}
                updateImagesAfterUpload={updateImagesAfterUpload}
              />
            </>
          ) : (
            this.renderPlaceholder()
          )}
        </div>
      </Loader>
    );
  }
}

RemoteControlsPage.propTypes = {
  seed: PropTypes.oneOfType([TYPE_SEED, PropTypes.bool]),
  controlsList: TYPE_PART_CONTROLS.isRequired,
  request: PropTypes.bool.isRequired,
  optionStatusList: TYPE_SUGGESTION_LIST.isRequired,
  fetchParts: PropTypes.func.isRequired,
  fetchImages: PropTypes.func.isRequired,
  updateImagesAfterUpload: PropTypes.func.isRequired,
  updateRemoteControl: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seedId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isPriceCodesEnabled: PropTypes.bool,
  deleteRemoteControl: PropTypes.func.isRequired,
  imagesList: TYPE_ASSET_LIST
};

RemoteControlsPage.defaultProps = {
  seed: false,
  isPriceCodesEnabled: false,
  imagesList: []
};

const mapStateToProps = (state, ownProps) => {
  const { seedId } = ownProps.match.params;

  return {
    seed: seedsSelectors.selectSeedMemoized(state, seedId),
    controlsList: partsSelectors.selectRemoteControlsListBySeed(state, seedId),
    request: seedsSelectors.selectSeedsRequest(state),
    optionStatusList: seedsSelectors.selectOptionStatusList(state, seedId),
    isPriceCodesEnabled: seedsSelectors.selectPriceCodesEnabled(state, seedId),
    imagesList: assetsSelectors.images.selectListBySeed(state, seedId)
  };
};

const mapDispatchToProps = {
  fetchParts: partsActions.fetchParts,
  fetchImages: assetsActions.images.fetchList,
  updateRemoteControl: api.endpoints.updateSeedRemoteControl.initiate,
  deleteRemoteControl: api.endpoints.deleteSeedRemoteControl.initiate,
  updateImagesAfterUpload: assetsActions.images.updateAssetsAfterUpload
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemoteControlsPage));
