import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../../../Atoms';
import { TYPE_MAPPED_INSTANCE_LINES } from '../../../../../../utils/propTypes';

const mappedInstanceEditor = b.with('mapped-instance-editor');

class MappedInstanceEditor extends React.PureComponent {
  state = {
    lines: [...this.props.lines]
  };

  handleSaveButton = () => {
    this.props.onSave(this.state.lines);
  };

  handleChangeValue = e => {
    const { dataset, value } = e.target;
    const { field } = dataset;
    const index = Number(dataset.index);

    this.setState(prevSate => ({
      lines: prevSate.lines.map((line, indx) => (index === indx ? { ...line, [field]: value } : line))
    }));
  };

  handleRemoveLine = e => {
    const index = Number(e.currentTarget.dataset.index);

    this.setState(prevSate => ({ lines: prevSate.lines.filter((line, indx) => index !== indx) }));
  };

  handleAddLine = () => {
    this.setState(prevSate => ({ lines: [...prevSate.lines, { key: '', value: '' }] }));
  };

  renderBody() {
    const { lines } = this.state;

    /* eslint-disable react/no-array-index-key */
    return lines.map((line, index) => (
      <InputGroup key={index} className="mb-2">
        <Input
          type="text"
          placeholder="key"
          value={line.key}
          data-field="key"
          data-index={index}
          onChange={this.handleChangeValue}
        />
        <Input
          type="text"
          placeholder="value"
          value={line.value}
          data-field="value"
          data-index={index}
          onChange={this.handleChangeValue}
        />
        <Button data-index={index} onClick={this.handleRemoveLine} color="danger" outline>
          <i className="fa fa-fw fa-trash-o" aria-hidden="true" />
        </Button>
      </InputGroup>
    ));
    /* eslint-enable react/no-array-index-key */
  }

  render() {
    const { onClose } = this.props;

    return (
      <Modal
        isOpen
        toggle={onClose}
        size="lg"
        centered
        className={mappedInstanceEditor()}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={onClose}>Edit mapped instance</ModalHeader>
        <ModalBody className={mappedInstanceEditor('body')}>
          {this.renderBody()}
          <Button color="primary" onClick={this.handleAddLine}>
            <i className="fa fa-fw fa-plus" /> Add
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSaveButton}>
            <i className="fa fa-fw fa-save" /> Save
          </Button>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MappedInstanceEditor.defaultProps = {
  lines: []
};

MappedInstanceEditor.propTypes = {
  lines: TYPE_MAPPED_INSTANCE_LINES,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default MappedInstanceEditor;
