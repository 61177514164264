import { reducer } from '../@helpers';
import iframeScriptsActions from './iframeScriptsActions';

function moduleReducer(state, { type, payload = {} }) {
  // eslint-disable-next-line
  switch (type) {
    case iframeScriptsActions.setLatestIframeScript.success.ACTION_TYPE: {
      const list = {};

      Object.values(state.list).forEach(script => {
        list[script._id] = { ...script, latest: script._id === payload._id };
      });

      return { ...state, list: { ...state.list, [payload._id]: { ...payload } } };
    }
    default:
      return state;
  }
}

export default reducer.createBaseModuleReducer(iframeScriptsActions, moduleReducer);
