import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FieldWrapper from '../FieldWrapper';
import ImageFieldComponent from './ImageFieldComponent';

const Image = props => {
  const { name, list, header, uploadImage, onUploadFailure, onUploadSuccess, hideUpload } = props;

  return (
    <FieldWrapper {...props}>
      <Field
        list={list}
        header={header}
        name={name}
        hideUpload={hideUpload}
        uploadImage={uploadImage}
        onUploadFailure={onUploadFailure}
        onUploadSuccess={onUploadSuccess}
        component={ImageFieldComponent}
      />
    </FieldWrapper>
  );
};

Image.propTypes = {
  name: PropTypes.string.isRequired,
  hideUpload: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  ),
  header: PropTypes.string,
  /*
    These callbacks have to be defined from outside because then we can use this component also for non-image/texture fields.
  */
  uploadImage: PropTypes.func,
  onUploadFailure: PropTypes.func,
  onUploadSuccess: PropTypes.func
};

Image.defaultProps = {
  list: [],
  header: 'Select image',
  hideUpload: false,
  uploadImage: () => null,
  onUploadFailure: () => null,
  onUploadSuccess: () => null
};

export default Image;
