import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field as FFField } from 'react-final-form';
import Form from '../../../../../Form';
import Field from '../../../../../Form/Field';
import { TYPE_PART_CONTROLS, TYPE_SUGGESTION_LIST } from '../../../../../../utils/propTypes';

const InteractionForm = ({ name, extraInfoCollections, cameraViews, materials, controls, tabsSuggestions }) => {
  const makeName = useCallback(
    fieldName => {
      const baseNameArray = [];

      if (name) {
        baseNameArray.push(name);
      }
      baseNameArray.push(fieldName);

      return baseNameArray.join('.');
    },
    [name]
  );

  const controlsSuggestions = useMemo(() => {
    return controls.map(({ name: controlName, parent }) => ({
      value: controlName,
      label: `${controlName} (${parent.ReferenceName} ${parent.Option})`
    }));
  }, [controls]);

  const makeOptionSuggestions = useCallback(
    controlName => {
      const control = controls.find(({ name: cName }) => cName === controlName);

      if (control) {
        return control.list.map(({ name: oName, displayName }) => ({
          value: oName,
          label: `${oName}`
        }));
      }

      return [];
    },
    [controls]
  );

  const renderControlOptions = useCallback(
    ({ input }) => {
      const { value } = input;

      return (
        <Field.Select
          name={makeName('controller.optionName')}
          options={makeOptionSuggestions(value)}
          label="Control value"
          description="Set control value "
          clearable
          creatable
        />
      );
    },
    [makeName, makeOptionSuggestions]
  );

  return (
    <>
      <Field.Toggle name={makeName('enabled')} label="Enabled" description="Enable interactions" />
      <Field.Select
        name={makeName('extraInfoCollection')}
        options={extraInfoCollections}
        label="Extra info"
        description="Show extra info on click"
        clearable
        creatable
      />
      <Field.Select
        name={makeName('cameraView')}
        options={cameraViews}
        label="Camera view"
        description="Set camera view on click"
        clearable
        creatable
      />
      <Field.Select
        name={makeName('selectTab')}
        options={tabsSuggestions}
        label="Select tab"
        description="Select given tab on click"
        clearable
        creatable
      />
      <Form.Group header="Select control">
        <Field.Select
          name={makeName('selectControl.name')}
          options={controlsSuggestions}
          label="Select control"
          description="Select given control on click"
          clearable
          creatable
        />
        <Field.Toggle
          name={makeName('selectControl.local')}
          label="Local"
          description="Control has to be defined as local also in Tab or Child control"
        />
      </Form.Group>
      <Form.Group header="Controller">
        <Field.Select
          name={makeName('controller.controlName')}
          options={controlsSuggestions}
          label="Control name"
          description="Set which control to manipulate"
          clearable
          creatable
        />
        <FFField name={makeName('controller.controlName')} render={renderControlOptions} />
      </Form.Group>

      <Field.Image
        name={makeName('hoverMaterial')}
        label="Hover material"
        description="Select hover material"
        list={materials}
        hideUpload
      />
    </>
  );
};

InteractionForm.propTypes = {
  name: PropTypes.string,
  cameraViews: TYPE_SUGGESTION_LIST,
  extraInfoCollections: TYPE_SUGGESTION_LIST,
  materials: PropTypes.arrayOf({}),
  controls: TYPE_PART_CONTROLS,
  tabsSuggestions: TYPE_SUGGESTION_LIST
};
InteractionForm.defaultProps = {
  name: '',
  cameraViews: [],
  extraInfoCollections: [],
  materials: [],
  controls: [],
  tabsSuggestions: []
};

export default InteractionForm;
