import React from 'react';
import { TYPE_SEED } from '../../../utils/propTypes';
import { usePartList } from '../../../utils/hooks';
import Loader from '../../Loader/Loader';
import RemoteSwitchesEditor from '../../RemoteSwitchesPage/RemoteSwitchesEditor';

const SelfServiceRemoteSwitches = ({ seed }) => {
  const { request } = usePartList(seed._id);

  return (
    <Loader loading={request}>
      <RemoteSwitchesEditor seedId={seed._id} />
    </Loader>
  );
};

SelfServiceRemoteSwitches.propTypes = {
  seed: TYPE_SEED.isRequired
};

export default SelfServiceRemoteSwitches;
