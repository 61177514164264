import settingsActions from './settingsActions';

export const initialState = {
  settings: {
    raw: null,
    parsed: ''
  },
  theme: {
    raw: {},
    parsed: ''
  },
  request: false,
  error: ''
};

const settingsReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case settingsActions.fetchGlobalSettings.request.ACTION_TYPE:
    case settingsActions.fetchGlobalTheme.request.ACTION_TYPE:
      return { ...state, request: true };
    case settingsActions.PARSE_GLOBAL_SETTINGS__SUCCESS: {
      const { raw, parsed } = payload;

      return { ...state, settings: { raw, parsed }, request: false, error: '' };
    }
    case settingsActions.PARSE_GLOBAL_THEME__SUCCESS: {
      const { raw, parsed } = payload;

      return { ...state, theme: { raw, parsed }, request: false, error: '' };
    }
    case settingsActions.fetchGlobalSettings.failure.ACTION_TYPE:
    case settingsActions.fetchGlobalTheme.failure.ACTION_TYPE:
    case settingsActions.updateGlobalSettings.failure.ACTION_TYPE:
    case settingsActions.updateGlobalTheme.failure.ACTION_TYPE:
    case settingsActions.PARSE_GLOBAL_SETTINGS__FAILURE:
    case settingsActions.PARSE_GLOBAL_THEME__FAILURE:
      return { ...state, error: payload.error, request: false };
    default:
      return state;
  }
};

export default settingsReducer;
