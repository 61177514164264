import axios from 'axios';
import { AssetKindType } from 'types';
import { ASSETS_URL } from '../../config';

const makeAssetUrl =
  (assetType: AssetKindType) =>
  ({ fileName, projectId }: { fileName: string; projectId?: string }) =>
    `${ASSETS_URL}/${axios.getUri({ url: `${assetType}/${fileName}`, params: { projectId } })}`;

export type GetAssetUrlType = ReturnType<typeof makeAssetUrl>;

export default makeAssetUrl;
