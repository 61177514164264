import { useState, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { getConvertedRegisterOptions } from '../helpers';
import { SwitchButtonProps, FieldType } from '../../types';
import FieldWrapper from '../FieldWrapper';
import { ButtonGroup, Button } from '../../..';

const SwitchButton = <T,>({
  name,
  label,
  description,
  required,
  readonly,
  placeholder,
  onChange,
  buttonLabels,
  ...rest
}: SwitchButtonProps<T>) => {
  const { setValue } = useFormContext<T>();
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const fieldError: any = fieldState.error;

  const [stateValue, setStateValue] = useState<boolean>();

  useEffect(() => {
    setStateValue(!!field.value);
  }, [field.value]);

  return (
    <FieldWrapper
      type={FieldType.BUTTON_SWITCH}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
      useShadowValue
      shadowLabelsMap={{ false: buttonLabels[0], true: buttonLabels[1] }}
    >
      <div className="d-flex flex-column flex-grow-1 mb-3 pb-4 border-bottom">
        <ButtonGroup>
          <Button
            color="secondary"
            onClick={() => {
              setStateValue(false);
              setValue(name, true as any, { shouldDirty: true });
              onChange && onChange(false);
            }}
            outline={stateValue}
          >
            {buttonLabels[0]}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setStateValue(true);
              setValue(name, true as any, { shouldDirty: true });
              onChange && onChange(true);
            }}
            outline={!stateValue}
          >
            {buttonLabels[1]}
          </Button>
        </ButtonGroup>
      </div>
    </FieldWrapper>
  );
};

export default SwitchButton;
