export const selectMappedInstance = (state, id) => state.mappedInstances.list[id];
export const selectMappedInstancesRequest = state => state.mappedInstances.request;
export const selectMappedInstancesPreviewRequest = state => state.mappedInstances.previewRequest;
export const selectMappedInstancesPreview = state => state.mappedInstances.preview;
export const selectMappedInstancesCreatedFlag = state => state.mappedInstances.created;
export const selectMappedInstancesCreatingFlag = state => state.mappedInstances.creating;

export const selectMappedInstanceLockedStatus = (state, id) => {
  const mappedInstance = state.mappedInstances.list[id] || {};

  return mappedInstance.locked;
};
