import React from 'react';
import PropTypes from 'prop-types';
import { JsonViewer as JsonViewerAtom, Modal, ModalBody, ModalHeader } from '../Atoms';

const JsonViewer = ({ header, onClose, data }) => (
  <Modal isOpen toggle={onClose} size="lg" centered>
    <ModalHeader toggle={onClose}>{header}</ModalHeader>
    <ModalBody className="overflow-auto" style={{ minHeight: '400px' }}>
      <JsonViewerAtom data={data} />
    </ModalBody>
  </Modal>
);

JsonViewer.propTypes = {
  header: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired // eslint-disable-line react/forbid-prop-types
};

export default JsonViewer;
