import { FC, ReactNode } from 'react';
import Loader from '../Loader/Loader';
import { Container } from '../Bootstrap';

interface ListPageWrapperProps {
  title: string;
  loading: boolean;
  button?: ReactNode;
  search?: ReactNode;
}

const ListPageWrapper: FC<ListPageWrapperProps> = ({ title, loading, button, search, children }) => {
  return (
    <Container type="lg" className="flex-grow-1 py-3">
      <div className="p-2 border-bottom d-flex justify-content-between align-items-center">
        <h2 className="m-0 fw-bolder">{title}</h2>
        {button}
      </div>
      {search}
      <Loader loading={loading}>{children}</Loader>
    </Container>
  );
};

export default ListPageWrapper;
