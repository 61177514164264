import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import { SortableElement } from 'react-sortable-hoc';
import { Button, InputGroup } from '../../../Atoms';
import SortableWrapper from '../../SortableWrapper';
import DragHandle from '../../DragHandle';
import useArrayFieldMutators from '../useArrayFieldMutators';
import ArrayItem from './ArrayItem';

const SortableInputGroup = SortableElement(InputGroup);

const ArrayList = ({ name, defaultData, component, componentProps, internalFieldName }) => {
  const fieldArray = useFieldArray(name, { submitting: true, pristine: true });
  const { fields } = fieldArray;
  const { keys, removeHandler, addHandler, moveHandler } = useArrayFieldMutators(fieldArray);

  return (
    <SortableWrapper
      onSortEnd={({ oldIndex, newIndex }) => moveHandler(oldIndex, newIndex)}
      useDragHandle
      helperClass="card_moved"
    >
      {fields.map((field, fieldIndex) => (
        <SortableInputGroup index={fieldIndex} className="flex-nowrap mb-1" key={keys[fieldIndex]}>
          <DragHandle />
          <ArrayItem
            component={component}
            componentProps={componentProps}
            name={field}
            internalFieldName={internalFieldName}
          />
          <Button
            className="flex-none"
            style={{ width: 30 }}
            size="sm"
            color="danger"
            outline
            onClick={removeHandler}
            data-index={fieldIndex}
          >
            <i className="fa fa-trash" />
          </Button>
        </SortableInputGroup>
      ))}
      <Button color="primary" outline onClick={e => addHandler(defaultData)}>
        + Add
      </Button>
    </SortableWrapper>
  );
};

ArrayList.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultData: PropTypes.any,
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.shape({}),
  internalFieldName: PropTypes.string
};
ArrayList.defaultProps = {
  defaultData: '',
  internalFieldName: '',
  componentProps: undefined
};

export default ArrayList;
