import React, { FC, ReactElement, useRef } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSwitch } from '../../../utils/hooks';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Collapse } from '..';
import Icon from '../Icon/Icon';

interface SectionProps {
  title: string;
  open?: boolean;
  disabled?: boolean;
  headerButton?: ReactElement | null;
  dragHandleListeners?: DraggableSyntheticListeners;
  onToggle?: () => void;
}

const Section: FC<SectionProps> = ({
  open,
  disabled,
  title,
  children,
  headerButton,
  dragHandleListeners,
  onToggle
}) => {
  const innerRef = useRef(null);
  const { isOpen, toggle } = useSwitch(open);

  return (
    <Card className="my-2">
      <CardHeader className="p-0 border-0">
        <ButtonGroup className="w-100">
          {dragHandleListeners ? (
            <div className="align-items-center d-flex" style={{ cursor: 'move' }} {...dragHandleListeners}>
              <Icon type="mdiDrag" scale={0.7} />
            </div>
          ) : null}
          <Button
            size="sm"
            color="light"
            onClick={() => {
              toggle();
              onToggle && onToggle();
            }}
            disabled={disabled}
            className="d-flex border-0 align-items-center"
          >
            {title === '' ? (
              <span className="text-muted">&lt;empty string&gt;</span>
            ) : (
              <span className={isOpen ? 'fw-bold' : 'text-decoration-underline'}>{title}</span>
            )}
            <span className="ms-auto">
              <Icon type={isOpen ? 'mdiUnfoldLessHorizontal' : 'mdiUnfoldMoreHorizontal'} scale={0.8} />
            </span>
          </Button>

          {headerButton}
        </ButtonGroup>
      </CardHeader>
      <Collapse isOpen={isOpen && !disabled} innerRef={innerRef} mountOnEnter>
        <CardBody>{children}</CardBody>
      </Collapse>
    </Card>
  );
};

export default Section;
