import { all, call, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import invitationsActions from './invitationsActions';

export function* doFetchInvitationsSaga() {
  yield call(sagas.doBaseAsyncSaga, invitationsActions.fetchInvitations, api.invitations.getList);
}

export function* watchFetchInvitationsSaga() {
  yield takeLatest(invitationsActions.FETCH_INVITATIONS, doFetchInvitationsSaga);
}

export function* doCreateInvitationSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, invitationsActions.createInvitation, api.invitations.create, payload.entity);
}

export function* watchCreateInvitationSaga() {
  yield takeLatest(invitationsActions.CREATE_INVITATION, doCreateInvitationSaga);
}

export function* doUpdateInvitationSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, invitationsActions.updateInvitation, api.invitations.update, payload.entity);
}

export function* watchUpdateInvitationSaga() {
  yield takeLatest(invitationsActions.UPDATE_INVITATION, doUpdateInvitationSaga);
}

export function* doDeleteInvitationSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, invitationsActions.deleteInvitation, api.invitations.delete, payload.id);
}

export function* watchDeleteInvitationSaga() {
  yield takeLatest(invitationsActions.DELETE_INVITATION, doDeleteInvitationSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchInvitationsSaga(),
    watchCreateInvitationSaga(),
    watchUpdateInvitationSaga(),
    watchDeleteInvitationSaga()
  ]);
}
