import { actions } from '../@helpers';

const MODULE_NAME = 'INSTANCES';
const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const base = type => instanceId => ({ type, payload: { instanceId } });

const markRequestAsRead = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'MARK_REQUEST_AS_READ'),
  { base }
);
const markRequestAsUnread = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'MARK_REQUEST_AS_UNREAD'),
  { base }
);
const deleteRequest = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'DELETE_REQUEST'),
  { base }
);
const makeLeadPrimary = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'MAKE_LEAD_PRIMARY'),
  { base }
);
const sendToLegalesign = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'SEND_TO_LEGALESIGN'),
  { base }
);
const checkLegalesignStatus = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'CHECK_LEGALESIGN_STATUS'),
  { base }
);
const sendToProduction = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'SEND_TO_PRODUCTION'),
  { base }
);
const cancelOrder = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'CANCEL_ORDER'),
  { base }
);
const runIntegration = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'RUN_INTEGRATION'),
  { base }
);

// for partners, requires right VISITORS__UPDATE
const updateInstance = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_INSTANCE'),
  { base: type => instance => ({ type, payload: { instance } }) }
);

// for managers, requires right INSTANCES__UPDATE
const updateInstanceData = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_INSTANCE_DATA'),
  { base: type => (instanceId, instance) => ({ type, payload: { instanceId, instance } }) }
);

const SIGNED_PDF_UPLOADED = actions.createModulePrefixForActionType(MODULE_NAME, 'SIGNED_PDF_UPLOADED');

function signedPDFUploaded(instance) {
  return {
    type: SIGNED_PDF_UPLOADED,
    payload: instance
  };
}

const instanceActions = {
  FETCH_INSTANCES: moduleActions.fetchList.ACTION_TYPE,

  fetchInstances: moduleActions.fetchList,

  MARK_REQUEST_AS_READ: markRequestAsRead.ACTION_TYPE,
  MARK_REQUEST_AS_UNREAD: markRequestAsUnread.ACTION_TYPE,
  DELETE_REQUEST: deleteRequest.ACTION_TYPE,
  MAKE_LEAD_PRIMARY: makeLeadPrimary.ACTION_TYPE,
  SEND_TO_LEGALESIGN: sendToLegalesign.ACTION_TYPE,
  CHECK_LEGALESIGN_STATUS: checkLegalesignStatus.ACTION_TYPE,
  SEND_TO_PRODUCTION: sendToProduction.ACTION_TYPE,
  CANCEL_ORDER: cancelOrder.ACTION_TYPE,
  RUN_INTEGRATION: runIntegration.ACTION_TYPE,
  UPDATE_INSTANCE: updateInstance.ACTION_TYPE,
  UPDATE_INSTANCE_DATA: updateInstanceData.ACTION_TYPE,
  DELETE_INSTANCE: moduleActions.deleteEntity.ACTION_TYPE,
  SIGNED_PDF_UPLOADED,

  markRequestAsRead,
  markRequestAsUnread,
  deleteRequest,
  makeLeadPrimary,
  sendToLegalesign,
  checkLegalesignStatus,
  sendToProduction,
  cancelOrder,
  runIntegration,
  updateInstance,
  updateInstanceData,
  signedPDFUploaded,

  ...moduleActions
};

export default instanceActions;
