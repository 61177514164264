import { useContext, useMemo, useCallback, useState, ReactNode, CSSProperties } from 'react';
import _mergeWith from 'lodash/mergeWith';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useFormContext, Path, UnpackNestedValue, DeepPartial, ArrayPath } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormField } from '..';
import { UncontrolledPopover, PopoverBody, Form, Button, ButtonGroup } from '../..';
import { FormContext } from '../FormContext';
import { FieldName } from '../types';
import Icon from '../../Icon/Icon';
import { useSwitch } from '../../../../utils/hooks';

const MASTER_LEVEL = 'master';
const PROJECT_LEVEL = 'project';
const CURRENT_LEVEL = 'current';
const SEED_LEVEL = 'public seed';

const levelDisplayNames = {
  master: 'Master',
  project: 'Project',
  'public seed': 'Version'
};

const RESULT_NAME = 'result';

enum Mode {
  Seed = 'seed',
  Project = 'project'
}

interface ShadowValueType {
  name?: string;
  value?: any;
}

interface ShadowSimpleProps {
  name: string;
  shadowValues: ShadowValueType[];
  shadowLabelsMap?: Record<any, string>;
}

interface ShadowComplexValuesProps {
  name: string;
  children?: any;
  label: string;
  format?: any;
  shadowValues: ShadowValueType[];
  level?: number;
  headerFormat?: (item: any) => string;
}

interface ShadowValueProps {
  name: string;
  isComplex?: boolean;
  children?: ReactNode;
  level?: number;
  headerFormat?: (item: any) => string;
  shadowLabelsMap?: Record<any, string>;
}

const useClearValues = (list: ShadowValueType[], fieldName: string) => {
  return useMemo(
    () =>
      list.map(level => {
        return level.name === CURRENT_LEVEL ? level : { name: level.name, value: _get(level.value, fieldName) };
      }),
    [list, fieldName]
  );
};

const useField = <T,>(name: FieldName<T>) => {
  const { setValue, getValues } = useFormContext<T>();

  const setUndefined = useCallback(() => {
    const options = { shouldDirty: true, shouldValidate: true };

    const current: any = getValues(name as Path<T>);

    // we can't just set value to undefined here, because in case of arrays and (some) strings it will cause a crash
    if (Array.isArray(current)) {
      // setting an array field to undefined with shouldDirty flag will fail, cause RHS will try to compare
      // new value (undefined) with the default one (some array) by length first
      setValue(name, [] as any, options);
      setValue(name, undefined as any);
    } else if (typeof current === 'number' || typeof current === 'boolean' || typeof current === 'string') {
      setValue(name, undefined as any, options);
    }
  }, [name, setValue, getValues]);

  const field = {
    value: getValues(name as Path<T>)
  };

  return { field, setUndefined };
};

// value can be of a different types
const parseValue = (value?: any, shadowLabelsMap?: Record<any, string>) => {
  if (value === undefined) {
    return typeof value;
  }

  if (value === '') {
    return 'empty string';
  }

  // special case for when the real value is not what should be displayed
  if (shadowLabelsMap && shadowLabelsMap[value]) {
    return shadowLabelsMap[value];
  }

  if (typeof value === 'boolean') {
    return Boolean(value).toString();
  }

  return value;
};

function customizer(_: any, sourceValue: any[]) {
  return Array.isArray(sourceValue) ? sourceValue : undefined;
}

const mergeValues = (values: any[]): ShadowValueType => {
  return _mergeWith({}, ...values.map(level => ({ value: level.value })), customizer);
};

const getMode = (shadowValues: ShadowValueType[]): Mode | undefined => {
  if (shadowValues.find(value => value.name === SEED_LEVEL)) {
    return Mode.Project;
  }

  if (shadowValues.find(value => value.name === PROJECT_LEVEL)) {
    return Mode.Seed;
  }

  return undefined;
};

const ShadowValue = <T,>({ name, isComplex, children, level, headerFormat, shadowLabelsMap }: ShadowValueProps) => {
  const { shadowValues } = useContext(FormContext);

  if (!shadowValues) return null;

  return isComplex ? (
    <ShadowComplexValues<T>
      shadowValues={shadowValues}
      name={name}
      label="test"
      level={level}
      headerFormat={headerFormat}
    >
      {children}
    </ShadowComplexValues>
  ) : (
    <ShadowSimpleValue<T> shadowValues={shadowValues} name={name} shadowLabelsMap={shadowLabelsMap} />
  );
};

const RemoveValueButton = ({
  field,
  onClick,
  title,
  style
}: {
  field: any;
  onClick: () => void;
  title: string;
  style?: CSSProperties;
}) => {
  return field.value !== undefined ? (
    <button
      type="button"
      title={title}
      className="d-inline btn btn-link p-0 align-baseline"
      onKeyDown={e => e.preventDefault()}
      tabIndex={-1}
      onClick={onClick}
      style={style}
    >
      <Icon type="mdiTrashCanOutline" mix="text-primary" />
    </button>
  ) : null;
};

interface InheritTypeIconProps {
  result: ShadowValueType;
}

const InheritTypeIcon = ({ result }: InheritTypeIconProps) => {
  if (result.name === CURRENT_LEVEL && result.value !== 'undefined') {
    return <Icon mix="text-primary" type="mdiFileCheckOutline" />;
  }

  if (result.name === SEED_LEVEL && result.value !== 'undefined') {
    return <Icon mix="text-warning" type="mdiFileSign" />;
  }

  if ((result.name === MASTER_LEVEL || result.name === PROJECT_LEVEL) && result.value !== 'undefined') {
    return <Icon mix="text-muted" type="mdiFileImportOutline" />;
  }

  return <></>;
};

interface InheritTypeMessageProps {
  result: ShadowValueType;
}

const InheritTypeMessage = ({ result }: InheritTypeMessageProps) => {
  if (result.name === CURRENT_LEVEL) {
    return (
      <div className="d-flex">
        <Icon mix="text-primary me-2" type="mdiFileCheckOutline" />
        <h5 className="m-0">Actual value</h5>
      </div>
    );
  }

  if (result.name === SEED_LEVEL && result.value !== 'undefined') {
    return (
      <div className="d-flex">
        <Icon mix="text-warning me-2" type="mdiFileSign" />
        <h5 className="m-0">Value overridden</h5>
      </div>
    );
  }

  if ((result.name === MASTER_LEVEL || result.name === PROJECT_LEVEL) && result.value !== 'undefined') {
    return (
      <div className="d-flex">
        <Icon mix="text-muted me-2" type="mdiFileImportOutline" />
        <h5 className="m-0">Value inherited</h5>
      </div>
    );
  }

  return <></>;
};

const ShadowSimpleValue = <T,>({ name, shadowValues, shadowLabelsMap }: ShadowSimpleProps) => {
  let result: ShadowValueType = {};

  const clearValues = useClearValues(shadowValues, name);
  const { field: value, setUndefined } = useField<T>(name as FieldName<T>);

  const mode = getMode(shadowValues);

  const values: ShadowValueType[] = clearValues.map(level =>
    level.name === CURRENT_LEVEL ? { name: CURRENT_LEVEL, value: value.value } : level
  );
  const merged = mergeValues(values);

  const source: ShadowValueType | undefined = values.reverse().find(level => {
    return level.value === merged.value;
  });

  const res = { name: source?.name, value: parseValue(_get(source, 'value')) };

  if (res.value !== result.value || res.name !== result.name) {
    result = res;
  }

  const id = useMemo(() => `${name.replace(/\W/g, '_')}_`, [name]);

  const levelNameString = useCallback(
    (levelName?: string) => {
      if (levelName === MASTER_LEVEL) {
        return levelDisplayNames[MASTER_LEVEL];
      }

      if (levelName === PROJECT_LEVEL) {
        return levelDisplayNames[PROJECT_LEVEL];
      }

      if (levelName === SEED_LEVEL) {
        return levelDisplayNames[SEED_LEVEL];
      }

      if (levelName === CURRENT_LEVEL) {
        if (mode === Mode.Project) {
          return levelDisplayNames[PROJECT_LEVEL];
        }

        return levelDisplayNames[SEED_LEVEL];
      }

      return '';
    },
    [mode]
  );

  return (
    <div className="d-inline d-flex align-items-baseline">
      <span id={id}>
        <InheritTypeIcon result={result} />
      </span>
      <RemoveValueButton onClick={setUndefined} title="Reset value" field={value} />
      <UncontrolledPopover placement="bottom" trigger="hover" target={id}>
        <PopoverBody>
          <InheritTypeMessage result={result} />
          {values.reverse().map(level => (
            <span
              style={
                level.name === CURRENT_LEVEL ? { border: '1px dashed #bdbdbd', paddingLeft: 2, marginLeft: -4 } : {}
              }
              className="d-block text-muted"
              key={level.name}
            >
              {levelNameString(level.name)}:{' '}
              <span className={level.name === result.name ? 'fw-bold' : ''}>
                {parseValue(level.name === result.name ? result.value : level.value, shadowLabelsMap)}
              </span>
            </span>
          ))}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

const ShadowComplexValues = <T,>({
  name,
  children,
  label,
  format,
  shadowValues,
  level: nestingLevel,
  headerFormat
}: ShadowComplexValuesProps) => {
  const clearValues = useClearValues(shadowValues, name);
  let result: ShadowValueType = {};

  const { field: value, setUndefined } = useField<T>(name as FieldName<T>);
  const { isOpen, open, close } = useSwitch(false);

  const values = clearValues.map(level =>
    level.name === CURRENT_LEVEL ? { name: CURRENT_LEVEL, value: value.value } : level
  );

  const merged = mergeValues(values);

  const source: ShadowValueType | undefined = values.reverse().find(level => {
    return level.value === merged.value;
  });

  const res = { name: source?.name, value: parseValue(_get(source, 'value')) };

  if (res.value !== result.value || res.name !== result.name) {
    result = res;
  }

  const allValues = [...values, { name: RESULT_NAME, value: merged.value }].map(level => ({
    ...level,
    formState: _set({}, name, level.value)
  }));

  const [currentType, setCurrentType] = useState(result.name);

  const handleChangeType = useCallback(e => {
    setCurrentType(e.currentTarget.dataset.type);
  }, []);

  const id = useMemo(() => `${name.replace(/\W/g, '_')}_`, [name]);

  return (
    <>
      <div className="d-inline d-flex">
        <button style={{ width: 34 }} id={id} className="btn btn-link" onClick={open} type="button" title="show popup">
          <InheritTypeIcon result={result} />
        </button>
        <RemoveValueButton style={{ marginLeft: 2 }} onClick={setUndefined} title="Reset value" field={value} />
        <UncontrolledPopover placement="bottom" trigger="hover" target={id}>
          <PopoverBody>
            <InheritTypeMessage result={result} />
            <small className="text-muted m-0">click to inspect values</small>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
      <Modal size="l" isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>{label || name}</ModalHeader>
        <ModalBody>
          <ButtonGroup className="mb-3" size="sm">
            {allValues.map(({ name: type, value: itemValue }) => (
              <Button
                data-type={type}
                key={type}
                onClick={handleChangeType}
                color="secondary"
                outline={currentType !== type}
              >
                {type}
                {Array.isArray(itemValue) ? ` (${itemValue.length})` : null}
              </Button>
            ))}
          </ButtonGroup>
          <Form<T>
            readOnly
            defaultValues={
              allValues?.find(({ name: type }) => type === currentType)?.formState as unknown as UnpackNestedValue<
                DeepPartial<T>
              >
            }
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            <FormField.ArrayWrapper<T> headerFormat={headerFormat} name={name as ArrayPath<T>}>
              {children}
            </FormField.ArrayWrapper>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShadowValue;
