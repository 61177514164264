import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import b from 'b_';
import { FormGroup, FormText, Input, Label } from '../../../Atoms';
import ShadowValue from '../../ShadowValue';

const field = b.with('form-field');

const FORMAT = value => value;

const Toggle = props => {
  const { name, idPrefix, label, disabled, description, useShadowValue } = props;

  const render = useCallback(
    ({ input, meta }) => (
      <div>
        <Input
          id={`${idPrefix}${name}`}
          {...input}
          type="switch"
          disabled={disabled}
          className="fw-bold text-uppercase"
        />
        <Label for={`${idPrefix}${name}`} check className="mb-0">
          {label || name}
        </Label>
      </div>
    ),
    [idPrefix, name, label, disabled]
  );

  return (
    <FormGroup switch className="mb-3">
      <Field name={name} render={render} type="checkbox" />
      {description ? <FormText className={`${field('description')} mt-0 mb-1 d-inline`}>{description}</FormText> : null}
      {useShadowValue ? <ShadowValue name={name} format={FORMAT} /> : null}
    </FormGroup>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  idPrefix: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.node,
  useShadowValue: PropTypes.bool
};

Toggle.defaultProps = {
  label: '',
  disabled: false,
  description: '',
  idPrefix: '',
  useShadowValue: false
};

export default Toggle;
