import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Card, CardBody, Collapse } from '../../Atoms';
import DragHandle from '../DragHandle';
import { useSwitch } from '../../../utils/hooks';

const Group = ({
  open,
  className,
  color,
  header,
  disabled,
  headerButtons,
  children,
  useDragHandle,
  icon,
  description,
  parentName,
  isWithinArray,
  mountOnEnter
}) => {
  const { isOpen, toggle } = useSwitch(open);

  /* If group is within */
  const childrenArray = React.Children.toArray(children);
  let renamedChildren = childrenArray;

  if (isWithinArray && parentName) {
    renamedChildren = childrenArray.map(child => {
      const childName = `${parentName}.${child.props.name}`;

      return React.cloneElement(child, { name: childName, id: childName, parentName, isWithinArray });
    });
  }

  return (
    <Card className={`my-2 ${className}`}>
      <ButtonGroup>
        <Button
          size="sm"
          block
          color={color}
          className="d-flex align-items-center border-0"
          onClick={toggle}
          disabled={disabled}
        >
          {useDragHandle ? <DragHandle /> : null}

          {icon ? <i className={`fa fa-fw ${icon}`} /> : null}

          {header}
          {description ? <span className="text-muted ms-1">{description}</span> : null}
          <i className={`text-secondary fa fa-fw ms-auto ${isOpen ? 'fa-minus' : 'fa-plus'}`} />
        </Button>

        {headerButtons}
      </ButtonGroup>
      {/* key is mountOnEnter since we want to reload the component if this prop changes */}
      <Collapse isOpen={isOpen && !disabled} mountOnEnter={mountOnEnter} key={mountOnEnter}>
        <CardBody>{renamedChildren}</CardBody>
      </Collapse>
    </Card>
  );
};

Group.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  header: PropTypes.node,
  headerButtons: PropTypes.node,
  disabled: PropTypes.bool,
  useDragHandle: PropTypes.bool,
  icon: PropTypes.string,
  description: PropTypes.string,
  isWithinArray: PropTypes.bool, // these variables might be inserted by ArrayObject field
  parentName: PropTypes.string,
  mountOnEnter: PropTypes.bool
};
Group.defaultProps = {
  open: false,
  color: 'light',
  header: '',
  className: '',
  disabled: false,
  headerButtons: null,
  useDragHandle: false,
  icon: '',
  description: '',
  isWithinArray: false,
  parentName: '',
  mountOnEnter: true
};

export default Group;
