import { FC, Fragment, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from '../Atoms';
import maxHeightDropdownModifier from '../../utils/maxHeightDropdownModifier';

export type BreadcrumbItemListType = { label: string; path: LocationDescriptor; id: string; group?: string }[];

interface BreadcrumbItemProps {
  title: string;
  list?: BreadcrumbItemListType;
  activeId?: string;
  activeLink?: boolean | LocationDescriptor;
}

const BreadcrumbSection: FC<BreadcrumbItemProps> = ({ title, list = [], activeId, activeLink = false }) => {
  const active = list.find(item => item.id === activeId);
  const activeRef = useRef<HTMLElement>(null);

  const getActive = () => {
    if (!active) return null;

    const className = 'px-1 fw-bolder text-truncate';

    if (activeLink) {
      return (
        <Link to={activeLink === true ? active.path : activeLink} className={`${className} text-dark`}>
          <u>{active.label}</u>
        </Link>
      );
    }

    return <span className={`${className} text-muted`}>{active.label}</span>;
  };

  const onToggleHandler = useCallback((_, isOpen) => {
    if (isOpen) {
      const timer = setTimeout(() => {
        activeRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        clearTimeout(timer);
      }, 0);
    }
  }, []);

  return (
    <li className="breadcrumb-item px-2">
      <div className="d-flex flex-column justify-content-center">
        {list.length === 0 || !activeId ? (
          <span className="text-secondary text-capitalize">{title}</span>
        ) : (
          <UncontrolledDropdown onToggle={onToggleHandler} className="d-flex align-content-center">
            <DropdownToggle
              tag="button"
              data-toggle="dropdown"
              size="sm"
              color="link"
              className="btn btn-outline-primary btn-sm px-1 py-0 border-0"
            >
              {title} <small>&#9660;</small>
            </DropdownToggle>
            <DropdownMenu modifiers={[maxHeightDropdownModifier]}>
              {list.map((item, index) => {
                let groupHeader = null;

                if ((index === 0 && item.group) || (index > 0 && item.group && item.group !== list[index - 1].group)) {
                  groupHeader = (
                    <DropdownItem className="ps-2" key={item.id} header>
                      {item.group}
                    </DropdownItem>
                  );
                }

                return (
                  <Fragment key={item.id}>
                    {groupHeader}
                    <DropdownItem tag={Link} to={item.path} active={item.id === activeId}>
                      <span ref={item.id === activeId ? activeRef : null}>{item.label}</span>
                    </DropdownItem>
                  </Fragment>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {getActive()}
      </div>
    </li>
  );
};

export default BreadcrumbSection;
