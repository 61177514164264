import { useMemo } from 'react';
import { PartType } from 'types';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';
import { GraphEdgeShape } from '../../../Graph/GraphEdge/GraphEdge';
import { GraphNodeShape } from '../../../Graph/GraphNode/GraphNode';
import PartCard, { PART_HEADER_HEIGHT } from '../PartCard';
import partGraph from '../partGraphStyles';
import { selectPartsUnsavedPositions } from '../../../../modules/parts/partsSelectors';

export const sortPartsByGraphY =
  (mergedPositions: Record<string, { x: number; y: number }>) => (partA: PartType, partB: PartType) => {
    const aY = mergedPositions[partA._id].y || 0;
    const bY = mergedPositions[partB._id].y || 0;

    return aY - bY;
  };

/** Values to use when calculating parts heights, y positions and offsets  */
export const partNodeHeights = {
  GROUP_PADDING: 5, // replicated in css
  GROUP_BORDER_WIDTH: 5, // replicated in css
  NODE_BORDER_WIDTH: 1,
  GAP: 5, // replicated in css
  HEADER: PART_HEADER_HEIGHT, // two lines
  CONTROL: 40, // comes from reactstraps ListItem,
  CONTROL_BORDER: 1
};

export const getPartHeight = (part: PartType) => {
  return (
    partNodeHeights.HEADER + // header
    partNodeHeights.CONTROL * (1 + (part.Controls?.Object?.length || 0)) + // controls and Add Control
    partNodeHeights.CONTROL_BORDER * (part.Controls?.Object?.length || 0) +
    partNodeHeights.NODE_BORDER_WIDTH * 2 // up and down node borders
  );
};

const useGroupedGraph = <T extends PartType & { populated: { isOrphan: boolean } }>(
  parts: T[],
  onOpenPart: (id: string) => void,
  onOpenRaw: (id: string) => void,
  onDelete: (id: string) => void,
  rootNodeId?: string
) => {
  const unsavedPositions = useSelector(selectPartsUnsavedPositions);

  /** Merge unsaved and graph positions to one object for easier access later.
   * Returns map by partId */
  const mergedPositions = parts.reduce<Record<string, { x: number; y: number }>>((result, part) => {
    const { Config: { graphX = 0, graphY = 0 } = {}, _id } = part;

    // eslint-disable-next-line no-param-reassign
    result[_id] = { x: unsavedPositions[_id]?.graphX ?? graphX, y: unsavedPositions[_id]?.graphY ?? graphY };

    return result;
  }, {});

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return useMemo(() => {
    const nodes: GraphNodeShape[] = [];
    const edges: GraphEdgeShape[] = [];

    // make nodes per each referenceName, id being the referenceName
    const partsMap: Record<string, { list: T[] }> = {};

    parts.forEach(part => {
      partsMap[part.ReferenceName] = partsMap[part.ReferenceName] || { list: [] };
      partsMap[part.ReferenceName].list.push(part);
    });

    // sort partsmap lists by y coordinate

    const sortedReferenceList = Object.entries(partsMap).map(([reference, { list }]) => {
      return { reference, list: [...list].sort(sortPartsByGraphY(mergedPositions)) };
    });

    // Generate coords for each partId for attaching edge source

    // 10 px for border and padding
    // 97 for card header
    // 81 for list item
    // 5px for gap
    // if single then use graphX,graphY

    const coordsMap: Record<string, { x: number; y: number; height: number }> = {};

    sortedReferenceList.forEach(({ list }) => {
      const [first, ...rest] = list;

      const { x, y } = mergedPositions[first._id];

      coordsMap[first._id] = { x, y, height: getPartHeight(first) };

      // calculate each position based on previous
      let previous = first;

      rest.forEach(current => {
        coordsMap[current._id] = {
          x: coordsMap[previous._id].x,
          y: coordsMap[previous._id].y + coordsMap[previous._id].height + partNodeHeights.GAP,
          height: getPartHeight(current)
        };

        previous = current;
      });
    });

    sortedReferenceList.forEach(({ reference, list }) => {
      const { x, y } = mergedPositions[list[0]._id];

      nodes.push({
        id: reference,
        x,
        y,
        pristine: true,
        label: list.length > 1 ? reference : `${reference} ${list[0].Option}`,
        height: list.reduce((result, item) => {
          return result + coordsMap[item._id].height + partNodeHeights.GAP;
        }, (partNodeHeights.GROUP_BORDER_WIDTH + partNodeHeights.GROUP_PADDING) * 2), // base value is two paddings and two borders

        hasParent: !list[0].populated.isOrphan || list[0]._id === rootNodeId,
        className: partGraph('node-wrapper'),
        render: ({ isSelected, onDeselect, onSelect }) => (
          <div className={partGraph('card-group', { multiple: list.length > 1 })}>
            {list.map(part => (
              <PartCard part={part} key={part._id} onDelete={onDelete} onOpen={onOpenPart} onOpenRaw={onOpenRaw} />
            ))}
          </div>
        )
      });
    });

    // define edges

    parts.forEach(part => {
      const { Position: children = [], ReferenceName: reference, _id } = part;

      const edgeMap: Record<string, GraphEdgeShape> = {};

      children.forEach(child => {
        if (!edgeMap[child.Reference] && partsMap[child.Reference]) {
          const [firstTarget] = partsMap[child.Reference].list;

          edgeMap[child.Reference] = {
            id: nanoid(),
            source: reference,
            target: child.Reference,
            sourcePosition: {
              x: coordsMap[_id].x || 0,
              y: coordsMap[_id].y || 0
            },
            targetPosition: {
              x: coordsMap[firstTarget._id].x || 0,
              y: coordsMap[firstTarget._id].y || 0
            }
          };
        }
      });

      edges.push(...Object.values(edgeMap));
    });

    // make edges between part reference and position

    return { nodes, edges, coordsMap, mergedPositions };
  }, [mergedPositions, onDelete, onOpenPart, onOpenRaw, parts, rootNodeId]);
};

export default useGroupedGraph;
