import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import b from 'b_';
import { compose } from 'redux';
import { partnersActions, partnersSelectors } from '../../../modules/partners';
import { TYPE_PARTNER_LIST, TYPE_SUGGESTION_LIST } from '../../../utils/propTypes';
import { RIGHTS } from '../../../utils/rights';
import { withUserRights } from '../../UserRights';
import Loader from '../../Loader';
import MultiSelectFilter from '../../MultiSelectFilter';
import { defaultFilterMethod, multiSelectFilterMethod } from '../../../utils/helpers/table';
import { getPartnerUrl } from '../../../utils/appRoutes';

const partnerList = b.with('partner-list');

export class PartnerList extends React.PureComponent {
  state = {
    filtered: [
      { id: 'projects', value: [] },
      { id: 'users', value: [] }
    ]
  };

  componentDidMount() {
    this.props.fetchPartners();
  }

  handleFilterChange = filtered => {
    this.setState({ filtered });
  };

  handleBadgeClick = e => {
    const { value, key } = e.currentTarget.dataset;

    this.setState(prevState => ({
      filtered: prevState.filtered.map(filter =>
        filter.id === key ? { ...filter, value: [...new Set([...filter.value, value])] } : filter
      )
    }));
  };

  renderBadgeCell = (row, key = 'name') =>
    row.value.sort().map(item => (
      <button
        type="button"
        key={item._id}
        className="app-badge app-badge_purple"
        onClick={this.handleBadgeClick}
        data-value={item._id}
        data-key={row.column.id}
      >
        {item[key]}
      </button>
    ));

  renderMultiSelectFilter = ({ filter, onChange }, options = [], name) => {
    return <MultiSelectFilter options={options} filter={filter} onChange={onChange} name={name} />;
  };

  render() {
    const { partners, users, projects, request, hasRights } = this.props;
    const { filtered } = this.state;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        width: 180,
        className: 'fw-bold p-2',
        Cell: row =>
          hasRights([RIGHTS.PARTNERS__UPDATE]) ? (
            <Link to={getPartnerUrl(row.original._id)} title="Edit partner">
              {row.value}
            </Link>
          ) : (
            row.value
          )
      },
      {
        Header: 'Code',
        accessor: 'code',
        width: 90,
        className: 'p-2'
      },
      {
        Header: 'Integration',
        accessor: 'integrationType',
        width: 80,
        className: 'p-2 text-center',
        Cell: row => (row.value ? <i className="fa fa-fw fa-cogs text-info" title={row.value} /> : null)
      },
      {
        Header: 'API Key',
        accessor: 'hasPublicAPIKey',
        width: 80,
        className: 'p-2 text-center',
        filterable: false,
        Cell: row =>
          row.value ? <i className="fa fa-fw fa-check text-info" title="This partner has access to public API" /> : null
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        width: 170,
        className: 'p-2'
      },
      {
        Header: 'Domain',
        accessor: 'domain',
        width: 250,
        className: 'p-2'
      },
      {
        id: 'projects',
        Header: 'Projects',
        accessor: 'populated.projects',
        className: 'app-entity-list__cell_badge',
        minWidth: 150,
        Cell: row => this.renderBadgeCell(row),
        Filter: filter => this.renderMultiSelectFilter(filter, projects, 'project'),
        filterMethod: multiSelectFilterMethod
      },
      {
        id: 'users',
        Header: 'Users',
        accessor: 'populated.users',
        className: 'app-entity-list__cell_badge',
        minWidth: 150,
        Cell: row => this.renderBadgeCell(row, 'username'),
        Filter: filter => this.renderMultiSelectFilter(filter, users, 'user'),
        filterMethod: multiSelectFilterMethod
      }
    ];

    return (
      <Loader loading={request}>
        <ReactTable
          data={partners}
          pageSize={partners.length}
          showPagination={false}
          columns={columns}
          filtered={filtered}
          onFilteredChange={this.handleFilterChange}
          defaultFilterMethod={defaultFilterMethod}
          defaultSorted={[{ id: 'name', desc: false }]}
          getTheadFilterThProps={() => ({ style: { position: 'inherit', overflow: 'inherit' } })}
          filterable
          resizable={false}
          className={`${partnerList()} app-entity-list -striped -highlight`}
        />
      </Loader>
    );
  }
}

PartnerList.defaultProps = {
  partners: [],
  users: [],
  projects: [],
  request: false
};

PartnerList.propTypes = {
  fetchPartners: PropTypes.func.isRequired,
  hasRights: PropTypes.func.isRequired,
  partners: TYPE_PARTNER_LIST,
  users: TYPE_SUGGESTION_LIST,
  projects: TYPE_SUGGESTION_LIST,
  request: PropTypes.bool
};

const mapStateToProps = state => ({
  partners: partnersSelectors.selectPartnerList(state),
  users: partnersSelectors.selectUserListForFilter(state),
  projects: partnersSelectors.selectProjectListForFilter(state),
  request: partnersSelectors.selectPartnersRequest(state)
});

const mapDispatchToProps = {
  fetchPartners: partnersActions.fetchPartners
};

export default compose(withUserRights, connect(mapStateToProps, mapDispatchToProps))(PartnerList);
