import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Field, Form as RFForm } from 'react-final-form';
import isEmail from 'validator/es/lib/isEmail';
import CreatableSelect from 'react-select/creatable';
import { Button, Col, RSForm, FormFeedback, FormGroup, Label, Row } from '../../Atoms';
import { TYPE_PARTNER } from '../../../utils/propTypes';
import FormField from '../../FormField';

import './EmailTemplatesForm.scss';

const emailTemplatesForm = b.with('email-templates-form');

const EMAIL_SETTINGS = [
  { key: 'address', label: 'Sender address', type: 'email', placeholder: 'Sender address', required: true },
  { key: 'name', label: 'Sender name', type: 'text', placeholder: 'Sender name', required: true }
];

const VISITOR_TEMPLATES = [
  {
    key: 'requestTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'Request',
    templateDescription: 'Sent to visitor when they send a request'
  },
  {
    key: 'requestPDFSummaryTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'Request PDF',
    templateDescription: 'Sent to visitor with the requested PDF'
  },
  {
    key: 'instanceTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'Instance',
    templateDescription: 'Sent to visitor when they save an instance'
  },
  {
    key: 'orphanInstanceTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'Orphan instance',
    templateDescription: 'Sent to visitor when they save an instance without authentication'
  }
];

const CLIENT_TEMPLATES = [
  {
    key: 'requestNotificationTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'RequestNotification',
    recipients: true,
    templateDescription: 'Notification to client when they get a new request'
  },
  {
    key: 'crmProductionTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'Production',
    recipients: true,
    templateDescription: 'Notification to client when an instance is sent to production from CRM'
  },
  {
    key: 'crmCancelOrderTemplate',
    placeholder: 'Number',
    type: 'number',
    label: 'CancelOrder',
    recipients: true,
    templateDescription: 'Notification to client when an instances SendToProduction is cancelled from CRM'
  },
  {
    key: 'crmPlotCutOffDate',
    placeholder: 'Number',
    type: 'number',
    label: 'PlotCutOffDate',
    recipients: true,
    templateDescription:
      'Used only in Nuliving CRM integration! Notification to client when the Plot Cutoff Date is approaching'
  }
];

const ERRORS = {
  required: 'Required'
};

class EmailTemplatesForm extends React.PureComponent {
  selectTheme = (theme, meta) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral20: meta.valid ? theme.colors.neutral20 : '#dc3545'
    }
  });

  validateForm = values => {
    const errors = { emailFrom: {}, emailTo: {} };

    EMAIL_SETTINGS.forEach(({ required, key }) => {
      if (required && (!values.emailFrom || !values.emailFrom[key])) {
        errors.emailFrom[key] = ERRORS.required;
      }
    });

    CLIENT_TEMPLATES.forEach(({ key }) => {
      if (
        values.emailTemplates &&
        values.emailTemplates[key] &&
        (!values.emailTo[key] || !values.emailTo[key].length)
      ) {
        errors.emailTo[key] = ERRORS.required;
      }
    });

    return errors;
  };

  renderSelect = field => (
    <FormGroup>
      <Label for={field.key}>{field.label}</Label>
      <Field
        name={field.key}
        render={({ input, meta }) => (
          <>
            <CreatableSelect
              isMulti
              name={field.key}
              className={emailTemplatesForm('recipients', { invalid: !meta.valid })}
              value={(input.value || []).map(value => ({ value, label: value }))}
              onChange={value => input.onChange(value.map(item => item.value) || [])}
              theme={theme => this.selectTheme(theme, meta)}
              getNewOptionData={value => (isEmail(value) ? { label: value, value } : undefined)}
              options={[]}
            />
            <FormFeedback>{meta.error}</FormFeedback>
          </>
        )}
      />
    </FormGroup>
  );

  renderTemplates = field => (
    <Row key={field.key}>
      <Col>
        <h5>{field.label}</h5>
        {field.templateDescription ? <small className="text-muted">{field.templateDescription}</small> : null}
        <Row>
          <Col md={3}>
            <FormField
              field={{ ...field, key: `emailTemplates.${field.key}`, label: 'Id' }}
              parse={value => (value ? Number(value) : undefined)}
            />
          </Col>
          <Col md={3}>
            <FormField
              field={{
                ...field,
                key: `emailSenders.${field.key}`,
                label: 'Optional sender',
                type: 'email',
                text: '',
                placeholder: 'E-mail'
              }}
            />
          </Col>
          <Col md={6}>
            {field.recipients
              ? this.renderSelect({ ...field, key: `emailTo.${field.key}`, label: 'Recipients' }, 'email')
              : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  renderForm = ({ handleSubmit, form, submitting, pristine }) => (
    <RSForm className={emailTemplatesForm()} onSubmit={handleSubmit} autoComplete="off">
      <div className={`${emailTemplatesForm('buttons')} mb-2`}>
        <Button
          type="submit"
          color="primary"
          disabled={submitting || pristine || !form.getState || form.getState().invalid}
          outline
        >
          Save
        </Button>
        <Button onClick={form.reset} disabled={submitting || pristine} outline>
          Reset
        </Button>
      </div>
      <div className={`${emailTemplatesForm('fields')}`}>
        <p className="text-muted">
          Transactional email will be sent from this address and with this name using the MailJet template with the
          corresponding Id. Sender address can be overridden separately for each template.
        </p>
        <Row>
          {EMAIL_SETTINGS.map(field => (
            <Col md={6} key={field.key}>
              <FormField field={{ ...field, key: `emailFrom.${field.key}` }} />
            </Col>
          ))}
        </Row>
        <h3 className="text-primary">Visitor templates</h3>
        {VISITOR_TEMPLATES.map(this.renderTemplates)}
        <h3 className="text-primary">Client templates</h3>
        {CLIENT_TEMPLATES.map(this.renderTemplates)}
      </div>
    </RSForm>
  );

  render() {
    const { partner, onSubmit } = this.props;

    return <RFForm onSubmit={onSubmit} initialValues={partner} render={this.renderForm} validate={this.validateForm} />;
  }
}

EmailTemplatesForm.defaultProps = {
  onSubmit: () => null
};

EmailTemplatesForm.propTypes = {
  partner: TYPE_PARTNER, // eslint-disable-line react/require-default-props
  onSubmit: PropTypes.func
};

export default EmailTemplatesForm;
