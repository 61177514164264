import { combineReducers } from '@reduxjs/toolkit';
import { api } from '../store/services/api';
import { uiSettings } from '../store/modules/uiSettings';
import auth from './auth';
import factoryMaps from './factoryMaps';
import iframeScripts from './iframeScripts';
import instances from './instances';
import invitations from './invitations';
import mappedInstances from './mappedInstances';
import partners from './partners';
import parts from './parts';
import plots from './plots';
import roles from './roles';
import settings from './settings';
import ui from './ui';
import statistics from './statistics';
import users from './users';
import assets from './assets';

const rootReducer = combineReducers({
  auth,
  factoryMaps,
  iframeScripts,
  instances,
  invitations,
  mappedInstances,
  partners,
  parts,
  plots,
  roles,
  settings,
  ui,
  statistics,
  users,
  uiSettings,
  textures: assets.textures,
  images: assets.images,
  models: assets.models,
  [api.reducerPath]: api.reducer
});

export default rootReducer;

declare module 'types' {
  export type RootStateType = ReturnType<typeof rootReducer>;
}
