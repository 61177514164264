import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { assetsActions } from '../../modules/assets';
import { useProject } from '../../store/project';
import { useSeeds } from '../../store/seed';
import { usePartners } from '../../modules/partners/partnersHooks';
import SelfServiceProject from './SelfServiceProject';

const SelfServiceProjectPage = ({ match }) => {
  const { projectId } = match.params;
  const { project } = useProject(projectId);

  const dispatch = useDispatch();

  useSeeds();
  usePartners();

  useEffect(() => {
    if (project) {
      dispatch(assetsActions.images.fetchList(project._id));
    }
  }, [dispatch, project]);

  return project ? <SelfServiceProject project={project} /> : null;
};

SelfServiceProjectPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default SelfServiceProjectPage;
