import React from 'react';
import { Link } from 'react-router-dom';
import SendInvitationButton from '../SendInvitationButton';
import { Page, PageHeader, PageContent } from '../Page';
import ProtectedButton from '../ProtectedButton';
import { RIGHTS } from '../../utils/rights';
import { ApplicationRoute } from '../../utils/appRoutes';
import UserList from './UserList';

const UsersPage = () => (
  <Page>
    <PageHeader>
      <div className="d-flex flex-row justify-content-end flex-wrap mb-2">
        <div className="d-flex flex-row align-items-center">
          <ProtectedButton
            rights={[RIGHTS.INVITATIONS__READ]}
            tag={Link}
            to={ApplicationRoute.Invitations}
            color="link"
            size="sm"
          >
            Show invitations
          </ProtectedButton>
          <SendInvitationButton />
        </div>
      </div>
    </PageHeader>
    <PageContent className="pt-2">
      <UserList />
    </PageContent>
  </Page>
);

export default UsersPage;
