import { FC, ReactNode } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { TemplateSourceType, TemplateType } from 'types';
import { useTemplate } from '../../store/template';
import { usePartners } from '../../modules/partners/partnersHooks';
import Loader from '../Loader';
import { getTemplatesUrl } from '../../utils/appRoutes';
import { Container } from '../Atoms';

interface TemplatePageWrapperProps {
  children: (template: TemplateType, partnerId: string, templateId: string, source: TemplateSourceType) => ReactNode;
}

const TemplatePageWrapper: FC<TemplatePageWrapperProps> = ({ children }) => {
  const { partnerId, templateId, source } =
    useParams<{ partnerId: string; templateId: string; source: TemplateSourceType }>();

  const { template, isInProgress } = useTemplate(partnerId, templateId);

  usePartners();

  return (
    <Loader loading={isInProgress}>
      {!template ? (
        <Redirect to={getTemplatesUrl(partnerId)} />
      ) : (
        <div className="bg-light py-3 flex-grow-1">
          <Container type="lg">{children(template, partnerId, templateId, source)}</Container>
        </div>
      )}
    </Loader>
  );
};

export default TemplatePageWrapper;
