import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field as RFField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { SortableElement } from 'react-sortable-hoc';
import { Alert, Button } from '../../Atoms';
import { TYPE_VIEW } from '../../../utils/propTypes';
import Form from '../../Form';
import Field from '../../Form/Field';
import { seedsSelectors } from '../../../modules/seeds';
import { partsActions, partsSelectors } from '../../../modules/parts';
import getPartFieldsToUpdate from '../../../utils/getPartFieldsToUpdate';
import PartPreview from './PartPreview';
import { VIEW_TYPES_SUGGESTIONS } from './viewTypes';

const SortableGroup = SortableElement(Form.Group);

const removeViewFromPart = (part, name) => {
  const newPart = { ...part };

  if (Array.isArray(newPart.Views?.list)) {
    newPart.Views.list = [...part.Views.list];

    const viewIndex = newPart.Views.list.findIndex(item => item.name === name);

    if (viewIndex !== -1) {
      newPart.Views.list.splice(viewIndex, 1);
    }
  }

  return newPart;
};

const updatePartView = (part, formValue, name) => {
  const newPart = { ...part };

  if (!newPart.Views) {
    newPart.Views = { list: [] };
  }

  if (!Array.isArray(newPart.Views.list)) {
    newPart.Views.list = [];
  }

  let index = newPart.Views.list.findIndex(item => item.name === name);

  index = index !== -1 ? index : newPart.Views.list.length;

  newPart.Views.list[index] = formValue.view;

  return newPart;
};

const useViewFormCallbacks = (seedId, view, parts) => {
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(view);
  const [pristine, setPristine] = useState(true);

  const handleChangeForm = useCallback(form => {
    setPristine(form.pristine);
    setFormValue(form.values);
  }, []);

  const handleSubmitForm = useCallback(
    value => {
      const partsToSave = [];

      if (value.partId !== view.partId) {
        const source = parts.find(part => part._id === view.partId);

        if (source) {
          partsToSave.push(removeViewFromPart(source, view.value));
        }
      }

      const target = parts.find(part => part._id === value.partId);

      if (target) {
        partsToSave.push(updatePartView(target, value, view.value));
      }

      if (partsToSave.length) {
        dispatch(
          partsActions.updateParts(
            seedId,
            partsToSave.map(part => getPartFieldsToUpdate(part, ['Views']))
          )
        );
      }
    },

    [dispatch, parts, seedId, view.partId, view.value]
  );

  const handleDeleteView = useCallback(() => {
    const part = parts.find(({ _id }) => _id === view.partId);
    // eslint-disable-next-line no-alert
    const response = window.confirm(`Are you sure that you want to delete view "${view.label}"?`);

    if (!response) {
      return;
    }

    if (Array.isArray(part?.Views?.list)) {
      const viewIndex = part.Views.list.findIndex(item => item.name === view.value);

      if (viewIndex !== -1) {
        const newList = [...part.Views.list];

        newList.splice(viewIndex, 1);
        const newPart = { ...part, Views: { ...part.Views, list: newList } };

        dispatch(partsActions.updatePart(getPartFieldsToUpdate(newPart, ['Views'])));
      }
    }
  }, [dispatch, parts, view.label, view.partId, view.value]);

  return { handleChangeForm, handleSubmitForm, handleDeleteView, pristine, formValue };
};

const roundReducer = (result, [key, value]) => {
  // eslint-disable-next-line no-param-reassign
  result[key] = Math.floor(value + 0.5);

  return result;
};

const ViewForm = ({ index, seedId, view }) => {
  const setCameraPosition = useRef(null);
  const setCameraTarget = useRef(null);
  const header = view.label;
  const rootPartId = useSelector(state => seedsSelectors.selectRootPartId(state, seedId));
  const parts = useSelector(state => partsSelectors.selectPartListBySeedMemoized(state, seedId));
  const partsForSuggestions = useSelector(state => partsSelectors.selectPartListBySeedForSuggestions(state, seedId));
  const sunlights = useSelector(state => partsSelectors.selectSunlightsListForSuggestions(state, seedId));
  const skylights = useSelector(state => partsSelectors.selectSkylightsListForSuggestions(state, seedId));
  const customLights = useSelector(state => partsSelectors.selectCustomLightsListForSuggestions(state, seedId));
  const isParentRoot = rootPartId === view.partId;
  const { pristine, handleChangeForm, handleSubmitForm, handleDeleteView, formValue } = useViewFormCallbacks(
    seedId,
    view,
    parts
  );
  const handleUpdateCamera = useCallback(({ cameraPosition, cameraTarget }) => {
    const newPosition = Object.entries(cameraPosition).reduce(roundReducer, {});
    const newTarget = Object.entries(cameraTarget).reduce(roundReducer, {});

    if (setCameraPosition.current) {
      setCameraPosition.current(newPosition);
    }

    if (setCameraTarget.current) {
      setCameraTarget.current(newTarget);
    }
  }, []);

  return (
    <SortableGroup
      header={header}
      index={index}
      useDragHandle
      className={isParentRoot ? '' : 'ms-2'}
      color={pristine ? 'light' : 'primary'}
      icon={view.view.hidden ? 'fa-eye-slash me-1 text-muted' : 'fa-eye me-1 text-info'}
    >
      <Form
        initialValues={view}
        onChange={handleChangeForm}
        onSubmit={handleSubmitForm}
        useBottomButtons
        customButton={
          <Button color="danger" className="pull-right" onClick={handleDeleteView}>
            <i className="fa fa-fw fa-trash-o" />
            Delete
          </Button>
        }
      >
        <Field.Select
          name="partId"
          label="View parent part"
          description="View will be visible if its parent part is loaded"
          options={partsForSuggestions}
        />
        {isParentRoot ? null : (
          <Alert color="info">
            Selected part is not a root part - view will be shown only if the selected part is loaded
          </Alert>
        )}
        <Field.Text name="view.name" label="View name" description="Used to identify the view in settings" required />
        <Field.Text
          name="view.displayName"
          guide="view.name"
          label="Display name"
          description="Displayed in the view picker widget. Shouldn’t be longer than 3 characters"
        />
        <Field.Text
          name="view.description"
          label="Description"
          description="Displayed in a tooltip next to the view button"
        />
        <Field.Toggle
          name="view.hidden"
          label="Hide view"
          description="Hide View from view buttons in Configurator"
          idPrefix={header}
        />
        <Field.Toggle
          name="view.summaryGallery"
          label="Summary gallery"
          description="Add a snapshot from that view to Summary gallery"
          idPrefix={header}
        />
        <Field.Toggle
          name="view.pdfGallery"
          label="PDF gallery"
          description="Add a snapshot from that view to PDF file"
          idPrefix={header}
        />
        <RFField
          name="view"
          render={({ input }) => {
            if (!(input.value.pdfGallery || input.value.summaryGallery)) return null;

            return (
              <>
                <Field.Number
                  name={`${input.name}.snapshotHeight`}
                  label="Snapshot height"
                  description="Used for rendering galleries"
                />
                <Field.Number
                  name={`${input.name}.snapshotWidth`}
                  label="Snapshot width"
                  description="Used for rendering galleries"
                />
              </>
            );
          }}
        />

        <h5>Camera position</h5>
        <Field.Select name="view.type" label="Camera type" options={VIEW_TYPES_SUGGESTIONS} />
        <small className="text-muted">
          Rhino coordinates are: <strong>x</strong>, <strong>y</strong>, <strong>z</strong>. Corresponding Creator
          coordinates are: <strong>x</strong>, <strong>z</strong>, <strong>-y</strong>
        </small>
        <RFField
          name="view.cameraPosition"
          render={({ input }) => {
            if (!setCameraPosition.current) {
              setCameraPosition.current = input.onChange;
            }

            return (
              <>
                <Field.Number name={`${input.name}.x`} label="Camera X" description="Rhino Viewport position X" />
                <Field.Number name={`${input.name}.z`} label="Camera Z" description="Rhino Viewport position -Y" />
                <Field.Number name={`${input.name}.y`} label="Camera Y" description="Rhino Viewport position Z" />
              </>
            );
          }}
        />
        <RFField
          name="view.cameraTarget"
          render={({ input }) => {
            if (!setCameraTarget.current) {
              setCameraTarget.current = input.onChange;
            }

            return (
              <>
                <Field.Number name={`${input.name}.x`} label="Target X" description="Rhino Viewport target X" />
                <Field.Number name={`${input.name}.z`} label="Target Z" description="Rhino Viewport target -Y" />
                <Field.Number name={`${input.name}.y`} label="Target Y" description="Rhino Viewport target Z" />
              </>
            );
          }}
        />
        <Field.Toggle
          name="view.disableCameraAnimation"
          label="Disable camera animation"
          description="Disable camera animation to and from this view"
          idPrefix={header}
        />
        <Field.Toggle
          name="view.isRelative"
          label="Relative coordinates"
          description="Relative to parent part transform matrix"
          idPrefix={header}
        />
        <Field.Toggle
          name="view.disablePartAnimation"
          label="Disable part animation"
          description="Disable part fade-in while this view is active. This has no effect if part animation is not enabled in settings"
          idPrefix={header}
        />
        <h5>Clipping plane</h5>
        <Field.Toggle name="view.clippingPlane.enabled" label="Enable clipping plane" idPrefix={header} />
        <Field.Number name="view.clippingPlane.normal.x" label="Normal X" />
        <Field.Number name="view.clippingPlane.normal.y" label="Normal Y" />
        <Field.Number name="view.clippingPlane.normal.z" label="Normal Z" />
        <Field.Number name="view.clippingPlane.origin.x" label="Origin X" />
        <Field.Number name="view.clippingPlane.origin.y" label="Origin Y" />
        <Field.Number name="view.clippingPlane.origin.z" label="Origin Z" />
        <h5>Lights</h5>
        <Field.Toggle name="view.lights.sunlight.disabled" label="Disable sunlight" idPrefix={header} />
        <Field.Select
          name="view.lights.sunlight.name"
          label="Pick sunlight"
          description="You can defined sunlights in Lights section. If none is selected, will use default one"
          options={sunlights}
        />
        <Field.Toggle name="view.lights.skylight.disabled" label="Disable skylight" idPrefix={header} />
        <Field.Select
          name="view.lights.skylight.name"
          label="Pick skylight"
          description="You can defined skylights in Lights section. If none is selected, will use default one"
          options={skylights}
        />
        <Field.MultiSelect
          name="view.lights.list"
          label="Additional lights"
          description="Will add to default sunlight and skylight defined above"
          options={customLights}
        />
        <Form.Group header="Additional parameters">
          <Field.Number name="view.fov" label="Field of view (default 45)" min={0} max={360} allowEmpty />
          <Field.Number
            name="view.near"
            label="Distance to near plane."
            description="Imperial values should be around 25 times smaller (inches vs millimetres)."
            min={0}
            allowEmpty
          />
          <Field.Number
            name="view.far"
            label="Distance to far plane"
            description="Imperial values should be around 25 times smaller (inches vs millimetres)."
            min={0}
            allowEmpty
          />
        </Form.Group>
        <hr />
      </Form>
      <PartPreview seedId={seedId} onUpdate={handleUpdateCamera} view={formValue.view} />
    </SortableGroup>
  );
};

ViewForm.propTypes = {
  index: PropTypes.number.isRequired,
  seedId: PropTypes.string.isRequired,
  view: TYPE_VIEW.isRequired
};

export default ViewForm;
