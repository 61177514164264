import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rolesActions, rolesSelectors } from '../../../modules/roles';
import RoleForm from '../RoleForm';
import { RIGHTS } from '../../../utils/rights';
import CreationModal from '../../CreationModal';

export const RoleCreation = () => {
  const dispatch = useDispatch();
  const onCreate = useCallback(
    role => {
      dispatch(rolesActions.createRole(role));
    },
    [dispatch]
  );
  const onReset = useCallback(() => {
    dispatch(rolesActions.resetFlags());
  }, [dispatch]);
  const created = useSelector(rolesSelectors.selectRoleCreatedFlag);

  return (
    <CreationModal
      button="Create new role"
      header="Add a new role"
      rights={[RIGHTS.ROLES__CREATE]}
      onCreate={onCreate}
      created={created}
      resetFlags={onReset}
    >
      {({ setForm }) => <RoleForm onChange={setForm} isNewRole />}
    </CreationModal>
  );
};

export default RoleCreation;
