import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export interface SkyBoxProps {
  projectTextures?: { name: string; url: string }[];
  uploadTexture: () => void;
  updateTexturesAfterUpload: () => void;
}

export const SkyBox = ({ projectTextures, uploadTexture, updateTexturesAfterUpload }: SkyBoxProps) => {
  return (
    <>
      <FormField.Toggle<ProjectType>
        name="settings.skybox.enabled"
        label="Enable skybox"
        description="Will show background as defined by skybox texture"
        useShadowValue
      />
      <FormField.Toggle<ProjectType>
        name="settings.skybox.useAsEnvMap"
        label="Use skybox textures as environment map"
        description="Will enable reflections based on skybox texture"
        useShadowValue
      />
      <FormField.Image<ProjectType>
        name="settings.skybox.equirectangular.Texture.fileName"
        label="Skybox"
        description="Equirectangular texture for skybox"
        list={projectTextures}
        header="Select texture"
        uploadImage={uploadTexture}
        onUploadSuccess={updateTexturesAfterUpload}
      />
    </>
  );
};
