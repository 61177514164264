import React, { useMemo } from 'react';
import { Alert } from '../../Atoms';
import { TYPE_PROJECT } from '../../../utils/propTypes';
import Loader from '../../Loader';
import { useAssetsActions, useParsedQueryParams } from '../../../utils/hooks';
import SelfServiceControls from '../SelfServiceControls';
import SelfServiceProjectForm from '../SelfServiceProjectForm';
import SelfServicePricing from '../SelfServicePricing';
import { RIGHTS } from '../../../utils/rights';
import TabNavigation from '../../TabNavigation';
import TabContent from '../../TabContent';
import SelfServiceRemoteSwitches from '../SelfServiceRemoteSwitches';
import { useSeed } from '../../../store/seed';

const SELF_SERVICE_SCREENS = {
  CONTROLS: 'controls',
  SWITCHES: 'switches',
  SETTINGS: 'settings',
  PRICING: 'prices'
};

const SelfServiceProject = ({ project }) => {
  const { screen: selectedScreen = SELF_SERVICE_SCREENS.SETTINGS } = useParsedQueryParams();

  const { seed: publicSeed, isInProgress } = useSeed(project.publicSeedId);
  const { updateImagesAfterUpload } = useAssetsActions(project._id);

  const SELF_SERVICE_SCREEN_LIST = useMemo(
    () => [
      {
        name: SELF_SERVICE_SCREENS.SETTINGS,
        label: 'Settings',
        icon: 'fa-cog',
        component: () => <SelfServiceProjectForm projectId={project._id} publicSeed={publicSeed} />
      },
      {
        name: SELF_SERVICE_SCREENS.CONTROLS,
        label: 'Controls',
        icon: 'fa-toggle-on',
        component: () => (
          <SelfServiceControls project={project} updateImagesAfterUpload={updateImagesAfterUpload} seed={publicSeed} />
        )
      },
      {
        name: SELF_SERVICE_SCREENS.SWITCHES,
        label: 'Switches',
        icon: 'fa-toggle-on',
        component: () => <SelfServiceRemoteSwitches seed={publicSeed} />,
        rights: [RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]
      },
      {
        name: SELF_SERVICE_SCREENS.PRICING,
        label: 'Prices',
        icon: 'fa-money',
        component: () => <SelfServicePricing seed={publicSeed} />,
        rights: [RIGHTS.SEEDS__PRICING_UPDATE]
      }
    ],
    [project, publicSeed, updateImagesAfterUpload]
  );

  return (
    <div className="flex-grow-1 w-100 p-3">
      <Loader loading={isInProgress}>
        {!project.publicSeedId ? (
          <Alert color="warning">No public seed for this project has been found. Cannot edit</Alert>
        ) : (
          <>
            <h3 className="text-primary">Project: {project.name}</h3>
            <p>
              This is a small self service editor. You can change intro settings, extra info for control options and
              controls here. These changes are applied to currently public seed of selected project.
            </p>
            <TabNavigation routes={SELF_SERVICE_SCREEN_LIST} activeRoute={selectedScreen} />
            <div className="px-1 pt-2">
              <TabContent routes={SELF_SERVICE_SCREEN_LIST} activeScreen={selectedScreen} />
            </div>
          </>
        )}
      </Loader>
    </div>
  );
};

SelfServiceProject.propTypes = {
  project: TYPE_PROJECT.isRequired
};

export default SelfServiceProject;
