import React from 'react';
import Code from '../Code';

const d64 = (base64string = '') => {
  return Buffer.from(base64string, 'base64').toString('ascii');
};

const e64 = (str = '') => {
  return Buffer.from(str).toString('base64');
};

const Css = props => <Code {...props} parse={e64} format={d64} mode="css" theme="xcode" />;

export default Css;
