import Form from './Form';
import Submit from './Submit';
import Reset from './Reset';
import Group from './Group';

Form.Submit = Submit;
Form.Reset = Reset;
Form.Group = Group;

export default Form;
