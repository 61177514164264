import { Col, Icon, PageTile, Row, RoundedBox } from '../Atoms';
import { getSeedUrl } from '../../utils/appRoutes';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';

interface SeedDashboardLinksProps {
  seedId: string;
}

const SeedDashboardLinks = ({ seedId }: SeedDashboardLinksProps) => {
  const hasRights = useUserRights();

  return (
    <PageTile>
      {hasRights([RIGHTS.SEEDS__UPDATE]) ? (
        <>
          <h5>Settings</h5>
          <Row className="border-bottom mb-4">
            <Col className="d-flex pb-4" sm={6} md={4}>
              <RoundedBox link={getSeedUrl(seedId, 'info')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiFileSettingsOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Basic Version Info</u>
                  </div>
                  <small className="text-muted">Version name, description</small>
                </div>
              </RoundedBox>
            </Col>
            <Col className="d-flex pb-4" sm={6} md={4}>
              <RoundedBox link={getSeedUrl(seedId, 'settings')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiFileCogOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Version Settings</u>
                  </div>
                  <small className="text-muted">Applied to this version, will override project settings</small>
                </div>
              </RoundedBox>
            </Col>
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.PARTS__READ]) ? (
        <>
          <h5 className="mt-4">Logic</h5>
          <Row className="border-bottom">
            <Col className="d-flex pb-4" sm={6} md={4}>
              <RoundedBox link={getSeedUrl(seedId, 'graph')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiGraphOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Graph Editor</u>
                  </div>
                  <small className="text-muted">Parts, Controls and Options</small>
                </div>
              </RoundedBox>
            </Col>
            <Col className="d-flex pb-4" sm={6} md={4}>
              <RoundedBox link={getSeedUrl(seedId, 'parts')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiTable" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Table Editor</u>
                  </div>
                  <small className="text-muted">Parts, Controls and Options</small>
                </div>
              </RoundedBox>
            </Col>
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.SEEDS__MATERIALS_UPDATE, RIGHTS.SEEDS__PRICING_UPDATE, RIGHTS.PARTS__UPDATE]) ? (
        <>
          <h5 className="mt-4">Components</h5>
          <Row className="border-bottom">
            {hasRights([RIGHTS.SEEDS__MATERIALS_UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <RoundedBox link={getSeedUrl(seedId, 'materials')}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiPaletteSwatchOutline" />
                  </span>
                  <div>
                    <div>
                      <u className="text-dark fw-bolder">Materials</u>
                    </div>
                    <small className="text-muted">Colors, textures and their settings</small>
                  </div>
                </RoundedBox>
              </Col>
            ) : null}
            {hasRights([RIGHTS.PARTS__UPDATE]) ? (
              <>
                <Col className="d-flex pb-4" sm={6} md={4}>
                  <RoundedBox link={getSeedUrl(seedId, 'tabs')}>
                    <span className="text-primary pe-2">
                      <Icon type="mdiFormatListBulleted" />
                    </span>
                    <div>
                      <div>
                        <u className="text-dark fw-bolder">Phases</u>
                      </div>
                      <small className="text-muted">Steps of user journey</small>
                    </div>
                  </RoundedBox>
                </Col>
                <Col className="d-flex pb-4" sm={6} md={4}>
                  <RoundedBox link={getSeedUrl(seedId, 'views')}>
                    <span className="text-primary pe-2">
                      <Icon type="mdiVideoOutline" />
                    </span>
                    <div>
                      <div>
                        <u className="text-dark fw-bolder">Cameras</u>
                      </div>
                      <small className="text-muted">Point of view</small>
                    </div>
                  </RoundedBox>
                </Col>
                <Col className="d-flex pb-4" sm={6} md={4}>
                  <RoundedBox link={getSeedUrl(seedId, 'lights')}>
                    <span className="text-primary pe-2">
                      <Icon type="mdiLightbulbOnOutline" />
                    </span>
                    <div>
                      <div>
                        <u className="text-dark fw-bolder">Lights</u>
                      </div>
                      <small className="text-muted">Sunlights, Skylights, Custom lights</small>
                    </div>
                  </RoundedBox>
                </Col>
              </>
            ) : null}
            {hasRights([RIGHTS.SEEDS__PRICING_UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <RoundedBox link={getSeedUrl(seedId, 'prices')}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiCash" />
                  </span>
                  <div>
                    <div>
                      <u className="text-dark fw-bolder">Prices</u>
                    </div>
                    <small className="text-muted">Pricing codes, Pricing schemes</small>
                  </div>
                </RoundedBox>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.REMOTE_CONTROLS__UPDATE, RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]) ? (
        <>
          <h5 className="mt-4">Remote</h5>
          <Row className="border-bottom">
            {hasRights([RIGHTS.REMOTE_CONTROLS__UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <RoundedBox link={getSeedUrl(seedId, 'remote-controls')}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiToggleSwitchOutline" />
                  </span>
                  <div>
                    <div>
                      <u className="text-dark fw-bolder">Remote Controls</u>
                    </div>
                    <small className="text-muted">All basics</small>
                  </div>
                </RoundedBox>
              </Col>
            ) : null}
            {hasRights([RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <RoundedBox link={getSeedUrl(seedId, 'remote-switches')}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiToggleSwitchOutline" />
                  </span>
                  <div>
                    <div>
                      <u className="text-dark fw-bolder">Remote Switches</u>
                    </div>
                    <small className="text-muted">All basics</small>
                  </div>
                </RoundedBox>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}
    </PageTile>
  );
};

export default SeedDashboardLinks;
