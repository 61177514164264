import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { compose } from 'redux';
import { withUserRights } from '../../../UserRights';
import { TYPE_INSTANCE } from '../../../../utils/propTypes';
import { RIGHTS } from '../../../../utils/rights';
import ProtectedButton from '../../../ProtectedButton';
import { instancesActions } from '../../../../modules/instances';

import './LegalesignSigner.scss';

const legalesignSigner = b.with('legalesign-signer');

const SIGNATURE_STATUSES = {
  10: <span className="text-info">Sent</span>,
  20: <span className="text-info">Fields completed</span>,
  30: <span className="text-success">Signed</span>,
  40: <span className="text-warning">Removed (before signing)</span>,
  50: <span className="text-danger">Rejected</span>
};

const SIGNER_STATUSES = {
  4: <span className="text-info">Unsent</span>,
  5: <span className="text-info">Scheduled to send</span>,
  10: <span className="text-info">Sent</span>,
  15: <span className="text-info">Email opened</span>,
  20: <span className="text-info">Visited</span>,
  30: <span className="text-info">Fields completed</span>,
  35: <span className="text-info">Fields done except signature fields</span>,
  39: <span className="text-info">Witness to sign</span>,
  40: <span className="text-success">Signed</span>,
  50: <span className="text-success">Downloaded</span>,
  60: <span className="text-danger">Rejected</span>
};

export class LegalesignSigner extends React.PureComponent {
  componentDidMount() {
    const { hasRights } = this.props;

    if (this.hasSignature && !this.isFinallyDocumentStatus && hasRights([RIGHTS.VISITORS__CHECK_LEGALESIGN_STATUS])) {
      this.handleCheckLegalesignStatus();
    }
  }

  get hasSignature() {
    const { instance } = this.props;

    return Boolean(instance.signature);
  }

  get isFinallyDocumentStatus() {
    const { instance } = this.props;

    return this.hasSignature && [30, 40, 50].includes(instance.signature.status);
  }

  get isLeadCancelled() {
    const { instance } = this.props;

    return instance.lead && instance.lead.cancelOrder;
  }

  handleSendDocumentToSign = () => {
    const { sendDocumentToSign, instance } = this.props;

    sendDocumentToSign(instance._id);
  };

  handleCheckLegalesignStatus = () => {
    const { checkLegalesignStatus, instance } = this.props;

    checkLegalesignStatus(instance._id);
  };

  renderSignatureStatus() {
    const { instance } = this.props;

    return SIGNATURE_STATUSES[instance.signature.status] || 'Unknown';
  }

  renderDetails() {
    if (!this.hasSignature) {
      return null;
    }

    const { instance } = this.props;
    const signers = instance.signature.signers || [];

    return (
      <div className={legalesignSigner('details')}>
        <dl className="d-flex flex-wrap mb-0">
          <dt className={legalesignSigner('label')}>Status</dt>
          <dd className={legalesignSigner('info')}>{this.renderSignatureStatus()}</dd>
        </dl>
        <h6 className="text-secondary">Signers</h6>
        <dl className="d-flex flex-wrap mb-0">
          {signers.map(signer => (
            <React.Fragment key={signer.first_name}>
              <dt className={legalesignSigner('label')}>
                {signer.first_name} {signer.last_name}
              </dt>
              <dd className={legalesignSigner('info')}>{SIGNER_STATUSES[signer.status] || signer.status}</dd>
            </React.Fragment>
          ))}
        </dl>
      </div>
    );
  }

  renderActions() {
    if (this.isFinallyDocumentStatus) {
      return null;
    }

    if (this.hasSignature) {
      return (
        <ProtectedButton
          rights={[RIGHTS.VISITORS__CHECK_LEGALESIGN_STATUS]}
          title="Update document status from Legalesign"
          onClick={this.handleCheckLegalesignStatus}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-fw fa-refresh" /> Check document status
        </ProtectedButton>
      );
    }

    return (
      <ProtectedButton
        rights={[RIGHTS.VISITORS__SEND_LEAD_TO_LEGALESIGN]}
        onClick={this.handleSendDocumentToSign}
        disabled={this.isLeadCancelled}
        size="sm"
        color="primary"
        outline
      >
        <i className="fa fa-fw fa-paper-plane-o" /> Send document to sign
      </ProtectedButton>
    );
  }

  render() {
    return (
      <div className={legalesignSigner()}>
        <h5 className="text-primary">Signature</h5>
        {this.renderActions()}
        {this.renderDetails()}
      </div>
    );
  }
}

LegalesignSigner.defaultProps = {};

LegalesignSigner.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  sendDocumentToSign: PropTypes.func.isRequired,
  checkLegalesignStatus: PropTypes.func.isRequired,
  hasRights: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  sendDocumentToSign: instancesActions.sendToLegalesign,
  checkLegalesignStatus: instancesActions.checkLegalesignStatus
};

export default compose(withUserRights, connect(null, mapDispatchToProps))(LegalesignSigner);
