import React from 'react';
import PropTypes from 'prop-types';
import { useField, Field as FField } from 'react-final-form';
import { Button } from 'reactstrap';
import Field from '../../Form/Field';
import { ButtonGroup } from '../../Atoms';

const PriceSchemes = ({ useShadowValue, supportedCurrencyList }) => {
  const headingFormat = value => value.name;

  const useMultiplePriceSchemes = useField('settings.price.useMultiplePriceSchemes');
  const { onChange } = useMultiplePriceSchemes.input;
  const isMultiple = useMultiplePriceSchemes.input.value;

  const getChildrenArray = (prefix, singleMode) => [
    singleMode ? null : (
      <Field.Text
        name={`${prefix}name`}
        label="Unique name"
        description={'Must be unique and not "default"'}
        useShadowValue={singleMode && useShadowValue}
        required
      />
    ),
    singleMode ? null : (
      <Field.Text name={`${prefix}displayName`} label="Display name" useShadowValue={singleMode && useShadowValue} />
    ),
    singleMode ? null : (
      <Field.Text name={`${prefix}description`} label="Description" useShadowValue={singleMode && useShadowValue} />
    ),
    <Field.Select
      name={`${prefix}currency`}
      label="Currency"
      options={supportedCurrencyList}
      description="Pick one from the list or type a custom symbol"
      creatable
      useShadowValue={singleMode && useShadowValue}
    />,
    <Field.Text
      name={`${prefix}currencyFormat`}
      label="Currency format"
      description="Use hashes to format currency like ####.##"
      useShadowValue={singleMode && useShadowValue}
    />,
    <Field.Number
      name={`${prefix}priceRounding`}
      label="Price rounding"
      description="Price will be a multiple of this value"
      useShadowValue={singleMode && useShadowValue}
    />,
    <Field.Textarea
      rows={4}
      name={`${prefix}caveat`}
      label="Caveat"
      description="Additional information about price"
      useShadowValue={singleMode && useShadowValue}
    />,
    singleMode ? null : <Field.Toggle name={`${prefix}disabled`} label="Disable" />
  ];

  return (
    <>
      <Field.Toggle
        name="settings.price.usePriceCodes"
        label="Use pricecodes"
        description="Enable using pricecodes for this seed. If defined here not in root part settings, option prices will not be remotely controllable as a side effect. Overrides root part settings. This will also enable pricing schemes"
        useShadowValue={useShadowValue}
      />
      <Field.Toggle
        name="settings.interface.summaryPriceHidden"
        label="Hide Total Price from Summary"
        useShadowValue={useShadowValue}
      />

      <div className="d-flex flex-column flex-grow-1 mb-3 pb-4 border-bottom">
        <span className="text-uppercase font-weight-bold mb-1">how many price schemes</span>

        <ButtonGroup>
          <Button
            color="secondary"
            onClick={() => {
              onChange(false);
            }}
            outline={isMultiple}
          >
            Single
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              onChange(true);
            }}
            outline={!isMultiple}
          >
            Multiple
          </Button>
        </ButtonGroup>
      </div>

      {!isMultiple && <div>{getChildrenArray('settings.price.defaults.', true).map(el => el)}</div>}
      {isMultiple && (
        <FField name="settings.price.schemes">
          {({ input }) => (
            /* eslint-disable react/no-children-prop */
            <Field.ArrayObject
              name="settings.price.schemes"
              label="Price schemes"
              description="Price schemes which enable overriding values defined above"
              useShadowValue={useShadowValue}
              headingFormat={headingFormat}
              defaultNew={{ name: 'untitled' }}
              children={getChildrenArray('', false)}
            />
          )}
        </FField>
      )}
    </>
  );
};

PriceSchemes.propTypes = {
  useShadowValue: PropTypes.bool,
  supportedCurrencyList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

PriceSchemes.defaultProps = {
  useShadowValue: false,
  supportedCurrencyList: []
};

export default PriceSchemes;
