import { useCallback } from 'react';
import { PartType } from 'types';
import { useSwitch } from '../../../utils/hooks';
import { Card, CardHeader, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from '../../Atoms';
import PartControls from './PartControls';
import partGraph from './partGraphStyles';

interface PartCardProps {
  part: PartType;
  onDelete: (id: string) => void;
  onOpen: (id: string) => void;
  onOpenRaw: (id: string) => void;
}

export const PART_HEADER_HEIGHT = 62; // pixels
const heightStyle = { height: PART_HEADER_HEIGHT };

/** Graph component to show one Part */
const PartCard = ({ part, onDelete, onOpen, onOpenRaw }: PartCardProps) => {
  const { ReferenceName: reference, Option: option } = part;
  const { isOpen, toggle } = useSwitch();

  const handleToggle = useCallback(
    e => {
      if (e.defaultPrevented) return; // default is prevented if dragging map
      toggle();
    },
    [toggle]
  );

  return (
    <Card className={partGraph('node')}>
      <CardHeader
        style={heightStyle}
        className={`${partGraph('node-header')} graph-node-handle`}
        onDoubleClick={() => onOpen(part._id)}
      >
        <div className={partGraph('node-label')}>
          <div className="text-truncate" title={reference}>
            {reference}
          </div>
          <div className="small text-muted text-truncate" title={option}>
            {option}
          </div>
        </div>

        <ButtonDropdown
          isOpen={isOpen}
          toggle={handleToggle}
          className={`${partGraph('node-dropdown')} pull-right`}
          size="sm"
        >
          <DropdownToggle caret color="light" />
          <DropdownMenu persist={false}>
            <DropdownItem onClick={() => onOpen(part._id)}>Edit</DropdownItem>
            <DropdownItem onClick={() => onOpenRaw(part._id)}>Edit Raw</DropdownItem>
            <DropdownItem onClick={() => onDelete(part._id)}>Delete</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </CardHeader>
      <PartControls nodeId={part._id} />
    </Card>
  );
};

export default PartCard;
