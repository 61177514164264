import { useCallback, useEffect, useMemo, useState, useRef, RefObject, ReactNode } from 'react';
import b from 'b_';
import { Node } from 'dagre';
import GraphNode from '../GraphNode';
import GraphEdge, { NODE_WIDTH } from '../GraphEdge';
import { GraphNodeShape } from '../GraphNode/GraphNode';
import { GraphEdgeShape } from '../GraphEdge/GraphEdge';
import CanvasControls from './CanvasControls';

const graph = b.with('graph');

const useCanvasControlsCallbacks = (
  nodes: GraphNodeShape[],
  edges: GraphEdgeShape[],
  onMapSet: (s: { scale: number; translation: { x: number; y: number } }) => void,
  graphContainer: RefObject<HTMLDivElement>,
  onReorderNodes: (n: Node<{ id: string }>[]) => void
) => {
  const [minX, maxX, minY, maxY] = useMemo(() => {
    const arrX: number[] = [];
    const arrY: number[] = [];

    nodes.forEach(({ x, y }) => {
      arrX.push(x);
      arrY.push(y);
    });

    // also add 10 px for padding
    return [Math.min(...arrX) - 10, Math.max(...arrX) + 10, Math.min(...arrY) - 10, Math.max(...arrY) + 10];
  }, [nodes]);

  const zoomExtentsHandler = useCallback(() => {
    if (graphContainer.current) {
      const { clientWidth, clientHeight } = graphContainer.current;

      const scaleX = clientWidth / (maxX - minX);
      const scaleY = clientHeight / (maxY - minY);
      const scale = scaleX < scaleY ? scaleX : scaleY;

      const cappedScale = scale < 1 ? scale : 1;

      const midY = (maxY + minY) / 2;
      const midX = (maxX + minX) / 2;

      const newX = 0.5 * clientWidth - midX * cappedScale;
      const newY = 0.5 * clientHeight - midY * cappedScale;

      onMapSet({ scale: cappedScale, translation: { x: newX, y: newY } });
    }
  }, [graphContainer, maxX, maxY, minX, minY, onMapSet]);

  const reorderHandler = useCallback(() => {
    import('dagre')
      .then(dagre => {
        const Graph = new dagre.graphlib.Graph<{ id: string }>();

        Graph.setGraph({
          rankdir: 'LR',
          nodesep: 40,
          ranksep: NODE_WIDTH,
          marginx: 0,
          marginy: 0
        });

        // eslint-disable-next-line func-names
        Graph.setDefaultEdgeLabel(function () {
          return {};
        });

        nodes.forEach(node => {
          Graph.setNode(node.id, { id: node.id, width: NODE_WIDTH, height: node.height });
        });

        edges.forEach(edge => Graph.setEdge(edge.source, edge.target));

        dagre.layout(Graph);

        const layoutNodes = Graph.nodes().map(node => Graph.node(node));

        onReorderNodes(layoutNodes.filter(node => node));

        setHasZoomed(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [edges, nodes, onReorderNodes]);

  const [hasZoomed, setHasZoomed] = useState(false);

  useEffect(() => {
    if (!hasZoomed) {
      setHasZoomed(true);
      zoomExtentsHandler();
    }
  }, [hasZoomed, zoomExtentsHandler]);

  return { zoomExtentsHandler, reorderHandler };
};

interface GraphCanvasProps {
  translation: { x: number; y: number };
  scale: number;
  nodes: GraphNodeShape[];
  edges: GraphEdgeShape[];
  onSelectNode: (id: string) => void;
  onDeselectNode: () => void;
  onReorderNodes: (n: Node<{ id: string }>[]) => void;
  selectedNodes: Record<string, string>;
  rootNodeId: string;
  draggedNode?: string;
  onMapSet: (s: { scale: number; translation: { x: number; y: number } }) => void;
  onDragStop: (nodeId: string, dX: number, dY: number, initialGraphX: number, initialGraphY: number) => void;
  onDragStart: (n: string) => void;
  onDrag: () => void;
  toolbar: ReactNode;
}

const GraphCanvas = ({
  translation,
  scale,
  onMapSet,
  nodes,
  edges,
  onSelectNode,
  onDeselectNode,
  selectedNodes,
  onDragStop,
  onDrag,
  onDragStart,
  onReorderNodes,
  draggedNode,
  rootNodeId,
  toolbar
}: GraphCanvasProps) => {
  const graphContainer = useRef<HTMLDivElement>(null);

  const { zoomExtentsHandler, reorderHandler } = useCanvasControlsCallbacks(
    nodes,
    edges,
    onMapSet,
    graphContainer,
    onReorderNodes
  );

  /* Memoizing nodes and edges speeds up rendering considerably */

  const childNodes = useMemo(
    () =>
      nodes.map(node => {
        const isSelected = Boolean(selectedNodes[node.id]);
        const isDragged = node.id === draggedNode;
        /* If node is not dragged, we supply it with scale 1 so the node wouldn't update */

        const scaleFactor = isDragged ? scale : 1;

        return (
          <GraphNode
            onSelect={onSelectNode}
            onDeselect={onDeselectNode}
            isSelected={isSelected}
            key={node.id}
            node={node}
            onDragStart={onDragStart}
            onDragStop={onDragStop}
            onDrag={onDrag}
            scale={scaleFactor}
            color={node.hasParent || node.id === rootNodeId ? 'light' : 'danger'}
          />
        );
      }),
    [
      draggedNode,
      nodes,
      onDeselectNode,
      onDrag,
      onDragStart,
      onDragStop,
      onSelectNode,
      rootNodeId,
      scale,
      selectedNodes
    ]
  );

  const childEdges = useMemo(
    () =>
      edges.map(edge => (
        <GraphEdge key={edge.id} source={edge.sourcePosition} target={edge.targetPosition} className={graph('edge')} />
      )),
    [edges]
  );

  return (
    <>
      <div className={graph('nodes-container')} ref={graphContainer}>
        <svg width="100%" height="100%" className={graph('edges')} xmlns="http://www.w3.org/2000/svg">
          <g transform={`translate(${translation.x}, ${translation.y}) scale(${scale})`}>{childEdges}</g>
        </svg>
        <div
          className={graph('nodes')}
          style={{ transform: `translate(${translation.x}px, ${translation.y}px) scale(${scale})` }}
        >
          {childNodes}
        </div>
        <CanvasControls
          className={graph('canvas-controls')}
          onZoomExtents={zoomExtentsHandler}
          onReorder={reorderHandler}
          toolbar={toolbar}
        />
      </div>
    </>
  );
};

export default GraphCanvas;
