import { reducer } from '../@helpers';
import factoryMapsActions from './factoryMapsActions';

function moduleReducer(state, { type, payload = {} }) {
  // eslint-disable-next-line
  switch (type) {
    case factoryMapsActions.setPublishedFlag.success.ACTION_TYPE: {
      const { populated } = state.list[payload._id];

      return { ...state, list: { ...state.list, [payload._id]: { ...payload, populated } } };
    }
    default:
      return state;
  }
}

export default reducer.createBaseModuleReducer(factoryMapsActions, moduleReducer);
