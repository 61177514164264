import { all } from 'redux-saga/effects';
import { authSaga } from './auth';
import { factoryMapsSaga } from './factoryMaps';
import { iframeScriptsSaga } from './iframeScripts';
import { instancesSaga } from './instances';
import { invitationsSaga } from './invitations';
import { mappedInstancesSaga } from './mappedInstances';
import { partnersSaga } from './partners';
import { partsSaga } from './parts';
import { plotsSaga } from './plots';
import { rolesSaga } from './roles';
import { settingsSaga } from './settings';
import { statisticsSaga } from './statistics';
import { usersSaga } from './users';
import { assetsSagas } from './assets';

export default function* rootSaga() {
  yield all([
    authSaga(),
    factoryMapsSaga(),
    iframeScriptsSaga(),
    instancesSaga(),
    invitationsSaga(),
    mappedInstancesSaga(),
    partnersSaga(),
    partsSaga(),
    plotsSaga(),
    rolesSaga(),
    settingsSaga(),
    statisticsSaga(),
    usersSaga(),
    assetsSagas.images(),
    assetsSagas.textures(),
    assetsSagas.models()
  ]);
}
