import { useCallback, useMemo, useState, useEffect } from 'react';
import { get, set } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { ProjectType } from 'types';
import { useSelector } from 'react-redux';
import { FormField, Col, Row } from '../../Atoms';
import { usePartList } from '../../../utils/hooks';
import { seedsSelectors } from '../../../modules/seeds';
import { TAB_NAMES } from '../../../utils/defaultTabNames';
import {
  ACTION_TYPES,
  ACTIONS,
  COLORS,
  ICONS,
  AUTHORIZATION_VISIBILITY_LIST,
  SAVING_VISIBILITY_LIST,
  REQUEST_VISIBILITY_LIST,
  CONFIGURATOR_MODE_VISIBILITY_LIST,
  getDefaultConfiguration
} from './actionsConstants';
import { TypeDependentFields } from './ActionsDependentFields';

interface Action {
  type: string;
  name: string;
  tab: string;
}

interface MenuItem {
  name: string;
  color: string;
  icon?: string;
}

interface MenuGroup {
  section: string;
  actions: MenuItem[];
}

interface ActionSettings {
  name: string;
  displayName: string;
  description: string;
  type: string;
  dialog: string;
  requireLogin: boolean;
  link: string;
  tab: string;
  hidingByAuth: string;
  hidingBySaving: string;
  hidingByRequest: string;
  hidingByConfiguratorMode: string;
  groups: {
    aboutTab: MenuItem[];
    reviewTab: MenuItem[];
    summaryTab: MenuItem[];
    menu: MenuGroup;
    overviewPanel: MenuItem[];
    summaryPanel: MenuItem[];
    shareDialog: MenuItem[];
    summarySaveButton: string;
    finishButton: string;
    homeButton: string;
  };
}

const ActionListSettingsPath = 'settings.actions.list';
const ActionGroupsSettingsPath = 'settings.actions.groups';

const getLabelPostfix = (flag: boolean) => (flag ? '(Currently hidden)' : '');

export const Actions = ({ seedId }: { seedId?: string }) => {
  const { setValue, getValues } = useFormContext<ProjectType>();
  const settings = useSelector(state => seedsSelectors.selectSeedSettingsMemoized(state, seedId));

  const handleReset = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to reset settings to default?`); // eslint-disable-line no-alert
    const options = { shouldValidate: true, shouldDirty: true, shouldTouch: true };

    const defaults = getDefaultConfiguration();

    if (response) {
      // hook form only updates its state when updating one level of arrays
      setValue(ActionListSettingsPath, defaults.list, options);
      setValue(`${ActionGroupsSettingsPath}.aboutTab`, defaults.groups.aboutTab, options);
      setValue(`${ActionGroupsSettingsPath}.reviewTab`, defaults.groups.reviewTab, options);
      setValue(`${ActionGroupsSettingsPath}.summaryTab`, defaults.groups.summaryTab, options);
      setValue(`${ActionGroupsSettingsPath}.menu`, defaults.groups.menu, options);
      setValue(`${ActionGroupsSettingsPath}.overviewPanel`, defaults.groups.overviewPanel, options);
      setValue(`${ActionGroupsSettingsPath}.summaryPanel`, defaults.groups.summaryPanel, options);
      setValue(`${ActionGroupsSettingsPath}.shareDialog`, defaults.groups.shareDialog, options);
      setValue(`${ActionGroupsSettingsPath}.summarySaveButton`, defaults.groups.summarySaveButton, options);
      setValue(`${ActionGroupsSettingsPath}.finishButton`, defaults.groups.finishButton, options);
    }
  }, [setValue]);

  const handleDelete = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to delete all values?`); // eslint-disable-line no-alert
    const options = { shouldValidate: true, shouldDirty: true, shouldTouch: true };

    if (response) {
      // hook form only updates its state when updating one level of arrays
      setValue(ActionListSettingsPath, [], options);
      setValue(`${ActionGroupsSettingsPath}.aboutTab`, [], options);
      setValue(`${ActionGroupsSettingsPath}.reviewTab`, [], options);
      setValue(`${ActionGroupsSettingsPath}.summaryTab`, [], options);
      setValue(`${ActionGroupsSettingsPath}.menu`, [], options);
      setValue(`${ActionGroupsSettingsPath}.overviewPanel`, [], options);
      setValue(`${ActionGroupsSettingsPath}.summaryPanel`, [], options);
      setValue(`${ActionGroupsSettingsPath}.shareDialog`, [], options);
      setValue(`${ActionGroupsSettingsPath}.summarySaveButton`, undefined, options);
      setValue(`${ActionGroupsSettingsPath}.finishButton`, undefined, options);

      setValue('settings.actions', undefined);
    }
  }, [setValue]);

  usePartList(seedId);

  const localSettings = useMemo(() => {
    const { seedSettings, projectSettings } = settings;
    const tabsMap: Record<string, any> = {};

    [
      ['aboutTabHidden', TAB_NAMES.ABOUT],
      ['reviewTabHidden', TAB_NAMES.REVIEW],
      ['summaryTabHidden', TAB_NAMES.SUMMARY]
    ].forEach(([flag, tab]) => {
      tabsMap[tab] = [true, false].includes(seedSettings.interface?.[flag])
        ? seedSettings.interface?.[flag]
        : projectSettings.interface?.[flag];
    });

    const actions: Record<string, any> = {};

    (projectSettings.actions?.list || seedSettings.actions?.list || [])
      .filter((action: Action) => action.type === ACTION_TYPES.OPEN_TAB)
      .forEach((action: Action) => {
        actions[action.name] = tabsMap[action.tab];
      });

    return { tabs: tabsMap, actions };
  }, [settings]);

  const headingFormat = useCallback(
    value => {
      if (!value) {
        return '';
      }

      return `${value.name} ${getLabelPostfix(localSettings.actions[value.name])}`;
    },
    [localSettings.actions]
  );

  const [state, setState] = useState<{ type: { label: string; value: string }[] }>({ type: [] });

  const handleTypeChange = (value?: { label: string; value: string } | null, index?: number) => {
    set(state, `type[${index}]`, value);
    setState(state);
  };

  const currentActionsList: any = getValues(ActionListSettingsPath) || [];
  const currentTypes = currentActionsList.map((action: ActionSettings) => action.type);

  useEffect(() => {
    currentTypes.forEach((type: string, index: number) => {
      const label = ACTIONS.find(action => action.value === type)?.label || '';
      const value: { label: string; value: string } = {
        value: type,
        label
      };

      set(state, `type[${index}]`, value);
    });
    setState(state);
  }, [currentTypes, state]);

  return (
    <>
      <Row>
        <Col xs="12" md="6">
          <div className="border border-danger p-5 mb-3 d-flex flex-column">
            <button onClick={handleDelete} type="button" className="btn btn-outline-danger mb-2">
              Delete values
            </button>
            <button onClick={handleReset} type="button" className="btn btn-danger">
              Reset to default
            </button>
            <span className="mt-2 text-center text-danger">
              This will restore all default values for all actions, use carefully
            </span>
          </div>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.list"
            label="All available actions"
            description="Define actions that will be used in the configuration"
            headerFormat={value => get(value, 'name', 'untitled')}
            useShadowValue
          >
            <FormField.Text<ActionSettings>
              name="name"
              label="Action name"
              description="Used to identify the action. Must be unique"
              required
            />
            <FormField.Text<ActionSettings> name="displayName" label="Display name" required />
            <FormField.Text<ActionSettings> name="description" label="Description" description="Used in the menu" />
            <FormField.Select<ActionSettings>
              name="type"
              label="Action type"
              description="Choose action type from the list"
              options={ACTIONS}
              required
              onChange={handleTypeChange}
            />
            <TypeDependentFields seedId={seedId} pageState={state} />
            <FormField.Select<ActionSettings>
              name="hidingByAuth"
              label="Visibility based on authorization"
              options={AUTHORIZATION_VISIBILITY_LIST}
            />
            <FormField.Select<ActionSettings>
              name="hidingBySaving"
              label="Visibility based on saving"
              options={SAVING_VISIBILITY_LIST}
            />
            <FormField.Select<ActionSettings>
              name="hidingByRequest"
              label="Visibility based on request"
              options={REQUEST_VISIBILITY_LIST}
            />
            <FormField.Select<ActionSettings>
              name="hidingByConfiguratorMode"
              label="Visibility based on configurator mode"
              options={CONFIGURATOR_MODE_VISIBILITY_LIST}
            />
          </FormField.ArrayWrapper>
        </Col>
        <Col xs="12" md="6">
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.aboutTab"
            label={`About tab actions ${getLabelPostfix(localSettings.tabs[TAB_NAMES.ABOUT])}`}
            headerFormat={headingFormat}
            useShadowValue
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.reviewTab"
            label={`Review tab actions ${getLabelPostfix(localSettings.tabs[TAB_NAMES.REVIEW])}`}
            headerFormat={headingFormat}
            useShadowValue
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.summaryTab"
            label={`Summary tab actions ${getLabelPostfix(localSettings.tabs[TAB_NAMES.SUMMARY])}`}
            headerFormat={headingFormat}
            useShadowValue
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>

          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.menu"
            label="Menu actions"
            headerFormat={value => `${get(value, 'section', '')}`}
            useShadowValue
          >
            <FormField.Text<MenuGroup> name="section" label="Section name" required />
            <FormField.ArrayWrapper<MenuGroup> name="actions" label="Actions" headerFormat={headingFormat}>
              <FormField.ControlledSelect<MenuItem, ProjectType>
                sourceField="settings.actions.list"
                name="name"
                label="Action name"
                getOptionValue={(option?: any) => `${option?.name}`}
                getOptionLabel={(option?: any) => `${option?.name}`}
                description="Select an action from the list"
                required
              />
            </FormField.ArrayWrapper>
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.overviewPanel"
            label="Overview panel actions"
            headerFormat={headingFormat}
            useShadowValue
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              name="name"
              label="Action name"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.summaryPanel"
            label="Summary panel actions"
            headerFormat={headingFormat}
            useShadowValue
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="icon" label="Icon" options={ICONS} required />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.shareDialog"
            label="Share dialog actions"
            headerFormat={headingFormat}
            useShadowValue
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
        </Col>
      </Row>
      <FormField.ControlledSelect<ProjectType, ProjectType>
        sourceField="settings.actions.list"
        getOptionValue={(option?: any) => `${option?.name}`}
        getOptionLabel={(option?: any) => `${option?.name}`}
        name="settings.actions.groups.summarySaveButton"
        label="Summary save button action"
        description="Select an action from the list"
      />
      <FormField.ControlledSelect<ProjectType, ProjectType>
        sourceField="settings.actions.list"
        getOptionValue={(option?: any) => `${option?.name}`}
        getOptionLabel={(option?: any) => `${option?.name}`}
        name="settings.actions.groups.finishButton"
        label="Next button action in the last step"
        description="Select an action from the list"
      />
      <FormField.ControlledSelect<ProjectType, ProjectType>
        sourceField="settings.actions.list"
        getOptionValue={(option?: any) => `${option?.name}`}
        getOptionLabel={(option?: any) => `${option?.name}`}
        name="settings.actions.groups.homeButton"
        label="Home button action"
        description="Select an action from the list"
      />
    </>
  );
};
