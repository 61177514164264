import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormFeedback, FormGroup, FormText, Input, Label } from '../Atoms';

const FormField = props => {
  const { field, size, parse } = props;
  const type = field.type || 'text';

  return (
    <FormGroup>
      <Label for={field.key}>{field.label}</Label>
      <Field
        name={field.key}
        placeholder={field.placeholder}
        type={type}
        disabled={field.disabled}
        required={field.required}
        parse={parse}
      >
        {({ input, meta }) => (
          <>
            <Input
              {...input}
              invalid={!meta.valid}
              disabled={field.disabled}
              type={type}
              placeholder={field.placeholder}
              bsSize={size}
            />
            <FormFeedback>{meta.error}</FormFeedback>
            <FormText>{field.text}</FormText>
          </>
        )}
      </Field>
    </FormGroup>
  );
};

FormField.defaultProps = {
  size: 'md',
  parse: value => value
};

FormField.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    text: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
  }).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  parse: PropTypes.func
};

export default FormField;
