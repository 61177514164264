import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import b from 'b_';
import { Button, RSForm, FormFeedback, FormGroup, Input } from '../../Atoms';
import { authActions, authSelectors } from '../../../modules/auth';
import { ApplicationRoute } from '../../../utils/appRoutes';

const loginForm = b.with('login-form');

export const LoginFormComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isDisabled = !(email && password);
  const error = useSelector(authSelectors.selectAuthError);
  const dispatch = useDispatch();

  const handleEmailChange = useCallback(e => setEmail(e.currentTarget.value), []);
  const handlePasswordChange = useCallback(e => setPassword(e.currentTarget.value), []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(authActions.login(email, password));
    },
    [dispatch, email, password]
  );

  return (
    <RSForm className={loginForm()} onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          type="email"
          name="email"
          placeholder="E-mail"
          onChange={handleEmailChange}
          value={email}
          invalid={Boolean(error)}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handlePasswordChange}
          value={password}
          invalid={Boolean(error)}
        />
        <FormFeedback>{error}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Button color="primary" type="submit" block disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>

      <span className="text-center fw-light d-block small">
        <Link to={ApplicationRoute.ForgotPassword}>Forgot password?</Link>
      </span>
    </RSForm>
  );
};

export default LoginFormComponent;
