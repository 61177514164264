import React from 'react';
import { ColumnInstance } from 'react-table';
import { INSTANCE_ORIGINS } from '../../modules/instances/instancesSelectors';
import SelectFilter, { ALL_FILTER } from '../SelectFilter';

const filterValues = [INSTANCE_ORIGINS.CREATOMUS, INSTANCE_ORIGINS.PARTNER, INSTANCE_ORIGINS.ORPHAN].map(item => ({
  label: item,
  value: item
}));

function InstanceOriginFilter({ column }: { column: ColumnInstance }) {
  const { setFilter, filter } = column;

  return (
    <SelectFilter
      options={filterValues}
      filter={filter}
      onChange={filterValue => {
        if (filterValue === ALL_FILTER) {
          setFilter(undefined);
        } else {
          setFilter(filterValue);
        }
      }}
    />
  );
}

export default InstanceOriginFilter;
