import { useCallback, useState } from 'react';
import { Input } from 'reactstrap';
import { useSwitch } from '../../../../../utils/hooks';
import FileUpload from '../../../../FileUpload/FileUpload';

export interface GalleryProps {
  list?: {
    name: string;
    url: string;
  }[];
  uploadImage: () => void;
  onClose: () => void;
  onSelect: (name: string) => void;
  onUploadSuccess: () => void;
  onUploadFailure?: () => void;
}

const Gallery = ({ list, uploadImage, onClose, onSelect, onUploadSuccess, onUploadFailure }: GalleryProps) => {
  const { isOpen, toggle } = useSwitch();
  const [selectedImg, setSelectedImg] = useState<{ name: string; url: string }>();
  const select = useCallback(name => onSelect(name), [onSelect]);
  const renderImg = useCallback((src, alt, backgroundColor) => {
    if (src) {
      return (
        <img
          src={src}
          alt={alt}
          style={{ width: '128px', height: '128px', objectFit: 'contain', alignSelf: 'center' }}
        />
      );
    }

    if (backgroundColor) {
      return <div className="img-fluid" style={{ backgroundColor, padding: '50% 0' }} />;
    }

    return null;
  }, []);

  const [filter, setFilter] = useState('');
  const filterHandler = useCallback(e => setFilter(e.currentTarget.value.toLowerCase()), []);

  return (
    <>
      <div className="d-flex pb-3 ps-1">
        <Input placeholder="Filter by name" value={filter} onChange={filterHandler} />
      </div>
      <div className="d-flex p-1 flex-wrap gap-3">
        {list
          ?.filter(({ name }) => name.toLowerCase().includes(filter))
          .map(image => {
            const selectedStyle =
              selectedImg === image
                ? {
                    border: '2px solid #2D9CDB',
                    borderRadius: 2
                  }
                : {
                    padding: 2
                  };

            return (
              <div
                style={{ ...selectedStyle, width: 150 }}
                className="d-flex flex-column"
                role="button"
                tabIndex={-1}
                onKeyDown={() => {
                  setSelectedImg(image);
                  select(image.name);
                }}
                onClick={() => {
                  setSelectedImg(image);
                  select(image.name);
                }}
              >
                {renderImg(image.url, image.name, '#fff')}
                <small className="text-center text-break">{image.name}</small>
              </div>
            );
          })}
      </div>
      <div>
        {isOpen && (
          <FileUpload
            onSuccess={onUploadSuccess}
            onFailure={onUploadFailure}
            caption="Upload image"
            onClose={toggle}
            api={uploadImage}
          />
        )}
      </div>
      <div className="mt-3 w-100 text-center">
        <button type="button" className="btn btn-outline-primary" onClick={toggle}>
          + Upload more
        </button>
      </div>
    </>
  );
};

export default Gallery;
