import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as invitationsSelectors from './invitationsSelectors';
import invitationsActions from './invitationsActions';

// eslint-disable-next-line import/prefer-default-export
export const useInvitationList = useCache => {
  const dispatch = useDispatch();
  const request = useSelector(invitationsSelectors.selectInvitationsRequest);
  const list = useSelector(invitationsSelectors.selectInvitationList);

  useEffect(() => {
    if (useCache && list.length) {
      return;
    }

    dispatch(invitationsActions.fetchInvitations());
  }, [dispatch, list.length, useCache]);

  return { request, list };
};
