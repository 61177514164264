import { FC } from 'react';
import { Spinner } from '../Bootstrap';

const Loader: FC<{ loading: boolean }> = ({ loading = false, children = null }) => (
  <>
    {loading ? (
      <div className="d-flex position-relative h-100 w-100 p-4 flex-grow-1">
        <div className="m-auto">
          <Spinner />
        </div>
      </div>
    ) : (
      children
    )}
  </>
);

export default Loader;
