import { BaseSyntheticEvent, ReactNode } from 'react';
import {
  ArrayPath,
  DeepPartial,
  DefaultValues,
  FieldArrayPath,
  FieldArrayWithId,
  FieldPath,
  FieldPathValue,
  FormState,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  UnpackNestedValue,
  PathValue,
  Path
} from 'react-hook-form';

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  URL = 'url',
  TEL = 'tel',
  PASSWORD = 'password',
  TEXTAREA = 'textarea',
  SWITCH = 'switch',
  CHECK = 'check',
  ARRAY = 'array',
  SELECT = 'select',
  ASYNC_SELECT = 'async-select',
  MULTI_SELECT = 'multi-select',
  CODE = 'code',
  BUTTON_GROUP = 'button-group',
  IMAGE_ARRAY = 'image-array',
  BUTTON_SWITCH = 'button-switch'
}

export type InputType =
  | FieldType.TEXT
  | FieldType.NUMBER
  | FieldType.EMAIL
  | FieldType.URL
  | FieldType.TEL
  | FieldType.PASSWORD
  | FieldType.TEXTAREA
  | FieldType.SWITCH
  | FieldType.CHECK
  | FieldType.IMAGE_ARRAY;

export type HandlerChangeFormValues<T> = (value: UnpackNestedValue<DeepPartial<T>>) => void;

export interface FormProps<T> {
  defaultValues?: DefaultValues<T>;
  className?: string;
  onSubmit: SubmitHandler<T>;
  onInvalid?: SubmitErrorHandler<T>;
  onChangeFormState?: (formState: FormState<T>) => void;
  onChangeFormValues?: HandlerChangeFormValues<T>;
  interruptNavigation?: boolean;
  modalMode?: boolean;
  onCancel?: () => void;
  shadowValues?: {
    name?: string;
    value?: any;
  }[];
  readOnly?: boolean;
  defaultError?: {
    path: string;
    message: string;
  };
}

export interface FormSpyProps<T> {
  onChange: HandlerChangeFormValues<T>;
}

export type FieldName<T> = FieldPath<T>;
export type FieldValue<T> = FieldPathValue<T, FieldName<T>>;

export type ArrayFieldName<T> = FieldArrayPath<T>;
export type ArrayField<T> = FieldArrayWithId<T>;

export interface NavigationInterceptorProps {
  isValid: boolean;
  isDirty: boolean;
  isSubmitSuccessful: boolean;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
}

interface CommonFieldProps<T> {
  name: FieldName<T>;
  label?: string;
  description?: ReactNode;
  requiredFn?: (value?: any, index?: number) => boolean;
  required?: boolean;
  column?: 'left' | 'right';
  index?: number;
}

export interface FieldWrapperProps extends CommonFieldProps<any> {
  name: string;
  type: FieldType;
  error?: string;
  isCheckbox?: boolean;
  isInputTag?: boolean;
  useShadowValue?: boolean;
  className?: string;
  noLabel?: boolean;
  isComplex?: boolean;
  level?: number;
  children?: ReactNode;
  shadowChildren?: ReactNode;
  headerFormat?: (item: any) => string;
  shadowLabelsMap?: Record<any, string>;
}

interface CommonInputProps<T> extends CommonFieldProps<T> {
  placeholder?: string;
  readonly?: boolean;
  onChange?: (e: BaseSyntheticEvent, index?: number) => void;
  index?: number;
  useShadowValue?: boolean;
  wrapperClass?: string;
  noLabel?: boolean;
  internalName?: string;
}

export interface BaseInputProps<T> extends CommonInputProps<T> {
  type: InputType;
  registerOptions?: RegisterOptions<T>;
  rows?: number;
  append?: string;
  prepend?: string;
  useShadowValue?: boolean;
}

export interface NumberInputProps<T> extends CommonInputProps<T> {
  min?: number;
  max?: number;
  allowEmpty?: boolean;
}

export interface TextInputProps<T> extends CommonInputProps<T> {
  minLength?: number;
  maxLength?: number;
  pattern?: 'text' | 'url' | 'email' | 'password' | 'tel' | RegExp;
  append?: string;
  prepend?: string;
}

export interface TextareaInputProps<T> extends CommonInputProps<T> {
  rows?: number;
}

export interface MarkdownProps<T> extends CommonInputProps<T> {
  classes?: {
    reactMde?: string;
    toolbar?: string;
    preview?: string;
    textArea?: string;
    suggestionsDropdown?: string;
  };
}

export interface ImageProps<T> extends CommonInputProps<T> {
  hideUpload?: boolean;
  list?: { name: string; url: string }[];
  header?: string;
  uploadImage: () => void;
  onUploadFailure?: () => void;
  onUploadSuccess: () => void;
}

export type SwitchInputProps<T> = CommonInputProps<T>;

export interface SwitchButtonProps<T> extends Omit<CommonInputProps<T>, 'onChange'> {
  buttonLabels: string[];
  onChange: (state: boolean) => void;
}

export interface CodeProps<T> extends CommonFieldProps<T> {
  mode: 'json' | 'html' | 'css' | 'javascript';
  placeholder?: string;
  readonly?: boolean;
  onChange?: (value?: string) => void;
  parseValue?: (value?: string) => string;
  defaultValue?: string;
}

export type DefaultOptionType = { label: string; value: string };

interface BaseSelectProps<T, K> extends CommonFieldProps<T> {
  getOptionValue?: (option?: K | null) => string;
  getOptionLabel?: (option?: K | null) => string;
  disabled?: boolean;
  useShadowValue?: boolean;
  creatable?: boolean;
}

export interface SelectProps<T, K> extends BaseSelectProps<T, K> {
  options: K[];
  onChange?: (value: K | null, index?: number) => void;
}

export interface ControlledSelectProps<T, P, K> extends BaseSelectProps<T, K> {
  sourceField: FieldName<P>;
}

export type ButtonGroupProps<T, K> = SelectProps<T, K>;

export interface AsyncSelectProps<T, K> extends BaseSelectProps<T, K> {
  api(value?: string): Promise<K[]>;
  filter?(value: string, options: K[]): K[];
}

export interface MultiSelectProps<T, K> extends SelectProps<T, K> {
  creatable?: boolean;
  getNewOptionData?: (value: string) => K;
}

export interface ArrayProps<T, N extends ArrayPath<T> = ArrayPath<T>> {
  label?: string;
  description?: ReactNode;
  required?: boolean;
  name: ArrayFieldName<T> | FieldName<T>;
  isStatic?: boolean;
  isSortable?: boolean;
  headerFormat?: (value: FieldArrayWithId<T, N, 'id'>) => string;
  keyName?: string;
  twoColumns?: boolean;
  column?: 'left' | 'right';
  component?: JSX.Element;
  defaultNew?: UnpackNestedValue<PathValue<T, ArrayPath<T> | Path<T>>>;
  useShadowValue?: boolean;
  noWrapper?: boolean;
  hideFollowingLabels?: boolean;
  level?: number;
}
