import { InstanceType } from 'types';
import { CsvDownloader } from '../../Atoms';
import { useAppSelector } from '../../../store/hooks';
import { instancesSelectors } from '../../../modules/instances';

const useCsvContent = () => {
  const requestComparator = (a: InstanceType, b: InstanceType) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }

    if (a.createdAt > b.createdAt) {
      return -1;
    }

    return 0;
  };
  const columns = [
    'Instance id',
    'Instance name',
    'Date created',
    'Date modified',
    'Request first name',
    'Request last name',
    'Request email',
    'Request message',
    'Consent'
  ];

  const instances: InstanceType[] = useAppSelector(instancesSelectors.selectInstancesWithRequest);
  const data = instances.sort(requestComparator).map(instance => {
    const { lead = {}, _id, instanceName, createdAt, updatedAt } = instance;
    const { firstName = '', lastName = '', email = '', message = '', consent } = lead;
    const consentLabel = consent?.label || 'Not given';

    return [
      _id,
      instanceName,
      createdAt,
      updatedAt,
      firstName,
      lastName,
      email,
      message ? message.replace(/\n/g, ' ') : '',
      consentLabel
    ];
  });

  return { data, columns };
};

const ExportRequests = () => {
  const { data, columns } = useCsvContent();

  return <CsvDownloader filename="requests" title="Download CSV" data={data} columns={columns} separator=";" />;
};

export default ExportRequests;
