import { useFormContext } from 'react-hook-form';
import { FieldName } from '../types';

interface HiddenInputProps<T> {
  name: FieldName<T>;
}

const Hidden = <T,>({ name }: HiddenInputProps<T>) => {
  const { register } = useFormContext<T>();

  return <input {...register(name)} type="hidden" />;
};

export default Hidden;
