import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Container } from '../../../Atoms';
import { CONFIGURATOR_URL } from '../../../../config';
import { partsSelectors } from '../../../../modules/parts';
import { TYPE_PART_VIEW } from '../../../../utils/propTypes';
import { seedsSelectors } from '../../../../modules/seeds';
import { useDebounce } from '../../../../utils/helpers/hooks';
import { useSeed } from '../../../../store/seed';

const SRC = `${CONFIGURATOR_URL}/dev/part`;

const HEADERS = { 'Configurator/ViewerData': true };

const PartPreview = ({ onUpdate, seedId, view }) => {
  const [camera, setCamera] = useState();
  const debouncedCamera = useDebounce(camera, 500);
  const [key, setKey] = useState(0);
  const materials = useSelector(state => partsSelectors.selectMaterials(state, seedId));
  const parts = useSelector(state => partsSelectors.selectPartListBySeedMemoized(state, seedId));
  const rootPartId = useSelector(state => seedsSelectors.selectRootPartId(state, seedId));
  const { seed = {} } = useSeed(seedId);
  const { projectId } = seed;
  const receiveMessage = useCallback(event => {
    const { data } = event;

    if (typeof data === 'object' && HEADERS[data.header] && data.info) {
      setCamera({ cameraPosition: data.info.camera.position, cameraTarget: data.info.target.position });
    }
  }, []);

  const postMessageHandler = useCallback(
    e => {
      const { contentWindow } = e.currentTarget;

      const frameParts = parts.map(part => {
        const newPart = { ...part };

        delete newPart.Controls;
        delete newPart.Phases;

        if (view) {
          delete newPart.Views;

          if (rootPartId === newPart._id) {
            newPart.Views = { list: [view] };
          }
        }

        return newPart;
      });

      const textures = materials
        .filter(material => material.DiffuseTexture)
        .map(material => ({ materialName: material.Name, textureName: material.DiffuseTexture }));

      const frameData = {
        parts: frameParts,
        header: 'Creator/partPreview',
        textures,
        rootPartId,
        projectId
      };

      contentWindow.postMessage(frameData, CONFIGURATOR_URL);
    },
    [materials, parts, projectId, rootPartId, view]
  );

  const reload = useCallback(() => {
    setKey(Date.now());
  }, []);

  const handleUpdate = useCallback(() => {
    onUpdate(debouncedCamera);
  }, [debouncedCamera, onUpdate]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [receiveMessage]);

  return (
    <Container className="mt-3">
      <h5>Preview</h5>
      <div className="m-2 position-absolute">
        <Button title="Reload frame if changed manually" outline color="dark" className="me-2" onClick={reload}>
          Reload
        </Button>
        <Button
          title="Update view coordinates from render window"
          outline
          color="primary"
          onClick={handleUpdate}
          disabled={!debouncedCamera}
        >
          Update coordinates
        </Button>
      </div>

      <iframe
        key={key}
        onLoad={postMessageHandler}
        title="Part preview"
        src={SRC}
        style={{ width: '100%', height: 500 }}
      />
    </Container>
  );
};

PartPreview.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  seedId: PropTypes.string.isRequired,
  view: TYPE_PART_VIEW.isRequired
};

export default PartPreview;
