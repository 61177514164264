import React from 'react';
import { useParams } from 'react-router-dom';
import { Page, PageContent, PageHeader } from '../Page';
import Loader from '../Loader';
import { useAssets } from '../../utils/hooks';
import AssetGroupPicker from '../AssetsPage/AssetGroupPicker';
import AssetUpload from '../AssetsPage/AssetUpload';
import { assetsHooks } from '../../modules/assets';
import AssetsTabs from '../AssetsPage/AssetsTabs';
import { useProject } from '../../store/project';

const AssetsByProjectPage = () => {
  const { projectId } = useParams();
  const selectedAssetType = assetsHooks.useActiveAssetsType();
  const { isInProgress } = useProject(projectId);

  const { projectTextures, projectImages, projectModels } = useAssets(projectId);

  return (
    <Page>
      <PageHeader>
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
          <AssetsTabs
            activeType={selectedAssetType}
            imagesLength={projectImages.length}
            texturesLength={projectTextures.length}
            modelsLength={projectModels.length}
          />
          <div className="py-2">
            <AssetUpload selectedProject={projectId} type={selectedAssetType} size="sm" />
          </div>
        </div>
      </PageHeader>
      <PageContent>
        <Loader loading={isInProgress}>
          <AssetGroupPicker
            assetType={selectedAssetType}
            filteredImagesList={projectImages}
            filteredTexturesList={projectTextures}
            filteredModelsList={projectModels}
            selectedProject={projectId}
            hideChangingProjects
          />
        </Loader>
      </PageContent>
    </Page>
  );
};

export default AssetsByProjectPage;
