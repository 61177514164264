import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAssetsActions } from '../../../utils/hooks';
import Form from '../../Form';
import Field from '../../Form/Field';
import { TYPE_SEED } from '../../../utils/propTypes';
import { useUpdateSeed } from '../../../store/seed';

const SelfServiceProjectForm = ({ projectId, publicSeed }) => {
  const { updateSeedSelfServiceScope } = useUpdateSeed();

  const { projectImages, uploadImage, updateImagesAfterUpload } = useAssetsActions(projectId);

  const getLanguages = useCallback((seed = {}) => {
    const { settings = {} } = seed;
    const { translations = [] } = settings;

    return Array.isArray(translations)
      ? translations.filter(({ disabled }) => !disabled).map(({ name }) => ({ value: name, label: name }))
      : [];
  }, []);

  return (
    <Form initialValues={publicSeed} onSubmit={updateSeedSelfServiceScope} name="Settings">
      <Form.Group header="Intro">
        <Field.ArrayObject
          name="settings.intro"
          label="Intro settings"
          description="Add different settings to each used language. Must have at least one"
          defaultNew={{ language: getLanguages(publicSeed)[0]?.value }}
        >
          <Field.Select
            name="language"
            label="Language"
            description="Select language"
            options={getLanguages(publicSeed)}
          />
          <Field.Text name="title" label="Intro title" description="" />
          <Field.Markdown
            name="content"
            label="Intro content"
            description=""
            classes={{ preview: 'markdown-content' }}
          />
          <Field.Array
            component={Field.Image}
            componentProps={{ list: projectImages, uploadImage, onUploadSuccess: updateImagesAfterUpload }}
            internalFieldName="fileName"
            name="images"
            label="Images"
            description="Shown in gallery"
          />
          <Field.Text
            name="introVideo"
            label="Intro video"
            description="Url of intro video to show instead of the gallery. This can be either YouTube or Vimeo link"
          />
        </Field.ArrayObject>
      </Form.Group>
      <Form.Group header="Caveats">
        <Field.Array
          component={Field.Text}
          name="settings.interface.priceCaveat"
          label="Price Caveat"
          description="Paragraphs to show additional information about price"
        />
        <Field.Array
          component={Field.Text}
          name="settings.model.netAreaCaveat"
          label="Net area Caveat"
          description="Paragraphs to show additional information about net area"
        />
      </Form.Group>
      <Form.Group header="Footer">
        <Field.ArrayObject name="settings.footer.partnerLinks" label="Links">
          <Field.Text name="label" label="Label" required />
          <Field.Text name="url" label="Url" required />
        </Field.ArrayObject>
      </Form.Group>
      <Form.Group header="Controls extra info">
        <Field.ArrayObject
          isStatic
          staticEmptyAlert="There are no extra info collections defined to modify"
          name="settings.extraInfoCollections"
          label="Extra info collections"
          uniqueField="name"
          defaultNew={{ name: 'Untitled collection' }}
          description="These collections can be used for options' and 3D sprites' more info panels"
        >
          <Field.Immutable name="name" label="Name" description="Unique name for the collection" />
          <Field.ArrayObject
            name="infoPanels"
            label="Image and description panels"
            description="These will be shown in a gallery with descriptive texts"
          >
            <Field.Text name="name" label="Name" description="" />
            <Field.Text name="text" label="Content" description="" />
            <Field.Text name="caption" label="Caption" description="" />
            <Field.Image
              name="image.fileName"
              label="Image"
              description=""
              list={projectImages}
              uploadImage={uploadImage}
              onUploadSuccess={updateImagesAfterUpload}
            />
          </Field.ArrayObject>
          <Field.Text
            name="externalLink.label"
            label="External link label"
            description={
              <span>
                This is used in <strong>imagesFullscreenControls</strong>.
              </span>
            }
          />
          <Field.Text
            name="externalLink.url"
            label="External link url"
            description={
              <span>
                This is used in <strong>imagesFullscreenControls</strong>.
              </span>
            }
          />
        </Field.ArrayObject>
      </Form.Group>
      <Form.Group header="Translations">
        <Field.ArrayObject
          uniqueField="name"
          name="settings.translations"
          label="Translations"
          description="Here add various translations for different languages. Base language is English."
          defaultNew={{ name: 'New language', disabled: false, definitions: [] }}
        >
          <Field.Text name="name" label="Language" description="Must be unique" required />
          <Field.Toggle name="disabled" label="Disable language" />
          <Field.ArrayObject name="definitions" label="Definitions">
            <Field.Text name="key" label="Original" />
            <Field.Text name="value" label="Translation" />
          </Field.ArrayObject>
        </Field.ArrayObject>
      </Form.Group>
    </Form>
  );
};

SelfServiceProjectForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  publicSeed: TYPE_SEED.isRequired
};

export default SelfServiceProjectForm;
