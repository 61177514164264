import { FC, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { InstanceType } from 'types';
import { OnChangeValue } from 'react-select';
import api from '../../utils/api/routes';

interface TemplateInstanceSelectorProps {
  partnerId: string;
  onSelect: (instanceId: string) => void;
}

const TemplateInstanceSelector: FC<TemplateInstanceSelectorProps> = ({ partnerId, onSelect }) => {
  const getInstances = useCallback(
    async (inputValue: string) => {
      const { data } = await api.instances.getListByPartner(partnerId, inputValue);

      return data as InstanceType[];
    },
    [partnerId]
  );

  const handleChange = useCallback(
    (value: OnChangeValue<InstanceType, false>) => {
      if (!value) return;

      onSelect(value._id);
    },
    [onSelect]
  );

  return (
    <AsyncSelect<InstanceType>
      cacheOptions
      defaultOptions
      loadOptions={getInstances}
      onChange={handleChange}
      getOptionLabel={option => `${option.instanceName || ''} [${option._id}]`}
      getOptionValue={option => option._id}
    />
  );
};

export default TemplateInstanceSelector;
