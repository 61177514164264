import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import b from 'b_';
import { compose } from 'redux';
import { plotsActions, plotsSelectors } from '../../../modules/plots';
import { projectsSelectors } from '../../../modules/projects';
import { TYPE_PLOT_LIST, TYPE_SUGGESTION_LIST } from '../../../utils/propTypes';
import Loader from '../../Loader';
import { withUserRights } from '../../UserRights';
import { RIGHTS } from '../../../utils/rights';
import { defaultFilterMethod, selectFilterMethod } from '../../../utils/helpers/table';
import PlotForm from '../PlotForm';
import SelectFilter from '../../SelectFilter';
import { api } from '../../../store/services/api';

const plotList = b.with('plot-list');

export class PlotList extends React.PureComponent {
  state = {
    expanded: {}
  };

  componentDidMount() {
    this.props.fetchPlots();

    const { unsubscribe } = this.props.fetchProjects();

    this.unsubscribe = unsubscribe;
  }

  componentWillUnmount() {
    this.unsubscribe?.();
  }

  handleExpandedChange = expanded => {
    this.setState({ expanded });
  };

  handleSubmitPlotForm = plot => {
    this.props.updatePlot(plot);
  };

  handleDeletePlot = id => {
    this.props.deletePlot(id);
  };

  renderSelectFilter = ({ filter, onChange }, options = []) => {
    return <SelectFilter options={options} filter={filter} onChange={onChange} />;
  };

  render() {
    const { plots, developments, projects, request, hasRights } = this.props;
    const { expanded } = this.state;

    const columns = [
      {
        resizable: false,
        expander: true,
        show: hasRights([RIGHTS.PLOTS__UPDATE])
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
        className: 'fw-bold p-2'
      },

      {
        Header: 'Development',
        accessor: 'development',
        width: 120,
        className: 'fw-bold p-2',
        filterMethod: selectFilterMethod,
        Filter: filter => this.renderSelectFilter(filter, developments)
      },
      {
        id: 'project',
        Header: 'Project',
        accessor: 'projectId',
        className: 'p-2',
        width: 160,
        Cell: row => row.original.populated.projectName || '',
        filterMethod: selectFilterMethod,
        Filter: filter => this.renderSelectFilter(filter, projects)
      },
      {
        Header: 'Code',
        accessor: 'code',
        width: 70,
        className: 'p-2'
      },
      {
        Header: 'Plot Type',
        accessor: 'plotType',
        width: 150,
        className: 'p-2'
      },
      {
        Header: 'Price',
        accessor: 'price',
        width: 80,
        className: 'p-2'
      },
      {
        Header: 'Description',
        accessor: 'description',
        className: 'p-2'
      },
      {
        Header: 'Cut-off date',
        accessor: 'cutOffDate',
        className: 'p-2'
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        className: 'p-2',
        filterMethod: selectFilterMethod,
        Filter: filter => this.renderSelectFilter(filter, this.props.statuses)
      }
    ];

    return (
      <Loader loading={request}>
        <ReactTable
          data={plots}
          pageSize={plots.length}
          showPagination={false}
          columns={columns}
          expanded={expanded}
          filterable
          resizable={false}
          defaultSorted={[{ id: 'name', desc: false }]}
          defaultFilterMethod={defaultFilterMethod}
          onExpandedChange={this.handleExpandedChange}
          className={`${plotList()} app-entity-list -striped -highlight`}
          SubComponent={row => (
            <div className="app-entity-list__form bg-light">
              <PlotForm plot={row.original} onSubmit={this.handleSubmitPlotForm} onDelete={this.handleDeletePlot} />
            </div>
          )}
        />
      </Loader>
    );
  }
}

PlotList.defaultProps = {
  plots: [],
  developments: [],
  statuses: [],
  projects: [],
  request: false
};

PlotList.propTypes = {
  fetchProjects: PropTypes.func.isRequired,
  fetchPlots: PropTypes.func.isRequired,
  updatePlot: PropTypes.func.isRequired,
  deletePlot: PropTypes.func.isRequired,
  hasRights: PropTypes.func.isRequired,
  plots: TYPE_PLOT_LIST,
  projects: TYPE_SUGGESTION_LIST,
  developments: TYPE_SUGGESTION_LIST,
  statuses: TYPE_SUGGESTION_LIST,
  request: PropTypes.bool
};

const mapStateToProps = state => ({
  plots: plotsSelectors.selectPlotList(state),
  developments: plotsSelectors.selectPlotDevelopmentList(state),
  statuses: plotsSelectors.selectPlotStatusList(state),
  request: plotsSelectors.selectPlotsRequest(state),
  projects: projectsSelectors.selectProjectListForSuggestions(state)
});

const mapDispatchToProps = {
  fetchProjects: api.endpoints.getProjects.initiate,
  fetchPlots: plotsActions.fetchPlots,
  updatePlot: plotsActions.updatePlot,
  deletePlot: plotsActions.deletePlot
};

export default compose(withUserRights, connect(mapStateToProps, mapDispatchToProps))(PlotList);
