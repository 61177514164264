import { PartnerType, ActionType, BaseInitialStateType } from 'types';
import { reducer } from '../@helpers';
import { baseInitialState } from '../@helpers/reducer';
import partnersActions from './partnersActions';

interface ModuleStateType<T> extends BaseInitialStateType<T> {
  apiKeyRequest: boolean;
}

type StateType = ModuleStateType<PartnerType>;

const moduleInitialState: StateType = {
  ...baseInitialState,
  apiKeyRequest: false
};

function moduleReducer(state: StateType, { type, payload = {} }: ActionType): StateType {
  switch (type) {
    case partnersActions.createAPIKey.request.ACTION_TYPE: {
      return { ...state, apiKeyRequest: true };
    }
    case partnersActions.createAPIKey.success.ACTION_TYPE: {
      const partner = payload;

      return {
        ...state,
        list: { ...state.list, [partner._id]: partner },
        apiKeyRequest: false
      };
    }
    case partnersActions.createAPIKey.failure.ACTION_TYPE:
      return { ...state, error: payload.error, apiKeyRequest: false };
    default:
      return state;
  }
}

export default reducer.createBaseModuleReducer<PartnerType, StateType>(
  partnersActions,
  moduleReducer,
  moduleInitialState
);
