import React from 'react';
import { TYPE_SEED } from '../../../utils/propTypes';
import { usePartList } from '../../../utils/hooks';
import Loader from '../../Loader/Loader';
import PriceEditor from '../../PricesPage/PricesEditor';

const SelfServicePricing = ({ seed }) => {
  const { request } = usePartList(seed._id);

  return (
    <Loader loading={request}>
      <PriceEditor seed={seed} />
    </Loader>
  );
};

SelfServicePricing.propTypes = {
  seed: TYPE_SEED.isRequired
};

export default SelfServicePricing;
