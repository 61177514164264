import { FC } from 'react';

interface PageTileProps {
  className?: string;
}

const PageTile: FC<PageTileProps> = ({ children, className = '' }) => {
  return <div className={`bg-white p-4 ${className}`}>{children}</div>;
};

export default PageTile;
