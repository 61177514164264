import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RemoteControlsEditor from '../../RemoteControlsPage/RemoteControlsEditor';
import { TYPE_PROJECT, TYPE_SEED } from '../../../utils/propTypes';
import { partsActions, partsSelectors } from '../../../modules/parts';
import { seedsSelectors } from '../../../modules/seeds';
import { assetsSelectors } from '../../../modules/assets';
import { useDeleteSeedRemoteControl, useUpdateSeedRemoteControl } from '../../../store/seed';

const SelfServiceControls = ({ project = {}, updateImagesAfterUpload, seed = {} }) => {
  const controlsList = useSelector(state => partsSelectors.selectRemoteControlsListBySeed(state, seed._id));
  const optionStatusList = useSelector(state => seedsSelectors.selectOptionStatusList(state, seed._id));
  const projectImages = useSelector(state => assetsSelectors.images.selectListByProjectId(state, project._id));
  const isPriceCodesEnabled = useSelector(state => seedsSelectors.selectPriceCodesEnabled(state, seed._id));

  const { updateSeedRemoteControl } = useUpdateSeedRemoteControl();
  const { deleteSeedRemoteControl } = useDeleteSeedRemoteControl();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partsActions.fetchParts(seed._id));
  }, [dispatch, project._id, seed._id]);

  const removeRemoteControlHandler = useCallback(
    remoteControl => {
      deleteSeedRemoteControl({ _id: seed._id, remoteControl });
    },
    [deleteSeedRemoteControl, seed._id]
  );

  const updateRemoteControlHandler = useCallback(
    remoteControl => {
      updateSeedRemoteControl({ _id: seed._id, remoteControl });
    },
    [updateSeedRemoteControl, seed._id]
  );

  return (
    <RemoteControlsEditor
      updateImagesAfterUpload={updateImagesAfterUpload}
      controlsList={controlsList}
      onSubmit={updateRemoteControlHandler}
      onRemoveRemoteControl={removeRemoteControlHandler}
      projectImages={projectImages}
      projectId={project._id}
      disableOptionPrice={isPriceCodesEnabled}
      optionStatusList={optionStatusList}
    />
  );
};

SelfServiceControls.propTypes = {
  project: TYPE_PROJECT.isRequired,
  seed: TYPE_SEED.isRequired,
  updateImagesAfterUpload: PropTypes.func.isRequired
};

export default SelfServiceControls;
