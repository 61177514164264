import axios from 'axios';
import { API_URL } from '../../config';
import ServerError from './serverError';

export const instance = axios.create({
  baseURL: API_URL,
  timeout: 7000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
});

async function callApi(method, ...args) {
  let response;
  let error;

  try {
    response = await method(...args);
  } catch (err) {
    error = ServerError.parse(err);
  }

  return { data: response ? response.data : null, error };
}

const api = {
  post: (url, data, config) => callApi(instance.post, url, data, config),
  get: (url, config) => callApi(instance.get, url, config),
  put: (url, data, config) => callApi(instance.put, url, data, config),
  patch: (url, data, config) => callApi(instance.patch, url, data, config),
  delete: (url, config) => callApi(instance.delete, url, config)
};

export default api;
