import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './EntityListPagesWrapper.scss';

const entityListPagesWrapper = b.with('entity-list-pages-wrapper');

const EntityListPagesWrapper = ({ header, list, btn }) => {
  return (
    <div className={`${entityListPagesWrapper()} flex-grow-1 w-100 p-3`}>
      {header || btn ? (
        <div className={`${entityListPagesWrapper('header')} d-flex justify-content-between align-items-center mb-3`}>
          <h3 className="h3 text-primary mb-0">{header}</h3>
          {btn}
        </div>
      ) : null}
      <div className={entityListPagesWrapper('list')}>{list}</div>
    </div>
  );
};

EntityListPagesWrapper.defaultProps = {
  btn: null
};

EntityListPagesWrapper.propTypes = {
  header: PropTypes.string.isRequired,
  list: PropTypes.node.isRequired,
  btn: PropTypes.node
};

export default EntityListPagesWrapper;
