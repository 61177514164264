import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../../../Atoms';

const StaticEmptyAlert = ({ isEmpty, content }) => (isEmpty && content ? <Alert color="info">{content}</Alert> : null);

StaticEmptyAlert.propTypes = {
  isEmpty: PropTypes.bool,
  content: PropTypes.string
};

StaticEmptyAlert.defaultProps = {
  isEmpty: false,
  content: ''
};

export default StaticEmptyAlert;
