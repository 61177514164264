import React from 'react';

interface ArrayContextType {
  arrayName?: string;
  isSortable?: boolean;
  isStatic?: boolean;
  remove?: (index?: number | number[]) => void;
}

const ArrayContext = React.createContext<ArrayContextType>({});

ArrayContext.displayName = 'ArrayContext';

export default ArrayContext;
