import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Button } from '../../Atoms';
import { partsActions } from '../../../modules/parts';
import { useRootPartBySeedId } from '../../../utils/hooks';
import getPartFieldsToUpdate from '../../../utils/getPartFieldsToUpdate';

const AddViewButton = ({ seedId, viewsLength }) => {
  const rootPart = useRootPartBySeedId(seedId);
  const dispatch = useDispatch();

  const handleAddView = useCallback(() => {
    const newPart = { ...rootPart };

    if (!newPart.Views) newPart.Views = {};

    if (!newPart.Views.list) newPart.Views.list = [];

    const newIndex = newPart.Views.list.length;
    const dummyView = {
      name: `new_view_${newIndex}`,
      viewIndex: viewsLength,
      type: 'View',
      icon: 'app_view-exterior'
    };

    newPart.Views.list.push(dummyView);

    dispatch(partsActions.updatePart(getPartFieldsToUpdate(newPart, ['Views'])));
  }, [dispatch, rootPart, viewsLength]);

  if (!rootPart) {
    return <Alert color="warning">No root part found. Cannot add tabs.</Alert>;
  }

  return (
    <Button color="outline-primary" onClick={handleAddView}>
      + Add (by default to root part)
    </Button>
  );
};

AddViewButton.propTypes = {
  seedId: PropTypes.string.isRequired,
  viewsLength: PropTypes.number.isRequired
};

export default AddViewButton;
