import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { useKeys } from '../../../utils/hooks';

const useArrayFieldMutators = ({ fields }: FieldArrayRenderProps<any, any>) => {
  const { keys, removeHandler: onRemove, moveHandler: onMove, addHandler: onPush } = useKeys(fields.length);

  const [removeAction, setRemoveAction] = useState<number>();
  const [addAction, setAddAction] = useState();
  const [moveAction, setMoveAction] = useState<number[]>([]);
  const [mounted, setMounted] = useState<Record<number, boolean>>({});

  // effect is run after rendering so all necessary items are mounted
  // make the changes
  useEffect(() => {
    if (removeAction !== undefined) {
      fields.remove(removeAction);
      onRemove(removeAction);
      setRemoveAction(undefined);
    }
  }, [fields, onRemove, removeAction]);

  useEffect(() => {
    if (addAction !== undefined) {
      fields.push(addAction);
      onPush();
      setAddAction(undefined);
    }
  }, [addAction, fields, onPush]);

  useEffect(() => {
    const [from, to] = moveAction;

    if (from !== undefined && to !== undefined && mounted[from] && mounted[to]) {
      fields.move(from, to);
      onMove(from, to);
      setMoveAction([]);
    }
  }, [fields, mounted, moveAction, onMove]);

  const moveHandler = useCallback((from: number, to: number) => {
    // generate all indices that need to be mounted
    const step = from < to ? 1 : -1;
    const range = _.range(from, to + step, step);

    const forceMounted = range.reduce<Record<number, boolean>>((result, item: number) => {
      // eslint-disable-next-line no-param-reassign
      result[item] = true;

      return result;
    }, {});

    setMounted(current => ({ ...current, ...forceMounted }));
    setMoveAction([from, to]);
  }, []);

  return { keys, removeHandler: setRemoveAction, moveHandler, addHandler: setAddAction, forceMount: mounted };
};

export default useArrayFieldMutators;
