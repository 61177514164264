import { Col, Icon, PageTile, Row, RoundedBox } from '../Atoms';
import { getProjectUrl } from '../../utils/appRoutes';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';

interface ProjectDashboardLinksProps {
  projectId: string;
}

const ProjectDashboardLinks = ({ projectId }: ProjectDashboardLinksProps) => {
  const hasRights = useUserRights();

  return (
    <PageTile>
      {hasRights([RIGHTS.PROJECTS__UPDATE]) ? (
        <>
          <h5>Settings</h5>
          <Row className="border-bottom mb-4">
            <Col className="d-flex pb-4" sm={6}>
              <RoundedBox link={getProjectUrl(projectId, 'info')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiFolderSettingsOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Basic Project Info</u>
                  </div>
                  <small className="text-muted">Project name, Partner, URL</small>
                </div>
              </RoundedBox>
            </Col>
            <Col className="d-flex pb-4" sm={6}>
              <RoundedBox link={getProjectUrl(projectId, 'settings')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiFolderCogOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Project Settings</u>
                  </div>
                  <small className="text-muted">
                    Applied to all configurators, can be overridden from version settings
                  </small>
                </div>
              </RoundedBox>
            </Col>
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.PROJECTS__EDIT_ASSETS]) ? (
        <>
          <h5 className="mt-4">Assets</h5>
          <Row className="border-bottom">
            <Col className="d-flex pb-4" sm={6}>
              <RoundedBox link={getProjectUrl(projectId, 'textures')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiTexture" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Textures</u>
                  </div>
                  <small className="text-muted">Used in 3D models</small>
                </div>
              </RoundedBox>
            </Col>
            <Col className="d-flex pb-4" sm={6}>
              <RoundedBox link={getProjectUrl(projectId, 'images')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiImageOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Images</u>
                  </div>
                  <small className="text-muted">Used in UI, gallery, extra info</small>
                </div>
              </RoundedBox>
            </Col>
            <Col className="d-flex pb-4" sm={6}>
              <RoundedBox link={getProjectUrl(projectId, 'models')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiCubeOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Models</u>
                  </div>
                  <small className="text-muted">3D files</small>
                </div>
              </RoundedBox>
            </Col>
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.PROJECTS__SELF_SERVICE]) ? (
        <>
          <h5 className="mt-4">Self-service</h5>
          <Row className="border-bottom">
            <Col className="d-flex pb-4">
              <RoundedBox link={getProjectUrl(projectId, 'self-service')}>
                <span className="text-primary pe-2">
                  <Icon type="mdiAccountBoxOutline" />
                </span>
                <div>
                  <div>
                    <u className="text-dark fw-bolder">Self Service</u>
                  </div>
                  <small className="text-muted">For customers</small>
                </div>
              </RoundedBox>
            </Col>
          </Row>
        </>
      ) : null}
    </PageTile>
  );
};

export default ProjectDashboardLinks;
