import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Spinner } from '../Atoms';
import Loader from '../Loader';
import { usePartList } from '../../utils/hooks';
import { partsSelectors } from '../../modules/parts';
import { seedsSelectors } from '../../modules/seeds';
import LightList from './LightList';

const LightsPage = () => {
  const { seedId } = useParams();

  const { request } = usePartList(seedId);
  const updatingParts = useSelector(partsSelectors.selectPartsUpdatingFlag);
  const rootPartId = useSelector(state => seedsSelectors.selectRootPartId(state, seedId));
  const conflicts = useSelector(state => partsSelectors.selectLightsConflict(state, seedId));

  return (
    <div className="w-100 p-3">
      <Loader loading={request}>
        <Alert color="info">
          Here you can define lights. You can also change the parent part of lights. Lights with parent other than the
          root part will be loaded only if the part is loaded. Non-root lights are marked with a small offset
        </Alert>
        {rootPartId ? null : <Alert color="danger">No root part found. Cannot add lights.</Alert>}
        {conflicts.length ? (
          <Alert color="danger">
            Warning: All light names must be unique. The following names are defined multiple times:{' '}
            {conflicts.map(light => `${light.name} (${light.count})`).join(', ')}
          </Alert>
        ) : null}
        <div className="mb-4">
          <h5>Sunlights</h5>
          <p>List of sunlights that can be applied in Views</p>
          <LightList type="sunlight" seedId={seedId} />
        </div>
        <div className="mb-4">
          <h5>Skylights</h5>
          <p>List of skylights that can be applied in Views</p>
          <LightList type="skylight" seedId={seedId} />{' '}
        </div>
        <h5>Custom lights</h5>
        <LightList type="custom" seedId={seedId} />{' '}
      </Loader>
      {updatingParts ? (
        <div className="app-overlay">
          <Spinner color="primary" />
        </div>
      ) : null}
    </div>
  );
};

export default LightsPage;
