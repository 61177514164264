import { flatten, get } from 'lodash';
import React, { PropsWithChildren, useContext, useEffect, useState, useCallback } from 'react';
import { useFieldArray, useFormContext, FieldArrayWithId, Path } from 'react-hook-form';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { FormContext } from '../../FormContext';
import FieldWrapper from '../FieldWrapper';
import { ArrayFieldName, ArrayProps, FieldType } from '../../types';
import ArrayItem from './ArrayItem';
import ArrayContext from './ArrayContext';

const ArrayWrapper = <T, N extends ArrayFieldName<T> = ArrayFieldName<T>>({
  name,
  label,
  description,
  required,
  isStatic,
  isSortable = true,
  headerFormat,
  children,
  twoColumns,
  component,
  useShadowValue,
  defaultNew,
  noWrapper,
  hideFollowingLabels,
  level = 0
}: PropsWithChildren<ArrayProps<T, N>>) => {
  const { control, getValues, setValue, watch, trigger } = useFormContext<T>();
  const { fields, remove, move } = useFieldArray<T>({ control, name: name as ArrayFieldName<T> });
  const { readOnly: contextReadOnly } = useContext(FormContext);

  const singleComponentMode = !!component;

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id !== over?.id) {
      const oldIndex = fields.findIndex(item => item.id === active.id);
      const newIndex = fields.findIndex(item => item.id === over?.id);

      move(oldIndex, newIndex);
    }
  };

  const [current, setCurrent] = useState<any>();

  useEffect(() => {
    setCurrent(watch());

    const watchAll = watch(value => {
      setCurrent(value);
    });

    // unsubscribe
    return () => watchAll.unsubscribe();
  }, [watch, setCurrent]);

  const getTitle = useCallback(
    (index: number) => {
      const currentPart: any = get(current, `${name}.${index}`);

      return headerFormat ? headerFormat(currentPart as unknown as FieldArrayWithId<T, N, 'id'>) : '';
    },
    [headerFormat, current, name]
  );

  const arrayItems = fields.map((item, index) => {
    return (
      <ArrayItem
        key={item.id}
        item={item}
        title={getTitle(index)}
        index={index}
        twoColumns={twoColumns}
        useShadowValue={useShadowValue}
        noWrapper={noWrapper}
        noLabel={hideFollowingLabels && index > 0}
        level={level + 1}
        onToggle={trigger}
      >
        {Array.isArray(children) ? flatten(children) : children}
      </ArrayItem>
    );
  });

  const singleComponentItems = fields.map((item, index) => {
    return (
      <ArrayItem
        key={item.id}
        item={item}
        title={getTitle(index)}
        index={index}
        twoColumns={twoColumns}
        singleComponentMode={singleComponentMode}
        useShadowValue={useShadowValue}
        noWrapper={noWrapper}
        noLabel={hideFollowingLabels && index > 0}
        onToggle={trigger}
      >
        {component}
      </ArrayItem>
    );
  });

  const items = component ? singleComponentItems : arrayItems;

  const emptyState = (
    <div style={{ border: '2px dashed #bdbdbd' }} className="w-100 text-center rounded p-2">
      <small className="text-muted">empty</small>
    </div>
  );

  const itemOrEmpty = items.length ? <div className="border-start border-dark border-4 ps-2">{items}</div> : emptyState;

  return (
    <ArrayContext.Provider value={{ arrayName: name, isSortable, isStatic, remove }}>
      <FieldWrapper
        name={name}
        type={FieldType.ARRAY}
        label={label}
        description={description}
        required={required}
        useShadowValue={useShadowValue}
        shadowChildren={
          singleComponentMode ? React.createElement(component.type, { ...component.props, isFullName: true }) : children
        }
        headerFormat={headerFormat}
        level={level}
        isComplex
      >
        {isSortable ? (
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              {itemOrEmpty}
            </SortableContext>
          </DndContext>
        ) : (
          itemOrEmpty
        )}
        {isStatic || contextReadOnly ? null : (
          <div className="w-100 text-center">
            <button
              type="button"
              className="btn btn-block btn-outline-primary mt-4 mx-auto px-5"
              onClick={() => {
                if (singleComponentMode) {
                  // append won't help here since officially RHS does not support flat arrays
                  // https://github.com/react-hook-form/react-hook-form/issues/3462
                  const currentArray: any = getValues(name as Path<T>);
                  let value;

                  if (currentArray) {
                    // this join is caused by the same issue - RHS does not support flat arrays
                    value = [...currentArray?.map((a: any) => Object.values(a).join('')), defaultNew || ''];
                  } else {
                    value = [defaultNew || ''];
                  }

                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue(name as Path<T>, value);
                  // ignore because of: https://github.com/react-hook-form/react-hook-form/issues/2978 (should be reopened)
                } else {
                  const currentArray: any = getValues(name as Path<T>) || [];
                  const value = [...currentArray];

                  value.push({});
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue(name as Path<T>, value);
                }
              }}
            >
              + Add
            </button>
          </div>
        )}
      </FieldWrapper>
    </ArrayContext.Provider>
  );
};

export default ArrayWrapper;
