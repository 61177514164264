import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { ApplicationRoute, getProjectUrl, getSeedUrl } from '../../../utils/appRoutes';
import BreadcrumbSection, { BreadcrumbItemListType } from '../BreadcrumbSection';
import BreadcrumbBackButton from '../BreadcrumbBackButton';
import { useProjectList } from '../../../store/project';
import { useSeeds } from '../../../store/seed';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';

const parserUrl: Record<string, (id: string) => LocationDescriptor> = {
  [ApplicationRoute.Seed]: id => getSeedUrl(id),
  [ApplicationRoute.SeedInfo]: id => getSeedUrl(id, 'info'),
  [ApplicationRoute.SeedSettings]: id => getSeedUrl(id, 'settings'),
  [ApplicationRoute.SeedGraph]: id => getSeedUrl(id, 'graph'),
  [ApplicationRoute.SeedParts]: id => getSeedUrl(id, 'parts'),
  [ApplicationRoute.SeedMaterials]: id => getSeedUrl(id, 'materials'),
  [ApplicationRoute.SeedLights]: id => getSeedUrl(id, 'lights'),
  [ApplicationRoute.SeedViews]: id => getSeedUrl(id, 'views'),
  [ApplicationRoute.SeedTabs]: id => getSeedUrl(id, 'tabs'),
  [ApplicationRoute.SeedPrices]: id => getSeedUrl(id, 'prices'),
  [ApplicationRoute.SeedRemoteControls]: id => getSeedUrl(id, 'remote-controls'),
  [ApplicationRoute.SeedRemoteSwitches]: id => getSeedUrl(id, 'remote-switches')
};

const lastSection: Record<string, { group: string; title: string; rights: string[] }> = {
  [ApplicationRoute.SeedInfo]: { group: 'Settings', title: 'Base Version Info', rights: [RIGHTS.SEEDS__UPDATE] },
  [ApplicationRoute.SeedSettings]: { group: 'Settings', title: 'Version Settings', rights: [RIGHTS.SEEDS__UPDATE] },
  [ApplicationRoute.SeedGraph]: { group: 'Logic', title: 'Graph Editor', rights: [RIGHTS.PARTS__READ] },
  [ApplicationRoute.SeedParts]: { group: 'Logic', title: 'Table Editor', rights: [RIGHTS.PARTS__READ] },
  [ApplicationRoute.SeedMaterials]: {
    group: 'Components',
    title: 'Materials',
    rights: [RIGHTS.SEEDS__MATERIALS_UPDATE]
  },
  [ApplicationRoute.SeedLights]: { group: 'Components', title: 'Lights', rights: [RIGHTS.PARTS__UPDATE] },
  [ApplicationRoute.SeedViews]: { group: 'Components', title: 'Cameras', rights: [RIGHTS.PARTS__UPDATE] },
  [ApplicationRoute.SeedTabs]: { group: 'Components', title: 'Phases', rights: [RIGHTS.PARTS__UPDATE] },
  [ApplicationRoute.SeedPrices]: { group: 'Components', title: 'Prices', rights: [RIGHTS.SEEDS__PRICING_UPDATE] },
  [ApplicationRoute.SeedRemoteControls]: {
    group: 'Remote',
    title: 'Remote Controls',
    rights: [RIGHTS.REMOTE_CONTROLS__UPDATE]
  },
  [ApplicationRoute.SeedRemoteSwitches]: {
    group: 'Remote',
    title: 'Remote Switches',
    rights: [RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]
  }
};

const SeedsBreadcrumbs: FC = () => {
  const match = useRouteMatch<{ seedId: string }>();
  const { projects } = useProjectList();
  const { seeds } = useSeeds();
  const hasRights = useUserRights();

  const currentSeed = useMemo(() => seeds[match.params.seedId], [match.params.seedId, seeds]);

  const projectList = useMemo(
    () =>
      projects.map(project => ({
        label: project.name,
        id: project._id,
        path: getProjectUrl(project._id)
      })),
    [projects]
  );

  const seedList = useMemo(
    () =>
      Object.values(seeds).reduce((result: BreadcrumbItemListType, seed: any) => {
        if (seed.projectId === currentSeed?.projectId) {
          result.push({
            label: `${seed.name}: ${seed.version}`,
            id: seed._id,
            path: parserUrl[match.path](seed._id)
          });
        }

        return result;
      }, []),
    [currentSeed, seeds, match.path]
  );

  const lastSectionsList = useMemo(
    () =>
      Object.keys(lastSection).reduce((result: BreadcrumbItemListType, section: any) => {
        if (hasRights(lastSection[section].rights)) {
          result.push({
            label: lastSection[section].title,
            group: lastSection[section].group,
            id: section,
            path: parserUrl[section](currentSeed?._id || '')
          });
        }

        return result;
      }, []),
    [currentSeed, hasRights]
  );

  const backLink: LocationDescriptor =
    match.path === ApplicationRoute.Seed || !currentSeed
      ? getProjectUrl(currentSeed?.projectId)
      : getSeedUrl(currentSeed._id);

  const lastSectionTitle = lastSection[match.path];

  return (
    <>
      <BreadcrumbBackButton to={backLink} />
      <BreadcrumbSection title="Projects" list={projectList} activeId={currentSeed?.projectId} activeLink />
      <BreadcrumbSection
        title="Versions"
        list={seedList}
        activeId={match.params.seedId}
        activeLink={lastSectionTitle ? backLink : false}
      />
      {lastSectionTitle ? <BreadcrumbSection title="Edit" list={lastSectionsList} activeId={match.path} /> : null}
    </>
  );
};

export default SeedsBreadcrumbs;
