import { useWindowSize } from '../modules/ui/uiHooks';
import { useUiSettings } from '../store/modules/uiSettings';
import { Button, Icon, Navbar } from './Atoms';
import UserMenu from './UserMenu';
import Breadcrumbs from './Breadcrumbs';

const Header = () => {
  const { xs } = useWindowSize();
  const { openSidebar } = useUiSettings();

  return (
    <Navbar light className="header bg-light border-bottom shadow-sm flex-nowrap" sticky="top">
      {xs ? (
        <div className="border-right px-2">
          <Button onClick={openSidebar} color="icon" size="sm">
            <Icon type="mdiMenu" scale={1.2} />
          </Button>
        </div>
      ) : null}
      <Breadcrumbs />
      <UserMenu />
    </Navbar>
  );
};

export default Header;
