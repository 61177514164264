import { FC } from 'react';
import { BSClassNameFormatter, BSPaginationSize } from '../helpers';

interface PaginationProps {
  size?: BSPaginationSize;
}

const Pagination: FC<PaginationProps> = ({ size, children }) => {
  return (
    <nav>
      <ul className={BSClassNameFormatter('pagination', { [`${size}`]: Boolean(size) })}>{children}</ul>
    </nav>
  );
};

export default Pagination;
