import { Badge, Col, PageTile, Row } from '../Atoms';
import ConfiguratorLink from '../ConfiguratorLink';
import { useProjects } from '../../store/project';
import SeedPageWrapper from './SeedPageWrapper';
import SeedDashboardActions from './SeedDashboardActions';
import SeedDashboardLinks from './SeedDashboardLinks';

const SeedDashboard = () => {
  const { projects } = useProjects();

  return (
    <SeedPageWrapper>
      {seed => (
        <>
          <Row>
            <Col lg={7} md={6} sm={12} className="mb-4">
              <PageTile className="h-100">
                <span className="d-inline-flex align-items-center">
                  {projects[seed.projectId]?.publicSeedId === seed._id ? (
                    <span className="d-inline-flex me-2">
                      <Badge color="warning" pill>
                        Public
                      </Badge>
                    </span>
                  ) : null}
                  <span className="text-muted">{seed.version}</span>
                </span>
                <h2 className="fw-bold mb-0">{seed.name}</h2>
                <div className="overflow-hidden">
                  <ConfiguratorLink type="seed" link={seed._id} />
                </div>
              </PageTile>
            </Col>
            <Col lg={5} md={6} sm={12} className="mb-4">
              <SeedDashboardActions seed={seed} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SeedDashboardLinks seedId={seed._id} />
            </Col>
          </Row>
        </>
      )}
    </SeedPageWrapper>
  );
};

export default SeedDashboard;
