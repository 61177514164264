import { FC, useCallback } from 'react';
import { TemplateType } from 'types';
import { Col, Form, FormField, PageTile, Row } from '../Atoms';
import { useUpdateTemplate } from '../../store/template';

interface TemplateFormProps {
  template: TemplateType;
}

const formatOptions = ['A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'Letter', 'Legal', 'Tabloid', 'Ledger'].map(label => ({
  label,
  value: label
}));

const marginRegexp = /^\d+(px|in|cm|mm)$/;

const TemplateForm: FC<TemplateFormProps> = ({ template, children }) => {
  const { updateTemplate } = useUpdateTemplate();

  const handleSubmit = useCallback(
    (formValues: TemplateType) => {
      updateTemplate(formValues);
    },
    [updateTemplate]
  );

  return (
    <Form<TemplateType> defaultValues={template} onSubmit={handleSubmit}>
      <Row className="mb-4">
        <Col md={6} sm={12} className="mb-4 mb-md-0">
          <PageTile className="h-100">
            <h5 className="mb-4">Settings</h5>
            <FormField.Text<TemplateType> name="name" required />
            <FormField.Select<TemplateType> name="options.format" label="Page size" options={formatOptions} required />
            <FormField.Switch<TemplateType> name="options.landscape" label="Landscape orientation" />
            <FormField.Text<TemplateType>
              name="options.margin.top"
              label="Header height"
              description="Available units: px|in|cm|mm"
              pattern={marginRegexp}
            />
            <FormField.Text<TemplateType>
              name="options.margin.bottom"
              label="Footer height"
              description="Available units: px|in|cm|mm"
              pattern={marginRegexp}
            />
          </PageTile>
        </Col>
        {children}
      </Row>
    </Form>
  );
};

export default TemplateForm;
