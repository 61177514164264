import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCreateTemplate, useTemplateList } from '../../store/template';
import { Button, ListPageWrapper } from '../Atoms';
import { partnersSelectors } from '../../modules/partners';
import { usePartners } from '../../modules/partners/partnersHooks';
import TemplateListItem from './TemplateListItem';

const TemplateList: FC = () => {
  const { partnerId } = useParams<{ partnerId: string }>();
  const { templates, isInProgress } = useTemplateList(partnerId);
  const { createTemplate } = useCreateTemplate();

  const handleCreate = useCallback(() => {
    const name = window.prompt('Specify template name'); // eslint-disable-line no-alert

    if (name) {
      createTemplate({ name, partnerId });
    }
  }, [createTemplate, partnerId]);

  // TODO: later
  const partners = useSelector(partnersSelectors.selectPartners);
  const { activeTemplateId } = partners[partnerId] || {};

  usePartners();

  return (
    <ListPageWrapper
      title="PDF Templates"
      loading={isInProgress}
      button={
        <Button onClick={handleCreate} color="primary" outline>
          + Add
        </Button>
      }
    >
      {templates.length === 0 ? (
        <div className="d-flex">
          <span className="my-4 mx-auto">No templates found</span>
        </div>
      ) : (
        templates.map(template => (
          <TemplateListItem key={template._id} template={template} active={activeTemplateId === template._id} />
        ))
      )}
    </ListPageWrapper>
  );
};

export default TemplateList;
