import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedButton from '../ProtectedButton';
import { RIGHTS } from '../../utils/rights';
import EntityListPagesWrapper from '../EntityListPagesWrapper';
import { ApplicationRoute } from '../../utils/appRoutes';
import PartnerList from './PartnerList';

const PartnerCreation = (
  <ProtectedButton
    rights={[RIGHTS.PARTNERS__CREATE]}
    tag={Link}
    to={ApplicationRoute.PartnerCreation}
    color="primary"
    outline
  >
    Create new partner
  </ProtectedButton>
);

const PartnersPage = () => <EntityListPagesWrapper header="Partners" list={<PartnerList />} btn={PartnerCreation} />;

export default PartnersPage;
