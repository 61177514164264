import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteComponentProps, RouteProps } from 'react-router';
import { useUserRights } from '../utils/hooks';
import { authSelectors } from '../modules/auth';
import { ApplicationRoute } from '../utils/appRoutes';
import { useAppSelector } from '../store/hooks';
import Layout from './Layout';
import { PROTECTED_ROUTE_LIST, PUBLIC_ROUTES } from './RouteList';

interface LocationState {
  referrer?: string;
}

// Route for none-authenticated user only
interface PublicRouteProps extends RouteProps {
  loggedIn: boolean;
}

const PublicRoute: FC<PublicRouteProps> = ({ loggedIn, component: Component, location, ...rest }) => {
  const { referrer = ApplicationRoute.Main } = (location?.state || {}) as LocationState;

  if (!Component) return null;

  return (
    <Route {...rest} render={routeProps => (loggedIn ? <Redirect to={referrer} /> : <Component {...routeProps} />)} />
  );
};

// Route for authenticated user only who has required permissions
interface ProtectedRouteProps extends PublicRouteProps {
  hasAccess: boolean;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ loggedIn, hasAccess, component: Component, location, ...rest }) => {
  const render = (routeProps: RouteComponentProps) => {
    if (!loggedIn) {
      return <Redirect to={{ pathname: ApplicationRoute.SignIn, state: { referrer: location?.pathname } }} />;
    }

    if (hasAccess && Component) {
      return <Component {...routeProps} />;
    }

    return <Redirect to={ApplicationRoute.Main} />;
  };

  return <Route {...rest} render={render} />;
};

const Routes = () => {
  const hasRights = useUserRights();
  const loggedIn: boolean = useAppSelector(authSelectors.selectLoggedIn);

  return (
    <Layout loggedIn={loggedIn}>
      <Switch>
        {PUBLIC_ROUTES.map(({ path, component }) => (
          <PublicRoute key={path} path={path} component={component} loggedIn={loggedIn} exact />
        ))}
        {PROTECTED_ROUTE_LIST.map(({ path, component, rights }) => (
          <ProtectedRoute
            key={path}
            path={path}
            component={component}
            hasAccess={hasRights(rights)}
            loggedIn={loggedIn}
            exact
          />
        ))}
      </Switch>
    </Layout>
  );
};

export default Routes;
