import { FC } from 'react';
import Header from './Header';
import Notifications from './Notifications';
import TermsOfUse from './TermsOfUse';
import Sidebar from './Sidebar';

interface LayoutProps {
  loggedIn: boolean;
}

const Layout: FC<LayoutProps> = ({ children, loggedIn }) => {
  if (!loggedIn) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="main d-flex flex-row w-100">
        <Sidebar />
        <div className="d-flex flex-column flex-grow-1 overflow-auto h-100">
          <Header />
          {children}
        </div>
      </div>
      <Notifications />
      <TermsOfUse />
    </>
  );
};

export default Layout;
