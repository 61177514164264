import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = () => (
  <span className="align-items-center d-flex me-2" style={{ cursor: 'move' }}>
    <i className="fa fa-fw fa-arrows" />
  </span>
);

export default SortableHandle(DragHandle);
