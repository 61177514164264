import { actions } from '../@helpers';

const MODULE_NAME = 'INVITATIONS';
const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const invitationsActions = {
  FETCH_INVITATIONS: moduleActions.fetchList.ACTION_TYPE,
  CREATE_INVITATION: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_INVITATION: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_INVITATION: moduleActions.deleteEntity.ACTION_TYPE,

  fetchInvitations: moduleActions.fetchList,
  createInvitation: moduleActions.createEntity,
  updateInvitation: moduleActions.updateEntity,
  deleteInvitation: moduleActions.deleteEntity,

  ...moduleActions
};

export default invitationsActions;
