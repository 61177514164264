import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useAppSelector } from '../hooks';

interface UiSettingsState {
  sidebarOpened: boolean;
}

const initialState: UiSettingsState = {
  sidebarOpened: false
};

const uiSettingsSlice = createSlice({
  name: 'uiSettings',
  initialState,
  reducers: {
    toggleSidebar: state => {
      state.sidebarOpened = !state.sidebarOpened;
    },
    setSidebarState: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpened = action.payload;
    }
  }
});

const { reducer: uiSettings, actions: uiSettingsActions } = uiSettingsSlice;

export { uiSettings, uiSettingsActions };

export const useUiSettings = () => {
  const dispatch = useDispatch();
  const uiSettingsState = useAppSelector(state => state.uiSettings);

  const toggleSidebar = useCallback(() => {
    dispatch(uiSettingsActions.toggleSidebar());
  }, [dispatch]);

  const openSidebar = useCallback(() => {
    dispatch(uiSettingsActions.setSidebarState(true));
  }, [dispatch]);

  const closeSidebar = useCallback(() => {
    dispatch(uiSettingsActions.setSidebarState(false));
  }, [dispatch]);

  return { uiSettings: uiSettingsState, toggleSidebar, openSidebar, closeSidebar };
};
