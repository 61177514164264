import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../Atoms';
import Form from '../../../Form';
import { partsActions, partsSelectors } from '../../../../modules/parts';
import Field from '../../../Form/Field';
import { useRootPartBySeedId } from '../../../../utils/hooks';
import getPartFieldsToUpdate from '../../../../utils/getPartFieldsToUpdate';

export const LIGHTS = {
  SUNLIGHT: 'sunlight',
  SKYLIGHT: 'skylight',
  CUSTOM: 'custom'
};

export const LIGHTS_LIST = Object.values(LIGHTS);

export const LIGHT_PATHS = {
  [LIGHTS.SUNLIGHT]: 'sunlights',
  [LIGHTS.SKYLIGHT]: 'skylights',
  [LIGHTS.CUSTOM]: 'customLights'
};

const CUSTOM_LIGHT_TYPES = ['ambient', 'directional', 'hemi', 'rect', 'point', 'spot'].map(value => ({
  value,
  label: value
}));

const getFormByType = (type, lightName) => {
  if (type === LIGHTS.SKYLIGHT) {
    return (
      <>
        <Field.Text name="name" label="Name" required />
        <Field.Rgb name="color" label="Color" />
        <Field.Range name="intensity" label="Intensity" max={10} />
        <Field.Toggle name="disabled" label="Disable" idPrefix={lightName} />
      </>
    );
  }

  if (type === LIGHTS.SUNLIGHT) {
    return (
      <>
        <Field.Text name="name" label="Name" required />
        <Field.Rgb name="color" label="Color" />
        <Field.Range name="intensity" label="Intensity" max={10} />
        <Field.Number name="azimuth" label="Azimuth" description="Sun azimuth angle in degrees" />
        <Field.Number name="altitude" label="Altitude" description="Sun altitude angle in degrees" />
        <Form.Group header="Shadow">
          <Field.Toggle name="castShadow" label="Cast shadow" idPrefix={lightName} />
          <p>Use the following fields to manipulate shadow camera position. These are multipliers not final values.</p>
          <Field.Number
            allowEmpty
            name="shadowBoxMultiplier"
            label="Shadow multiplier"
            description="This sets a multiplier for sun distance from 0,0,0. Also changes the size of the shadowbox. Base measurement to multiply is 25 metres or 1000 inches in each direction"
          />
        </Form.Group>
        <Field.Toggle name="disabled" label="Disable" idPrefix={lightName} />
      </>
    );
  }

  return (
    <>
      <Field.Text name="name" label="Name" required />
      <Field.Toggle name="disabled" label="Disable" idPrefix={lightName} />
      <Field.Select name="type" label="Type" description="Rect light does not work yet" options={CUSTOM_LIGHT_TYPES} />
      <Field.Rgb name="color" label="Color" />
      <Field.Range name="intensity" label="Intensity" max={10} />

      <Field.Toggle
        name="castShadow"
        label="Cast shadow"
        description="No shadow support for Ambient and Rect lights"
        idPrefix={lightName}
      />

      <div className="mb-2">
        <h4 className="mb-0">Position</h4>
        <small className="text-muted">Used for positioning the light</small>{' '}
      </div>
      <Field.Number name="position.x" label="Position X" />
      <Field.Number name="position.y" label="Position Y" />
      <Field.Number name="position.z" label="Position Z" />
      <div className="mb-2">
        <h4 className="mb-0">Target</h4>
        <small className="text-muted">
          Used for directed lights. For spotlight the target is relative to lights position
        </small>
      </div>
      <Field.Number name="lookAt.x" label="Target X" />
      <Field.Number name="lookAt.y" label="Target Y" />
      <Field.Number name="lookAt.z" label="Target Z" />
      <Field.Number name="distance" label="Distance" />
      <div className="mb-2">
        <h4 className="mb-0">Other options</h4>
        <small className="text-muted">Options specific to light types</small>{' '}
      </div>
      <Field.Number name="decay" label="Decay" description="Used for Spot lights. Physically correct is 2" step={0.1} />
      <Field.Number
        name="penumbra"
        label="Penumbra"
        description="Used for Spot lights. See https://en.wikipedia.org/wiki/Umbra,_penumbra_and_antumbra"
        min={0}
        max={1}
        step={0.1}
      />
      <Field.Range name="angle" label="Angle" max={90} description="In degrees. Used for Spot lights" />
      <Field.Number name="width" label="Width" description="Used for Rect lights" />
      <Field.Number name="height" label="Height" description="Used for Rect lights" />
      <Field.Rgb name="colorSecondary" label="Secondary color" description="Used for hemi light ground light color" />
    </>
  );
};

const removeLightFromPart = (part, name, path) => {
  const newPart = { ...part };

  if (Array.isArray(newPart.Lights[path])) {
    newPart.Lights[path] = [...part.Lights[path]];

    const lightIndex = newPart.Lights[path].findIndex(light => light.name === name);

    if (lightIndex !== -1) {
      newPart.Lights[path].splice(lightIndex, 1);
    }
  }

  return newPart;
};

const updatePartLight = (part, formValue, name, path) => {
  const newPart = { ...part };
  const value = { ...formValue };

  delete value.partId;

  newPart.Lights = { ...(newPart.Lights || {}) };
  newPart.Lights[path] = [...(newPart.Lights[path] || [])];

  let index = newPart.Lights[path].findIndex(light => light.name === name);

  index = index !== -1 ? index : newPart.Lights[path].length;

  newPart.Lights[path][index] = value;

  return newPart;
};

const useLightsCallbacks = (type, seedId, light) => {
  const dispatch = useDispatch();
  const [pristine, setPristine] = useState(true);
  const path = LIGHT_PATHS[type];
  const rootPart = useRootPartBySeedId(seedId);
  const parts = useSelector(state => partsSelectors.selectPartListBySeedMemoized(state, seedId));
  const lightNames = useSelector(state => partsSelectors.selectLightsNames(state, seedId));

  const handleChangeForm = useCallback(form => {
    setPristine(form.pristine);
  }, []);

  const handleSubmitForm = useCallback(
    value => {
      if (light.name !== value.name && lightNames[value.name]) {
        // eslint-disable-next-line no-alert
        window.alert(`Please, use a different name. This name is already used: ${value.name}`);

        return;
      }
      const partsToSave = [];

      if (value.partId !== light.partId) {
        const source = parts.find(part => part._id === light.partId);

        if (source) {
          partsToSave.push(removeLightFromPart(source, light.name, path));
        }
      }

      const target = parts.find(part => part._id === value.partId);

      if (target) {
        partsToSave.push(updatePartLight(target, value, light.name, path));
      }

      if (partsToSave.length) {
        dispatch(
          partsActions.updateParts(
            seedId,
            partsToSave.map(part => getPartFieldsToUpdate(part, ['Lights']))
          )
        );
      }
    },
    [dispatch, light.name, light.partId, lightNames, parts, path, seedId]
  );

  const handleDeleteLight = useCallback(() => {
    const part = parts.find(({ _id }) => _id === light.partId);
    // eslint-disable-next-line no-alert
    const response = window.confirm(`Are you sure that you want to delete light "${light.name}"?`);

    if (!response) {
      return;
    }

    const newPart = removeLightFromPart(part, light.name, path);

    dispatch(partsActions.updateParts(seedId, [getPartFieldsToUpdate(newPart, ['Lights'])]));
  }, [dispatch, light.name, light.partId, parts, path, seedId]);

  return { handleChangeForm, handleSubmitForm, handleDeleteLight, pristine, rootPart };
};

const LightForm = ({ type, seedId, light }) => {
  const { handleChangeForm, handleSubmitForm, handleDeleteLight, pristine, rootPart } = useLightsCallbacks(
    type,
    seedId,
    light
  );
  const partsForSuggestions = useSelector(state => partsSelectors.selectPartListBySeedForSuggestions(state, seedId));

  return (
    <Form.Group
      header={light.name}
      color={pristine ? 'light' : 'primary'}
      className={light.partId === rootPart?._id ? '' : 'ms-2'}
    >
      <Form
        initialValues={light}
        onChange={handleChangeForm}
        onSubmit={handleSubmitForm}
        customButton={
          <Button color="danger" className="pull-right" onClick={handleDeleteLight}>
            <i className="fa fa-fw fa-trash-o" />
            Delete
          </Button>
        }
      >
        <Field.Select
          name="partId"
          label="Light parent part"
          description="Light will be visible if its parent part is loaded"
          options={partsForSuggestions}
        />
        {getFormByType(type, light.name)}
      </Form>
    </Form.Group>
  );
};

LightForm.propTypes = {
  light: PropTypes.shape({
    partId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  type: PropTypes.oneOf(LIGHTS_LIST).isRequired,
  seedId: PropTypes.string.isRequired
};

export default LightForm;
