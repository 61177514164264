import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { Link } from 'react-router-dom';
import AuthPagesWrapper from '../AuthPagesWrapper';
import { authActions, authSelectors } from '../../modules/auth';
import { ApplicationRoute } from '../../utils/appRoutes';
import ResetPasswordForm from './ResetPasswordForm';

const resetPasswordPage = b.with('reset-password-page');

export class ResetPasswordPageComponent extends React.PureComponent {
  componentDidMount() {
    const { match, verifyToken } = this.props;

    verifyToken(match.params.token);
  }

  get hasError() {
    return Boolean(this.props.error);
  }

  handleSubmitForm = password => {
    const { match, resetPassword } = this.props;

    resetPassword(password, match.params.token);
  };

  renderMainContent = () => {
    const { tokenValid } = this.props;

    return tokenValid ? this.renderResetPasswordForm() : this.renderFailureMessage();
  };

  renderResetPasswordForm = () => {
    const { passwordChanged, request } = this.props;

    return passwordChanged ? (
      this.renderSuccessMessage()
    ) : (
      <ResetPasswordForm onSubmit={this.handleSubmitForm} request={request} />
    );
  };

  renderFailureMessage = () => (
    <span className="text-center fw-light d-block small">
      The token is expired or invalid.
      <br />
      Please <Link to={ApplicationRoute.ForgotPassword}>try again</Link>
    </span>
  );

  renderSuccessMessage = () => (
    <span className="text-center fw-light d-block small">
      Password has been reset successfully.
      <br />
      <Link to={ApplicationRoute.SignIn}>Sign in</Link>
    </span>
  );

  renderSpinner = () => (
    <div className="text-center">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  renderError = () => <span className="fw-light text-danger d-block small mt-2">{this.props.error}</span>;

  render() {
    const { tokenChecked } = this.props;

    return (
      <AuthPagesWrapper header="Reset password" className={resetPasswordPage()}>
        {tokenChecked ? this.renderMainContent() : this.renderSpinner()}
        {this.hasError ? this.renderError() : null}
      </AuthPagesWrapper>
    );
  }
}

ResetPasswordPageComponent.defaultProps = {
  error: '',
  tokenValid: false,
  passwordChanged: false,
  tokenChecked: false,
  request: false
};

ResetPasswordPageComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  verifyToken: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  request: PropTypes.bool,
  tokenValid: PropTypes.bool,
  passwordChanged: PropTypes.bool,
  tokenChecked: PropTypes.bool
};

const mapStateToProps = state => ({
  error: authSelectors.selectAuthError(state),
  request: authSelectors.selectAuthRequest(state),
  tokenValid: authSelectors.selectTokenValid(state),
  tokenChecked: authSelectors.selectTokenChecked(state),
  passwordChanged: authSelectors.selectPasswordChanged(state)
});

const mapDispatchToProps = {
  verifyToken: authActions.verifyToken,
  resetPassword: authActions.resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPageComponent);
