import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { Button } from '../../../Atoms';
import { mappedInstancesActions, mappedInstancesSelectors } from '../../../../modules/mappedInstances';
import { TYPE_INSTANCE, TYPE_MAPPED_INSTANCE } from '../../../../utils/propTypes';
import MappedInstancePreview from './MappedInstancePreview';
import MappedInstanceViewer from './MappedInstanceViewer';

export const MODE = {
  DEFAULT: 'default',
  PREVIEW: 'preview',
  EDITOR: 'editor'
};

const mappedInstanceClassName = b.with('mapped-instance');

export class MappedInstance extends React.PureComponent {
  state = {
    mode: MODE.DEFAULT
  };

  componentDidMount() {
    if (this.hasMappedInstance) {
      this.fetchMappedInstance();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { instance, mappedInstance } = this.props;

    if (prevProps.instance._id !== instance._id && this.hasMappedInstance && !mappedInstance) {
      this.fetchMappedInstance();
    }
  }

  get hasFactoryMap() {
    const { instance } = this.props;

    return Boolean(instance.factoryMap);
  }

  get hasMappedInstance() {
    const { instance } = this.props;

    return Boolean(instance.mappedInstanceId);
  }

  get isPreviewMode() {
    return this.state.mode === MODE.PREVIEW;
  }

  get isEditorMode() {
    return this.state.mode === MODE.EDITOR;
  }

  handleOpenPreview = () => {
    this.setState({ mode: MODE.PREVIEW });
  };

  handleOpenEditor = () => {
    this.setState({ mode: MODE.EDITOR });
  };

  handleCloseModal = () => {
    this.setState({ mode: MODE.DEFAULT });
  };

  fetchMappedInstance() {
    const { fetchMappedInstance, instance } = this.props;

    fetchMappedInstance(instance.mappedInstanceId);
  }

  renderActions() {
    const { request, mappedInstance } = this.props;

    if (request) {
      return (
        <Button size="sm" outline disabled>
          <i className="fa fa-fw fa-circle-o-notch fa-spin" /> Loading...
        </Button>
      );
    }

    if (!mappedInstance) {
      return (
        <Button color="info" onClick={this.handleOpenPreview} size="sm" outline>
          <i className="fa fa-fw fa-industry" /> Preview factory data
        </Button>
      );
    }

    return (
      <Button color="primary" onClick={this.handleOpenEditor} size="sm" outline>
        <i className="fa fa-fw fa-industry" /> Show factory data
      </Button>
    );
  }

  renderPreview() {
    const { instance } = this.props;

    return this.isPreviewMode ? <MappedInstancePreview onClose={this.handleCloseModal} instance={instance} /> : null;
  }

  renderViewer() {
    const { instance, mappedInstance } = this.props;

    return this.isEditorMode ? (
      <MappedInstanceViewer onClose={this.handleCloseModal} instance={instance} mappedInstance={mappedInstance} />
    ) : null;
  }

  render() {
    if (!this.hasFactoryMap) {
      return null;
    }

    return (
      <div className={mappedInstanceClassName()}>
        {this.renderActions()}
        {this.renderViewer()}
        {this.renderPreview()}
      </div>
    );
  }
}

MappedInstance.defaultProps = {
  request: false
};

MappedInstance.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  mappedInstance: TYPE_MAPPED_INSTANCE, // eslint-disable-line react/require-default-props
  fetchMappedInstance: PropTypes.func.isRequired,
  request: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  request: mappedInstancesSelectors.selectMappedInstancesRequest(state),
  mappedInstance: mappedInstancesSelectors.selectMappedInstance(state, ownProps.instance.mappedInstanceId)
});

const mapDispatchToProps = {
  fetchMappedInstance: mappedInstancesActions.fetchMappedInstance
};

export default connect(mapStateToProps, mapDispatchToProps)(MappedInstance);
