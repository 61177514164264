import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import Form from '../../Form';
import Field from '../../Form/Field';
import { useModal } from '../../../utils/hooks';
import { iframeScriptsActions, iframeScriptsSelectors } from '../../../modules/iframeScripts';

const IframeScriptCreation = () => {
  const { isOpen, open, close } = useModal();

  const dispatch = useDispatch();
  const created = useSelector(iframeScriptsSelectors.selectIframeScriptsCreatedFlag);
  const handleSubmit = useCallback(
    value => {
      dispatch(iframeScriptsActions.createIframeScript(value));
    },
    [dispatch]
  );
  const handleClose = useCallback(() => {
    dispatch(iframeScriptsActions.resetFlags());
    close();
  }, [close, dispatch]);

  useEffect(() => {
    if (created) {
      handleClose();
    }
  }, [created, handleClose]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        inModal
        name="Create new iframe script"
        closeModal={handleClose}
        isModalOpen={isOpen}
      >
        <Field.Text required name="version" label="Version" />
        <Field.Textarea name="description" label="Description" />
        <Field.Code required name="value" label="Value" mode="javascript" theme="xcode" />
      </Form>
      <ProtectedButton rights={[RIGHTS.IFRAME_SCRIPT__CREATE]} onClick={open} color="primary">
        <i className="fa fa-plus fa-fw" />
        Create new script
      </ProtectedButton>
    </>
  );
};

export default IframeScriptCreation;
