import { UsePaginationInstanceProps } from 'react-table';
import { Pagination as BSPagination, PaginationItem as BSPaginationItem } from '../Bootstrap';

interface PaginationProps<T extends Record<any, any>> extends UsePaginationInstanceProps<T> {
  pageIndex: number;
}

const Pagination = <T,>({
  pageCount,
  pageOptions,
  nextPage,
  previousPage,
  gotoPage,
  canNextPage,
  canPreviousPage,
  pageIndex
}: PaginationProps<T>) => {
  const windowSize = 5;

  if (pageCount < 2) return null;

  let pagesWindow: number[] = [];
  const showDots = {
    left: false,
    right: false
  };

  if (pageCount > windowSize + 2) {
    if (pageIndex - Math.floor(windowSize / 2) > 0 && pageIndex + Math.floor(windowSize / 2) < pageCount - 1) {
      // in the middle
      pagesWindow = pageOptions.slice(pageIndex - 2, pageIndex - 2 + windowSize);
      showDots.left = true;
      showDots.right = true;
    }

    if (pageIndex - Math.floor(windowSize / 2) <= 0) {
      // start
      pagesWindow = pageOptions.slice(0, windowSize);
      showDots.right = true;
    }

    if (pageIndex + Math.floor(windowSize / 2) >= pageCount - 1) {
      // end
      pagesWindow = pageOptions.slice(pageCount - 1 - windowSize, pageCount);
      showDots.left = true;
    }
  } else {
    pagesWindow = pageOptions;
  }

  return (
    <BSPagination>
      <BSPaginationItem onClick={gotoPage} disabled={!canPreviousPage} page={0} type="first" />
      <BSPaginationItem onClick={previousPage} disabled={!canPreviousPage} type="previous" />
      {showDots.left && (
        <>
          <BSPaginationItem className="d-none d-lg-block" onClick={gotoPage} page={0} />
          <div className="d-none d-md-block text-primary px-3 py-3">...</div>
        </>
      )}
      {pagesWindow.map(key => (
        <BSPaginationItem
          className={pageIndex === key ? '' : 'd-none d-md-block'}
          key={key}
          onClick={gotoPage}
          page={key}
          active={pageIndex === key}
        />
      ))}
      {showDots.right && (
        <>
          <div className="d-none d-md-block text-primary px-3 py-3">...</div>
          <BSPaginationItem className="d-none d-md-block" onClick={gotoPage} page={pageCount - 1} />
        </>
      )}

      <BSPaginationItem onClick={nextPage} disabled={!canNextPage} type="next" />
      <BSPaginationItem onClick={gotoPage} disabled={!canNextPage} page={pageCount - 1} type="last" />
    </BSPagination>
  );
};

export default Pagination;
