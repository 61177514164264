import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { Button } from '../../../Atoms';
import Group from '../../Group';
import getTransformedChildren from './getTransformedChildren';

const SortableGroup = SortableElement(Group);

const FieldItem = ({
  name,
  index,
  isOpen,
  isStatic,
  lockMoving,
  onRemove,
  children,
  headingIndex,
  headingFormat,
  value,
  forceMount
}) => {
  const transformedChildren = useMemo(() => getTransformedChildren(name, index, children), [children, index, name]);

  const headerButtons = isStatic ? null : (
    <Button color="danger" size="sm" outline className="ms-auto" data-index={index} onClick={onRemove}>
      <i className="fa fa-trash" />
    </Button>
  );

  const GroupComponent = isStatic || lockMoving ? Group : SortableGroup;

  const header = headingFormat ? headingFormat(value, name, children) : value[children[headingIndex].props.name];

  return (
    <GroupComponent
      useDragHandle={!isStatic && !lockMoving}
      index={index}
      headerButtons={headerButtons}
      header={header}
      open={isOpen}
      mountOnEnter={!forceMount}
    >
      {transformedChildren}
    </GroupComponent>
  );
};

FieldItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool.isRequired,
  lockMoving: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  headingIndex: PropTypes.number.isRequired,
  headingFormat: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  forceMount: PropTypes.bool
};

FieldItem.defaultProps = {
  headingFormat: undefined,
  value: undefined,
  forceMount: false
};

export default FieldItem;
