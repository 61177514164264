import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../modules/auth';
import TermsOfUseDialog from './TermsOfUseDialog';

const TermsOfUse = () => {
  const isTermsOfUseAccepted = useSelector(authSelectors.selectIsTermsOfUseAccepted);

  return isTermsOfUseAccepted ? null : <TermsOfUseDialog />;
};

export default TermsOfUse;
