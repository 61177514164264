import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spinner } from '../Atoms';
import Loader from '../Loader';
import { usePartList } from '../../utils/hooks';
import { partsActions, partsSelectors } from '../../modules/parts';
import SortableWrapper from '../Form/SortableWrapper';
import getPartFieldsToUpdate from '../../utils/getPartFieldsToUpdate';
import ViewForm from './ViewForm';
import AddViewButton from './AddViewButton';

const ViewsPage = () => {
  const dispatch = useDispatch();
  const { seedId } = useParams();
  const stateViews = useSelector(state => partsSelectors.selectViews(state, seedId));

  const [views, setViews] = useState(stateViews);
  const { request, parts } = usePartList(seedId);
  const updatingParts = useSelector(partsSelectors.selectPartsUpdatingFlag);

  useEffect(() => {
    setViews(stateViews);
  }, [stateViews]);

  const handleSortViews = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }
      const newViews = [...views];

      newViews.splice(newIndex, 0, newViews.splice(oldIndex, 1)[0]);

      const modifiedParts = {};

      newViews.forEach((view, viewIndex) => {
        const newView = { ...view.view, viewIndex };

        const part = modifiedParts[view.partId] || parts.find(p => p._id === view.partId);

        const modifiedPart = { ...part };

        if (Array.isArray(modifiedPart.Views?.list)) {
          const index = modifiedPart.Views.list.findIndex(listedView => listedView.name === view.value);

          if (index !== -1) {
            modifiedPart.Views.list[index] = newView;
            modifiedParts[modifiedPart._id] = modifiedPart;
          }
        }
      });

      const modifiedPartsArray = Object.values(modifiedParts).map(part => getPartFieldsToUpdate(part, ['Views']));

      if (modifiedPartsArray.length) {
        dispatch(partsActions.updateParts(seedId, modifiedPartsArray));

        setViews(newViews);
      }
    },
    [dispatch, parts, seedId, views]
  );

  return (
    <div className="w-100 p-3">
      <Loader loading={request}>
        <Alert color="info">
          Here you can define and reorder views. You can also change the parent part of views. Views with parent other
          than the root part will be loaded only if the part is loaded. Non-root views are marked with a small offset.
        </Alert>
        <SortableWrapper useDragHandle fluid onSortEnd={handleSortViews}>
          {views.map((view, viewIndex) => (
            <ViewForm view={view} index={viewIndex} key={`${view.value}_${view.partId}`} seedId={seedId} />
          ))}
        </SortableWrapper>
        <AddViewButton seedId={seedId} viewsLength={views.length} />
      </Loader>
      {updatingParts ? (
        <div className="app-overlay">
          <Spinner color="primary" />
        </div>
      ) : null}
    </div>
  );
};

export default ViewsPage;
