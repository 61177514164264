import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export const Pdf = () => {
  return (
    <>
      <FormField.Text<ProjectType> name="settings.pdf.caption" label="Pdf caption" description="" useShadowValue />
      <FormField.Text<ProjectType>
        name="settings.pdf.partnerName"
        label="Pdf partner name"
        description=""
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.pdf.development"
        label="Pdf development name"
        description=""
        useShadowValue
      />
      <FormField.ArrayWrapper<ProjectType>
        name="settings.pdf.address"
        component={<FormField.Text<ProjectType> useShadowValue={false} name="settings.pdf.address" label="" />}
        label="Pdf address"
        description="Add as many lines as needed"
        useShadowValue
      />
      <FormField.Toggle<ProjectType>
        name="settings.pdf.showNotes"
        label="Display notes in pdf"
        description=""
        useShadowValue
      />
      <FormField.Toggle<ProjectType>
        name="settings.pdf.showFooter"
        label="Display footer in pdf"
        description=""
        useShadowValue
      />
      <FormField.ArrayWrapper<ProjectType>
        name="settings.pdf.instruction"
        component={<FormField.Text<ProjectType> useShadowValue={false} name="settings.pdf.instruction" label="" />}
        label="Instructions"
        description=""
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.pdf.customTotal"
        label="Custom total"
        description="Custom string for 'total' in pdf"
        useShadowValue
      />
      <FormField.Text<ProjectType>
        name="settings.pdf.customNotes"
        label="Custom notes"
        description="Custom notes to add to pdf"
        useShadowValue
      />
    </>
  );
};
