import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { plotsActions, plotsSelectors } from '../../../modules/plots';
import PlotForm from '../PlotForm';
import { RIGHTS } from '../../../utils/rights';
import CreationModal from '../../CreationModal';

export const PlotCreation = () => {
  const dispatch = useDispatch();
  const created = useSelector(plotsSelectors.selectPlotCreatedFlag);

  const createPlot = useCallback(
    value => {
      dispatch(plotsActions.createPlot(value));
    },
    [dispatch]
  );
  const resetFlags = useCallback(() => {
    dispatch(plotsActions.resetFlags());
  }, [dispatch]);

  return (
    <CreationModal
      button="Create new plot"
      header="Add a new plot"
      rights={[RIGHTS.PLOTS__CREATE]}
      onCreate={createPlot}
      resetFlags={resetFlags}
      created={created}
    >
      {({ setForm }) => <PlotForm onChange={setForm} isNew />}
    </CreationModal>
  );
};

export default PlotCreation;
