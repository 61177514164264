import { FC } from 'react';
import { BSClassNameFormatter, BSColorType } from '../helpers';

interface BadgeProps {
  color?: BSColorType;
  pill?: boolean;
}

const Badge: FC<BadgeProps> = ({ children, color = 'secondary', pill = false }) => {
  return <span className={BSClassNameFormatter('badge', { [color]: true, pill })}>{children}</span>;
};

export default Badge;
