import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import { useKeys } from '../../../../utils/hooks';
import ArrayItem from './ArrayItem';

const ArrayPreview = ({ name, component, componentProps, internalFieldName }) => {
  const { fields } = useFieldArray(name, { submitting: true, pristine: true });
  const { keys } = useKeys(fields.length);

  return (
    <div className="w-100">
      {fields.map((field, fieldIndex) => (
        <ArrayItem
          name={field}
          key={keys[fieldIndex]}
          component={component}
          componentProps={componentProps}
          disabled
          internalFieldName={internalFieldName}
        />
      ))}
    </div>
  );
};

ArrayPreview.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.shape({}),
  internalFieldName: PropTypes.string
};

ArrayPreview.defaultProps = {
  internalFieldName: '',
  componentProps: undefined
};

export default ArrayPreview;
