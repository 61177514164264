import ASelect from 'react-select/async';
import { useController } from 'react-hook-form';
import { useCallback, useState } from 'react';
import FieldWrapper from '../FieldWrapper';
import { AsyncSelectProps, DefaultOptionType, FieldType } from '../../types';
import { getConvertedRegisterOptions, getThemeForSelectComponent } from '../helpers';

const AsyncSelect = <T, K = DefaultOptionType>({
  name,
  description,
  label,
  required,
  api,
  filter,
  getOptionValue = (option?: any) => `${option?.value}`,
  getOptionLabel = (option?: any) => `${option?.label}`,
  disabled
}: AsyncSelectProps<T, K>) => {
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const fieldError: any = fieldState.error;

  const [list, setList] = useState<K[]>([]);

  const loadOptions = useCallback(
    async (value: string) => {
      const data = await api(value);

      setList(data);

      return filter ? filter(value, data) : data;
    },
    [api, filter]
  );

  const value = field.value !== undefined ? list.find(option => getOptionValue(option) === field.value) : null;

  return (
    <FieldWrapper
      type={FieldType.ASYNC_SELECT}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <ASelect<K>
        value={value}
        loadOptions={loadOptions}
        onChange={option => field.onChange(getOptionValue(option))}
        onBlur={field.onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled}
        isClearable={!required}
        className={fieldState.invalid ? 'is-invalid' : ''}
        theme={theme => getThemeForSelectComponent(theme, fieldState.invalid)}
        cacheOptions
        defaultOptions
      />
    </FieldWrapper>
  );
};

export default AsyncSelect;
