import { ALL_FILTER } from '../../components/SelectFilter';

export const defaultFilterMethod = (filter, row) =>
  String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase());

export const selectFilterMethod = (filter, row) =>
  filter.value === ALL_FILTER ? true : row[filter.id] === filter.value;

export const multiSelectFilterMethod = (filter, row) => {
  if (Array.isArray(filter.value)) {
    if (filter.value.length) {
      return row[filter.id].some(item => filter.value.includes(item._id));
    }

    return true;
  }

  if (filter.value === ALL_FILTER) {
    return true;
  }

  return row[filter.id].some(item => filter.value === item._id);
};
