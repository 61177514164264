import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { authSelectors } from '../../modules/auth';
import AuthPagesWrapper from '../AuthPagesWrapper';
import ForgotPasswordForm from './ForgotPasswordForm';

const forgotPasswordPage = b.with('forgot-password-page');

export const ForgotPasswordPageComponent = props => {
  const { emailSent } = props;
  const successMessage = (
    <span className="text-center fw-light d-block small">
      Password reset link has been sent successfully. Please check your email
    </span>
  );

  return (
    <AuthPagesWrapper header="Forgot your password?" className={forgotPasswordPage()}>
      {emailSent ? successMessage : <ForgotPasswordForm />}
    </AuthPagesWrapper>
  );
};

ForgotPasswordPageComponent.defaultProps = {
  emailSent: false
};

ForgotPasswordPageComponent.propTypes = {
  emailSent: PropTypes.bool
};

const mapStateToProps = state => ({
  emailSent: authSelectors.selectEmailSent(state)
});

export default connect(mapStateToProps, null)(ForgotPasswordPageComponent);
