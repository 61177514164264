import { createSelector } from 'reselect';
import { factoryMapsSelectors } from '../factoryMaps';

export const selectInstances = state => state.instances.list;
export const selectInstancesRequest = state => state.instances.request;
export const selectInstancesIntegrationRequest = state => state.instances.integrationRequest;

export const selectInstanceList = createSelector([selectInstances], instances => Object.values(instances));

export const selectInstancesWithRequest = createSelector([selectInstanceList], instances =>
  instances.filter(instance => instance.lead && instance.lead.email)
);

export const selectNoOrphanInstances = createSelector([selectInstanceList], instances =>
  instances.filter(instance => !instance.orphan)
);

export const selectVisitors = createSelector(
  [selectNoOrphanInstances, factoryMapsSelectors.selectFactoryMapsBySeedId],
  (instances, factoryMaps) =>
    instances.reduce((visitors, instance) => {
      const { visitor, seed } = instance.populated;

      if (!visitors[visitor._id]) {
        visitors[visitor._id] = { ...visitor, instances: [] }; // eslint-disable-line no-param-reassign
      }

      visitors[visitor._id].instances.push({ ...instance, factoryMap: factoryMaps[seed._id] });

      return visitors;
    }, {})
);

export const VISITOR_STATUS = [
  'Visiting',
  'Request',
  'Active',
  'Lost',
  'Meeting booked',
  'Plot reserved',
  'In production',
  'Delivered'
];

export const selectVisitorList = createSelector([selectVisitors], visitors =>
  Object.values(visitors).map(visitor => {
    const primaryInstance = visitor.instances.find(instance => instance.lead && instance.lead.primary);

    if (primaryInstance) {
      const { firstName = '', lastName = '', status = 2 } = primaryInstance.lead;
      const name = `${firstName} ${lastName}`;

      return { ...visitor, name, status: VISITOR_STATUS[status] };
    }

    const leadInstance = visitor.instances.find(instance => Boolean(instance.lead));

    if (leadInstance) {
      const { firstName = '', lastName = '' } = leadInstance.lead;
      const name = `${firstName} ${lastName}`;

      return { ...visitor, name, status: VISITOR_STATUS[1] };
    }

    return { ...visitor, name: '', status: VISITOR_STATUS[0] };
  })
);

export const selectVisitor = id =>
  createSelector([selectVisitorList], visitors => visitors.find(visitor => visitor._id === id));

/** Used for first line for instance CSV data */
const instanceCSVHeaders = [
  'controlName',
  'controlDisplayName',
  'selectedOptionName',
  'selectedOptionDisplayName',
  'selectedOptionPrice',
  'isSelectedOptionDefault',
  'phaseName',
  'phaseDisplayName'
];

export const INSTANCE_ORIGINS = {
  CREATOMUS: 'creatomus',
  ORPHAN: 'orphan',
  PARTNER: 'partner'
};
/** Selects data and csv arrays for Instances page */
export const selectFormattedInstances = createSelector([selectInstanceList], instances => {
  return instances.map(
    ({ instanceName, _id, summary, populated, lead = {}, createdAt, createdBy, orphan, partnerUserId }) => {
      let origin = INSTANCE_ORIGINS.CREATOMUS;

      if (partnerUserId) {
        origin = INSTANCE_ORIGINS.PARTNER;
      } else if (orphan) {
        origin = INSTANCE_ORIGINS.ORPHAN;
      }

      return {
        instanceName,
        _id,
        populated,
        hasLead: lead && lead.email,
        origin,
        createdAt,
        createdBy,
        partnerUserId,
        csv: summary?.sections?.reduce(
          (result, section) => {
            const { phaseName, heading: phaseDisplayName, choices = [] } = section;

            choices.forEach(choice => {
              const {
                name: selectedOptionName,
                displayName: selectedOptionDisplayName,
                control,
                totalPrice: selectedOptionPrice
              } = choice.option;
              const { name: controlName, displayName: controlDisplayName, default: defaultValue } = control;

              result.push([
                controlName,
                controlDisplayName,
                selectedOptionName,
                selectedOptionDisplayName,
                selectedOptionPrice,
                String(defaultValue === selectedOptionName),
                phaseName,
                phaseDisplayName
              ]);
            });

            return result;
          },
          [instanceCSVHeaders]
        )
      };
    }
  );
});
