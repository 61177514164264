import React, { useCallback, useMemo } from 'react';
import ProtectedButton from '../../ProtectedButton';
import Form from '../../Form';
import Field from '../../Form/Field';
import { TYPE_PROJECT } from '../../../utils/propTypes';
import { useAssets } from '../../../utils/hooks';
import Settings from '../Settings';
import { RIGHTS } from '../../../utils/rights';
import { useFetchGlobalSettings } from '../../../modules/settings/settingsHooks';
import { useDeleteProject, useUpdateProject } from '../../../store/project';
import { useSeed } from '../../../store/seed';

const Project = ({ project }) => {
  const { updateProject } = useUpdateProject();
  const { deleteProject } = useDeleteProject(project._id, project.name);
  const globalSettings = useFetchGlobalSettings();

  useAssets(project._id);

  const { seed: publicSeed } = useSeed(project.publicSeedId);
  const handleSubmitForm = useCallback(
    value => {
      // https://github.com/final-form/react-final-form/issues/271
      updateProject(value);
    },
    [updateProject]
  );

  const shadowValues = useMemo(() => {
    return {
      list: [
        {
          name: 'master',
          value: {
            settings: globalSettings.raw
          }
        },
        {
          name: 'current'
        },
        {
          name: 'public seed',
          value: {
            settings: publicSeed?.settings
          }
        }
      ]
    };
  }, [globalSettings.raw, publicSeed]);

  if (!project) return null;

  return (
    <div className="w-100 h-100 px-3">
      <Form initialValues={project} onSubmit={handleSubmitForm} shadowValues={shadowValues}>
        <h4>{project.name}</h4>

        <Field.MultiSelect
          creatable
          name="allowedDomains"
          label="Allowed domains"
          description="List of domains where embedding is allowed"
          options={[]}
        />
        <Settings header="Project settings" projectId={project._id} seedId={project.publicSeedId} useShadowValue />
        <Form.Group
          header={
            <span className="text-primary">
              <i className="fa fa-css3 fa-fw" />
              Theme
            </span>
          }
        >
          <Field.Css description="Project theme" name="theme" label="Theme" />
        </Form.Group>
        <div>
          <ProtectedButton
            onClick={deleteProject}
            color="danger"
            outline
            className="pull-right"
            rights={[RIGHTS.PROJECTS__DELETE]}
          >
            <i className="fa fa-trash fa-fw" />
            Delete project
          </ProtectedButton>
        </div>
      </Form>
    </div>
  );
};

Project.propTypes = {
  project: TYPE_PROJECT.isRequired
};

export default Project;
