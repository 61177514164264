import { actions } from '../@helpers';

const MODULE_NAME = 'PLOTS';
const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const plotsActions = {
  FETCH_PLOTS: moduleActions.fetchList.ACTION_TYPE,
  CREATE_PLOT: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_PLOT: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_PLOT: moduleActions.deleteEntity.ACTION_TYPE,

  fetchPlots: moduleActions.fetchList,
  createPlot: moduleActions.createEntity,
  updatePlot: moduleActions.updateEntity,
  deletePlot: moduleActions.deleteEntity,

  ...moduleActions
};

export default plotsActions;
