import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PartType } from 'types';
import { partsActions, partsSelectors } from '../../../modules/parts';
import { usePartList } from '../../../utils/hooks';
import Graph from '../../Graph';
import { GraphNodeShape } from '../../Graph/GraphNode/GraphNode';
import Loader from '../../Loader';
import PartModals from '../PartList/PartModals';
import PartForm from '../PartList/PartModals/PartForm';
import PartCard from './PartCard';
import PartGraphToolbar from './PartGraphToolbar';

interface LegacyPartGraphProps {
  seedId: string;
  projectId?: string;
  isPartModalOpen: boolean;
  onClosePartModal: () => void;
  onCreatePart: (p: PartType) => void;
  onOpenPartModal: () => void;
  onOpenPart: (id: string) => void;
  onOpenPartRaw: (id: string) => void;
  onDeletePart: (id: string) => void;
  onSwitch: () => void;
}

const LegacyPartGraph = ({
  seedId,
  projectId,
  isPartModalOpen,
  onClosePartModal,
  onCreatePart,
  onOpenPart,
  onOpenPartModal,
  onDeletePart,
  onOpenPartRaw,
  onSwitch
}: LegacyPartGraphProps) => {
  const { request } = usePartList(seedId);
  const dispatch = useDispatch();

  const { nodes, edges, rootNodeId } = useSelector(state => partsSelectors.selectPartsNodesWithEdges(state, seedId));

  const parts: Record<string, PartType> = useSelector(partsSelectors.selectParts);

  const nodesWithChildren = useMemo(
    () =>
      nodes.map<GraphNodeShape>(node => ({
        ...node,
        render: ({ onSelect, onDeselect, isSelected }) => (
          <PartCard part={parts[node.id]} onDelete={onDeletePart} onOpen={onOpenPart} onOpenRaw={onOpenPartRaw} />
        )
      })),
    [nodes, onDeletePart, onOpenPart, onOpenPartRaw, parts]
  );

  const nodeUpdateHandler = useCallback(
    ({ nodeId, dX, dY, initialGraphX, initialGraphY }) => {
      dispatch(
        partsActions.updatePartPosition({ _id: nodeId, graphX: initialGraphX + dX, graphY: initialGraphY + dY })
      );
    },
    [dispatch]
  );

  const nodesUpdateHandler = useCallback(
    calculatedNodes => {
      dispatch(partsActions.reorderParts(calculatedNodes));
    },
    [dispatch]
  );

  const submitHandler = useCallback(() => {
    dispatch(partsActions.saveUnsavedParts(seedId));
  }, [dispatch, seedId]);

  const resetHandler = useCallback(() => {
    dispatch(partsActions.resetUnsavedPartsInfo());
  }, [dispatch]);

  const isPristine = !useSelector(partsSelectors.selectArePartsUnsaved);

  return (
    <Loader loading={request}>
      <Graph
        nodes={nodesWithChildren}
        edges={edges}
        rootNodeId={rootNodeId}
        onUpdateNode={nodeUpdateHandler}
        onReorderNodes={nodesUpdateHandler}
        toolbar={
          <PartGraphToolbar
            onReset={resetHandler}
            onSubmit={submitHandler}
            isPristine={isPristine}
            onOpenPartModal={onOpenPartModal}
            onSwitch={onSwitch}
          />
        }
      />
      <PartModals projectId={projectId} />
      {isPartModalOpen ? (
        <PartForm onSubmit={onCreatePart} close={onClosePartModal} part={{}} projectId={projectId} seedId={seedId} />
      ) : null}
    </Loader>
  );
};

export default LegacyPartGraph;
