import { useCallback, useEffect, MouseEvent } from 'react';
import { Input, Table as RSTable } from 'reactstrap';
import { Column, FilterProps, Row, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import b from 'b_';
import Pagination from './Pagination';

import './Table.scss';

const table = b.with('table');

let filtersState: {
  id: string;
  value: any;
}[] = [];

const DefaultColumnFilter = <T extends Record<string, any>>({ column }: FilterProps<T>) => {
  return (
    <Input
      value={column.filterValue || ''}
      onChange={e => {
        const foundFilter = filtersState.find(filter => filter.id === column.id);

        if (!foundFilter) {
          filtersState.push({
            id: column.id,
            value: e.target.value
          });
        } else {
          foundFilter.value = e.target.value;
        }

        column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Filter"
    />
  );
};

const ColumnSortIcon = ({ order = undefined }: { order?: boolean }) => {
  let icon = 'fa-sort';

  if (order === true) {
    icon = 'fa-sort-desc';
  }

  if (order === false) {
    icon = 'fa-sort-asc';
  }

  return <i className={`${table('column-sort-icon', { active: order !== undefined })} fa ${icon}`} />;
};

interface TableProps<T extends Record<string, any>> {
  columns: Column<T>[];
  hiddenColumns?: string[];
  data?: T[];
  defaultPageSize?: number;
  className?: string;
  striped?: boolean;
  hover?: boolean;
  selectedRowId?: string;
  onSelectRowId?: (id: string) => void;
  getRowId?: (row: T, relativeIndex: number, parent?: Row<T>) => string; // for custom rowids based on row data
}

const Table = <T extends Record<string, any>>({
  columns,
  hiddenColumns = [],
  data = [],
  defaultPageSize = 50,
  className = '',
  striped = false,
  hover = false,
  selectedRowId,
  onSelectRowId,
  getRowId
}: TableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    setPageSize,
    pageOptions,
    state: { pageIndex }
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageSize: defaultPageSize, hiddenColumns, filters: filtersState },
      autoResetPage: false, // stops from resetting pagination if data has changed
      autoResetSortBy: false,
      defaultColumn: { Filter: DefaultColumnFilter },
      getRowId
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    return () => {
      filtersState = [];
    };
  }, []);

  const handleSelectRow = useCallback(
    (e: MouseEvent<HTMLTableRowElement>) => {
      const { rowId } = e.currentTarget.dataset;

      if (rowId && onSelectRowId) {
        onSelectRowId(selectedRowId === rowId ? '' : rowId);
      }
    },
    [onSelectRowId, selectedRowId]
  );

  return (
    <>
      <RSTable
        {...getTableProps()}
        className={`small ${className}`}
        size="sm"
        responsive
        hover={hover}
        striped={striped}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <p {...column.getSortByToggleProps()} className={table('column-header')}>
                    {column.canSort ? <ColumnSortIcon order={column.isSortedDesc} /> : null}
                    {column.render('Header')}
                  </p>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps}>
          {page.map((row, i) => {
            prepareRow(row);

            const { className: rowClass = '', ...rowProps } = row.getRowProps();

            return (
              <tr
                {...rowProps}
                onClick={handleSelectRow}
                data-row-id={row.id}
                className={rowClass + table('row', { selected: selectedRowId === row.id })}
              >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </RSTable>
      <Pagination
        page={page}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
      />
    </>
  );
};

export default Table;
