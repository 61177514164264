import { FC, ReactNode } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface ArraySortableItemWrapperProps {
  id: string;
  children: (listeners: DraggableSyntheticListeners) => ReactNode;
}

const ArraySortableItemWrapper: FC<ArraySortableItemWrapperProps> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {children(listeners)}
    </div>
  );
};

export default ArraySortableItemWrapper;
