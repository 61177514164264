import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, ListGroup, ListGroupItem, Row } from '../../Atoms';
import { TYPE_ASSET_LIST, TYPE_PART_CONTROLS, TYPE_SUGGESTION_LIST } from '../../../utils/propTypes';
import RemoteControlForm from './RemoteControlForm';

class RemoteControlsEditor extends React.PureComponent {
  state = {
    selectedControl: false,
    filter: ''
  };

  makeRemoteControl = ({ name, displayName, value, description, partId, list }) => ({
    name,
    displayName,
    value,
    description,
    partId,
    list: [
      ...(Array.isArray(list)
        ? list.map(option => ({
            name: option.name,
            price: option.price,
            price_psq: option.price_psq,
            displayName: option.displayName,
            optionHint: option.optionHint,
            status: option.status,
            image: option.image
          }))
        : [])
    ]
  });

  selectControl = e => {
    const { name, partId, label } = e.currentTarget.dataset;

    this.setState({ selectedControl: { name, partId, label } });
  };

  filterControls = ({ name, label }) => name.match(this.state.filter) || label.match(this.state.filter);

  filterChangeHandler = e => this.setState({ filter: new RegExp(e.currentTarget.value, 'ui') });

  render() {
    const {
      controlsList,
      onSubmit,
      optionStatusList,
      disableOptionPrice,
      onRemoveRemoteControl,
      projectImages,
      projectId,
      updateImagesAfterUpload
    } = this.props;

    const { selectedControl } = this.state;

    let isNewControl = true;
    let remoteControl;

    if (selectedControl) {
      const control = controlsList.find(c => c.name === selectedControl.name && c.partId === selectedControl.partId);

      // If some data was invalid in list item in API, it doesnt get saved
      // So we take the original remote control's list as a guide to have all fields present.

      const originalRemoteControl = this.makeRemoteControl(control);

      remoteControl = control.remote || originalRemoteControl;

      if (control.remote) {
        const list = originalRemoteControl.list.map(option => {
          const remoteOption = remoteControl.list.find(item => item.name === option.name);

          return remoteOption || option;
        });

        remoteControl = { ...remoteControl, list };
        isNewControl = false;
      }
    }

    return (
      <div>
        <h4 className="mb-3">Define Control parameters</h4>
        <Row>
          <Col md={12} lg={4} className="mb-3">
            <Input placeholder="Filter" onChange={this.filterChangeHandler} className="mb-2" />
            <ListGroup className="mb-3">
              {controlsList.filter(this.filterControls).map(({ name, partId, label, displayName, remote }) => (
                <ListGroupItem
                  key={`${partId}_${name}`}
                  active={selectedControl && selectedControl.name === name && selectedControl.partId === partId}
                  tag="button"
                  action
                  data-part-id={partId}
                  data-name={name}
                  data-label={label}
                  onClick={this.selectControl}
                  className="px-2"
                >
                  <span className="d-block">
                    <i
                      className={`fa fa-sm fa-fw ${remote ? 'fa-link' : 'fa-chain-broken'}`}
                      title={remote ? 'Linked' : 'Unlinked'}
                    />
                    {displayName}
                  </span>
                  <small className="d-block">{name}</small>
                  <small className="d-block text-end">{label}</small>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col>
            {remoteControl ? (
              <RemoteControlForm
                remoteControl={remoteControl}
                onSubmit={onSubmit}
                onRemoveRemoteControl={onRemoveRemoteControl}
                partLabel={selectedControl.label}
                optionStatusList={optionStatusList}
                disableOptionPrice={disableOptionPrice}
                isNewControl={isNewControl}
                projectImages={projectImages}
                projectId={projectId}
                updateImagesAfterUpload={updateImagesAfterUpload}
              />
            ) : (
              <p>First select a control and then edit it.</p>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

RemoteControlsEditor.propTypes = {
  controlsList: TYPE_PART_CONTROLS,
  optionStatusList: TYPE_SUGGESTION_LIST,
  onSubmit: PropTypes.func.isRequired,
  disableOptionPrice: PropTypes.bool,
  onRemoveRemoteControl: PropTypes.func.isRequired,
  projectImages: TYPE_ASSET_LIST,
  projectId: PropTypes.string.isRequired,
  updateImagesAfterUpload: PropTypes.func.isRequired
};
RemoteControlsEditor.defaultProps = {
  controlsList: [],
  optionStatusList: [],
  disableOptionPrice: false,
  projectImages: []
};

export default RemoteControlsEditor;
