import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { DropdownItem } from '../../Atoms';
import { TYPE_SUGGESTION } from '../../../utils/propTypes';

import './FilterItem.scss';

const filterItem = b.with('filter-item');

const FilterItem = ({ option, onChange, active }) => {
  const handleChange = () => {
    onChange(option.value);
  };

  const icon = active ? (
    <i className="fa fa-fw fa-check-square text-primary pe-2" />
  ) : (
    <i className="fa fa-fw fa-square-o pe-2" />
  );

  return (
    <DropdownItem
      toggle={false}
      key={option.value}
      active={active}
      onClick={handleChange}
      tag="span"
      className={filterItem()}
    >
      {icon}
      <span className={filterItem('label')}> {option.label}</span>
    </DropdownItem>
  );
};

FilterItem.defaultProps = {
  active: false
};

FilterItem.propTypes = {
  option: TYPE_SUGGESTION.isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default FilterItem;
