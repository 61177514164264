import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as rolesSelectors from './rolesSelectors';
import rolesActions from './rolesActions';

// eslint-disable-next-line import/prefer-default-export
export const useRoleListForSelect = () => {
  const dispatch = useDispatch();
  const list = useSelector(rolesSelectors.selectRoleListForSuggestions);

  useEffect(() => {
    if (list.length === 0) {
      dispatch(rolesActions.fetchRoles());
    }
  }, [dispatch, list.length]);

  return { list };
};
