import { useCallback, useMemo } from 'react';
import { SeedType } from 'types';
import {
  useCloneSeedMutation,
  useCreateSeedMutation,
  useDeleteSeedMutation,
  useDeleteSeedRemoteControlMutation,
  useGetSeedsQuery,
  useUpdateSeedMaterialsMutation,
  useUpdateSeedMutation,
  useUpdateSeedPricesMutation,
  useUpdateSeedRemoteControlMutation,
  useUpdateSeedRemoteSwitchesMutation
} from './services/api';
import { seedsAdapter } from './services/adapter';
import useSuccessNotification from './useSuccessNotification';

export const useSeeds = () => {
  const { data, isLoading, isUninitialized } = useGetSeedsQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    seeds: data ? data.entities : {},
    isLoading,
    isUninitialized,
    isInProgress
  };
};

export const useSeedList = () => {
  const { seeds, isLoading, isUninitialized } = useGetSeedsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      seeds: data ? seedsAdapter.getSelectors().selectAll(data) : [],
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return { seeds, isLoading, isUninitialized, isInProgress };
};

export const useSeedListByProject = (projectId: string) => {
  const { seeds, ...rest } = useSeedList();

  const map = useMemo(() => {
    const result: Record<string, SeedType[]> = {};

    seeds.forEach(seed => {
      if (!result[seed.projectId]) {
        result[seed.projectId] = [];
      }

      result[seed.projectId].push(seed);
    });

    return result;
  }, [seeds]);

  return { seeds: map[projectId] || [], ...rest };
};

export const useSeedListForSelect = () => {
  const { seeds, ...rest } = useSeedList();

  const list = useMemo(
    () =>
      seeds.map(seed => ({
        label: `${seed.name}: ${seed.version}`,
        value: seed._id,
        projectId: seed.projectId
      })),
    [seeds]
  );

  return { seeds: list, ...rest };
};

export const useSeedListByProjectForSelect = (projectId: string) => {
  const { seeds, ...rest } = useSeedListForSelect();

  return { seeds: seeds.filter(seed => seed.projectId === projectId), ...rest };
};

export const useSeed = (id: string) => {
  const { seed, isLoading, isUninitialized } = useGetSeedsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      seed: data ? seedsAdapter.getSelectors().selectById(data, id) : undefined,
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return {
    seed,
    isLoading,
    isUninitialized,
    isInProgress,
    notFound: !seed && !isInProgress
  };
};

export const useCreateSeed = () => {
  const [createSeed, { data, isSuccess }] = useCreateSeedMutation();

  useSuccessNotification(isSuccess, 'Version created successfully');

  return { createSeed, data, isSuccess };
};

export const useCloneSeed = () => {
  const [cloneSeed, { data, isSuccess }] = useCloneSeedMutation();

  useSuccessNotification(isSuccess, 'Version cloned successfully');

  return { cloneSeed, data, isSuccess };
};

export const useUpdateSeed = () => {
  const [updateSeed, { data, isSuccess, error, isError }] = useUpdateSeedMutation();

  const updateSeedMainScope = useCallback(
    (value: SeedType) => {
      return updateSeed({ data: value, scope: 'main' });
    },
    [updateSeed]
  );

  const updateSeedSelfServiceScope = useCallback(
    (value: SeedType) => {
      return updateSeed({ data: value, scope: 'selfService' });
    },
    [updateSeed]
  );

  useSuccessNotification(isSuccess, 'Version updated successfully');

  return { updateSeed, updateSeedMainScope, updateSeedSelfServiceScope, data, isSuccess, error, isError };
};

export const useDeleteSeed = (id: string, name: string, version: string) => {
  const [deleteSeed, { data, isSuccess }] = useDeleteSeedMutation();

  const handleDelete = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to delete version "${name} (${version})"?`); // eslint-disable-line no-alert

    if (response) {
      deleteSeed(id);
    }
  }, [deleteSeed, id, name, version]);

  useSuccessNotification(isSuccess, 'Version deleted successfully');

  return { deleteSeed: handleDelete, data, isSuccess };
};

export const useUpdateSeedPrices = () => {
  const [updateSeedPrices, { data, isSuccess }] = useUpdateSeedPricesMutation();

  useSuccessNotification(isSuccess, 'Prices updated successfully');

  return { updateSeedPrices, data, isSuccess };
};

export const useUpdateSeedMaterials = () => {
  const [updateSeedMaterials, { data, isSuccess }] = useUpdateSeedMaterialsMutation();

  useSuccessNotification(isSuccess, 'Materials updated successfully');

  return { updateSeedMaterials, data, isSuccess };
};

export const useUpdateSeedRemoteSwitches = () => {
  const [updateSeedRemoteSwitches, { data, isSuccess }] = useUpdateSeedRemoteSwitchesMutation();

  useSuccessNotification(isSuccess, 'Remote switches updated successfully');

  return { updateSeedRemoteSwitches, data, isSuccess };
};

export const useUpdateSeedRemoteControl = () => {
  const [updateSeedRemoteControl, { data, isSuccess }] = useUpdateSeedRemoteControlMutation();

  useSuccessNotification(isSuccess, 'Remote control updated successfully');

  return { updateSeedRemoteControl, data, isSuccess };
};

export const useDeleteSeedRemoteControl = () => {
  const [deleteSeedRemoteControl, { data, isSuccess }] = useDeleteSeedRemoteControlMutation();

  useSuccessNotification(isSuccess, 'Remote control deleted successfully');

  return { deleteSeedRemoteControl, data, isSuccess };
};
