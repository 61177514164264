import { useEffect } from 'react';
import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useGetTemplateHtmlPreviewMutation,
  useGetTemplatePdfPreviewMutation,
  useGetTemplatesQuery,
  useUpdateTemplateMutation
} from './services/api';
import { templatesAdapter } from './services/adapter';
import useSuccessNotification from './useSuccessNotification';

export const useTemplates = (partnerId: string) => {
  const { data, isLoading, isUninitialized } = useGetTemplatesQuery(partnerId);
  const isInProgress = isLoading || isUninitialized;

  return {
    templates: data ? data.entities : {},
    isLoading,
    isUninitialized,
    isInProgress
  };
};

export const useTemplateList = (partnerId: string) => {
  const { templates, isLoading, isUninitialized } = useGetTemplatesQuery(partnerId, {
    selectFromResult: ({ data, ...rest }) => ({
      templates: data ? templatesAdapter.getSelectors().selectAll(data) : [],
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return { templates, isLoading, isUninitialized, isInProgress };
};

export const useCreateTemplate = () => {
  const [createTemplate, { data, isSuccess }] = useCreateTemplateMutation();

  useSuccessNotification(isSuccess, 'Template created successfully');

  return { createTemplate, data, isSuccess };
};

export const useUpdateTemplate = () => {
  const [updateTemplate, { data, isSuccess }] = useUpdateTemplateMutation();

  useSuccessNotification(isSuccess, 'Template updated successfully');

  return { updateTemplate, data };
};

export const useDeleteTemplate = () => {
  const [deleteTemplate, { data, isSuccess }] = useDeleteTemplateMutation();

  useSuccessNotification(isSuccess, 'Template deleted successfully');

  return { deleteTemplate, data, isSuccess };
};

export const useTemplate = (partnerId: string, templateId: string) => {
  const { template, isLoading, isUninitialized } = useGetTemplatesQuery(partnerId, {
    selectFromResult: ({ data, ...rest }) => ({
      template: data ? templatesAdapter.getSelectors().selectById(data, templateId) : undefined,
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return { template, isLoading, isUninitialized, isInProgress, notFound: !template && !isInProgress };
};

export const useGetTemplatePreview = () => {
  const [getTemplateHtmlPreview, { data: htmlData, isLoading: isLoadingHtml }] = useGetTemplateHtmlPreviewMutation();
  const [getTemplatePdfPreview, { data: pdfLink, isLoading: isLoadingPdf }] = useGetTemplatePdfPreviewMutation();

  useEffect(() => {
    return () => {
      if (!pdfLink) return;

      URL.revokeObjectURL(pdfLink);
    };
  }, [pdfLink]);

  return {
    getTemplateHtmlPreview,
    getTemplatePdfPreview,
    htmlData,
    pdfLink,
    isLoading: isLoadingHtml || isLoadingPdf
  };
};
