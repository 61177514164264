import { createSelector } from '@reduxjs/toolkit';
import { PartnerType, RootStateType } from 'types';

export const selectPartners = (state: RootStateType) => state.partners.list;
export const selectPartner = (state: RootStateType, id: string): PartnerType => state.partners.list[id];
export const selectPartnersRequest = (state: RootStateType) => state.partners.request;
export const selectPartnerCreatedFlag = (state: RootStateType) => state.partners.created;
export const selectPartnerDeletedFlag = (state: RootStateType) => state.partners.deleted;
export const selectPartnersApiKeyRequest = (state: RootStateType) => state.partners.apiKeyRequest;

export const selectPartnerList = createSelector([selectPartners], partners => Object.values(partners));

export const selectPartnerListForSuggestions = createSelector([selectPartnerList], partners =>
  partners.map(partner => ({ label: partner.name, value: partner._id }))
);

function convertObjectToArrayForFilter(obj: { [key: string]: string }) {
  return Object.keys(obj).map(id => ({ label: obj[id], value: id }));
}

export const selectPartnerListsForFilter = createSelector([selectPartnerList], partners => {
  const users: { [key: string]: string } = {};
  const projects: { [key: string]: string } = {};

  partners.forEach(partner => {
    partner.populated.users.forEach(({ _id, username }) => {
      users[_id] = username;
    });

    partner.populated.projects.forEach(({ _id, name }) => {
      projects[_id] = name;
    });
  });

  return { users: convertObjectToArrayForFilter(users), projects: convertObjectToArrayForFilter(projects) };
});

export const selectUserListForFilter = createSelector([selectPartnerListsForFilter], filter => filter.users);
export const selectProjectListForFilter = createSelector([selectPartnerListsForFilter], filter => filter.projects);
