import * as Effects from 'redux-saga/effects';
import { ActionCreatorsGroupType } from 'types';
import { uiActions } from '../ui';
import * as authActions from '../auth/authActions';

const { put } = Effects;
const call: any = Effects.call; // eslint-disable-line prefer-destructuring

function* doBaseAsyncSaga(actionCreatorGroup: ActionCreatorsGroupType, api: () => void, ...args: any[]) {
  yield put(actionCreatorGroup.request());

  const { data, error } = yield call(api, ...args);

  if (error) {
    yield put(actionCreatorGroup.failure(error.message));

    if (error.statusCode === 401) {
      yield put(authActions.logoutSuccess());
    } else {
      yield put(uiActions.showErrorNotification(error.message, error.details));
    }
  } else {
    yield put(actionCreatorGroup.success(data));
  }

  return { data, error };
}

export { doBaseAsyncSaga }; // eslint-disable-line import/prefer-default-export
