import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import b from 'b_';
import { FormGroup, Label } from '../../../Atoms';
import api from '../../../../utils/api/routes';
import { TYPE_FACTORY_MAP } from '../../../../utils/propTypes';
import Loader from '../../../Loader';
import InstancePreview from './InstancePreview';

const factoryMapPreview = b.with('factory-map-preview');

const FactoryMapPreview = ({ factoryMap }) => {
  const [options, setOptions] = useState([]);
  const [request, setRequest] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});

  useEffect(() => {
    let ignore = false;

    async function fetchInstances() {
      setRequest(true);
      const { data } = await api.instances.getListBySeed(factoryMap.seedId, ['seed.root', 'summary']);

      if (!ignore && Array.isArray(data)) {
        setOptions(data.map(instance => ({ ...instance, label: instance._id, value: instance._id })));
      }

      setSelectedInstance({});
      setRequest(false);
    }

    fetchInstances();

    return () => {
      ignore = true;
    };
  }, [factoryMap.seedId]);

  return (
    <div className={factoryMapPreview()}>
      <Loader loading={request}>
        <div className={factoryMapPreview('select')}>
          <FormGroup className="w-100 d-block">
            <Label className="fw-bold text-uppercase d-inline-block me-2 mb-1 form-field__label" htmlFor="instance">
              Instance *
            </Label>
            <Select
              onChange={setSelectedInstance}
              placeholder="Select instance..."
              value={selectedInstance}
              options={options}
              id="instance"
            />
          </FormGroup>
        </div>

        {selectedInstance.value ? (
          <InstancePreview factoryMap={factoryMap} selectedInstance={selectedInstance} />
        ) : (
          <span className="d-block mb-3">No test instance selected</span>
        )}
      </Loader>
    </div>
  );
};

FactoryMapPreview.propTypes = {
  factoryMap: TYPE_FACTORY_MAP.isRequired
};

export default FactoryMapPreview;
