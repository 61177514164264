import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { compose } from 'redux';
import b from 'b_';
import { TYPE_SUGGESTION_LIST, TYPE_USER_LIST } from '../../../utils/propTypes';
import { usersActions, usersSelectors } from '../../../modules/users';
import { rolesActions, rolesSelectors } from '../../../modules/roles';
import { partnersActions, partnersSelectors } from '../../../modules/partners';
import { projectsSelectors } from '../../../modules/projects';
import { RIGHTS } from '../../../utils/rights';
import { defaultFilterMethod, multiSelectFilterMethod } from '../../../utils/helpers/table';
import Loader from '../../Loader';
import { withUserRights } from '../../UserRights';
import MultiSelectFilter from '../../MultiSelectFilter';
import SelectFilter from '../../SelectFilter';
import { api as projectsApi } from '../../../store/services/api';
import UserForm from './UserForm';

const userList = b.with('user-list');

const badgeCellClassName = 'app-entity-list__cell_badge';

export class UserList extends React.PureComponent {
  state = {
    expanded: {},
    filtered: [
      { id: 'roles', value: [] },
      { id: 'projects', value: [] },
      { id: 'partner', value: 'All' }
    ]
  };

  componentDidMount() {
    if (!this.props.users.length) {
      this.props.fetchUsers();
    }

    this.props.fetchRoles();
    this.props.fetchPartners();
    const { unsubscribe } = this.props.fetchProjects();

    this.unsubscribe = unsubscribe;
  }

  componentWillUnmount() {
    this.unsubscribe?.();
  }

  handleExpandedChange = expanded => {
    this.setState({ expanded });
  };

  handleFilterChange = filtered => {
    this.setState({ filtered });
  };

  handleBadgeClick = e => {
    const { value, key } = e.currentTarget.dataset;

    this.setState(prevState => ({
      filtered: prevState.filtered.map(filter =>
        filter.id === key
          ? { ...filter, value: Array.isArray(filter.value) ? [...new Set([...filter.value, value])] : value }
          : filter
      )
    }));
  };

  renderBadgeCell = row => {
    return row.value.sort().map(item => (
      <button
        type="button"
        key={item._id}
        className="app-badge app-badge_purple"
        onClick={this.handleBadgeClick}
        data-value={item._id}
        data-key={row.column.id}
      >
        {item.name}
      </button>
    ));
  };

  renderMultiSelectFilter = ({ filter, onChange }, options = [], name) => {
    return <MultiSelectFilter options={options} filter={filter} onChange={onChange} name={name} />;
  };

  renderSelectFilter = ({ filter, onChange }, options = []) => {
    return <SelectFilter options={options} filter={filter} onChange={onChange} />;
  };

  render() {
    const { users, roles, projects, partners, request, hasRights } = this.props;

    const { expanded, filtered } = this.state;
    const columns = [
      {
        resizable: false,
        expander: true,
        show: hasRights([RIGHTS.USERS__UPDATE])
      },
      {
        id: 'username',
        Header: 'Name',
        accessor: 'username',
        width: 250,
        className: 'fw-bold p-2'
      },
      {
        id: 'roles',
        Header: 'Roles',
        accessor: 'populated.roles',
        className: badgeCellClassName,
        minWidth: 150,
        Cell: this.renderBadgeCell,
        Filter: filter => this.renderMultiSelectFilter(filter, roles, 'role'),
        filterMethod: multiSelectFilterMethod
      },
      {
        id: 'projects',
        Header: 'Projects',
        accessor: 'populated.projects',
        className: badgeCellClassName,
        minWidth: 150,
        Cell: this.renderBadgeCell,
        Filter: filter => this.renderMultiSelectFilter(filter, projects, 'project'),
        filterMethod: multiSelectFilterMethod
      },
      {
        id: 'partner',
        Header: 'Partner',
        accessor: 'populated.partner',
        className: badgeCellClassName,
        maxWidth: 160,
        Cell: this.renderBadgeCell,
        Filter: filter => this.renderSelectFilter(filter, partners),
        filterMethod: multiSelectFilterMethod
      },
      {
        id: 'lastVisitAt',
        Header: 'Last visit',
        accessor: 'lastVisitAt',
        className: 'p-2',
        width: 150,
        filterable: false,
        resizable: false,
        Cell: row => (row.value ? new Date(row.value).toLocaleString() : null)
      }
    ];

    return (
      <Loader loading={request}>
        <ReactTable
          data={users}
          pageSize={users.length}
          showPagination={false}
          columns={columns}
          expanded={expanded}
          filtered={filtered}
          resizable={false}
          sortable
          filterable
          defaultSorted={[{ id: 'username', desc: false }]}
          defaultFilterMethod={defaultFilterMethod}
          onFilteredChange={this.handleFilterChange}
          onExpandedChange={this.handleExpandedChange}
          className={`${userList()} app-entity-list -striped -highlight`}
          getTheadFilterThProps={() => ({ style: { position: 'inherit', overflow: 'inherit' } })}
          SubComponent={row => (
            <div className="app-entity-list__form bg-light">
              <UserForm user={row.original} />
            </div>
          )}
        />
      </Loader>
    );
  }
}

UserList.defaultProps = {
  users: [],
  roles: [],
  projects: [],
  partners: [],
  request: false
};

UserList.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchPartners: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  hasRights: PropTypes.func.isRequired,
  users: TYPE_USER_LIST,
  roles: TYPE_SUGGESTION_LIST,
  projects: TYPE_SUGGESTION_LIST,
  partners: TYPE_SUGGESTION_LIST,
  request: PropTypes.bool
};

const mapStateToProps = state => ({
  users: usersSelectors.selectUserList(state),
  request: usersSelectors.selectUsersRequest(state),
  roles: rolesSelectors.selectRoleListForSuggestions(state),
  projects: projectsSelectors.selectProjectListForSuggestions(state),
  partners: partnersSelectors.selectPartnerListForSuggestions(state)
});

const mapDispatchToProps = {
  fetchUsers: usersActions.fetchUsers,
  fetchRoles: rolesActions.fetchRoles,
  fetchPartners: partnersActions.fetchPartners,
  fetchProjects: projectsApi.endpoints.getProjects.initiate
};

export default compose(withUserRights, connect(mapStateToProps, mapDispatchToProps))(UserList);
