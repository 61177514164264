import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InstanceType } from 'types';
import { Modal, ModalBody, Form, Button, ModalFooter } from '../../../Atoms';
import { instancesActions } from '../../../../modules/instances';
import { authSelectors } from '../../../../modules/auth';
import { Number, Textarea } from '../../../Atoms/Form/Fields';

const InstanceEdit = ({ instance, onClose }: { instance: InstanceType; onClose: () => void }) => {
  const isReservationFeeAvailable = useSelector(authSelectors.selectUserRequestsShowReservationFee);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value: InstanceType) => {
      dispatch(instancesActions.updateInstance(value));
      onClose();
    },
    [dispatch, onClose]
  );

  return (
    <Modal isOpen toggle={onClose} centered>
      <ModalBody>
        <Form onSubmit={handleSubmit} defaultValues={instance}>
          {isReservationFeeAvailable ? (
            <Number<InstanceType> name="lead.reservationFee" label="Reservation Fee" />
          ) : null}
          <Textarea<InstanceType> name="pdfNotes" label="PDF Notes" />
          <ModalFooter>
            <Button outline onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default InstanceEdit;
