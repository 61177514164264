import { ReactNode } from 'react';
import { FieldType, FieldWrapperProps } from '../types';
import ShadowValue from '../ShadowValue';

const CLASSNAMES: Partial<Record<FieldType, string>> = {
  [FieldType.SWITCH]: 'form-check form-switch mb-3',
  [FieldType.CHECK]: 'form-check mb-3'
};

const LABEL_CLASSNAMES: Partial<Record<FieldType, string>> = {
  [FieldType.SWITCH]: 'form-check-label',
  [FieldType.CHECK]: 'form-check-label'
};

const getLabel = (name: string, label?: string, required?: boolean) => {
  if (label !== undefined) {
    return `${label}${required ? ' <sup>*</sup>' : ''}`;
  }

  return `${label || name}${required ? ' <sup>*</sup>' : ''}`;
};

const ShadowComponent = ({
  useShadowValue,
  noLabel,
  isComplex,
  level,
  name,
  headerFormat,
  children,
  shadowChildren,
  shadowLabelsMap
}: {
  name: string;
  useShadowValue?: boolean;
  noLabel?: boolean;
  isComplex?: boolean;
  level?: number;
  children?: ReactNode;
  shadowChildren?: ReactNode;
  headerFormat?: (item: any) => string;
  shadowLabelsMap?: Record<any, string>;
}) => {
  return (
    <>
      {useShadowValue && !noLabel && !isComplex && (
        <ShadowValue shadowLabelsMap={shadowLabelsMap} isComplex={isComplex} level={level} name={name}>
          {children}
        </ShadowValue>
      )}
      {useShadowValue && !noLabel && isComplex && (
        <ShadowValue
          shadowLabelsMap={shadowLabelsMap}
          headerFormat={headerFormat}
          isComplex={isComplex}
          level={level}
          name={name}
        >
          {shadowChildren}
        </ShadowValue>
      )}
    </>
  );
};

/* eslint-disable react/no-danger */
const FieldWrapper = ({
  name,
  type,
  label = '',
  description = '',
  required,
  error,
  isInputTag,
  isCheckbox,
  children,
  useShadowValue,
  className,
  noLabel,
  isComplex,
  level,
  shadowChildren,
  headerFormat,
  shadowLabelsMap
}: FieldWrapperProps) => {
  return (
    <div className={CLASSNAMES[type] || `mb-3 ${className || ''}`}>
      <div className="d-flex align-items-flex-start">
        {!noLabel && (
          <>
            {type === 'switch' && <div>{children}</div>}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor={name}
              className={`${LABEL_CLASSNAMES[type] || 'form-label'} text-uppercase fw-bold me-2`}
              dangerouslySetInnerHTML={{ __html: getLabel(name, label, required) }}
            />
          </>
        )}
        {description && !isCheckbox ? (
          <small style={{ marginTop: '0.1rem' }} className="flex-grow-1 d-inline-block text-muted">
            {description}
          </small>
        ) : (
          <span className="flex-grow-1" />
        )}

        <ShadowComponent
          useShadowValue={useShadowValue}
          noLabel={noLabel}
          isComplex={isComplex}
          level={level}
          name={name}
          headerFormat={headerFormat}
          shadowChildren={shadowChildren}
          shadowLabelsMap={shadowLabelsMap}
        >
          {children}
        </ShadowComponent>
      </div>
      {isInputTag && !isCheckbox ? <div className="input-group">{children}</div> : null}

      {!(isInputTag && !isCheckbox) && type !== 'switch' && children}

      {description && isCheckbox ? <small className="form-text d-block text-muted">{description}</small> : null}

      {error ? <small className="form-text text-danger" dangerouslySetInnerHTML={{ __html: error }} /> : null}
    </div>
  );
};

export default FieldWrapper;
