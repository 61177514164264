import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AckeeTrackingReturn } from 'ackee-tracker';
import { authActions, authSelectors } from '../modules/auth';
import { uiActions } from '../modules/ui';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import ackeeTracker from '../utils/ackeeTracker';
import Routes from './Routes';
import Loader from './Loader';
import UserSettingsSynchronizer from './UserSettingsSynchronizer';

const useSetWindowSize = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    function handleResize() {
      dispatch(uiActions.setWindowSize(window.innerWidth, window.innerHeight));
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);
};

const useAckeePageTracker = () => {
  const location = useLocation();
  const recordRef = useRef<AckeeTrackingReturn>();

  useEffect(() => {
    recordRef.current?.stop();

    recordRef.current = ackeeTracker.record();
  }, [location]);
};

const preventScrollOnInputs = () => {
  document.addEventListener('wheel', () => {
    const element: HTMLInputElement = document?.activeElement as HTMLInputElement;

    if (element?.type === 'number') {
      element?.blur();
    }
  });
};

const App = () => {
  useSetWindowSize();
  useAckeePageTracker();
  preventScrollOnInputs();

  const dispatch = useAppDispatch();
  const userInfoReceived = useAppSelector(authSelectors.selectUserInfoReceived);

  useEffect(() => {
    dispatch(authActions.getUserInfo());
  }, [dispatch]);

  return (
    <>
      <UserSettingsSynchronizer />
      <Loader loading={!userInfoReceived}>
        <Routes />
      </Loader>
    </>
  );
};

export default App;
