import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export const UserFlow = () => {
  return (
    <>
      <FormField.Toggle<ProjectType> useShadowValue name="settings.interface.aboutTabHidden" label="Hide About page" />
      <FormField.Toggle<ProjectType>
        useShadowValue
        name="settings.interface.reviewTabHidden"
        label="Hide Review step"
      />
      <FormField.Toggle<ProjectType>
        useShadowValue
        name="settings.interface.summaryTabHidden"
        label="Hide Summary page"
      />
      <FormField.Toggle<ProjectType>
        useShadowValue
        name="settings.comments.enabled"
        label="Enable commenting"
        description="Experimental feature"
      />
    </>
  );
};
