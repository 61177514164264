import React from 'react';
import { Link } from 'react-router-dom';
import { Page, PageContent, PageHeader } from '../Page';
import SendInvitationButton from '../SendInvitationButton';
import ProtectedButton from '../ProtectedButton';
import { RIGHTS } from '../../utils/rights';
import { ApplicationRoute } from '../../utils/appRoutes';
import InvitationList from './InvitationList';

const InvitationsPage = () => (
  <Page>
    <PageHeader>
      <div className="d-flex flex-row justify-content-end flex-wrap mb-2">
        <div className="d-flex flex-row align-items-center">
          <ProtectedButton rights={[RIGHTS.USERS__READ]} tag={Link} to={ApplicationRoute.Users} color="link" size="sm">
            Show users
          </ProtectedButton>
          <SendInvitationButton />
        </div>
      </div>
    </PageHeader>
    <PageContent className="pt-2">
      <InvitationList />
    </PageContent>
  </Page>
);

export default InvitationsPage;
