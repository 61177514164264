import { FC, useCallback, useMemo } from 'react';
import { TemplateType } from 'types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Icon, UncontrolledDropdown } from '../Atoms';
import { useUserRights } from '../../utils/hooks';
import { getTemplateUrl } from '../../utils/appRoutes';
import { RIGHTS } from '../../utils/rights';
import { useCreateTemplate, useDeleteTemplate } from '../../store/template';
import { partnersActions } from '../../modules/partners';

interface TemplateListItemActionsProps {
  template: TemplateType;
  active: boolean;
}

const TemplateListItemActions: FC<TemplateListItemActionsProps> = ({ template, active }) => {
  const dispatch = useDispatch();
  const { _id, name, partnerId } = template;
  const { deleteTemplate } = useDeleteTemplate();
  const { createTemplate } = useCreateTemplate();

  const handlePublish = useCallback(() => {
    dispatch(partnersActions.updatePartner({ _id: partnerId, activeTemplateId: _id }));
  }, [_id, dispatch, partnerId]);

  const handleDelete = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to delete template "${name}"?`); // eslint-disable-line no-alert

    if (response) {
      deleteTemplate({ partnerId, id: _id });
    }
  }, [deleteTemplate, name, partnerId, _id]);

  const copyData = useMemo(
    () => ({
      name: template.name,
      partnerId: template.partnerId,
      body: template.body,
      header: template.header,
      footer: template.footer
    }),
    [template]
  );

  const handleCopy = useCallback(() => {
    createTemplate(copyData);
  }, [createTemplate, copyData]);

  const hasRights = useUserRights();

  return (
    <UncontrolledDropdown className="ms-auto p-0">
      <DropdownToggle color="icon" className="text-primary" size="sm">
        <Icon type="mdiDotsVertical" />
      </DropdownToggle>
      <DropdownMenu right>
        {hasRights([RIGHTS.TEMPLATES__UPDATE]) ? (
          <DropdownItem className="px-3 d-flex" tag={Link} to={getTemplateUrl(partnerId, _id)}>
            <Icon type="mdiFolderOutline" />
            <span className="ps-2 fw-bolder">Open</span>
          </DropdownItem>
        ) : null}
        {hasRights([RIGHTS.PARTNERS__UPDATE]) && !active ? (
          <DropdownItem className="px-3 d-flex" onClick={handlePublish}>
            <Icon type="mdiCheck" />
            <span className="ps-2">Publish</span>
          </DropdownItem>
        ) : null}
        {hasRights([RIGHTS.TEMPLATES__CREATE]) ? (
          <DropdownItem className="px-3 d-flex" onClick={handleCopy}>
            <Icon type="mdiContentCopy" />
            <span className="ps-2">Duplicate</span>
          </DropdownItem>
        ) : null}
        {hasRights([RIGHTS.TEMPLATES__DELETE]) ? (
          <DropdownItem className="px-3 d-flex dropdown-item-danger" onClick={handleDelete} disabled={active}>
            <Icon type="mdiDeleteOutline" />
            <span className="ps-2">Delete</span>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default TemplateListItemActions;
