import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { usePartList } from '../../utils/hooks';
import { useSeed } from '../../store/seed';
import RemoteSwitchesEditor from './RemoteSwitchesEditor';
import RemoteSwitchesRawEditor from './RemoteSwitchesRawEditor';

const Placeholder = () => (
  <div className="d-flex align-items-center justify-content-center h-100">
    <p>The model was not found</p>
  </div>
);

const RemoteSwitchesPage = () => {
  const { seedId } = useParams();

  const { seed, isInProgress } = useSeed(seedId);
  const { request: partRequest } = usePartList(seedId);

  return (
    <Loader loading={isInProgress || partRequest}>
      <div className="w-100 p-3">
        {seed ? (
          <>
            <RemoteSwitchesRawEditor seed={seed} />
            <RemoteSwitchesEditor seedId={seedId} />
          </>
        ) : (
          <Placeholder />
        )}
      </div>
    </Loader>
  );
};

export default RemoteSwitchesPage;
