import { useCallback, useEffect, useMemo } from 'react';
import { authActions } from '../modules/auth';
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useGetProjectStatisticQuery,
  useGetLastUpdatedProjectsQuery,
  useUpdateProjectMutation
} from './services/api';
import { projectsAdapter } from './services/adapter';
import { useAppDispatch } from './hooks';
import useSuccessNotification from './useSuccessNotification';

export const useProjects = () => {
  const { data, isLoading, isUninitialized } = useGetProjectsQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    projects: data ? data.entities : {},
    isLoading,
    isUninitialized,
    isInProgress
  };
};

export const useProjectStatistics = (id: string, skip?: boolean) => {
  const { data, isLoading, isUninitialized } = useGetProjectStatisticQuery(id, { skip });
  const isInProgress = isLoading || isUninitialized;

  return {
    data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};

export const useLastUpdatedProjects = () => {
  const { data, isLoading, isUninitialized } = useGetLastUpdatedProjectsQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};

export const useProjectList = () => {
  const { projects, isLoading, isUninitialized } = useGetProjectsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      projects: data ? projectsAdapter.getSelectors().selectAll(data) : [],
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return { projects, isLoading, isUninitialized, isInProgress };
};

export const useProject = (id: string) => {
  const { project, isLoading, isUninitialized, isFetching } = useGetProjectsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      project: data ? projectsAdapter.getSelectors().selectById(data, id) : undefined,
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return {
    project,
    isLoading,
    isUninitialized,
    isInProgress,
    isFetching,
    notFound: !project && !isInProgress
  };
};

export const useCreateProject = () => {
  const dispatch = useAppDispatch();
  const [createProject, { data, isSuccess }] = useCreateProjectMutation();

  useSuccessNotification(isSuccess, 'Project created successfully');

  // TODO: refactor later
  useEffect(() => {
    if (isSuccess) {
      dispatch(authActions.getUserInfo());
    }
  }, [dispatch, isSuccess]);

  return { createProject, data, isSuccess };
};

export const useUpdateProject = () => {
  const [updateProject, { data, isSuccess, isError, error, isLoading }] = useUpdateProjectMutation();

  useSuccessNotification(isSuccess, 'Project updated successfully');

  return { updateProject, data, isSuccess, isError, error, isLoading };
};

export const useDeleteProject = (id: string, name: string) => {
  const [deleteProject, { data, isSuccess }] = useDeleteProjectMutation();

  const handleDelete = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to delete project "${name}"?`); // eslint-disable-line no-alert

    if (response) {
      deleteProject(id);
    }
  }, [deleteProject, id, name]);

  useSuccessNotification(isSuccess, 'Project deleted successfully');

  return { deleteProject: handleDelete, data, isSuccess };
};

export const useUpdateProjectPublicSeed = (id: string) => {
  const { updateProject } = useUpdateProject();
  const { project } = useProject(id);

  const updateProjectPublicSeed = useCallback(
    (publicSeedId: string) => {
      if (!project) return;

      updateProject({ ...project, publicSeedId });
    },
    [project, updateProject]
  );

  return { updateProjectPublicSeed };
};

export const useProjectListForSelect = () => {
  const { projects } = useProjectList();

  return useMemo(() => projects.map(project => ({ label: project.name, value: project._id })), [projects]);
};
