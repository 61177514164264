import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import b from 'b_';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from '../Atoms';
import { partnersActions, partnersSelectors } from '../../modules/partners';
import { TYPE_PARTNER } from '../../utils/propTypes';
import PartnerForm from '../PartnerForm';
import { RIGHTS } from '../../utils/rights';
import { withUserRights } from '../UserRights';
import { ApplicationRoute, getTemplatesUrl } from '../../utils/appRoutes';
import EmailTemplatesForm from './EmailTemplatesForm';
import PartnerPublicAPIKey from './PartnerPublicAPIKey';

const partnerPage = b.with('partner-page');

const TABS = {
  SETTINGS: '#settings',
  EMAIL: '#email',
  TEMPLATES: '#templates'
};

export class PartnerPage extends React.PureComponent {
  componentDidMount() {
    const { partner, fetchPartners } = this.props;

    if (!partner) {
      fetchPartners();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isDeleted, history, resetFlag } = this.props;

    if (isDeleted) {
      resetFlag();
      history.push(ApplicationRoute.Partners);
    }
  }

  get activeTab() {
    return this.props.location.hash || TABS.SETTINGS;
  }

  get hasRightsToSeeTemplates() {
    return this.props.hasRights([RIGHTS.TEMPLATES__READ]);
  }

  handleSubmitPartnerForm = partner => {
    this.props.updatePartner(partner);
  };

  render() {
    const { partner } = this.props;

    return (
      <div className={`${partnerPage()} flex-grow-1 w-100 p-3`}>
        {partner ? (
          <>
            <Nav tabs>
              <NavItem>
                <NavLink tag={Link} to={TABS.SETTINGS} active={this.activeTab === TABS.SETTINGS}>
                  Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to={TABS.EMAIL} active={this.activeTab === TABS.EMAIL}>
                  Transactional email
                </NavLink>
              </NavItem>
              {this.hasRightsToSeeTemplates ? (
                <NavItem>
                  <NavLink tag={Link} to={getTemplatesUrl(partner._id)} active={this.activeTab === TABS.TEMPLATES}>
                    PDF quote templates
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            {/*
              Both PartnerForm and EmailTemplatesForm keep a separate copy of the partner object in their state.
              When user changes something in one tab, moves to another tab, changes something there and then saves the form,
              only the changes in the second tab will be saved. This behavior is intentional.
            */}
            <TabContent activeTab={this.activeTab}>
              <TabPane tabId={TABS.SETTINGS} className="pt-3">
                <PartnerForm partner={partner} onSubmit={this.handleSubmitPartnerForm} />
                <PartnerPublicAPIKey partner={partner} />
              </TabPane>
              <TabPane tabId={TABS.EMAIL} className="pt-3">
                <EmailTemplatesForm partner={partner} onSubmit={this.handleSubmitPartnerForm} />
              </TabPane>
            </TabContent>
          </>
        ) : null}
      </div>
    );
  }
}

PartnerPage.defaultProps = {
  isDeleted: false
};

PartnerPage.propTypes = {
  partner: TYPE_PARTNER, // eslint-disable-line react/require-default-props
  fetchPartners: PropTypes.func.isRequired,
  updatePartner: PropTypes.func.isRequired,
  resetFlag: PropTypes.func.isRequired,
  hasRights: PropTypes.func.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  isDeleted: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  partner: partnersSelectors.selectPartner(state, ownProps.match.params.partnerId),
  isDeleted: partnersSelectors.selectPartnerDeletedFlag(state)
});

const mapDispatchToProps = {
  fetchPartners: partnersActions.fetchPartners,
  updatePartner: partnersActions.updatePartner,
  resetFlag: partnersActions.resetFlags
};

export default compose(withUserRights, connect(mapStateToProps, mapDispatchToProps))(PartnerPage);
