import { Loader } from '../Atoms';
import { useProjectStatistics } from '../../store/project';

interface ProjectDashboardStatisticsProps {
  projectId: string;
}

const ProjectDashboardStatistics = ({ projectId }: ProjectDashboardStatisticsProps) => {
  const { data, isInProgress } = useProjectStatistics(projectId);

  return (
    <Loader loading={isInProgress}>
      {data ? (
        <div className="d-flex flex-row mt-4">
          <div className="flex-grow-1 flex-basis-0 py-4 text-center">
            <h3 className="fw-bolder">{data.instances || 0}</h3>
            <span>Saved designs</span>
          </div>
          <div className="flex-grow-1 flex-basis-0 py-4 border-start border-end text-center">
            <h3 className="fw-bolder">{data.visitors || 0}</h3>
            <span>Visitors</span>
          </div>
          <div className="flex-grow-1 flex-basis-0 py-4 text-center">
            <h3 className="fw-bolder">{data.leads || 0}</h3>
            <span>Requests</span>
          </div>
        </div>
      ) : null}
    </Loader>
  );
};

export default ProjectDashboardStatistics;
