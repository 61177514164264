import { RefObject, useCallback, useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const useOnClickOutside = (ref: RefObject<any>, callback: (event: Event) => any, isOpen: boolean, id: string) => {
  const listener = useCallback(
    event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (id && id === event.target?.dataset?.ignoreClickOutside) {
        return;
      }

      callback(event);
    },
    [callback, id, ref]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    } else {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, isOpen, listener]);
};

export { useDebounce, useOnClickOutside };
