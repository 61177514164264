import { PartnerType } from 'types';
import { actions } from '../@helpers';

const MODULE_NAME = 'PARTNERS';
const moduleActions = actions.createModuleAsyncActionCreators<PartnerType>(MODULE_NAME);

const createAPIKey = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'CREATE_API_KEY'),
  { base: type => (partnerId: string, remove: boolean) => ({ type, payload: { partnerId, remove } }) }
);

const partnersActions = {
  FETCH_PARTNERS: moduleActions.fetchList.ACTION_TYPE,
  CREATE_PARTNER: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_PARTNER: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_PARTNER: moduleActions.deleteEntity.ACTION_TYPE,
  CREATE_API_KEY: createAPIKey.ACTION_TYPE,

  fetchPartners: moduleActions.fetchList,
  createPartner: moduleActions.createEntity,
  updatePartner: moduleActions.updateEntity,
  deletePartner: moduleActions.deleteEntity,
  createAPIKey,

  ...moduleActions
};

export default partnersActions;
