import React from 'react';
import PropTypes from 'prop-types';

const ArrayItem = ({ component: Component, componentProps, disabled, name: fieldName, internalFieldName }) => {
  const name = internalFieldName ? `${fieldName}.${internalFieldName}` : fieldName;

  return (
    <div className={`w-100 ${disabled ? 'mb-1' : ''}`}>
      <Component {...componentProps} name={name} noLabel disabled={disabled} />
    </div>
  );
};

ArrayItem.propTypes = {
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.shape({}),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  internalFieldName: PropTypes.string
};

ArrayItem.defaultProps = {
  disabled: false,
  internalFieldName: '',
  componentProps: {}
};

export default ArrayItem;
