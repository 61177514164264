import React from 'react';
import b from 'b_';
import { useHistory } from 'react-router-dom';
import AuthPagesWrapper from '../AuthPagesWrapper';
import { useParsedQueryParams } from '../../utils/hooks';
import { ApplicationRoute } from '../../utils/appRoutes';
import ValidateInvitation from './ValidateInvitation';

const registrationPage = b.with('registration-page');

const RegistrationPage = () => {
  const query = useParsedQueryParams();
  const history = useHistory();

  const { id } = query;

  if (!id) {
    history.replace(ApplicationRoute.SignIn);

    return null;
  }

  return (
    <AuthPagesWrapper header="Sign up" className={registrationPage()}>
      <ValidateInvitation invitationId={id} />
    </AuthPagesWrapper>
  );
};

export default RegistrationPage;
