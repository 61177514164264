import { FieldType, InputType, TextInputProps } from '../../types';
import { getPattern } from '../helpers';
import BaseInput from './BaseInput';

const Text = <T,>({ minLength, maxLength, required, pattern = FieldType.TEXT, ...rest }: TextInputProps<T>) => {
  return (
    <BaseInput
      {...rest}
      type={pattern as InputType}
      registerOptions={{ required, minLength, maxLength, pattern: getPattern(pattern) }}
    />
  );
};

export default Text;
