import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormFeedback, Input, Row } from '../../../../Atoms';
import Modal from '../../Modal';
import ImageModalItem from '../ImageModalItem';
import ImageModalSelectedItem from '../ImageModalSelectedItem';
import ImageModalUpload from '../ImageModalUpload';
import { useModal } from '../../../../../utils/hooks';
import FormContext from '../../../FormContext';

const ImageFieldComponent = props => {
  const { preview } = useContext(FormContext);
  const { input, meta, list, header, uploadImage, onUploadFailure, onUploadSuccess, hideUpload } = props;
  const { value } = input;
  const { isOpen, toggle, close } = useModal();
  const defaultSelectedItem = '';
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  const selectItem = useCallback(
    item => (item === selectedItem ? setSelectedItem(defaultSelectedItem) : setSelectedItem(item)),
    [selectedItem]
  );

  const [filter, setFilter] = useState('');
  const filterHandler = useCallback(e => setFilter(e.currentTarget.value), []);

  const filterRegExp = new RegExp(filter, 'ui');

  const submitHandler = useCallback(() => input.onChange(selectedItem), [input, selectedItem]);

  const selectedItemObject = list.find(item => item.name === selectedItem);

  const selectedUrl = (selectedItemObject && selectedItemObject.url) || '';

  return (
    <>
      <Button color={value ? 'primary' : 'secondary'} block onClick={toggle}>
        {value || 'Select image'}
      </Button>
      {isOpen ? (
        <Modal size="xl" onClose={close} onSubmit={submitHandler} header={header} preview={preview}>
          <Row>
            <Col lg={8} md={6} style={{ overflowX: 'hidden', overflowY: 'auto', height: '70vh' }}>
              <Input placeholder="Filter" onChange={filterHandler} value={filter} className="text-center mb-2" />
              <Row>
                {list.length > 0 ? (
                  list
                    .filter(({ name }) => name.match(filterRegExp))
                    .map((item, index) => (
                      <ImageModalItem
                        key={item.key || item.url}
                        {...item}
                        onSelect={selectItem}
                        isSelected={selectedItem === item.name}
                      />
                    ))
                ) : (
                  <p>No images loaded</p>
                )}
              </Row>
            </Col>
            <Col>
              <ImageModalSelectedItem url={selectedUrl} name={selectedItem} />
              {hideUpload || preview ? null : (
                <ImageModalUpload
                  api={uploadImage}
                  onUploadFailure={onUploadFailure}
                  onUploadSuccess={onUploadSuccess}
                />
              )}
            </Col>
          </Row>
        </Modal>
      ) : null}

      <FormFeedback>{meta.error}</FormFeedback>
    </>
  );
};

ImageFieldComponent.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }).isRequired, // generated by final form
  meta: PropTypes.shape({
    error: PropTypes.string
  }).isRequired, // generated by final form
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      color: PropTypes.string
    })
  ),
  header: PropTypes.string,
  uploadImage: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  onUploadFailure: PropTypes.func.isRequired,
  hideUpload: PropTypes.bool
};

ImageFieldComponent.defaultProps = {
  list: [],
  header: '',
  hideUpload: false
};

export default ImageFieldComponent;
