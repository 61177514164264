import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import RSelect from 'react-select';
import { FormContext } from '../../FormContext';
import { DefaultOptionType, FieldType, ControlledSelectProps } from '../../types';
import FieldWrapper from '../FieldWrapper';
import { getConvertedRegisterOptions, getThemeForSelectComponent } from '../helpers';

const ControlledSelect = <T, P, K = DefaultOptionType>({
  name,
  description,
  label,
  required,
  getOptionValue = (option?: any) => `${option?.value}`,
  getOptionLabel = (option?: any) => `${option?.label}`,
  disabled,
  sourceField
}: ControlledSelectProps<T, P, K>) => {
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const { getValues } = useFormContext();
  const srcValue: any[] = getValues(sourceField);
  const ownValue: any = getValues(name);
  const { readOnly } = useContext(FormContext);

  const fieldError: any = fieldState.error;
  let value =
    field.value !== undefined && srcValue ? srcValue.find(option => getOptionValue(option) === field.value) : null;

  let source: any[];

  if (ownValue && !srcValue) {
    // this might happen if this control has value but no source, for example is used in ComplexShadow with a part of the form
    value = { value: ownValue, label: ownValue };
    source = [value];
    // eslint-disable-next-line no-param-reassign
    getOptionValue = (option?: any) => `${option?.value}`;
    // eslint-disable-next-line no-param-reassign
    getOptionLabel = (option?: any) => `${option?.label}`;
  } else {
    source = srcValue;
  }

  return (
    <FieldWrapper
      type={FieldType.SELECT}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <RSelect<K>
        value={value}
        options={source}
        onChange={option => field.onChange(getOptionValue(option))}
        onBlur={field.onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled || readOnly}
        isClearable={!required}
        className={fieldState.invalid ? 'is-invalid' : ''}
        theme={theme => getThemeForSelectComponent(theme, fieldState.invalid)}
      />
    </FieldWrapper>
  );
};

export default ControlledSelect;
