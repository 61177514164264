import { createEntityAdapter } from '@reduxjs/toolkit';
import { ProjectType, SeedType, SubscriptionType, TemplateType } from 'types';

export const projectsAdapter = createEntityAdapter<ProjectType>({
  selectId: project => project._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export const seedsAdapter = createEntityAdapter<SeedType>({
  selectId: seed => seed._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export const templatesAdapter = createEntityAdapter<TemplateType>({
  selectId: template => template._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

export const subscriptionsAdapter = createEntityAdapter<SubscriptionType>({
  selectId: subscription => subscription._id
});
