import { ModuleActionCreatorsType, ActionType, BaseInitialStateType, CustomReducerType } from 'types';
import { mapValues } from '../../utils';

export const baseInitialState: BaseInitialStateType<any> = {
  list: {},
  error: '',
  request: false,
  created: false,
  updated: false,
  deleted: false
};

export function createBaseModuleReducer<T, S extends BaseInitialStateType<T>>(
  actions: ModuleActionCreatorsType,
  reducer: CustomReducerType<S> = state => state,
  initialState: S = baseInitialState as S
) {
  return function moduleReducer(state = initialState, action: ActionType): S {
    const { type, payload = {} } = action;

    switch (type) {
      case actions.fetchList.request.ACTION_TYPE:
        return { ...state, request: true };
      case actions.fetchList.success.ACTION_TYPE:
        return { ...state, list: mapValues(payload), request: false, error: '' };
      case actions.createEntity.success.ACTION_TYPE: {
        const entity = payload;

        return { ...state, list: { [entity._id]: entity, ...state.list }, request: false, error: '', created: true };
      }
      case actions.updateEntity.success.ACTION_TYPE: {
        const entity = payload;

        return { ...state, list: { ...state.list, [entity._id]: entity }, request: false, error: '', updated: true };
      }
      case actions.deleteEntity.success.ACTION_TYPE: {
        const { id } = payload;
        const list = { ...state.list };

        delete list[id];

        return { ...state, list, request: false, deleted: true, error: '' };
      }
      case actions.fetchList.failure.ACTION_TYPE:
      case actions.createEntity.failure.ACTION_TYPE:
      case actions.updateEntity.failure.ACTION_TYPE:
      case actions.deleteEntity.failure.ACTION_TYPE:
        return { ...state, error: payload.error, request: false };
      case actions.RESET_FLAGS:
        return { ...state, created: false, updated: false, deleted: false };
      default:
        return reducer(state, action);
    }
  };
}
