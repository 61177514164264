import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import Form from '../../Form';
import Field from '../../Form/Field';
import { useModal } from '../../../utils/hooks';
import { factoryMapsActions, factoryMapsSelectors } from '../../../modules/factoryMaps';
import { useSeedListForSelect } from '../../../store/seed';

const FactoryMapCreation = ({ clone, initialValues }) => {
  const { isOpen, open, close } = useModal();
  const { seeds } = useSeedListForSelect();

  const dispatch = useDispatch();
  const created = useSelector(factoryMapsSelectors.selectFactoryMapCreatedFlag);
  const handleSubmit = useCallback(
    value => {
      dispatch(factoryMapsActions.createFactoryMap(value));
    },
    [dispatch]
  );
  const handleClose = useCallback(() => {
    dispatch(factoryMapsActions.resetFlags());
    close();
  }, [close, dispatch]);

  useEffect(() => {
    if (created) {
      handleClose();
    }
  }, [created, handleClose]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        inModal
        name={clone ? 'Clone factory map' : 'Create new factory map'}
        closeModal={handleClose}
        initialValues={initialValues}
        isModalOpen={isOpen}
      >
        <Field.Text required name="name" label="Name" />
        <Field.Number name="version" label="Version" disabled={clone} />
        <Field.Select required options={seeds} name="seedId" label="Select seed" disabled={clone} />
      </Form>
      <ProtectedButton rights={[RIGHTS.FACTORY_MAPS__CREATE]} onClick={open} color="primary">
        <i className={`fa fa-${clone ? 'copy' : 'plus'} fa-fw`} />
        {clone ? 'Clone map' : 'Create new map'}
      </ProtectedButton>
    </>
  );
};

FactoryMapCreation.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.number,
    seedId: PropTypes.string,
    definition: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  clone: PropTypes.bool
};

FactoryMapCreation.defaultProps = {
  clone: false,
  initialValues: {}
};

export default FactoryMapCreation;
