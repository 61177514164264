import { SeedType } from 'types';
import React, { useCallback, useEffect } from 'react';
import { Form, FormField, Modal, ModalHeader } from '../Atoms';
import { useCloneSeed, useCreateSeed } from '../../store/seed';
import { useProjectListForSelect } from '../../store/project';

interface SeedCreationModalProps {
  defaultValues: Partial<SeedType>;
  type: 'clone' | 'create';
  onClose(): void;
  isOpen?: boolean;
}

const SeedCreationModal = ({ type, defaultValues, onClose, isOpen = true }: SeedCreationModalProps) => {
  const isCloneType = type === 'clone';
  const { cloneSeed, isSuccess: isClonedSuccess } = useCloneSeed();
  const { createSeed, isSuccess: isCreatedSuccess } = useCreateSeed();

  const projects = useProjectListForSelect();

  const handleSubmit = useCallback(
    values => {
      if (isCloneType) {
        cloneSeed(values);

        return;
      }

      createSeed(values);
    },
    [cloneSeed, createSeed, isCloneType]
  );

  useEffect(() => {
    if (isClonedSuccess || isCreatedSuccess) {
      onClose();
    }
  }, [isClonedSuccess, isCreatedSuccess, onClose]);

  return (
    <Modal isOpen={isOpen} size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>{isCloneType ? 'Clone version' : 'New version'}</ModalHeader>
      <Form<SeedType> defaultValues={defaultValues} onSubmit={handleSubmit} modalMode>
        <FormField.Text<SeedType> name="name" label="Version name" required />
        <FormField.Select<SeedType> name="projectId" label="Select project" options={projects} required />

        <FormField.Text<SeedType> name="version" label="Version" required />
        <FormField.Text<SeedType> name="nameLong" label="Longer descriptive name" />
        <FormField.Text<SeedType> name="description" label="Short description" />

        {isCloneType ? null : (
          <>
            <hr />
            <FormField.Switch<SeedType & { createBoilerPlate: boolean }>
              name="createBoilerPlate"
              label="Create boilerplate"
              description="Sets up root part (Root|Default), default view and options tab automatically for this seed"
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default SeedCreationModal;
