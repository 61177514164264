import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from '../../utils/hooks';
import { invitationsActions } from '../../modules/invitations';
import InvitationForm from '../InvitationForm';
import ProtectedButton from '../ProtectedButton';
import { RIGHTS } from '../../utils/rights';

const SendInvitationButton = () => {
  const { isOpen, open, close } = useModal();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    value => {
      dispatch(invitationsActions.createInvitation(value));
    },
    [dispatch]
  );

  return (
    <>
      {isOpen ? <InvitationForm onClose={close} onSubmit={handleSubmit} title="Invite new user" /> : null}
      <ProtectedButton rights={[RIGHTS.INVITATIONS__CREATE]} onClick={open} color="primary">
        Invite new user
      </ProtectedButton>
    </>
  );
};

export default SendInvitationButton;
