import React from 'react';
import PropTypes from 'prop-types';
import { CodeEditor } from '../Atoms';

const MODE = {
  CSS: 'css',
  JSON: 'json'
};

class SettingsEditor extends React.PureComponent {
  get mode() {
    return this.props.type === 'settings' ? MODE.JSON : MODE.CSS;
  }

  get isJsonMode() {
    return this.mode === MODE.JSON;
  }

  get isCssMode() {
    return this.mode === MODE.CSS;
  }

  handleChange = value => {
    const { onChange, type } = this.props;

    if (this.isJsonMode) {
      try {
        JSON.parse(value);

        onChange(value, type);
      } catch (e) {
        return false;
      }
    }

    if (this.isCssMode) {
      try {
        window.btoa(value);

        onChange(value, type);
      } catch (e) {
        return false;
      }
    }

    return true;
  };

  handleValidate = errors => {
    const invalid = errors.length > 0;

    this.props.onValid(invalid);
  };

  render() {
    const { value, type } = this.props;

    return (
      <CodeEditor
        mode={this.mode}
        onChange={this.handleChange}
        onValidate={this.handleValidate}
        name={`editor-${type}`}
        value={value}
      />
    );
  }
}

SettingsEditor.defaultProps = {
  type: 'settings',
  onChange: () => null,
  onValid: () => null
};

SettingsEditor.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['settings', 'theme']),
  onChange: PropTypes.func,
  onValid: PropTypes.func
};

export default SettingsEditor;
