import { FC } from 'react';
import BreadcrumbBackButton from '../BreadcrumbBackButton';
import { ApplicationRoute } from '../../../utils/appRoutes';
import BreadcrumbSection from '../BreadcrumbSection';

interface DefaultBreadcrumbsProps {
  title: string;
}

const DefaultBreadcrumbs: FC<DefaultBreadcrumbsProps> = ({ title }) => (
  <>
    <BreadcrumbBackButton to={ApplicationRoute.Main} />
    <BreadcrumbSection title={title} />
  </>
);

export default DefaultBreadcrumbs;
