import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { authActions, authHooks } from '../../../modules/auth';
import RegistrationAccountForm from './RegistrationAccountForm';

const ValidateInvitation = ({ invitationId }) => {
  const dispatch = useDispatch();
  const { checked, valid } = authHooks.useAuthInvitation();

  useEffect(() => {
    dispatch(authActions.verifyInvitation(invitationId));
  }, [dispatch, invitationId]);

  if (!checked) {
    return (
      <div className="text-center">
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!valid) {
    return <p className="text-center">The invitation is expired or does not exist</p>;
  }

  return <RegistrationAccountForm />;
};

ValidateInvitation.propTypes = {
  invitationId: PropTypes.string.isRequired
};

export default ValidateInvitation;
