import { ActionCreatorFnType, ActionCreatorsGroupType, ModuleActionCreatorsType } from 'types';

export const createBaseActionCreator: ActionCreatorFnType = type => () => ({ type });
export const createRequestActionCreator: ActionCreatorFnType = type => () => ({ type });
export const createSuccessActionCreator: ActionCreatorFnType = type => response => ({ type, payload: response });
export const createFailureActionCreator: ActionCreatorFnType = type => error => ({ type, payload: { error } });

export const createModulePrefixForActionType = (MODULE: string, TYPE: string) => `${MODULE}/${TYPE}`;

export const createRequestActionType = (TYPE: string) => `${TYPE}__REQUEST`;
export const createSuccessActionType = (TYPE: string) => `${TYPE}__SUCCESS`;
export const createFailureActionType = (TYPE: string) => `${TYPE}__FAILURE`;

export function createAsyncActionCreator(
  ACTION_TYPE: string,
  {
    base = createBaseActionCreator,
    request = createRequestActionCreator,
    success = createSuccessActionCreator,
    failure = createFailureActionCreator
  } = {}
): ActionCreatorsGroupType {
  const REQUEST_ACTION_TYPE = createRequestActionType(ACTION_TYPE);
  const SUCCESS_ACTION_TYPE = createSuccessActionType(ACTION_TYPE);
  const FAILURE_ACTION_TYPE = createFailureActionType(ACTION_TYPE);

  return Object.assign(base(ACTION_TYPE), {
    ACTION_TYPE,
    request: Object.assign(request(REQUEST_ACTION_TYPE), { ACTION_TYPE: REQUEST_ACTION_TYPE }),
    success: Object.assign(success(SUCCESS_ACTION_TYPE), { ACTION_TYPE: SUCCESS_ACTION_TYPE }),
    failure: Object.assign(failure(FAILURE_ACTION_TYPE), { ACTION_TYPE: FAILURE_ACTION_TYPE })
  });
}

export function createModuleAsyncActionCreators<T>(module: string): ModuleActionCreatorsType {
  const fetchList = createAsyncActionCreator(createModulePrefixForActionType(module, 'FETCH_LIST'));
  const createEntity = createAsyncActionCreator(createModulePrefixForActionType(module, 'CREATE_ENTITY'), {
    base: type => (entity: T) => ({ type, payload: { entity } })
  });
  const updateEntity = createAsyncActionCreator(createModulePrefixForActionType(module, 'UPDATE_ENTITY'), {
    base: type => (entity: T) => ({ type, payload: { entity } })
  });
  const deleteEntity = createAsyncActionCreator(createModulePrefixForActionType(module, 'DELETE_ENTITY'), {
    base: type => (id: string) => ({ type, payload: { id } }),
    success: type => (id: string) => ({ type, payload: { id } })
  });

  const RESET_FLAGS = createModulePrefixForActionType(module, 'RESET_FLAGS');
  const resetFlags = () => ({ type: RESET_FLAGS });

  return { fetchList, createEntity, updateEntity, deleteEntity, resetFlags, RESET_FLAGS };
}
