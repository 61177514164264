import { MutableState, Tools } from 'final-form';
import arrayMutators from 'final-form-arrays';

const formMutators = {
  setValue<T>([field, newValue]: [string, any], state: MutableState<T>, helpers: Tools<T>) {
    helpers.changeValue(state, field, () => newValue);
  },
  ...arrayMutators
};

export default formMutators;
