import b from 'b_';
import { CellProps, Column } from 'react-table';
import { SeedStatisticsType, StatisticsType } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Table } from '../../Atoms';
import { statisticsActions, statisticsSelectors } from '../../../modules/statistics';
import Loader from '../../Loader';
import { msToTime } from '../../../utils';
import StatisticsData from './StatisticsData';

import './VisitorStatistics.scss';

const visitorStatistics = b.with('visitor-statistics');

const columns: Column<SeedStatisticsType>[] = [
  {
    Header: 'Seed',
    accessor: '_id',
    minWidth: 150,
    disableFilters: true
  },
  {
    Header: 'Total visits',
    accessor: 'totalVisits',
    minWidth: 130,
    disableFilters: true
  },
  {
    Header: 'Total time',
    accessor: 'totalTime',
    Cell: ({ value }) => (value ? msToTime(value) : null),
    minWidth: 130,
    disableFilters: true
  },
  {
    Header: 'Total requests',
    accessor: 'requests',
    Cell: ({ value }) => value.length,
    minWidth: 130,
    disableFilters: true
  },
  {
    Header: 'First visit',
    accessor: row => (Array.isArray(row.visits) && row.visits.length ? new Date(row.visits[0].date) : null),
    maxWidth: 350,
    Cell: ({ value }: CellProps<SeedStatisticsType, Date | null>) => (value ? value.toLocaleDateString() : ''),
    minWidth: 150,
    disableFilters: true
  },
  {
    Header: 'Last visit',
    accessor: row =>
      Array.isArray(row.visits) && row.visits.length ? new Date(row.visits[row.visits.length - 1].date) : null,
    maxWidth: 350,
    Cell: ({ value }: CellProps<SeedStatisticsType, Date | null>) => (value ? value.toLocaleDateString() : ''),
    minWidth: 150,
    disableFilters: true
  }
];

const useStatistics = (userId: string) => {
  const dispatch = useDispatch();

  const onGetStatistics = useCallback(() => {
    dispatch(statisticsActions.fetchUserStatistics(userId));
  }, [dispatch, userId]);

  const statistics: StatisticsType = useSelector(statisticsSelectors.selectStatisticsByUser(userId));

  useEffect(() => {
    onGetStatistics();
  }, [onGetStatistics]);

  return { onGetStatistics, statistics };
};

const getRowId = (row: SeedStatisticsType) => row._id;

const VisitorStatistics = ({ userId }: { userId: string }) => {
  const [selectedSeedId, setSelectedSeedId] = useState('');

  const { statistics, onGetStatistics } = useStatistics(userId);

  const seedStatistics = statistics?.seeds;

  const selectedSeedStats = useMemo(
    () => seedStatistics?.find((seed: SeedStatisticsType) => seed._id === selectedSeedId),
    [seedStatistics, selectedSeedId]
  );

  return (
    <div className={visitorStatistics()}>
      <Loader loading={false}>
        <div className="mb-3">
          <Button color="primary" size="sm" onClick={onGetStatistics} outline>
            <i className="fa fa-fw fa-refresh" /> Update
          </Button>
        </div>
        {statistics && statistics.seeds.length ? (
          <Table
            selectedRowId={selectedSeedId}
            onSelectRowId={setSelectedSeedId}
            getRowId={getRowId}
            data={statistics.seeds}
            columns={columns}
            className={`${visitorStatistics('table')}`}
          />
        ) : (
          <p>No statistics for the current user</p>
        )}
        {selectedSeedStats ? <StatisticsData statistics={selectedSeedStats} /> : null}
      </Loader>
    </div>
  );
};

export default VisitorStatistics;
