import { useAppSelector } from '../../store/hooks';
import { selectWindowSize } from './uiSelectors';

const SM_BREAKPOINT = 576;
const MD_BREAKPOINT = 768;
const LG_BREAKPOINT = 992;

export const useWindowSize = () => {
  const windowSize: { width: number; height: number } = useAppSelector(selectWindowSize);

  return {
    windowSize,
    xs: windowSize.width < SM_BREAKPOINT,
    sm: windowSize.width >= SM_BREAKPOINT && windowSize.width < MD_BREAKPOINT,
    md: windowSize.width >= MD_BREAKPOINT && windowSize.width < LG_BREAKPOINT,
    lg: windowSize.width >= LG_BREAKPOINT
  };
};
