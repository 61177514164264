import { FC } from 'react';
import b from 'b_';

import './Loader.scss';

const loader = b.with('loader');

const Spinner = () => (
  <div className={loader()}>
    <div className={`${loader('spinner')} spinner-border text-primary`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

const Loader: FC<{ loading: boolean }> = ({ loading = false, children = null }) => (
  <>{loading ? <Spinner /> : children}</>
);

export default Loader;
