import { useCallback } from 'react';
import { AssetType } from 'types';
import saveAs from 'file-saver';
import { Button, Modal, ModalBody, ModalFooter } from '../../Atoms';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';

interface AssetModalProps {
  onClose: () => void;
  asset: AssetType;
  onDeleteAsset: (i: string, unsafe?: string) => void;
  assetUrl: string;
  assetIdUrl: string;
}

const AssetModal = ({ onClose, asset, onDeleteAsset, assetUrl, assetIdUrl }: AssetModalProps) => {
  const deleteAssetHandler = useCallback(
    e => {
      const response = window.confirm(`Are you sure that you want to delete asset "${asset.filename}"?`); // eslint-disable-line no-alert

      if (response) {
        onDeleteAsset(asset._id, e.currentTarget.dataset.unsafe);
      }
    },
    [asset._id, asset.filename, onDeleteAsset]
  );

  const handleDownload = useCallback(() => {
    saveAs(assetIdUrl, asset.filename);
  }, [asset.filename, assetIdUrl]);

  const softDeleteRights = [RIGHTS.IMAGES__SOFT_DELETE, RIGHTS.TEXTURES__SOFT_DELETE];
  const hardDeleteRights = [RIGHTS.IMAGES__HARD_DELETE, RIGHTS.TEXTURES__HARD_DELETE];

  return (
    <Modal size="lg" isOpen toggle={onClose}>
      <ModalBody className="align-items-center d-flex flex-column">
        <img className="d-block mw-100" src={assetIdUrl} alt={asset.filename} />
      </ModalBody>
      <ModalFooter className="d-flex w-100">
        <ProtectedButton rights={softDeleteRights} size="sm" outline color="danger" onClick={deleteAssetHandler}>
          <i className="fa fa-ban fa-fw" />
          Remove
        </ProtectedButton>
        <ProtectedButton
          rights={hardDeleteRights}
          outline
          size="sm"
          color="danger"
          onClick={deleteAssetHandler}
          data-unsafe="true"
        >
          <i className="fa fa-trash fa-fw" />
          Delete
        </ProtectedButton>
        <Button onClick={handleDownload} color="primary" outline>
          Download
        </Button>

        <Button color="primary" onClick={onClose}>
          <i className="fa fa-times fa-fw" />
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AssetModal;
