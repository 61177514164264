import statisticsActions from './statisticsActions';

export const initialState = {
  list: {},
  error: '',
  request: false
};

const statisticsReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case statisticsActions.fetchUserStatistics.request.ACTION_TYPE:
      return { ...state, request: true };
    case statisticsActions.fetchUserStatistics.success.ACTION_TYPE: {
      const statistics = payload;

      return { ...state, list: { ...state.list, [statistics._id]: statistics }, request: false, error: '' };
    }
    case statisticsActions.fetchUserStatistics.failure.ACTION_TYPE:
      return { ...state, error: payload.error, request: false };
    default:
      return state;
  }
};

export default statisticsReducer;
