import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './modules';
import getInitialState from './modules/state';
import sagas from './modules/sagas';
import { api } from './store/services/api';
import { getLocalStorageItem, UI_SETTINGS_LOCALSTORAGE_KEY } from './utils/helpers/storage';
import errorHandlingMiddleware from './store/errorHandlingMiddleware';

const sagaMiddleware = createSagaMiddleware();

const userLocalStorageSettings = getLocalStorageItem(UI_SETTINGS_LOCALSTORAGE_KEY);
const initialState = userLocalStorageSettings ? { uiSettings: userLocalStorageSettings } : {};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([
      sagaMiddleware,
      api.middleware,
      errorHandlingMiddleware
    ]),
  preloadedState: getInitialState(initialState),
  devTools: process.env.NODE_ENV !== 'production'
});

sagaMiddleware.run(sagas);

declare module 'types' {
  export type AppDispatch = typeof store.dispatch;
}

export default store;
