import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import FieldWrapper from '../FieldWrapper';
import { Suspense } from '../../../Atoms';
import { useDebounce } from '../../../../utils/helpers/hooks';

import 'react-mde/lib/styles/css/react-mde.css';
import 'react-mde/lib/styles/css/react-mde-editor.css';
import 'react-mde/lib/styles/css/react-mde-toolbar.css';

import './Markdown.scss';

const ReactMde = React.lazy(() => import('react-mde'));

const uponSanitizeElement = (node, data) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';

    if (
      !src.startsWith('https://www.youtube.com/embed/') &&
      !src.startsWith('https://player.vimeo.com/') &&
      node.parentNode
    ) {
      node.parentNode.removeChild(node);
    }
  }
};

const afterSanitizeAttributes = node => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer noopener');
  }
};

const Markdown = props => {
  const [selectedTab, setSelectedTab] = useState('write');
  const { name, classes } = props;

  const field = useField(name);
  const { input } = field;

  const htmlField = useField(`${name}Html`);
  const { input: htmlInput } = htmlField;
  const { onChange: onChangeHtml } = htmlInput;

  const inputValueDelayed = useDebounce(input.value, 1000);

  const parseMarkdown = useCallback(md => {
    return Promise.all([import('marked'), import('dompurify')])
      .then(([{ default: marked }, DOMPurify]) => {
        DOMPurify.addHook('uponSanitizeElement', uponSanitizeElement);
        DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributes);

        return Promise.resolve(
          DOMPurify.sanitize(marked(md), {
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target']
          })
        );
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);

        return '';
      });
  }, []);

  useEffect(() => {
    async function updateHtmlInput() {
      const parsed = await parseMarkdown(inputValueDelayed);

      onChangeHtml(parsed);
    }

    updateHtmlInput();
  }, [onChangeHtml, inputValueDelayed, parseMarkdown]);

  const handleChange = useCallback(
    value => {
      input.onChange(value);
    },
    [input]
  );

  return (
    <FieldWrapper {...props}>
      <Suspense>
        <ReactMde
          value={input.value}
          onChange={handleChange}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={parseMarkdown}
          classes={classes}
        />
      </Suspense>
    </FieldWrapper>
  );
};

Markdown.defaultProps = {
  classes: {}
};

Markdown.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    reactMde: PropTypes.string,
    toolbar: PropTypes.string,
    preview: PropTypes.string,
    textArea: PropTypes.string,
    suggestionsDropdown: PropTypes.string
  })
};

export default Markdown;
