import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import filesize from 'filesize';
import Dropzone from 'react-dropzone';
import { Button, Modal, ModalBody } from '../Atoms';

import './FileUpload.scss';

const fileUpload = b.with('file-upload');

const MAX_FILE_SIZE = 5242880 * 30; // 150Mb

export const STATUSES = {
  WAITING: 0,
  PROGRESS: 1,
  SUCCESS: 2,
  FAILURE: 3
};

class FileUpload extends React.Component {
  state = {
    status: STATUSES.WAITING,
    files: {}
  };

  handleSelectFile = files => {
    if (files.length) {
      if (Object.values(this.state.files).length + files.length > this.props.limit) {
        return;
      }

      this.setState(prevState => ({
        files: {
          ...prevState.files,
          ...files.reduce((result, file) => {
            // eslint-disable-next-line no-param-reassign
            result[file.name] = { file, status: STATUSES.WAITING };

            return result;
          }, {})
        }
      }));
    }
  };

  handleUploadFiles = () => {
    const { multiUpload } = this.props;

    this.setState({ status: STATUSES.PROGRESS });

    if (multiUpload) {
      this.multiUploadFiles();
    } else {
      this.uploadFiles();
    }
  };

  handler(name, { data }) {
    if (data) {
      this.updateState(name, STATUSES.SUCCESS);

      return data;
    }

    this.updateState(name, STATUSES.FAILURE);

    return false;
  }

  get hasFiles() {
    return Object.values(this.state.files).length > 0;
  }

  get isButtonDisabled() {
    const { files, status } = this.state;

    return Object.values(files).length === 0 || status !== STATUSES.WAITING;
  }

  get isInputDisabled() {
    const { limit } = this.props;
    const { status, files } = this.state;

    return status !== STATUSES.WAITING || Object.values(files).length >= limit;
  }

  async multiUploadFiles() {
    const { files } = this.state;
    const { api, onError, onSuccess } = this.props;

    for (const { file } of Object.values(files)) {
      this.updateState(file.name, STATUSES.PROGRESS);
    }

    const result = await api(Object.values(files).map(({ file }) => file));

    if (result.data) {
      const { assets = [] } = result.data;
      let failure = true;
      const successResponse = [];

      assets.forEach(({ filename, asset }) => {
        this.updateState(filename, asset ? STATUSES.SUCCESS : STATUSES.FAILURE);

        if (asset) {
          failure = false;

          successResponse.push(asset);
        }
      });

      this.setState({ status: failure ? STATUSES.FAILURE : STATUSES.SUCCESS });

      if (failure) {
        onError();
      } else {
        onSuccess(successResponse);
      }
    }

    if (result.error) {
      this.setState({ status: STATUSES.FAILURE });

      onError();
    }
  }

  async uploadFiles() {
    const { files } = this.state;
    const { api, onError, onSuccess } = this.props;
    const results = [];

    for (const { file } of Object.values(files)) {
      this.updateState(file.name, STATUSES.PROGRESS);

      results.push(api(file).then(this.handler.bind(this, file.name)));
    }

    const result = await Promise.all(results);

    const failure = result.includes(false);

    if (failure) {
      onError(result);
    } else {
      onSuccess(result);
    }

    this.setState({ status: failure ? STATUSES.FAILURE : STATUSES.SUCCESS });
  }

  updateState(name, status) {
    this.setState(prevState => ({
      files: { ...prevState.files, [name]: { ...prevState.files[name], status } }
    }));
  }

  renderStatus = (status = 0) => {
    if (status === STATUSES.PROGRESS) {
      return <i className="fa fa-fw fa-spinner fa-spin text-primary" />;
    }

    if (status === STATUSES.SUCCESS) {
      return <i className="fa fa-fw fa-check text-success" />;
    }

    if (status === STATUSES.FAILURE) {
      return <i className="fa fa-fw fa-times text-danger" />;
    }

    return <i className="fa fa-fw fa-clock-o text-secondary" />;
  };

  render() {
    const { onClose, caption, limit, mimeTypes } = this.props;
    const { files } = this.state;

    return (
      <Modal
        isOpen
        toggle={onClose}
        centered
        className={fileUpload()}
        backdrop={this.hasFiles ? 'static' : true}
        keyboard={!this.hasFiles}
      >
        <ModalBody className={fileUpload('body')}>
          <h4 className="mb-3">{caption}</h4>
          <div className="border">
            <div className={fileUpload('files')}>
              <div className={`${fileUpload('files-header')} shadow-sm`}>
                <span className="border-end w-50 p-2">File name</span>
                <span className="border-end text-center w-25 p-2">Type</span>
                <span className="border-end text-center w-15 p-2">Size</span>
                <span className="w-10" />
              </div>
              <div className={fileUpload('list')}>
                {Object.values(files).map(({ file, status }) => (
                  <div className={fileUpload('file')} key={file.name}>
                    <span
                      className={`${fileUpload('file-name')} border-end w-50 p-2 overflow-hidden`}
                      title={file.name}
                    >
                      {file.name}
                    </span>
                    <span className="border-end text-center w-25 p-2">{file.type}</span>
                    <span className="border-end text-center w-15 p-2">{filesize(file.size)}</span>
                    <span className={`${fileUpload('file-status')} text-center w-10 p-2`}>
                      {this.renderStatus(status)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Dropzone
            accept={mimeTypes}
            onDrop={this.handleSelectFile}
            disabled={this.isInputDisabled}
            noDrag={this.isInputDisabled}
            multiple={limit > 1}
            maxSize={MAX_FILE_SIZE}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={fileUpload('input', { disabled: this.isInputDisabled, active: isDragActive })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="p-3 d-flex flex-column align-items-center">
                  <i className="fa fa-fw fa-3x fa-cloud-upload" />
                  <span>Drag and drop or click here</span>
                </div>
              </div>
            )}
          </Dropzone>
          <p className="text-end mb-4">
            <small>The maximum file size - 150MB.</small>
            <br />
            <small>The maximum number of files - {limit}</small>
          </p>
          <Button disabled={this.isButtonDisabled} onClick={this.handleUploadFiles} color="primary">
            <i className="fa fa-fw fa-upload" /> Upload
          </Button>
          <Button onClick={onClose} color="secondary" className="pull-right">
            Close
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

FileUpload.defaultProps = {
  onSuccess: () => null,
  onError: () => null,
  caption: 'Upload file',
  limit: 1,
  mimeTypes: undefined,
  multiUpload: false
};

FileUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
  api: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  limit: PropTypes.number,
  caption: PropTypes.string,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  multiUpload: PropTypes.bool
};

export default FileUpload;
