import { ReactNode } from 'react';
import { LocationDescriptor } from 'history';
import { useHistory } from 'react-router-dom';
import b from 'b_';

import './RoundedBox.scss';

const boxClassName = b.with('rounded-box');

export interface IContainerProps {
  link?: string | LocationDescriptor;
  children?: ReactNode[];
}

const RoundedBox = ({ children, link }: IContainerProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <button type="button" onClick={handleClick} className={boxClassName('wrapper')}>
      {children}
    </button>
  );
};

export default RoundedBox;
