import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApplicationRoute } from '../../utils/appRoutes';
import {
  DefaultBreadcrumbs,
  FactoryMapsBreadcrumbs,
  IframeScriptsBreadcrumbs,
  PartnersBreadcrumbs,
  ProjectsBreadcrumbs,
  SeedsBreadcrumbs,
  TemplatesBreadcrumbs,
  VisitorBreadcrumbs
} from './BreadcrumbsGroups';

import './Breadcrumbs.scss';

const BREADCRUMBS_MAP: Record<string, FC> = {
  [ApplicationRoute.Main]: () => <></>,

  [ApplicationRoute.Partners]: PartnersBreadcrumbs,
  [ApplicationRoute.Partner]: PartnersBreadcrumbs,
  [ApplicationRoute.PartnerCreation]: PartnersBreadcrumbs,

  [ApplicationRoute.Templates]: TemplatesBreadcrumbs,
  [ApplicationRoute.Template]: TemplatesBreadcrumbs,
  [ApplicationRoute.TemplateSource]: TemplatesBreadcrumbs,

  [ApplicationRoute.Users]: () => <DefaultBreadcrumbs title="Users" />,
  [ApplicationRoute.Invitations]: () => <DefaultBreadcrumbs title="Invitations" />,
  [ApplicationRoute.Roles]: () => <DefaultBreadcrumbs title="Roles" />,
  [ApplicationRoute.GlobalSettings]: () => <DefaultBreadcrumbs title="Global settings" />,
  [ApplicationRoute.GlobalTheme]: () => <DefaultBreadcrumbs title="Global theme" />,

  [ApplicationRoute.Plots]: () => <DefaultBreadcrumbs title="Plots" />,

  [ApplicationRoute.ProjectList]: ProjectsBreadcrumbs,
  [ApplicationRoute.Project]: ProjectsBreadcrumbs,
  [ApplicationRoute.ProjectSelfService]: ProjectsBreadcrumbs,
  [ApplicationRoute.ProjectAssets]: ProjectsBreadcrumbs,
  [ApplicationRoute.ProjectInfo]: ProjectsBreadcrumbs,
  [ApplicationRoute.ProjectSettings]: ProjectsBreadcrumbs,

  [ApplicationRoute.Seed]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedInfo]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedSettings]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedGraph]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedParts]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedMaterials]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedLights]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedViews]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedTabs]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedPrices]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedRemoteControls]: SeedsBreadcrumbs,
  [ApplicationRoute.SeedRemoteSwitches]: SeedsBreadcrumbs,

  [ApplicationRoute.Instances]: () => <DefaultBreadcrumbs title="Instances" />,

  [ApplicationRoute.Visitor]: VisitorBreadcrumbs,
  [ApplicationRoute.Visitors]: () => <DefaultBreadcrumbs title="Visitors" />,

  [ApplicationRoute.Requests]: () => <DefaultBreadcrumbs title="Requests" />,

  [ApplicationRoute.Assets]: () => <DefaultBreadcrumbs title="Assets" />,

  [ApplicationRoute.FactoryMaps]: FactoryMapsBreadcrumbs,
  [ApplicationRoute.FactoryMap]: FactoryMapsBreadcrumbs,

  [ApplicationRoute.IframeScripts]: IframeScriptsBreadcrumbs,
  [ApplicationRoute.IframeScript]: IframeScriptsBreadcrumbs
};

const Breadcrumbs = () => {
  return (
    <nav className="me-1 min-width-0" aria-label="breadcrumb">
      <ul className="breadcrumb mb-0 px-0 py-1 align-items-center bg-light flex-nowrap">
        <Switch>
          {Object.entries(BREADCRUMBS_MAP).map(([path, component]) => (
            <Route key={path} path={path} component={component} exact />
          ))}
        </Switch>
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
