import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PartType } from 'types';
import { partsActions, partsSelectors } from '../../../../modules/parts';
import { usePartList } from '../../../../utils/hooks';
import { Loader } from '../../../Atoms';
import Graph from '../../../Graph';
import PartModals from '../../PartList/PartModals';
import PartForm from '../../PartList/PartModals/PartForm';
import PartGraphToolbar from '../PartGraphToolbar';
import useGroupedGraph, { getPartHeight, partNodeHeights } from './useGroupedGraph';

interface GroupedPartGraphProps {
  seedId: string;
  projectId?: string;
  isPartModalOpen: boolean;
  onClosePartModal: () => void;
  onCreatePart: (p: PartType) => void;
  rootNodeId?: string;
  onOpenPartModal: () => void;
  onOpenPart: (id: string) => void;
  onOpenPartRaw: (id: string) => void;
  onDelete: (id: string) => void;
  onSwitch: () => void;
}

/** Used in part reducer which is not yet converted */
interface UnsavedPosition {
  id: string;
  x: number;
  y: number;
  height: number;
}

export interface DagreNode {
  id: string;
  width: number;
  height: number;
  x: number;
  y: number;
}

const sortByGraphY =
  <T extends string>(mergedPositions: Record<string, { x: number; y: number }>) =>
  (a: T, b: T) => {
    return (mergedPositions[a]?.y || 0) - (mergedPositions[b]?.y || 0);
  };

/** New Part graph with color coding and grouping by ReferenceName */
const GroupedPartGraph = ({
  seedId,
  projectId,
  isPartModalOpen,
  onClosePartModal,
  onCreatePart,
  onOpenPart,
  onOpenPartRaw,
  onOpenPartModal,
  rootNodeId = '',
  onDelete,
  onSwitch
}: GroupedPartGraphProps) => {
  const { request, parts } = usePartList(seedId);
  const partsMap = useSelector(partsSelectors.selectParts); // used for getting parts heights
  const { nodes, edges, mergedPositions } = useGroupedGraph(parts, onOpenPart, onOpenPartRaw, onDelete, rootNodeId);
  const isPristine = !useSelector(partsSelectors.selectArePartsUnsaved);
  const dispatch = useDispatch();

  const partIdsByReference = useMemo(() => {
    return parts.reduce<Record<string, string[]>>(
      (result, { ReferenceName: reference, _id, Config: { graphX = 0, graphY = 0 } = {} }) => {
        // eslint-disable-next-line no-param-reassign
        result[reference] = result[reference] || [];
        result[reference].push(_id);

        return result;
      },
      {}
    );
  }, [parts]);

  const handleNodeUpdate = useCallback(
    ({ nodeId, dX, dY, initialGraphX, initialGraphY }) => {
      const partIds = partIdsByReference[nodeId];

      const sortedPartIds = [...partIds].sort(sortByGraphY(mergedPositions));

      // offset is used to give parts their real individual graphY as well so that after
      // reordering the legacy part graph view still works
      let offsetY = 0;

      const updatedPartsPosition = sortedPartIds.reduce<
        Record<string, { _id: string; graphX: number; graphY: number }>
      >((result, partId) => {
        // eslint-disable-next-line no-param-reassign
        result[partId] = { _id: partId, graphX: initialGraphX + dX, graphY: initialGraphY + dY + offsetY };
        offsetY += getPartHeight(partsMap[partId]) + partNodeHeights.GAP;

        return result;
      }, {});

      dispatch(partsActions.updatePartsPosition(updatedPartsPosition));
    },
    [dispatch, mergedPositions, partIdsByReference, partsMap]
  );

  const nodesUpdateHandler = useCallback(
    (calculatedNodes: DagreNode[]) => {
      // calculated nodes' ids are references not partIds.
      // convert to partIds so that each needed part gets updated
      const expandedNodes: UnsavedPosition[] = [];

      calculatedNodes.forEach(({ id, x, y, height }) => {
        const partIds = partIdsByReference[id] || [];

        const sortedPartIds = [...partIds].sort(sortByGraphY(mergedPositions));

        // offset is used to give parts their real individual graphY as well so that after
        // reordering the legacy part graph view still works
        let offsetY = 0;

        sortedPartIds.forEach(partId => {
          expandedNodes.push({ id: partId, x, y: y + offsetY, height });
          offsetY += getPartHeight(partsMap[partId]) + partNodeHeights.GAP;
        });
      });

      // reformat to {id, x,y,height} for each part with this referencename
      dispatch(partsActions.reorderParts(expandedNodes));
    },
    [dispatch, mergedPositions, partIdsByReference, partsMap]
  );

  const submitHandler = useCallback(() => {
    dispatch(partsActions.saveUnsavedParts(seedId));
  }, [dispatch, seedId]);

  const resetHandler = useCallback(() => {
    dispatch(partsActions.resetUnsavedPartsInfo());
  }, [dispatch]);

  return (
    <Loader loading={request}>
      <Graph
        nodes={nodes}
        edges={edges}
        onUpdateNode={handleNodeUpdate}
        onReorderNodes={nodesUpdateHandler}
        toolbar={
          <PartGraphToolbar
            isPristine={isPristine}
            onSubmit={submitHandler}
            onReset={resetHandler}
            onOpenPartModal={onOpenPartModal}
            onSwitch={onSwitch}
            isGroupedMode
          />
        }
      />
      <PartModals projectId={projectId} />
      {isPartModalOpen ? (
        <PartForm onSubmit={onCreatePart} close={onClosePartModal} part={{}} projectId={projectId} seedId={seedId} />
      ) : null}
    </Loader>
  );
};

export default GroupedPartGraph;
