import { useEffect } from 'react';
import { uiActions } from '../modules/ui';
import { useAppDispatch } from './hooks';

const useSuccessNotification = (isSuccess: boolean, message: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuccess) {
      dispatch(uiActions.showSuccessNotification(message));
    }
  }, [dispatch, isSuccess, message]);
};

export default useSuccessNotification;
