export default class ServerError {
  constructor({ error, message, statusCode, data = {} }) {
    this.error = error;
    this.message = message;
    this.statusCode = statusCode;
    this.data = data;
  }

  get details() {
    if (this.statusCode !== 422 || !this.data[0]) return '';
    const { message = '', path } = this.data[0];

    return `${message} (${path.join('.')})`;
  }

  static parse = err => {
    const { code, response, aborted } = err;

    // Timeout error
    if (aborted || (code && code === 'ECONNABORTED')) {
      return new ServerError(ServerError.TIMEOUT_ERROR);
    }

    // Server error
    if (response && response.data && response.data.isServer) {
      return new ServerError(response.data);
    }

    // Login error
    if (response && response.status === 401 && response.data === 'Unauthorized') {
      return new ServerError({ ...ServerError.LOGIN_ERROR, statusCode: response.status });
    }

    console.error(err); // eslint-disable-line no-console

    return new ServerError(ServerError.UNKNOWN_ERROR);
  };

  static TIMEOUT_ERROR = {
    error: 'Timeout error',
    message: 'The server is not responding'
  };

  static LOGIN_ERROR = {
    error: 'Unauthorized',
    message: 'Invalid e-mail or password. Please try again'
  };

  static UNKNOWN_ERROR = {
    error: 'Unknown Error',
    message: 'Something is wrong. Please try again'
  };
}
