import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-final-form-arrays';
import Group from '../../Group';
import getTransformedChildren from './getTransformedChildren';

const PreviewFields = ({ name, subscription, children, headingIndex, headingFormat }) => {
  const { fields } = useFieldArray(name, { subscription });
  const childrenArray = React.Children.toArray(children);

  return fields.map((field, index) => {
    const transformedChildren = getTransformedChildren(field, index, childrenArray, { disabled: true });
    const values = fields.value[index];

    const header = headingFormat
      ? headingFormat(values, field, childrenArray)
      : values[childrenArray[headingIndex].props.name];

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Group useDragHandle={false} key={index} index={index} header={header}>
        {transformedChildren}
      </Group>
    );
  });
};

PreviewFields.propTypes = {
  name: PropTypes.string.isRequired,
  subscription: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  headingIndex: PropTypes.number,
  headingFormat: PropTypes.func
};

PreviewFields.defaultProps = {
  headingIndex: 0,
  headingFormat: undefined
};

export default PreviewFields;
