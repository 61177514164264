import { SubscriptionType } from 'types';
import { Column } from 'react-table';
import { Table } from '../Atoms';

interface SubscriptionTableProps {
  subscriptions: SubscriptionType[];
}

const SubscriptionTable = ({ subscriptions }: SubscriptionTableProps) => {
  const columns: Column<SubscriptionType>[] = [
    {
      accessor: '_id',
      Header: 'ID',
      disableFilters: true,
      disableSortBy: true
    },
    {
      accessor: 'partnerName',
      Header: 'Partner',
      disableFilters: true
    },
    {
      accessor: 'targetUrl',
      Header: 'Target',
      disableFilters: true,
      disableSortBy: true
    },
    {
      accessor: 'event',
      Header: 'Event',
      disableFilters: true,
      disableSortBy: true
    },
    {
      accessor: 'createdAt',
      Header: 'Created',
      disableFilters: true,
      Cell: row => new Date(row.value).toLocaleString()
    }
  ];

  return <Table columns={columns} data={subscriptions} defaultPageSize={subscriptions.length} striped />;
};

export default SubscriptionTable;
