import { FC } from 'react';
import RCsvDownloader from 'react-csv-downloader';
import { Button } from 'reactstrap';

interface Column {
  displayName?: string;
  id: string;
}

interface CsvDownloaderProps {
  filename: string;
  title: string;
  data: (
    | string[]
    | {
        [key: string]: string | null | undefined;
      }
  )[];
  columns?: (string | Column)[];
  separator?: ',' | ';';
  size?: 'sm' | 'md';
}

const CsvDownloader: FC<CsvDownloaderProps> = ({
  filename,
  title,
  data,
  columns = [],
  separator = ',',
  size = 'md'
}: CsvDownloaderProps) => {
  return (
    <RCsvDownloader filename={filename} datas={data} columns={columns} separator={separator}>
      <Button color="primary" disabled={data.length === 0} size={size} block outline>
        {title}
      </Button>
    </RCsvDownloader>
  );
};

export default CsvDownloader;
