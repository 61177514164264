import { useController, useFormContext } from 'react-hook-form';
import React, { useCallback } from 'react';
import { getConvertedRegisterOptions } from '../helpers';
import { CodeProps, FieldType } from '../../types';
import FieldWrapper from '../FieldWrapper';
import CodeEditor from '../../../CodeEditor/CodeEditor';

const ERROR_SEVERITY = 8;

const Code = <T,>({ name, label, description, required, readonly, placeholder, mode, onChange }: CodeProps<T>) => {
  const {
    setError,
    clearErrors,
    trigger,
    formState: { isValid }
  } = useFormContext<T>();
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const fieldError: any = fieldState.error;

  const handleValidate = useCallback(
    (issues: any[]) => {
      const issueToString = (issue: any) => `${issue.message} at ${issue.startLineNumber}:${issue.startColumn}`;

      if (issues.length) {
        setError(name, {
          type: 'manual',
          message: issues
            .filter(issue => issue.severity === ERROR_SEVERITY)
            .map(issueToString)
            .join('<br />')
        });

        return;
      }

      if (isValid) return;

      clearErrors(name);
      trigger(name);
    },
    [name, isValid, clearErrors, setError, trigger]
  );

  return (
    <FieldWrapper
      type={FieldType.CODE}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <div className={fieldError ? 'is-invalid border border-danger' : ''}>
        <CodeEditor
          mode={mode}
          readonly={readonly}
          value={field.value as string}
          onChange={(value?: string) => {
            field.onChange(value);
            onChange && onChange(value);
          }}
          onValidate={handleValidate}
        />
      </div>
    </FieldWrapper>
  );
};

export default Code;
