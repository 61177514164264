import { actions } from '../@helpers';

const MODULE_NAME = 'MAPPED_INSTANCES';

const FETCH_MAPPED_INSTANCE = actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_MAPPED_INSTANCE');
const FETCH_MAPPED_INSTANCE_PREVIEW = actions.createModulePrefixForActionType(
  MODULE_NAME,
  'FETCH_MAPPED_INSTANCE_PREVIEW'
);
const UPDATE_MAPPED_INSTANCE = actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_MAPPED_INSTANCE');
const CREATE_MAPPED_INSTANCE = actions.createModulePrefixForActionType(MODULE_NAME, 'CREATE_MAPPED_INSTANCE');

const fetchMappedInstance = actions.createAsyncActionCreator(FETCH_MAPPED_INSTANCE, {
  base: type => id => ({ type, payload: { id } })
});

const fetchMappedInstancePreview = actions.createAsyncActionCreator(FETCH_MAPPED_INSTANCE_PREVIEW, {
  base: type => instanceId => ({ type, payload: { instanceId } })
});

const createMappedInstance = actions.createAsyncActionCreator(CREATE_MAPPED_INSTANCE, {
  base: type => (instanceId, factoryId) => ({ type, payload: { instanceId, factoryId } })
});

const updateMappedInstance = actions.createAsyncActionCreator(UPDATE_MAPPED_INSTANCE, {
  base: type => mappedInstance => ({ type, payload: { mappedInstance } })
});

const mappedInstancesActions = {
  fetchMappedInstance,
  fetchMappedInstancePreview,
  createMappedInstance,
  updateMappedInstance,

  FETCH_MAPPED_INSTANCE,
  FETCH_MAPPED_INSTANCE_PREVIEW,
  CREATE_MAPPED_INSTANCE,
  UPDATE_MAPPED_INSTANCE
};

export default mappedInstancesActions;
