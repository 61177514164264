import React, { FC, ReactElement, ReactNode } from 'react';
import b from 'b_';
import { FormGroup, Label } from '../../../Atoms';
import ShadowValue from '../../ShadowValue/ShadowValue';
import FieldFeedback from './FieldFeedback';

const field = b.with('form-field');

export interface FieldProps {
  name: string;
  label?: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  noLabel?: boolean;
  required?: boolean;
  useShadowValue?: boolean;
  complex?: boolean;
  format?: (arg0: any) => any;
  parse?: (arg0: any) => any;
}

const dummyFormat = (i: string | number) => i;

const FieldWrapper: FC<FieldProps> = ({
  name,
  children,
  label = '',
  description = '',
  noLabel = false,
  required = false,
  useShadowValue = false,
  complex = false,
  format = dummyFormat
}) => {
  if (noLabel) {
    return children as ReactElement; // children are ReactNode and require casting for some reason
  }

  return (
    <FormGroup className={`${field()} w-100 d-block`}>
      <Label
        className={`${field('label')} fw-bold text-uppercase d-inline-block me-2 ${description ? 'mb-0' : 'mb-1'}`}
        htmlFor={name}
      >
        {label || name} {required ? '*' : null}
      </Label>
      <FieldFeedback description={description} />

      {useShadowValue ? (
        <ShadowValue name={name} complex={complex} format={format} label={label}>
          {children}
        </ShadowValue>
      ) : null}
      {children}
    </FormGroup>
  );
};

export default FieldWrapper;
