import b from 'b_';
import { useUiSettings } from '../store/modules/uiSettings';
import { useWindowSize } from '../modules/ui/uiHooks';
import Navigation from './Navigation';
import { Button, Collapse, Icon } from './Atoms';

const sidebarClassName = b.with('sidebar');

const Sidebar = () => {
  const { uiSettings, toggleSidebar, closeSidebar } = useUiSettings();
  const { sidebarOpened } = uiSettings;

  const { xs } = useWindowSize();

  return (
    <Collapse
      isOpen={!xs || sidebarOpened}
      className={`${sidebarClassName({
        opened: !xs && sidebarOpened,
        desktop: !xs,
        mobile: xs
      })} border-end bg-white overflow-auto`}
    >
      {xs ? (
        <div className="d-flex p-3">
          <Button onClick={closeSidebar} color="icon" size="sm" className="ms-auto">
            <Icon type="mdiClose" scale={1.2} />
          </Button>
        </div>
      ) : null}

      <Navigation opened={xs || sidebarOpened} />

      {xs ? null : (
        <>
          <Button
            onClick={toggleSidebar}
            color="dark"
            size="sm"
            className={`${sidebarClassName('toggle')} ${sidebarOpened ? 'align-self-end me-3' : 'align-self-center'}`}
          >
            <Icon type={sidebarOpened ? 'mdiChevronDoubleLeft' : 'mdiChevronDoubleRight'} scale={1.2} />
          </Button>
          <br />
        </>
      )}
    </Collapse>
  );
};

export default Sidebar;
