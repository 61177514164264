import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../Atoms';
import { authActions } from '../../modules/auth';
import { TERMS_OF_USE_LINK } from '../../config';

const TermsOfUseDialog = () => {
  const [opened, setOpened] = useState(false);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const onAccept = useCallback(() => {
    dispatch(authActions.acceptTermsOfUse());
  }, [dispatch]);

  const onOpenTermsOfUse = useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <Modal isOpen>
      <ModalHeader>New Terms of Use</ModalHeader>
      <ModalBody>
        <p className="text-uppercase fw-bold text-smaller">
          Creatomus Solutions OÜ updated Terms of Use for content managers
        </p>
        <Button
          color="primary"
          outline
          block
          tag="a"
          href={TERMS_OF_USE_LINK}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onOpenTermsOfUse}
        >
          Read new Terms of Use
        </Button>
        <p className="mt-2 text-uppercase fw-bold text-smaller">
          You need to accept New Terms if you want to continue using Creatomus admin
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline onClick={onClose}>
          Cancel and logout
        </Button>
        <Button color="primary" onClick={onAccept} disabled={!opened}>
          <i className="fa fa-check fa-fw" />
          Accept new Terms of Use
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TermsOfUseDialog;
