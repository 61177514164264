import { useCallback, useMemo } from 'react';
import { SeedRemoteControlType, SeedType } from 'types';
import { useUpdateSeedMutation } from '../../store/services/api';
import { RIGHTS } from '../../utils/rights';
import RawEditor from '../RawEditor';

interface RemoteControlsRawEditorProps {
  seed: SeedType;
}

const RemoteControlsRawEditor = ({ seed }: RemoteControlsRawEditorProps) => {
  const [update] = useUpdateSeedMutation();

  const handleSubmit = useCallback(
    ({ json }: { json: SeedRemoteControlType[] }) => {
      if (Array.isArray(json)) {
        update({ scope: 'main', data: { ...seed, remoteControls: json } });
      }
    },
    [seed, update]
  );

  const initialValues = useMemo(() => ({ json: seed.remoteControls }), [seed.remoteControls]);

  return <RawEditor rights={[RIGHTS.SUPERUSER]} initial={initialValues} onSubmit={handleSubmit} />;
};

export default RemoteControlsRawEditor;
