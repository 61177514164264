export const RIGHTS = {
  SUPERUSER: 'SUPERUSER',

  ROLES__READ: 'ROLES__READ',
  ROLES__CREATE: 'ROLES__CREATE',
  ROLES__UPDATE: 'ROLES__UPDATE',
  ROLES__DELETE: 'ROLES__DELETE',

  PARTNERS__READ: 'PARTNERS__READ',
  PARTNERS__CREATE: 'PARTNERS__CREATE',
  PARTNERS__UPDATE: 'PARTNERS__UPDATE',
  PARTNERS__DELETE: 'PARTNERS__DELETE',
  PARTNERS__CREATE_API_KEY: 'PARTNERS__CREATE_API_KEY',

  TEMPLATES__READ: 'TEMPLATES__READ',
  TEMPLATES__CREATE: 'TEMPLATES__CREATE',
  TEMPLATES__UPDATE: 'TEMPLATES__UPDATE',
  TEMPLATES__DELETE: 'TEMPLATES__DELETE',

  IFRAME_SCRIPTS__READ: 'IFRAME_SCRIPTS__READ',
  IFRAME_SCRIPT__CREATE: 'IFRAME_SCRIPT__CREATE',
  IFRAME_SCRIPT__UPDATE: 'IFRAME_SCRIPT__UPDATE',
  IFRAME_SCRIPT__DELETE: 'IFRAME_SCRIPT__DELETE',
  IFRAME_SCRIPT__SET_LATEST: 'IFRAME_SCRIPT__SET_LATEST',

  GLOBAL_THEME__READ: 'GLOBAL_THEME__READ',
  GLOBAL_THEME__UPDATE: 'GLOBAL_THEME__UPDATE',

  GLOBAL_SETTINGS__READ: 'GLOBAL_SETTINGS__READ',
  GLOBAL_SETTINGS__UPDATE: 'GLOBAL_SETTINGS__UPDATE',

  USERS__READ: 'USERS__READ',
  USERS__UPDATE: 'USERS__UPDATE',
  USERS__DELETE: 'USERS__DELETE',

  INVITATIONS__READ: 'INVITATIONS__READ',
  INVITATIONS__CREATE: 'INVITATIONS__CREATE',
  INVITATIONS__UPDATE: 'INVITATIONS__UPDATE',
  INVITATIONS__DELETE: 'INVITATIONS__DELETE',

  PLOTS__READ: 'PLOTS__READ',
  PLOTS__CREATE: 'PLOTS__CREATE',
  PLOTS__UPDATE: 'PLOTS__UPDATE',
  PLOTS__DELETE: 'PLOTS__DELETE',

  PROJECTS__READ: 'PROJECTS__READ',
  PROJECTS__CREATE: 'PROJECTS__CREATE',
  PROJECTS__UPDATE: 'PROJECTS__UPDATE',
  PROJECTS__DELETE: 'PROJECTS__DELETE',
  PROJECTS__UPDATE_PARTNER: 'PROJECTS__UPDATE_PARTNER',
  PROJECTS__SELF_SERVICE: 'PROJECTS__SELF_SERVICE',
  PROJECTS__EDIT_RAW: 'PROJECTS__EDIT_RAW',
  PROJECTS__EDIT_ASSETS: 'PROJECTS__EDIT_ASSETS',
  PROJECTS__READ_STATISTICS: 'PROJECTS__READ_STATISTICS',

  REQUESTS__READ: 'REQUESTS__READ',
  REQUESTS__DELETE: 'REQUESTS__DELETE',

  VISITORS__READ: 'VISITORS__READ',
  VISITORS__MAKE_LEAD_PRIMARY: 'VISITORS__MAKE_LEAD_PRIMARY',
  VISITORS__READ_PDF: 'VISITORS__READ_PDF',
  VISITORS__READ_SIGNED_PDF: 'VISITORS__READ_SIGNED_PDF',
  VISITORS__UPLOAD_SIGNED_PDF: 'VISITORS__UPLOAD_SIGNED_PDF',
  VISITORS__SEND_LEAD_TO_LEGALESIGN: 'VISITORS__SEND_LEAD_TO_LEGALESIGN',
  VISITORS__CHECK_LEGALESIGN_STATUS: 'VISITORS__CHECK_LEGALESIGN_STATUS',
  VISITORS__SEND_TO_PRODUCTION: 'VISITORS__SEND_TO_PRODUCTION',
  VISITORS__CANCEL_ORDER: 'VISITORS__CANCEL_ORDER',
  VISITORS__RUN_INTEGRATION: 'VISITORS__RUN_INTEGRATION',
  VISITORS__UPDATE: 'VISITORS__UPDATE',

  VISITORS__STATISTICS__READ: 'VISITORS__STATISTICS__READ',

  MAPPED_INSTANCES__CREATE: 'MAPPED_INSTANCES__CREATE',
  MAPPED_INSTANCES__UPDATE: 'MAPPED_INSTANCES__UPDATE',

  SEEDS__READ: 'SEEDS_READ',
  SEEDS__CREATE: 'SEEDS__CREATE',
  SEEDS__UPDATE: 'SEEDS__UPDATE',
  SEEDS__DELETE: 'SEEDS__DELETE',
  SEEDS__CLONE: 'SEEDS__CLONE',
  SEEDS__PRICING_UPDATE: 'SEEDS__PRICING_UPDATE',
  SEEDS__ANALYTICS: 'SEEDS__ANALYTICS',
  SEEDS__EDIT_RAW: 'SEEDS__EDIT_RAW',
  SEEDS__MATERIALS_UPDATE: 'SEEDS__MATERIALS_UPDATE',
  SEEDS__REMOTE_SWITCHES_UPDATE: 'SEEDS__REMOTE_SWITCHES_UPDATE',

  PARTS__READ: 'PARTS__READ',
  PARTS__CREATE: 'PARTS__CREATE',
  PARTS__UPDATE: 'PARTS__UPDATE',
  PARTS__DELETE: 'PARTS__DELETE',
  PARTS__GRASSHOPPER: 'PARTS__GRASSHOPPER',
  PARTS__EDIT_RAW: 'PARTS__EDIT_RAW',

  REMOTE_CONTROLS__READ: 'REMOTE_CONTROLS__READ',
  REMOTE_CONTROLS__UPDATE: 'REMOTE_CONTROLS__UPDATE',
  REMOTE_CONTROLS__DELETE: 'REMOTE_CONTROLS__DELETE',

  IMAGES__READ: 'IMAGES__READ',
  IMAGES__UPLOAD: 'IMAGES__UPLOAD',
  IMAGES__UPDATE: 'IMAGES__UPDATE',
  IMAGES__HARD_DELETE: 'IMAGES__HARD_DELETE',
  IMAGES__SOFT_DELETE: 'IMAGES__SOFT_DELETE',
  IMAGES__ANALYSE: 'IMAGES__ANALYSE',
  IMAGES__COPY_TO_PROJECT: 'IMAGES__COPY_TO_PROJECT',

  TEXTURES__READ: 'TEXTURES__READ',
  TEXTURES__UPLOAD: 'TEXTURES__UPLOAD',
  TEXTURES__UPDATE: 'TEXTURES__UPDATE',
  TEXTURES__HARD_DELETE: 'TEXTURES__HARD_DELETE',
  TEXTURES__SOFT_DELETE: 'TEXTURES__SOFT_DELETE',
  TEXTURES__ANALYSE: 'TEXTURES__ANALYSE',
  TEXTURES__COPY_TO_PROJECT: 'TEXTURES__COPY_TO_PROJECT',

  ASSETS__MANIPULATE_GLOBALS: 'ASSETS__MANIPULATE_GLOBALS',

  MODELS__READ: 'MODELS__READ',
  MODELS__UPLOAD: 'MODELS__UPLOAD',
  MODELS__UPDATE: 'MODELS__UPDATE',
  MODELS__HARD_DELETE: 'MODELS__HARD_DELETE',
  MODELS__SOFT_DELETE: 'MODELS__SOFT_DELETE',

  FACTORY_MAPS__READ: 'FACTORY_MAPS__READ',
  FACTORY_MAPS__CREATE: 'FACTORY_MAPS__CREATE',
  FACTORY_MAPS__UPDATE: 'FACTORY_MAPS__UPDATE',
  FACTORY_MAPS__DELETE: 'FACTORY_MAPS__DELETE',
  FACTORY_MAPS__PUBLISH: 'FACTORY_MAPS__PUBLISH',

  INSTANCES__READ: 'INSTANCES__READ',
  INSTANCES__DELETE: 'INSTANCES__DELETE',
  INSTANCES__UPDATE: 'INSTANCES__UPDATE',

  SUBSCRIPTIONS__READ: 'SUBSCRIPTIONS__READ'
};

const arr = Object.values(RIGHTS);

export const rightList = arr.map(right => ({ value: right, label: right }));

export default arr;
