import { all, call, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import statisticsActions from './statisticsActions';

export function* doFetchUserStatisticsSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    statisticsActions.fetchUserStatistics,
    api.statistics.getUserStatistics,
    payload.userId
  );
}

export function* watchFetchUserStatisticsSaga() {
  yield takeLatest(statisticsActions.FETCH_USER_STATISTICS, doFetchUserStatisticsSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchUserStatisticsSaga()]);
}
