import { FieldType, NumberInputProps } from '../../types';
import BaseInput from './BaseInput';

const setValueAs = (value: string) => parseInt(value, 10) || '';

const Number = <T,>({ min, max, required, allowEmpty, ...rest }: NumberInputProps<T>) => {
  return (
    <BaseInput
      {...rest}
      type={FieldType.NUMBER}
      registerOptions={{ required, min, max, setValueAs, valueAsNumber: !allowEmpty }}
    />
  );
};

export default Number;
