import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ProjectStatisticsType,
  ProjectType,
  SeedRemoteControlType,
  SeedType,
  SubscriptionType,
  TemplateOptionsType,
  TemplateType,
  LastUpdatedInstancesType,
  PartnerType,
  UserType,
  RoleType,
  InvitationType
} from 'types';
import { EntityState } from '@reduxjs/toolkit';
import { API_URL } from '../../config';
import { projectsAdapter, seedsAdapter, subscriptionsAdapter, templatesAdapter } from './adapter';

const PROJECTS_TAG = 'Projects';
const SEEDS_TAG = 'Seeds';
const TEMPLATES_TAG = 'Templates';

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, credentials: 'include' }),
  tagTypes: [PROJECTS_TAG, SEEDS_TAG, TEMPLATES_TAG],
  endpoints: build => ({
    // PROJECTS
    getProjects: build.query<EntityState<ProjectType>, void>({
      query: () => `projects`,
      transformResponse(response: ProjectType[]) {
        return projectsAdapter.addMany(projectsAdapter.getInitialState(), response);
      },
      providesTags: data => [
        ...(data?.ids || []).map(id => ({ type: PROJECTS_TAG, id } as const)),
        { type: PROJECTS_TAG, id: 'LIST' }
      ]
    }),
    createProject: build.mutation<ProjectType, Partial<ProjectType>>({
      query(body) {
        return { url: `projects`, method: 'POST', body };
      },
      invalidatesTags: [{ type: PROJECTS_TAG, id: 'LIST' }]
    }),
    updateProject: build.mutation<ProjectType, Partial<ProjectType> & Pick<ProjectType, '_id'>>({
      query(data) {
        const { _id, ...body } = data;

        return { url: `projects/${_id}`, method: 'PUT', body };
      },
      invalidatesTags: (result, error, data) => [{ type: PROJECTS_TAG, id: data._id }]
    }),
    deleteProject: build.mutation<{ id: string }, string>({
      query(id) {
        return { url: `projects/${id}`, method: 'DELETE' };
      },
      invalidatesTags: (result, error, id) => [{ type: PROJECTS_TAG, id }]
    }),
    getProjectStatistic: build.query<ProjectStatisticsType, string>({
      query: id => `projects/${id}/statistics`
    }),
    getLastUpdatedProjects: build.query<ProjectType[], void>({
      query: () => `projects-last-updated`
    }),

    // TEMPLATES
    getTemplates: build.query<EntityState<TemplateType>, string>({
      query: partnerId => `partners/${partnerId}/templates`,
      transformResponse(response: TemplateType[]) {
        return templatesAdapter.addMany(templatesAdapter.getInitialState(), response);
      },
      providesTags: (data, error, partnerId) => [{ type: TEMPLATES_TAG, id: partnerId }]
    }),
    createTemplate: build.mutation<TemplateType, Partial<TemplateType>>({
      query(body) {
        return { url: `partners/${body.partnerId}/templates`, method: 'POST', body };
      },
      invalidatesTags: (result, error, { partnerId }) => [{ type: TEMPLATES_TAG, id: partnerId }]
    }),
    updateTemplate: build.mutation<TemplateType, Partial<TemplateType>>({
      query(body) {
        return { url: `partners/${body.partnerId}/templates/${body._id}`, method: 'PUT', body };
      },
      invalidatesTags: (result, error, { partnerId }) => [{ type: TEMPLATES_TAG, id: partnerId }]
    }),
    deleteTemplate: build.mutation<{ id: string }, { partnerId: string; id: string }>({
      query({ partnerId, id }) {
        return { url: `partners/${partnerId}/templates/${id}`, method: 'DELETE' };
      },
      invalidatesTags: (result, error, { partnerId }) => [{ type: TEMPLATES_TAG, id: partnerId }]
    }),
    getTemplateHtmlPreview: build.mutation<
      { body: string; header: string; footer: string; variables: Record<any, any> },
      { instanceId: string; body?: string; header?: string; footer?: string }
    >({
      query({ instanceId, body, header, footer }) {
        return { url: `instances/${instanceId}/html-preview`, method: 'POST', body: { body, header, footer } };
      }
    }),
    getTemplatePdfPreview: build.mutation<
      string,
      { instanceId: string; body?: string; header?: string; footer?: string; options?: TemplateOptionsType }
    >({
      query({ instanceId, body, header, footer, options }) {
        return {
          url: `instances/${instanceId}/pdf-preview`,
          method: 'POST',
          body: { body, header, footer, options },
          responseHandler: response => response.blob().then(blob => URL.createObjectURL(blob))
        };
      }
    }),

    // SEEDS
    getSeeds: build.query<EntityState<SeedType>, void>({
      query: () => `seeds`,
      transformResponse(response: SeedType[]) {
        return seedsAdapter.addMany(seedsAdapter.getInitialState(), response);
      },
      providesTags: data => [
        ...(data?.ids || []).map(id => ({ type: SEEDS_TAG, id } as const)),
        { type: SEEDS_TAG, id: 'LIST' }
      ]
    }),
    createSeed: build.mutation<SeedType, Partial<SeedType>>({
      query(body) {
        return { url: `seeds`, method: 'POST', body };
      },
      invalidatesTags: [{ type: SEEDS_TAG, id: 'LIST' }]
    }),
    cloneSeed: build.mutation<SeedType, Partial<SeedType> & Pick<SeedType, '_id'>>({
      query(body) {
        return { url: `seeds/${body._id}/clone`, method: 'POST', body };
      },
      invalidatesTags: [{ type: SEEDS_TAG, id: 'LIST' }]
    }),
    updateSeed: build.mutation<
      SeedType,
      { data: Partial<SeedType> & Pick<SeedType, '_id'>; scope: 'main' | 'selfService' }
    >({
      query({ data, scope }) {
        const { _id, ...body } = data;

        return { url: `seeds/${_id}`, method: 'PATCH', body, params: { scope } };
      },
      invalidatesTags: (result, error, { data }) => [{ type: SEEDS_TAG, id: data._id }]
    }),
    deleteSeed: build.mutation<{ id: string }, string>({
      query(id) {
        return { url: `seeds/${id}`, method: 'DELETE' };
      },
      invalidatesTags: (result, error, id) => [{ type: SEEDS_TAG, id }]
    }),
    updateSeedPrices: build.mutation<SeedType, Pick<SeedType, '_id' | 'prices'>>({
      query(data) {
        const { _id, prices } = data;

        return { url: `seeds/${_id}/update-prices`, method: 'PATCH', body: { prices } };
      },
      invalidatesTags: (result, error, data) => [{ type: SEEDS_TAG, id: data._id }]
    }),
    updateSeedMaterials: build.mutation<SeedType, Pick<SeedType, '_id' | 'materials'>>({
      query(data) {
        const { _id, materials } = data;

        return { url: `seeds/${_id}/update-materials`, method: 'PATCH', body: { materials } };
      },
      invalidatesTags: (result, error, data) => [{ type: SEEDS_TAG, id: data._id }]
    }),
    updateSeedRemoteControl: build.mutation<SeedType, { _id: string; remoteControl: SeedRemoteControlType }>({
      query(data) {
        const { _id, remoteControl } = data;

        return { url: `seeds/${_id}/update-remote-control`, method: 'PATCH', body: { remoteControl } };
      },
      invalidatesTags: (result, error, data) => [{ type: SEEDS_TAG, id: data._id }]
    }),
    deleteSeedRemoteControl: build.mutation<SeedType, { _id: string; remoteControl: SeedRemoteControlType }>({
      query(data) {
        const { _id, remoteControl } = data;

        return { url: `seeds/${_id}/delete-remote-control`, method: 'PATCH', body: { remoteControl } };
      },
      invalidatesTags: (result, error, data) => [{ type: SEEDS_TAG, id: data._id }]
    }),
    updateSeedRemoteSwitches: build.mutation<SeedType, Pick<SeedType, '_id' | 'remoteSwitches'>>({
      query(data) {
        const { _id, remoteSwitches } = data;

        return { url: `seeds/${_id}/update-remote-switches`, method: 'PATCH', body: { remoteSwitches } };
      },
      invalidatesTags: (result, error, data) => [{ type: SEEDS_TAG, id: data._id }]
    }),

    // SUBSCRIPTIONS
    getSubscriptions: build.query<EntityState<SubscriptionType>, void>({
      query: () => `subscriptions`,
      transformResponse(response: SubscriptionType[]) {
        return subscriptionsAdapter.addMany(subscriptionsAdapter.getInitialState(), response);
      }
    }),

    // INSTANCES
    getLastUpdatedInstances: build.query<LastUpdatedInstancesType, void>({
      query: () => `instances-last-updated`
    }),

    // PARTNERS
    getPartners: build.query<PartnerType[], void>({
      query: () => `partners`
    }),

    // USERS
    getUsers: build.query<UserType[], void>({
      query: () => `users`
    }),

    // ROLES
    getRoles: build.query<RoleType[], void>({
      query: () => `roles`
    }),

    // INVITATIONS
    getInvitations: build.query<InvitationType[], void>({
      query: () => `invitations`
    })
  })
});

export const {
  useGetProjectsQuery,
  useGetProjectStatisticQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetLastUpdatedProjectsQuery,

  useGetSeedsQuery,
  useCreateSeedMutation,
  useCloneSeedMutation,
  useUpdateSeedMutation,
  useDeleteSeedMutation,
  useUpdateSeedPricesMutation,
  useUpdateSeedMaterialsMutation,
  useUpdateSeedRemoteControlMutation,
  useDeleteSeedRemoteControlMutation,
  useUpdateSeedRemoteSwitchesMutation,

  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useGetTemplateHtmlPreviewMutation,
  useGetTemplatePdfPreviewMutation,

  useGetSubscriptionsQuery,

  useGetLastUpdatedInstancesQuery,

  useGetPartnersQuery,

  useGetUsersQuery,

  useGetRolesQuery,

  useGetInvitationsQuery
} = api;
