import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { FormSpyProps } from './types';

const FormSpy = <T,>({ onChange }: FormSpyProps<T>) => {
  const values = useWatch<T>({});

  useEffect(() => {
    onChange(values);
  }, [onChange, values]);

  return null;
};

export default FormSpy;
