import { createContext } from 'react';

interface FormContextProps {
  shadowValues?: {
    name?: string;
    value?: any;
  }[];
  readOnly?: boolean;
}

export const FormContext = createContext({ shadowValues: [], readOnly: false } as FormContextProps);

FormContext.displayName = 'FormContext';
