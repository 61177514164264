import { ProjectType } from 'types';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Icon, UncontrolledDropdown } from '../Atoms';
import { useUserRights } from '../../utils/hooks';
import { getPartnerUrl, getProjectUrl } from '../../utils/appRoutes';
import { RIGHTS } from '../../utils/rights';
import { useDeleteProject } from '../../store/project';

interface ProjectListItemActionsProps {
  project: ProjectType;
}

const ProjectListItemActions = ({ project }: ProjectListItemActionsProps) => {
  const { deleteProject } = useDeleteProject(project._id, project.name);
  const hasRights = useUserRights();

  return (
    <UncontrolledDropdown className="ms-auto p-0">
      <DropdownToggle color="icon" className="text-primary" size="sm">
        <Icon type="mdiDotsVertical" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="px-3 d-flex" tag={Link} to={getProjectUrl(project._id)}>
          <Icon type="mdiFolderOutline" />
          <span className="ps-2 fw-bolder">Open</span>
        </DropdownItem>
        {hasRights([RIGHTS.PARTNERS__READ]) ? (
          <DropdownItem className="px-3 d-flex" tag={Link} to={getPartnerUrl(project.partnerId)}>
            <Icon type="mdiAccountTie" />
            <span className="ps-2">Go to partner</span>
          </DropdownItem>
        ) : null}
        {hasRights([RIGHTS.PROJECTS__SELF_SERVICE]) ? (
          <DropdownItem className="px-3 d-flex" tag={Link} to={getProjectUrl(project._id, 'self-service')}>
            <Icon type="mdiAccountBoxOutline" />
            <span className="ps-2">Self-service</span>
          </DropdownItem>
        ) : null}
        {hasRights([RIGHTS.PROJECTS__DELETE]) ? (
          <DropdownItem className="px-3 d-flex dropdown-item-danger" onClick={deleteProject}>
            <Icon type="mdiDeleteOutline" />
            <span className="ps-2">Delete</span>
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProjectListItemActions;
