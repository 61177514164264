import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../../../../../Atoms';
import Field from '../../../../../Form/Field';
import { useAssetsActions } from '../../../../../../utils/hooks';

const MaterialForm = ({ fieldName, projectId }) => {
  const { projectTextures: textures } = useAssetsActions(projectId);

  return (
    <Field.ArrayObject name={fieldName} lockMoving label="Materials" noLabel>
      <Field.Text name="Name" label="Name" required />
      <Alert color="info">
        All textures except <b>Diffuse</b> (color), <b>Environment</b> and <b>Emissive</b> are treated as in linear
        color space.
      </Alert>

      <Field.Rgb
        name="ColorDiffuse"
        label="Color"
        description="For metallic materials this represents the reflection color."
      />
      <Field.Image
        name="Textures.Diffuse.Texture.fileName"
        label="Diffuse texture"
        description="Color map. This texture is gamma corrected (2.2)"
        hideUpload
        list={textures}
      />
      <Field.Rgb name="ColorEmission" label="Emission color (glow)" description="Set for light sources" />
      <Field.Image
        name="Textures.Emissive.Texture.fileName"
        label="Emissive texture"
        description="Set for light sources. This texture is gamma corrected (2.2). If this is set, make sure Emission color is something other than black."
        hideUpload
        list={textures}
      />
      <Field.Number
        name="IndexOfRefraction"
        label="IoR"
        description="Index of Refraction"
        min="1"
        max="3"
        step="0.01"
      />
      <Field.Number
        name="EmissiveIntensity"
        label="Emissive intensity"
        description="Defaults to 1"
        min={0}
        max={1}
        step={0.1}
      />

      <Field.Number
        name="Gloss"
        label="Gloss (Anti-Roughness)"
        description="This is Gloss not Roughness for legacy reasons. Opposite of Roughness for PBR (1 - Roughness)"
        min={0}
        max={1}
        step={0.1}
      />
      <Field.Image name="Textures.Roughness.Texture.fileName" label="Roughness map" hideUpload list={textures} />

      <Field.Number
        name="Metalness"
        label="Metalness"
        min={0}
        max={1}
        step={0.1}
        description="Usually 1 or 0. If undefined, will be either 0 or 1 depending on whether Metalness map is defined: 1 if defined, 0 if not."
      />
      <Field.Image name="Textures.Metalness.Texture.fileName" label="Metalness map" hideUpload list={textures} />

      <Field.Image
        name="Textures.Normal.Texture.fileName"
        label="Normal map"
        description="RGB map. Will prefer this over Bump map."
        hideUpload
        list={textures}
      />
      <Field.Toggle
        name="Textures.Normal.DisableFlipY"
        label="Disable Normal Map Y flipping"
        description={
          <span>
            By default, texture y is flipped. If normal map looks wrong, you can disable it. Read more about{' '}
            <a
              href="https://blender.stackexchange.com/questions/100017/directx-and-opengl-normal-maps"
              target="_blank"
              rel="noreferrer noopener"
            >
              DirectX and OpenGL normal maps
            </a>
          </span>
        }
      />
      <Field.Number name="NormalScale.x" label="Normal scale" min={0} max={1} step={0.1} />
      {/*
      If vectorN fields are added we can make NormalScale a vector control but until that just fix them together
      */}
      <Field.Alias name="NormalScale.y" alias="NormalScale.x" />
      <Field.Image
        name="Textures.AmbientOcclusion.Texture.fileName"
        label="AO map"
        description="Ambient Occlusion map. This requires second set of UVs. If none are supplied, will use duplicate first set."
        hideUpload
        list={textures}
      />
      <Field.Number
        name="AmbientOcclusionMapIntensity"
        label="Ambient Occlusion intensity"
        min={0}
        max={1}
        step={0.1}
      />

      <Field.Image
        name="Textures.Light.Texture.fileName"
        label="Light map"
        description="Light map. This requires second set of UVs. If none are supplied, will use duplicate first set."
        hideUpload
        list={textures}
      />
      <Field.Number name="LightMapIntensity" label="Light Map intensity" min={0} max={1} step={0.1} />

      <Field.Image
        name="Textures.Environment.Texture.fileName"
        label="Environement map"
        description="Equirectangular map. This is gamma corrected (2.2)"
        hideUpload
        list={textures}
      />

      <Field.Number name="EnvironmentMapIntensity" label="Environment Map intensity" min={0} max={1} step={0.1} />

      <Field.Number
        name="BumpScale"
        label="Bump scale"
        description="Somewhere around 1-10. Imperial units will require around 40 times smaller value (0 - 0.25)"
      />
      <Field.Image
        name="Textures.Bump.Texture.fileName"
        label="Bump map"
        description="If Normal Map is defined (PBR) this will be ignored."
        hideUpload
        list={textures}
      />
      <Field.Number name="Opacity" label="Opacity" min={0} max={1} step={0.1} />
      <Field.Image name="Textures.Transparency.Texture.fileName" label="Transparency map" hideUpload list={textures} />
      <Field.Toggle
        name="Transparent"
        label="Transparent"
        description="Transparent objects are drawn later than opaque"
        hideUpload
        list={textures}
      />
      <Field.Number
        name="AlphaTest"
        label="Alpha test factor"
        description="Factor from which a part of the texture is considered transparent"
        min={0}
        max={1}
        step={0.01}
      />
      <Field.Toggle name="Wireframe" label="Wireframe" description="Render material as wireframe" />
    </Field.ArrayObject>
  );
};

MaterialForm.propTypes = {
  projectId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default MaterialForm;
