function isStorageAvailable(type: 'localStorage' | 'sessionStorage') {
  try {
    const storage = window[type];
    const x = '__storage_test__';

    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (e) {
    return false;
  }
}

const LOCALSTORAGE_AVAILABLE = isStorageAvailable('localStorage');

export const setLocalStorageItem = (key: string, value: any) => {
  if (!LOCALSTORAGE_AVAILABLE) return;

  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  if (!LOCALSTORAGE_AVAILABLE) return undefined;

  const value = localStorage.getItem(key);

  if (value) return JSON.parse(value);

  return undefined;
};

export const UI_SETTINGS_LOCALSTORAGE_KEY = 'ui_settings';
