import { useUpdateProject } from '../../store/project';
import ProjectPageWrapper from './ProjectPageWrapper';
import ProjectInfoForm from './ProjectInfoForm';

const ProjectInfoPage = () => {
  const { updateProject } = useUpdateProject();

  return (
    <ProjectPageWrapper>{project => <ProjectInfoForm project={project} onSubmit={updateProject} />}</ProjectPageWrapper>
  );
};

export default ProjectInfoPage;
