import { PartType } from 'types';

const getPartFieldsToUpdate = (part: PartType, fields: Array<keyof PartType>) => {
  const result: Partial<PartType> = { _id: part._id, seed: part.seed };

  fields.forEach(field => {
    if (part[field]) {
      result[field] = part[field];
    }
  });

  return result;
};

export default getPartFieldsToUpdate;
