import { all, call, takeLatest, put } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import { uiActions } from '../ui';
import factoryMapsActions from './factoryMapsActions';

export function* doFetchFactoryMapsSaga() {
  yield call(sagas.doBaseAsyncSaga, factoryMapsActions.fetchFactoryMaps, api.factoryMaps.getList);
}

export function* watchFetchFactoryMapsSaga() {
  yield takeLatest(factoryMapsActions.FETCH_FACTORY_MAPS, doFetchFactoryMapsSaga);
}

export function* doCreateFactoryMapSaga({ payload }) {
  const { data } = yield call(
    sagas.doBaseAsyncSaga,
    factoryMapsActions.createFactoryMap,
    api.factoryMaps.create,
    payload.entity
  );

  if (data) {
    yield put(uiActions.showSuccessNotification('Factory map created successfully'));
  }
}

export function* watchCreateFactoryMapSaga() {
  yield takeLatest(factoryMapsActions.CREATE_FACTORY_MAP, doCreateFactoryMapSaga);
}

export function* doUpdateFactoryMapSaga({ payload }) {
  const { data } = yield call(
    sagas.doBaseAsyncSaga,
    factoryMapsActions.updateFactoryMap,
    api.factoryMaps.update,
    payload.entity
  );

  if (data) {
    yield put(uiActions.showSuccessNotification('Factory map updated successfully'));
  }
}

export function* watchUpdateFactoryMapSaga() {
  yield takeLatest(factoryMapsActions.UPDATE_FACTORY_MAP, doUpdateFactoryMapSaga);
}

export function* doDeleteFactoryMapSaga({ payload }) {
  const { data } = yield call(
    sagas.doBaseAsyncSaga,
    factoryMapsActions.deleteFactoryMap,
    api.factoryMaps.delete,
    payload.id
  );

  if (data) {
    yield put(uiActions.showSuccessNotification('Factory map deleted successfully'));
  }
}

export function* watchDeleteFactoryMapSaga() {
  yield takeLatest(factoryMapsActions.DELETE_FACTORY_MAP, doDeleteFactoryMapSaga);
}

export function* doSetPublishedFlagSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    factoryMapsActions.setPublishedFlag,
    api.factoryMaps.publish,
    payload.factoryId,
    payload.published
  );
}

export function* watchSetPublishedFlagSaga() {
  yield takeLatest(factoryMapsActions.SET_PUBLISHED_FLAG, doSetPublishedFlagSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchFactoryMapsSaga(),
    watchCreateFactoryMapSaga(),
    watchUpdateFactoryMapSaga(),
    watchDeleteFactoryMapSaga(),
    watchSetPublishedFlagSaga()
  ]);
}
