import { useFormContext } from 'react-hook-form';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';

export const Theming = () => {
  const { setValue, getValues } = useFormContext<ProjectType>();

  const base64toString = (base64string = '') => {
    return Buffer.from(base64string, 'base64').toString('ascii');
  };

  const stringToBase64 = (str = '') => {
    return Buffer.from(str).toString('base64');
  };

  const defaultValue = getValues('theme');

  setValue('themeAscii', base64toString(defaultValue));

  return (
    <FormField.Code<ProjectType>
      onChange={(value?: string) => {
        setValue('theme', stringToBase64(value), { shouldDirty: true, shouldValidate: true });
      }}
      mode="css"
      description="Project theme"
      name="themeAscii"
      label="Theme"
    />
  );
};
