import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useUserRights } from '../../utils/hooks';

const TabContent = ({ routes, activeScreen }) => {
  const hasRights = useUserRights();
  const currentScreen = useMemo(() => routes.find(({ name }) => name === activeScreen), [activeScreen, routes]);

  if (!hasRights(currentScreen.rights)) return null;

  const { component: Component } = currentScreen;

  return <Component />;
};

TabContent.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      rights: PropTypes.arrayOf(PropTypes.string.isRequired),
      component: PropTypes.elementType.isRequired
    })
  ).isRequired,
  activeScreen: PropTypes.string.isRequired
};

export default TabContent;
