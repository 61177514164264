import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Field, Form as RFForm } from 'react-final-form';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Row } from '../Atoms';
import { TYPE_PARTNER } from '../../utils/propTypes';
import FormField from '../FormField';
import { defaultPartner } from '../../utils/defaultValues';
import DeletePartnerButton from './DeletePartnerButton';

const partnerForm = b.with('partner-form');

const NOT_USED = 'Currently not used for anything';

const MAIN_SETTINGS = [
  {
    key: 'code',
    label: 'Code',
    type: 'text',
    placeholder: 'Partner code',
    required: true,
    text: 'Identification for this partner'
  },
  {
    key: 'name',
    label: 'Name',
    type: 'text',
    placeholder: 'Partner name',
    required: true,
    text: 'Used when sending documents to LegaleSign'
  },
  {
    key: 'domain',
    label: 'Domain',
    type: 'url',
    placeholder: 'Partner domain',
    required: true,
    text: NOT_USED
  },
  {
    key: 'email',
    label: 'E-mail',
    type: 'email',
    placeholder: 'Partner email',
    required: true,
    text: 'Used when sending emails from LegaleSign'
  }
];

const ADMIN_SETTINGS = {
  FIELDS: [
    {
      key: 'configuratorHost',
      label: 'Configurator host',
      type: 'url',
      placeholder: 'Host',
      required: true,
      text: 'Display a different host to the configurators in the Admin interface'
    },
    {
      key: 'logo',
      label: 'Logo',
      type: 'text',
      placeholder: 'Logo',
      text: NOT_USED
    },
    {
      key: 'title',
      label: 'Title',
      type: 'text',
      placeholder: 'Title',
      text: NOT_USED
    }
  ],
  CHECKBOXES: [
    { key: 'adminSettings.requestsShowSendToProduction', label: 'Enable SendToProduction' },
    { key: 'adminSettings.requestsShowReservationFee', label: 'Enable adding ReservationFee to Instances' },
    { key: 'adminSettings.requestsShowPlot', label: 'Show Plot column to Visitors list' },
    { key: 'adminSettings.isLegaleSignAvailable', label: 'Enable sending Requests to LegaleSign' }
  ]
};

const INTEGRATIONS = ['McStain', 'NUliving'].map(value => ({ value, label: value }));

const ERRORS = {
  required: 'Required'
};

class PartnerForm extends React.PureComponent {
  validateForm = values => {
    const errors = { adminSettings: {} };

    MAIN_SETTINGS.forEach(({ required, key }) => {
      if (required && !values[key]) {
        errors[key] = ERRORS.required;
      }
    });

    ADMIN_SETTINGS.FIELDS.forEach(({ required, key }) => {
      if (required && (!values.adminSettings || !values.adminSettings[key])) {
        errors.adminSettings[key] = ERRORS.required;
      }
    });

    return errors;
  };

  renderCheckbox = field => (
    <Field
      key={field.key}
      name={field.key}
      type="checkbox"
      render={({ input }) => (
        <FormGroup check>
          <Label for={field.key} check>
            {field.label}
          </Label>
          <Input type="checkbox" id={field.key} label={field.label} {...input} />
        </FormGroup>
      )}
    />
  );

  renderForm = ({ handleSubmit, form, submitting, pristine }) => (
    <Form className={partnerForm()} onSubmit={handleSubmit} autoComplete="off">
      <div className={`${partnerForm('buttons')} mb-2`}>
        <Button
          type="submit"
          color="primary"
          disabled={submitting || pristine || !form.getState || form.getState().invalid}
          outline
        >
          Save
        </Button>
        <Button onClick={form.reset} disabled={submitting || pristine} outline>
          Reset
        </Button>
        {this.props.created ? null : <DeletePartnerButton partner={this.props.partner} />}
      </div>
      <div className={`${partnerForm('fields')}`}>
        <Row>
          {MAIN_SETTINGS.map(field => (
            <Col md={6} key={field.key}>
              <FormField field={field} />
            </Col>
          ))}
        </Row>
        <h3 className="text-primary">Admin settings</h3>
        <Row>
          {ADMIN_SETTINGS.FIELDS.map(field => (
            <Col md={6} key={field.key}>
              <FormField field={{ ...field, key: `adminSettings.${field.key}` }} />
            </Col>
          ))}
          <Col md={6}>{ADMIN_SETTINGS.CHECKBOXES.map(this.renderCheckbox)}</Col>
        </Row>
        <h3 className="text-primary">Integration</h3>
        <Row>
          <Col>
            <FormGroup>
              <Label for="integrationType">Type</Label>
              <Field
                name="integrationType"
                parse={value => value}
                render={({ input }) => (
                  <Select
                    isClearable
                    name="integrationType"
                    value={{ value: input.value, label: input.value }}
                    onChange={value => input.onChange(value ? value.value : '')}
                    options={INTEGRATIONS}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Form>
  );

  render() {
    // In case of creating a new partner, the form is intialized with default values for all fields,
    // but only the ones on the the PartnerForm will be editable. The others will be saved as is.
    const { partner = defaultPartner, onSubmit } = this.props;

    return <RFForm onSubmit={onSubmit} initialValues={partner} render={this.renderForm} validate={this.validateForm} />;
  }
}

PartnerForm.defaultProps = {
  onSubmit: () => null,
  created: false
};

PartnerForm.propTypes = {
  partner: TYPE_PARTNER, // eslint-disable-line react/require-default-props
  onSubmit: PropTypes.func,
  created: PropTypes.bool
};

export default PartnerForm;
