import { nanoid } from 'nanoid/non-secure';
import * as uiActions from './uiActions';

export const getNotificationId = () => nanoid(12);

export const initialState = {
  visitors: {
    expanded: {},
    selected: {}
  },
  notifications: [],
  windowSize: {
    width: undefined,
    height: undefined
  }
};

const uiReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case uiActions.VISITOR_LIST__EXPAND:
      return { ...state, visitors: { ...state.visitors, expanded: payload.expanded } };
    case uiActions.VISITOR_LIST__SELECT_INSTANCE:
      return {
        ...state,
        visitors: { ...state.visitors, selected: { ...state.visitors.selected, [payload.visitor]: payload.instance } }
      };
    case uiActions.NOTIFICATION__SHOW: {
      const { message, timeout, details = '' } = payload;
      const notifications = [
        ...state.notifications,
        { id: getNotificationId(), type: payload.type, message, details, timeout }
      ];

      return { ...state, notifications };
    }
    case uiActions.NOTIFICATION__HIDE: {
      const notifications = state.notifications.map(notification =>
        notification.id === payload.id ? { ...notification, hidden: true } : notification
      );

      return { ...state, notifications };
    }
    case uiActions.SET_WINDOW_SIZE: {
      return { ...state, windowSize: payload };
    }
    default:
      return state;
  }
};

export default uiReducer;
