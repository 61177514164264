import { all, call, takeLatest, put } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import { uiActions } from '../ui';
import rolesActions from './rolesActions';

export function* doFetchRolesSaga() {
  yield call(sagas.doBaseAsyncSaga, rolesActions.fetchRoles, api.roles.getList);
}

export function* watchFetchRolesSaga() {
  yield takeLatest(rolesActions.FETCH_ROLES, doFetchRolesSaga);
}

export function* doCreateRoleSaga({ payload }) {
  const { data } = yield call(sagas.doBaseAsyncSaga, rolesActions.createRole, api.roles.create, payload.entity);

  if (data) {
    yield put(uiActions.showSuccessNotification('Role created successfully'));
  }
}

export function* watchCreateRoleSaga() {
  yield takeLatest(rolesActions.CREATE_ROLE, doCreateRoleSaga);
}

export function* doUpdateRoleSaga({ payload }) {
  const { data } = yield call(sagas.doBaseAsyncSaga, rolesActions.updateRole, api.roles.update, payload.entity);

  if (data) {
    yield put(uiActions.showSuccessNotification('Role updated successfully'));
  }
}

export function* watchUpdateRoleSaga() {
  yield takeLatest(rolesActions.UPDATE_ROLE, doUpdateRoleSaga);
}

export function* doDeleteRoleSaga({ payload }) {
  const { data } = yield call(sagas.doBaseAsyncSaga, rolesActions.deleteRole, api.roles.delete, payload.id);

  if (data) {
    yield put(uiActions.showSuccessNotification('Role deleted successfully'));
  }
}

export function* watchDeleteRoleSaga() {
  yield takeLatest(rolesActions.DELETE_ROLE, doDeleteRoleSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchRolesSaga(), watchCreateRoleSaga(), watchUpdateRoleSaga(), watchDeleteRoleSaga()]);
}
