import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ApplicationRoute, getPartnerUrl, getTemplatesUrl, getTemplateUrl } from '../../../utils/appRoutes';
import BreadcrumbSection from '../BreadcrumbSection';
import { useTemplateList } from '../../../store/template';
import BreadcrumbBackButton from '../BreadcrumbBackButton';
import PartnersBreadcrumbs from './PartnersBreadcrumbs';

const TemplatesBreadcrumbs: FC = () => {
  const {
    path,
    params: { partnerId, templateId, source }
  } = useRouteMatch<{ partnerId: string; templateId?: string; source?: string }>();
  const { templates } = useTemplateList(partnerId);

  let backLink = path === ApplicationRoute.Templates ? getPartnerUrl(partnerId) : getTemplatesUrl(partnerId);

  if (path === ApplicationRoute.TemplateSource && templateId) {
    backLink = getTemplateUrl(partnerId, templateId);
  }

  const list = useMemo(
    () =>
      templates.map(template => ({
        label: template.name,
        id: template._id,
        path: getTemplateUrl(template.partnerId, template._id)
      })),
    [templates]
  );

  return (
    <>
      <BreadcrumbBackButton to={backLink} />
      <PartnersBreadcrumbs generatePathUrl={getTemplatesUrl} hideBackButton />
      <BreadcrumbSection title="PDF Templates" list={list} activeId={templateId} />
      {source ? <BreadcrumbSection title={source} /> : null}
    </>
  );
};

export default TemplatesBreadcrumbs;
