import { actions } from '../@helpers';

const MODULE_NAME = 'IFRAME_SCRIPTS';
const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const setLatestIframeScript = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'SET_LATEST'),
  { base: type => id => ({ type, payload: { id } }) }
);

const iframeScriptActions = {
  FETCH_IFRAME_SCRIPTS: moduleActions.fetchList.ACTION_TYPE,
  CREATE_IFRAME_SCRIPT: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_IFRAME_SCRIPT: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_IFRAME_SCRIPT: moduleActions.deleteEntity.ACTION_TYPE,
  SET_LATEST_IFRAME_SCRIPT: setLatestIframeScript.ACTION_TYPE,

  fetchIframeScripts: moduleActions.fetchList,
  createIframeScript: moduleActions.createEntity,
  updateIframeScript: moduleActions.updateEntity,
  deleteIframeScript: moduleActions.deleteEntity,
  setLatestIframeScript,

  ...moduleActions
};

export default iframeScriptActions;
