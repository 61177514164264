import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../Atoms';
import FileUpload from '../../../../FileUpload';
import { useModal } from '../../../../../utils/hooks';

const ImageModalUpload = ({ api, onUploadSuccess, onUploadFailure }) => {
  const { close, open, isOpen } = useModal();

  return (
    <>
      <Button color="primary" onClick={open} className="mt-3">
        <i className="fa fa-plus fa-fw" />
        Upload
      </Button>
      {isOpen ? (
        <FileUpload
          onSuccess={onUploadSuccess}
          onFailure={onUploadFailure}
          caption="Upload image"
          onClose={close}
          api={api}
        />
      ) : null}
    </>
  );
};

ImageModalUpload.propTypes = {
  api: PropTypes.func.isRequired,
  onUploadFailure: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired
};

export default ImageModalUpload;
