import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as settingsSelectors from './settingsSelectors';
import settingsActions from './settingsActions';

export const useRawGlobalSettings = () => useSelector(settingsSelectors.selectRawGlobalSettings);

export const useFetchGlobalSettings = () => {
  const dispatch = useDispatch();
  const raw = useRawGlobalSettings();

  useEffect(() => {
    if (!raw) {
      dispatch(settingsActions.fetchGlobalSettings());
    }
  }, [dispatch, raw]);

  return { raw };
};
