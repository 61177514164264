import { useCallback, useState } from 'react';
import b from 'b_';
import {
  ChartContainer,
  ChartRow,
  Charts,
  Resizable,
  ScatterChart,
  YAxis,
  HighlightType
} from 'react-timeseries-charts';
import { TimeRange, TimeSeries } from 'pondjs';
import { Button } from '../../../../Atoms';
import { msToTime } from '../../../../../utils';

const statisticsChart = b.with('statistics-chart');

const StatisticsChart = ({ series }: { series: TimeSeries }) => {
  const [highlight, setHighlight] = useState<HighlightType | null>(null);
  const [timeRange, setTimeRange] = useState(series.timerange());

  const handleTimeRangeChange = useCallback((range: TimeRange) => {
    setTimeRange(range);
  }, []);

  const resetZoom = useCallback(() => {
    setTimeRange(series.timerange());
  }, [series]);

  const handleMouseNear = useCallback((newHighlight: HighlightType) => {
    setHighlight(newHighlight);
  }, []);

  let tooltip;
  let infoValues: { label: string; value: string }[] = [];

  if (highlight) {
    const value = msToTime(highlight.event.get(highlight.column) * 1000 * 60);

    tooltip = `Date: ${highlight.event.timestamp().toLocaleTimeString()}, Time: ${value}`;
    infoValues = [{ label: 'Time', value }];
  }

  return (
    <div className={statisticsChart()}>
      <div className="d-flex flex-row justify-content-between mb-2">
        {highlight ? <span>{tooltip}</span> : <span />}
        <Button color="primary" onClick={resetZoom} size="sm" outline>
          Reset zoom
        </Button>
      </div>
      <Resizable>
        <ChartContainer timeRange={timeRange} enableDragZoom onTimeRangeChanged={handleTimeRangeChange}>
          <ChartRow height="150">
            <YAxis id="time" label="Time (minutes)" format=".1f" hideAxisLine min={0} max={series.max('value')} />
            <Charts>
              <ScatterChart
                axis="time"
                series={series}
                columns={['value']}
                info={infoValues}
                infoHeight={28}
                infoWidth={110}
                infoStyle={{
                  fill: 'black',
                  color: '#DDD'
                }}
                onMouseNear={handleMouseNear}
                highlight={highlight}
                radius={3}
              />
            </Charts>
          </ChartRow>
        </ChartContainer>
      </Resizable>
    </div>
  );
};

export default StatisticsChart;
