import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field as RFField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { factoryMapsSelectors } from '../../../../modules/factoryMaps';
import { FACTORY_MAP_DEFINITIONS, FACTORY_MAP_TYPE } from '../../../../utils/propTypes';
import Field from '../../../Form/Field';

const parse = (values = []) => values.map(value => ({ value }));
const format = (values = []) => values.map(({ value }) => value);

const FactoryMapFormValues = ({ definition, seedId, index, id }) => {
  const controls = useSelector(state => factoryMapsSelectors.selectFactoryControlsList(state, seedId));
  const controlList = useMemo(() => Object.values(controls).map(({ value, label }) => ({ label, value })), [controls]);

  return (
    <RFField
      name="values"
      render={() => {
        if (definition[index].type === FACTORY_MAP_TYPE.MAP) {
          return (
            <Field.ArrayObject name={id} label="Values" description="First value with matching condition will be used">
              <Field.Text name="value" label="Value" description="Value to be displayed when the conditions match" />
              <RFField
                name="conditions"
                render={c => {
                  return (
                    <Field.ArrayObject
                      name={c.id}
                      label="Conditions"
                      description="All conditions have to be true"
                      lockMoving
                    >
                      <Field.Select options={controlList} name="key" label="Control name" creatable />
                      <RFField
                        name="values"
                        render={field => {
                          const controlName = definition[index].values[c.index].conditions[field.index].key;
                          const options = controls[controlName]?.options || [];

                          return (
                            <Field.MultiSelect
                              options={options}
                              name={field.id}
                              label="Selected option (OR)"
                              description="Condition is true if any of the options is selected"
                              creatable
                            />
                          );
                        }}
                      />
                    </Field.ArrayObject>
                  );
                }}
              />
            </Field.ArrayObject>
          );
        }

        if (definition[index].type === FACTORY_MAP_TYPE.KEY) {
          return (
            <Field.Select
              options={controlList}
              name={`${id}[0].value`}
              label="Value"
              description="Name of the control to get the value from"
              creatable
            />
          );
        }

        if (definition[index].type === FACTORY_MAP_TYPE.KEYS) {
          return (
            <Field.MultiSelect
              options={controlList}
              name={`${id}`}
              label="Value"
              description="Names of controls to get the value from. First successful field will be used."
              creatable
              parse={parse}
              format={format}
            />
          );
        }

        return <Field.Text name={`${id}[0].value`} label="Value" description="Fixed value to display in the mapping" />;
      }}
    />
  );
};

FactoryMapFormValues.propTypes = {
  definition: FACTORY_MAP_DEFINITIONS.isRequired,
  seedId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired
};

export default FactoryMapFormValues;
