import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { OptionStatus } from '../types';

export const OptionStatuses = () => {
  return (
    <>
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'name', 'untitled')}
        name="settings.optionStatuses"
        label="Option statuses"
        description="Option statuses defined here can be used in option definitions and remote controls to change how options are shown in the Configurator"
        twoColumns
        useShadowValue
      >
        <FormField.Text<OptionStatus>
          name="name"
          label="Name"
          description="Name which the status is referred by elsewhere. Must be unique"
          column="left"
        />
        <FormField.Text<OptionStatus>
          name="label"
          label="Label"
          description="Label which is displayed in Option and Remote Controls panel"
          column="left"
        />
        <FormField.Text<OptionStatus>
          name="description"
          label="Description"
          description="Additional hints"
          column="left"
        />
        <FormField.Toggle<OptionStatus>
          name="forceReselectState"
          label="Force reselect"
          description="Does this option force reselection of control if it's disabled"
          column="right"
        />
        <FormField.Toggle<OptionStatus>
          name="disabledState"
          label="Disable"
          description="Disable option and remove it from ui"
          column="right"
        />
        <FormField.Toggle<OptionStatus>
          name="lockedState"
          label="Lock"
          description="Locked options are visible, muted and not selectable"
          column="right"
        />
        <FormField.Toggle<OptionStatus>
          name="inactiveState"
          label="Inactivate"
          description="Inactive options are visible, muted but selectable"
          column="right"
        />
        <FormField.Toggle<OptionStatus>
          name="priceHiddenState"
          label="Hide price"
          description="Hide price of this option in Configurator"
          column="right"
        />
        <FormField.Toggle<OptionStatus>
          name="areaHiddenState"
          label="Hide area"
          description="Hide area of this option in Configurator"
          column="right"
        />
        <FormField.Toggle<OptionStatus>
          name="optionHintHiddenState"
          label="Hide option hint"
          description="Hide hint of this option in Configurator"
          column="right"
        />
      </FormField.ArrayWrapper>
    </>
  );
};
