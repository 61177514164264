import { ComponentType } from 'react';
import { ConnectedComponent } from 'react-redux';
import { ApplicationRoute } from '../utils/appRoutes';
import { RIGHTS } from '../utils/rights';
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import RegistrationPage from './RegistrationPage';
import ResetPasswordPage from './ResetPasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import PartnersPage from './PartnersPage';
import PartnerPage from './PartnerPage';
import PartnerCreationPage from './PartnerCreationPage';
import RolesPage from './RolesPage';
import GlobalSettingsPage from './GlobalSettingsPage';
import GlobalThemePage from './GlobalThemePage';
import UsersPage from './UsersPage';
import PlotsPage from './PlotsPage';
import RequestsPage from './RequestsPage';
import VisitorsPage from './VisitorsPage';
import VisitorPage from './VisitorPage';
import TemplateList from './TemplateList';
import TemplatePage, { TemplateSourcePage } from './TemplatePage';
import SubscriptionList from './SubscriptionList';
import ProjectList from './ProjectList';
import ProjectPage, { ProjectInfoPage, ProjectSettingsPage } from './ProjectPage';
import SeedPage, { SeedInfoPage, SeedSettingsPage } from './SeedPage';
import AssetsPage from './AssetsPage';
import SelfServiceProjectPage from './SelfServiceProjectPage';
import FactoryMapsPage from './FactoryMapsPage';
import FactoryMapPage from './FactoryMapPage';
import AssetsByProjectPage from './AssetsByProjectPage';
import InvitationsPage from './InvitationsPage';
import InstancesPage from './InstancesPage';
import IframeScriptsPage from './IframeScriptsPage';
import IframeScriptPage from './IframeScriptPage';
import PartGraph from './PartsPage/PartGraph';
import PartList from './PartsPage/PartList';
import SeedMaterialsPage from './SeedMaterialsPage';
import PricesPage from './PricesPage';
import TabsPage from './TabsPage';
import ViewsPage from './ViewsPage';
import LightsPage from './LightsPage';
import RemoteControlsPage from './RemoteControlsPage';
import RemoteSwitchesPage from './RemoteSwitchesPage';
import { IconType } from './Atoms';
import NewTestForm from './TestForm';

interface PublicAppRoute {
  path: ApplicationRoute;
  component: ComponentType<any> | ConnectedComponent<any, any>;
}

export interface ProtectedAppRoute extends PublicAppRoute {
  name?: string;
  icon?: IconType;
  description?: string;
  nav?: boolean;
  rights: string[];
}

export interface ProtectedAppRouteGroup {
  name?: string;
  color?: string;
  links: ProtectedAppRoute[];
}

export const PROTECTED_ROUTES: ProtectedAppRouteGroup[] = [
  {
    links: [
      {
        name: 'Dashboard',
        icon: 'mdiDotsGrid',
        description: 'Start here',
        rights: [],
        path: ApplicationRoute.Main,
        component: Dashboard,
        nav: true
      }
    ]
  },
  {
    name: 'Setup',
    color: '#2D9CDB',
    links: [
      {
        name: 'Project list',
        icon: 'mdiFolderOutline',
        description: 'List of all projects',
        rights: [RIGHTS.PROJECTS__READ],
        path: ApplicationRoute.ProjectList,
        component: ProjectList,
        nav: true
      },
      {
        name: 'Assets',
        icon: 'mdiImageMultipleOutline',
        description: 'Images, Textures, Models',
        rights: [RIGHTS.TEXTURES__READ, RIGHTS.IMAGES__READ],
        path: ApplicationRoute.Assets,
        component: AssetsPage,
        nav: true
      }
    ]
  },
  {
    name: 'User data',
    color: '#6FCF97',
    links: [
      {
        name: 'Designs',
        icon: 'mdiCubeOutline',
        rights: [RIGHTS.INSTANCES__READ],
        path: ApplicationRoute.Instances,
        component: InstancesPage,
        nav: true
      },
      {
        name: 'Visitors',
        icon: 'mdiAccount',
        rights: [RIGHTS.VISITORS__READ],
        path: ApplicationRoute.Visitors,
        component: VisitorsPage,
        nav: true
      },
      {
        name: 'Requests',
        icon: 'mdiMessageTextOutline',
        rights: [RIGHTS.REQUESTS__READ],
        path: ApplicationRoute.Requests,
        component: RequestsPage,
        nav: true
      }
    ]
  },
  {
    name: 'Integrations',
    color: '#F2994A',
    links: [
      {
        name: 'Plot management',
        icon: 'mdiTableLarge',
        rights: [RIGHTS.PLOTS__READ],
        path: ApplicationRoute.Plots,
        component: PlotsPage,
        nav: true
      },
      {
        name: 'Export to production',
        icon: 'mdiFactory',
        description: 'Factory maps',
        rights: [RIGHTS.FACTORY_MAPS__READ],
        path: ApplicationRoute.FactoryMaps,
        component: FactoryMapsPage,
        nav: true
      },
      {
        name: 'Web hooks',
        icon: 'mdiWebhook',
        description: 'Subscriptions',
        rights: [RIGHTS.SUBSCRIPTIONS__READ],
        path: ApplicationRoute.Subscriptions,
        component: SubscriptionList,
        nav: true
      },

      { path: ApplicationRoute.FactoryMap, component: FactoryMapPage, rights: [RIGHTS.FACTORY_MAPS__UPDATE] }
    ]
  },
  {
    name: 'Access',
    color: '#EB5757',
    links: [
      {
        name: 'Partners',
        icon: 'mdiOfficeBuildingOutline',
        rights: [RIGHTS.PARTNERS__READ],
        component: PartnersPage,
        path: ApplicationRoute.Partners,
        nav: true
      },
      {
        name: 'Users',
        icon: 'mdiAccountMultipleOutline',
        rights: [RIGHTS.USERS__READ],
        path: ApplicationRoute.Users,
        component: UsersPage,
        nav: true
      },
      {
        name: 'Roles',
        icon: 'mdiAccountDetailsOutline',
        rights: [RIGHTS.ROLES__READ],
        path: ApplicationRoute.Roles,
        component: RolesPage,
        nav: true
      },
      {
        name: 'Invitations',
        icon: 'mdiAccountPlusOutline',
        rights: [RIGHTS.INVITATIONS__READ],
        path: ApplicationRoute.Invitations,
        component: InvitationsPage,
        nav: true
      },

      { path: ApplicationRoute.PartnerCreation, component: PartnerCreationPage, rights: [RIGHTS.PARTNERS__CREATE] },
      { path: ApplicationRoute.Partner, component: PartnerPage, rights: [RIGHTS.PARTNERS__UPDATE] }
    ]
  },
  {
    name: 'Global',
    color: '#828282',
    links: [
      {
        name: 'Settings',
        icon: 'mdiCogOutline',
        description: 'Applied to all projects',
        rights: [RIGHTS.GLOBAL_SETTINGS__READ],
        path: ApplicationRoute.GlobalSettings,
        component: GlobalSettingsPage,
        nav: true
      },
      {
        name: 'Theme',
        icon: 'mdiPalette',
        description: 'CSS',
        rights: [RIGHTS.GLOBAL_THEME__READ],
        path: ApplicationRoute.GlobalTheme,
        component: GlobalThemePage,
        nav: true
      },
      {
        name: 'Embedding',
        icon: 'mdiApplicationBracketsOutline',
        description: '<iframe> scripts',
        rights: [RIGHTS.IFRAME_SCRIPTS__READ],
        path: ApplicationRoute.IframeScripts,
        component: IframeScriptsPage,
        nav: true
      },

      { path: ApplicationRoute.IframeScript, component: IframeScriptPage, rights: [RIGHTS.IFRAME_SCRIPT__UPDATE] }
    ]
  },
  {
    links: [
      {
        path: ApplicationRoute.ProjectSelfService,
        component: SelfServiceProjectPage,
        rights: [RIGHTS.PROJECTS__SELF_SERVICE]
      },
      { path: ApplicationRoute.Visitor, component: VisitorPage, rights: [RIGHTS.VISITORS__READ] },
      { path: ApplicationRoute.Templates, component: TemplateList, rights: [RIGHTS.TEMPLATES__READ] },
      { path: ApplicationRoute.TemplateSource, component: TemplateSourcePage, rights: [RIGHTS.TEMPLATES__UPDATE] },
      { path: ApplicationRoute.Template, component: TemplatePage, rights: [RIGHTS.TEMPLATES__UPDATE] },
      { path: ApplicationRoute.Project, component: ProjectPage, rights: [RIGHTS.PROJECTS__READ] },
      { path: ApplicationRoute.ProjectInfo, component: ProjectInfoPage, rights: [RIGHTS.PROJECTS__UPDATE] },
      { path: ApplicationRoute.ProjectSettings, component: ProjectSettingsPage, rights: [RIGHTS.PROJECTS__UPDATE] },
      { path: ApplicationRoute.ProjectAssets, component: AssetsByProjectPage, rights: [RIGHTS.PROJECTS__EDIT_ASSETS] },
      { path: ApplicationRoute.Seed, component: SeedPage, rights: [RIGHTS.SEEDS__READ] },
      { path: ApplicationRoute.SeedInfo, component: SeedInfoPage, rights: [RIGHTS.SEEDS__UPDATE] },
      { path: ApplicationRoute.SeedSettings, component: SeedSettingsPage, rights: [RIGHTS.SEEDS__UPDATE] },
      { path: ApplicationRoute.SeedGraph, component: PartGraph, rights: [RIGHTS.PARTS__READ] },
      { path: ApplicationRoute.SeedParts, component: PartList, rights: [RIGHTS.PARTS__READ] },
      { path: ApplicationRoute.SeedMaterials, component: SeedMaterialsPage, rights: [RIGHTS.SEEDS__MATERIALS_UPDATE] },
      { path: ApplicationRoute.SeedPrices, component: PricesPage, rights: [RIGHTS.SEEDS__PRICING_UPDATE] },
      { path: ApplicationRoute.SeedTabs, component: TabsPage, rights: [RIGHTS.PARTS__UPDATE] },
      { path: ApplicationRoute.SeedViews, component: ViewsPage, rights: [RIGHTS.PARTS__UPDATE] },
      { path: ApplicationRoute.SeedLights, component: LightsPage, rights: [RIGHTS.PARTS__UPDATE] },
      {
        path: ApplicationRoute.SeedRemoteControls,
        component: RemoteControlsPage,
        rights: [RIGHTS.REMOTE_CONTROLS__UPDATE]
      },
      {
        path: ApplicationRoute.SeedRemoteSwitches,
        component: RemoteSwitchesPage,
        rights: [RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]
      },
      { path: ApplicationRoute.Test, component: NewTestForm, rights: [] }
    ]
  }
];

export const PUBLIC_ROUTES: PublicAppRoute[] = [
  { path: ApplicationRoute.SignIn, component: LoginPage },
  { path: ApplicationRoute.SignUp, component: RegistrationPage },
  { path: ApplicationRoute.ForgotPassword, component: ForgotPasswordPage },
  { path: ApplicationRoute.ResetPassword, component: ResetPasswordPage }
];

export const PROTECTED_ROUTE_LIST = PROTECTED_ROUTES.reduce((acc: ProtectedAppRoute[], group) => {
  acc.push(...group.links);

  return acc;
}, []);
