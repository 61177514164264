import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Form from '../Form';
import MaterialForm from '../PartsPage/PartList/PartModals/PartForm/MaterialForm';
import { useSeed, useUpdateSeedMaterials } from '../../store/seed';
import { Loader } from '../Atoms';

const SeedMaterialsPage = () => {
  const { seedId } = useParams();
  const { updateSeedMaterials } = useUpdateSeedMaterials();

  const { seed, isInProgress } = useSeed(seedId);
  const onSubmit = useCallback(
    values => {
      updateSeedMaterials({ _id: seedId, materials: values.materials });
    },
    [updateSeedMaterials, seedId]
  );

  return (
    <Loader loading={isInProgress}>
      <div className="d-flex w-100 flex-column p-3">
        <h5 className="my-3">Materials</h5>
        <Form initialValues={{ materials: seed?.materials || [] }} onSubmit={onSubmit}>
          <MaterialForm fieldName="materials" projectId={seed?.projectId} noLabel />
          <br />
        </Form>
      </div>
    </Loader>
  );
};

export default SeedMaterialsPage;
