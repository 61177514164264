import { FC, useCallback, useState } from 'react';
import { Form, FormField, FormSection } from './Atoms';

interface ArrayTestType {
  q: string;
  m?: number;
}

interface ArrayTestType2 {
  t: string;
  k?: string;
}

interface TestType {
  name: string;
  count: number;
  maxInstances: number;
  option: string;
  status: string[];
  controls: ArrayTestType[];
  controlsNew: ArrayTestType2[];
  controlsStatic: ArrayTestType[];
}

const defaultValues: Partial<TestType> = {
  status: ['lol'],
  controls: [
    {
      q: 'QQQ',
      m: 10
    }
  ],
  controlsStatic: [
    {
      q: 'QQQ',
      m: 10
    },
    {
      q: 'WWW',
      m: 20
    }
  ],
  controlsNew: [
    {
      t: 'TTT1',
      k: 'KKK1'
    },
    {
      t: 'TTT2',
      k: 'KKK2'
    }
  ]
};

const optionsA = [
  { a: 'Foo', b: 'foo-lol' },
  { a: 'Bar', b: 'bar-lol' }
];

const optionsB = [
  { aa: 'Foo', bb: 'foo-lol' },
  { aa: 'Bar', bb: 'bar-lol' },
  { aa: 'Baz', bb: 'baz-lol' }
];

const TestForm: FC = () => {
  const [formState, setFormState] = useState(defaultValues);
  const onSubmit = useCallback(data => {
    // eslint-disable-next-line no-console
    console.log(data);
  }, []);
  const onChangeFormValues = useCallback(data => {
    setFormState(data);
  }, []);

  return (
    <div className="m-4">
      <h1>Test form</h1>
      <Form<TestType> defaultValues={defaultValues} onSubmit={onSubmit} onChangeFormValues={onChangeFormValues}>
        <FormField.Text<TestType> name="name" label="Name" required />
        <FormSection title="Demo section">
          <FormField.Number<TestType> name="count" label="Count" />
          <FormField.Number<TestType> name="maxInstances" max={10} />
        </FormSection>
        <FormField.Select<TestType, { a: string; b: string }>
          name="option"
          label="Option"
          options={optionsA}
          getOptionLabel={option => option?.a || ''}
          getOptionValue={option => option?.b || ''}
        />
        <FormField.MultiSelect<TestType, { aa: string; bb: string }>
          name="status"
          label="Status"
          description="Description"
          options={optionsB}
          getOptionLabel={option => option?.aa || ''}
          getOptionValue={option => option?.bb || ''}
          getNewOptionData={bb => ({ aa: bb, bb })}
          creatable
        />
        <FormField.ArrayWrapper<TestType, 'controls'>
          name="controls"
          headerFormat={value => (value as ArrayTestType).q}
          label="Array field"
          description="Here add various translations for different languages. Base language is English."
          required
          isSortable
        >
          <FormField.Text<ArrayTestType> name="q" label="Q label" required />
          <FormField.Number<ArrayTestType> name="m" label="M label" max={12} />
        </FormField.ArrayWrapper>
        <FormField.ArrayWrapper<TestType, 'controlsNew'>
          name="controlsNew"
          headerFormat={value => (value as ArrayTestType2).t}
          label="Not sortable array"
          description="Lorem ipsum"
        >
          <FormField.Text<ArrayTestType2> name="t" label="T label" required />
          <FormField.Text<ArrayTestType2> name="k" label="K label" />
        </FormField.ArrayWrapper>
        <FormField.ArrayWrapper<TestType, 'controlsStatic'>
          name="controlsStatic"
          headerFormat={value => (value as ArrayTestType).q}
          label="controlsStatic"
          isStatic
        >
          <FormField.Text<ArrayTestType> name="q" label="Q label" required />
          <FormField.Number<ArrayTestType> name="m" label="M label" max={12} />
        </FormField.ArrayWrapper>
      </Form>
      <pre>{JSON.stringify(formState, null, 2)}</pre>
    </div>
  );
};

export default TestForm;
