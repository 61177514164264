import Editor from '@monaco-editor/react';

interface CodeEditorProps {
  value?: string;
  defaultvalue?: string;
  mode: 'json' | 'html' | 'css' | 'javascript';
  onChange?(value?: string): void;
  onValidate?(issues: any[]): void;
  readonly?: boolean;
  className?: string;
}

const CodeEditor = ({ value, mode, onChange, onValidate, readonly, className, defaultvalue }: CodeEditorProps) => {
  return (
    <div style={{ width: '100%' }} className={className}>
      <Editor
        options={{ readOnly: readonly, minimap: { enabled: false } }}
        onChange={onChange}
        onValidate={onValidate}
        value={value}
        height="50vh"
        defaultValue={defaultvalue}
        defaultLanguage={mode}
      />
    </div>
  );
};

export default CodeEditor;
