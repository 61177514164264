import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import pluralize from 'pluralize';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from '../Atoms';
import { TYPE_SUGGESTION_LIST } from '../../utils/propTypes';
import FilterItem from './FilterItem';

import './MultiSelectFilter.scss';

const multiSelectFilter = b.with('multi-select-filter');

class MultiSelectFilter extends React.PureComponent {
  state = {
    open: false,
    value: this.props.filter.value,
    changed: false
  };

  get isActiveFilter() {
    return Boolean(this.props.filter.value.length);
  }

  get text() {
    const { name, filter } = this.props;

    return this.isActiveFilter ? `Selected ${pluralize(name, filter.value.length, true)}` : 'Set filter';
  }

  handleChange = id => {
    this.setState(prevState => ({
      value: prevState.value.includes(id) ? prevState.value.filter(value => value !== id) : [...prevState.value, id],
      changed: true
    }));
  };

  resetAll = e => {
    e.stopPropagation();

    this.setState({ value: [], open: false, changed: false }, this.onChange);
  };

  toggle = () => {
    this.setState(
      prevState => ({ open: !prevState.open }),
      () => {
        if (!this.state.open) {
          this.cancel();
        }
      }
    );
  };

  cancel = () => {
    this.setState({ value: this.props.filter.value, open: false, changed: false });
  };

  apply = () => {
    this.setState({ open: false, changed: false }, this.onChange);
  };

  onChange = () => {
    this.props.onChange(this.state.value);
  };

  render() {
    const { options } = this.props;
    const { open, value, changed } = this.state;

    return (
      <Dropdown isOpen={open} toggle={this.toggle} className={multiSelectFilter()}>
        <DropdownToggle tag="span" className={multiSelectFilter('toggle')}>
          <span className={multiSelectFilter('text', { active: this.isActiveFilter })}>
            <i className="fa fa-fw fa-filter" /> {this.text}
          </span>
          <Button
            close
            aria-label="Cancel"
            className={multiSelectFilter('clear', { hidden: !this.isActiveFilter })}
            onClick={this.resetAll}
            title="Clear filter"
          />
        </DropdownToggle>
        <DropdownMenu className={multiSelectFilter('menu')}>
          <div className={multiSelectFilter('options')}>
            {options.map(option => (
              <FilterItem
                key={option.value}
                onChange={this.handleChange}
                active={value.includes(option.value)}
                option={option}
              />
            ))}
          </div>
          <div className={`${multiSelectFilter('footer')} p-2 border-top bg-light`}>
            <Button onClick={this.resetAll} outline size="sm" color="danger" disabled={!this.isActiveFilter}>
              Reset all
            </Button>
            <div>
              <Button onClick={this.apply} outline size="sm" color="primary" disabled={!changed}>
                Apply
              </Button>
              <Button onClick={this.cancel} outline size="sm">
                Cancel
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

MultiSelectFilter.defaultProps = {
  options: [],
  filter: {
    value: []
  }
};

MultiSelectFilter.propTypes = {
  name: PropTypes.string.isRequired,
  options: TYPE_SUGGESTION_LIST,
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string)
  }),
  onChange: PropTypes.func.isRequired
};

export default MultiSelectFilter;
