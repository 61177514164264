import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { TYPE_SUGGESTION_LIST } from '../../utils/propTypes';

export const ALL_FILTER = 'All';

const ALL_FILTER_OPTION = {
  label: ALL_FILTER,
  value: ALL_FILTER
};

const SelectFilter = ({ filter, onChange, options }) => {
  const value = filter.value || ALL_FILTER;
  const [selectedInstance, setSelectedInstance] = useState({});

  useEffect(() => {
    const filterValue = options.find(option => option.value === value);

    if (filterValue) {
      setSelectedInstance(filterValue);
    } else {
      setSelectedInstance(ALL_FILTER_OPTION);
    }
  }, [value, options]);

  return (
    <Select
      isSearchable={false}
      onChange={option => {
        setSelectedInstance(option);
        onChange(option.value);
      }}
      value={selectedInstance}
      title="Set filter"
      options={[...options, ALL_FILTER_OPTION]}
    />
  );
};

SelectFilter.defaultProps = {
  options: [],
  filter: {
    value: ''
  }
};

SelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: TYPE_SUGGESTION_LIST,
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string
  })
};

export default SelectFilter;
