import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { Image, PanImage, IntroSettings } from '../types';

export interface ContentProps {
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
}

export const Content = ({ projectImages, uploadImage, updateImagesAfterUpload }: ContentProps) => {
  return (
    <>
      <FormField.Toggle<ProjectType> useShadowValue name="settings.interface.hideGallery" label="Hide gallery" />
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'language', 'untitled')}
        name="settings.intro"
        label="Intro settings"
        twoColumns
        useShadowValue
      >
        <FormField.ControlledSelect<IntroSettings, ProjectType>
          name="language"
          label="language"
          description="Select language from previously defined interface languages list"
          sourceField="settings.translations"
          getOptionValue={(option?: any) => `${option?.name}`}
          getOptionLabel={(option?: any) => `${option?.name}`}
          column="left"
        />
        <FormField.Text<IntroSettings> name="title" label="Intro title" column="left" />
        <FormField.Markdown<IntroSettings> name="content" label="Intro content" column="right" />
        <FormField.ArrayWrapper<IntroSettings>
          headerFormat={value => get(value, 'fileName', 'untitled')}
          name="images"
          label="Shown in gallery"
          column="right"
        >
          <FormField.Image<Image>
            name="fileName"
            label="images"
            list={projectImages}
            uploadImage={uploadImage}
            onUploadSuccess={updateImagesAfterUpload}
          />
          <FormField.Toggle<PanImage> name="isPanorama" label="Panorama" />
        </FormField.ArrayWrapper>
        <FormField.Text<IntroSettings>
          name="introVideo"
          label="Video"
          description="Url of intro video to show instead of the gallery"
          column="right"
        />
      </FormField.ArrayWrapper>
    </>
  );
};
