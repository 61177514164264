import React from 'react';
import Input from '../Input';

const componentToHex = c => {
  const hex = c.toString(16);

  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : null;
};

const format = (value = '') => {
  // value should be in format rgb(r,g,b), result #rrggbb
  const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
  const matches = value.match(rgbRegex);

  if (matches && matches.length === 4) {
    return rgbToHex(Number(matches[1]), Number(matches[2]), Number(matches[3]));
  }

  return '#000000';
};

const parse = value => {
  const rgbValue = hexToRgb(value);

  return rgbValue || 'rgb(0, 0, 0)';
};

const Rgb = props => {
  return <Input {...props} type="color" parse={parse} format={format} />;
};

export default Rgb;
