import { all, call, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import mappedInstancesActions from './mappedInstancesActions';

export function* doFetchMappedInstanceSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, mappedInstancesActions.fetchMappedInstance, api.mappedInstances.getOne, payload.id);
}

export function* watchFetchMappedInstanceSaga() {
  yield takeLatest(mappedInstancesActions.FETCH_MAPPED_INSTANCE, doFetchMappedInstanceSaga);
}

export function* doFetchMappedInstancePreviewSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    mappedInstancesActions.fetchMappedInstancePreview,
    api.mappedInstances.getPreview,
    payload.instanceId
  );
}

export function* watchFetchMappedInstancePreviewSaga() {
  yield takeLatest(mappedInstancesActions.FETCH_MAPPED_INSTANCE_PREVIEW, doFetchMappedInstancePreviewSaga);
}

export function* doCreateMappedInstanceSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    mappedInstancesActions.createMappedInstance,
    api.mappedInstances.create,
    payload.instanceId,
    payload.factoryId
  );
}

export function* watchCreateMappedInstanceSaga() {
  yield takeLatest(mappedInstancesActions.CREATE_MAPPED_INSTANCE, doCreateMappedInstanceSaga);
}

export function* doUpdateMappedInstanceSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    mappedInstancesActions.updateMappedInstance,
    api.mappedInstances.update,
    payload.mappedInstance
  );
}

export function* watchUpdateMappedInstanceSaga() {
  yield takeLatest(mappedInstancesActions.UPDATE_MAPPED_INSTANCE, doUpdateMappedInstanceSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchMappedInstanceSaga(),
    watchFetchMappedInstancePreviewSaga(),
    watchCreateMappedInstanceSaga(),
    watchUpdateMappedInstanceSaga()
  ]);
}
