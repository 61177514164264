import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field as RFField, useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Button, Col, Row } from '../../Atoms';
import Form from '../../Form';
import Field from '../../Form/Field';
import { partsSelectors } from '../../../modules/parts';
import { usePartList } from '../../../utils/hooks';
import { seedsSelectors } from '../../../modules/seeds';
import { TAB_NAMES } from '../../../utils/defaultTabNames';

/* eslint-disable */
const ACTION_TYPES = {
  OPEN_TAB: 'open-tab',
  OPEN_DIALOG: 'open-dialog',
  OPEN_LINK: 'open-link',
  OPEN_FIRST_CONTROL_TAB: 'open-first-control-tab',
  OPEN_LAST_CONTROL_TAB: 'open-last-control-tab'
};

const DIALOG_TYPES = {
  AUTH: 'auth',
  LOGOUT: 'logout',
  PROFILE: 'profile',
  SAVE: 'save',
  SHARE: 'share',
  CONTACT: 'contact',
  PDF: 'pdf',
  OPEN_INSTANCE: 'open-instance',
  EXPORT_MODEL: 'export-model'
};

const REQUIRE_LOGIN_DIALOGS = {
  [DIALOG_TYPES.SAVE]: true,
  [DIALOG_TYPES.SHARE]: true,
  [DIALOG_TYPES.CONTACT]: true,
  [DIALOG_TYPES.PDF]: true
};

const ACTIONS = [
  { label: 'Open tab', value: ACTION_TYPES.OPEN_TAB },
  { label: 'Open dialog', value: ACTION_TYPES.OPEN_DIALOG },
  { label: 'Open link', value: ACTION_TYPES.OPEN_LINK },
  { label: 'Open first control tab', value: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB },
  { label: 'Open last control tab', value: ACTION_TYPES.OPEN_LAST_CONTROL_TAB }
];

const COLORS = [
  { label: 'Main Fill', value: 'main' },
  { label: 'Main Outline', value: 'main-outline' },
  { label: 'Accent Fill', value: 'accent' },
  { label: 'Accent Outline', value: 'accent-outline' },
  { label: 'Secondary Fill', value: 'secondary' },
  { label: 'Secondary Outline', value: 'secondary-hovered' },
  { label: 'Text Outline', value: 'text' },
  { label: 'Text Flat', value: 'text-borderless' }
];

const ICONS = [
  { label: 'Save', value: 'save' },
  { label: 'Web share', value: 'web-share' },
  { label: 'Cube', value: 'cube' },
  { label: 'Cogs', value: 'cogs' },
  { label: 'Chevron left', value: 'chevron-left' }
];

const DIALOGS = [
  { label: 'Auth', value: DIALOG_TYPES.AUTH },
  { label: 'Logout', value: DIALOG_TYPES.LOGOUT },
  { label: 'My Designs', value: DIALOG_TYPES.PROFILE },
  { label: 'Save', value: DIALOG_TYPES.SAVE },
  { label: 'Share', value: DIALOG_TYPES.SHARE },
  { label: 'Contact', value: DIALOG_TYPES.CONTACT },
  { label: 'PDF', value: DIALOG_TYPES.PDF },
  { label: 'Open instance', value: DIALOG_TYPES.OPEN_INSTANCE },
  { label: 'Export model', value: DIALOG_TYPES.EXPORT_MODEL }
];

const AUTHORIZATION_VISIBILITY_LIST = [
  { label: 'Hide for authorized users', value: 'authorized' },
  { label: 'Hide for unauthorized users', value: 'unauthorized' }
];

const SAVING_VISIBILITY_LIST = [
  { label: 'Hide for unsaved instances', value: 'unsaved' },
  { label: 'Hide for saved instances', value: 'saved' }
];

const REQUEST_VISIBILITY_LIST = [
  { label: 'Hide for instances with request', value: 'withRequest' },
  { label: 'Hide fot instances without request', value: 'withoutRequest' }
];

const CONFIGURATOR_MODE_VISIBILITY_LIST = [
  { label: 'Hide for project or seed mode', value: 'noInstance' },
  { label: 'Hide fot instance mode', value: 'instance' }
];

const getDefaultConfiguration = () => ({
  list: [
    {
      name: 'login',
      displayName: 'Log in',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.AUTH,
      hidingByAuth: 'authorized'
    },
    {
      name: 'logout',
      displayName: 'Log out',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.LOGOUT,
      hidingByAuth: 'unauthorized'
    },
    {
      name: 'my-designs',
      displayName: 'My Designs',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.PROFILE,
      hidingByAuth: 'unauthorized'
    },
    {
      name: 'save',
      displayName: 'Save',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.SAVE,
      hidingBySaving: 'saved'
    },
    {
      name: 'share',
      displayName: 'Share',
      description: 'Unique link to your design',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.SHARE
    },
    {
      name: 'contact-us',
      displayName: 'Contact us',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.CONTACT,
      hidingByRequest: 'withRequest'
    },
    {
      name: 'contact-us-again',
      displayName: 'Contact us again',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.CONTACT,
      hidingByRequest: 'withoutRequest'
    },
    {
      name: 'download-pdf',
      displayName: 'Download summary',
      description: 'File with complete information about your design',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.PDF,
      requireLogin: true
    },
    {
      name: 'design-your-home',
      displayName: 'Design Your Home',
      type: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB,
      hidingByConfiguratorMode: 'instance'
    },
    {
      name: 'review-design',
      displayName: 'Review the design',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review',
      hidingByConfiguratorMode: 'noInstance'
    },
    {
      name: 'edit',
      displayName: 'Edit',
      type: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB,
      hidingByConfiguratorMode: 'noInstance'
    },
    {
      name: 'back',
      displayName: 'Back',
      type: ACTION_TYPES.OPEN_LAST_CONTROL_TAB,
      hidingByConfiguratorMode: 'instance'
    },
    {
      name: 'review',
      displayName: 'Review',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review'
    },
    {
      name: 'about-this-house',
      displayName: 'About this house',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'index'
    },
    {
      name: 'summary',
      displayName: 'Summary',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'summary'
    },
    {
      name: 'open-instance',
      displayName: 'Open...',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.OPEN_INSTANCE
    },
    {
      name: 'finish',
      displayName: 'Finish',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review'
    }
  ],
  groups: {
    aboutTab: [
      { name: 'design-your-home', color: 'accent' },
      { name: 'review-design', color: 'accent' },
      { name: 'login', color: 'text-borderless' },
      { name: 'my-designs', color: 'text-borderless' }
    ],
    reviewTab: [
      {
        name: 'edit',
        color: 'main-outline'
      },
      {
        name: 'back',
        color: 'main-outline'
      },
      { name: 'save', color: 'main' },
      { name: 'summary', color: 'main' }
    ],
    summaryTab: [
      { name: 'contact-us', color: 'accent' },
      { name: 'contact-us-again', color: 'accent' },
      { name: 'download-pdf', color: 'accent-outline' }
    ],
    menu: [
      {
        section: 'actions',
        actions: [
          { name: 'my-designs' },
          { name: 'save' },
          { name: 'share' },
          { name: 'download-pdf' },
          { name: 'contact-us' },
          { name: 'contact-us-again' },
          { name: 'open-instance' }
        ]
      },
      { section: 'links', actions: [{ name: 'about-this-house' }] },
      { section: 'auth', actions: [{ name: 'login' }, { name: 'logout' }] }
    ],
    overviewPanel: [
      { name: 'contact-us', color: 'accent' },
      { name: 'contact-us-again', color: 'accent' },
      { name: 'summary', color: 'text-borderless' }
    ],
    summaryPanel: [
      { name: 'back', color: 'main-outline', icon: 'chevron-left' },
      { name: 'edit', color: 'main-outline', icon: 'cogs' },
      { name: 'review', color: 'main-outline', icon: 'cube' },
      { name: 'save', color: 'main', icon: 'save' },
      { name: 'share', color: 'main-outline', icon: 'web-share' }
    ],
    shareDialog: [{ name: 'contact-us', color: 'accent' }],
    summarySaveButton: 'save',
    finishButton: 'finish'
  }
});
/* eslint-enable */

const getLabelPostfix = flag => (flag ? <span className="text-danger">(Currently hidden)</span> : '');

const CtaSettings = ({ seedId, useShadowValue }) => {
  const { input: field } = useField('settings.actions');

  const tabs = useSelector(state => partsSelectors.selectTabNamesForSuggestions(state, seedId));
  const settings = useSelector(state => seedsSelectors.selectSeedSettingsMemoized(state, seedId));
  const tabsSuggestions = useMemo(() => {
    return [
      ...tabs,
      { label: 'About', value: TAB_NAMES.ABOUT },
      { label: 'Review', value: TAB_NAMES.REVIEW },
      { label: 'Summary', value: TAB_NAMES.SUMMARY }
    ];
  }, [tabs]);

  const handleClick = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to reset settings to default?`); // eslint-disable-line no-alert

    if (response) {
      field.onChange(getDefaultConfiguration());
    }
  }, [field]);

  usePartList(seedId);

  const localSettings = useMemo(() => {
    const { seedSettings, projectSettings } = settings;
    const tabsMap = {};

    [
      ['aboutTabHidden', TAB_NAMES.ABOUT],
      ['reviewTabHidden', TAB_NAMES.REVIEW],
      ['summaryTabHidden', TAB_NAMES.SUMMARY]
    ].forEach(([flag, tab]) => {
      tabsMap[tab] = [true, false].includes(seedSettings.interface?.[flag])
        ? seedSettings.interface?.[flag]
        : projectSettings.interface?.[flag];
    });

    const actions = {};

    (projectSettings.actions?.list || seedSettings.actions?.list || [])
      .filter(action => action.type === ACTION_TYPES.OPEN_TAB)
      .forEach(action => {
        actions[action.name] = tabsMap[action.tab];
      });

    return { tabs: tabsMap, actions };
  }, [settings]);

  const headingFormat = useCallback(
    value => (
      <span>
        {value.name} {getLabelPostfix(localSettings.actions[value.name])}
      </span>
    ),
    [localSettings.actions]
  );

  return (
    <Form.Group header="CTA Settings" icon="fa-mouse-pointer">
      <Row>
        <Col xs="12" md="6">
          <Button onClick={handleClick} size="sm" className="mb-2" color="light">
            Reset to default
          </Button>
          <Field.ArrayObject
            name="settings.actions.list"
            label="All available actions"
            description="Define actions that will be used in the configuration"
            uniqueField="name"
            useShadowValue={useShadowValue}
          >
            <Field.Text
              name="name"
              label="Action name"
              description="Used to identify the action. Must be unique"
              required
            />
            <Field.Text name="displayName" label="Display name" required />
            <Field.Text name="description" label="Description" description="Used in the menu" />
            <Field.Select
              name="type"
              label="Action type"
              description="Choose action type from the list"
              options={ACTIONS}
              required
            />
            <RFField
              name="type"
              render={({ input, parentName, disabled }) => {
                if (input.value === ACTION_TYPES.OPEN_DIALOG) {
                  return (
                    <>
                      <Field.Select
                        name={`${parentName}.dialog`}
                        label="Dialog"
                        options={DIALOGS}
                        required
                        disabled={disabled}
                      />
                      <RFField
                        name={`${parentName}.dialog`}
                        render={({ input: dialogInput }) =>
                          REQUIRE_LOGIN_DIALOGS[dialogInput.value] ? (
                            <Field.Toggle
                              name={`${parentName}.requireLogin`}
                              label="Require login"
                              disabled={disabled}
                            />
                          ) : null
                        }
                      />
                    </>
                  );
                }

                if (input.value === ACTION_TYPES.OPEN_LINK) {
                  return <Field.Text name={`${parentName}.link`} label="Link" required disabled={disabled} />;
                }

                if (input.value === ACTION_TYPES.OPEN_TAB) {
                  return (
                    <Field.Select
                      name={`${parentName}.tab`}
                      label="Tab"
                      options={tabsSuggestions}
                      required
                      disabled={disabled}
                    />
                  );
                }

                return null;
              }}
            />
            <Field.Select
              name="hidingByAuth"
              label="Visibility based on authorization"
              options={AUTHORIZATION_VISIBILITY_LIST}
              clearable
            />
            <Field.Select
              name="hidingBySaving"
              label="Visibility based on saving"
              options={SAVING_VISIBILITY_LIST}
              clearable
            />
            <Field.Select
              name="hidingByRequest"
              label="Visibility based on request"
              options={REQUEST_VISIBILITY_LIST}
              clearable
            />
            <Field.Select
              name="hidingByConfiguratorMode"
              label="Visibility based on configurator mode"
              options={CONFIGURATOR_MODE_VISIBILITY_LIST}
              clearable
            />
          </Field.ArrayObject>
        </Col>
        <Col xs="12" md="6">
          <Field.ArrayObject
            name="settings.actions.groups.aboutTab"
            label={<span>About tab actions {getLabelPostfix(localSettings.tabs[TAB_NAMES.ABOUT])}</span>}
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.SpySelect
              source="settings.actions.list"
              sourceField={{ value: 'name', label: 'displayName' }}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <Field.Select name="color" label="Color" options={COLORS} clearable />
          </Field.ArrayObject>
          <Field.ArrayObject
            name="settings.actions.groups.reviewTab"
            label={<span>Review tab actions {getLabelPostfix(localSettings.tabs[TAB_NAMES.REVIEW])}</span>}
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.SpySelect
              source="settings.actions.list"
              sourceField={{ value: 'name', label: 'displayName' }}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <Field.Select name="color" label="Color" options={COLORS} clearable />
          </Field.ArrayObject>
          <Field.ArrayObject
            name="settings.actions.groups.summaryTab"
            label={<span>Summary tab actions {getLabelPostfix(localSettings.tabs[TAB_NAMES.SUMMARY])}</span>}
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.SpySelect
              source="settings.actions.list"
              sourceField={{ value: 'name', label: 'displayName' }}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <Field.Select name="color" label="Color" options={COLORS} clearable />
          </Field.ArrayObject>
          <Field.ArrayObject name="settings.actions.groups.menu" label="Menu actions" useShadowValue={useShadowValue}>
            <Field.Text name="section" label="Section name" required />
            <Field.ArrayObject name="actions" label="Actions" headingFormat={headingFormat}>
              <Field.SpySelect
                source="settings.actions.list"
                sourceField={{ value: 'name', label: 'displayName' }}
                name="name"
                label="Action name"
                description="Select an action from the list"
                required
              />
            </Field.ArrayObject>
          </Field.ArrayObject>
          <Field.ArrayObject
            name="settings.actions.groups.overviewPanel"
            label="Overview panel actions"
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.SpySelect
              source="settings.actions.list"
              sourceField={{ value: 'name', label: 'displayName' }}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <Field.Select name="color" label="Color" options={COLORS} clearable />
          </Field.ArrayObject>
          <Field.ArrayObject
            name="settings.actions.groups.summaryPanel"
            label="Summary panel actions"
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.SpySelect
              source="settings.actions.list"
              sourceField={{ value: 'name', label: 'displayName' }}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <Field.Select name="icon" label="Icon" options={ICONS} required />
            <Field.Select name="color" label="Color" options={COLORS} clearable />
          </Field.ArrayObject>
          <Field.ArrayObject
            name="settings.actions.groups.shareDialog"
            label="Share dialog actions"
            useShadowValue={useShadowValue}
            headingFormat={headingFormat}
          >
            <Field.SpySelect
              source="settings.actions.list"
              sourceField={{ value: 'name', label: 'displayName' }}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <Field.Select name="color" label="Color" options={COLORS} clearable />
          </Field.ArrayObject>
          <Field.SpySelect
            source="settings.actions.list"
            sourceField={{ value: 'name', label: 'displayName' }}
            name="settings.actions.groups.summarySaveButton"
            label="Summary save button action"
            description="Select an action from the list"
            clearable
          />
          <Field.SpySelect
            source="settings.actions.list"
            sourceField={{ value: 'name', label: 'displayName' }}
            name="settings.actions.groups.finishButton"
            label="Next button action in the last step"
            description="Select an action from the list"
            clearable
          />
          <Field.SpySelect
            source="settings.actions.list"
            sourceField={{ value: 'name', label: 'displayName' }}
            name="settings.actions.groups.homeButton"
            label="Home button action"
            description="Select an action from the list"
            clearable
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

CtaSettings.propTypes = {
  seedId: PropTypes.string.isRequired,
  useShadowValue: PropTypes.bool
};

CtaSettings.defaultProps = {
  useShadowValue: false
};

export default CtaSettings;
