import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InstanceType } from 'types';
import { Alert } from '../../Atoms';
import instanceActions from '../../../modules/instances/instancesActions';
import api from '../../../utils/api';
import Form from '../../Form';
import Field from '../../Form/Field';
import Loader from '../../Loader';

type InstanceFormProps = {
  instanceId: string;
  onClose: () => void;
};

const useInstance = (instanceId: string, onClose: () => void) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    values => {
      dispatch(instanceActions.updateInstanceData(instanceId, values.code));
      onClose();
    },
    [dispatch, instanceId, onClose]
  );

  const [instance, setInstance] = useState<{ code: InstanceType }>();

  // this does not run through a saga and is not stored in redux since only a single instance is loaded
  // with different data signature as usual ones.
  // Same approach as with pdfs.
  const fetchInstance = useCallback(async () => {
    const { data: fetchedInstance }: { data: InstanceType } = await api.instances.getOne(instanceId, [
      'savedState',
      'createdAt',
      'updatedAt',
      'createdBy',
      'instanceName',
      'summary',
      '_id',
      'lang'
    ]);

    setInstance({ code: fetchedInstance });
    setLoading(false);
  }, [instanceId]);

  useEffect(() => {
    fetchInstance();
  }, [fetchInstance]);

  return { instance, loading, handleSubmit };
};

const InstanceForm = ({ instanceId, onClose }: InstanceFormProps) => {
  const { instance, loading, handleSubmit } = useInstance(instanceId, onClose);

  const format = (value: any) => JSON.stringify(value, null, 2);
  const parse = (value = '') => JSON.parse(value);

  return (
    <Loader loading={loading}>
      <Form
        name="instance JSON"
        inModal
        isModalOpen
        initialValues={instance}
        onSubmit={handleSubmit}
        closeModal={onClose}
      >
        <Alert color="danger">This is advanced instance editing. Only change if you know what you are doing.</Alert>

        <hr />
        <Field.Code name="code" format={format} parse={parse} />
      </Form>
    </Loader>
  );
};

export default InstanceForm;
