import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import { TYPE_PARTNER } from '../../../utils/propTypes';
import { partnersActions } from '../../../modules/partners';

export class DeletePartnerButton extends React.PureComponent {
  get isDisabledButton() {
    const { partner } = this.props;

    return Boolean(partner.populated?.users?.length);
  }

  get title() {
    const { partner } = this.props;

    if (partner.populated.users.length) {
      return 'This partner is used by users';
    }

    return '';
  }

  handleDeletePartner = () => {
    const { partner, deletePartner } = this.props;
    const response = window.confirm(`Are you sure that you want to delete partner "${partner.name}"?`); // eslint-disable-line no-alert

    if (response) {
      deletePartner(partner._id);
    }
  };

  render() {
    return (
      <ProtectedButton
        rights={[RIGHTS.PARTNERS__DELETE]}
        color="danger"
        onClick={this.handleDeletePartner}
        outline
        disabled={this.isDisabledButton}
        title={this.title}
      >
        Delete
      </ProtectedButton>
    );
  }
}

DeletePartnerButton.propTypes = {
  partner: TYPE_PARTNER.isRequired,
  deletePartner: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  deletePartner: partnersActions.deletePartner
};

export default connect(null, mapDispatchToProps)(DeletePartnerButton);
