import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Icon, IconType } from '../Atoms';
import { useDebounce } from '../../utils/helpers/hooks';

export enum SortType {
  CALENDAR = 'calendar',
  ALPHABET = 'alphabet'
}

interface ProjectListSearchProps {
  onChange: (value: string) => void;
  onChangeSortType: (sortType: SortType) => void;
  currentSortType: SortType;
  delay?: number;
}

const BUTTONS: Array<{ type: SortType; icon: IconType; title: string; className: string }> = [
  {
    type: SortType.CALENDAR,
    icon: 'mdiSortCalendarAscending',
    title: 'Recent',
    className: ''
  },
  {
    type: SortType.ALPHABET,
    icon: 'mdiSortAlphabeticalAscending',
    title: 'Alphabetically',
    className: 'ms-0'
  }
];

const ProjectListSearch: FC<ProjectListSearchProps> = ({
  onChange,
  onChangeSortType,
  currentSortType,
  delay = 150
}) => {
  const [value, setValue] = useState('');
  const search = useDebounce(value, delay);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const handleClickOnSort = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const { sortType } = e.currentTarget.dataset;

      onChangeSortType(sortType as SortType);
    },
    [onChangeSortType]
  );

  useEffect(() => {
    onChange(search.toLowerCase());
  }, [onChange, search]);

  return (
    <div className="px-2 py-4 border-bottom d-flex">
      <div className="position-relative flex-grow-1 me-2">
        <input
          className="form-control pe-5"
          placeholder="Search..."
          type="text"
          onChange={handleChange}
          value={value}
        />
        <span className="text-muted position-absolute" style={{ top: '6px', right: '6px' }}>
          <Icon type="mdiMagnify" />
        </span>
      </div>
      {BUTTONS.map(item => (
        <button
          key={item.type}
          className={`btn btn-link px-2 py-1 ${currentSortType === item.type ? 'text-primary' : 'text-dark'} ${
            item.className
          }`}
          type="button"
          title={item.title}
          data-sort-type={item.type}
          onClick={handleClickOnSort}
        >
          <Icon type={item.icon} />
        </button>
      ))}
    </div>
  );
};

export default ProjectListSearch;
