import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form-html5-validation';
import { FormFeedback, Input, InputGroup, InputGroupText } from '../../../Atoms';
import FieldWrapper from '../FieldWrapper';

class Range extends React.PureComponent {
  renderField = ({ input, meta }) => {
    const { id, min, max, step } = this.props;
    const value = input.value === undefined || input.value === '' ? min : input.value;

    return (
      <>
        <InputGroup className="flex-nowrap">
          <Input {...input} value={value} type="range" id={id} min={min} max={max} step={step} />
          <InputGroupText>{value}</InputGroupText>
        </InputGroup>
        {meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
      </>
    );
  };

  render() {
    const { name } = this.props;

    return (
      <FieldWrapper {...this.props}>
        <Field name={name} render={this.renderField} parse={Number} />
      </FieldWrapper>
    );
  }
}

Range.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};

Range.defaultProps = {
  id: '', // this is injected while cloning
  min: 0,
  max: 1,
  step: 0.1
};

export default Range;
