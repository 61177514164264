import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FieldWrapper from '../FieldWrapper';
import { FieldProps } from '../FieldWrapper/FieldWrapper';

const Immutable = (props: FieldProps) => (
  <FieldWrapper {...props}>
    <Field name={props.name}>{({ input }) => <p>{input.value}</p>}</Field>
  </FieldWrapper>
);

Immutable.propTypes = {
  name: PropTypes.string.isRequired
};

export default Immutable;
