import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { compose } from 'redux';
import b from 'b_';
import { rolesActions, rolesSelectors } from '../../../modules/roles';
import { TYPE_ROLE_LIST, TYPE_SUGGESTION_LIST } from '../../../utils/propTypes';
import Loader from '../../Loader';
import { withUserRights } from '../../UserRights/UserRights';
import MultiSelectFilter from '../../MultiSelectFilter';
import { RIGHTS, rightList } from '../../../utils/rights';
import RoleForm from '../RoleForm';
import { defaultFilterMethod } from '../../../utils/helpers/table';

import './RoleList.scss';

const roleList = b.with('role-list');

const badgeCellClassName = 'app-entity-list__cell_badge';

export class RoleList extends React.PureComponent {
  state = {
    expanded: {},
    filtered: [
      { id: 'rights', value: [] },
      { id: 'users', value: [] }
    ]
  };

  componentDidMount() {
    this.props.fetchRoles();
  }

  handleExpandedChange = expanded => {
    this.setState({ expanded });
  };

  handleFilterChange = filtered => {
    this.setState({ filtered });
  };

  handleSubmitRoleForm = role => {
    this.props.updateRole(role);
  };

  handleDeleteRole = id => {
    this.props.deleteRole(id);
  };

  handleBadgeClick = e => {
    const { value, key } = e.currentTarget.dataset;

    this.setState(prevState => ({
      filtered: prevState.filtered.map(filter =>
        filter.id === key ? { ...filter, value: [...new Set([...filter.value, value])] } : filter
      )
    }));
  };

  renderMultiSelectFilter = ({ filter, onChange }, options = [], name) => {
    return <MultiSelectFilter options={options} filter={filter} onChange={onChange} name={name} />;
  };

  rightsFilterMethod = (filter, row) =>
    filter.value.length ? row[filter.id].some(item => filter.value.includes(item)) : true;

  usersFilterMethod = (filter, row) =>
    filter.value.length ? row[filter.id].some(item => filter.value.includes(item._id)) : true;

  render() {
    const { roles, users, request, hasRights } = this.props;
    const { expanded, filtered } = this.state;
    const columns = [
      {
        resizable: false,
        expander: true,
        show: hasRights([RIGHTS.ROLES__UPDATE])
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
        className: 'fw-bold p-2'
      },
      {
        id: 'rights',
        Header: 'Rights',
        accessor: 'rights',
        className: badgeCellClassName,
        minWidth: 150,
        Cell: row =>
          // TODO: investigate. for some reason when navigation from users page to this one, error occurs https://redux.js.org/style-guide/style-guide#do-not-mutate-state
          [...row.value].sort().map(right => (
            <button
              key={right}
              type="button"
              className="app-badge app-badge_main"
              onClick={this.handleBadgeClick}
              data-value={right}
              data-key="rights"
            >
              {right}
            </button>
          )),
        Filter: filter => this.renderMultiSelectFilter(filter, rightList, 'right'),
        filterMethod: this.rightsFilterMethod
      },
      {
        id: 'users',
        Header: 'Users',
        accessor: 'users',
        className: badgeCellClassName,
        minWidth: 150,
        Cell: row =>
          row.value.sort().map(user => (
            <button
              key={user._id}
              type="button"
              className="app-badge app-badge_main"
              onClick={this.handleBadgeClick}
              data-value={user._id}
              data-key="users"
            >
              {user.username}
            </button>
          )),
        Filter: filter => this.renderMultiSelectFilter(filter, users, 'user'),
        filterMethod: this.usersFilterMethod
      },
      {
        Header: 'Description',
        accessor: 'description',
        className: `${roleList('description')} p-2`
      }
    ];

    return (
      <Loader loading={request}>
        <ReactTable
          data={roles}
          pageSize={roles.length}
          showPagination={false}
          columns={columns}
          expanded={expanded}
          filtered={filtered}
          resizable={false}
          sortable
          filterable
          defaultSorted={[{ id: 'name', desc: false }]}
          onExpandedChange={this.handleExpandedChange}
          onFilteredChange={this.handleFilterChange}
          defaultFilterMethod={defaultFilterMethod}
          className={`${roleList()} app-entity-list -striped -highlight`}
          getTheadFilterThProps={() => ({ style: { position: 'inherit', overflow: 'inherit' } })}
          SubComponent={row => (
            <div className="app-entity-list__form bg-light">
              <RoleForm role={row.original} onSubmit={this.handleSubmitRoleForm} onDelete={this.handleDeleteRole} />
            </div>
          )}
        />
      </Loader>
    );
  }
}

RoleList.defaultProps = {
  roles: [],
  users: [],
  request: false
};

RoleList.propTypes = {
  fetchRoles: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  hasRights: PropTypes.func.isRequired,
  users: TYPE_SUGGESTION_LIST,
  roles: TYPE_ROLE_LIST,
  request: PropTypes.bool
};

const mapStateToProps = state => ({
  roles: rolesSelectors.selectRoleList(state),
  users: rolesSelectors.selectUserListForFilter(state),
  request: rolesSelectors.selectRolesRequest(state)
});

const mapDispatchToProps = {
  fetchRoles: rolesActions.fetchRoles,
  updateRole: rolesActions.updateRole,
  deleteRole: rolesActions.deleteRole
};

export default compose(withUserRights, connect(mapStateToProps, mapDispatchToProps))(RoleList);
