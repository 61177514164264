import { useGetInvitationsQuery } from './services/api';

export const useInvitationsList = () => {
  const { data, isLoading, isUninitialized } = useGetInvitationsQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    invitations: data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};
