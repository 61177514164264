import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../../Form';
import Field from '../../../../Form/Field';
import { TYPE_PART } from '../../../../../utils/propTypes';
import { seedsSelectors } from '../../../../../modules/seeds';
import { RIGHTS } from '../../../../../utils/rights';
import { partsActions, partsSelectors } from '../../../../../modules/parts';
import { useFetchGlobalSettings } from '../../../../../modules/settings/settingsHooks';
import { useProject } from '../../../../../store/project';
import { useSeed } from '../../../../../store/seed';
import MaterialForm from './MaterialForm';
import InteractionForm from './InteractionForm';

const usePartShadowValues = (seedId, projectId) => {
  const globalSettings = useFetchGlobalSettings();

  const { project } = useProject(projectId);
  const { seed } = useSeed(seedId);

  return useMemo(() => {
    return {
      list: [
        {
          name: 'master',
          value: {
            Config: { maxInstanceCount: globalSettings?.raw?.model?.maxInstanceCount }
          }
        },
        {
          name: 'project',
          value: {
            Config: { maxInstanceCount: project?.settings?.model?.maxInstanceCount }
          }
        },
        {
          name: 'seed',
          value: {
            Config: { maxInstanceCount: seed?.settings?.model?.maxInstanceCount }
          }
        },
        {
          name: 'current'
        }
      ]
    };
  }, [globalSettings, project, seed]);
};

const defaultNewPosition = {
  Type: 'Position',
  Name: 'Position',
  Transform: {
    Matrix: {
      RowCount: 4,
      ColumnCount: 4,
      Values: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
    }
  }
};

const PartForm = ({ part, close, onSubmit, projectId, seedId }) => {
  const dispatch = useDispatch();
  const extraInfoCollections = useSelector(state => seedsSelectors.selectExtraInfoCollectionsNames(state, seedId));
  const success = useSelector(partsSelectors.selectPartsFlag);
  const cameraViewSuggestions = useSelector(state => partsSelectors.selectCameraViewsForSuggestions(state, seedId));
  const tabsSuggestions = useSelector(state => partsSelectors.selectTabNamesForSuggestions(state, seedId));
  const priceCodes = useSelector(state => seedsSelectors.selectSeedPriceCodes(state, part.seed));

  const controls = useSelector(state => partsSelectors.selectControlsForSeed(state, seedId));
  const materials = useSelector(state => partsSelectors.selectMaterialsForPreview(state, seedId));

  const metricsList = useSelector(state => seedsSelectors.selectMetricsList(state, seedId));

  const shadowValues = usePartShadowValues(seedId, projectId);

  useEffect(() => {
    if (success) {
      dispatch(partsActions.resetFlags());
      close();
    }
  }, [close, dispatch, success]);

  return (
    <Form
      initialValues={part}
      onSubmit={onSubmit}
      inModal
      name="Part"
      closeModal={close}
      rights={[RIGHTS.PARTS__UPDATE]}
      shadowValues={shadowValues}
      isModalOpen
    >
      <Field.Text name="ReferenceName" required label="Reference Name" />
      <Field.Text name="Option" required label="Option" />
      <Field.Toggle name="disabled" label="Disable" description="Removes part from Configurator" />
      <MaterialForm projectId={projectId} fieldName="Materials" />
      <Field.ArrayObject name="Position" label="Position" lockMoving defaultNew={defaultNewPosition}>
        <Field.Text name="Reference" label="Reference" />
        <Field.Matrix
          label="Transform matrix"
          description="4 by 4 transformation matrix"
          name="Transform.Matrix.Values"
          columns={4}
          rows={4}
        />
      </Field.ArrayObject>
      <Form.Group header="Configuration">
        <Field.Toggle
          name="Config.isTreeIdFlat"
          label="Flat treeId"
          description="Ignore 'Option' of this part when building treeId"
        />
        <Field.Toggle
          name="Config.excludeFromSnapshots"
          label="Exclude from snapshots and 3D"
          description="Do not include this part or it's Sprites when exporting 3D model or taking snapshots for sharing, summary and pdf. "
        />
        <Field.Number
          name="Config.maxInstanceCount"
          label="Max instance count"
          description="Change limit of instances for this part"
          useShadowValue
        />
        {/* Metrics */}
        <Form.Group header="Metrics">
          <Field.Number name="Info.Price.ppuPart.price" label="Price per unit" description="Optional" allowEmpty />

          <Field.Select
            name="Info.Price.ppuPart.priceCode"
            label="Pricecode for PPU"
            description="Overwrites PPU"
            clearable
            creatable
            options={priceCodes}
          />

          {metricsList.length ? (
            <>
              <hr />
              <p>Following contents are generated from by seed metric settings</p>
            </>
          ) : null}

          {metricsList.map(metric => {
            return (
              <Field.Number
                name={`Info.metrics.${metric.name}`}
                label={`${metric.displayName} (${metric.name})`}
                description={`Unit: ${metric.unit}`}
                key={metric.name}
              />
            );
          })}
        </Form.Group>
      </Form.Group>

      <Field.ArrayObject
        name="Sprites.list"
        label="Sprites"
        defaultNew={{
          name: 'Unnamed sprite',
          transform: { Matrix: { Values: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1] } },
          center: { x: 0.5, y: 0.5 }
        }}
      >
        <Field.Text name="name" required label="Name" />
        <Field.Image
          name="material"
          label="Material"
          description="Select from all materials defined in this seed's parts"
          required
          list={materials}
          hideUpload
        />
        <Field.Matrix
          name="transform.Matrix.Values"
          label="Transform"
          columns={4}
          rows={4}
          description="4x4 transformation matrix, defines Position, Rotation and Scale"
        />
        <Field.Number name="center.x" label="Center X" />
        <Field.Number name="center.y" label="Center Y" />
        <Form.Group header="Sprite Interaction" icon="fa-hand-pointer-o">
          <InteractionForm
            name="interaction"
            cameraViews={cameraViewSuggestions}
            extraInfoCollections={extraInfoCollections}
            materials={materials}
            controls={controls}
            tabsSuggestions={tabsSuggestions}
          />
        </Form.Group>
      </Field.ArrayObject>
      <Form.Group header="Part Interaction" icon="fa-hand-pointer-o">
        <InteractionForm
          name="Config.interaction"
          cameraViews={cameraViewSuggestions}
          extraInfoCollections={extraInfoCollections}
          materials={materials}
          controls={controls}
          tabsSuggestions={tabsSuggestions}
        />
        <Field.Toggle
          name="Config.interaction.propagate"
          label="Propagate interaction"
          description="If selected, child parts will inherit this behavior"
        />
      </Form.Group>
    </Form>
  );
};

PartForm.defaultProps = {
  projectId: '',
  seedId: ''
};

PartForm.propTypes = {
  part: TYPE_PART.isRequired,
  onSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  seedId: PropTypes.string
};

export default PartForm;
