import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const PARSER_WITH_EMPTY = input => {
  return input ? Number(input) : '';
};

function NumberField(props) {
  return <Input parse={PARSER_WITH_EMPTY} {...props} type="number" />;
}

NumberField.defaultProps = {
  allowEmpty: false
};

NumberField.propTypes = {
  // not used currently, left to not break forms and keep track of this property usage
  allowEmpty: PropTypes.bool,
  name: PropTypes.string, // eslint-disable-line react/require-default-props
  noLabel: PropTypes.bool // eslint-disable-line react/require-default-props
};

export default NumberField;
