import Alias from './Alias';
import ArrayField from './ArrayField';
import ArrayObject from './ArrayObject';
import AsyncSelect from './AsyncSelect';
import Code from './Code';
import Css from './Css';
import Image from './Image';
import Immutable from './Immutable';
import Json from './Json';
import Markdown from './Markdown';
import Matrix from './Matrix';
import MultiSelect from './MultiSelect';
import Number from './Number';
import Range from './Range';
import Rgb from './Rgb';
import Select from './Select';
import SpySelect from './SpySelect';
import Text from './Text';
import Textarea from './Textarea';
import Email from './Email';
import Toggle from './Toggle';
import Input from './Input';
import Condition from './Condition';

const components = {
  Alias, // Note that alias only works for path endpoints
  Array: ArrayField,
  ArrayObject,
  AsyncSelect,
  Code,
  Condition,
  Css,
  Image,
  Immutable,
  Json,
  Markdown,
  MultiSelect,
  Number,
  Matrix,
  Range,
  Rgb,
  Select,
  SpySelect,
  Text,
  Textarea,
  Email,
  Toggle,
  Input
};

export default components;
