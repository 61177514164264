import { createSelector } from 'reselect';
import { partsSelectors } from '../parts';

export const selectFactoryMaps = state => state.factoryMaps.list;
export const selectFactoryMap = factoryId => state => state.factoryMaps.list[factoryId];
export const selectFactoryMapsRequest = state => state.factoryMaps.request;
export const selectFactoryMapCreatedFlag = state => state.factoryMaps.created;
export const selectFactoryMapUpdatedFlag = state => state.factoryMaps.updated;
export const selectFactoryMapDeletedFlag = state => state.factoryMaps.deleted;

export const selectFactoryMapsList = createSelector([selectFactoryMaps], factoryMaps => Object.values(factoryMaps));

export const selectPublishedFactoryMapsList = createSelector([selectFactoryMapsList], factoryMaps =>
  factoryMaps.filter(factoryMap => factoryMap.published)
);

export const selectFactoryMapsBySeedId = createSelector([selectPublishedFactoryMapsList], factoryMaps =>
  factoryMaps.reduce((result, factoryMap) => ({ ...result, [factoryMap.seedId]: factoryMap }), {})
);

export const selectFactoryControlsList = createSelector([partsSelectors.selectControlsForSeed], controls => {
  const map = controls.reduce((result, control) => {
    if (!result[control.name]) {
      // eslint-disable-next-line no-param-reassign
      result[control.name] = {
        label: control.name,
        value: control.name,
        displayName: control.displayName,
        options: {}
      };
    }
    const list = control.list || control.option || [];

    list.forEach(option => {
      // eslint-disable-next-line no-param-reassign
      result[control.name].options[option.name] = {
        value: option.name,
        displayName: option.displayName,
        name: option.name,
        label: `${option.displayName} (${option.name})`
      };
    });

    return result;
  }, {});

  Object.keys(map).forEach(key => {
    map[key] = {
      ...map[key],
      options: Object.values(map[key].options)
    };
  });

  return map;
});
