import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import b from 'b_';
import Loader from '../Loader';
import { useFetchIframeScript, useIframeScriptsFlags } from '../../modules/iframeScripts/iframeScriptsHooks';
import { ApplicationRoute } from '../../utils/appRoutes';
import IframeScriptForm from './IframeScriptForm/IframeScriptForm';

const iframeScriptPage = b.with('iframe-script-page');

const IframeScriptPage = () => {
  const { scriptId } = useParams();
  const history = useHistory();
  const { iframeScript, request } = useFetchIframeScript(scriptId);
  const { deleted, reset } = useIframeScriptsFlags();

  useEffect(() => {
    if (deleted) {
      reset();

      history.replace(ApplicationRoute.IframeScripts);
    }
  }, [deleted, history, reset]);

  return (
    <div className={`${iframeScriptPage()} h-100 w-100 px-3`}>
      <Loader loading={request}>{iframeScript ? <IframeScriptForm iframeScript={iframeScript} /> : null}</Loader>
    </div>
  );
};

export default IframeScriptPage;
