import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from '../../../../Atoms';

const ImageModalItem = ({ name, url, color, isSelected, onSelect }) => {
  const select = useCallback(() => onSelect(name), [name, onSelect]);
  const renderImg = useCallback((src, alt, backgroundColor) => {
    if (src) {
      return <img src={src} alt={alt} style={{ width: '128px', height: '128px', objectFit: 'contain' }} />;
    }

    if (backgroundColor) {
      return <div className="img-fluid" style={{ backgroundColor, padding: '50% 0' }} />;
    }

    return null;
  }, []);

  return (
    <Col xs={6} lg={4} xl={3} className="my-2">
      <Button onClick={select} block className="w-100" color={isSelected ? 'primary' : 'light'}>
        {renderImg(url, name, color)}
        <span className="small text-break">{name}</span>
      </Button>
    </Col>
  );
};

ImageModalItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  color: PropTypes.string,
  isSelected: PropTypes.bool
};

ImageModalItem.defaultProps = {
  color: '',
  isSelected: false
};

export default ImageModalItem;
