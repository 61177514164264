import { useGetRolesQuery } from './services/api';

export const useRolesList = () => {
  const { data, isLoading, isUninitialized } = useGetRolesQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    roles: data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};
