import { Button } from '../../Atoms';

interface PartGraphToolbarProps {
  onSubmit: () => void;
  onReset: () => void;
  onOpenPartModal: () => void;
  isPristine: boolean;
  onSwitch: () => void;
  isGroupedMode?: boolean;
}

/** Additional buttons that appear in Graph toolbar next to "Zoom Extents" */
const PartGraphToolbar = ({
  onSwitch,
  onSubmit,
  onReset,
  onOpenPartModal,
  isPristine,
  isGroupedMode = false
}: PartGraphToolbarProps) => {
  return (
    <>
      <Button color="primary" onClick={onSubmit} disabled={isPristine}>
        <i className="fa fa-save" /> Save
      </Button>
      <Button color="primary" onClick={onReset} disabled={isPristine}>
        <i className="fa fa-undo" />
      </Button>
      <Button color="primary" onClick={onOpenPartModal}>
        <i className="fa fa-plus" /> Add Part
      </Button>
      <Button onClick={onSwitch} outline={!isGroupedMode} title="Grouped / Separate">
        <i className="fa fa-object-group" />
      </Button>
    </>
  );
};

export default PartGraphToolbar;
