import React from 'react';
import b from 'b_';
import AuthPagesWrapper from '../AuthPagesWrapper';
import SocialAuth from '../SocialAuth';
import TermsOfUseLink from '../TermsOfUseLink';
import PrivacyPolicyLink from '../PrivacyPolicyLink';
import LoginForm from './LoginForm';

const loginPage = b.with('login-page');

const LoginPage = () => (
  <AuthPagesWrapper header="Sign in" className={loginPage()}>
    <LoginForm />
    <SocialAuth />
    <hr />
    <small className="text-center d-block">
      By logging in, you agree to our <TermsOfUseLink /> and to receive Creatomus emails & updates and acknowledge that
      you read our <PrivacyPolicyLink />. You also acknowledge that Creatomus uses cookies to give the best user
      experience.
    </small>
  </AuthPagesWrapper>
);

export default LoginPage;
