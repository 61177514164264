import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as RFForm } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import b from 'b_';
import { Alert, Button, FormFeedback, FormGroup, Input, Label, RSForm } from '../../../Atoms';
import { authActions, authHooks, authSelectors } from '../../../../modules/auth';
import TermsOfUseLink from '../../../TermsOfUseLink';
import PrivacyPolicyLink from '../../../PrivacyPolicyLink';

const registrationForm = b.with('registration-form');

const RegistrationAccountForm = () => {
  const dispatch = useDispatch();
  const authError = useSelector(authSelectors.selectAuthError);
  const { invitation } = authHooks.useAuthInvitation();

  const onSubmit = useCallback(
    values => {
      dispatch(authActions.registration(values.email, values.password, values.invitationId));
    },
    [dispatch]
  );

  const validate = useCallback(value => {
    const errors = {};

    if (value.password && value.password2 && value.password !== value.password2) {
      errors.password2 = 'Passwords do not match. Please try again';
    }

    return errors;
  }, []);

  const renderForm = useCallback(
    ({ handleSubmit, form, values, invalid }) => (
      <RSForm className={registrationForm()} onSubmit={handleSubmit} autoComplete="off">
        <FormGroup>
          <Label for="email" className="text-uppercase">
            <small>Email</small>
          </Label>
          <Input type="email" name="email" value={values.email} disabled />
        </FormGroup>
        <FormGroup>
          <Label for="password" className="text-uppercase">
            <small>Password</small>
          </Label>
          <Field name="password" type="password" required>
            {({ input, meta }) => (
              <>
                <Input {...input} invalid={!meta.valid && meta.touched} placeholder="Password" required />
                <FormFeedback>{meta.error}</FormFeedback>
              </>
            )}
          </Field>
        </FormGroup>
        <FormGroup>
          <Label for="password2" className="text-uppercase">
            <small>Confirm password</small>
          </Label>
          <Field name="password2" type="password" required>
            {({ input, meta }) => (
              <>
                <Input {...input} invalid={!meta.valid && meta.touched} placeholder="Confirm password" required />
                <FormFeedback>{meta.error}</FormFeedback>
              </>
            )}
          </Field>
        </FormGroup>
        <FormGroup check>
          <Field
            name="tc"
            type="checkbox"
            render={({ input }) => (
              <>
                <Input {...input} type="checkbox" id="tc" required />
                <Label check for="tc">
                  <small>
                    I accept the <TermsOfUseLink /> and <PrivacyPolicyLink />
                  </small>
                </Label>
              </>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Button color="primary" type="submit" disabled={invalid} block>
            Sign up
          </Button>
        </FormGroup>
      </RSForm>
    ),
    []
  );

  return (
    <>
      <RFForm
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
        initialValues={{ email: invitation.username, invitationId: invitation._id }}
      />
      {authError ? <Alert color="danger">{authError}</Alert> : null}
    </>
  );
};

export default RegistrationAccountForm;
