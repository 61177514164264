import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _mergeWith from 'lodash/mergeWith';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useField as useRFFField } from 'react-final-form';
import {
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  Button,
  UncontrolledPopover,
  PopoverBody
} from '../../Atoms';
import FormContext from '../FormContext';
import { useSwitch } from '../../../utils/hooks';
import Form from '../Form';
import SHADOW_VALUES_TYPE from '../shadowValuesType';

const CURRENT_NAME = 'current';
const RESULT_NAME = 'result';

const useResetMounted = () => {
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, [setMounted]);

  return mounted;
};

const useClearValues = (list, fieldName) => {
  return useMemo(
    () =>
      list.map(level =>
        level.name === CURRENT_NAME ? level : { name: level.name, value: _get(level.value, fieldName) }
      ),
    [list, fieldName]
  );
};

const useField = (name, format) => {
  const { input } = useRFFField(name, { format });
  const { value } = input;

  const setUndefined = useCallback(() => {
    input.onChange(undefined);
  }, [input]);

  return { value, setUndefined };
};

const parseValue = value => {
  if (value === undefined) {
    return typeof value;
  }

  if (value === '') {
    return 'empty string';
  }

  if (typeof value === 'boolean') {
    return Boolean(value).toString();
  }

  return value;
};

function customizer(objectValue, sourceValue) {
  return Array.isArray(sourceValue) ? sourceValue : undefined;
}

const mergeValues = values =>
  new Promise(resolve => {
    const result = _mergeWith({}, ...values.map(level => ({ value: level.value })), customizer);

    resolve(result);
  });

const ShadowValue = ({ complex = false, ...rest }) => {
  const { shadowValues } = useContext(FormContext);

  if (!shadowValues) return null;

  return complex ? (
    <ShadowComplexValues shadowValues={shadowValues} {...rest} />
  ) : (
    <ShadowSimpleValue shadowValues={shadowValues} {...rest} />
  );
};

ShadowValue.propTypes = {
  complex: PropTypes.bool // eslint-disable-line react/require-default-props
};

const RemoveValueButton = ({ value, onClick, title }) =>
  value !== undefined ? (
    <>
      {' '}
      |{' '}
      <Button size="sm" color="link" onClick={onClick} className="p-0 m-0" style={{ fontSize: 'inherit' }}>
        {title}
      </Button>
    </>
  ) : null;

RemoveValueButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  value: PropTypes.any
};

const ShadowSimpleValue = ({ name, format, shadowValues }) => {
  const mounted = useResetMounted();
  const [result, setResult] = useState({});

  const clearValues = useClearValues(shadowValues.list, name);
  const { value, setUndefined } = useField(name, format);

  useEffect(() => {
    const values = clearValues.map(level => (level.name === CURRENT_NAME ? { name: CURRENT_NAME, value } : level));

    mergeValues(values).then(merged => {
      if (mounted) {
        const source = values.reverse().find(level => level.value === merged.value);

        setResult({ name: source.name, value: parseValue(source.value) });
      }
    });
  }, [clearValues, mounted, value]);

  const id = useMemo(() => `${name.replace(/\W/g, '_')}_`, [name]);

  const icon =
    result.name === CURRENT_NAME ? (
      <i className="fa fa-fw fa-check text-success" />
    ) : (
      <i className="fa fa-fw fa-code-fork text-info" />
    );

  return (
    <FormText className="d-inline">
      <span id={id} className="text-primary" style={{ cursor: 'default' }}>
        {icon} <strong>{result.value}</strong> ({result.name})
      </span>
      <RemoveValueButton onClick={setUndefined} title="Remove value" value={value} />
      <UncontrolledPopover placement="bottom" trigger="hover" target={id}>
        <PopoverBody>
          {clearValues.map(level =>
            level.name === CURRENT_NAME ? (
              <span className="d-block text-muted" key={level.name}>
                {CURRENT_NAME}: <strong>{parseValue(value)}</strong>
              </span>
            ) : (
              <span className="d-block text-muted" key={level.name}>
                {level.name}: <strong>{parseValue(level.value)}</strong>
              </span>
            )
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </FormText>
  );
};

ShadowSimpleValue.propTypes = {
  name: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  shadowValues: SHADOW_VALUES_TYPE.isRequired
};

const useFormValue = list => {
  const [allValues, setAllValues] = useState(list);
  const [currentType, setCurrentType] = useState(list[0].name);

  const handleChangeType = useCallback(e => {
    setCurrentType(e.currentTarget.dataset.type);
  }, []);

  const formValue = allValues.find(({ name: type }) => type === currentType);

  return { currentType, handleChangeType, allValues, setAllValues, formValue };
};

const ShadowComplexValues = ({ name, children, label, format, shadowValues }) => {
  const mounted = useResetMounted();
  const [result, setResult] = useState({});

  const clearValues = useClearValues(shadowValues.list, name);
  const { value, setUndefined } = useField(name, format);

  const { isOpen, open, close } = useSwitch(false);
  const { allValues, setAllValues, formValue, currentType, handleChangeType } = useFormValue(shadowValues.list);

  useEffect(() => {
    const values = clearValues.map(level => (level.name === CURRENT_NAME ? { name: CURRENT_NAME, value } : level));

    mergeValues(values).then(merged => {
      if (mounted) {
        setAllValues(
          [...values, { name: RESULT_NAME, value: merged.value }].map(level => ({
            ...level,
            formState: _set({}, name, level.value)
          }))
        );

        const mergedValue = Array.isArray(merged.value) ? merged.value.length : undefined;

        const source = values.reverse().find(level => {
          const levelValue = Array.isArray(level.value) ? level.value.length : undefined;

          return levelValue === mergedValue;
        });

        setResult(source);
      }
    });
  }, [clearValues, mounted, name, setAllValues, value]);

  return (
    <>
      <FormText className="d-inline">
        <Button size="sm" color="link" onClick={open} className="p-0 m-0" style={{ fontSize: 'inherit' }}>
          Show the final value ({result.name})
        </Button>
        <RemoveValueButton onClick={setUndefined} title="Remove array" value={value} />
      </FormText>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader toggle={close}>{label || name}</ModalHeader>
        <ModalBody>
          <ButtonGroup className="mb-3" size="sm">
            {allValues.map(({ name: type, value: itemValue }) => (
              <Button
                data-type={type}
                key={type}
                onClick={handleChangeType}
                color="light"
                active={currentType === type}
              >
                {type}
                {Array.isArray(itemValue) ? ` (${itemValue.length})` : null}
              </Button>
            ))}
          </ButtonGroup>
          <Form initialValues={formValue.formState} useBottomButtons={false} preview>
            {children}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ShadowComplexValues.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  shadowValues: SHADOW_VALUES_TYPE.isRequired
};

export default ShadowValue;
