import b from 'b_';
import { TimeSeries } from 'pondjs';
import { Column } from 'react-table';
import { SeedStatisticsType, SeedStatisticsEntryType } from 'types';
import { Col, Row, Table } from '../../../Atoms';
import { msToTime } from '../../../../utils';
import StatisticsChart from './StatisticsChart';

const statisticsData = b.with('statistics-data');

function getSeries(data: SeedStatisticsEntryType[]) {
  return new TimeSeries({
    name: 'Visits',
    columns: ['time', 'value'],
    points: data.map(item => [new Date(item.date), item.value / 1000 / 60])
  });
}

const columns: Column<SeedStatisticsEntryType>[] = [
  {
    Header: 'Date',
    accessor: 'date',
    Cell: row => new Date(row.value).toLocaleString(),
    minWidth: 170,
    disableFilters: true
  },
  {
    Header: 'Time',
    accessor: 'value',
    Cell: row => msToTime(row.value),
    minWidth: 170,
    disableFilters: true
  }
];

const StatisticsData = ({ statistics }: { statistics: SeedStatisticsType }) => {
  return (
    <div className={statisticsData()}>
      <h5 className="text-primary mt-3">Visits</h5>
      <Row>
        <Col lg="6" className="mb-3">
          <Table data={statistics.visits} columns={columns} defaultPageSize={10} />
        </Col>
        <Col lg="6">
          <StatisticsChart series={getSeries(statistics.visits)} />
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsData;
