import { FC } from 'react';
import { TemplateSourceType, TemplateType } from 'types';
import { Col, Form, FormField, FormType, PageTile, Row } from '../Atoms';

interface TemplateSourceFormProps {
  template: TemplateType;
  templateSource: TemplateSourceType;
  handleSubmit: (values: TemplateType) => void;
  handleChangeFormValues: FormType.HandlerChangeFormValues<TemplateType>;
}

const TemplateSourceForm: FC<TemplateSourceFormProps> = ({
  children,
  template,
  templateSource,
  handleSubmit,
  handleChangeFormValues
}) => {
  return (
    <Form<TemplateType> defaultValues={template} onSubmit={handleSubmit} onChangeFormValues={handleChangeFormValues}>
      <Row className="mb-4">
        <Col md={6} sm={12}>
          <PageTile className="h-100">
            <h5>Edit {templateSource}</h5>
            <FormField.Code<TemplateType> name={templateSource} mode="html" required />
          </PageTile>
        </Col>
        {children}
      </Row>
    </Form>
  );
};

export default TemplateSourceForm;
