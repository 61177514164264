import PropTypes from 'prop-types';
import ASSET_TYPES from '../modules/assets/assetTypes';

export const TYPE_STRING_OR_NUMBER = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const TYPE_SUGGESTION = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: TYPE_STRING_OR_NUMBER.isRequired
});

export const TYPE_SUGGESTION_LIST = PropTypes.arrayOf(TYPE_SUGGESTION);

export const TYPE_IMAGE_SUGGESTION = PropTypes.shape({
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const TYPE_IMAGE_SUGGESTIONS = PropTypes.arrayOf(TYPE_IMAGE_SUGGESTION);

const TYPE_ID_NAME = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const TYPE_ROLE = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    })
  ).isRequired,
  rights: PropTypes.arrayOf(PropTypes.string).isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdBy: PropTypes.string
});

export const TYPE_ROLE_LIST = PropTypes.arrayOf(TYPE_ROLE);

export const TYPE_PARTNER = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  activeTemplateId: PropTypes.string.isRequired,
  adminSettings: PropTypes.shape({
    configuratorHost: PropTypes.string.isRequired,
    logo: PropTypes.string,
    title: PropTypes.string,
    requestsShowSendToProduction: PropTypes.bool,
    requestsShowPlot: PropTypes.bool,
    requestsShowReservationFee: PropTypes.bool,
    isLegaleSignAvailable: PropTypes.bool
  }).isRequired,
  domain: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailFrom: PropTypes.shape({
    address: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  emailTemplates: PropTypes.shape({
    welcomeTemplate: PropTypes.number,
    requestTemplate: PropTypes.number,
    requestPDFSummaryTemplate: PropTypes.number,
    requestNotificationTemplate: PropTypes.number,
    crmProductionTemplate: PropTypes.number,
    crmCancelOrderTemplate: PropTypes.number,
    crmPlotCutOffDate: PropTypes.number
  }).isRequired,
  emailTo: PropTypes.shape({
    crmProductionTemplate: PropTypes.arrayOf(PropTypes.string).isRequired,
    crmCancelOrderTemplate: PropTypes.arrayOf(PropTypes.string).isRequired,
    crmPlotCutOffDate: PropTypes.arrayOf(PropTypes.string).isRequired,
    requestNotificationTemplate: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  seeds: PropTypes.arrayOf(PropTypes.string).isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdBy: PropTypes.string,
  populated: PropTypes.shape({
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
      })
    )
  }),
  publicAPIKey: PropTypes.string,
  hasPublicAPIKey: PropTypes.bool
});

export const TYPE_PARTNER_LIST = PropTypes.arrayOf(TYPE_PARTNER);

export const TYPE_USER = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  partner: PropTypes.string,
  populated: PropTypes.shape({
    roles: PropTypes.arrayOf(TYPE_ID_NAME).isRequired,
    partner: PropTypes.arrayOf(TYPE_ID_NAME).isRequired,
    projects: PropTypes.arrayOf(TYPE_ID_NAME).isRequired
  }).isRequired,
  lastVisitAt: PropTypes.string
});

export const TYPE_USER_LIST = PropTypes.arrayOf(TYPE_USER);

export const TYPE_PLOT = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  plotType: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['Not released', 'Released', 'Reserved', 'Sold']).isRequired,
  development: PropTypes.string,
  price: TYPE_STRING_OR_NUMBER,
  cutOffDate: PropTypes.string,
  description: PropTypes.string,
  populated: PropTypes.shape({
    projectName: PropTypes.string.isRequired
  }).isRequired
});

export const TYPE_PLOT_LIST = PropTypes.arrayOf(TYPE_PLOT);

export const TYPE_PROJECT = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  publicSeedId: PropTypes.string,
  partnerId: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  populated: PropTypes.shape({
    partnerName: PropTypes.string.isRequired
  }).isRequired
});

export const TYPE_PROJECT_LIST = PropTypes.arrayOf(TYPE_PROJECT);

export const FACTORY_MAP_TYPE = {
  KEY: 'key',
  KEYS: 'keys',
  VALUE: 'value',
  MAP: 'map'
};
export const FACTORY_MAP_TYPES = Object.values(FACTORY_MAP_TYPE);

export const FACTORY_MAP_DEFINITION = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.oneOf(FACTORY_MAP_TYPES),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      conditions: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string)
        })
      )
    })
  )
});

export const FACTORY_MAP_DEFINITIONS = PropTypes.arrayOf(FACTORY_MAP_DEFINITION);

export const TYPE_FACTORY_MAP = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  seedId: PropTypes.string.isRequired,
  published: PropTypes.bool,
  populated: PropTypes.shape({
    seedName: PropTypes.string.isRequired,
    seedVersion: PropTypes.string.isRequired
  }).isRequired,
  definition: FACTORY_MAP_DEFINITIONS
});

export const TYPE_IFRAME_SCRIPT = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  description: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  latest: PropTypes.bool
});

export const TYPE_LEAD = PropTypes.shape({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  message: PropTypes.string,
  pdfFileId: PropTypes.string,
  sentToProductionAt: PropTypes.string,
  sentToProduction: PropTypes.bool,
  cancelOrderAt: PropTypes.string,
  cancelOrder: PropTypes.bool,
  downloadedPDF: PropTypes.bool,
  unread: PropTypes.bool
});

export const TYPE_INSTANCE = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  instanceName: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  orphan: PropTypes.bool,
  pdfNotes: PropTypes.string,
  seed: PropTypes.shape({
    root: PropTypes.string.isRequired
  }),
  mappedInstanceId: PropTypes.string,
  populated: PropTypes.shape({
    seed: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      version: TYPE_STRING_OR_NUMBER
    }).isRequired,
    visitor: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    })
  }),
  factoryMap: TYPE_FACTORY_MAP,
  lead: TYPE_LEAD
});

export const TYPE_INSTANCE_LIST = PropTypes.arrayOf(TYPE_INSTANCE);

export const TYPE_MAPPED_INSTANCE_LINES = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string
  })
);

export const TYPE_MAPPED_INSTANCE = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  factoryId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  locked: PropTypes.bool,
  sentToProductionAt: PropTypes.string,
  sentToProduction: PropTypes.bool,
  generatedLines: TYPE_MAPPED_INSTANCE_LINES.isRequired,
  lines: TYPE_MAPPED_INSTANCE_LINES.isRequired,
  originalLines: TYPE_MAPPED_INSTANCE_LINES.isRequired
});

export const TYPE_MAPPED_INSTANCE_PREVIEW = PropTypes.shape({
  factoryId: PropTypes.string.isRequired,
  lines: TYPE_MAPPED_INSTANCE_LINES.isRequired
});

export const TYPE_VISITOR = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  name: PropTypes.string,
  instances: TYPE_INSTANCE_LIST.isRequired,
  status: PropTypes.string.isRequired
});

export const TYPE_SEED_STATS = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  totalTime: PropTypes.number,
  totalVisits: PropTypes.number,
  requests: PropTypes.arrayOf(PropTypes.string),
  visits: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  )
});

export const TYPE_STATISTICS = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  seeds: PropTypes.arrayOf(TYPE_SEED_STATS)
});

export const TYPE_VISITOR_LIST = PropTypes.arrayOf(TYPE_VISITOR);

export const TYPE_SEED_PRICE = PropTypes.shape({
  code: PropTypes.string.isRequired,
  price: TYPE_STRING_OR_NUMBER.isRequired
});

export const TYPE_OPTION_STATUS = PropTypes.shape({ name: PropTypes.string, label: PropTypes.string });
export const TYPE_OPTION_STATUSES = PropTypes.arrayOf(TYPE_OPTION_STATUS);

export const TYPE_PART_OPTION_MATERIAL = PropTypes.shape({
  original: PropTypes.string,
  apply: PropTypes.string,
  price_psq: TYPE_STRING_OR_NUMBER,
  priceCode_psq: TYPE_STRING_OR_NUMBER,
  layer: PropTypes.string
});

export const TYPE_NAMESPACED_IMAGE = PropTypes.shape({
  fileName: PropTypes.string,
  namespace: PropTypes.string
});

export const TYPE_PART_CONTROL_OPTION = PropTypes.shape({
  name: PropTypes.string,
  optionHint: PropTypes.string,
  displayName: PropTypes.string,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  inactive: PropTypes.bool,
  priceHidden: PropTypes.bool,
  areaHidden: PropTypes.bool,
  price: TYPE_STRING_OR_NUMBER,
  priceCode: PropTypes.string,
  priceCode_psq: PropTypes.string,
  fix: PropTypes.objectOf(PropTypes.string),
  status: PropTypes.string,
  statusLabel: PropTypes.string,
  image: TYPE_NAMESPACED_IMAGE,
  material: PropTypes.arrayOf(TYPE_PART_OPTION_MATERIAL),
  extraInfoCollection: PropTypes.string
});

export const TYPE_PART_CONTROL = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: TYPE_STRING_OR_NUMBER,
  list: PropTypes.arrayOf(TYPE_PART_CONTROL_OPTION),
  default: TYPE_STRING_OR_NUMBER,
  displayName: PropTypes.string,
  type: PropTypes.string, // will not list each with oneOf because this is subject to change and needs to be updated in multiple places. This list can be filled when Creator is integrated,
  disabled: PropTypes.bool,
  partId: PropTypes.string // this is injected for remote controls
});

export const TYPE_PART_CONTROLS = PropTypes.arrayOf(TYPE_PART_CONTROL);

export const TYPE_REMOTE_CONTROL = PropTypes.shape({
  name: PropTypes.string,
  partId: PropTypes.string,
  value: TYPE_STRING_OR_NUMBER,
  list: PropTypes.arrayOf(TYPE_PART_CONTROL_OPTION),
  displayName: PropTypes.string
});

export const TYPE_REMOTE_CONTROLS = PropTypes.arrayOf(TYPE_REMOTE_CONTROL);

export const TYPE_SEED = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameLong: PropTypes.string,
  version: TYPE_STRING_OR_NUMBER,
  projectId: PropTypes.string.isRequired,
  rootPartId: PropTypes.string.isRequired,
  description: PropTypes.string,
  public: PropTypes.bool,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  createdBy: PropTypes.string,
  prices: PropTypes.arrayOf(TYPE_SEED_PRICE).isRequired,
  remoteControls: TYPE_REMOTE_CONTROLS
});

export const TYPE_SEED_LIST = PropTypes.arrayOf(TYPE_SEED);

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info'
};

export const TYPE_NOTIFICATION = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired,
  message: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  hidden: PropTypes.bool
});

export const TYPE_NOTIFICATIONS = PropTypes.arrayOf(TYPE_NOTIFICATION);

export const TYPE_TEMPLATE = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  draft: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.string
});

export const TYPE_TEMPLATES = PropTypes.arrayOf(TYPE_TEMPLATE);
export const TYPE_PART_MATERIAL = PropTypes.shape({
  ColorDiffuse: PropTypes.string,
  ColorEmission: PropTypes.string,
  ColorSpecular: PropTypes.string,
  Gloss: PropTypes.number,
  Guid: PropTypes.string,
  Name: PropTypes.string.isRequired,
  Opacity: PropTypes.number,
  DiffuseTexture: PropTypes.string,
  BumpTexture: PropTypes.string,
  BumpScale: PropTypes.number
});

export const TYPE_PART_POSITION = PropTypes.shape({
  Guid: PropTypes.string.isRequired,
  Name: PropTypes.string,
  Reference: PropTypes.string.isRequired,
  Type: PropTypes.string,
  Transform: PropTypes.shape({
    Matrix: PropTypes.shape({
      RowCount: PropTypes.number,
      ColumnCount: PropTypes.number,
      Values: PropTypes.arrayOf(PropTypes.number)
    })
  })
});

export const TYPE_VECTOR_THREE = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  z: PropTypes.number.isRequired
});

export const TYPE_PART_VIEW = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired, // will be filled with oneOF after Creator integration
  cameraPosition: TYPE_VECTOR_THREE.isRequired,
  cameraTarget: TYPE_VECTOR_THREE.isRequired,
  icon: PropTypes.string, // will be filled with oneOf after Creator integration
  clippingPlane: PropTypes.shape({
    enabled: PropTypes.bool,
    normal: TYPE_VECTOR_THREE,
    origin: TYPE_VECTOR_THREE
  }),
  sunlight: PropTypes.string,
  sunlightEnabled: PropTypes.bool,
  skylight: PropTypes.string,
  skylightEnabled: PropTypes.bool,
  customLights: PropTypes.arrayOf(PropTypes.string)
});

export const TYPE_PART_CUSTOM_LIGHT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['ambient', 'directional', 'hemi', 'point', 'rect', 'spot']),
  color: PropTypes.string,
  intensity: PropTypes.number,
  castShadow: PropTypes.bool,
  colorSecondary: PropTypes.string,
  position: TYPE_VECTOR_THREE,
  distance: PropTypes.number,
  decay: PropTypes.number,
  width: PropTypes.number, // for rectangular
  height: PropTypes.number, // for rectangular
  lookAt: TYPE_VECTOR_THREE
});

export const TYPE_PART_SKYLIGHT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  intensity: PropTypes.number
});

export const TYPE_PART_SUNLIGHT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  intensity: PropTypes.number,
  azimuth: PropTypes.number,
  altitude: PropTypes.number
});

export const TYPE_PART_PHASE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  openView: PropTypes.string,
  controls: PropTypes.arrayOf(TYPE_PART_CONTROL),
  controlPanelNextHidden: PropTypes.bool,
  controlPanelDisplay: PropTypes.bool,
  summaryDisabled: PropTypes.bool
});

export const TYPE_PART = PropTypes.shape({
  _id: PropTypes.string,
  root: PropTypes.bool,
  seed: PropTypes.string,
  ReferenceName: PropTypes.string,
  Option: PropTypes.string,
  Info: PropTypes.shape({
    Price: PropTypes.shape({
      ppuPart: PropTypes.shape({
        price: TYPE_STRING_OR_NUMBER
      })
    }),
    counterRooms: PropTypes.number,
    ExtraInfo: PropTypes.shape({
      image: PropTypes.string,
      infoPanels: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          image: TYPE_NAMESPACED_IMAGE,
          caption: PropTypes.string,
          text: PropTypes.string
        })
      ),
      externalLink: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string
      })
    })
  }),
  Config: PropTypes.shape({
    graphX: PropTypes.number,
    graphY: PropTypes.number
  }),
  Controls: PropTypes.shape({
    Object: TYPE_PART_CONTROLS
  }),
  Position: PropTypes.arrayOf(TYPE_PART_POSITION),
  Materials: PropTypes.arrayOf(TYPE_PART_MATERIAL),
  Views: PropTypes.shape({
    list: PropTypes.arrayOf(TYPE_PART_VIEW)
  }),
  Lights: PropTypes.shape({
    sunlights: PropTypes.arrayOf(TYPE_PART_SUNLIGHT),
    skylights: PropTypes.arrayOf(TYPE_PART_SKYLIGHT),
    customLights: PropTypes.arrayOf(TYPE_PART_CUSTOM_LIGHT)
  }),
  Phases: PropTypes.shape({
    Object: PropTypes.arrayOf(TYPE_PART_PHASE)
  })
  //  Object: PropTypes.shape({}),
});
export const TYPE_PARTS = PropTypes.arrayOf(TYPE_PART);
export const TYPE_PARTS_MAP = PropTypes.objectOf(TYPE_PART);

export const TYPE_ASSET = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    deleted: PropTypes.bool,
    projectId: PropTypes.string,
    uploadedWith: PropTypes.string
  }),
  filename: PropTypes.string.isRequired
});
export const TYPE_ASSET_LIST = PropTypes.arrayOf(TYPE_ASSET);

export const TYPE_ASSET_TYPES = PropTypes.oneOf(Object.values(ASSET_TYPES));

export const TYPE_ASSET_PARENT = PropTypes.shape({
  fileName: PropTypes.string,
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      ReferenceName: PropTypes.string.isRequired,
      Option: PropTypes.string.isRequired,
      seed: PropTypes.string.isRequired
    })
  ),
  seeds: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      version: TYPE_STRING_OR_NUMBER,
      projectId: PropTypes.string.isRequired
    })
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
});
export const TYPE_ASSET_PARENTS = PropTypes.arrayOf(TYPE_ASSET_PARENT);

export const TYPE_TAB = PropTypes.shape({
  name: PropTypes.string.isRequired,
  partId: PropTypes.string.isRequired,
  definition: PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    openView: PropTypes.string,
    tabIndex: PropTypes.number,
    controls: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ),
    controlPanelDisplay: PropTypes.bool,
    controlPanelNextHidden: PropTypes.bool,
    summaryDisabled: PropTypes.bool
  })
});

export const TYPE_VIEW = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  partId: PropTypes.string.isRequired,
  view: TYPE_PART_VIEW.isRequired
});

export const TYPE_GRAPH_NODE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  pristine: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired
});
export const TYPE_GRAPH_NODES = PropTypes.arrayOf(TYPE_GRAPH_NODE);

export const TYPE_GRAPH_EDGE = PropTypes.shape({
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  sourcePosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired,
  targetPosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
});

export const TYPE_GRAPH_EDGES = PropTypes.arrayOf(TYPE_GRAPH_EDGE);

export const TYPE_NODE_ACTIONS = PropTypes.arrayOf(
  PropTypes.shape({
    clickHandler: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired
  })
);
