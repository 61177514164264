const DashHeader = () => {
  return (
    <div className="d-flex py-4 mx-4 border-bottom align-items-center">
      <img alt="Creatomus logo" height="50px" src="logo.jpg" />
      <div className="pl-4">
        <small className="text-muted">Welcome to</small>
        <p className="h3 font-weight-bolder text-dark py-0 mx-0">Creatomus Admin</p>
      </div>
    </div>
  );
};

export default DashHeader;
