import { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { ApplicationRoute, getProjectUrl } from '../../../utils/appRoutes';
import BreadcrumbSection, { BreadcrumbItemListType } from '../BreadcrumbSection';
import BreadcrumbBackButton from '../BreadcrumbBackButton';
import { useProjectList } from '../../../store/project';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';

const parserUrl: Record<string, (id: string) => LocationDescriptor> = {
  [ApplicationRoute.ProjectList]: id => getProjectUrl(id, 'list'),
  [ApplicationRoute.Project]: id => getProjectUrl(id),
  [ApplicationRoute.ProjectAssets]: id => getProjectUrl(id, 'images'),
  [ApplicationRoute.ProjectSelfService]: id => getProjectUrl(id, 'self-service'),
  [ApplicationRoute.ProjectInfo]: id => getProjectUrl(id, 'info'),
  [ApplicationRoute.ProjectSettings]: id => getProjectUrl(id, 'settings')
};

const lastSection: Record<string, { group: string; title: string; rights: string[] }> = {
  [ApplicationRoute.ProjectInfo]: { group: 'Settings', title: 'Base Project Info', rights: [RIGHTS.PROJECTS__UPDATE] },
  [ApplicationRoute.ProjectSettings]: {
    group: 'Settings',
    title: 'Project Settings',
    rights: [RIGHTS.PROJECTS__UPDATE]
  },
  [ApplicationRoute.ProjectAssets]: { group: 'Assets', title: 'Assets', rights: [RIGHTS.PROJECTS__EDIT_ASSETS] },
  [ApplicationRoute.ProjectSelfService]: {
    group: 'Self-service',
    title: 'Self Service',
    rights: [RIGHTS.PROJECTS__SELF_SERVICE]
  }
};

const ProjectsBreadcrumbs: FC = () => {
  const match = useRouteMatch<{ projectId: string }>();
  const { projects } = useProjectList();
  const hasRights = useUserRights();

  let backLink: LocationDescriptor =
    match.path === ApplicationRoute.ProjectList ? ApplicationRoute.Main : ApplicationRoute.ProjectList;

  if (lastSection[match.path]) {
    backLink = getProjectUrl(match.params.projectId);
  }

  const list = useMemo(
    () =>
      projects.map(project => ({
        label: project.name,
        id: project._id,
        path: parserUrl[match.path](project._id)
      })),
    [match.path, projects]
  );

  const lastSectionsList = useMemo(
    () =>
      Object.keys(lastSection).reduce((result: BreadcrumbItemListType, section: any) => {
        if (hasRights(lastSection[section].rights)) {
          result.push({
            label: lastSection[section].title,
            group: lastSection[section].group,
            id: section,
            path: parserUrl[section](match.params?.projectId || '')
          });
        }

        return result;
      }, []),
    [match, hasRights]
  );

  const lastSectionTitle = lastSection[match.path];

  return (
    <>
      <BreadcrumbBackButton to={backLink} />
      <BreadcrumbSection
        title="Projects"
        list={list}
        activeLink={lastSectionTitle ? backLink : false}
        activeId={match.params.projectId}
      />
      {lastSectionTitle ? <BreadcrumbSection title="Edit" list={lastSectionsList} activeId={match.path} /> : null}
    </>
  );
};

export default ProjectsBreadcrumbs;
