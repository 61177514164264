import { SeedType } from 'types';
import { Button, PageTile } from '../Atoms';
import { useSeedListByProject } from '../../store/seed';
import SeedCreationModal from '../SeedCreationModal';
import { useModal } from '../../utils/hooks';
import ProjectDashboardVersionItem from './ProjectDashboardVersionItem';

interface ProjectDashboardVersionsProps {
  projectId: string;
  projectName: string;
  publicSeedId?: string;
}

const useGroupedSeedsByProject = (projectId: string, publicSeedId?: string) => {
  const { seeds } = useSeedListByProject(projectId);
  const published: SeedType[] = [];
  const rest: SeedType[] = [];
  const draft: SeedType[] = [];
  const childSeedIds = new Set();

  seeds.forEach(seed => {
    if (seed._id === publicSeedId) {
      published.push(seed);

      if (Array.isArray(seed.settings?.childSeedIds) && seed.settings.childSeedIds.length !== 0) {
        seed.settings.childSeedIds.forEach(id => childSeedIds.add(id));
      }

      return;
    }

    rest.push(seed);
  });

  if (Array.from(childSeedIds).length !== 0) {
    rest.forEach(seed => {
      if (childSeedIds.has(seed._id)) {
        published.push(seed);
      } else {
        draft.push(seed);
      }
    });
  } else {
    draft.push(...rest);
  }

  return { published, draft };
};

const ProjectDashboardVersions = ({ projectId, projectName, publicSeedId }: ProjectDashboardVersionsProps) => {
  const { isOpen, close, open } = useModal();
  const seeds = useGroupedSeedsByProject(projectId, publicSeedId);

  return (
    <>
      <PageTile className="mb-4">
        <div className="d-flex align-items-center">
          <h5>Versions</h5>
          <Button onClick={open} color="primary" outline size="sm" className="ms-auto">
            + Add
          </Button>
        </div>
        {seeds.published.length !== 0 ? (
          <>
            <small className="text-muted">Published</small>
            <ul className="list-group mb-3">
              {seeds.published.map(seed => (
                <ProjectDashboardVersionItem key={seed._id} seed={seed} publicSeedId={publicSeedId} />
              ))}
            </ul>
          </>
        ) : null}

        {seeds.draft.length !== 0 ? (
          <>
            <small className="text-muted">Not published</small>
            <ul className="list-group mb-3">
              {seeds.draft.map(seed => (
                <ProjectDashboardVersionItem key={seed._id} seed={seed} publicSeedId={publicSeedId} />
              ))}
            </ul>
          </>
        ) : null}
      </PageTile>
      <SeedCreationModal
        type="create"
        defaultValues={{ projectId, name: projectName }}
        onClose={close}
        isOpen={isOpen}
      />
    </>
  );
};

export default ProjectDashboardVersions;
