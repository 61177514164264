import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ImageProps } from '../../types';
import { useSwitch } from '../../../../../utils/hooks';
import Icon from '../../../Icon/Icon';
import Gallery from './Gallery';

const SIZE = 128;

const Image = <T,>({ name, list, uploadImage, onUploadFailure, onUploadSuccess, label }: ImageProps<T>) => {
  const { field } = useController<T>({ name });
  const handleChange = useCallback(
    value => {
      field.onChange(value);
    },
    [field]
  );
  const { isOpen, toggle } = useSwitch(false);
  const { isOpen: showImage, toggle: toggleImage } = useSwitch(false);

  const currentImage = list?.find(image => image.name === field.value);

  const emptyState = (
    <div
      style={{ border: '2px dashed #bdbdbd', width: `${SIZE}px`, height: `${SIZE / 2}px` }}
      className="text-center rounded d-flex justify-content-center align-items-center p-2"
    >
      <small className="text-muted">no image selected</small>
    </div>
  );

  const onRemove = useCallback(() => {
    field.onChange(undefined);
  }, [field]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="form-label text-uppercase fw-bold me-2">{label}</label>
      <div>
        {currentImage ? (
          <div className="py-2" style={{ border: '2px dashed #bdbdbd' }}>
            <>
              <div className="d-flex">
                <span className="d-block ps-2 fw-bold text-muted">{currentImage.name}</span>
                <button
                  type="button"
                  title="remove image"
                  className="d-inline btn btn-link p-0 align-baseline"
                  onKeyDown={e => e.preventDefault()}
                  tabIndex={-1}
                  onClick={onRemove}
                >
                  <Icon type="mdiTrashCanOutline" mix="text-primary" />
                </button>
              </div>

              {showImage ? (
                <img
                  className="ps-2"
                  src={currentImage?.url}
                  alt={currentImage?.name}
                  style={{ width: `${SIZE}px`, height: `${SIZE}px`, objectFit: 'contain' }}
                />
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  role="button"
                  tabIndex={-1}
                  className="ps-2 text-muted text-decoration-underline"
                  onClick={toggleImage}
                  onKeyDown={() => null}
                >
                  click to preview
                </span>
              )}
            </>
          </div>
        ) : (
          emptyState
        )}
      </div>
      <div className="mt-3 mb-3 w-100">
        <button className="btn btn-outline-primary" type="button" onClick={toggle}>
          {currentImage ? 'Replace image' : 'Pick an image'}
        </button>
      </div>
      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Uploaded images</ModalHeader>
        <ModalBody>
          <Gallery
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={onUploadFailure}
            uploadImage={uploadImage}
            list={list}
            onClose={toggle}
            onSelect={value => {
              handleChange(value);
              toggle();
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Image;
