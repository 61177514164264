import { useDispatch } from 'react-redux';
import { InstanceType } from 'types';
import { RIGHTS } from '../../utils/rights';
import ProtectedButton from '../ProtectedButton';
import { instancesActions } from '../../modules/instances';

interface DeleteRequestButtonProps {
  instance: InstanceType;
}

export const DeleteRequestButton = ({ instance }: DeleteRequestButtonProps) => {
  const dispatch = useDispatch();
  const deleteRequest = (instanceId: string) => dispatch(instancesActions.deleteRequest(instanceId));
  const handleDeleteButtonClick = () => {
    const response = window.confirm('Are you sure that you want to delete request ?'); // eslint-disable-line no-alert

    if (response) {
      deleteRequest(instance._id);
    }
  };

  if (instance.lead?.sentToProduction && !instance.lead?.cancelOrder) {
    return null;
  }

  return (
    <ProtectedButton
      rights={[RIGHTS.REQUESTS__DELETE]}
      onClick={handleDeleteButtonClick}
      color="danger"
      size="sm"
      outline
    >
      <i className="fa fa-trash fa-fw" />
      Delete request
    </ProtectedButton>
  );
};

export default DeleteRequestButton;
