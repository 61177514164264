import React from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import b from 'b_';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from '../Atoms';
import Submit from './Submit';
import Reset from './Reset';
import FormContext from './FormContext';
import SHADOW_VALUES_TYPE from './shadowValuesType';
import formMutators from './formMutators';

import './Form.scss';

const formClass = b.with('form');

const dummySubmit = () => null;

class Form extends React.PureComponent {
  renderButtons = (pristine, invalid) => {
    const { rights, customButton, ignorePristine } = this.props;

    // if onSubmit is not given
    return (
      <div className={formClass('footer')}>
        {customButton}
        <div className={formClass('buttons')}>
          <Submit disabled={(!ignorePristine && pristine) || invalid} rights={rights} className={formClass('submit')} />
          <Reset disabled={pristine} className={formClass('reset')} />
        </div>
      </div>
    );
  };

  renderForm = ({ handleSubmit, pristine, invalid, form }) => {
    const { useBottomButtons, useTopButtons, name, onChange, inModal, closeModal, loading, modalSize, isModalOpen } =
      this.props;

    // since form in modal can be a quite frequent occurrence the layout is different
    if (inModal) {
      return (
        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          size={modalSize}
          backdrop={pristine ? true : 'static'}
          keyboard={pristine}
        >
          <form onSubmit={handleSubmit} onReset={form.reset} className={formClass()}>
            {name ? (
              <ModalHeader toggle={closeModal} className={formClass('modal-header')}>
                {name}
              </ModalHeader>
            ) : null}
            <ModalBody className={formClass('modal-body')}>{this.props.children}</ModalBody>
            <ModalFooter className={formClass('modal-footer')}>{this.renderButtons(pristine, invalid)}</ModalFooter>
            <FormSpy subscription={{ values: true, pristine: true, invalid: true }} onChange={onChange} />
            {loading ? (
              <div className={formClass('overlay')}>
                <Spinner color="primary" />
              </div>
            ) : null}
          </form>
        </Modal>
      );
    }

    return (
      <form onSubmit={handleSubmit} onReset={form.reset} className={formClass()}>
        {name ? <h4>{name}</h4> : null}
        {useTopButtons ? this.renderButtons(pristine, invalid) : null}

        {this.props.children}
        {useBottomButtons ? this.renderButtons(pristine, invalid) : null}
        <FormSpy subscription={{ values: true, pristine: true, invalid: true }} onChange={onChange} />
        {loading ? (
          <div className={formClass('overlay')}>
            <Spinner color="primary" />
          </div>
        ) : null}
      </form>
    );
  };

  render() {
    const { onSubmit, validate, initialValues, shadowValues, preview } = this.props;

    // subscription needs to be set up to improve performance on larger forms
    return (
      <FormContext.Provider value={{ preview, shadowValues }}>
        <FinalForm
          mutators={formMutators}
          subscription={{ submitting: true, pristine: true, invalid: true }}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={this.renderForm}
        />
      </FormContext.Provider>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.shape({}), // controlled, will use onChange
  children: PropTypes.node.isRequired, // Form definition
  validate: PropTypes.func,
  useTopButtons: PropTypes.bool,
  useBottomButtons: PropTypes.bool,
  ignorePristine: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  rights: PropTypes.arrayOf(PropTypes.string),
  inModal: PropTypes.bool,
  customButton: PropTypes.node,
  closeModal: PropTypes.func, // only used if in modal to pass modal close function
  shadowValues: SHADOW_VALUES_TYPE,
  preview: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  modalSize: PropTypes.string
};

Form.defaultProps = {
  onSubmit: dummySubmit,
  onChange: dummySubmit,
  initialValues: {},
  name: '',
  validate: () => true,
  useTopButtons: false,
  useBottomButtons: true,
  ignorePristine: false,
  loading: false,
  rights: [],
  inModal: false,
  closeModal: () => null,
  customButton: null,
  shadowValues: null,
  preview: false,
  isModalOpen: false,
  modalSize: 'lg'
};

export default Form;
