import { useCallback, useMemo } from 'react';
import b from 'b_';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Icon, Nav, NavItem, NavLink } from '../Atoms';
import { useUserRights } from '../../utils/hooks';
import { PROTECTED_ROUTES } from '../RouteList';
import { useUiSettings } from '../../store/modules/uiSettings';
import { useWindowSize } from '../../modules/ui/uiHooks';

import './Navigation.scss';

const navigationClassName = b.with('navigation');

interface NavigationProps {
  opened: boolean;
}

const Navigation = ({ opened }: NavigationProps) => {
  const hasRights = useUserRights();

  const { closeSidebar } = useUiSettings();

  const { xs } = useWindowSize();

  const handleClick = useCallback(() => {
    if (xs) {
      closeSidebar();
    }
  }, [closeSidebar, xs]);

  const filteredRoutes = useMemo(
    () =>
      PROTECTED_ROUTES.map(group => ({
        ...group,
        links: group.links.filter(link => link.nav && hasRights(link.rights))
      })).filter(group => group.links.length !== 0),
    [hasRights]
  );

  return (
    <Nav tag="nav" vertical className={`${navigationClassName()} text-dark`}>
      {filteredRoutes.map((group, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          {i === 0 ? null : (
            <span className={navigationClassName('group')}>
              {opened ? <small className={navigationClassName('group-name')}>{group.name}</small> : null}
            </span>
          )}

          {group.links
            .filter(link => hasRights(link.rights))
            .map(link => (
              <NavItem key={link.path}>
                <NavLink
                  tag={RRNavLink}
                  to={link.path}
                  className={`${navigationClassName('nav-link')} text-dark`}
                  onClick={handleClick}
                  activeClassName="active"
                  exact
                >
                  <div className={navigationClassName('indicator')} style={{ backgroundColor: group.color }} />
                  <div className={navigationClassName('icon')} style={{ color: group.color }} title={link.name}>
                    {link.icon ? <Icon type={link.icon} scale={1.3} /> : null}
                  </div>
                  {opened ? (
                    <div className="d-flex flex-column justify-content-center ms-3">
                      <u>{link.name}</u>
                      {link.description ? <small className="text-muted">{link.description}</small> : null}
                    </div>
                  ) : null}
                </NavLink>
              </NavItem>
            ))}
        </div>
      ))}
    </Nav>
  );
};

export default Navigation;
