import { useController } from 'react-hook-form';
import { SyntheticEvent, useCallback } from 'react';
import { ButtonGroupProps, DefaultOptionType, FieldType } from '../../types';
import { getConvertedRegisterOptions } from '../helpers';
import FieldWrapper from '../FieldWrapper';

const ButtonGroup = <T, K extends DefaultOptionType = DefaultOptionType>({
  name,
  description,
  label,
  required,
  options = [],
  getOptionValue = (option?: any) => `${option?.value}`,
  getOptionLabel = (option?: any) => `${option?.label}`,
  disabled
}: ButtonGroupProps<T, K>) => {
  const { field, fieldState } = useController<T>({
    name,
    rules: getConvertedRegisterOptions({ required: Boolean(required) })
  });
  const fieldError: any = fieldState.error;

  const handleChange = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const { value } = e.currentTarget.dataset;

      field.onChange(value);
    },
    [field]
  );

  return (
    <FieldWrapper
      type={FieldType.BUTTON_GROUP}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <div className="btn-group d-flex" role="group" aria-label="Basic example">
        {options.map(option => (
          <button
            key={getOptionValue(option)}
            type="button"
            className={`btn btn-${field.value === getOptionValue(option) ? '' : 'outline-'}secondary`}
            data-value={getOptionValue(option)}
            disabled={disabled}
            onClick={handleChange}
          >
            {getOptionLabel(option)}
          </button>
        ))}
      </div>
    </FieldWrapper>
  );
};

export default ButtonGroup;
