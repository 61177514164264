import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { partsSelectors } from '../../../../../../modules/parts';
import Field from '../../../../../Form/Field';

/** Helper component to generate control fix values based on selected control name. */
const ControlOptionFix = ({ input, seedId, parentName }) => {
  const { value = '' } = input;
  const controls = useSelector(state => partsSelectors.selectControlListForSuggestions(state, seedId));

  const options = useMemo(() => controls.find(control => control.name === value)?.list || [], [controls, value]);

  return <Field.Select name={`${parentName}.value`} label="Value" options={options} creatable clearable required />;
};

ControlOptionFix.propTypes = {
  // eslint-disable-next-line react/require-default-props
  input: PropTypes.shape({
    value: PropTypes.string
  }).isRequired,
  seedId: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired // is generated by array-object
};

export default ControlOptionFix;
