import { AckeeInstance, create } from 'ackee-tracker';
import { ACKEE_DOMAIN_ID, ACKEE_SERVER } from '../config';

class AckeeTracker {
  server: string;

  domainId: string;

  instance: AckeeInstance;

  constructor(server: string, domainId: string) {
    this.server = server;
    this.domainId = domainId;

    this.instance = create(this.server, {
      detailed: true
    });
  }

  record() {
    return this.instance.record(this.domainId);
  }
}

const fake = {
  record: () => ({ stop: () => null }),
  updateRecord: () => ({ stop: () => null }),
  action: () => null,
  updateAction: () => null
};

const ackeeTracker = ACKEE_SERVER && ACKEE_DOMAIN_ID ? new AckeeTracker(ACKEE_SERVER, ACKEE_DOMAIN_ID) : fake;

export default ackeeTracker;
