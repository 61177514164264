import { actions } from '../@helpers';

const MODULE_NAME = 'ROLES';
const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const rolesActions = {
  FETCH_ROLES: moduleActions.fetchList.ACTION_TYPE,
  CREATE_ROLE: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_ROLE: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_ROLE: moduleActions.deleteEntity.ACTION_TYPE,

  fetchRoles: moduleActions.fetchList,
  createRole: moduleActions.createEntity,
  updateRole: moduleActions.updateEntity,
  deleteRole: moduleActions.deleteEntity,

  ...moduleActions
};

export default rolesActions;
