import { useUpdateSeed } from '../../store/seed';
import SeedPageWrapper from './SeedPageWrapper';
import SeedInfoForm from './SeedInfoForm';

const SeedInfoPage = () => {
  const { updateSeedMainScope } = useUpdateSeed();

  return <SeedPageWrapper>{seed => <SeedInfoForm seed={seed} onSubmit={updateSeedMainScope} />}</SeedPageWrapper>;
};

export default SeedInfoPage;
