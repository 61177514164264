import { actions } from '../@helpers';

const MODULE_NAME = 'FACTORY_MAPS';

const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

const setPublishedFlag = actions.createAsyncActionCreator(
  actions.createModulePrefixForActionType(MODULE_NAME, 'SET_PUBLISHED_FLAG'),
  { base: type => (factoryId, published) => ({ type, payload: { factoryId, published } }) }
);

const factoryMapsActions = {
  FETCH_FACTORY_MAPS: moduleActions.fetchList.ACTION_TYPE,
  CREATE_FACTORY_MAP: moduleActions.createEntity.ACTION_TYPE,
  UPDATE_FACTORY_MAP: moduleActions.updateEntity.ACTION_TYPE,
  DELETE_FACTORY_MAP: moduleActions.deleteEntity.ACTION_TYPE,
  SET_PUBLISHED_FLAG: setPublishedFlag.ACTION_TYPE,

  fetchFactoryMaps: moduleActions.fetchList,
  createFactoryMap: moduleActions.createEntity,
  updateFactoryMap: moduleActions.updateEntity,
  deleteFactoryMap: moduleActions.deleteEntity,
  setPublishedFlag,

  ...moduleActions
};

export default factoryMapsActions;
