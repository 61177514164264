import { createSelector } from 'reselect';

export const selectVisitorListExpanded = state => state.ui.visitors.expanded;
export const selectVisitorListSelectedInstance = (state, id) => state.ui.visitors.selected[id];
export const selectNotifications = state => state.ui.notifications;

export const selectVisibleNotifications = createSelector([selectNotifications], notifications =>
  notifications.filter(notification => !notification.hidden)
);

export const selectWindowSize = state => state.ui.windowSize;
