import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormFeedback, CodeEditor } from '../../../Atoms';
import FieldWrapper from '../FieldWrapper';

class Code extends React.PureComponent {
  renderEditor = ({ input, meta }) => {
    const { mode, parse } = this.props;

    return (
      <>
        <CodeEditor
          mode={mode}
          name={input.name}
          value={input.value}
          onChange={value => {
            if (parse(value) !== undefined) {
              input.onChange(value);
            }
          }}
        />
        {meta.error ? <FormFeedback>{meta.error}</FormFeedback> : null}
      </>
    );
  };

  render() {
    const { name, format, parse } = this.props;

    return (
      <FieldWrapper {...this.props}>
        <Field name={name} render={this.renderEditor} format={format} parse={parse} />
      </FieldWrapper>
    );
  }
}

Code.propTypes = {
  mode: PropTypes.string,
  name: PropTypes.string.isRequired,
  format: PropTypes.func,
  parse: PropTypes.func
};
Code.defaultProps = {
  mode: 'json',
  format: i => i, // identity
  parse: i => i
};

export default Code;
