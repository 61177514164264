import { useCallback, useState } from 'react';
import b from 'b_';
import { InstanceType } from 'types';
import { useDispatch } from 'react-redux';
import { RIGHTS } from '../../../../utils/rights';
import ProtectedButton from '../../../ProtectedButton';
import { instancesActions } from '../../../../modules/instances';
import ConfirmSendToProduction from '../MappedInstance/ConfirmSendToProduction';
import './SendToProduction.scss';

const sendToProduction = b.with('send-to-production');

interface ActionsProps {
  isLeadCancelled?: boolean;
  isLeadSent?: boolean;
  onSendToProduction: () => void;
  onCancelOrder: () => void;
}
const Actions = ({ isLeadCancelled, isLeadSent, onSendToProduction, onCancelOrder }: ActionsProps) => {
  if (isLeadCancelled) {
    return null;
  }

  if (isLeadSent) {
    return (
      <ProtectedButton
        rights={[RIGHTS.VISITORS__CANCEL_ORDER]}
        onClick={onCancelOrder}
        size="sm"
        color="danger"
        outline
      >
        <i className="fa fa-fw fa-ban" /> Cancel order
      </ProtectedButton>
    );
  }

  return (
    <ProtectedButton
      rights={[RIGHTS.VISITORS__SEND_TO_PRODUCTION]}
      onClick={onSendToProduction}
      size="sm"
      color="primary"
      outline
    >
      <i className="fa fa-fw fa-paper-plane-o" /> Send to production
    </ProtectedButton>
  );
};

const Details = ({
  sentToProductionAt,
  cancelOrderAt,
  isLeadCancelled,
  isLeadSent
}: {
  isLeadCancelled?: boolean;
  sentToProductionAt?: string;
  cancelOrderAt?: string;
  isLeadSent?: boolean;
}) => {
  if (!(isLeadSent && sentToProductionAt)) return null;

  return (
    <div className={sendToProduction('details')}>
      <dl className="d-flex flex-wrap mb-0">
        <dt className={sendToProduction('label')}>Sent at</dt>
        <dd className={sendToProduction('info')}>{new Date(sentToProductionAt).toLocaleString()}</dd>
        {isLeadCancelled && cancelOrderAt ? (
          <>
            <dt className={sendToProduction('label')}>Cancel at</dt>
            <dd className={sendToProduction('info')}>{new Date(cancelOrderAt).toLocaleString()}</dd>
          </>
        ) : null}
      </dl>
    </div>
  );
};

const SendToProduction = ({ instance }: { instance: InstanceType }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dispatch = useDispatch();

  const handleCancelOrder = useCallback(() => {
    const response = window.confirm('Are you sure that you want cancel the order?'); // eslint-disable-line no-alert

    if (response) {
      dispatch(instancesActions.cancelOrder(instance._id));
    }
  }, [dispatch, instance._id]);

  const handleSend = useCallback(() => {
    dispatch(instancesActions.sendToProduction(instance._id));
    setShowConfirmModal(false);
  }, [dispatch, instance._id]);

  return (
    <div className={sendToProduction()}>
      <h5 className="text-primary">Production</h5>
      <Actions
        isLeadCancelled={instance.lead?.cancelOrder}
        isLeadSent={instance.lead?.sentToProduction}
        onSendToProduction={() => setShowConfirmModal(true)}
        onCancelOrder={handleCancelOrder}
      />
      <Details
        isLeadSent={instance.lead?.sentToProduction}
        isLeadCancelled={instance.lead?.cancelOrder}
        cancelOrderAt={instance.lead?.cancelOrderAt}
        sentToProductionAt={instance.lead?.sentToProductionAt}
      />
      {showConfirmModal ? (
        <ConfirmSendToProduction
          onClose={() => setShowConfirmModal(false)}
          onContinue={handleSend}
          hasMappedInstance={!!instance.mappedInstanceId}
          hasFactoryMap={!!instance.factoryMap}
        />
      ) : null}
    </div>
  );
};

export default SendToProduction;
