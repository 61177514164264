import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spinner } from '../Atoms';
import Loader from '../Loader';
import { usePartList } from '../../utils/hooks';
import { partsActions, partsSelectors } from '../../modules/parts';
import SortableWrapper from '../Form/SortableWrapper';
import getPartFieldsToUpdate from '../../utils/getPartFieldsToUpdate';
import TabForm from './TabForm';
import AddTabButton from './AddTabButton';

const TabsPage = () => {
  const dispatch = useDispatch();
  const { seedId } = useParams();
  const stateTabs = useSelector(state => partsSelectors.selectTabs(state, seedId));
  const [tabs, setTabs] = useState(stateTabs);

  useEffect(() => {
    setTabs(stateTabs);
  }, [stateTabs]);

  const { request, parts } = usePartList(seedId);
  const updatingParts = useSelector(partsSelectors.selectPartsUpdatingFlag);

  const handleSortTabs = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }
      const newTabs = [...tabs];

      newTabs.splice(newIndex, 0, newTabs.splice(oldIndex, 1)[0]);

      const modifiedParts = {};

      newTabs.forEach((tab, tabIndex) => {
        const newTab = { ...tab.definition, tabIndex };

        const part = modifiedParts[tab.partId] || parts.find(p => p._id === tab.partId);

        const modifiedPart = { ...part };

        if (Array.isArray(modifiedPart.Phases?.Object)) {
          const index = modifiedPart.Phases.Object.findIndex(phase => phase.name === tab.name);

          if (index !== -1) {
            modifiedPart.Phases.Object[index] = newTab;
            modifiedParts[modifiedPart._id] = modifiedPart;
          }
        }
      });

      const modifiedPartsArray = Object.values(modifiedParts).map(part => getPartFieldsToUpdate(part, ['Phases']));

      if (modifiedPartsArray.length) {
        dispatch(partsActions.updateParts(seedId, modifiedPartsArray));
        setTabs(newTabs);
      }
    },
    [dispatch, parts, seedId, tabs]
  );

  return (
    <div className="w-100 p-3">
      <Loader loading={request}>
        <Alert color="info">
          Here you can change and reorder tabs. You can also change the parent part of tabs. Tabs with parent other than
          the root part will be loaded only if the part is loaded. Non-root tabs are marked with a small offset
        </Alert>
        <SortableWrapper useDragHandle fluid onSortEnd={handleSortTabs}>
          {tabs.map((tab, tabIndex) => (
            <TabForm tab={tab} index={tabIndex} key={`${tab.name}_${tab.partId}`} seedId={seedId} />
          ))}
        </SortableWrapper>
        <AddTabButton seedId={seedId} tabsLength={tabs.length} />
      </Loader>
      {updatingParts ? (
        <div className="app-overlay">
          <Spinner color="primary" />
        </div>
      ) : null}
    </div>
  );
};

export default TabsPage;
