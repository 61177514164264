import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import settingsActions from './settingsActions';

export function* doFetchGlobalSettingsSaga() {
  yield call(sagas.doBaseAsyncSaga, settingsActions.fetchGlobalSettings, api.settings.getGlobalSettings);
}

export function* watchFetchGlobalSettingsSaga() {
  yield takeLatest(settingsActions.fetchGlobalSettings.ACTION_TYPE, doFetchGlobalSettingsSaga);
}

export function* doFetchGlobalThemeSaga() {
  yield call(sagas.doBaseAsyncSaga, settingsActions.fetchGlobalTheme, api.settings.getGlobalTheme);
}

export function* watchFetchGlobalThemeSaga() {
  yield takeLatest(settingsActions.fetchGlobalTheme.ACTION_TYPE, doFetchGlobalThemeSaga);
}

export function* doUpdateGlobalSettingsSaga({ payload }) {
  yield call(
    sagas.doBaseAsyncSaga,
    settingsActions.updateGlobalSettings,
    api.settings.updateGlobalSettings,
    payload.settings
  );
}

export function* watchUpdateGlobalSettingsSaga() {
  yield takeLatest(settingsActions.updateGlobalSettings.ACTION_TYPE, doUpdateGlobalSettingsSaga);
}

export function* doUpdateGlobalThemeSaga({ payload }) {
  yield call(sagas.doBaseAsyncSaga, settingsActions.updateGlobalTheme, api.settings.updateGlobalTheme, payload.theme);
}

export function* watchUpdateGlobalThemeSaga() {
  yield takeLatest(settingsActions.updateGlobalTheme.ACTION_TYPE, doUpdateGlobalThemeSaga);
}

export function* doParseGlobalSettingsSaga(action) {
  const raw = action.payload;

  try {
    const value = JSON.stringify(raw, undefined, 2);

    yield put(settingsActions.parseGlobalSettingsSuccess(raw, value));
  } catch (e) {
    yield put(settingsActions.parseGlobalSettingsFailure(e.message));
  }
}

export function* watchParseGlobalSettingsSaga() {
  yield takeLatest(
    [settingsActions.fetchGlobalSettings.success.ACTION_TYPE, settingsActions.updateGlobalSettings.success.ACTION_TYPE],
    doParseGlobalSettingsSaga
  );
}

export function* doParseGlobalThemeSaga(action) {
  const raw = action.payload;

  try {
    const value = window.atob(raw);

    yield put(settingsActions.parseGlobalThemeSuccess(raw, value));
  } catch (e) {
    yield put(settingsActions.parseGlobalThemeFailure(e.message));
  }
}

export function* watchParseGlobalThemeSaga() {
  yield takeLatest(
    [settingsActions.fetchGlobalTheme.success.ACTION_TYPE, settingsActions.updateGlobalTheme.success.ACTION_TYPE],
    doParseGlobalThemeSaga
  );
}

export default function* moduleSaga() {
  yield all([
    watchFetchGlobalSettingsSaga(),
    watchFetchGlobalThemeSaga(),
    watchUpdateGlobalSettingsSaga(),
    watchUpdateGlobalThemeSaga(),
    watchParseGlobalSettingsSaga(),
    watchParseGlobalThemeSaga()
  ]);
}
