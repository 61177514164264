import { createSelector } from 'reselect';
import { RIGHTS } from '../../utils/rights';
import { CONFIGURATOR_URL, IS_PRODUCTION } from '../../config';

export const selectUser = state => state.auth.user;
export const selectAuthError = state => state.auth.error;
export const selectAuthRequest = state => state.auth.request;
export const selectEmailSent = state => state.auth.emailSent;
export const selectPasswordChanged = state => state.auth.passwordChanged;
export const selectTokenValid = state => state.auth.token.valid;
export const selectTokenChecked = state => state.auth.token.checked;
export const selectInvitationValid = state => state.auth.invitation.valid;
export const selectInvitationChecked = state => state.auth.invitation.checked;
export const selectInvitationValue = state => state.auth.invitation.value;

export const selectUserInfoReceived = state => state.auth.userInfoReceived;

export const selectLoggedIn = createSelector([selectUser], user => user && Boolean(user.uid));

export const selectUserName = createSelector([selectUser], user => (user ? user.email : ''));

export const selectUserRights = createSelector([selectUser], user => (user ? user.rights : []));

export const selectUserProjects = createSelector([selectUser], user => (user ? user.projectIds : []));

export const selectIsSuperUser = createSelector([selectUserRights], rights => rights.includes(RIGHTS.SUPERUSER));

export const selectUserHasPartner = createSelector([selectUser], (user = {}) => Boolean(user.partner));

export const selectUserPartnerId = createSelector([selectUser], (user = {}) => (user.partner ? user.partner._id : ''));

export const selectUserConfiguratorUrl = createSelector(
  [selectUser],
  user => (IS_PRODUCTION && user?.partner?.adminSettings?.configuratorHost) || CONFIGURATOR_URL
);

export const selectUserRequestsShowPlot = createSelector([selectUser], user =>
  user && user.partner ? user.partner.adminSettings.requestsShowPlot : false
);

export const selectUserRequestsShowReservationFee = createSelector([selectUser], user =>
  user && user.partner ? user.partner.adminSettings.requestsShowReservationFee : false
);

export const selectUserRequestsShowSendToProduction = createSelector([selectUser], user =>
  user && user.partner ? user.partner.adminSettings.requestsShowSendToProduction : false
);

export const selectUserIsLegaleSignAvailable = createSelector([selectUser], user =>
  user && user.partner ? user.partner.adminSettings.isLegaleSignAvailable : false
);

export const selectIsIntegrationStatusVisible = createSelector(
  [selectIsSuperUser, selectUser],
  (isSuperUser, user) => isSuperUser || (user && user.partner && user.partner.integrationType)
);

export const selectIsTermsOfUseAccepted = createSelector([selectUser], user => Boolean(user?.acceptedTermsOfUse));
