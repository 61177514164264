import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field as FFField } from 'react-final-form';
import { Alert } from '../../../../Atoms';
import Form from '../../../../Form';
import Field from '../../../../Form/Field';
import WhenFieldChange from '../../../../Form/WhenFieldChange';
import { TYPE_PART } from '../../../../../utils/propTypes';
import { RIGHTS } from '../../../../../utils/rights';
import { partsActions, partsSelectors } from '../../../../../modules/parts';
import { seedsSelectors } from '../../../../../modules/seeds';
import ProtectedButton from '../../../../ProtectedButton';
import { useAssetsActions } from '../../../../../utils/hooks';
import { useSeed, useSeedListByProjectForSelect } from '../../../../../store/seed';
import ControlOptionFix from './ControlOptionFix';

const TYPES_LIST = [
  {
    label: 'toggle',
    value: 'toggle'
  },
  {
    label: 'dropdown',
    value: 'dropdown'
  },
  {
    label: 'column',
    value: 'column'
  },
  {
    label: 'twoColumn',
    value: 'twoColumn'
  },
  {
    label: 'threeColumn',
    value: 'threeColumn'
  },
  {
    label: 'slider',
    value: 'slider'
  },
  {
    label: 'option',
    value: 'option'
  },
  {
    label: 'controlMaterials',
    value: 'controlMaterials'
  },
  {
    label: 'controlImages',
    value: 'controlImages'
  },
  {
    label: 'bulkMaterials',
    value: 'bulkMaterials'
  },
  {
    label: 'varSelectedControl',
    value: 'varSelectedControl'
  },
  {
    label: 'varSelectedTab',
    value: 'varSelectedTab'
  },
  {
    label: 'varSelectedView',
    value: 'varSelectedView'
  },
  {
    label: 'varPriceScheme',
    value: 'varPriceScheme'
  },
  {
    label: 'empty',
    value: 'empty'
  }
];

const TAGS_MODES = [
  {
    label: 'Checkbox',
    value: 'checkbox'
  },
  {
    label: 'Radio',
    value: 'radio'
  }
];

const formatFix = (arr = [], isOptionStatusFix) =>
  arr.reduce((fix, { key, value, optionStatus }) => {
    if (isOptionStatusFix) {
      fix[key] = fix[key] || []; // eslint-disable-line no-param-reassign

      fix[key].push({ value, optionStatus });
    } else {
      fix[key] = value; // eslint-disable-line no-param-reassign
    }

    return fix;
  }, {});

const parseFix = (obj = {}, isOptionStatusFix) => {
  const result = [];

  Object.keys(obj).forEach(key => {
    if (isOptionStatusFix) {
      (obj[key] || []).forEach(({ value, optionStatus }) => {
        result.push({ key, value, optionStatus });
      });

      return;
    }

    result.push({ key, value: obj[key] });
  });

  return result;
};

const formatNames = (values = []) => values.map(value => value.name);

const parseNames = (values = []) => values.map(value => ({ name: value }));

const ControlForm = ({ part, controlIndex, close, onSubmit, projectId }) => {
  const [control, setControl] = useState(part.Controls.Object[controlIndex] || {});

  const dispatch = useDispatch();
  const success = useSelector(partsSelectors.selectPartsFlag);
  const referenceNames = useSelector(state => partsSelectors.selectPartsReferenceNames(state, part.seed));
  /** List of all controls defined for this seed */
  const controls = useSelector(state => partsSelectors.selectControlListForSuggestions(state, part.seed));
  const views = useSelector(state => partsSelectors.selectViews(state, part.seed));
  const extraInfoCollections = useSelector(state => seedsSelectors.selectExtraInfoCollectionsNames(state, part.seed));
  const priceSchemeSuggestions = useSelector(state => seedsSelectors.selectPriceSchemesSuggestions(state, part.seed));
  const selectPossibleOptionNamesSelector = useMemo(
    () => partsSelectors.selectPossibleOptionNames(control.parameter || control.name),
    [control.name, control.parameter]
  );
  const controlOptionNames = useSelector(state => selectPossibleOptionNamesSelector(state, part.seed));
  const priceCodes = useSelector(state => seedsSelectors.selectSeedPriceCodes(state, part.seed));
  const optionStatusList = useSelector(state => seedsSelectors.selectOptionStatusList(state, part.seed));
  const materials = useSelector(state => partsSelectors.selectMaterialsForPreview(state, part.seed));
  const phaseNames = useSelector(state => partsSelectors.selectTabNamesForSuggestions(state, part.seed));

  const { seeds: seedIdsForSuggestions } = useSeedListByProjectForSelect(projectId);

  const existingInteractionGroups = useSelector(state =>
    partsSelectors.selectExistingInteractionGroupsForSuggestions(state, part.seed)
  );

  const { projectImages } = useAssetsActions(projectId);
  const { seed = {} } = useSeed(part.seed);
  const { remoteControls = [], remoteSwitches = [] } = seed;
  const remoteControl = useMemo(() => {
    const index = remoteControls.findIndex(({ name }) => name === control.name);

    return remoteControls[index];
  }, [control.name, remoteControls]);
  const remoteSwitch = useMemo(() => {
    const index = remoteSwitches.findIndex(({ name }) => name === control.name);

    return remoteSwitches[index];
  }, [control.name, remoteSwitches]);

  const shadowValues = useMemo(() => {
    const value = { Controls: { Object: [] } };

    value.Controls.Object[controlIndex] = control.remoteSwitchEnabled ? remoteSwitch : remoteControl;

    return {
      list: [
        {
          name: 'current'
        },
        {
          name: control.remoteSwitchEnabled ? 'remoteSwitch' : 'remoteControl',
          value
        }
      ]
    };
  }, [control.remoteSwitchEnabled, controlIndex, remoteControl, remoteSwitch]);

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line no-alert
    const response = window.confirm(`Are you sure that you want to delete control?`);

    if (response) {
      const result = { ...part, Controls: { ...part.Controls, Object: [...part.Controls.Object] } };

      result.Controls.Object.splice(controlIndex, 1);

      onSubmit(result);
    }
  }, [controlIndex, onSubmit, part]);

  const handleSubmit = useCallback(
    formValue => {
      const result = { ...formValue };

      if (result.Controls.Object[controlIndex]?.list) {
        result.Controls.Object[controlIndex].list = formValue.Controls.Object[controlIndex]?.list.map(item => ({
          ...item,
          fix: formatFix(item.fix),
          optionStatusFix: formatFix(item.optionStatusFix, true)
        }));
      }

      onSubmit(result);
    },
    [controlIndex, onSubmit]
  );
  const handleChangeForm = useCallback(
    form => {
      setControl(form.values.Controls.Object[controlIndex]);
    },
    [controlIndex]
  );
  const optionNames = useMemo(() => {
    return (control.list || control.option || [])
      .map(item => item.name)
      .filter(Boolean)
      .map(value => ({ value, label: value }));
  }, [control.list, control.option]);
  const tags = useMemo(() => (control.tags?.list || []).map(value => ({ value, label: value })), [control.tags]);

  useEffect(() => {
    if (success) {
      dispatch(partsActions.resetFlags());
      close();
    }
  }, [close, dispatch, success]);
  const deleteBtn = (
    <ProtectedButton
      onClick={handleDelete}
      color="danger"
      outline
      className="pull-right"
      rights={[RIGHTS.PARTS__UPDATE]}
    >
      <i className="fa fa-trash fa-fw" />
      Delete control
    </ProtectedButton>
  );
  const parsedPart = useMemo(() => {
    const result = { ...part, Controls: { ...part.Controls, Object: [...part.Controls.Object] } };

    if (!result.Controls.Object[controlIndex]) {
      result.Controls.Object[controlIndex] = { type: 'option' };

      return result;
    }

    result.Controls.Object[controlIndex] = { ...part.Controls.Object[controlIndex] };

    const { list: oldList = [] } = part.Controls.Object[controlIndex];

    result.Controls.Object[controlIndex].list = oldList.map(item => {
      const response = { ...item };

      response.fix = parseFix(item.fix);
      response.optionStatusFix = parseFix(item.optionStatusFix, true);

      return response;
    });

    return result;
  }, [controlIndex, part]);

  return (
    <Form
      initialValues={parsedPart}
      onSubmit={handleSubmit}
      inModal
      name={`${part.ReferenceName}:${part.Option} - Control`}
      closeModal={close}
      rights={[RIGHTS.PARTS__UPDATE]}
      customButton={deleteBtn}
      onChange={handleChangeForm}
      shadowValues={shadowValues}
      isModalOpen
    >
      <Field.Select
        name={`Controls.Object[${controlIndex}].name`}
        label="Name"
        required
        creatable
        options={referenceNames}
        description="Pick from list of part references or type in custom name"
      />
      <Field.Text
        name={`Controls.Object[${controlIndex}].displayName`}
        label="Display name"
        guide={`Controls.Object[${controlIndex}].name`}
        useShadowValue={control.remoteControlEnabled}
      />
      <Field.Select name={`Controls.Object[${controlIndex}].type`} label="Type" clearable options={TYPES_LIST} />
      <FFField
        name={`Controls.Object[${controlIndex}].type`}
        render={({ input }) => {
          if (input.value === 'empty') {
            return (
              <>
                <Field.Toggle
                  name={`Controls.Object[${controlIndex}].hideFromPreferences`}
                  label="Hide from preferences"
                />
                <Field.Markdown name={`Controls.Object[${controlIndex}].body`} label="Content" />
              </>
            );
          }

          return (
            <>
              <Field.Text
                name={`Controls.Object[${controlIndex}].parameter`}
                label="Parameter"
                description="Optional parameter name to use instead of control name defined above"
              />
              <Field.Text
                name={`Controls.Object[${controlIndex}].description`}
                label="Description"
                description="Description to show for control"
                useShadowValue={control.remoteControlEnabled}
              />

              <Field.Toggle
                name={`Controls.Object[${controlIndex}].disabled`}
                label="Disable"
                description="Removes control from Configurator"
              />
              <Field.Toggle
                name={`Controls.Object[${controlIndex}].remoteControlEnabled`}
                label="Enable remote control"
                description="Enables overriding this control's options in Admin"
              />
              <Field.Toggle
                name={`Controls.Object[${controlIndex}].remoteSwitchEnabled`}
                label="Enable remote switch"
                description="Enables overriding control value in Admin"
              />
              <WhenFieldChange
                field={`Controls.Object[${controlIndex}].remoteSwitchEnabled`}
                set={`Controls.Object[${controlIndex}].remoteControlEnabled`}
                becomes
                to={
                  parsedPart.Controls.Object[controlIndex].remoteControlEnabled === undefined
                    ? parsedPart.Controls.Object[controlIndex].remoteControlEnabled
                    : false
                }
              />
              <WhenFieldChange
                field={`Controls.Object[${controlIndex}].remoteControlEnabled`}
                set={`Controls.Object[${controlIndex}].remoteSwitchEnabled`}
                becomes
                to={
                  parsedPart.Controls.Object[controlIndex].remoteSwitchEnabled === undefined
                    ? parsedPart.Controls.Object[controlIndex].remoteSwitchEnabled
                    : false
                }
              />
              <Field.Toggle
                name={`Controls.Object[${controlIndex}].bindExtraInfo`}
                label="Controlled extra info"
                description="If extra info is defined, selecting options from this control open and close extra info automatically"
              />
              <Field.Toggle
                name={`Controls.Object[${controlIndex}].enableCollapse`}
                label="Make control collapsible"
                description="Control options can be shown by pressing a button"
              />
              <Field.Toggle
                name={`Controls.Object[${controlIndex}].isTreeNameStatic`}
                label="Use static treeName"
                description="Using static treeName will keep the value the same for all controls with same name wherever they are defined in the part tree"
              />
              <h4>Settings</h4>
              <Field.ArrayObject name={`Controls.Object[${controlIndex}].list`} label="Options">
                <Field.Select name="name" label="Name" required creatable options={controlOptionNames} />
                <Field.Text
                  name="displayName"
                  guide="name"
                  label="Display name"
                  useShadowValue={control.remoteControlEnabled}
                />
                <Field.Text name="optionHint" label="Option hint" useShadowValue={control.remoteControlEnabled} />
                <Field.Select
                  name="extraInfoCollection"
                  label="Extra info collection"
                  options={extraInfoCollections}
                  clearable
                />
                <Field.Number name="price" label="Price" allowEmpty useShadowValue={control.remoteControlEnabled} />
                <Field.Select name="priceCode" label="Price code" options={priceCodes} clearable creatable />
                <Field.Select
                  name="priceScheme"
                  label="Price scheme"
                  options={priceSchemeSuggestions}
                  clearable
                  creatable
                  description="Change price scheme with this option"
                />

                <Field.ArrayObject
                  name="fix"
                  label="Fix"
                  description="List of control names and values to fix if this option is selected."
                >
                  <Field.Select name="key" label="Control" options={controls} creatable clearable required />
                  <FFField name="key" component={ControlOptionFix} seedId={part.seed} />
                </Field.ArrayObject>
                <Field.ArrayObject
                  name="optionStatusFix"
                  label="Option status fix"
                  description="List of control names and values to fix if this option is selected."
                >
                  <Field.Select name="key" label="Control" options={controls} creatable clearable required />
                  <FFField name="key" component={ControlOptionFix} seedId={part.seed} />
                  <Field.Select name="optionStatus" label="Option status" options={optionStatusList} />
                </Field.ArrayObject>

                <Field.Image
                  name="image.fileName"
                  label="Image name"
                  description="Optional image to show if control type is 'controlImages'"
                  hideUpload
                  list={projectImages}
                  useShadowValue={control.remoteControlEnabled}
                />

                <Form.Group header="Material changes">
                  <Field.Number
                    name="price_psq"
                    label="Price per square unit"
                    allowEmpty
                    useShadowValue={control.remoteControlEnabled}
                    description="Used for material area based prices. Can be overridden per material. Ignored for local material changes."
                  />

                  <Field.Select
                    name="priceCode_psq"
                    label="Price code psq"
                    options={priceCodes}
                    clearable
                    creatable
                    description="Used for material area based prices. Can be overridden per material. Ignored for local material changes."
                  />
                  <Field.ArrayObject
                    name="material"
                    label="Change material"
                    description="Array of materials to change with this option"
                  >
                    <Field.Image
                      name="original"
                      label="Original material"
                      description="Material to be changed"
                      hideUpload
                      list={materials}
                    />
                    <Field.Image
                      name="apply"
                      label="Target material (apply)"
                      description="Material to change to"
                      hideUpload
                      list={materials}
                    />
                    <Field.Toggle
                      name="isLocal"
                      label="Local"
                      description="Propagate this material change only down the tree. This disables pricing for this material change."
                    />
                    <FFField name="isLocal">
                      {({ input: isLocalInput, parentName }) => {
                        if (!isLocalInput.value) {
                          return (
                            <>
                              <Field.Number
                                name={`${parentName}.price_psq`}
                                label="Price per area"
                                description="Overwrites options' price"
                                allowEmpty
                              />
                              <Field.Select
                                name={`${parentName}.priceCode_psq`}
                                label="Area price code"
                                description="Overwrites options' price code"
                                options={priceCodes}
                                clearable
                                creatable
                              />
                              <Field.Text
                                name={`${parentName}.layer`}
                                label="Layer"
                                description="Calculates price by area of all parts on given layer instead of area of given material "
                              />
                            </>
                          );
                        }

                        return <Alert color="warning">Pricing options are disabled if material is local.</Alert>;
                      }}
                    </FFField>
                  </Field.ArrayObject>
                </Form.Group>
                <Form.Group header="Option status">
                  <Field.Select
                    name="status"
                    label="Status"
                    description="If no options are available, please configure option statuses in settings first. If this is set, it will configure value:disabled, value:locked, value:inActive, value:priceHidden, value:areaHidden, value:statusDescription, value:forceReselect and value:statusLabel fields as defined in settings/optionStatuses"
                    options={optionStatusList}
                    useShadowValue={control.remoteControlEnabled}
                  />
                  <Field.Text
                    name="statusLabel"
                    label="Status label"
                    description="Label to show under option. This can be used to give more information about why the option has status that it has"
                  />
                  <Field.Text
                    name="statusDescription"
                    label="Status description"
                    description="Optional text to describe to client why they should change their options. Works if forceReselect is used"
                  />
                  <Field.Toggle
                    name="disabled"
                    label="Disable"
                    description="Removes Control option from Configurator"
                  />
                  <Field.Toggle
                    name="locked"
                    label="Lock"
                    description="Makes control option unselectable and greyed out"
                  />
                  <Field.Toggle
                    name="inactive"
                    label="Inactivate"
                    description="Inactive option is greyed out but can be selected if it is not 'locked'"
                  />
                  <Field.Toggle
                    name="forceReselect"
                    label="Force option reselect"
                    description="Guide user to reselect their choices if this option is disabled, locked or inactive"
                  />
                  <Field.Toggle
                    name="priceHidden"
                    label="Hide price"
                    description="Will hide price from user interface"
                  />
                  <Field.Toggle
                    name="areaHidden"
                    label="Hide net area"
                    description="Will hide net area from user interface"
                  />
                </Form.Group>
                <Field.MultiSelect name="tags" label="Tags" options={tags} />
              </Field.ArrayObject>
              <Field.Select
                name={`Controls.Object[${controlIndex}].default`}
                label="Default"
                required
                options={optionNames}
                description="Default value for this control. Is basis for calculating control value. Can be overwritten by remote controls or remote switches."
                useShadowValue={control.remoteControlEnabled || control.remoteSwitchEnabled}
              />
            </>
          );
        }}
      />
      <hr />
      <Form.Group header="Advanced features">
        <Field.Select
          name={`Controls.Object[${controlIndex}].view`}
          label="View"
          clearable
          options={views}
          description="Selecting an Option from this control will animate camera to given view. If view animation is disabled, transition will be instant"
        />
        <Form.Group header="Tags">
          <Field.MultiSelect
            name={`Controls.Object[${controlIndex}].tags.list`}
            label="Available tags"
            creatable
            clearable
          />
          <Field.MultiSelect
            name={`Controls.Object[${controlIndex}].tags.defaults`}
            label="Default tags"
            options={tags}
          />
          <Field.Select name={`Controls.Object[${controlIndex}].tags.mode`} label="Tags mode" options={TAGS_MODES} />
          <Field.Toggle name={`Controls.Object[${controlIndex}].tags.isAllTagVisible`} label="Show `All` tag" />
        </Form.Group>
        <Form.Group header="Child controls">
          <Field.ArrayObject name={`Controls.Object[${controlIndex}].childControls`} label="Child controls">
            <Field.Select name="name" label="Name" creatable clearable options={controls} />
            <Field.Toggle
              name="local"
              label="Local"
              description="Show this control only from children of current part"
            />
          </Field.ArrayObject>

          <Field.Text
            name={`Controls.Object[${controlIndex}].childTabName`}
            label="Child tab name"
            description="It will also be used as the button name"
          />
          <Field.Select
            name={`Controls.Object[${controlIndex}].childTabView`}
            label="Child tab view"
            description="View to set if this child tab is active."
            creatable
            clearable
            options={views}
          />
        </Form.Group>
        <Form.Group header="Instance loader">
          <p>
            Instance loader loads selections from another instance to the node tree. Option names correspond to instance
            ids which are added by default. If generateOptions is turned on, the rest of the options will be taken by
            user&apos;s saved instances.
          </p>
          <Field.Toggle
            name={`Controls.Object[${controlIndex}].instanceLoader.enabled`}
            label="Enable"
            description="Instance loader controls select which instance to load into node tree"
          />
          <Field.Toggle
            name={`Controls.Object[${controlIndex}].instanceLoader.generateOptions`}
            label="Generate options"
            description="Generate options based on user created instances"
          />
          <Field.Condition source={`Controls.Object[${controlIndex}].instanceLoader.generateOptions`}>
            <Field.Select
              name={`Controls.Object[${controlIndex}].instanceLoader.seedId`}
              required
              options={seedIdsForSuggestions}
              label="Child seedId"
              creatable
              clearable
              description="If generateOptions is true, instances from this seedId are loaded as dynamic option"
            />
            <Field.Image
              name={`Controls.Object[${controlIndex}].instanceLoader.optionImage.fileName`}
              label="Option image"
              hideUpload
              list={projectImages}
              description="Optional image to assign to generated options"
            />
          </Field.Condition>

          <Field.Toggle
            name={`Controls.Object[${controlIndex}].instanceLoader.enableNavigation`}
            label="Enable navigation"
            description="Enables navigation to child instance to change values"
          />
        </Form.Group>

        <Form.Group header="Interaction">
          <Field.Toggle name={`Controls.Object[${controlIndex}].interactive`} label="Enable interaction" />
          <Field.MultiSelect
            name={`Controls.Object[${controlIndex}].interactionGroups`}
            label="Interaction groups"
            description="Group names that this control belongs to"
            format={formatNames}
            parse={parseNames}
            creatable
            clearable
            options={existingInteractionGroups}
          />
          <Field.Select
            name={`Controls.Object[${controlIndex}].deleteValue`}
            label="Delete value"
            description="Option to change to if 'delete' button is pressed"
            options={optionNames}
            clearable
            creatable
          />
          <Field.Select
            name={`Controls.Object[${controlIndex}].closeTarget`}
            label="Close target"
            description="Phase name to switch to if this X button is clicked"
            options={phaseNames}
            creatable
            clearable
          />
          <hr />
          <Field.Toggle name={`Controls.Object[${controlIndex}].transform.enabled`} label="Enable transform" />

          <FFField name={`Controls.Object[${controlIndex}].transform.enabled`}>
            {({ input }) =>
              input.value ? (
                <>
                  <Field.Number
                    name={`Controls.Object[${controlIndex}].transform.translationStep`}
                    label="Translation step"
                  />
                  <Field.Toggle
                    name={`Controls.Object[${controlIndex}].transform.tX.enabled`}
                    label="X axis translation enabled"
                  />
                  <Field.Toggle
                    name={`Controls.Object[${controlIndex}].transform.tY.enabled`}
                    label="Y axis translation enabled"
                  />
                  <Field.Toggle
                    name={`Controls.Object[${controlIndex}].transform.tZ.enabled`}
                    label="Z axis translation enabled"
                  />
                  <hr />
                  <Field.Number
                    name={`Controls.Object[${controlIndex}].transform.rotationStep`}
                    label="Rotation step"
                    description="In degrees"
                  />
                  <Field.Toggle
                    name={`Controls.Object[${controlIndex}].transform.rX.enabled`}
                    label="X axis rotation enabled"
                  />
                  <Field.Toggle
                    name={`Controls.Object[${controlIndex}].transform.rY.enabled`}
                    label="Y axis rotation enabled"
                  />
                  <Field.Toggle
                    name={`Controls.Object[${controlIndex}].transform.rZ.enabled`}
                    label="Z axis rotation enabled"
                  />
                </>
              ) : null
            }
          </FFField>
        </Form.Group>
        <Field.MultiSelect
          name={`Controls.Object[${controlIndex}].statusesOrder`}
          label="Order of statuses"
          options={optionStatusList}
        />
      </Form.Group>
    </Form>
  );
};

ControlForm.defaultProps = {
  projectId: ''
};

ControlForm.propTypes = {
  part: TYPE_PART.isRequired,
  controlIndex: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  projectId: PropTypes.string
};

export default ControlForm;
