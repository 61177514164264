import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from '../../Atoms';
import Form from '../../Form';
import Field from '../../Form/Field';
import { partsActions, partsSelectors } from '../../../modules/parts';
import { seedsSelectors } from '../../../modules/seeds';
import { TYPE_TAB } from '../../../utils/propTypes';
import getPartFieldsToUpdate from '../../../utils/getPartFieldsToUpdate';

const SortableGroup = SortableElement(Form.Group);

const removeTabFromPart = (part, name) => {
  const newPart = { ...part };

  if (Array.isArray(newPart.Phases?.Object)) {
    newPart.Phases.Object = [...part.Phases.Object];

    const tabIndex = newPart.Phases.Object.findIndex(phase => phase.name === name);

    if (tabIndex !== -1) {
      newPart.Phases.Object.splice(tabIndex, 1);
    }
  }

  return newPart;
};

const updatePartTab = (part, formValue, name) => {
  const newPart = { ...part };

  if (!newPart.Phases) {
    newPart.Phases = { Object: [] };
  }

  if (!Array.isArray(newPart.Phases.Object)) {
    newPart.Phases.Object = [];
  }

  let index = newPart.Phases.Object.findIndex(phase => phase.name === name);

  index = index !== -1 ? index : newPart.Phases.Object.length;

  newPart.Phases.Object[index] = formValue.definition;

  return newPart;
};

const useTabFormCallbacks = (seedId, tab, parts) => {
  const [pristine, setPristine] = useState(true);

  const dispatch = useDispatch();

  const handleChangeForm = useCallback(form => {
    setPristine(form.pristine);
  }, []);
  const handleSubmitForm = useCallback(
    value => {
      const partsToSave = [];

      if (value.partId !== tab.partId) {
        const source = parts.find(part => part._id === tab.partId);

        if (source) {
          partsToSave.push(removeTabFromPart(source, tab.name));
        }
      }

      const target = parts.find(part => part._id === value.partId);

      if (target) {
        partsToSave.push(updatePartTab(target, value, tab.name));
      }

      if (partsToSave.length) {
        dispatch(
          partsActions.updateParts(
            seedId,
            partsToSave.map(part => getPartFieldsToUpdate(part, ['Phases']))
          )
        );
      }
    },
    [dispatch, parts, seedId, tab.name, tab.partId]
  );
  const handleDeleteTab = useCallback(() => {
    const part = parts.find(({ _id }) => _id === tab.partId);
    // eslint-disable-next-line no-alert
    const response = window.confirm(`Are you sure that you want to delete tab "${tab.name}"?`);

    if (!response) {
      return;
    }

    if (Array.isArray(part?.Phases?.Object)) {
      const tabIndex = part.Phases.Object.findIndex(phase => phase.name === tab.name);

      if (tabIndex !== -1) {
        const newPhases = [...part.Phases.Object];

        newPhases.splice(tabIndex, 1);
        const newPart = { ...part, Phases: { ...part.Phases, Object: newPhases } };

        dispatch(partsActions.updatePart(getPartFieldsToUpdate(newPart, ['Phases'])));
      }
    }
  }, [dispatch, parts, tab.name, tab.partId]);

  return { pristine, handleChangeForm, handleSubmitForm, handleDeleteTab };
};

const TabForm = ({ tab, index, seedId }) => {
  const views = useSelector(state => partsSelectors.selectViews(state, seedId));
  const parts = useSelector(state => partsSelectors.selectPartListBySeedMemoized(state, seedId));
  const partsForSuggestions = useSelector(state => partsSelectors.selectPartListBySeedForSuggestions(state, seedId));
  const controls = useSelector(state => partsSelectors.selectControlListForSuggestions(state, seedId));
  const rootPartId = useSelector(state => seedsSelectors.selectRootPartId(state, seedId));
  const isParentRoot = rootPartId === tab.partId;

  /* Separated callbacks to hook to reduce cognitive complexity */
  const { pristine, handleChangeForm, handleSubmitForm, handleDeleteTab } = useTabFormCallbacks(seedId, tab, parts);

  return (
    <SortableGroup
      header={tab.name}
      index={index}
      useDragHandle
      color={pristine ? 'light' : 'primary'}
      className={isParentRoot ? '' : 'ms-2'}
    >
      <Form
        initialValues={tab}
        onChange={handleChangeForm}
        onSubmit={handleSubmitForm}
        customButton={
          <Button color="danger" className="pull-right" onClick={handleDeleteTab}>
            <i className="fa fa-fw fa-trash-o" />
            Delete
          </Button>
        }
      >
        <Field.Select
          name="partId"
          label="Tab parent part"
          description="Tab will be visible if its parent part is loaded"
          options={partsForSuggestions}
        />
        {isParentRoot ? null : (
          <Alert color="info">
            Selected part is not a root part - tab will be shown only if the selected part is loaded
          </Alert>
        )}
        <Field.Text
          name="definition.name"
          label="Tab name"
          description="Changing tab name will reload current tab view"
          required
        />
        <Field.Text name="definition.displayName" label="Display name" guide="definition.name" />
        <Field.Select
          name="definition.openView"
          label="Tab view"
          description="Please make sure the view is not missing (if it's defined in a non-loaded part)"
          options={views}
        />
        <Field.ArrayObject name="definition.controls" label="Controls" description="Pick controls to show in this tab">
          <Field.Select name="name" label="Name" creatable clearable options={controls} />
          <Field.Toggle name="local" label="Local" description="Show this control only from children of current part" />
        </Field.ArrayObject>

        <Field.Toggle name="definition.summaryDisabled" label="Hide Overview Panel" idPrefix={tab.name} />
        <Field.Toggle name="definition.hideViewPickerButtons" label="Hide View Picker Buttons" idPrefix={tab.name} />
      </Form>
    </SortableGroup>
  );
};

TabForm.propTypes = {
  index: PropTypes.number.isRequired,
  seedId: PropTypes.string.isRequired,
  tab: TYPE_TAB.isRequired
};

export default TabForm;
