import { useContext } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import FieldWrapper from '../FieldWrapper';
import { BaseInputProps, FieldType, InputType } from '../../types';
import { getConvertedRegisterOptions } from '../helpers';
import { FormContext } from '../../FormContext';

const CLASSNAMES: Partial<Record<InputType, string>> = {
  [FieldType.SWITCH]: 'form-check-input',
  [FieldType.CHECK]: 'form-check-input'
};

const INPUT_TAG = 'input';

const CHECKBOX_TYPES = new Set([FieldType.SWITCH, FieldType.CHECK]);
const isCheckbox = (type: InputType) => CHECKBOX_TYPES.has(type);

const BaseInput = <T,>({
  name,
  type,
  label,
  description,
  placeholder,
  rows,
  readonly,
  registerOptions,
  append,
  prepend,
  onChange,
  index,
  useShadowValue,
  wrapperClass,
  noLabel,
  requiredFn
}: // eslint-disable-next-line sonarjs/cognitive-complexity
BaseInputProps<T>) => {
  const { setValue, getValues, setError, clearErrors } = useFormContext<T>();

  const { readOnly: contextReadOnly } = useContext(FormContext);

  const Tag = type === FieldType.TEXTAREA ? type : INPUT_TAG;

  let required = Boolean(registerOptions?.required);

  if (requiredFn) {
    required = requiredFn(getValues(), index);
  }

  const min = registerOptions?.min === undefined ? undefined : Number(registerOptions?.min);
  const max = registerOptions?.max === undefined ? undefined : Number(registerOptions?.max);

  const minLength = registerOptions?.minLength === undefined ? undefined : Number(registerOptions?.minLength);
  const maxLength = registerOptions?.maxLength === undefined ? undefined : Number(registerOptions?.maxLength);

  const { field, fieldState } = useController<T>({
    name,
    rules: getConvertedRegisterOptions({
      required,
      min,
      max,
      minLength,
      maxLength,
      validate: {
        notNumber: (v: any) => {
          if (type !== 'number') return true;

          if (type === 'number' && !required && (v === undefined || v === null)) return true;

          return !isNaN(v?.toString().replace(',', '.'));
        }
      }
    })
  });

  let className = CLASSNAMES[type] || 'form-control';

  const { error } = fieldState;

  if (error && error.type === 'notNumber') {
    error.message = 'Incorrect input';
  }

  if (error) {
    className = `${className} is-invalid`;
  }

  return (
    <FieldWrapper
      type={type}
      name={name}
      label={label}
      description={description}
      required={required}
      error={error?.message}
      isInputTag={Tag === INPUT_TAG}
      isCheckbox={isCheckbox(type)}
      useShadowValue={useShadowValue}
      className={wrapperClass}
      noLabel={noLabel}
    >
      {prepend ? <span className="input-group-text">{prepend}</span> : null}
      <Tag
        value={(field.value as any) || ''}
        id={name}
        className={className}
        type={isCheckbox(type) ? 'checkbox' : type}
        checked={isCheckbox(type) && field.value === true}
        placeholder={placeholder}
        readOnly={readonly || contextReadOnly}
        required={required}
        rows={rows}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        role={type}
        onKeyUp={e => {
          if (type === 'number') {
            const valid = (e.target as HTMLInputElement).checkValidity();

            if (!valid) {
              setError(name, {
                type: 'notNumber'
              });
            } else {
              clearErrors(name);
            }
          }
        }}
        onChange={e => {
          // need to make the form dirty
          let value = isCheckbox(type) ? (e.target as any).checked : e.target.value;

          if (value === '' && type === 'number') {
            value = null; // it appears we can't set to undefined in the array wrapper
          }

          setValue(name, value as any, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
          onChange && onChange(e, index);
        }}
      />
      {append ? <span className="input-group-text">{append}</span> : null}
    </FieldWrapper>
  );
};

export default BaseInput;
