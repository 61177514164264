import { useEffect } from 'react';
import { setLocalStorageItem, UI_SETTINGS_LOCALSTORAGE_KEY } from '../utils/helpers/storage';
import { useUiSettings } from '../store/modules/uiSettings';

const UserSettingsSynchronizer = () => {
  const { uiSettings } = useUiSettings();

  useEffect(() => {
    setLocalStorageItem(UI_SETTINGS_LOCALSTORAGE_KEY, JSON.stringify(uiSettings));
  }, [uiSettings]);

  return null;
};

export default UserSettingsSynchronizer;
