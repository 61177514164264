import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

const Condition = ({ children, source, condition, alternative }) => (
  <Field name={source}>{({ input }) => (input.value === condition ? children : alternative)}</Field>
);

Condition.propTypes = {
  source: PropTypes.string.isRequired,
  condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  alternative: PropTypes.node
};

Condition.defaultProps = {
  condition: true,
  alternative: null
};

export default Condition;
