import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';

const Wrapper = ({ className, style, children }) => (
  <div className={className} style={style}>
    {children}
  </div>
);

Wrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  children: PropTypes.node
};

Wrapper.defaultProps = {
  className: '',
  style: {},
  children: null
};

export default SortableContainer(Wrapper);
