import PropTypes from 'prop-types';

const SHADOW_VALUES_TYPE = PropTypes.shape({
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.shape({})
    })
  )
});

export default SHADOW_VALUES_TYPE;
