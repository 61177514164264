import { useCallback } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Icon, UncontrolledDropdown } from '../Atoms';
import { authActions, authSelectors } from '../../modules/auth';
import TermsOfUseLink from '../TermsOfUseLink';
import PrivacyPolicyLink from '../PrivacyPolicyLink';
import { HELP_LINK } from '../../config';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const UserMenuComponent = () => {
  const dispatch = useAppDispatch();
  const username = useAppSelector(authSelectors.selectUserName);
  const logout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <UncontrolledDropdown title={username} className="ms-auto p-0">
      <DropdownToggle color="icon" className="text-dark">
        <Icon type="mdiAccount" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header className="d-none d-md-inline-block">
          {username}
        </DropdownItem>
        <DropdownItem divider className="d-none d-md-block" />
        <TermsOfUseLink dropdown />
        <PrivacyPolicyLink dropdown />
        <DropdownItem href={HELP_LINK} rel="noopener noreferrer" target="_blank">
          Help
        </DropdownItem>
        <DropdownItem onClick={logout}>Log out</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserMenuComponent;
