import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useDispatch } from 'react-redux';
import { Media } from '../Atoms';
import { authActions } from '../../modules/auth';
import makeAssetUrl from '../../utils/api/makeAssetUrl';
import './AuthPagesWrapper.scss';

const authPagesWrapper = b.with('auth-pages-wrapper');
const logoUrl = makeAssetUrl('images')({ fileName: 'creatomus_logo.png' });

const AuthPagesWrapper = ({ header, children, className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.resetAuthState());
  }, [dispatch]);

  return (
    <div className={`${authPagesWrapper()} ${className}`}>
      <h2 className={`${authPagesWrapper('header')} text-center mb-2`}>
        <Media
          className={authPagesWrapper('logo')}
          middle
          object
          src={logoUrl}
          alt="Creatomus"
          title="Creatomus Staff Portal"
        />
        Staff Portal
      </h2>
      <h5 className="text-center">{header}</h5>
      <div className={`${authPagesWrapper('content')} p-3`}>{children}</div>
    </div>
  );
};

AuthPagesWrapper.defaultProps = {
  className: ''
};

AuthPagesWrapper.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default AuthPagesWrapper;
