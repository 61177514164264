import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { usePart, useQueryParams, useUserRights } from '../../../../utils/hooks';
import { partsActions } from '../../../../modules/parts';
import RawEditor from '../../../RawEditor';
import { RIGHTS } from '../../../../utils/rights';
import PartForm from './PartForm';
import ControlForm from './ControlForm';

export const MODAL_TYPES = {
  PART: 'part',
  CONTROL: 'control'
};

export const MODAL_MODES = {
  DEFAULT: 'default',
  RAW: 'raw'
};

export const NEW_INDEX = 'new';

const PartModals = ({ projectId }) => {
  const dispatch = useDispatch();
  const hasRights = useUserRights();
  const { location } = useHistory();
  const setParams = useQueryParams();
  const { type, partId, controlIndex, mode } = qs.parse(location.search);
  const selectedPart = usePart(partId);
  const closeModal = useCallback(() => {
    setParams({ partId: undefined, type: undefined, controlIndex: undefined, mode: undefined });
  }, [setParams]);

  const updatePart = useCallback(
    value => {
      dispatch(partsActions.updatePart(value));
    },
    [dispatch]
  );
  const initialRawPartValue = useMemo(
    () =>
      selectedPart
        ? {
            json: {
              ReferenceName: selectedPart.ReferenceName,
              Option: selectedPart.Option,
              disabled: selectedPart.disabled,
              Materials: selectedPart.Materials,
              Position: selectedPart.Position,
              Info: selectedPart.Info,
              ExtraInfo: selectedPart.ExtraInfo,
              Sprites: selectedPart.Sprites,
              Config: selectedPart.Config,
              Controls: selectedPart.Controls,
              Lights: selectedPart.Lights,
              Phases: selectedPart.Phases,
              Views: selectedPart.Views
            }
          }
        : { json: {} },
    [selectedPart]
  );

  const updateRawPart = useCallback(
    value => {
      dispatch(partsActions.updatePart({ ...selectedPart, ...value.json }));
    },
    [dispatch, selectedPart]
  );

  if (!selectedPart) {
    return null;
  }

  if (type === MODAL_TYPES.PART) {
    if (mode === MODAL_MODES.RAW) {
      if (!hasRights([RIGHTS.PARTS__EDIT_RAW])) {
        closeModal();

        return null;
      }

      return (
        <RawEditor
          block
          onSubmit={updateRawPart}
          initial={initialRawPartValue}
          header="Edit part"
          onlyModal
          onClose={closeModal}
        />
      );
    }

    return (
      <PartForm
        part={selectedPart}
        close={closeModal}
        onSubmit={updatePart}
        projectId={projectId}
        seedId={selectedPart.seed}
      />
    );
  }

  let index = Number(controlIndex);

  if (controlIndex === NEW_INDEX) {
    if (!selectedPart.Controls) {
      selectedPart.Controls = { Object: [] };
    }

    if (!Array.isArray(selectedPart.Controls.Object)) {
      selectedPart.Controls.Object = [];
    }

    index = selectedPart.Controls.Object.length;
  }

  return (
    <ControlForm
      onSubmit={updatePart}
      close={closeModal}
      part={selectedPart}
      controlIndex={index}
      projectId={projectId}
    />
  );
};

PartModals.defaultProps = {
  projectId: ''
};

PartModals.propTypes = {
  projectId: PropTypes.string
};

export default PartModals;
