interface PdfViewerProps {
  src: string;
}

const PdfViewer = ({ src }: PdfViewerProps) => (
  <object data={src} type="application/pdf" width="100%" height="100%">
    <embed src={src} width="100%" height="100%" />
    <p>
      This browser does not support PDFs. Please download the PDF to view it:
      <a href="https://sumanbogati.github.io/tiny.pdf">Download PDF</a>.
    </p>
  </object>
);

export default PdfViewer;
