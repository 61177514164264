import { useCallback, useMemo } from 'react';
import { SeedRemoteSwitchType, SeedType } from 'types';
import { useUpdateSeedRemoteSwitches } from '../../store/seed';
import { RIGHTS } from '../../utils/rights';
import RawEditor from '../RawEditor';

interface RemoteSwitchesRawEditorProps {
  seed: SeedType;
}

const RemoteSwitchesRawEditor = ({ seed }: RemoteSwitchesRawEditorProps) => {
  const { updateSeedRemoteSwitches } = useUpdateSeedRemoteSwitches();

  const handleSubmit = useCallback(
    ({ json }: { json: SeedRemoteSwitchType[] }) => {
      if (Array.isArray(json)) {
        updateSeedRemoteSwitches({ _id: seed._id, remoteSwitches: json });
      }
    },
    [seed._id, updateSeedRemoteSwitches]
  );

  const initialValues = useMemo(() => ({ json: seed.remoteSwitches }), [seed.remoteSwitches]);

  return <RawEditor rights={[RIGHTS.SUPERUSER]} initial={initialValues} onSubmit={handleSubmit} />;
};

export default RemoteSwitchesRawEditor;
