import { useGetUsersQuery } from './services/api';

export const useUsersList = () => {
  const { data, isLoading, isUninitialized } = useGetUsersQuery();
  const isInProgress = isLoading || isUninitialized;

  return {
    users: data,
    isLoading,
    isUninitialized,
    isInProgress
  };
};
