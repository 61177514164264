import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../../Atoms';

const MappedInstanceMetadata = ({ instanceId, seedId, factoryId }) => (
  <Row tag="dl">
    <Col tag="dt" sm="3">
      Seed
    </Col>
    <Col tag="dd" sm="9">
      {seedId}
    </Col>
    <Col tag="dt" sm="3">
      Instance id
    </Col>
    <Col tag="dd" sm="9">
      {instanceId}
    </Col>
    <Col tag="dt" sm="3">
      Source map
    </Col>
    <Col tag="dd" sm="9">
      {factoryId}
    </Col>
  </Row>
);

MappedInstanceMetadata.propTypes = {
  instanceId: PropTypes.string.isRequired,
  seedId: PropTypes.string.isRequired,
  factoryId: PropTypes.string.isRequired
};

export default MappedInstanceMetadata;
