import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ApplicationRoute, getVisitorUrl } from '../../../utils/appRoutes';
import BreadcrumbSection from '../BreadcrumbSection';
import { instancesSelectors } from '../../../modules/instances';
import BreadcrumbBackButton from '../BreadcrumbBackButton';

const VisitorBreadcrumbs: FC = () => {
  const match = useRouteMatch<{ visitorId: string }>();
  const isListPage = match.path === ApplicationRoute.Visitors;
  const visitors = useSelector(instancesSelectors.selectVisitorList);

  const list = useMemo(
    () =>
      visitors.map(visitor => ({
        label: visitor.username,
        id: visitor._id,
        path: getVisitorUrl(visitor._id)
      })),
    [visitors]
  );

  return (
    <>
      <BreadcrumbBackButton to={isListPage ? ApplicationRoute.Main : ApplicationRoute.Visitors} />
      <BreadcrumbSection title="Visitors" list={list} activeId={match.params.visitorId} />
    </>
  );
};

export default VisitorBreadcrumbs;
