import { useMemo } from 'react';
import b from 'b_';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { PopulatedFactoryMapType } from 'types';
import Loader from '../../Loader';
import { Table } from '../../Atoms';
import { factoryMapsHooks } from '../../../modules/factoryMaps';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';
import { getFactoryMapUrl } from '../../../utils/appRoutes';

const factoryMapsList = b.with('factory-maps-list');

const FactoryMapsList = () => {
  const { request, list } = factoryMapsHooks.useFactoryMapsList();
  const hasRights = useUserRights();

  const columns = useMemo<Column<PopulatedFactoryMapType>[]>(() => {
    const className = 'fw-bold p-2';

    return [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 200,
        className,
        Cell: ({ row, value }) =>
          hasRights([RIGHTS.FACTORY_MAPS__UPDATE]) ? (
            <Link to={getFactoryMapUrl(row.original._id)}>{value}</Link>
          ) : (
            value
          )
      },
      {
        Header: '',
        id: 'published',
        accessor: row => Number(row.published),
        width: 50,
        disableFilters: true,
        className: 'text-center p-2',
        Cell: ({ value }: { value: number }) =>
          value ? <i className="fa fa-fw fa-check-square-o text-success" title="Published" /> : null
      },
      {
        Header: 'Version',
        accessor: 'version',
        width: 50,
        disableFilters: true,
        className: `text-center ${className}`
      },
      {
        Header: 'Seed',
        id: 'populated.seedName',
        accessor: row => row.populated.seedName,
        width: 200,
        className,
        Cell: ({ row, value }: CellProps<PopulatedFactoryMapType, string>) =>
          value ? `${value} (${row.original.populated.seedVersion})` : null
      }
    ];
  }, [hasRights]);

  return (
    <Loader loading={request}>
      <Table data={list} columns={columns} className={factoryMapsList()} />
    </Loader>
  );
};

export default FactoryMapsList;
