import { useGetSubscriptionsQuery } from './services/api';
import { subscriptionsAdapter } from './services/adapter';

export const useSubscriptionList = () => {
  const { subscriptions, isLoading, isUninitialized } = useGetSubscriptionsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      subscriptions: data ? subscriptionsAdapter.getSelectors().selectAll(data) : [],
      ...rest
    })
  });
  const isInProgress = isLoading || isUninitialized;

  return { subscriptions, isLoading, isUninitialized, isInProgress };
};
