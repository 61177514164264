import { PartnerType, ProjectType } from 'types';
import { Form, FormField, Icon, PageTile } from '../Atoms';
import { usePartners } from '../../modules/partners/partnersHooks';
import { useAppSelector } from '../../store/hooks';
import { authSelectors } from '../../modules/auth';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';

interface ProjectInfoFormProps {
  project: ProjectType;
  onSubmit(value: ProjectType): void;
}

// TODO: research is needed, performance degradations for projects with big arrays of translations without hidden field
// https://admin.rossi.creatomus.com/projects/602cc126742fe8b780fe1886/info
const ProjectInfoForm = ({ project, onSubmit }: ProjectInfoFormProps) => {
  const { list } = usePartners();
  const configuratorUrl = useAppSelector(authSelectors.selectUserConfiguratorUrl);
  const hasRights = useUserRights();

  return (
    <Form<ProjectType> defaultValues={project} onSubmit={onSubmit}>
      <PageTile className="mb-4 d-flex align-items-center">
        <div className="text-primary ms-2">
          <Icon type="mdiFolderSettingsOutline" scale={2} />
        </div>
        <div className="ps-4">
          <h2 className="fw-bold mb-0">{project.name}</h2>
          <small className="text-muted">These settings will be applied to the whole project</small>
        </div>
      </PageTile>
      <PageTile>
        <FormField.Text<ProjectType> name="name" label="Project name" required />
        {hasRights([RIGHTS.PROJECTS__UPDATE_PARTNER]) ? (
          <FormField.Select<ProjectType, PartnerType>
            name="partnerId"
            label="Partner"
            description="To whom this project belongs"
            options={list}
            getOptionValue={partner => `${partner?._id}`}
            getOptionLabel={partner => `${partner?.name}`}
            required
          />
        ) : null}
        <FormField.Text<ProjectType>
          name="link"
          label="URL"
          description="Link to configurator, no spaces or special symbols allowed"
          prepend={`${configuratorUrl}/`}
          required
        />
        <FormField.Hidden<ProjectType> name="settings" />
      </PageTile>
    </Form>
  );
};

export default ProjectInfoForm;
