import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { Translation, TranslationDefinition } from '../types';

export const Translations = () => {
  return (
    <>
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'name', 'untitled')}
        name="settings.translations"
        label="Translations"
        description="Here add various translations for different languages. Base language is English."
        useShadowValue
      >
        <FormField.Text<Translation>
          useShadowValue
          name="name"
          label="Language"
          description="Must be unique"
          required
        />
        <FormField.Toggle<Translation> useShadowValue name="disabled" label="Disable language" />
        <FormField.ArrayWrapper<Translation>
          headerFormat={value => get(value, 'key', 'untitled')}
          name="definitions"
          label="Definitions"
          twoColumns
          noWrapper
          hideFollowingLabels
        >
          <FormField.Text<TranslationDefinition>
            wrapperClass="flex-grow-1 me-2"
            name="key"
            label="Original"
            column="left"
            useShadowValue={false}
          />
          <FormField.Text<TranslationDefinition>
            wrapperClass="flex-grow-1"
            name="value"
            label="Translation"
            column="right"
            useShadowValue={false}
          />
        </FormField.ArrayWrapper>
      </FormField.ArrayWrapper>
    </>
  );
};
