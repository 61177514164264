import { useState, useCallback } from 'react';
import b from 'b_';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from '../Atoms';
import { instancesSelectors } from '../../modules/instances';
import VisitorInstanceList from '../VisitorInstanceList';
import { RIGHTS } from '../../utils/rights';
import { useUserRights } from '../../utils/hooks';
import Loader from '../Loader';
import { useInstancesList } from '../InstancesPage/InstancesPage';
import VisitorStatistics from './VisitorStatistics';

import './VisitorPage.scss';

const visitorPage = b.with('visitor-page');

const TAB = {
  INFO: 'info',
  STATS: 'stats'
} as const;

type TabType = typeof TAB[keyof typeof TAB];

const VisitorPage = () => {
  const [activeTab, setActiveTab] = useState<TabType>(TAB.INFO);
  const { visitorId } = useParams<{ visitorId: string }>();
  const visitor = useSelector(instancesSelectors.selectVisitor(visitorId));

  const handleSwitchTab = useCallback(e => {
    const { tab } = e.currentTarget.dataset;

    setActiveTab(tab);
  }, []);

  const hasUserRights = useUserRights();
  const hasStatisticsRights = hasUserRights([RIGHTS.VISITORS__STATISTICS__READ]);

  const { loading } = useInstancesList();

  return (
    <div className={`${visitorPage()} p-3 flex-grow-1 w-100`}>
      <Loader loading={loading}>
        {visitor ? (
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  data-tab={TAB.INFO}
                  className={activeTab === TAB.INFO ? 'active' : ''}
                  onClick={handleSwitchTab}
                >
                  Info
                </NavLink>
              </NavItem>
              {hasStatisticsRights ? (
                <NavItem>
                  <NavLink
                    data-tab={TAB.STATS}
                    className={activeTab === TAB.STATS ? 'active' : ''}
                    onClick={handleSwitchTab}
                  >
                    Statistics
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TAB.INFO} className={visitorPage('list')}>
                <VisitorInstanceList visitor={visitor} />
              </TabPane>
              {hasStatisticsRights ? (
                <TabPane tabId={TAB.STATS} className={visitorPage('statistics')}>
                  <VisitorStatistics userId={visitor._id} />
                </TabPane>
              ) : null}
            </TabContent>
          </>
        ) : null}
      </Loader>
    </div>
  );
};

export default VisitorPage;
