import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FormFeedback } from '../../../Atoms';
import { TYPE_SUGGESTION_LIST } from '../../../../utils/propTypes';
import FieldWrapper from '../FieldWrapper';

export const arrayMove = (array, from, to) => {
  const newArray = [...array];

  newArray.splice(to < 0 ? array.length + to : to, 0, newArray.splice(from, 1)[0]);

  return newArray;
};

/*
  Lifted from react-select's examples
*/
const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it.
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };

  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const DraggableValueLabel = SortableHandle(components.MultiValueLabel);

class MultiSelect extends React.PureComponent {
  renderField = ({ input, meta }) => {
    const { options, clearable, creatable, disabled } = this.props;

    let selectedOptions;

    if (Array.isArray(input.value)) {
      const optionsMap = options.reduce((result, option) => {
        result[option.value] = option; // eslint-disable-line no-param-reassign

        return result;
      }, {});

      // If selected options don't exist in given options, they need to be created according to right shape
      selectedOptions = input.value.map(value => optionsMap[value] || { label: value, value });
    } else {
      selectedOptions = [];
    }

    const sortEndHandler = ({ oldIndex, newIndex }) => {
      const newValue = arrayMove(selectedOptions, oldIndex, newIndex);

      input.onChange(newValue.map(item => item.value));
    };

    const changeHandler = values => {
      const newValues = Array.isArray(values) ? values.map(item => item.value) : [];

      input.onChange(newValues);
    };

    const SelectComponent = creatable ? SortableContainer(CreatableSelect) : SortableContainer(ReactSelect);

    return (
      <>
        <SelectComponent
          {...input}
          onChange={changeHandler}
          onSortEnd={sortEndHandler}
          useDragHandle
          axis="xy"
          options={options}
          value={selectedOptions}
          isClearable={clearable}
          isDisabled={disabled}
          isSearchable
          isMulti
          classNamePrefix="field-select"
          className={`w-100 ${meta.invalid ? 'is-invalid' : ''}`}
          components={{ MultiValue: SortableMultiValue, MultiValueLabel: DraggableValueLabel }}
        />

        <FormFeedback>{meta.error}</FormFeedback>
      </>
    );
  };

  validate = value => {
    if (this.props.required && !value?.length) {
      return 'Required';
    }

    return undefined;
  };

  render() {
    const { name, parse, format } = this.props;

    return (
      <FieldWrapper {...this.props}>
        <Field name={name} parse={parse} format={format} render={this.renderField} validate={this.validate} />
      </FieldWrapper>
    );
  }
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: TYPE_SUGGESTION_LIST,
  creatable: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  parse: PropTypes.func,
  format: PropTypes.func
};

MultiSelect.defaultProps = {
  creatable: false,
  clearable: true,
  disabled: false,
  required: false,
  options: [],
  parse: value => value,
  format: value => value
};

export default MultiSelect;
