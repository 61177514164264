import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import b from 'b_';
import Icon from '../../Icon/Icon';
import type { IconType } from '../../Icon/Icon';

import './Box.scss';

const boxClassName = b.with('box');

export interface IBoxProps {
  color?: string;
  icon?: IconType;
  title?: string;
  description?: string | JSX.Element;
  inactive?: boolean;
  link?: string;
  children?: ReactNode;
  innerChildren?: ReactNode;
  onClick?: () => void;
}

export interface ILinkBoxProps extends IBoxProps {
  link: string;
}

export interface IClickBoxProps extends IBoxProps {
  onClick: () => void;
}

const Box = ({
  color,
  icon,
  title,
  description,
  link,
  inactive,
  children,
  innerChildren,
  onClick
}: ILinkBoxProps | IClickBoxProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      history.push(link);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div className="h-100 flex-grow-1 flex-sm-grow-0">
      <button className={`h-100 ${boxClassName('wrapper')}`} type="button" onClick={handleClick}>
        <div className={`h-100 ${boxClassName('index-item', { inactive })}`} style={{ borderColor: color }}>
          <div className={boxClassName('item-icon', { inactive })} style={{ color }}>
            {icon ? <Icon type={icon} scale={1.3} /> : null}
          </div>
          <div className="text-center font-weight-bolder text-dark text-decoration-underline">{title}</div>
          {description ? <small className="text-muted text-center">{description}</small> : null}
          {innerChildren}
        </div>
      </button>
      <div className={boxClassName('children-wrapper')}>{children}</div>
    </div>
  );
};

export default Box;
