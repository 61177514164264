import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from '../../../Form';
import Field from '../../../Form/Field';
import ProtectedButton from '../../../ProtectedButton';
import { TYPE_SUGGESTION_LIST, TYPE_REMOTE_CONTROL, TYPE_ASSET_LIST } from '../../../../utils/propTypes';
import { RIGHTS } from '../../../../utils/rights';
import api from '../../../../utils/api';
import makeAssetUrl from '../../../../utils/api/makeAssetUrl';
import { ASSET_TYPES } from '../../../../modules/assets';
import { usePart } from '../../../../utils/hooks';

const RemoteControlForm = props => {
  const {
    remoteControl,
    onSubmit,
    disableOptionPrice,
    optionStatusList,
    onRemoveRemoteControl,
    isNewControl,
    projectImages,
    projectId,
    updateImagesAfterUpload
  } = props;

  const { list = [] } = remoteControl;
  const valueOptions = list.map(option => ({ value: option.name, label: option.name }));
  const imageOptionsMap = projectImages.reduce((result, { filename, metadata }) => {
    // eslint-disable-next-line no-param-reassign
    result[filename] = {
      name: filename,
      url: makeAssetUrl(ASSET_TYPES.IMAGES)({
        fileName: filename,
        projectId: metadata ? metadata.projectId : ''
      })
    };

    return result;
  }, {});
  const imageOptions = Object.values(imageOptionsMap);

  const removeRemoteControlHandler = useCallback(
    e => onRemoveRemoteControl(remoteControl),
    [onRemoveRemoteControl, remoteControl]
  );

  const headingFormat = useCallback((value, name, childrenArray) => {
    return (
      <>
        <span>{value[childrenArray[1].props.name]}</span>
        <span className="text-pre-wrap"> - {value[childrenArray[0].props.name]}</span>
      </>
    );
  }, []);

  const uploadImage = useCallback(file => api.assets.images.upload(projectId)(file), [projectId]);
  const part = usePart(remoteControl.partId);
  const partControl = useMemo(
    () =>
      Array.isArray(part?.Controls?.Object)
        ? part.Controls.Object.find(control => control.name === remoteControl.name)
        : {},
    [part.Controls.Object, remoteControl.name]
  );

  const shadowValues = useMemo(() => {
    return {
      list: [
        {
          name: 'part',
          value: partControl
        },
        { name: 'current' }
      ]
    };
  }, [partControl]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={remoteControl}
      shadowValues={isNewControl ? null : shadowValues}
      useTopButtons
    >
      {!isNewControl ? (
        <ProtectedButton
          size="sm"
          rights={[RIGHTS.REMOTE_CONTROLS__DELETE]}
          color="danger"
          onClick={removeRemoteControlHandler}
          className="pull-right"
          title="Unlink"
        >
          <i className="fa fa-chain-broken fa-fw" />
          Unlink
        </ProtectedButton>
      ) : null}
      <h4 className="mt-3">Control</h4>
      <Field.Text name="displayName" label="Label" useShadowValue />
      <Field.Text
        name="description"
        label="Description"
        description="Small description about the Control (optional)"
        useShadowValue
      />
      <Field.Select name="value" label="Value" options={valueOptions} useShadowValue />
      <hr />
      <h4>Options</h4>
      <Field.ArrayObject name="list" headingFormat={headingFormat} isStatic>
        <Field.Immutable name="name" label="Name" />
        <Field.Text name="displayName" label="Label" useShadowValue />
        <Field.Text name="optionHint" label="Hint" useShadowValue />
        {disableOptionPrice ? null : <Field.Number name="price" label="Price" useShadowValue />}
        {disableOptionPrice ? null : <Field.Number name="price_psq" label="Price per area" useShadowValue />}
        <Field.Select name="status" options={optionStatusList} label="Status" useShadowValue />
        <Field.Image
          name="image.fileName"
          label="Image"
          list={imageOptions}
          uploadImage={uploadImage}
          onUploadSuccess={updateImagesAfterUpload}
          useShadowValue
        />
      </Field.ArrayObject>
    </Form>
  );
};

RemoteControlForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  optionStatusList: TYPE_SUGGESTION_LIST.isRequired,
  remoteControl: TYPE_REMOTE_CONTROL.isRequired,
  disableOptionPrice: PropTypes.bool.isRequired,
  onRemoveRemoteControl: PropTypes.func.isRequired,
  isNewControl: PropTypes.bool,
  projectImages: TYPE_ASSET_LIST,
  projectId: PropTypes.string.isRequired,
  updateImagesAfterUpload: PropTypes.func.isRequired
};

RemoteControlForm.defaultProps = {
  isNewControl: false,
  projectImages: []
};

export default RemoteControlForm;
