import { FC } from 'react';
import { TemplateType } from 'types';
import { Link } from 'react-router-dom';
import { Badge, Icon, ListPageItem } from '../Atoms';
import { RIGHTS } from '../../utils/rights';
import { getTemplateUrl } from '../../utils/appRoutes';
import { useUserRights } from '../../utils/hooks';
import TemplateListItemActions from './TemplateListItemActions';

interface TemplateListItemProps {
  template: TemplateType;
  active: boolean;
}

const TemplateListItem: FC<TemplateListItemProps> = ({ template, active }) => {
  const { _id, partnerId, name } = template;
  const hasRights = useUserRights();

  return (
    <ListPageItem>
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <Icon type="mdiFilePdfBox" />
          {hasRights([RIGHTS.TEMPLATES__UPDATE]) ? (
            <Link to={getTemplateUrl(partnerId, _id)} title="Open template">
              <u className="px-2 text-dark fw-bolder">{name}</u>
            </Link>
          ) : (
            <span className="px-2 text-dark fw-bolder">{name}</span>
          )}
          <Badge color={active ? 'warning' : 'secondary'} pill>
            {active ? 'Public' : 'Draft'}
          </Badge>
        </div>
        <TemplateListItemActions template={template} active={active} />
      </div>
    </ListPageItem>
  );
};

export default TemplateListItem;
