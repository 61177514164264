import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { makeSuggestions } from '../../../utils';

export const ModelSettings = () => {
  return (
    <>
      <FormField.Toggle<ProjectType>
        name="settings.model.forceFetchingAssets"
        label="Force fetching assets"
        description={
          <span>
            Will use <strong>updatedAt</strong> field when fetching assets to ignore old cache
          </span>
        }
        useShadowValue
      />
      <FormField.Number<ProjectType> name="settings.model.maxInstanceCount" label="Max instance count" useShadowValue />
      <FormField.Select<ProjectType>
        name="settings.model.modelUnits"
        options={makeSuggestions(['metric', 'imperial'])}
        label="Model units"
        description=""
        useShadowValue
      />
    </>
  );
};
