import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { PpsqmLayer } from '../types';

export const PerLayerPricing = () => {
  return (
    <>
      <div className="mb-3 border border-danger text-center">
        <span className="text-danger ">
          Net area price is calculated by multiplying the area of gemoetry on this layer with the value of the Price
          Code. If this is not set, Price is used.
        </span>
      </div>
      <FormField.ArrayWrapper<ProjectType>
        name="settings.model.ppsqmLayers"
        label="Price per sq unit layers"
        description="List of layer names to calculate price per square unit from"
        headerFormat={value => get(value, 'layer', 'untitled')}
        useShadowValue
      >
        <FormField.Text<PpsqmLayer> name="layer" label="Layer name" required />
        <FormField.Number<PpsqmLayer> name="ppsqm" label="Price" description="Price per square unit" />
        <FormField.Text<PpsqmLayer> name="priceCode_psq" label="Pricecode" description="Price code per square unit" />
      </FormField.ArrayWrapper>
    </>
  );
};
